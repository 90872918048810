import { Badge } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
const AccountRoleBadge: React.FC<{ role: string }> = ({ role }) => {
  const borderRadius = 8
  const maxW = 24
  const { t } = useTranslation()
  switch (role) {
    case 'Admin':
      return (
        <Badge
          bg="logo_green01"
          color="white"
          borderRadius={borderRadius}
          maxW={maxW}
          textAlign="center"
        >
          {t('accounts.roles.admin')}
        </Badge>
      )
    case 'Consumer':
      return (
        <Badge
          bg="primary_orange01"
          color="white"
          borderRadius={borderRadius}
          maxW={maxW}
          textAlign="center"
        >
          {t('accounts.roles.consumer')}
        </Badge>
      )
    case 'Provider':
      return (
        <Badge
          bg="logo_pink"
          borderRadius={borderRadius}
          maxW={maxW}
          textAlign="center"
        >
          {t('accounts.roles.provider')}
        </Badge>
      )

    default:
      return <Badge bg="yellow.600">{role}</Badge>
  }
}
export default AccountRoleBadge
