import { Flex, useMediaQuery, Image, ResponsiveValue } from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import { Note } from '@contracts/misc'
import { useAllNotes as useAllPropertyNotes } from '../../apiClients/propertiesApiClient/notes'
import { useAllNotes as useAllProjectNotes } from '../../apiClients/projectsApiClient/notes'
import { useArea } from '../../common/area-context'

import Spinner from '../Spinner'
import NoteCardMicro from '../notes/NoteCardMicro'
import NoteCardCompact from '../notes/NoteCardCompact'
import PromoCardSimple from '../ui/PromoCardSimple'
import promoPropertyNotes from '../../img/properties/noPropertyNotesPromo.png'
import promoProjectNotes from '../../img/projects/noProjectNotesPromo.png'

const LatestNotesSection: React.FC<{
  px?: ResponsiveValue<number | string>
  maxAgeInMonths?: number
  maxNoNotes?: number
}> = ({ px, maxAgeInMonths = 2, maxNoNotes = 6 }) => {
  const noteArea = useArea()
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const { data: latestPropertyNotes } = useAllPropertyNotes(maxAgeInMonths)
  const { data: latestProjectNotes } = useAllProjectNotes(maxAgeInMonths)

  latestProjectNotes
  const [viewOption, setViewOption] = useState<'list' | 'compact' | 'micro'>(
    isTabletOrSmaller ? 'micro' : 'compact',
  )

  let notes =
    noteArea.area === 'property' ? latestPropertyNotes : latestProjectNotes

  const compareNoteDates = (a: Note, b: Note) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  }

  notes?.sort(compareNoteDates)
  notes = notes?.slice(0, maxNoNotes)
  useEffect(() => {
    if (isTabletOrSmaller) {
      setViewOption('micro')
    } else {
      setViewOption('compact')
    }
  }, [isTabletOrSmaller])

  return (
    <Flex
      flexDir="row"
      wrap="wrap"
      gap={{ xxs: 4, laptop: 8 }}
      px={px}
      justifyContent="left"
      // border="1px solid red"
    >
      {!notes?.length && (
        <PromoCardSimple
          bg="transparent"
          variant={isTabletOrSmaller ? 'wide' : 'full'}
          noBorder
        >
          <Image
            src={
              noteArea.area === 'property'
                ? promoPropertyNotes
                : promoProjectNotes
            }
            mt={6}
          />
        </PromoCardSimple>
      )}

      {notes ? (
        notes.map(note => {
          if (viewOption === 'micro') {
            return (
              // <Text>
              //   {getDateStrFromDate(new Date(note.updatedAt))}{' '}
              //   {getTimeStrFromNumber(note.updatedAt)}
              // </Text>
              <NoteCardMicro
                note={note}
                key={note.id}
                propertyId={
                  noteArea.area === 'property' ? note.entityId : undefined
                }
                projectId={
                  noteArea.area === 'project' ? note.entityId : undefined
                }
              />
            )
          }
          if (viewOption === 'compact') {
            return (
              // <Text>{note.updatedAt}</Text>
              <NoteCardCompact
                note={note}
                key={note.id}
                propertyId={
                  noteArea.area === 'property' ? note.entityId : undefined
                }
                projectId={
                  noteArea.area === 'project' ? note.entityId : undefined
                }
              />
            )
          }
        })
      ) : (
        <Spinner />
      )}
    </Flex>
  )
}
export default LatestNotesSection
