import { ConstructionGlossary } from '@contracts/support'
import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL

const constructionGlossaryFetcher = async (
  urlKey: string,
): Promise<ConstructionGlossary[] | undefined> => {
  return axios
    .get<ConstructionGlossary[]>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data
    })
}

export const useConstructionGlossary = (): SWRResponse<
  ConstructionGlossary[] | undefined
> => {
  return useSWR<ConstructionGlossary[] | undefined>(
    '/construction-glossaries/',
    constructionGlossaryFetcher,
    { revalidateOnFocus: false },
  )
}
export const fetchConstructionGlossary = async (filter: {
  letter?: string
  search?: string
}) => {
  if (!filter.letter && !filter.search) {
    const errorMsg =
      'Error fetching construction glossary, letter or search string must be provided.'
    log.warn(errorMsg)
    throw new Error(errorMsg)
  }

  const queryParts = { letter: '', search: '' }
  if (filter.letter) {
    queryParts.letter = filter.letter
  }
  if (filter.search) {
    queryParts.search = filter.search
  }
  const uri = `${baseUrl}/construction-glossaries?${new URLSearchParams(
    queryParts,
  ).toString()}`

  return axios
    .get(uri)
    .then(res => {
      log.info('Successfully fetched construction glossary')
      return res
    })
    .catch(error => {
      log.error({ err: error }, 'Error fetching construction glossary')
      throw error
    })
}

export const updateGlossaryItem = async (
  id: string,
  name: string | undefined,
  description: string | undefined,
) => {
  return axios
    .put(
      `${baseUrl}/construction-glossaries/${id}`,
      {
        ...(name && { name }),
        ...(description && { description }),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated glossary item', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating glossary item')
      throw error
    })
}

export const deleteGlossaryItem = async (id: string) => {
  await axios
    .delete(`${baseUrl}/construction-glossaries/${id}`)
    .then(() => {
      log.info(`Successfully deleted glossary item with id ${id}`)
    })
    .catch((error: Error) => {
      log.error({ err: error }, 'Error deleting glossary item')
      return error.message
    })
}

export async function addGlossaryItem(
  name: string,
  description: string,
): Promise<ConstructionGlossary> {
  return axios
    .post<ConstructionGlossary, AxiosResponse<ConstructionGlossary>>(
      `${baseUrl}/construction-glossaries/`,
      {
        name,
        description,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('New glossary item was successfully added', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error creating new construction glossary item')
      throw error
    })
}
