import { ArticleInfo, Author, Tag } from '@contracts/support'
import { validateString } from '../../common/auth-utils'

export interface ArticleFormValues {
  heading: string
  content: string
  intro: string
  author: Author
  publishedAt?: number
  tags: Tag[]
}

interface ArticleErrors {
  heading?: string
  content?: string
  intro?: string
  author?: Author
  publishedAt?: number
  tags?: Tag[]
}

export const validate = (values: ArticleFormValues): ArticleErrors => {
  const errors: ArticleErrors = {}

  const tmpRes = validateString(values.heading, 'rubrik', 2, 255)
  if (!tmpRes.isValid) {
    errors.heading = tmpRes.errorMsg
  }

  const tmpIntroRes = validateString(values.intro, 'introduktionstext', 2, 2000)
  if (!tmpIntroRes.isValid) {
    errors.intro = tmpIntroRes.errorMsg
  }
  return errors
}

export const getStatus = (article: ArticleInfo) => {
  if (article.status === 'Done') {
    if (article.publishedAt) {
      return 'Published'
    }
    return 'NotPublished'
  }
  return article.status
}
