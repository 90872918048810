import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Show } from '@chakra-ui/react'

import AuthContext from '../common/auth-context'
import GlobalFeedback from '../components/feedback/GlobalFeedback'
import HeaderNavbarPortal from './portal/HeaderNavbarPortal'
import SidebarPortal from './portal/SidebarPortal'
import { SIDE_BAR_WIDTH_EXPANDED } from './constants'
import SidebarProviderPortal from './portal/SidebarProviderPortal'

const LayoutPortal: React.FC<{ context: 'consumer' | 'provider' }> = ({
  context,
}) => {
  const authCtx = useContext(AuthContext)
  // const [isExpanded, setIsExpanded] = useState(true)
  // const onToggleHandler = () => {
  //   setIsExpanded(!isExpanded)
  // }
  if (!authCtx.isLoggedIn) {
    return null
  }
  // const [isTablet] = useMediaQuery('(max-width: 900px)')
  // useEffect(() => {
  // if (isTablet) {
  // setIsExpanded(false)
  // }
  // }, [isTablet])

  return (
    <Box display="flex" flexDir="row">
      <Box display="flex" flexDir="column">
        <Show above="laptop">
          {context === 'consumer' ? (
            // <SidebarPortal isExpanded={isExpanded} onToggle={onToggleHandler} />
            <SidebarPortal />
          ) : (
            <SidebarProviderPortal
            // isExpanded={isExpanded}
            // onToggle={onToggleHandler}
            />
          )}
          <Box
            width="100vw"
            // pl={isExpanded ? SIDE_BAR_WIDTH_EXPANDED : SIDE_BAR_WIDTH_COLLAPSED}
            pl={SIDE_BAR_WIDTH_EXPANDED}
          >
            <Outlet />
            <HeaderNavbarPortal showHamburger={false} />
          </Box>
          <GlobalFeedback />
        </Show>
        <Show below="laptop">
          <Box width="100vw">
            <Outlet />
            <HeaderNavbarPortal showHamburger={true} />
            <GlobalFeedback />
          </Box>
        </Show>
      </Box>
    </Box>
  )
}
export default LayoutPortal
