import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerAsks.png'

import AuthContext from '../../common/auth-context'

import MyHelpRequestSection from '../../components/sections/portal/MyHelpRequestSection'
import ProviderHelpRequestSection from '../../components/sections/portal/ProviderHelpRequestSection'

const ProviderHelpRequestsPage = () => {
  const { t } = useTranslation()
  const { isConsumer, isProvider } = useContext(AuthContext)

  return (
    <>
      <Flex flexDir="column" width="100%">
        <Helmet>
          <title>{t('portal.sidebar.provider.helpRequests')}</title>
        </Helmet>
        <ContentPageHeader
          label={
            isConsumer
              ? `${t('portal.sidebar.menu.helpRequests').toUpperCase()}`
              : `${t('portal.sidebar.provider.helpRequests').toUpperCase()}`
          }
          h={32}
          pt={4}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          backgroundImgSrc={headerBackground}
          bgSize="cover"
        />
      </Flex>

      <Flex
        flexDir="column"
        backgroundColor="white"
        py={6}
        mx={{ xxs: '0%', tablet: '2%' }}
        maxW="container.md"
        bg="primaryBackground"
      >
        {isConsumer && <MyHelpRequestSection />}
        {isProvider && (
          <>
            <Text
              textStyle="infoTextLight"
              mt={2}
              mb={8}
              px={{ xxs: 2, tablet: 0 }}
            >
              Här hittar du dina inkomna förfrågningar. Du kan skicka meddelande
              och föra diskussioner med kund för att komma fram till en
              överenskommelse. Under sektionen "offert" kan du lämna din offert
              eller förslag till uppgörelse. Detta kan vara en formell offert,
              en uppgörelse om fastpris, löpande eller annat. Kunden godkänner
              eller avslår sedan din offert, och ni får en tydlig dokumentation
              på överenskommelsen.
            </Text>
            <ProviderHelpRequestSection />
          </>
        )}
      </Flex>
    </>
  )
}
export default ProviderHelpRequestsPage
