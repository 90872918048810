import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
// import { useOrganizations } from '../../../apiClients/organizationsApiClient'
import { Organization } from '@contracts/misc'
import {
  PATH_ADMIN_PAGES,
  PATH_ADMIN_PAGES_FIND_EXPERT,
  PATH_MY_PAGES_FIND_EXPERT,
} from '../../../common/nav'
import OrganizationPublicCard from '../../organizations/ProviderProfilePublicCard'
// import { Organization } from '@contracts/misc'

const ListProviderProfilesSection: React.FC<{
  organizations: Organization[] | undefined
  mt?: number | string
  mr?: number | string
  mx?: ResponsiveValue<number | string>
  mb?: number | string
  ml?: number | string
}> = ({ organizations, mt, mr, mb, ml, mx }) => {
  const { pathname } = useLocation()
  let linkPath = ''
  if (pathname.startsWith(PATH_ADMIN_PAGES)) {
    linkPath = PATH_ADMIN_PAGES_FIND_EXPERT
  } else {
    linkPath = PATH_MY_PAGES_FIND_EXPERT
  }

  return (
    <Flex flexDir="column" mt={mt} mr={mr} mb={mb} ml={ml} px={mx} gap={6}>
      {organizations?.map(org => {
        return <OrganizationPublicCard org={org} key={org.id} path={linkPath} />
      })}
      {!organizations?.length && (
        <Text>Inga experter matchar ditt filtrer, bredda din sökning...</Text>
      )}
    </Flex>
  )
}
export default ListProviderProfilesSection
