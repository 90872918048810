import { Badge, Box, Flex, Text, Image } from '@chakra-ui/react'
import _ from 'lodash'
import { Note } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import StarIcon from '../ui/StarIcon'
import { getDateStrFromNumber } from '../../common/util'
import Heading from '../ui/Heading'
import { Icon } from '../ui/Icon'
import log from '../../common/log'

import { useArea } from '../../common/area-context'
import { useNoteParts } from './notesHelper'
import { getImageForNote } from './getImageForNote'
import NoteCardMediumButton from './NoteCardMediumButton'
import NoteCardContentButton from './NoteCardContentButton'
import NoteStatus from './NoteStatus'
import fallbackNoteCardCover from './../../img/notes/fallback_note-card-cover.png'

const NoteCardCompact: React.FC<{
  note: Note
  propertyId?: string
  projectId?: string
}> = ({ note, propertyId, projectId }) => {
  const { t } = useTranslation()
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const { mutate, noteTypes, toggleStar } = useNoteParts({
    propertyId,
    projectId,
  })
  const paddingX = 4
  const borderRadius = 8

  const toggleStarHandlerLocal = async () => {
    await toggleStar(note).then(() => {
      mutate ? void mutate() : ''
    })
  }
  const w = 60
  const h = '120px'
  const headerW = 48
  return (
    <NoteCardMediumButton
      note={note}
      propertyId={propertyId}
      projectId={projectId}
    >
      <Box
        background="white"
        width={w}
        minW={60}
        borderRadius={borderRadius}
        boxShadow="lg"
        pt={4}
        position="relative"
        _hover={{
          boxShadow: 'dark-lg',
          transform: 'translate(-1px,-1px)',
          cursor: 'pointer',
        }}
        id={`note-card-compact-${note.id}`}
      >
        <Heading
          ml={4}
          textStyle="textMediumFixed"
          overflow="clip"
          w={headerW}
          h={6}
        >
          {_.truncate(note.name, { length: 28 })}
        </Heading>
        <Box position="absolute" top={1} right={1}>
          <StarIcon
            isStarred={note.starred}
            onClick={() => {
              void toggleStarHandlerLocal()
            }}
          />
        </Box>
        <Flex
          flexDir="row"
          wrap="nowrap"
          mt={2}
          px={paddingX}
          overflow="clip"
          mr={4}
          h={8}
        >
          {note.noteTypeIds?.map(noteTypeId => {
            return (
              <Badge
                key={noteTypeId}
                py={0.1}
                px={1}
                mt={1}
                mr={1}
                borderWidth={3}
                borderColor="gray.300"
                borderRadius="md"
                fontWeight="light"
                h={6}
              >
                {
                  noteTypes?.find(nt => {
                    return nt.id === noteTypeId
                  })?.name
                }
              </Badge>
            )
          })}
        </Flex>
        <Flex flexDir="row" justifyContent="space-between" alignItems="center">
          <Text py={2} fontSize={12} px={paddingX}>
            {note.noteTimestamp
              ? getDateStrFromNumber(note?.noteTimestamp)
              : ''}
          </Text>
          {note.status && <NoteStatus status={note.status} />}
          <NoteCardContentButton
            note={note}
            propertyId={propertyId ?? ''}
            projectId={projectId ?? ''}
          />
        </Flex>

        {getImageForNote(note) && (
          <Image
            w={w}
            h={h}
            src={getImageForNote(note)}
            objectFit="cover"
            borderBottomRadius={8}
            fallbackSrc={fallbackNoteCardCover}
          />
        )}
        {!getImageForNote(note) && (
          <Flex
            flexDir="row"
            w={w}
            h={h}
            bg={
              noteArea.area === 'property'
                ? 'logo_yellow_light'
                : 'logo_pink_light'
            }
            px={4}
            justifyContent="space-between"
            alignItems="center"
            columnGap={4}
            borderBottomRadius="lg"
          >
            <Icon name="camera" h={32} />
            <Text textStyle="lgLight">{t('notes.noCoverAttachment')}</Text>
          </Flex>
        )}
        {note.attachmentsJson && note.attachmentsJson?.length > 0 && (
          <Box pos="absolute" bottom={1} right={1}>
            <Icon name="attachmentWithBackground" h={6} />
          </Box>
        )}
      </Box>
    </NoteCardMediumButton>
  )
}
export default NoteCardCompact
