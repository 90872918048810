import { useContext, useEffect, useState } from 'react'
import { Container, Flex, Text } from '@chakra-ui/react'

import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AccountData } from '@contracts/misc'
import AuthContext from '../../common/auth-context'
import { confirmVerifyEmail } from '../../apiClients/accountsApiClient'
import Spinner from '../../components/Spinner'
import Alert from '../../components/ui/Alert'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'
import LoginAndRegistrationPage from './LoginAndRegistrationPage'

const VerifyEmailPage = () => {
  const [search] = useSearchParams()
  const [verified, setVerified] = useState(false)
  const [isBusy, setBusy] = useState(true)
  const { currentUser, isLoggedIn, setCurrentUser } = useContext(AuthContext)
  const { t } = useTranslation()

  const userId = search.get('userId')
  const emailVerificationCode = search.get('emailVerifyCode')

  const userOk = () => {
    return isLoggedIn && currentUser?.id === userId
  }

  useEffect(() => {
    if (userOk()) {
      if (currentUser?.status === 'Verified') {
        setVerified(true)
        setBusy(false)
      } else {
        if (currentUser) {
          confirmVerifyEmail(emailVerificationCode || '')
            .then(() => {
              setVerified(true)

              const updatedUser: AccountData = {
                id: currentUser?.id,
                firstName: currentUser?.firstName,
                lastName: currentUser?.lastName,
                email: currentUser?.email,
                status: 'Verified',
                createdAt: currentUser?.createdAt,
                updatedAt: currentUser?.updatedAt,
                accountTypes: currentUser.accountTypes,
                profileAttachmentId: currentUser.profileAttachmentId,
              }
              // Call login to update session currentUser - both for client and for server communication
              setCurrentUser(updatedUser)
            })
            .catch(() => {
              setVerified(false)
            })
            .finally(() => {
              setBusy(false)
            })
        }
      }
    } else {
      setBusy(false)
    }
  }, [currentUser])

  if (isBusy) {
    return <Spinner />
  }

  // URI is corrupt
  if (!userId || !emailVerificationCode) {
    return (
      <Container alignContent="center" textAlign="center" mt="10%">
        <Helmet>
          <title>{t('accounts.verifyEmail')}</title>
        </Helmet>
        <Alert
          variant="Warning"
          title={t('accounts.verifyEmailTokenFailedTitle')}
          message={t('accounts.verifyEmailTokenFailedMessage')}
          forwardUri={PATH_MY_PRIVATE_PAGES}
        />
      </Container>
    )
  }
  if (!isLoggedIn || (isLoggedIn && !userOk())) {
    return (
      <Flex justifyContent="space-around">
        <Helmet>
          <title>{t('accounts.verifyEmail')}</title>
        </Helmet>
        <Flex
          flexDir="column"
          textAlign="center"
          mt="10%"
          justifyContent="space-around"
          justifyItems="center"
          w={{ xxs: '100%', tablet: 'lg' }}
        >
          <Text textStyle="h6" mb={6} px={2}>
            {t('accounts.verifyEmailNotLoggedIn')}
          </Text>
          <Flex justifyContent="space-around">
            <LoginAndRegistrationPage
              forwardUri={`?userId=${userId}&emailVerifyCode=${emailVerificationCode}`}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Container alignContent="center" textAlign="center" mt="10%">
      {/* Success */}
      {verified && (
        <Alert
          variant="Success"
          title={t('accounts.verifyEmailCongrats')}
          message={t('accounts.verifyEmailOk')}
          forwardUri={PATH_MY_PRIVATE_PAGES}
        />
      )}
      {/* Failed to verify token. */}
      {!verified && userOk() && (
        <Alert
          variant="Warning"
          title={t('accounts.verifyEmailTokenFailedTitle')}
          message={t('accounts.verifyEmailTokenFailedMessage')}
          forwardUri={PATH_MY_PRIVATE_PAGES}
        />
      )}
    </Container>
  )
}
export default VerifyEmailPage
