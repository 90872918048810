import { Box, Button, Flex, Radio, Text, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { AccountRelation, AccountTypeName } from '@contracts/misc'

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import RadioGroup from '../ui/RadioGroup'
import {
  addOrganizationAccount,
  updateOrganizationAccount,
  useOrganizationAccounts,
} from '../../apiClients/organizationsApiClient'
import log from '../../common/log'

import { useAccountInvites } from '../../apiClients/accountsApiClient'

interface FormValues {
  accountType: AccountTypeName
  userEmail: string
  accessRights: AccountRelation
}
interface Errors {
  userEmail?: string
  accessRights?: string
}

const AddOrEditOrgAccount: React.FC<{
  onCloseModal: () => void
  orgId: string
  orgAccountId?: string
  accountEmail?: string
  isEdit?: boolean
}> = ({
  orgId,
  orgAccountId,
  onCloseModal,

  isEdit,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const applicationInsights = useAppInsightsContext()

  const { data: orgAccounts, mutate: mutateOrgAccounts } =
    useOrganizationAccounts(orgId)
  const { mutate: mutateOrgInvitations } = useAccountInvites(
    'Organization',
    orgId,
  )
  const thisOrgAccount = orgAccounts?.find(orgAccount => {
    return orgAccount.accountId === orgAccountId
  })

  if (!orgId) {
    return null
  }

  const validate = (values: FormValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.userEmail,
      t('accounts.usersEmail'),
      2,
      100,
      true,
    )
    if (!tmpRes.isValid) {
      errors.userEmail = tmpRes.errorMsg
    }
    return errors
  }

  const initialValues: FormValues = {
    accountType: 'Provider',
    userEmail: thisOrgAccount?.account?.email ?? '',
    accessRights: thisOrgAccount?.accountRelation ?? 'Viewer',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      if (!orgAccountId) {
        // Add collaborator
        const newCollaboratorRequest = {
          accountEmail: values.userEmail,
          accountRelation: values.accessRights,
          accountType: values.accountType,
        }

        addOrganizationAccount(orgId, newCollaboratorRequest)
          .then(status => {
            // eslint-disable-next-line no-console
            console.log('status: ', status)

            applicationInsights.trackEvent(
              {
                name: 'Added organization account',
              },
              newCollaboratorRequest,
            )

            toast({
              title: t('providers.addOrgAccountSuccessMessage'),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })

            // void mutateConversation()

            void mutateOrgAccounts()
            void mutateOrgInvitations()

            onCloseModal()
          })
          .catch(e => {
            if (e.response.status === 400) {
              log.error(
                { err: e },
                'Failed to add collaborator. Email address is already member of another organization.',
              )
              toast({
                title: t('providers.addOrgAccountAleadyMemberFailureMessage', {
                  name: newCollaboratorRequest.accountEmail,
                }),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
              })
            } else {
              log.error({ err: e }, 'Failed to add collaborator')
              toast({
                title: t('providers.addOrgAccountFailureMessage', {
                  name: newCollaboratorRequest.accountEmail,
                }),
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
              })
            }
          })
          .finally(() => {
            setSubmitting(false)
          })
      } else {
        // Edit collaborator
        const collaboratorRequest = {
          orgAccountId,
          accountRelation: values.accessRights,
          accountType: values.accountType,
        }

        updateOrganizationAccount(orgId, orgAccountId, collaboratorRequest)
          .then(() => {
            applicationInsights.trackEvent(
              {
                name: 'Updated account organization',
              },
              collaboratorRequest,
            )

            toast({
              title: t('collaborators.updateSuccessMessage', {
                // name: thisCollaborator?.account?.email,
                name: orgAccountId,
              }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateOrgAccounts()
            onCloseModal()
          })
          .catch(e => {
            log.error({ err: e }, 'Failed to update collaborator')
            toast({
              title: t('collaborators.updateFailureMessage', {
                name: orgAccountId,
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateOrgAccounts()
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="add-edit-collaborator">
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">
          {t(`providers.${isEdit ? 'edit' : 'add'}OrgUser`)} -{' '}
        </Box>
      </Box>
      <Flex flexDir="column" mb={4} mx={6}>
        {!orgAccountId && (
          <>
            <Input
              title={t(
                isEdit
                  ? 'accounts.accountForm.email'
                  : 'providers.newOrgAccountEmail',
              )}
              name="userEmail"
              type="email"
              defaultValue={thisOrgAccount?.account?.email}
              isMandatory={true}
              isError={!!errors.userEmail}
              errorText={
                touched.userEmail && errors.userEmail ? errors.userEmail : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              position="single"
              mt={3}
            />
            <Text ml="1rem" textStyle="infoSmall">
              *{t('input.mandatoryFields')}
            </Text>
          </>
        )}
        <RadioGroup
          title={t('providers.accessRights.accessRights')}
          value={formikValues.accessRights}
          tooltip={t('providers.accessRights.tooltip')}
          isMandatory={true}
        >
          <>
            <Radio
              key="Owner"
              name="accessRights"
              value="Owner"
              padding=".5rem 1rem"
              onChange={handleChange}
            >
              {t('providers.accessRights.owner')}
            </Radio>
            <Radio
              key="Editor"
              name="accessRights"
              value="Editor"
              padding=".5rem 1rem"
              onChange={handleChange}
            >
              {t('providers.accessRights.editor')}
            </Radio>
            <Radio
              key="Viewer"
              name="accessRights"
              value="Viewer"
              padding=".5rem 1rem"
              onChange={handleChange}
            >
              {t('providers.accessRights.viewer')}
            </Radio>
          </>
        </RadioGroup>
        <Button
          type="submit"
          margin="1rem 0"
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
          disabled={isLoading}
        >
          {t(
            isEdit
              ? 'ui.button.update'
              : 'properties.sendCollaboratorInvitation',
          )}
        </Button>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}
export default AddOrEditOrgAccount
