import {
  Button,
  ResponsiveValue,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import AddOrganizationCollaborator from '../collaborators/AddOrganizationCollaborator'
import { Icon } from './Icon'
import Modal from './Modal'

const AddOrganizationCollaboratorButton: React.FC<{
  mt?: number
  w?: ResponsiveValue<number | string>
  children?: ReactElement
  variant: 'BUTTON' | 'ICON'
  isDisabled?: boolean
  tooltip?: string
}> = ({ mt, children, w, variant, isDisabled, tooltip }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (variant === 'BUTTON') {
    return (
      <>
        <Tooltip label={tooltip}>
          <Button
            onClick={onOpen}
            mt={mt}
            variant="secondary"
            w={w || 32}
            disabled={isDisabled}
            size="sm"
          >
            {children}
          </Button>
        </Tooltip>

        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <AddOrganizationCollaborator onCloseModal={onClose} />
        </Modal>
      </>
    )
  }

  if (variant === 'ICON') {
    return (
      <>
        <Icon
          name="IoAddCircleOutline"
          _hover={{
            color: 'gray',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
          color={isDisabled ? 'gray.300' : 'primary_orange02'}
          onClick={isDisabled ? undefined : onOpen}
          isDisabled={isDisabled}
          tooltip={tooltip}
        />
        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <AddOrganizationCollaborator onCloseModal={onClose} />
        </Modal>
      </>
    )
  }
  return null
}
export default AddOrganizationCollaboratorButton
