import { filter, orderBy } from 'lodash'
import { AccountData } from '@contracts/misc'
import { AccountSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: AccountSortingOptionType,
  accounts?: AccountData[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption
  const filteredAccounts = searchStr
    ? filter(accounts, ({ firstName, lastName, email }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return (
          firstName.toLowerCase().includes(searchStrLowercase) ||
          lastName.toLowerCase().includes(searchStrLowercase) ||
          email.toLowerCase().includes(searchStrLowercase)
        )
      })
    : accounts

  return orderBy(filteredAccounts, sortingField, sortOrder)
}
