import { Box, Button, Flex, Radio, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useContext } from 'react'
import { CreateOrganizationCollaboratorRequest } from '@contracts/accounts'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import {
  addOrganizationCollaborator,
  useOrganizationCollaborators,
} from '../../apiClients/organizationsCollaboratorApiClient'
import AuthContext from '../../common/auth-context'
import RadioGroup from '../ui/RadioGroup'

interface FormValues {
  email: string
  isPartner: string
}
interface Errors {
  email?: string
}

const AddOrganizationCollaborator: React.FC<{
  onCloseModal: () => void
}> = ({ onCloseModal }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const applicationInsights = useAppInsightsContext()
  const { currentOrganization } = useContext(AuthContext)
  const { mutate: mutateOrganizationCollaborators } =
    useOrganizationCollaborators(currentOrganization?.id)

  const validate = (values: FormValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.email,
      t('accounts.usersEmail'),
      2,
      100,
      true,
    )
    if (!tmpRes.isValid) {
      errors.email = tmpRes.errorMsg
    }
    return errors
  }

  const initialValues: FormValues = {
    email: '',
    isPartner: 'No',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const newCollaboratorRequest: CreateOrganizationCollaboratorRequest = {
        email: values.email,
        partner: values.isPartner === 'Yes',
        endorse: false,
      }

      addOrganizationCollaborator(
        currentOrganization?.id ?? '',
        newCollaboratorRequest,
      )
        .then(() => {
          applicationInsights.trackEvent(
            {
              name: 'Added organization collaborator',
            },
            newCollaboratorRequest,
          )

          toast({
            title: t(
              'organizationCollaborators.addCollaboratorSuccessMessage',
              { name: newCollaboratorRequest.email },
            ),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          void mutateOrganizationCollaborators()

          onCloseModal()
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to add collaborator')
          toast({
            title: t(
              'organizationCollaborators.addCollaboratorFailureMessage',
              {
                name: newCollaboratorRequest.email,
              },
            ),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="add-edit-collaborator">
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">
          {t('organizationCollaborators.addCollaborator')}
        </Box>
      </Box>
      <Flex flexDir="column" mb={4} mx={6}>
        <>
          <Input
            title={t('accounts.accountForm.email')}
            name="email"
            type="email"
            // defaultValue={thisCollaborator?.account?.email}
            isMandatory={true}
            isError={!!errors.email}
            errorText={touched.email && errors.email ? errors.email : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="single"
            mt={3}
          />

          <RadioGroup
            title={t('organizationCollaborators.isPartnerQuestion')}
            // message={t('collaborators.addAccountTypeForPropertyMessage')}
            value={formikValues.isPartner}
            // value={formikValues.isPartner ? 'Yes' : 'No'}
          >
            <Flex gap={8}>
              <Radio name="isPartner" value="Yes" onChange={handleChange}>
                Ja
              </Radio>
              <Radio name="isPartner" value="No" onChange={handleChange}>
                Nej
              </Radio>
            </Flex>
          </RadioGroup>
        </>

        <Button
          type="submit"
          my={4}
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
          disabled={isLoading}
        >
          {t('ui.button.add')}
        </Button>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}
export default AddOrganizationCollaborator
