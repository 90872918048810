import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ListHelpRequests from './ListHelpRequests'

const ManagHelpRequests = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const helpRequestId = search.get('helpRequest')
  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('admin.manageHelpRequests')}</title>
      </Helmet>
      <ListHelpRequests searchHelpRequestId={helpRequestId ?? undefined} />
    </Box>
  )
}
export default ManagHelpRequests
