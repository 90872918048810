import {
  Flex,
  useDisclosure,
  Image,
  ResponsiveValue,
  Text,
  Box,
} from '@chakra-ui/react'
import { InstagramPostChild } from '@contracts/accounts'
import { ReactElement } from 'react'

import { getFriendlyShortDateStrFromNumber } from '../../common/util'
import GalleryCard from '../ui/GalleryCard'
import Modal from '../ui/Modal'
import { Icon } from '../ui/Icon'

const InstaPostCardModal: React.FC<{
  src: string
  caption: string
  variant: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM'
  timestamp: number
  children: ReactElement
  w?: ResponsiveValue<string | number>
  h?: ResponsiveValue<string | number>
  carouselChildren?: InstagramPostChild[]
}> = ({
  src,
  caption,
  timestamp,
  variant,
  children,
  w,
  h,
  carouselChildren,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex onClick={onOpen} cursor="pointer">
      {children}
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        mb={0}
        px={0}
        py={0}
        pt={0}
        size="md"
        fixedSize
      >
        <Flex flexDir="column">
          {variant === 'IMAGE' && (
            <Image src={src} w={w} h={h} borderTopRadius="lg" />
          )}
          {variant === 'VIDEO' && (
            <Box pt="42px">
              <video controls width="100%">
                <source src={src} />
              </video>
            </Box>
          )}
          {variant === 'CAROUSEL_ALBUM' && carouselChildren?.length && (
            <Box pos="relative">
              <GalleryCard items={carouselChildren} showNav={true} />
              <Box
                pos="absolute"
                top={2}
                right={2}
                bg="gray.100"
                opacity={0.6}
                _hover={{ background: 'gray.300' }}
                borderRadius="lg"
                p={1}
              >
                <Icon name={'AiOutlineClose'} onClick={onClose} h={20} />
              </Box>
            </Box>
          )}
          <Flex
            bg="white"
            borderBottomRadius="lg"
            px={2}
            py={2}
            flexDir="column"
          >
            <Text textStyle="textSmall" textAlign="right">
              {getFriendlyShortDateStrFromNumber(timestamp)}
            </Text>
            <Text whiteSpace="break-spaces">{caption}</Text>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}
export default InstaPostCardModal
