import { Box, Button, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { loadClimateData } from '../../apiClients/climateApiClient'
import log from '../../common/log'
import Spinner from '../../components/Spinner'
import Divider from '../../components/ui/Divider'
import { Heading } from '../../ui'

const ManageSystem = () => {
  const { t } = useTranslation()
  const [categories, setCategories] = useState(0)
  const [resources, setResources] = useState(0)
  const [status, setStatus] = useState<'Success' | 'Error' | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [statusText, setStatusText] = useState(
    `${t('admin.system.pressButtonToDownloadData')}`,
  )
  return (
    <div>
      <Box pb={40}>
        <Heading textStyle="h3">System</Heading>
      </Box>
      <Divider />
      <Box h={60} zIndex="hide">
        <Text mt={4} textStyle="h6">
          {t('admin.system.fetchDataFromBoverket')}
        </Text>
        <Text>{statusText}</Text>
        <Button
          bg="secondary"
          color="white"
          my={6}
          onClick={() => {
            setIsLoading(true)
            loadClimateData()
              .then(({ data }) => {
                setCategories(data.categories)
                setResources(data.resources)
                setStatusText(t('admin.system.downloadSuccess'))
                setStatus('Success')
              })
              .catch(err => {
                log.error({ err: err }, 'Error loading climate data')
                setStatusText(t('admin.system.failedToDownloadData'))
                setStatus('Error')
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        >
          {t('admin.system.downloadData')}
        </Button>
        {status && (
          <>
            <Text>
              {t('admin.system.downloadedCategories')}: {categories}
            </Text>
            <Text>
              {t('admin.system.downloadedProducts')}: {resources}
            </Text>
          </>
        )}
        {isLoading && <Spinner top="470px" left="60px" />}
      </Box>
      <Divider />
    </div>
  )
}
export default ManageSystem
