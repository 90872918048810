import { Flex, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import log from '../../common/log'
import Modal from '../ui/Modal'
import AccountProfileCard from './AccountProfileCard'
import OrganizationProfileCard from './OrganizationProfileCard'

const AccountProfileCardModal: React.FC<{
  accountId?: string
  organizationId?: string
  variant: 'userAccount' | 'organizationAccount'
  children: ReactElement
}> = ({ accountId, organizationId, children, variant }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  // if (variant === 'userAccount' && !accountId) {
  //   log.error(
  //     "Failed to render ProfileCard - variant 'account' needs to come with an account id",
  //   )
  //   return null
  // }
  if (variant === 'organizationAccount' && !organizationId) {
    log.error(
      "Failed to render ProfileCard - variant 'organization' needs to come with an organization id",
    )
    return null
  }

  return (
    <Flex onClick={onOpen} cursor="pointer">
      {children}
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        mb={0}
        px={0}
        py={0}
        pt={0}
        size="md"
        fixedSize
      >
        <>
          {variant === 'userAccount' && accountId && (
            <AccountProfileCard accountId={accountId} />
          )}
          {variant === 'organizationAccount' && organizationId && (
            <OrganizationProfileCard organizationId={organizationId} />
          )}
        </>
      </Modal>
    </Flex>
  )
}
export default AccountProfileCardModal
