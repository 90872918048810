import { Flex } from '@chakra-ui/react'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { AccountData } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import { useCollaborators as usePropertyCollaborators } from '../../apiClients/propertiesApiClient'
import { useCollaborators as useProjectCollaborators } from '../../apiClients/projectsApiClient'
import { useArea } from '../../common/area-context'
import Spinner from '../Spinner'
import { useProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import { useAccountsByAccountIds } from '../../apiClients/accountsApiClient'
import {
  useHelpRequest,
  useHelpRequestsTemplate,
} from '../../apiClients/projectsApiClient/helpRequests'
import { MentionItem } from './MentionItem'

type MentionListProps = SuggestionProps

interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void
}

export const MentionList = forwardRef<MentionListActions, MentionListProps>(
  // ({ clientRect, command, query }, ref) => {
  ({ decorationNode, command }, ref) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    // const referenceEl = useMemo(
    //   () => (clientRect ? { getBoundingClientRect: clientRect } : null),
    //   [clientRect],
    // )
    // const currentUser = useContext(AuthContext)
    // const isMounted = useIsMounted()
    // const [people, setPeople] = useState<Person[]>([])
    const [accounts, setAccounts] = useState<
      (AccountData | undefined)[] | undefined
    >([])

    const { area, areaId } = useArea()

    // eslint-disable-next-line no-console
    // console.log('area: ', area)
    // eslint-disable-next-line no-console
    // console.log('areaId: ', areaId)

    const { data: propertyCollaborators } = usePropertyCollaborators(
      area === 'property' ? areaId : undefined,
    )
    const { data: projectCollaborators } = useProjectCollaborators(
      area === 'project' ? areaId : undefined,
    )
    const collaborators =
      area === 'property' ? propertyCollaborators : projectCollaborators

    // Handle case for area === 'projectPlace'
    const { data: projectPlace } = useProjectPlace(
      area === 'projectPlace' ? areaId : undefined,
    )
    const { data: orgAccounts } = useOrganizationAccounts(
      area === 'projectPlace' ? projectPlace?.organizationId : undefined,
    )

    const { data: helpReq } = useHelpRequest(
      area === 'helpRequest' ? areaId : undefined,
    )

    const { data: orgAccountsForHelpRequest } = useOrganizationAccounts(
      helpReq?.organizationId,
    )

    const { data: helpRequestTemplate } = useHelpRequestsTemplate(
      helpReq?.helpRequestTemplateId,
    )
    const { data: helpRequestClients } = useProjectCollaborators(
      area === 'helpRequest' ? helpRequestTemplate?.projectId : undefined,
    )

    let userIds: string[] | undefined
    if (area === 'projectPlace') {
      userIds = orgAccounts?.map(orgAccount => {
        return orgAccount.accountId
      })
    } else if (area === 'helpRequest') {
      userIds =
        orgAccountsForHelpRequest?.map(orgAccount => {
          return orgAccount.accountId
        }) ?? []
      const clientIds =
        helpRequestClients?.filter(client => {
          return (
            client.accountRelation === 'Owner' &&
            client.accountType === 'Consumer'
          )
        }) ?? []

      userIds = [
        ...userIds,
        ...clientIds.map(client => {
          return client.accountId
        }),
      ]
    } else {
      userIds = collaborators?.map(collab => {
        return collab.accountId
      })
    }

    const { data: accountsData } = useAccountsByAccountIds(userIds)

    useEffect(() => {
      if (
        !accountsData?.find(account => {
          return account.id === 'MENTION_ALL'
        })
      ) {
        // Only add MENTION_ALL once
        accountsData?.push({
          id: 'MENTION_ALL',
          firstName: t('messages.mention.all'),
          lastName: '',
          updatedAt: 0,
          createdAt: 0,
          email: '',
          status: 'New',
        })
      }
      setAccounts(accountsData)
      setIsLoading(false)
    }, [accountsData])

    const handleCommand = (index: number) => {
      let selectedAccount: AccountData | undefined
      if (accounts && accounts[index]) {
        selectedAccount = accounts[index]
      }

      command({
        id: selectedAccount?.id,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        label: `${selectedAccount?.firstName} ${selectedAccount?.lastName}`,
      })
    }

    const [hoverIndex, setHoverIndex] = useState(0)
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        const { key } = event

        if (key === 'ArrowUp') {
          setHoverIndex(prev => {
            const beforeIndex = prev - 1
            return beforeIndex >= 0 ? beforeIndex : 0
          })
          return true
        }

        if (key === 'ArrowDown') {
          setHoverIndex(prev => {
            const afterIndex = prev + 1
            const accountCount = accounts ? accounts.length - 1 : 0
            return afterIndex < accountCount ? afterIndex : accountCount
          })
          return true
        }

        if (key === 'Enter') {
          handleCommand(hoverIndex)
          return true
        }

        return false
      },
    }))

    const [el, setEl] = useState<HTMLDivElement | null>(null)

    const { styles, attributes } = usePopper(decorationNode, el, {
      placement: 'bottom-start',
    })

    return createPortal(
      <Flex
        objectPosition=""
        flexDir="column"
        ref={setEl}
        // className="mentionsContainer"
        style={styles.popper}
        {...attributes.popper}
        // border="1px solid green"

        minW={44}
        borderRadius="lg"
        border="1px solid"
        borderColor="gray.300"
        boxShadow="lg"
        bg="#ffffff"
        zIndex="popover"
      >
        {!isLoading &&
          accounts &&
          accounts.map((account, index) => (
            <MentionItem
              key={account?.id ?? index}
              isActive={index === hoverIndex}
              onMouseEnter={() => setHoverIndex(index)}
              onClick={() => handleCommand(index)}
            >
              {account?.firstName} {account?.lastName}
            </MentionItem>
          ))}
        {/* accountsData?.push({
        id: 'MENTION_ALL',
        firstName: t('messages.mention.all'),
        lastName: '',
        updatedAt: 0,
        createdAt: 0,
        email: '',
        status: 'New',
      }) */}
        {isLoading && <Spinner />}
      </Flex>,
      document.body,
    )
  },
)
