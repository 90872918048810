import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import { useContext } from 'react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerArchivedProjects.png'
import ListProjectPlaces from '../admin/ListProjectPlaces'
import AuthContext from '../../common/auth-context'

const ProviderProjectPlacesPage = () => {
  const { t } = useTranslation()
  const { currentOrganization } = useContext(AuthContext)

  return (
    <>
      <Flex flexDir="column" width="100%" bg="primary_grey01">
        <Helmet>
          <title>{t('portal.sidebar.provider.myProjectPlaces')}</title>
        </Helmet>
        <ContentPageHeader
          label={`${t(
            'portal.sidebar.provider.myProjectPlaces',
          ).toUpperCase()}`}
          h={32}
          pt={4}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          bgSize="contain"
          backgroundImgSrc={headerBackground}
        />
      </Flex>

      <ListProjectPlaces
        variant="provider"
        organizationId={currentOrganization?.id}
        px={{ xxs: '1%', tablet: '3%' }}
        mt={20}
      />
    </>
  )
}
export default ProviderProjectPlacesPage
