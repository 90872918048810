import { Button, useDisclosure } from '@chakra-ui/react'
import { PropertyResponse } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'
import { ReactElement, useContext } from 'react'
import { KeyedMutator } from 'swr'
import AuthContext from '../../common/auth-context'
import AddProject from '../projects/AddProject'
import AddProjectPlace from '../projects/AddProjectPlace'
import { Icon } from './Icon'
import Modal from './Modal'

const AddProjectButton: React.FC<{
  mt?: number
  w?: number
  properties: PropertyResponse[]
  children?: ReactElement
  variant: 'BUTTON' | 'ICON'
  disabled?: boolean
  noForwardRedirect?: boolean
  mutateProp?: KeyedMutator<ProjectResponse[] | undefined>
}> = ({
  mt,
  children,
  w,
  properties,
  variant,
  disabled,
  noForwardRedirect,
  mutateProp,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isProvider } = useContext(AuthContext)

  if (variant === 'BUTTON') {
    return (
      <>
        <Button
          onClick={onOpen}
          mt={mt}
          variant="secondary"
          w={w || 32}
          disabled={disabled}
        >
          {children}
        </Button>

        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <>
            {isProvider && <AddProjectPlace onCloseModal={onClose} />}
            {!isProvider && (
              <AddProject
                onCloseModal={onClose}
                properties={properties}
                noForwardRedirect={noForwardRedirect}
                mutateProp={mutateProp}
              />
            )}
          </>
        </Modal>
      </>
    )
  }
  if (variant === 'ICON') {
    return (
      <>
        <Icon
          name="IoAddCircleOutline"
          _hover={{ color: 'gray' }}
          color="primary_orange02"
          onClick={onOpen}
          isDisabled={disabled}
        />
        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <>
            {/* <AddProject
            onCloseModal={onClose}
            properties={properties}
            noForwardRedirect={noForwardRedirect}
          /> */}
            {isProvider && <AddProjectPlace onCloseModal={onClose} />}
            {!isProvider && (
              <AddProject
                onCloseModal={onClose}
                properties={properties}
                noForwardRedirect={noForwardRedirect}
                mutateProp={mutateProp}
              />
            )}
          </>
        </Modal>
      </>
    )
  }
  return null
}
export default AddProjectButton
