import { Flex } from '@chakra-ui/react'

import { useHelpRequestsTemplates } from '../../../apiClients/projectsApiClient/helpRequests'
import ExpandableHelpRequestTemplateCard from '../../helpRequests/ExpandableHelpRequestTemplateCard'

const ProjectHelpRequestTemplateSection: React.FC<{
  projectId: string
  mb?: number | string
}> = ({ projectId, mb }) => {
  const { data: helpRequestTemplates } = useHelpRequestsTemplates(projectId)

  return (
    <Flex flexDir="column" mb={mb} rowGap={4}>
      {helpRequestTemplates &&
        helpRequestTemplates.length > 0 &&
        helpRequestTemplates.map(helpReqTemplate => {
          return (
            <ExpandableHelpRequestTemplateCard
              key={helpReqTemplate.id}
              helpReqTemplate={helpReqTemplate}
            />
          )
        })}
    </Flex>
  )
}
export default ProjectHelpRequestTemplateSection
