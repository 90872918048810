import { AvatarGroup, Flex, ResponsiveValue } from '@chakra-ui/react'
import { useCollaboratorParts } from '../../collaborators/collaboratorHelper'
import AccountAvatar from '../../accounts/AccountAvatar'

const MembersGroup: React.FC<{
  propertyId?: string
  projectId?: string
  mt?: number | string
  pt?: number | string
  mx?: number | string
  variant?: 'section' | 'avatars-lg' | 'avatars-compact'
  border?: string
  position?: 'absolute' | 'relative'
  top?: number
  bottom?: number
  right?: number
  max?: number
  w?: ResponsiveValue<number | string>
}> = ({
  propertyId,
  projectId,
  mx,
  mt,
  pt = 0,
  border,
  position = 'relative',
  top,
  bottom,
  right,
  max = 5,
  w = 64,
}) => {
  const { collaborators } = useCollaboratorParts({
    propertyId,
    projectId,
  })

  return (
    <Flex
      pos={position === 'absolute' ? 'absolute' : 'relative'}
      top={top}
      bottom={bottom}
      right={right}
      flexDir="row"
      justifyContent="right"
      pt={pt}
      mt={mt}
      px={mx}
      border={border}
      columnGap={-2}
      w={w}
    >
      <AvatarGroup size="md" max={max} gap={0.5}>
        {collaborators &&
          collaborators.length > 1 &&
          collaborators.map(collaborator => {
            return (
              <AccountAvatar
                key={collaborator.accountId}
                accountId={collaborator.accountId}
                variant="avatar"
                my={1.5}
              />
            )
          })}
      </AvatarGroup>
    </Flex>
  )
}
export default MembersGroup
