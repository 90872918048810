import { Alert, AlertIcon, Box, Button, Select, Text } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ClimateResource } from '@contracts/support'
import log from '../../common/log'

import Input from '../ui/Input'

import {
  fetchClimateResources,
  useClimateResourceCategories,
} from '../../apiClients/climateApiClient'

import ListItem from './ListItem'
import ProductBadge from './ProductBadge'

const AddCompareClimateImpactProduct: React.FC<{
  onCloseModal: () => void
  propsSelectedProducts?: ClimateResource[]
  onCompare: (updateProductsToCompare: ClimateResource[]) => void
}> = ({ onCloseModal, onCompare, propsSelectedProducts }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { data: climateResourceCategories } = useClimateResourceCategories()
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined)
  const [productName, setProductName] = useState<string | undefined>(undefined)
  const [products, setProducts] = useState<ClimateResource[]>([])
  const [selectedProducts, setSelectedProducts] = useState<ClimateResource[]>(
    propsSelectedProducts ?? [],
  )

  const addSelectProductHandler = (selectedProduct: ClimateResource) => {
    if (
      !selectedProducts.find(product => {
        return product.id === selectedProduct.id
      })
    ) {
      setSelectedProducts([...selectedProducts, selectedProduct])
    }
  }

  const removeSelectedProductHandler = (selectedProduct: ClimateResource) => {
    setSelectedProducts(
      selectedProducts.filter(product => {
        return product.id !== selectedProduct.id
      }),
    )
  }

  const changeCategoryHandler = (value: string) => {
    setCategoryId(value)

    if (value || productName) {
      fetchClimateResources({
        categoryId: value,
        productName: productName,
      })
        .then(({ data }) => {
          if (data) {
            setProducts(data)
          } else {
            setProducts([])
          }
        })
        .catch(error => {
          log.error({ err: error }, 'Error fetching resources')
        })
    }
  }

  const changeProductNameHandler = (value: string) => {
    setProductName(value)

    if (value || categoryId) {
      fetchClimateResources({
        categoryId: categoryId,
        productName: value,
      })
        .then(({ data }) => {
          if (data) {
            setProducts(data)
          } else {
            setProducts([])
          }
        })
        .catch(error => {
          log.error({ err: error }, 'Error fetching resources')
        })
    }
  }

  const submit = () => {
    applicationInsights.trackEvent({ name: 'Added compare climate product' })
    onCompare(selectedProducts)
    onCloseModal()
  }

  return (
    <>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('climate.chooseProducts')}</Box>
      </Box>
      <Box display="flex" flexDirection="column" m="1rem 1.5rem">
        <Input
          title={t('climate.search')}
          name="productName"
          type="text"
          isMandatory={false}
          onChange={e => {
            changeProductNameHandler(e.target.value)
          }}
          position="single"
        />

        <Text mt={6} mb={1}>
          {t('climate.category')}
        </Text>
        <Select
          placeholder={t('climate.chooseCategory')}
          name="category"
          onChange={e => {
            changeCategoryHandler(e.target.value)
          }}
        >
          {climateResourceCategories &&
            climateResourceCategories.map(category => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              )
            })}
        </Select>

        {products && products.length > 0 && (
          <>
            <Text mt={6} mb={1}>
              {t('climate.products')}
            </Text>

            <Box
              border="1px"
              borderColor="gray.300"
              borderRadius="lg"
              p={1}
              maxH={60}
              overflow="scroll"
            >
              {products.map(product => {
                return (
                  <ListItem
                    key={product.id}
                    product={product}
                    onClick={addSelectProductHandler}
                  />
                )
              })}
            </Box>
          </>
        )}

        {products.length === 0 && (
          <Box my={6} textAlign="center">
            {products.length === 0 && !categoryId && !productName && (
              <Alert status="info">
                <AlertIcon />
                {t('climate.enterSearchCriterias')}
              </Alert>
            )}
            {products.length === 0 && (categoryId || productName) && (
              <Alert status="warning">
                <AlertIcon />
                <Text>{t('climate.noProductsFound')}</Text>
              </Alert>
            )}
          </Box>
        )}

        {selectedProducts && selectedProducts.length > 0 && (
          <>
            <Text mt={3}>{t('climate.productsToCompare')}</Text>
            {selectedProducts.map(product => {
              return (
                <ProductBadge
                  key={product.id}
                  product={product}
                  onClick={removeSelectedProductHandler}
                />
              )
            })}
          </>
        )}

        <Button
          mt={10}
          variant={selectedProducts.length > 0 ? 'primary' : 'disabled'}
          onClick={submit}
        >
          {t('climate.compareClimateImpactItems')}
        </Button>
      </Box>
    </>
  )
}
export default AddCompareClimateImpactProduct
