import { Flex, Show, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountRelation, Collaborator } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'
import { PropertyResponse } from '@contracts/misc'

import AuthContext from '../../common/auth-context'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import AddOrEditPropertyCollaboratorButton from '../ui/AddOrEditCollaboratorButton'

import AccountProfileCardModal from '../accounts/AccountProfileCardModal'
import { Icon } from '../ui/Icon'
import { useCollaboratorParts } from './collaboratorHelper'

const CollaboratorCard: React.FC<{
  collaborator: Collaborator
  property?: PropertyResponse
  project?: ProjectResponse
  currentUserAccessRights: AccountRelation
  isOnlyAdmin: boolean
  isOnlyClient: boolean
}> = ({
  property,
  project,
  collaborator,
  currentUserAccessRights,
  isOnlyAdmin,
  isOnlyClient,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const { deleteCollaborator } = useCollaboratorParts({
    propertyId: property?.id,
    projectId: project?.id,
  })
  const account = collaborator.account

  let isAllowedToEdit = true
  let isAllowedToDelete = true
  let tooltipText = ''
  const vSpacing = 4

  if (
    currentUserAccessRights === 'Owner' &&
    currentUser?.status === 'Verified'
  ) {
    if (property && isOnlyAdmin) {
      isAllowedToEdit = false
      isAllowedToDelete = false
      tooltipText = t('accounts.notAllowedToDeleteOnlyPropertyAdminTooltip')
    } else if (project && isOnlyClient) {
      isAllowedToEdit = false
      isAllowedToDelete = false
      tooltipText = t('accounts.notAllowedToDeleteOnlyProjectClientTooltip')
    } else if (project && isOnlyAdmin) {
      isAllowedToEdit = false
      isAllowedToDelete = false
      tooltipText = t('accounts.notAllowedToDeleteOnlyProjectAdminTooltip')
    }
  } else {
    if (
      currentUserAccessRights !== 'Owner' ||
      currentUser?.status !== 'Verified'
    ) {
      isAllowedToEdit = false
      isAllowedToDelete = false
      tooltipText = t('accounts.notAllowedToEditTooltip')
    }
  }

  return (
    <Flex
      flexDir="row"
      borderRadius={8}
      flexWrap="wrap"
      justifyContent={{ xxs: 'left', tablet: 'space-around' }}
      alignItems="center"
      columnGap={8}
      rowGap={8}
    >
      {account && (
        <AccountProfileCardModal accountId={account?.id} variant="userAccount">
          <Flex
            wrap="wrap"
            justifyContent={{ xxs: 'left', tablet: 'space-around' }}
            columnGap={8}
            rowGap={4}
          >
            <Show above="desktop">
              <Flex flexDir="column">
                <Text mr={1}>{t('properties.propertyForm.name')}</Text>
                <Text>
                  {account?.firstName} {account?.lastName}
                </Text>
              </Flex>
            </Show>
            <Flex flexDir="column">
              <Text>{t('accounts.accountForm.email')}</Text>
              <Text>{account?.email}</Text>
            </Flex>
            <Flex flexDir="column">
              <Text>{t('accounts.accountForm.phoneNumber')}</Text>
              <Text>
                {account?.phoneNumber ??
                  t('accounts.accountForm.missingPhoneNumber')}
              </Text>
            </Flex>
          </Flex>
        </AccountProfileCardModal>
      )}
      {!account && (
        <Text mb={vSpacing}>{t('properties.collaboratorNotRegistered')}</Text>
      )}

      <Flex flexDir="row">
        <AddOrEditPropertyCollaboratorButton
          property={property}
          project={project}
          accountId={collaborator.accountId}
          variant={'ICON'}
          isDisabled={!isAllowedToEdit || !isAllowedToDelete}
          tooltip={
            isAllowedToEdit
              ? isAllowedToDelete
                ? ''
                : tooltipText
              : tooltipText
          }
        />
        {isAllowedToDelete && (
          <AlertDialogAndButton
            title={t('collaborators.deleteCollaborator')}
            message={t('collaborators.deleteCollaboratorQuestion', {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              name: `${account?.firstName} ${account?.lastName}`,
            })}
            buttonTitle={t('ui.button.delete')}
            buttonW="100%"
            onDestructiveAction={() => void deleteCollaborator(collaborator)}
            variant="children"
          >
            <Icon
              name="DeleteIcon"
              color="secondary"
              h={5}
              ml={3}
              mt={0.5}
              _hover={{ color: 'gray' }}
              isDisabled={!isAllowedToDelete}
              tooltip={isAllowedToDelete ? '' : tooltipText}
            />
          </AlertDialogAndButton>
        )}
        {!isAllowedToDelete && (
          <Icon
            name="DeleteIcon"
            color="secondary"
            h={5}
            ml={3}
            mt={0.5}
            _hover={{ color: 'gray' }}
            isDisabled={!isAllowedToDelete}
            tooltip={isAllowedToDelete ? '' : tooltipText}
          />
        )}
      </Flex>
    </Flex>
  )
}
export default CollaboratorCard
