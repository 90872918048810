import { Flex, ResponsiveValue } from '@chakra-ui/react'
import Heading from '../ui/Heading'
import { useOrganizationCollaborators } from '../../apiClients/organizationsCollaboratorApiClient'
import ProviderProfileCardModal from './ProviderProfileCardModal'
import OrganizationLogoCard from './OrganizationLogoCard'

const ProviderPartnersSection: React.FC<{
  organizationId: string
  mt?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
}> = ({ organizationId, mt, px }) => {
  const { data: organizationCollaborators } =
    useOrganizationCollaborators(organizationId)

  const collabPartners = organizationCollaborators?.filter(collab => {
    return collab.partner && collab.organizationId
  })

  if (collabPartners?.length) {
    return (
      <Flex
        mt={mt}
        px={px}
        bg="primary_green01"
        justifyContent="space-around"
        pt={5}
        pb={10}
        w="100%"
      >
        <Flex flexDir="column" gap={4} textAlign="center">
          <Heading textStyle="h4">Våra samarbetspartners</Heading>

          <Flex gap={4} wrap="wrap" pt={4} justifyContent="center">
            {collabPartners &&
              collabPartners.length > 0 &&
              collabPartners?.map(partner => {
                if (partner.organizationId) {
                  return (
                    <ProviderProfileCardModal
                      key={partner.id}
                      organizationId={partner.organizationId}
                    >
                      <OrganizationLogoCard
                        organizationId={partner.organizationId}
                        w={{ xxs: 24, tablet: 44 }}
                      />
                    </ProviderProfileCardModal>
                  )
                }
              })}
          </Flex>
        </Flex>
      </Flex>
    )
  } else return null
}
export default ProviderPartnersSection
