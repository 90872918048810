import { Divider, Flex, ResponsiveValue } from '@chakra-ui/react'
import { ProjectPhase } from '@contracts/projects'
import Step from './Step'

const ProjectPhaseStepper: React.FC<{
  currentPhase: ProjectPhase
  ml?: ResponsiveValue<number | string>
  mr?: ResponsiveValue<number | string>
  pt?: ResponsiveValue<number | string>
  variant?: 'show-all-phases' | 'show-3-phases'
  stepVariant?: 'full' | 'mobile'
}> = ({
  currentPhase,
  ml,
  mr,
  pt,
  variant = 'all-phases',
  stepVariant = 'full',
}) => {
  const isPhaseCompleted = (phase: ProjectPhase) => {
    switch (phase) {
      case 'Idea':
        if (currentPhase !== 'Idea') {
          return true
        }
        return false

      case 'Design':
        if (currentPhase === 'Production' || currentPhase === 'Warranty') {
          return true
        }
        return false

      case 'Production':
        if (currentPhase === 'Warranty') {
          return true
        }
        return false

      case 'Warranty':
        return false
    }
  }
  return (
    <Flex alignItems="center" gap={2} ml={ml} mr={mr} pt={pt} w="100%">
      {(variant === 'show-all-phases' ||
        currentPhase === 'Idea' ||
        currentPhase === 'Design') && (
        <Step
          phase="Idea"
          isCompleted={isPhaseCompleted('Idea')}
          isActive={currentPhase === 'Idea'}
          variant={stepVariant}
        />
      )}
      <Divider
        flexBasis={10}
        flexGrow={1}
        flexShrink={1}
        borderColor="primary_orange02"
        border="2px solid"
      />
      <Step
        phase="Design"
        isCompleted={isPhaseCompleted('Design')}
        isActive={currentPhase === 'Design'}
        variant={stepVariant}
      />
      <Divider
        flexBasis={10}
        flexGrow={1}
        flexShrink={1}
        borderColor="primary_orange02"
        border="2px solid"
      />
      <Step
        phase="Production"
        isCompleted={isPhaseCompleted('Production')}
        isActive={currentPhase === 'Production'}
        variant={stepVariant}
      />
      <Divider
        flexBasis={10}
        flexGrow={1}
        flexShrink={1}
        borderColor="primary_orange02"
        border="2px solid"
      />
      {(variant === 'show-all-phases' ||
        currentPhase === 'Production' ||
        currentPhase === 'Warranty') && (
        <Step
          phase="Warranty"
          isCompleted={isPhaseCompleted('Warranty')}
          isActive={currentPhase === 'Warranty'}
          variant={stepVariant}
        />
      )}
    </Flex>
  )
}

export default ProjectPhaseStepper
