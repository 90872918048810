import { OrganizationProfile } from '@contracts/accounts'
import { Organization } from '@contracts/misc'

export const isProfileInfoFilledIn = (
  organizationProfile: OrganizationProfile,
) => {
  if (
    organizationProfile.backgroundAttachmentId?.length &&
    (organizationProfile.consultant || organizationProfile.craftsman) &&
    organizationProfile.profileAttachmentId?.length &&
    organizationProfile.profileText &&
    organizationProfile.serviceTypeIds?.length
  ) {
    return true
  }

  return false
}

export const isMandatoryProviderDataFilledIn = (
  organizationContacts: Organization,
) => {
  if (
    // organizationContacts?.vatNumber &&
    organizationContacts.address?.street &&
    organizationContacts.address.zipCode &&
    organizationContacts.address.city &&
    organizationContacts.email &&
    organizationContacts.phoneNumber
  ) {
    return true
  }
  return false
}
