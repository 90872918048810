/**
 * These are all the feature flags we have
 *
 * Let key and value be the same for these.
 */
export const FEATURE_FLAG = Object.freeze({
  FEATURE_USED_IN_TEST: 'FEATURE_USED_IN_TEST',
  SHOW_PROVIDER: 'SHOW_PROVIDER',
} as const)
export type _FeatureFlagKeys = keyof typeof FEATURE_FLAG
export type FeatureFlagValues = typeof FEATURE_FLAG[_FeatureFlagKeys]

export const ACTIVE_ENVIRONMENTS = Object.freeze({
  dev: 'dev',
  test: 'test',
  prod: 'prod',
} as const)

type EnvironmentKeys = keyof typeof ACTIVE_ENVIRONMENTS
export type EnvironmentValues = typeof ACTIVE_ENVIRONMENTS[EnvironmentKeys]

export interface EnvironmentVariablesType {
  ACCOUNTS_SERVICE_URL: string
  APPLICATIONINSIGHTS_CONNECTION_STRING: string
  AZURE_BLOB_STORAGE_BASE_URL: string
  PROJECTS_SERVICE_URL: string
  PROPERTIES_SERVICE_URL: string
  SUPPORT_SERVICE_URL: string
  ENVIRONMENT: EnvironmentValues
}

// Store overrides in local storage
const localStorageKey = 'berikarFeatureFlagOverrides'
const getFeatureFlagOverrides = (): Record<string, boolean> => {
  const valueFromStorage = localStorage.getItem(localStorageKey)
  if (valueFromStorage) {
    const value = JSON.parse(valueFromStorage)
    if (value) {
      return value
    }
  }
  return {}
}

export const setFeatureFlagOverride = (
  flag: FeatureFlagValues,
  newValue: boolean,
) => {
  const overrides = getFeatureFlagOverrides()
  overrides[flag] = newValue
  localStorage.setItem(localStorageKey, JSON.stringify(overrides))
  window.location.reload()
}

export const clearFeatureFlagOverrides = () => {
  localStorage.setItem(localStorageKey, '')
  window.location.reload()
}

/**
 * Use like this for instance: useFeatureFlagActive(FEATURE_FLAG.SHOW_PROVIDER) && Show_Provider_Stuff
 */
export const useFeatureFlagActive = (
  flag: FeatureFlagValues,
  ignoreOverride = false,
): boolean => {
  const calculated = calculateFeatureFlag(flag)
  const overrides = getFeatureFlagOverrides()
  if (
    !ignoreOverride &&
    (overrides[flag] === true || overrides[flag] === false)
  ) {
    return overrides[flag]
  }
  return calculated
}

/**
 * Here we define the rules for each feature flag
 */
const calculateFeatureFlag = (flag: FeatureFlagValues): boolean => {
  switch (flag) {
    case FEATURE_FLAG.FEATURE_USED_IN_TEST: {
      return ACTIVE_ENVIRONMENTS.prod !== window.ENVIRONMENT
    }
    case FEATURE_FLAG.SHOW_PROVIDER: {
      return ACTIVE_ENVIRONMENTS.prod !== window.ENVIRONMENT
    }
    default:
      return false
  }
}
