import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import RegisterNewProviderSection from '../../components/sections/portal/AddOrEditOrganizationBaseData'

const RegisterProviderPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('providers.registerProvider')}</title>
      </Helmet>
      <RegisterNewProviderSection px="5%" mt={6} maxW="container.md" />
    </>
  )
}
export default RegisterProviderPage
