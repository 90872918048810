import { Box, Flex, Image, ResponsiveValue, Text } from '@chakra-ui/react'
import { ArticleInfo } from '@contracts/support'
import { useNavigate } from 'react-router-dom'
import HtmlPane from '../richTextEditor/HtmlPane'
import fallBackImg from '../../img/articles/rectangle2_md.png'
import { getDateStrFromDate } from '../../common/util'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ArticlePromoMedium: React.FC<{
  articleInfo: ArticleInfo
  path?: string
  mb?: ResponsiveValue<string | number>
  mr?: ResponsiveValue<string | number>
}> = ({ articleInfo, path, mb, mr }) => {
  const navigate = useNavigate()
  const getImgId = () => {
    if (articleInfo.attachmentIdMedium) {
      return articleInfo.attachmentIdMedium
    }
    if (articleInfo.attachmentIdLarge) {
      return articleInfo.attachmentIdLarge
    }
    if (articleInfo.attachmentIdSmall) {
      return articleInfo.attachmentIdSmall
    }
    return undefined
  }
  const imgId = getImgId()

  return (
    <Flex
      flexDir="column"
      w="100%"
      maxW={{ tablet: '100%', laptop: '550px' }}
      onClick={() => {
        if (path) {
          navigate(`/${path}/${articleInfo.id}`)
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      }}
      _hover={{ cursor: 'pointer' }}
      mb={mb}
      mr={mr}
    >
      <Box>
        {imgId && (
          <Image src={`${baseUrl}${imgId}`} h={72} w="xl" objectFit="cover" />
        )}
        {!imgId && <Image src={fallBackImg} objectFit="cover" h={72} w="xl" />}
      </Box>

      <Text mt={4}>
        {articleInfo?.publishedAt
          ? getDateStrFromDate(new Date(articleInfo.publishedAt))
          : ''}
      </Text>
      <Text mt={4} mb={3} textStyle="h4">
        {articleInfo && articleInfo.heading}
      </Text>

      <Box overflow="clip" h={24} maxW="550px">
        {articleInfo && (
          <HtmlPane
            content={articleInfo.intro}
            bg="transparent"
            lineHeight="default"
          />
        )}
      </Box>
    </Flex>
  )
}
export default ArticlePromoMedium
