import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { ingressClimateSmartConstructionPage } from '../../apiClients/contentApiClient'
import ConstructionLifeCycleSection from '../../components/sections/portal/ConstructionLifeCycleSection'
import CompareClimateImpactSection from '../../components/sections/portal/CompareClimateImpactSection'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'

const CompareClimateImpactPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)
  return (
    <>
      <Helmet>
        <title>{t('portal.sidebar.menu.compareClimateImpact')}</title>
      </Helmet>
      <ContentPageHeader
        label={t('portal.sidebar.menu.compareClimateImpact').toUpperCase()}
        ingress={ingressClimateSmartConstructionPage}
        isEmbedded={isWithinMyPages ? false : true}
      />
      <ConstructionLifeCycleSection />
      <CompareClimateImpactSection />
    </>
  )
}
export default CompareClimateImpactPage
