import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sv from './sv.json'

const resources = {
  sv: {
    translation: sv,
  },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: 'sv',
  defaultNS: 'translation',
})

export default i18n
