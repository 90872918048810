import { Avatar, Flex, Text } from '@chakra-ui/react'
import { Author } from '@contracts/support'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const AuthorCard: React.FC<{ author: Author | undefined; ml?: number }> = ({
  author,
  ml,
}) => {
  const { t } = useTranslation()
  return (
    <Flex
      flexDir="column"
      p={4}
      minW={96}
      maxW="600px"
      borderRadius={8}
      ml={ml}
    >
      {author && (
        <>
          <Text mb={4}>{t('articles.author.author')}</Text>

          <Flex flexDir="row" alignItems="center" ml={4}>
            <Avatar src={author.profileImg} name={author.name} />
            <Flex flexDir="column" ml={12}>
              <Text>{t('articles.author.name')}:</Text>
              <Text>{t('articles.author.title')}:</Text>
              <Text>{t('articles.author.company')}:</Text>
            </Flex>
            <Flex flexDir="column" ml={8}>
              <Text>
                {author.name?.length > 0
                  ? author.name
                  : t('general.inputMissing')}
              </Text>
              <Text>
                {author.title?.length > 0
                  ? author.title
                  : t('general.inputMissing')}
              </Text>
              <Text>
                {author.company?.length > 0
                  ? author.company
                  : t('general.inputMissing')}
              </Text>
            </Flex>
          </Flex>
          <Text mt={2}>{t('articles.author.linkProfileImg')}:</Text>
          <Text>
            {author.profileImg && author.profileImg.length > 0
              ? _.truncate(author.profileImg, { length: 100 })
              : t('general.inputMissing')}
          </Text>
        </>
      )}
      {!author && <Text>{t('articles.article.noAuthorAvailable')}</Text>}
    </Flex>
  )
}
export default AuthorCard
