import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import EditPassword from '../../accounts/EditPassword'

const EditPasswordSection: React.FC<{
  px: ResponsiveValue<number | string>
  maxW?: number | string
}> = ({ px, maxW }) => {
  const { t } = useTranslation()
  return (
    <Flex flexDir="column" maxW={maxW} px={px} pt={6}>
      <Text textStyle="textLargeRegular">{t('accounts.editPassword')}</Text>
      <Text size="lg" fontWeight="light" maxW="container.sm">
        {t('accounts.passwordInstructions1')}
      </Text>
      <br />
      <Text size="lg" fontWeight="light">
        {t('accounts.passwordInstructions2')}
      </Text>

      <EditPassword />
    </Flex>
  )
}
export default EditPasswordSection
