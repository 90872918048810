import _ from 'lodash'
import { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Show, Table, Tbody, Td, Tr, Text } from '@chakra-ui/react'

import { Icon } from '../ui/Icon'
import { getDateStrFromDate } from '../../common/util'
import { useAccountInvites } from '../../apiClients/accountsApiClient'

import Heading from '../ui/Heading'
import InviteCard from '../collaborators/InviteCard'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'

const ListOrgAccountInvites: React.FC<{
  orgId: string
  mt?: number | string
  pb?: number | string
}> = ({ orgId, mt, pb }) => {
  const { t } = useTranslation()
  const area = 'Organization'
  const areaId = orgId

  const { data: orgAccounts } = useOrganizationAccounts(orgId)
  const { currentUser } = useContext(AuthContext)
  const currentOrgAccount = orgAccounts?.find(orgAccount => {
    return orgAccount.accountId === currentUser?.id
  })

  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }
  const { data: invites, mutate } = useAccountInvites(area, areaId)

  return (
    <Box pb={pb} mt={mt}>
      {invites && invites.length > 0 && (
        <>
          <Heading textStyle="h9" mt={8} mb={2}>
            {t('providers.listingInvites')}
          </Heading>
          <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
            <Tbody>
              {invites.map((invite, i) => {
                const createdAt = getDateStrFromDate(new Date(invite.createdAt))
                return (
                  <Fragment key={invite.id}>
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      data-cy="accountTag"
                      onClick={() => {
                        toggleAccordion(invite.id)
                      }}
                    >
                      <Show above="laptop">
                        <Td maxW={8}>
                          {!accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlinePlusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                          {accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlineMinusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                        </Td>
                        <Td
                          overflow="clip"
                          wordBreak="break-word"
                          maxW={72}
                          minW={44}
                        >
                          {invite.email}
                        </Td>
                        <Td>
                          {invite.invitedAs === 'Consumer'
                            ? t('general.consumerUser')
                            : t('general.providerUser')}
                        </Td>
                        <Td minW={{ tablet: 44 }}>
                          {t(
                            `providers.accessRights.${invite.relation.toLowerCase()}`,
                          )}
                        </Td>
                      </Show>
                      <Show below="laptop">
                        <Td maxW={8}>
                          {!accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlinePlusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                          {accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlineMinusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                        </Td>
                        <Td
                          colSpan={3}
                          overflow="clip"
                          wordBreak="break-word"
                          maxW={72}
                          minW={44}
                        >
                          <Text fontWeight="bold">{invite.email}</Text>

                          <Text>
                            {invite.invitedAs === 'Consumer'
                              ? t('general.consumerUser')
                              : t('general.providerUser')}
                          </Text>
                          <Text>
                            {t(
                              `providers.accessRights.${invite.relation.toLowerCase()}`,
                            )}
                          </Text>
                        </Td>
                      </Show>

                      <Show above="laptop">
                        <Td
                          maxW={{ xxs: 40, tablet: 96, laptop: '' }}
                          overflow="clip"
                        >
                          {t('properties.collaboratorInviteSentNotRegistered', {
                            date: createdAt,
                          })}
                        </Td>
                      </Show>
                    </Tr>
                    {accordionsToShow.includes(invite.id) && (
                      <Tr
                        bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                        borderTopWidth={1}
                      >
                        <Td colSpan={8}>
                          <InviteCard
                            area={area}
                            areaId={areaId}
                            invite={invite}
                            currentUserAccessRights={
                              currentOrgAccount?.accountRelation ?? 'Viewer'
                            }
                            mutate={mutate}
                          />
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                )
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  )
}

export default ListOrgAccountInvites
