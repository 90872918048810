import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import AddTagButton from '../../components/ui/CreateTagButton'
import ListTags from '../../components/tags/ListTags'

const ManageTags = () => {
  const { t } = useTranslation()

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('admin.manageTags')}</title>
      </Helmet>
      <Box pos="absolute" top={12} right={20} zIndex="hide">
        <AddTagButton w={44}>{t('tags.addTag')}</AddTagButton>
      </Box>
      <ListTags />
    </Box>
  )
}
export default ManageTags
