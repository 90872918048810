import { ClimateResourceCategory } from '@contracts/support'

import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL

const climateResourceCategoryFetcher = async (
  urlKey: string,
): Promise<ClimateResourceCategory[] | undefined> => {
  return axios
    .get<ClimateResourceCategory[]>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data
    })
}

export const useClimateResourceCategories = (): SWRResponse<
  ClimateResourceCategory[] | undefined
> => {
  return useSWR<ClimateResourceCategory[] | undefined>(
    '/climate-resource-categories/',
    climateResourceCategoryFetcher,
    { revalidateOnFocus: false },
  )
}
export const fetchClimateResources = async (filter: {
  categoryId?: string
  productName?: string
}) => {
  if (!filter.categoryId && !filter.productName) {
    log.warn(
      'Error fetching climate resources, category or name must be provided.',
    )

    throw new Error(
      'Error fetching climate resources, category or name must be provided.',
    )
  }

  const queryParts = { categoryId: '', name: '' }

  if (filter.categoryId) {
    queryParts.categoryId = filter.categoryId
  }

  if (filter.productName) {
    queryParts.name = filter.productName
  }
  const uri = `${baseUrl}/climate-resources?${new URLSearchParams(
    queryParts,
  ).toString()}`

  return axios
    .get(uri)
    .then(res => {
      log.info('Successfully fetched resources')

      return res
    })
    .catch(error => {
      log.error({ err: error }, 'Error fetching resources')
      throw error
    })
}

export const loadClimateData = async () => {
  return axios
    .post(`${baseUrl}/admin/climate-resources/reload`, {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(res => {
      log.info('Successfully loaded data from Boverket')
      return res
    })
    .catch(error => {
      log.error({ err: error }, 'Error loading data from Boverket')
      throw error
    })
}
