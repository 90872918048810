import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'

import { ProjectCategory, UpdateProjectRequest } from '@contracts/projects'

import {
  CreateHelpRequest,
  CreateHelpRequestTemplate,
  HelpRequestConsumerStatus,
  HelpRequestProviderStatus,
  HelpRequestResponse,
  HelpRequestTemplateInfo,
  HelpRequestTemplateResponse,
  UpdateHelpRequest,
} from '@contracts/misc'
import { useContext } from 'react'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROJECTS_SERVICE_URL

export const useHelpRequestsTemplate = (
  helpRequestTemplateId: string | undefined,
): SWRResponse<HelpRequestTemplateResponse | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestTemplateResponse | undefined
  >(
    helpRequestTemplateId
      ? `${baseUrl}/help-request-template/${helpRequestTemplateId}`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const useHelpRequestsTemplates = (
  projectId: string,
): SWRResponse<HelpRequestTemplateResponse[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestTemplateResponse[] | undefined
  >(`${baseUrl}/projects/${projectId}/help-request-templates/`)
  return { data, error, isValidating, mutate }
}

export const useHelpRequestsTemplatesByProjectIds = (
  projectIds: string[] | undefined,
): SWRResponse<HelpRequestTemplateResponse[] | undefined> => {
  const queryString = encodeURIComponent(JSON.stringify(projectIds)) ?? ''

  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestTemplateResponse[] | undefined
  >(
    // projectIds && projectIds.length
    projectIds && projectIds.length > 0
      ? `${baseUrl}/help-request-templates?projectIds=${queryString}`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const useHelpRequest = (
  helpRequestId: string | undefined,
): SWRResponse<HelpRequestResponse | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestResponse | undefined
  >(helpRequestId ? `${baseUrl}/help-requests/${helpRequestId}` : null)
  return { data, error, isValidating, mutate }
}

export const useHelpRequests = (
  helpRequestTemplateIds: string[] | undefined,
): SWRResponse<HelpRequestResponse[] | undefined> => {
  const queryString = encodeURI(JSON.stringify(helpRequestTemplateIds)) ?? ''

  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestResponse[] | undefined
  >(
    helpRequestTemplateIds && helpRequestTemplateIds.length
      ? `${baseUrl}/help-requests?helpRequestTemplateIds=${queryString}`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const useAllHelpRequestsAdmin = (): SWRResponse<
  HelpRequestResponse[] | undefined
> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<HelpRequestResponse[] | undefined>(
    isAdmin ? `${baseUrl}/help-requests` : null,
  )
  // return { data, error, isValidating, mutate }
}

export const useHelpRequestsByOrganizationId = (
  organizationId: string | undefined,
): SWRResponse<HelpRequestResponse[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    HelpRequestResponse[] | undefined
  >(
    organizationId
      ? `${baseUrl}/help-requests?organizationId=${organizationId}`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const addHelpRequestTemplate = async (
  projectId: string,
  requestData: CreateHelpRequestTemplate,
) => {
  return axios.post(
    `${baseUrl}/projects/${projectId}/help-request-templates`,
    {
      ...requestData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}
export const addHelpRequest = async (
  // projectId: string,
  requestData: CreateHelpRequest,
) => {
  return axios.post(
    `${baseUrl}/help-requests`,
    {
      ...requestData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateHelpRequestTemplate = async (
  helpRequestTemplateId: string,
  projectId: string,
  requestData: HelpRequestTemplateInfo,
  // requestData: UpdateHelpRequestTemplate,
) => {
  return axios.put(
    `${baseUrl}/projects/${projectId}/help-request-templates/${helpRequestTemplateId}`,
    {
      ...requestData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateHelpRequest = async (
  helpRequestId: string,
  requestData: UpdateHelpRequest,
) => {
  return axios.put(
    `${baseUrl}/help-requests/${helpRequestId}`,
    {
      ...requestData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateHelpRequestOffer = async (
  helpRequestId: string,
  requestData: UpdateHelpRequest,
) => {
  return axios.put(
    `${baseUrl}/help-requests/${helpRequestId}/offer`,
    {
      ...requestData,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateHelpRequestProviderStatus = async (
  helpRequestId: string,
  providerStatus: HelpRequestProviderStatus,
) => {
  return axios.put(
    `${baseUrl}/help-requests/${helpRequestId}/provider-status`,
    {
      providerStatus,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateHelpRequestConsumerStatus = async (
  helpRequestId: string,
  consumerStatus: HelpRequestConsumerStatus,
) => {
  return axios.put(
    `${baseUrl}/help-requests/${helpRequestId}/consumer-status`,
    {
      consumerStatus,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const addProject = async (
  projectName: string,
  propertyId: string,
  projectCategories: ProjectCategory[],
) => {
  return axios.post(
    `${baseUrl}/projects`,
    { name: projectName, propertyId, projectCategories },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const deleteHelpRequestTemplate = (
  projectId: string,
  helpRequestTemplateId: string,
) => {
  return axios.delete(
    `${baseUrl}/projects/${projectId}/help-request-templates/${helpRequestTemplateId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateProject = (
  id: string,
  requestData: UpdateProjectRequest,
) => {
  return axios
    .put(
      `${baseUrl}/projects/${id}`,
      { ...requestData },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated project', status, data)
      return data
    })
}

export const deleteProject = (projectId: string) => {
  return axios.delete(`${baseUrl}/projects/${projectId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
