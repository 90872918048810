import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
} from '@chakra-ui/react'

import { OrganizationSortingOption } from '../../common/enums'
import SortableHeading from '../../components/ui/SortableHeading'

import { calculateSortedItems } from '../../components/organizations/organizationListUtils'
import { useOrganizationsAdmin } from '../../apiClients/organizationsApiClient'
import OrganizationSummaryCard from '../../components/organizations/OrganizationAdminSummaryCard'
import ProviderStatusBadge from '../provider/ProviderStatusBadge'

const ListOrganizations: React.FC<{ searchOrg?: string | undefined }> = ({
  searchOrg,
}) => {
  const { t } = useTranslation()
  const [sortType, setSortType] = useState(
    OrganizationSortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const { data: allOrganizations, mutate: mutateOrganizations } =
    useOrganizationsAdmin()
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const sortedOrganizations = calculateSortedItems(
    sortType,
    allOrganizations,
    searchStr,
  )

  return (
    <Box pb={40}>
      <>
        <Helmet>
          <title>{t('providers.registeredAccounts')}</title>
        </Helmet>

        <Flex flexDir="row" alignItems="center" flexWrap="wrap" mt={6} mb={6}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" />}
            />
            <Input
              type="text"
              placeholder={t('accounts.searchHomes')}
              maxW="md"
              borderColor="gray.400"
              height={10}
              onChange={e => setSearchStr(e.target.value)}
            />
          </InputGroup>
          <Text ml={4} textStyle="textMedium">
            {t('providers.searchInfo', {
              total: allOrganizations?.length ?? 0,
              filtered: sortedOrganizations.length ?? 0,
            })}
          </Text>
        </Flex>

        <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
          <TableCaption>{t('accounts.listingBerikarAccounts')}</TableCaption>
          <Thead>
            <Tr>
              <Th></Th>

              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.EMAIL_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.EMAIL_DESC)
                  }
                >
                  {t('accounts.providers.status')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.EMAIL_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.EMAIL_DESC)
                  }
                >
                  {t('accounts.providers.name')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.NAME_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.NAME_DESC)
                  }
                >
                  {t('accounts.providers.registrationNumber')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.PHONE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.PHONE_DESC)
                  }
                >
                  {t('accounts.providers.vatNumber')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading>{t('accounts.providers.id')}</SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.CREATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.CREATED_DATE_DESC)
                  }
                >
                  {t('accounts.createdAt')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(OrganizationSortingOption.UPDATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(OrganizationSortingOption.UPDATED_DATE_DESC)
                  }
                >
                  {t('accounts.updatedAt')}
                </SortableHeading>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedOrganizations && sortedOrganizations.length ? (
              sortedOrganizations.map((organization, i) => {
                let bg: string
                if (organization.id === searchOrg) {
                  bg = 'red.100'
                } else {
                  bg = i % 2 !== 0 ? 'white' : 'primary_grey01'
                }

                return (
                  <Fragment key={organization.id}>
                    <Tr bg={bg} data-cy="accountTag">
                      <Td
                        color="primary_orange02"
                        _hover={{ color: 'primary_orange01' }}
                      >
                        {!accordionsToShow.includes(organization.id) && (
                          <FaRegPlusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(organization.id)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(organization.id) && (
                          <FaRegMinusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(organization.id)
                            }}
                          />
                        )}
                      </Td>
                      <Td>
                        <ProviderStatusBadge
                          status={organization.status}
                          variant="badge-only"
                        />
                      </Td>
                      <Td>{organization.name}</Td>
                      <Td></Td>
                      <Td></Td>
                      <Td>
                        <Tooltip
                          hasArrow
                          label={organization.id}
                          bg="primaryBackground"
                          color="black"
                          cursor="pointer"
                          placement="top"
                        >
                          <Box>{organization.id.substring(0, 15)}...</Box>
                        </Tooltip>
                      </Td>

                      <Td>
                        {new Date(organization.createdAt).toLocaleString(
                          'sv-SE',
                        )}
                      </Td>
                      <Td>
                        {new Date(organization.updatedAt).toLocaleString(
                          'sv-SE',
                        )}
                      </Td>
                    </Tr>
                    {accordionsToShow.includes(organization.id) && (
                      <Tr bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}>
                        <Td colSpan={9}>
                          <Flex justifyContent="space-around">
                            <OrganizationSummaryCard
                              org={organization}
                              headerBg={
                                i % 2 !== 0 ? 'white' : 'primary_grey01'
                              }
                              mutateOrganizations={mutateOrganizations}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>{t('accounts.noAccountsFound')}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </>
    </Box>
  )
}
export default ListOrganizations
