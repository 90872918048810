import { Avatar, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  useOrganization,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'

import { getDateStrFromDate } from '../../common/util'
import { useImageUrl } from '../attachments/attachmentHelper'
import ProviderBadge from '../ProviderBadge'
import { Logo } from '../ui/Logo'

const OrganizationProfileCard: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { data: org } = useOrganization(organizationId)
  const { data: orgProfile } = useOrganizationProfile(organizationId)
  const { t } = useTranslation()

  const imgUrl = useImageUrl(orgProfile?.profileAttachmentId)
  if (!org) {
    return null
  }

  return (
    <Flex flexDir="column" m={0}>
      <Flex
        flexDir="column"
        bg="secondary"
        h={28}
        borderTopRadius="md"
        pl={40}
        pt={10}
      >
        <Text textStyle="textLarge">{org.name}</Text>
      </Flex>
      <Flex
        flexDir="column"
        minH={28}
        borderTopRadius="md"
        pl={{ xxs: 28, laptop: 40 }}
        pt={{ xxs: 8, laptop: 4 }}
        pb={2}
      >
        <>
          <ProviderBadge size={'sm'} transform="translate(-10px,0)" />
          <Text textStyle="textLarge">{org.name}</Text>
        </>
        <Text>{org.email}</Text>
        <Text>{org.phoneNumber}</Text>
      </Flex>

      <Flex
        flexDir="row"
        wrap="wrap"
        alignItems="center"
        flexWrap="wrap"
        pb={2}
        borderBottomRadius="lg"
        justifyContent="left"
        px={2}
      >
        <Logo name="Berikar" h={5} />
        <Text ml={1} textStyle="xsLight">
          {t('accounts.withBerikarSince', {
            createdAt: getDateStrFromDate(new Date(org.createdAt)),
          })}
        </Text>
      </Flex>
      {!imgUrl && (
        <Avatar
          pos="absolute"
          name={org.name}
          boxSize={32}
          borderRadius="full"
          border="5px solid"
          mt={4}
          ml={2}
          zIndex="base"
        />
      )}

      {imgUrl && (
        <Image
          pos="absolute"
          fit="cover"
          src={imgUrl}
          backgroundPosition="center"
          boxSize={32}
          borderRadius="full"
          border="5px solid"
          borderColor="primary_grey02"
          mt={4}
          ml={2}
          zIndex="base"
        />
      )}
    </Flex>
  )
}
export default OrganizationProfileCard
