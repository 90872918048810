/* eslint-disable */ //react/jsx-no-useless-fragment */
import { Avatar, Button, Flex, Text } from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import {
  useCollaboratorsByAccountId,
  useProperties,
  usePropertiesByAccount,
  usePropertyTypes,
} from '../../apiClients/propertiesApiClient'
import Skeleton from '../ui/Skeleton'
import TextCard from '../ui/TextCard'
import { PATH_PORTAL } from '../../common/nav'

import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import { useAccountAttachments } from '../../apiClients/attachmentsApiClient'
import {
  useProjectsByAccount,
  useProjectsByProperties,
} from '../../apiClients/projectsApiClient/projects'
import ProviderOverviewCardAdmin from '../provider/ProviderOverviewCardAdmin'
import ProjectsByPropertyCard from '../projects/ProjectsByPropertyCard'
import { Link } from '../../ui'
import { PATH_ADMIN_PROJECTS, PATH_ADMIN_PROPERTIES } from '../../common/nav'
import PropertyOrProjectAvatar from '../projects/PropertyOrProjectAvatar'
import AccountAttachmentsTreeViewCard from '../attachments/AccountAttachmentsTreeViewCard'
import AccountAttachmentsOverviewCard from '../attachments/AccountAttachmentsOverviewCard'
import { ProjectResponse } from '@contracts/projects'
import { prepareImpersonate } from '../../apiClients/accountsApiClient'
import { ImpersonateLoginAccountRequest } from '@contracts/accounts'
const baseUrlPrivate = `${window.AZURE_BLOB_STORAGE_BASE_URL}/private`

const AccountAdminSummaryCard: React.FC<{
  account: AccountData
  mr?: number
  bg?: string
}> = ({ account, mr, bg }) => {
  const { data: properties } = usePropertiesByAccount(account.id)
  const { t } = useTranslation()
  const { data: propertyTypes } = usePropertyTypes()
  const { data: collaborators } = useCollaboratorsByAccountId(account.id)
  const { data: allAttachments } = useAccountAttachments(account.id)
  const { data: allProperties } = usePropertiesByAccount(account.id)

  const { data: allProjects } = useProjectsByAccount(account.id)

  const { data: allPropertyProjects } = useProjectsByProperties(
    allProperties?.map(property => {
      return property.id
    }),
  )
  // Projects without property where provided account/user is member
  let orphanProjects: ProjectResponse[] = []

  if (allProjects && allPropertyProjects) {
    orphanProjects = allProjects.filter(project => {
      return !allPropertyProjects.find(propProj => {
        return propProj.id === project.id
      })
    })
  }

  const hSpacing = 4
  const vSpacing = 6

  const owners = collaborators?.filter(c => {
    return c.accountRelation === 'Owner'
  })
  const editorOrViewer = collaborators?.filter(c => {
    return c.accountRelation === 'Editor' || c.accountRelation === 'Viewer'
  })

  const getPropertyNameAndType = (propertyId: string) => {
    const property = properties?.find(property => {
      return property.id === propertyId
    })
    return `${property?.name} (${
      propertyTypes?.find(pt => {
        return pt.id === property?.propertyTypeId
      })?.name
    })`
  }

  const impersonate = async () => {
    const req: ImpersonateLoginAccountRequest = {
      accountId: account.id,
      accountType: account.accountTypes?.includes('Provider')
        ? 'Provider'
        : 'Consumer',
    }
    await prepareImpersonate(req).then(ctx => {
      window
        .open(
          `${PATH_PORTAL}/impersonate?ctx=${ctx}`,
          '_blank',
          'incognito=true,noopener=true',
        )
        ?.focus()
    })
  }

  if (properties) {
    return (
      <Flex flexDir="column" mb={4} rowGap={4}>
        <Button onClick={() => impersonate()} variant="secondary" maxW={60}>
          {t('admin.impersonate')}
        </Button>
        {account.accountTypes?.includes('Provider') && (
          <ProviderOverviewCardAdmin providerAccount={account} />
        )}

        <ExpandableSectionCard
          title={`${t('admin.userProperties')} (${allProperties?.length})`}
          w="100%"
        >
          <Flex flexDir="row" gap={4} mb={4}>
            {/* Admin card  */}
            <TextCard
              mr={hSpacing}
              mt={vSpacing}
              heading={`${t('admin.adminOfProperty')} (${owners?.length ?? 0})`}
              variant="border"
              minW="container.sm"
              px={4}
              headerBg={bg}
            >
              <>
                <Text>
                  {owners?.length === 0 && t('admin.adminOfNoProperty')}
                </Text>
                <Flex
                  flexDir="row"
                  wrap="wrap"
                  gap={2}
                  alignItems="flex-start"
                  mt={4}
                >
                  {owners &&
                    owners.map(collaborator => {
                      return (
                        <PropertyOrProjectAvatar
                          key={collaborator.accountId}
                          area="property"
                          areaId={collaborator.entityId}
                          variant="avatarAndName"
                          link={`${PATH_ADMIN_PROPERTIES}/?fastighet=${collaborator.entityId}`}
                          w={64}
                        />
                      )
                    })}
                </Flex>
              </>
            </TextCard>

            {/* Member (not admin) card  */}
            <TextCard
              mr={hSpacing}
              mt={vSpacing}
              heading={`${t('admin.memberOfProperty')} (${
                editorOrViewer?.length ?? 0
              })`}
              variant="border"
              minW="container.sm"
              px={4}
              headerBg={bg}
            >
              <>
                <Text>
                  {editorOrViewer?.length === 0 &&
                    t('admin.memberOfNoProperty')}
                </Text>
                <Flex
                  flexDir="row"
                  wrap="wrap"
                  gap={2}
                  mt={4}
                  alignItems="flex-start"
                >
                  {editorOrViewer &&
                    editorOrViewer.map(collaborator => {
                      return (
                        <PropertyOrProjectAvatar
                          key={collaborator.accountId}
                          area="property"
                          areaId={collaborator.entityId}
                          variant="avatarAndName"
                          link={`${PATH_ADMIN_PROPERTIES}/?fastighet=${collaborator.entityId}`}
                          w={64}
                        />
                      )
                    })}
                </Flex>
              </>
            </TextCard>
          </Flex>
        </ExpandableSectionCard>

        {/* List projects */}
        <ExpandableSectionCard
          title={`${t('admin.userProjects')} (${allProjects?.length})`}
          w="100%"
        >
          <>
            <Flex
              flexDir="row"
              flexWrap="wrap"
              rowGap={vSpacing}
              columnGap={hSpacing}
              my={6}
            >
              {/* Projects cards */}
              {properties.map(property => {
                return (
                  <ProjectsByPropertyCard
                    key={property.id}
                    accountId={account.id}
                    propertyName={property.name}
                    propertyId={property.id}
                    headerBg={bg}
                  />
                )
              })}
            </Flex>
            {orphanProjects.length > 0 && (
              <Text>{t('admin.orphanProjects')}</Text>
            )}
            <Flex
              flexDir="row"
              flexWrap="wrap"
              rowGap={vSpacing}
              columnGap={hSpacing}
              my={6}
            >
              {orphanProjects?.map(project => {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const imgUrl = `${baseUrlPrivate}/${project.imageAttachmentId}`

                return (
                  <Flex flexDir="row" key={project.id} gap={2}>
                    <Link to={`${PATH_ADMIN_PROJECTS}/?projekt=${project.id}`}>
                      <Flex alignItems="center" w="xs">
                        <Avatar
                          name={project.name}
                          src={imgUrl}
                          size="sm"
                          mr={2}
                        />
                        {project.name}
                      </Flex>
                    </Link>
                  </Flex>
                )
              })}
            </Flex>
          </>
        </ExpandableSectionCard>

        {/* List Attachments */}
        <ExpandableSectionCard
          title={`${t('admin.userAttachments')} (${allAttachments?.length})`}
          w="100%"
        >
          <AccountAttachmentsOverviewCard account={account} mt={6} />
        </ExpandableSectionCard>

        <ExpandableSectionCard
          title={`${t('admin.userAttachmentsTreeView')} (${
            allAttachments?.length
          })`}
          w="100%"
        >
          <AccountAttachmentsTreeViewCard account={account} mt={6} />
        </ExpandableSectionCard>
      </Flex>
    )
  } else {
    return <Skeleton />
  }
}
export default AccountAdminSummaryCard
