import { Button, Flex } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { useContext } from 'react'
import {
  updateHelpRequestProviderStatus,
  useHelpRequestsByOrganizationId,
} from '../../apiClients/projectsApiClient/helpRequests'
import { AreaContext } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import HelpRequestTemplateCard from '../sections/portal/HelpRequestTemplateCard'
import MyMessageSection from '../sections/portal/MyMessageSection'
import CreatedOrUpdatedDateCard from '../ui/CreatedOrUpdatedDateCard'
import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import AddOrEditOffer from './AddOrEditOffer'
import HelpRequestProviderRowCard from './HelpRequestProviderRowCard'

const ExpandableHelpRequestProviderCard: React.FC<{
  helpReq: HelpRequestResponse
  isExpanded?: boolean
}> = ({ helpReq, isExpanded = false }) => {
  const { currentOrganization } = useContext(AuthContext)

  const { mutate: mutateAllHelpRequests } = useHelpRequestsByOrganizationId(
    currentOrganization?.id,
  )

  return (
    <ExpandableSectionCard
      key={helpReq.id}
      variant="arrow-left"
      headerChildren={<HelpRequestProviderRowCard helpReq={helpReq} />}
      mx={{ xxs: 1, tablet: 4 }}
      w="100%"
      isExpanded={isExpanded}
    >
      <Flex
        flexDir="column"
        gap={4}
        mb={4}
        mt={2}
        textStyle="infoText"
        key={helpReq.id}
      >
        <Flex flexDir="column" px={0}>
          <ExpandableSectionCard
            title="Förfrågningsunderlag"
            boxShadow="none"
            noDivider
            mx={0}
          >
            <>
              <HelpRequestTemplateCard
                helpRequestTemplateId={helpReq.helpRequestTemplateId}
                // projectId={''}
                inviteMessage={helpReq.inviteMessage ?? ''}
                isReadOnly
              />
              {helpReq.providerStatus === 'Rejected' && (
                <Flex justifyContent="right" mt={4} px={4}>
                  Nuvarande status: {helpReq.providerStatus}
                </Flex>
              )}
              <Flex justifyContent="right" mt={4} px={4}>
                {helpReq.providerStatus !== 'Rejected' && (
                  <Button
                    w={44}
                    size="sm"
                    colorScheme="red"
                    onClick={() => {
                      void updateHelpRequestProviderStatus(
                        helpReq.id,
                        'Rejected',
                      )
                      void mutateAllHelpRequests()
                    }}
                  >
                    Nej tack! (avslå förfrågan)
                  </Button>
                )}
                {helpReq.providerStatus === 'Rejected' && (
                  <Button
                    w={44}
                    // mr={4}
                    size="sm"
                    colorScheme="green"
                    onClick={() => {
                      void updateHelpRequestProviderStatus(helpReq.id, 'New')
                      void mutateAllHelpRequests()
                    }}
                  >
                    Lås upp förfrågan igen
                  </Button>
                )}
              </Flex>
            </>
          </ExpandableSectionCard>

          <ExpandableSectionCard title="Vår offert" boxShadow="none" mx={0}>
            <AddOrEditOffer helpReq={helpReq} mt={0} mb={4} />
          </ExpandableSectionCard>
          <AreaContext.Provider
            value={{ area: 'helpRequest', areaId: helpReq.id }}
          >
            <MyMessageSection
              areaId={helpReq.id}
              area="HelpRequest"
              areaFriendlyName="Förfrågning"
              areaFriendlyParentName={helpReq.helpRequestTemplate?.name ?? ''}
              heading="Meddelande"
              headingColor="black"
              pt={2}
              parentArea="HelpRequestTemplate"
              parentAreaId={helpReq.helpRequestTemplateId}
              grandParentArea="Project"
              grandParentAreaId={helpReq.helpRequestTemplate?.projectId}
              conversationArea="HelpRequest"
            />
          </AreaContext.Provider>
          <CreatedOrUpdatedDateCard
            variant="created"
            createdAt={helpReq.createdAt}
            authorAccountId={helpReq.createdBy}
          />
        </Flex>
      </Flex>
    </ExpandableSectionCard>
  )
}
export default ExpandableHelpRequestProviderCard
