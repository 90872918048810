import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'

import {
  ProjectCategory,
  ProjectInfoResponse,
  ProjectResponse,
  ProjectsResponseData,
  UpdateProjectRequest,
} from '@contracts/projects'

import { useContext } from 'react'
import { AccountRelation } from '@contracts/misc'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROJECTS_SERVICE_URL

const projectsFetcher = async (
  urlKey: string,
): Promise<ProjectResponse[] | undefined> => {
  return axios.get<ProjectsResponseData>(`${urlKey}`).then(({ data }) => {
    return data.projects
  })
}

export const useProjectCategories = (): SWRResponse<
  ProjectCategory[] | undefined
> => {
  const { data, error, isValidating, mutate } = useSWRImmutable<
    ProjectCategory[] | undefined
  >(`${baseUrl}/project-categories`)
  return { data, error, isValidating, mutate }
}

export const useProjects = (
  accountRelation?: AccountRelation,
): SWRResponse<ProjectResponse[] | undefined> => {
  const queryString = accountRelation ? accountRelation : ''
  return useSWR<ProjectResponse[] | undefined>(
    accountRelation
      ? `${baseUrl}/projects?accountRelations=${queryString}`
      : `${baseUrl}/projects/`,
    projectsFetcher,
  )
}

export const useAllProjectsAdmin = (): SWRResponse<
  ProjectResponse[] | undefined
> => {
  return useSWR<ProjectResponse[] | undefined>(
    `${baseUrl}/admin/projects`,
    projectsFetcher,
  )
}

export const useProjectsByAccount = (
  accountId: string | undefined,
): SWRResponse<ProjectResponse[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<ProjectResponse[] | undefined>(
    isAdmin && accountId
      ? `${baseUrl}/admin/projects/accounts/${accountId}`
      : `${baseUrl}/projects/accounts/`,
    projectsFetcher,
  )
}

export const useProject = (
  projectId: string | undefined,
): SWRResponse<ProjectResponse | undefined> => {
  return useSWR<ProjectResponse | undefined>(
    projectId ? `${baseUrl}/projects/${projectId}` : null,
  )
}

export const useProjectInfo = (
  projectId: string | undefined,
): SWRResponse<ProjectInfoResponse | undefined> => {
  return useSWR<ProjectInfoResponse | undefined>(
    projectId ? `${baseUrl}/projects/${projectId}/info` : null,
  )
}

export const useProjectsByProperty = (
  propertyId: string | undefined,
): SWRResponse<ProjectResponse[] | undefined> => {
  return useSWR<ProjectResponse[] | undefined>(
    propertyId ? `${baseUrl}/projects/properties/${propertyId}` : null,
    projectsFetcher,
  )
}

export const useProjectsByProperties = (
  propertyIds: string[] | undefined,
): SWRResponse<ProjectResponse[] | undefined> => {
  const querySring = encodeURIComponent(JSON.stringify(propertyIds)) ?? ''

  return useSWR<ProjectResponse[] | undefined>(
    propertyIds
      ? `${baseUrl}/projects/properties/propertyIds?propertyIds=${querySring}`
      : null,
    projectsFetcher,
  )
}

export const useProjectsByPropertyAdmin = (
  propertyId: string,
): SWRResponse<ProjectResponse[] | undefined> => {
  return useSWR<ProjectResponse[] | undefined>(
    `${baseUrl}/admin/projects/properties/${propertyId}`,
    projectsFetcher,
  )
}

export const addProject = async (
  projectName: string,
  propertyId: string,
  projectCategories: ProjectCategory[],
) => {
  return axios.post(
    `${baseUrl}/projects`,
    { name: projectName, propertyId, projectCategories },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateProject = (
  id: string,
  requestData: UpdateProjectRequest,
) => {
  return axios
    .put(`${baseUrl}/projects/${id}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated project', status, data)
      return data
    })
}

export const deleteProject = (projectId: string) => {
  return axios.delete(`${baseUrl}/projects/${projectId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
