import React, { useEffect, useMemo, useState } from 'react'
import { AccountData, Organization } from '@contracts/misc'
import { getCurrentUser, logout } from '../apiClients/accountsApiClient'
import { useImageUrl } from '../components/attachments/attachmentHelper'
import { useCurrentOrganization } from '../apiClients/organizationsApiClient'

export interface AuthContextProps {
  currentUser: AccountData | null
  profileImg: string
  isLoggedIn: boolean
  isConsumer: boolean
  isProvider: boolean
  isAdmin: boolean
  setCurrentUser: (account: AccountData) => void
  logout: () => void
  currentOrganization: Organization | undefined
  isReady: boolean
}

const AuthContext = React.createContext<AuthContextProps>({
  currentUser: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    accountTypes: [],
    status: 'New',
    createdAt: 0,
    updatedAt: 0,
    phoneNumber: '',
  },
  profileImg: '',
  isLoggedIn: false,
  isConsumer: false,
  isProvider: false,
  isAdmin: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  currentOrganization: undefined,
  isReady: false,
})

export const AuthContextProvider: React.FC<{
  children: React.ReactNode
}> = props => {
  const [isReady, setIsReady] = useState(false)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)
  const [currentUser, setCurrentUser] = useState<AccountData | null>(null)
  useEffect(() => {
    // Show a simple loading message after 1 seconds of loading
    const loadingTimeout = setTimeout(() => {
      setShowLoadingIndicator(true)
    }, 1000)

    getCurrentUser()
      .then(response => {
        setCurrentUser(response?.currentUser ?? null)
      })
      .catch(() => {
        setCurrentUser(null)
      })
      .finally(() => {
        setIsReady(true)
        clearTimeout(loadingTimeout)
      })

    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [])

  const userIsLoggedIn = useMemo(() => !!currentUser, [currentUser])

  const profileImg =
    useImageUrl(currentUser?.profileAttachmentId, undefined) ?? ''

  const isProvider = useMemo(
    () => Boolean(currentUser?.accountTypes?.includes('Provider')),
    [currentUser],
  )
  const isConsumer = useMemo(
    () => Boolean(currentUser?.accountTypes?.includes('Consumer')),
    [currentUser],
  )
  const isAdmin = useMemo(
    () => Boolean(currentUser?.accountTypes?.includes('Admin')),
    [currentUser],
  )
  const { data: currentOrganization } = useCurrentOrganization(
    isProvider ? currentUser?.id : undefined,
  )

  const loginHandler = (userData: AccountData) => {
    setCurrentUser(userData)
  }

  const logoutHandler = () => {
    void logout()

    setCurrentUser(null)
  }

  const contextValue: AuthContextProps = {
    currentUser: currentUser,
    profileImg: profileImg,
    isLoggedIn: userIsLoggedIn,
    isConsumer,
    isProvider,
    isAdmin,
    setCurrentUser: loginHandler,
    logout: logoutHandler,
    currentOrganization,
    isReady,
  }

  // Wait for the /me response before we load the rest of the app
  if (!isReady) {
    if (showLoadingIndicator) return <>Hämtar användare...</>
    return null
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}
export default AuthContext
