import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAccount } from '../../apiClients/accountsApiClient'
import { getDateStrFromNumber, getTimeStrFromNumber } from '../../common/util'

const CreatedOrUpdatedDateCard: React.FC<{
  variant: 'created' | 'updated'
  createdAt?: number
  updatedAt?: number
  authorAccountId?: string
}> = ({ createdAt, updatedAt, authorAccountId, variant }) => {
  const { data: author } = useAccount(authorAccountId)
  const { t } = useTranslation()
  if (variant === 'created') {
    return (
      <Text fontSize="small" fontWeight="light" fontStyle="italic">
        {t('general.createdInfo', {
          date: `${getDateStrFromNumber(createdAt)} ${getTimeStrFromNumber(
            createdAt,
          )}`,

          author: authorAccountId
            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${author?.firstName} ${author?.lastName}`
            : '?',
        })}
      </Text>
    )
  }
  if (variant === 'updated') {
    return (
      <Text fontSize="small" fontWeight="light" fontStyle="italic">
        {t('general.updatedInfo', {
          date: `${getDateStrFromNumber(updatedAt)} ${getTimeStrFromNumber(
            updatedAt,
          )}`,

          author: authorAccountId
            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${author?.firstName} ${author?.lastName}`
            : '?',
        })}
      </Text>
    )
  }

  return null
}
export default CreatedOrUpdatedDateCard
