import { Flex } from '@chakra-ui/react'

import { useLocation } from 'react-router-dom'
import { usePublishedArticlesInfo } from '../../../apiClients/articlesApiClient'
import ArticlePromoSmall from '../../articleTemplates/ArticlePromoSmall'

import { PATH_MY_PRIVATE_PAGES } from '../../../common/nav'
import Heading from '../../ui/Heading'

const SimpleArticlesSection: React.FC<{
  title?: string
  pathPortal: string
  pathPublic: string
  pageTag: string
  mb?: number
  mx?: number | string
  maxW?: number | string
}> = ({ title, pathPortal, pathPublic, pageTag, mb, mx, maxW = '1500px' }) => {
  const { pathname } = useLocation()
  let linkPath = ''
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    linkPath = pathPortal
  } else {
    linkPath = pathPublic
  }

  const { data: articles } = usePublishedArticlesInfo({
    includeTags: [pageTag],
    excludeTags: [],
  })

  return (
    <Flex flexDir="column" maxW={maxW} px={mx} wrap="wrap" mb={mb}>
      <Heading>{title}</Heading>

      <Flex flexDir="row" w="100%" minW="300px" flexWrap="wrap">
        {articles &&
          articles.map(article => {
            return (
              <ArticlePromoSmall
                path={linkPath}
                key={article.id.toString()}
                articleInfo={article}
                variant="Column"
                mt={4}
              />
            )
          })}
      </Flex>
    </Flex>
  )
}
export default SimpleArticlesSection
