import { Text, Image, Flex, Center, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { Organization } from '@contracts/misc'
import {
  useOrganizationProfile,
  useOrganizationProfileAdmin,
} from '../../apiClients/organizationsApiClient'

import { Heading } from '../../ui'
import AuthContext from '../../common/auth-context'
import { Icon } from '../ui/Icon'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ProviderProfileHeader: React.FC<{
  organization: Organization
  onClose?: () => void
}> = ({ organization, onClose }) => {
  const { isAdmin } = useContext(AuthContext)

  const { data: organizationProfileNotAdmin } = useOrganizationProfile(
    isAdmin ? undefined : organization.id,
  )
  const { data: organizationProfileAdmin } = useOrganizationProfileAdmin(
    isAdmin ? organization.id : undefined,
  )

  const organizationProfile = isAdmin
    ? organizationProfileAdmin
    : organizationProfileNotAdmin

  const { t } = useTranslation()

  if (!organizationProfile) {
    return null
  }

  return (
    <>
      {onClose && (
        <Box pos="absolute" top={4} left={4} bg="gray.300" zIndex="tooltip">
          <Icon name={'AiOutlineClose'} onClick={onClose} />
        </Box>
      )}

      {!organizationProfile.backgroundAttachmentId && (
        <Center
          height={{ xxs: '140px', laptop: '180px' }}
          border="1px solid"
          borderColor="gray.300"
          color="gray.500"
          bg="gray.200"
        >
          {t('general.missingImage')}
        </Center>
      )}
      {organizationProfile.backgroundAttachmentId && (
        <Image
          src={`${baseUrl}${organizationProfile.backgroundAttachmentId}`}
          height={{ xxs: '140px', laptop: '180px' }}
          objectFit="cover"
        />
      )}

      <Flex pos="absolute" top={0} left={0}>
        {!organizationProfile.profileAttachmentId && (
          <Center
            boxSize={{ xxs: 24, tablet: 32, laptop: 40 }}
            border="5px solid"
            borderColor="primary_grey02"
            color="gray.500"
            bg="gray.200"
            borderRadius="full"
          >
            {t('general.missingImage')}
          </Center>
        )}
        {organizationProfile.profileAttachmentId && (
          <Image
            fit="cover"
            src={`${baseUrl}${organizationProfile.profileAttachmentId}`}
            backgroundPosition="center"
            boxSize={{ xxs: 24, tablet: 32, laptop: 40 }}
            borderRadius="full"
            border="5px solid"
            borderColor="primary_grey02"
            mt={{ xxs: 28, tablet: 24, laptop: 32 }}
            ml={{ xxs: 2, tablet: 4, laptop: 12 }}
            zIndex="base"
          />
        )}
      </Flex>
      <Flex
        flexDir="column"
        textAlign="left"
        ml={{ xxs: 28, tablet: 40, laptop: 60 }}
        mt={2}
      >
        <Heading
          textStyle={{ xxs: 'h4', tablet: 'h3', laptop: 'h2' }}
          color="black"
        >
          {organization.name.toUpperCase()}
        </Heading>
        {organizationProfile.provideRemoteServices && (
          <Flex flexDir="row" alignItems="center" wrap="wrap">
            <Icon name="videoMeeting" />
            <Text textStyle="infoTextLight" ml={1} wordBreak="keep-all">
              {t('providers.profile.offerHeadingDistanceShort')}
            </Text>
            <Text textStyle="infoTextLight" ml={2}>
              {' |'}
            </Text>
            <Text textStyle="infoTextLight" ml={2}>
              {organization.address?.city}
            </Text>
          </Flex>
        )}
        {!organizationProfile.provideRemoteServices && (
          <Text textStyle="infoTextLight">{organization.address?.city}</Text>
        )}
      </Flex>
    </>
  )
}
export default ProviderProfileHeader
