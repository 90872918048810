import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Show, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerAsks.png'
import AccountAttachmentsTreeViewCard from '../../components/attachments/AccountAttachmentsTreeViewCard'
import AuthContext from '../../common/auth-context'
import AccountAttachmentsMobileTreeViewCard from '../../components/attachments/AccountAttachmentsMobileTreeViewCard'

const MyFilesPage = () => {
  const { t } = useTranslation()
  const { currentUser, isConsumer, isProvider } = useContext(AuthContext)

  return (
    <>
      <Flex flexDir="column" width="100%">
        <Helmet>
          <title>{t('portal.sidebar.provider.myFiles')}</title>
        </Helmet>
        <ContentPageHeader
          label={`${t('portal.sidebar.provider.myFiles').toUpperCase()}`}
          h={32}
          pt={4}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          backgroundImgSrc={headerBackground}
          bgSize="cover"
        />
      </Flex>

      <Flex
        flexDir="column"
        backgroundColor="white"
        py={6}
        mx={{ xxs: '0%', tablet: '2%' }}
        maxW="container.md"
        bg="primaryBackground"
      >
        {/* <Text textStyle="textLarge" my={4}>
          En tydlig överblick av alla dina filer!
        </Text> */}

        {isConsumer && (
          <Text textStyle="textLarge" my={4} mx={{ xxs: 2, tablet: 0 }}>
            {t('attachments.myFilesConsumerIngress')}
          </Text>
        )}
        {isProvider && (
          <Text textStyle="textLarge" my={4} mx={{ xxs: 2, tablet: 0 }}>
            {t('attachments.myFilesProviderIngress')}
          </Text>
        )}

        {currentUser && (
          <Flex
            justifyContent={{ xxs: 'center', tablet: 'left' }}
            alignItems="center"
          >
            <Show below="laptop">
              <AccountAttachmentsMobileTreeViewCard
                account={currentUser}
                w={{ xxs: '100%', mobile: '400px' }}
              />
            </Show>
            <Show above="laptop">
              <AccountAttachmentsTreeViewCard account={currentUser} />
            </Show>
          </Flex>
        )}

        {/* <Text textStyle="infoTextLight" my={4}>
          {t('content.provider.engageInCreatingTogether')}
        </Text>
        <FeedbackPopover context={'provider.asks'} /> */}
      </Flex>
    </>
  )
}
export default MyFilesPage
