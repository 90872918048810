import React from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import type { Extensions } from '@tiptap/react'
import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { formatHtml } from '../../common/richTextHelpers'
import { BaseCustomMediaNode } from './CustomImageNode'
import TextPane from './TextPane'
// import Paragraph from '@tiptap/extension-paragraph'
// import Document from '@tiptap/extension-document'
// import Text from '@tiptap/extension-text'

type TiptapProps = {
  editorLabel?: string
  bg?: string
  border?: string
  borderColor?: string
  borderRadius?: string
  content: string | undefined
  w?: ResponsiveValue<string | number>
  maxH?: ResponsiveValue<string | number>
  placeholder?: string
  withTypographyExtension?: boolean
  withLinkExtension?: boolean
  withCodeBlockLowlightExtension?: boolean
  withTaskListExtension?: boolean
  withPlaceholderExtension?: boolean
  withMentionSuggestion?: boolean
  withEmojiSuggestion?: boolean
  withEmojisReplacer?: boolean
  withHexColorsDecorator?: boolean
  variant?: 'RICH_TEXT_OUTPUT' | 'PLAIN_TEXT_OUTPUT' | 'HTML_OUTPUT'
  overflow?: string
  px?: ResponsiveValue<string | number>
  py?: ResponsiveValue<string | number>
  mx?: string | number
  my?: string | number
  ml?: string | number
  fontSize?: ResponsiveValue<string>
  fontWeight?: string
  fontStyle?: string
  transform?: string
  textStyle?: string
  flexDir?: 'column' | 'row'
  textAlign?: 'left' | 'right' | 'center'
  noScroll?: boolean
  lineHeight?: 's' | 'm' | 'l' | 'default'
}

const HtmlPane: React.FC<TiptapProps> = ({
  content = '',
  withTypographyExtension = false,
  withLinkExtension = false,
  withCodeBlockLowlightExtension = false,
  withTaskListExtension = true,
  variant = 'RICH_TEXT_OUTPUT',
  maxH,
  overflow,
  border,
  px = { xxs: 2, tablet: 4 },
  py = 4,
  mx,
  my,
  w,
  fontSize,
  fontStyle,
  fontWeight,

  bg = 'white',
  borderRadius = 'lg',
  borderColor = 'gray.300',
  ml,
  transform,
  flexDir = 'column',
  textStyle = 'textMedium',
  textAlign,
  noScroll = false,
  lineHeight = 's',
}: TiptapProps) => {
  const editable = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extensions: Extensions | any = [
    StarterKit.configure({
      ...(withCodeBlockLowlightExtension && { codeBlock: false }),
    }),
    Link.configure({
      openOnClick: true,
    }),
    BaseCustomMediaNode,
    // Don't remove below commented rows
    // Document,
    // Paragraph,
    // Text,
  ]
  if (withTypographyExtension) {
    extensions.push(Typography)
  }
  if (withLinkExtension) {
    extensions.push(
      Link.configure({
        linkOnPaste: false,
        openOnClick: false,
      }),
    )
  }
  if (withTaskListExtension) {
    extensions.push(TaskList, TaskItem)
  }

  const editor = useEditor(
    {
      content,
      extensions,
      editable,
    },
    [content],
  )

  const getLineHeight = (): string => {
    switch (lineHeight) {
      case 's':
        return '8pt'
      case 'm':
        return '10pt'
      case 'l':
        return '12pt'
      default:
        return ''
    }
  }

  if (!editor || !content) {
    return null
  }
  if (variant === 'PLAIN_TEXT_OUTPUT') {
    return (
      <Flex
        fontSize={fontSize}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        w={w}
        maxH={maxH}
        overflow={noScroll ? 'clip' : 'scroll'}
      >
        {editor.getText()}
      </Flex>
    )
  }
  if (variant === 'HTML_OUTPUT') {
    return (
      <TextPane
        text={formatHtml(editor.getHTML())}
        textStyle={textStyle}
        background={bg}
        pt={6}
        border="1px"
        borderColor={borderColor}
        w={w}
      />
    )
  }

  // Rich text output
  return (
    <Flex
      flexDir={flexDir}
      maxH={maxH}
      px={px}
      py={py}
      overflow={overflow}
      textOverflow="clip"
      wordBreak="break-word"
      border={border}
      w={w}
      bg={bg}
      borderRadius={borderRadius}
      borderColor={borderColor}
      mx={mx}
      my={my}
      ml={ml}
      transform={transform}
      textStyle={textStyle}
      textAlign={textAlign}
      lineHeight={getLineHeight()}
    >
      <EditorContent editor={editor} />
    </Flex>
  )
}
export default HtmlPane
