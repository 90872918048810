import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Flex, ResponsiveValue } from '@chakra-ui/react'
import { ReactElement, useState } from 'react'
import Divider from './Divider'
import Heading from './Heading'

const ExpandableSectionCard: React.FC<{
  title?: string
  headerChildren?: ReactElement
  children: ReactElement
  mt?: number
  mr?: number
  mx?: ResponsiveValue<number>
  mb?: number
  pb?: number | string
  ml?: number
  w?: number | string
  bg?: string
  isExpanded?: boolean
  border?: string
  borderColor?: string
  boxShadow?: string
  variant?: 'arrow-right' | 'arrow-left' | 'arrow-far-right'
  noDivider?: boolean
}> = ({
  title,
  children,
  headerChildren,
  mt,
  mr,
  mb,
  pb,
  ml,
  boxShadow = 'lg',
  mx = { xxs: 1, tablet: 6 },
  w = 'container.lg',
  bg = 'white',
  isExpanded = false,
  border,
  borderColor,
  variant = 'arrow-right',
  noDivider = false,
}) => {
  const [isCardExpanded, setIsCardExpanded] = useState(isExpanded)

  const toggleAccordian = () => {
    setIsCardExpanded(!isCardExpanded)
  }

  return (
    <Flex
      flexDir="column"
      borderRadius="lg"
      border={border}
      borderColor={borderColor}
      bg={bg}
      boxShadow={boxShadow}
      mt={mt}
      mr={mr}
      mb={mb}
      pb={isCardExpanded ? pb : 0}
      ml={ml}
      maxW={w}
    >
      <Flex
        flexDir="row"
        // mt={isExpanded ? 2 : 0}
        py={isExpanded ? 0 : 2}
        justifyContent="space-between"
        alignItems="center"
        _hover={{ cursor: 'pointer' }}
        onClick={toggleAccordian}
        px={mx}
      >
        <Flex
          flexDir="row"
          alignItems="center"
          w="100%"
          justifyContent={variant === 'arrow-far-right' ? 'space-between' : ''}
          pt={isExpanded ? 2 : 0}
          pr={1}
          pb={2}
        >
          {variant === 'arrow-left' && isCardExpanded && (
            <ChevronUpIcon boxSize={6} />
          )}
          {variant === 'arrow-left' && !isCardExpanded && (
            <ChevronDownIcon boxSize={6} />
          )}
          {headerChildren}
          <Heading mr={2} textStyle="h8">
            {title}
          </Heading>

          {(variant === 'arrow-right' || variant === 'arrow-far-right') &&
            isCardExpanded && <ChevronUpIcon boxSize={6} />}
          {(variant === 'arrow-right' || variant === 'arrow-far-right') &&
            !isCardExpanded && <ChevronDownIcon boxSize={6} />}
        </Flex>
      </Flex>
      <Box mx={mx}>
        {isCardExpanded && (
          <>
            {!noDivider && <Divider my={1} />}
            {children}
          </>
        )}
      </Box>
    </Flex>
  )
}
export default ExpandableSectionCard
