import { Button, useDisclosure } from '@chakra-ui/react'
import { Article, Tag } from '@contracts/support'
import { ReactElement } from 'react'
import { IconName } from '../../common/icon-utils'
import AddTag from '../tags/AddTag'
import { Icon } from './Icon'
import Modal from './Modal'

const AddTagButton: React.FC<{
  variant: 'ICON' | 'BUTTON'
  mt?: number
  mb?: number
  w?: number
  iconName?: IconName
  iconColor?: string
  children?: ReactElement | string
  article: Article
  h?: number
  label?: string
  updateCallbackHandler: (tags: Tag[]) => void
}> = ({
  mt,
  mb,
  children,
  w,
  article,
  h,
  iconName = 'IoAddCircleOutline',
  iconColor = 'primary_orange02',
  label,
  variant,
  updateCallbackHandler,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getUIComponent = () => {
    if (variant === 'ICON') {
      return (
        <Icon
          name={iconName}
          _hover={{ color: 'gray' }}
          color={iconColor}
          onClick={onOpen}
        />
      )
    }
    return (
      <Button
        onClick={onOpen}
        mt={mt}
        mb={mb}
        variant="primary"
        w={w || 32}
        h={h}
      >
        {children}
        {label}
      </Button>
    )
  }
  return (
    <>
      {getUIComponent()}
      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AddTag
          article={article}
          onCloseModal={onClose}
          updateCallbackHandler={updateCallbackHandler}
        />
      </Modal>
    </>
  )
}
export default AddTagButton
