import { Box, Flex } from '@chakra-ui/react'
import { ProjectPlaceResponse } from '@contracts/projects'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { PATH_PROJECT_PLACE } from '../../common/nav'
import ProjectPlaceBadge from '../../components/projects/ProjectPlaceBadge'
import StringShortener from '../../components/ui/StringShortener'

const ListProjectPlacesRowFlex: React.FC<{
  bg: string
  accordionsToShow: string[]
  projectPlace: ProjectPlaceResponse
  toggleAccordion: (id: string) => void
}> = ({ projectPlace, bg, accordionsToShow, toggleAccordion }) => {
  const navigate = useNavigate()
  const addressStr = `${projectPlace.street}, ${projectPlace.city}`
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const projectPlaceLink = `${PATH_PROJECT_PLACE}/${projectPlace.id}`

  return (
    // <>
    <Flex
      bg={bg}
      data-cy="projectPlaces"
      flexDir="row"
      w={{ xxs: '100%', tablet: '90vw' }}
      columnGap={2}
      py={4}
      px={2}
      textStyle="textMedium"
    >
      <Box
        pr={2}
        color="primary_orange02"
        _hover={{ color: 'primary_orange01' }}
      >
        {!accordionsToShow.includes(projectPlace.id) && (
          <AiOutlinePlusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(projectPlace.id)
            }}
          />
        )}
        {accordionsToShow.includes(projectPlace.id) && (
          <AiOutlineMinusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(projectPlace.id)
            }}
          />
        )}
      </Box>
      <Flex wrap="wrap" rowGap={2}>
        <Box
          minW={28}
          onClick={() => {
            navigate(projectPlaceLink)
          }}
          _hover={{ cursor: 'pointer' }}
        >
          <StringShortener title={projectPlace.name} maxLength={15} />
        </Box>

        <Box
          onClick={() => {
            navigate(projectPlaceLink)
          }}
          _hover={{ cursor: 'pointer' }}
        >
          <StringShortener title={addressStr} maxLength={25} />
        </Box>
        <Flex justifyContent="space-around" w={64}>
          <Box
            onClick={() => {
              navigate(projectPlaceLink)
            }}
            _hover={{ cursor: 'pointer' }}
          >
            <ProjectPlaceBadge variant="status" status={projectPlace.status} />
          </Box>
          <Box
            onClick={() => {
              navigate(projectPlaceLink)
            }}
            _hover={{ cursor: 'pointer' }}
          >
            <ProjectPlaceBadge variant="phase" phase={projectPlace.phase} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default ListProjectPlacesRowFlex
