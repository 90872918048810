import {
  CreateOrganizationCollaboratorRequest,
  CreateOrganizationCollaboratorResponse,
  CreateOrganizationRequest,
  OrganizationCollaborator,
  UpdateOrganizationCollaboratorRequest,
  UpdateOrganizationRequest,
} from '@contracts/accounts'
import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.ACCOUNTS_SERVICE_URL as string

export const useOrganizationCollaborator = (
  organizationId: string | undefined,
): SWRResponse<OrganizationCollaborator | undefined> => {
  return useSWR<OrganizationCollaborator | undefined>(
    organizationId ? `${baseUrl}/organizations/${organizationId}` : null,
  )
}

export const useOrganizationCollaborators = (
  organizationId: string | undefined,
): SWRResponse<OrganizationCollaborator[] | undefined> => {
  return useSWR<OrganizationCollaborator[] | undefined>(
    organizationId
      ? `${baseUrl}/organizations/${organizationId}/collaborators`
      : null,
  )
}

export const useEndorsingCollaborators = (
  organizationId: string | undefined,
): SWRResponse<OrganizationCollaborator[] | undefined> => {
  return useSWR<OrganizationCollaborator[] | undefined>(
    organizationId
      ? `${baseUrl}/organizations/${organizationId}/endorsing-collaborators`
      : null,
  )
}

export const addOrganizationCollaborator = async (
  organizationId: string,
  requestData: CreateOrganizationCollaboratorRequest,
): Promise<CreateOrganizationCollaboratorResponse> => {
  // eslint-disable-next-line no-console
  console.log('requestData: ', requestData)
  return axios
    .post<
      CreateOrganizationRequest,
      AxiosResponse<CreateOrganizationCollaboratorResponse>
    >(`${baseUrl}/organizations/${organizationId}/collaborators`, requestData, {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info(
        'Response from server for new organization collaborator',
        status,
        data,
      )
      return data
    })
}

export const updateOrganizationCollaborator = async (
  organizationId: string,
  organizationCollaboratorId: string,
  requestData: UpdateOrganizationCollaboratorRequest,
): Promise<OrganizationCollaborator> => {
  return axios
    .put<UpdateOrganizationRequest, AxiosResponse<OrganizationCollaborator>>(
      `${baseUrl}/organizations/${organizationId}/collaborators/${organizationCollaboratorId}`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for update organization collaborator',
        status,
        data,
      )
      return data
    })
}

export const deleteOrganizationCollaborator = async (
  organizationId: string,
  organizationCollaboratorId: string,
): Promise<OrganizationCollaborator> => {
  return axios
    .delete<UpdateOrganizationRequest, AxiosResponse<OrganizationCollaborator>>(
      `${baseUrl}/organizations/${organizationId}/collaborators/${organizationCollaboratorId}`,
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for update organization collaborator',
        status,
        data,
      )
      return data
    })
}
