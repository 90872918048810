import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Image,
  Checkbox,
  Link as LinkChakra,
  ResponsiveValue,
  useDisclosure,
  Box,
  Editable,
  EditableInput,
  EditablePreview,
} from '@chakra-ui/react'
import { AttachmentSummary } from '@contracts/misc'
import { Attachment } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { MdHistory } from 'react-icons/md'
import { AiOutlineFile } from 'react-icons/ai'
import { useState } from 'react'
import {
  getDateStrFromNumber,
  getUserFriendlyDateStrFromNumber,
} from '../../common/util'

import Link from '../ui/Link'
import Modal from '../ui/Modal'
import Heading from '../ui/Heading'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import { downloadAttachmentUrl } from './attachmentHelper'
import FileIcon from './FileIcon'

const EditAttachmentsCardCompactVersioned: React.FC<{
  attachments: Attachment[]
  minW?: number | string
  maxW?: number | string
  isCheckable?: boolean
  isChecked?: boolean
  onFileChecked?: (attachment: AttachmentSummary) => void
  variant?: 'list-item' | 'normal' | 'thumbnail'
  link?: string
  onOpenNoteCard?: () => void
  onChangeVersionedAttachmentName: (
    attachmentIds: string[],
    newFileName: string,
    mimeType: string,
  ) => Promise<void>
  thumbnailSize?: ResponsiveValue<number | string>

  onDeleteAttachments: (attachmentIds: string[]) => void
}> = ({
  attachments,
  minW = '52',
  maxW, // = 'md',
  isCheckable = false,
  isChecked = false,
  onFileChecked,

  onChangeVersionedAttachmentName,
  variant = 'normal',
  link,
  onOpenNoteCard,
  thumbnailSize = 14,

  onDeleteAttachments,
}) => {
  const { t } = useTranslation()
  const indexOfLatestVersion = attachments.length - 1
  const [fileName, setFileName] = useState(
    attachments[indexOfLatestVersion].originalFilename,
  )
  const {
    isOpen: isInfoModalOpen,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()

  if (!attachments) {
    return null
  }

  const openAttachmentInNewTab = () => {
    window.open(
      downloadAttachmentUrl(
        attachments[indexOfLatestVersion].id,
        attachments[indexOfLatestVersion].originalFilename,
      ),
      '_blank',
    )
  }

  const fileNameChangedHandler = (newFileName: string) => {
    setFileName(newFileName)
    if (newFileName !== attachments[indexOfLatestVersion].originalFilename) {
      void onChangeVersionedAttachmentName(
        attachments.map(at => {
          return at.id
        }),
        newFileName,
        attachments[indexOfLatestVersion].mimeType,
      )
      // }
    }
  }

  const onChange = () => {
    onFileChecked ? void onFileChecked(attachments[indexOfLatestVersion]) : ''
  }

  if (variant === 'thumbnail') {
    if (!attachments[indexOfLatestVersion].areaId) {
      return null
    }
    return (
      <Flex
        flexDir="column"
        pos="relative"
        borderRadius="lg"
        borderColor="gray.200"
        _hover={{ borderColor: 'gray.400' }}
      >
        <Image
          src={downloadAttachmentUrl(
            attachments[indexOfLatestVersion].id,
            attachments[indexOfLatestVersion].originalFilename,
          )}
          boxSize={thumbnailSize}
          fit="cover"
        />
      </Flex>
    )
  }
  return (
    <>
      <Flex
        flexDir="row"
        alignItems=""
        justifyContent="space-between"
        minW={minW}
        maxW={maxW}
        _hover={{ color: 'gray.500', cursor: 'pointer' }}
      >
        {variant === 'normal' && (
          <Flex
            flexDir="row"
            alignItems="center"
            w="100%"
            justifyContent="space-between"
          >
            <Flex alignItems="center" gap={2} mr={2}>
              <FileIcon
                attachment={attachments[indexOfLatestVersion]}
                isMultipleFiles
              />
              <Editable
                textStyle="cardText"
                // ml={2}
                value={fileName}
                submitOnBlur={true}
                onSubmit={fileNameChangedHandler}
                border="1px solid"
                borderColor="gray.100"
                // h={7}
                borderRadius="lg"
                overflow="clip"
                wordBreak="break-all"
                pr={2}
                pl={2}
              >
                <EditablePreview
                  _hover={{ cursor: 'pointer' }}
                  overflow="clip"
                  w={{ tablet: '380px' }}
                />
                <EditableInput
                  onChange={e => {
                    setFileName(e.currentTarget.value)
                  }}
                  border="1px solid"
                  borderColor="gray.200"
                  bg="gray.100"
                  w="100%"
                  minW={{ mobile: 64, tablet: 'sm' }}
                />
              </Editable>
            </Flex>
            <Flex w={24} justifyContent="space-between" alignItems="center">
              <Text textStyle="textSmall" color="gray.500" fontStyle="italic">
                Ver{attachments.length}
              </Text>

              <Box
                color="gray.500"
                _hover={{ color: 'gray.400' }}
                onClick={() => {
                  onOpenInfoModal()
                }}
              >
                <MdHistory size="24px" />
              </Box>
              <AlertDialogAndButton
                title="Radera bifogad fil och dess historik"
                message="Är du säker på att du vill radera bifogad fil och dess historik?"
                onDestructiveAction={() => {
                  onDeleteAttachments(
                    attachments.map(a => {
                      return a.id
                    }),
                  )
                }}
                buttonTitle={''}
                variant="deleteIcon"
              />
            </Flex>
          </Flex>
        )}
        {variant === 'list-item' && (
          <Flex flexDir="row" alignItems="center" textStyle="infoText" w="100%">
            {isCheckable && (
              <Checkbox mr={3} defaultChecked={isChecked} onChange={onChange} />
            )}

            <Flex
              justifyContent="space-between"
              w="100%"
              gap={2}
              alignItems="center"
            >
              <LinkChakra
                as="a"
                onClick={openAttachmentInNewTab}
                textStyle="infoTextLight"
                w="100%"
                _hover={{ textDecoration: 'none' }}
              >
                <Flex
                  justifyContent="space-between"
                  w="100%"
                  gap={2}
                  alignItems="center"
                >
                  <Text
                    h={{ xxs: 5, tablet: 7 }}
                    wordBreak="break-all"
                    overflow="clip"
                  >
                    {attachments[indexOfLatestVersion].originalFilename}
                  </Text>

                  <Flex gap={2} alignItems="center" minW={16}>
                    <Text textStyle="textSmallFixed">
                      {getDateStrFromNumber(
                        attachments[indexOfLatestVersion].createdAt,
                      )}
                    </Text>
                  </Flex>
                </Flex>
              </LinkChakra>

              {link && (
                <Link to={link} onClick={onOpenNoteCard}>
                  <ExternalLinkIcon mb={1} fontSize="14px" ml={0} />
                </Link>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {t('attachments.earlierVersionsTitle')}
          </Heading>

          <Text textStyle="infoText">
            {attachments[indexOfLatestVersion].originalFilename}
          </Text>
          {attachments?.map((attachment, index) => {
            return (
              <Flex
                gap={2}
                key={attachment.id}
                alignItems="center"
                w="100%"
                justifyContent="space-between"
              >
                <Flex alignItems="center" gap={2}>
                  <AiOutlineFile />

                  <Text textStyle="infoTextLight" fontStyle="italic">
                    Version {index + 1}
                  </Text>
                </Flex>
                <Text textStyle="infoTextLight">
                  {getUserFriendlyDateStrFromNumber(attachment.createdAt)}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Modal>
    </>
  )
}
export default EditAttachmentsCardCompactVersioned
