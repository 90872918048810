import {
  Flex,
  CheckboxGroup as ChakraCheckboxGroup,
  Grid,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'

const CheckboxGroup: React.FC<{
  title?: string
  isMandatory?: boolean
  isError?: boolean
  errorText?: string
  children?: ReactElement
  tooltip?: string
  columns?: number
  defaultValue?: (number | string)[]
}> = ({
  title,
  isError,
  errorText,
  isMandatory,
  tooltip,
  children,
  columns,
  defaultValue,
}) => {
  return (
    <ChakraCheckboxGroup defaultValue={defaultValue}>
      <Flex display="flex" flexWrap="nowrap" alignItems="center">
        <Tooltip
          hasArrow
          label={tooltip}
          bg="primaryBackground"
          color="black"
          placement="top"
          cursor="pointer"
        >
          <Flex display="flex" textStyle="infoText" mt={4} alignItems="center">
            {title}
            {isMandatory && <h1>*&nbsp;</h1>}
            <Text textStyle="inputPlaceholder">
              {tooltip && <FaRegQuestionCircle color="inputPlaceholder" />}
            </Text>
          </Flex>
        </Tooltip>
      </Flex>
      <Flex textStyle="errorSmall" m=".5rem 1rem 0 1rem">
        {isError && errorText}
      </Flex>

      <Grid
        textStyle="infoTextLight"
        templateColumns={`repeat(${columns ?? 2}, 1fr)`}
      >
        {children}
      </Grid>
    </ChakraCheckboxGroup>
  )
}
export default CheckboxGroup
