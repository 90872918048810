import { Box, Flex, Text } from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useTranslation } from 'react-i18next'
// import { useAccountAttachments } from '../../apiClients/attachmentsApiClient'
import {
  useProjectsByAccount,
  useProjectsByProperties,
} from '../../apiClients/projectsApiClient/projects'
import { useProperties } from '../../apiClients/propertiesApiClient'
import AuthContext from '../../common/auth-context'
import FileDirectory from '../ui/FileDirectory'
import FileHeader from '../ui/FileHeader'
import FileList from '../ui/FileList'
// import { getSumOfFiles } from './attachmentHelper'

const AccountAttachmentsTreeViewCard: React.FC<{
  account: AccountData
  mt?: string | number
  bg?: string
  minH?: string
}> = ({ account, mt, bg = 'white', minH = 'md' }) => {
  const { t } = useTranslation()
  const { isConsumer, isProvider } = useContext(AuthContext)
  const columnMinW = 64

  // const { data: allAttachments } = useAccountAttachments(account.id)
  const { data: properties } = useProperties(account.id)
  const { data: allProjects } = useProjectsByAccount(account.id)
  const { data: allPropertyProjects } = useProjectsByProperties(
    properties?.map(property => {
      return property.id
    }),
  )
  const [activeLevelOne, setActiveLevelOne] = useState<string>()
  const [fatherOfLevelTwo, setFatherOfLevelTwo] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [fatherOfLevelThree, setFatherOfLevelThree] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [fatherOfLevelFour, setFatherOfLevelFour] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [activeLevelTwo, setActiveLevelTwo] = useState<string>()
  const [activeLevelThree, setActiveLevelThree] = useState<string>()
  // const sumOfFilesSizes = allAttachments ? getSumOfFiles(allAttachments) : 0

  let orphanProjects: ProjectResponse[] = []

  if (allProjects && allPropertyProjects) {
    orphanProjects = allProjects.filter(project => {
      return !allPropertyProjects.find(propProj => {
        return propProj.id === project.id
      })
    })
  }

  // Level 1 click
  const onPropertyClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevelOne(areaId)
    setActiveLevelTwo(undefined)
    setActiveLevelThree(undefined)
    setFatherOfLevelTwo({
      title,
      area,
      areaId,
    })
    setFatherOfLevelThree(undefined)
    setFatherOfLevelFour(undefined)
  }

  // Level 2 click
  const onProjectOrPropertyNoteClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevelTwo(areaId)
    setActiveLevelThree(undefined)

    setFatherOfLevelThree({
      title,
      area,
      areaId,
    })
    setFatherOfLevelFour(undefined)
  }

  // Level 3 click
  const onProjectNoteClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevelThree(areaId)
    setFatherOfLevelFour({
      title,
      area,
      areaId,
    })
  }

  return (
    <>
      {/* <TextCard heading="Filer" mt={mt}> */}
      {/* <Flex flexDir="column" mt={mt} mb={8}>
        <Text>
          {t('attachments.filesInAccount', {
            count: allAttachments?.length,
            size: sumOfFilesSizes,
          })}
        </Text>
        <Text>Antal filer: {allAttachments?.length}</Text>
        <Text>Summa lagrade filer: {sumOfFilesSizes} MB</Text>
      </Flex> */}
      {/* </TextCard> */}

      <Flex flexDir="row" gap={1} mb={8} minH="32" mt={mt}>
        {/* Level 1 - properties */}

        <Flex
          flexDir="column"
          gap={0}
          minW={columnMinW}
          minH={minH}
          bg={bg}
          border="1px solid"
          borderRadius="lg"
          borderColor="gray.200"
          boxShadow="lg"
          py={2}
        >
          {/* No properties */}
          {isConsumer &&
            (!properties || properties.length === 0) &&
            (!orphanProjects || orphanProjects.length === 0) && (
              <Text textStyle="cardText" my={2} mx={2}>
                {t('attachments.noPropertiesNorProjectsFound')}
              </Text>
            )}
          {/* No projects */}
          {isProvider && (!orphanProjects || orphanProjects.length === 0) && (
            <Text textStyle="cardText" my={2} mx={2}>
              {t('attachments.noProjectsFound')}
            </Text>
          )}

          {properties && properties.length > 0 && (
            <FileHeader area={'property'} />
          )}
          {properties &&
            properties.length > 0 &&
            properties.map(property => {
              return (
                <Box px={4} overflow="clip" key={property.id}>
                  <FileDirectory
                    title={property.name}
                    area="property"
                    areaId={property.id}
                    isActive={property.id === activeLevelOne}
                    callback={onPropertyClickHandler}
                  />
                </Box>
              )
            })}

          {orphanProjects && orphanProjects.length > 0 && (
            <FileHeader area={'project'} />
          )}
          {orphanProjects &&
            orphanProjects.length > 0 &&
            orphanProjects.map(project => {
              return (
                <Box px={4} overflow="clip" key={project.id}>
                  <FileDirectory
                    title={project.name}
                    area="project"
                    areaId={project.id}
                    isActive={project.id === activeLevelOne}
                    callback={onPropertyClickHandler}
                  />
                </Box>
              )
            })}
        </Flex>
        {/* Level 2 - projects and property notes */}
        {fatherOfLevelTwo && (
          <Flex
            flexDir="column"
            minW={columnMinW}
            maxW={96}
            bg={bg}
            border="1px solid"
            borderRadius="lg"
            borderColor="gray.200"
            boxShadow="lg"
            py={2}
          >
            {/* <Text>Level 2</Text> */}
            <FileList
              area={fatherOfLevelTwo.area}
              areaId={fatherOfLevelTwo.areaId}
              callback={onProjectOrPropertyNoteClickHandler}
              activeId={activeLevelTwo}
            />

            {/* Always present projects that user is member of but without connection to any property   */}
          </Flex>
        )}
        {/* Level 3 - project notes, property note files */}
        {/* <Text>Level 3</Text> */}
        {fatherOfLevelThree && (
          <Flex
            flexDir="column"
            minW={columnMinW}
            maxW={72}
            bg={bg}
            border="1px solid"
            borderRadius="lg"
            borderColor="gray.200"
            boxShadow="lg"
            py={2}
          >
            <FileList
              area={fatherOfLevelThree.area}
              areaId={fatherOfLevelThree.areaId}
              callback={onProjectNoteClickHandler}
              activeId={activeLevelThree}
            />
          </Flex>
        )}
        {/* Level 4 - project note files */}
        {fatherOfLevelFour && (
          <Flex
            flexDir="column"
            minW={columnMinW}
            bg={bg}
            border="1px solid"
            borderRadius="lg"
            borderColor="gray.200"
            boxShadow="lg"
            py={2}
          >
            {/* <Text>Level 4</Text> */}
            <FileList
              area="projectNote"
              areaId={fatherOfLevelFour.areaId}
              callback={onProjectNoteClickHandler}
              activeId={activeLevelThree}
            />
          </Flex>
        )}
      </Flex>
    </>
  )
}
export default AccountAttachmentsTreeViewCard
