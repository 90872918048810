import { Input as ChakraInput, Box } from '@chakra-ui/react'
import React from 'react'

const InputDate: React.FC<{
  title?: string
  value?: string
  isError?: boolean
  errorText?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  position?: 'top' | 'middle' | 'bottom' | 'single'
  name?: string
  my?: number
  mx?: number
  tooltip?: string
  defaultValue?: string
  w?: number | string
}> = ({ mx, my, name, value, onChange, onBlur, defaultValue, w }) => {
  return (
    <Box
      border="1px solid"
      borderColor="inputBorder"
      my={my}
      mx={mx}
      borderRadius={8}
      px={2}
      w={w}
    >
      <ChakraInput
        name={name}
        value={value}
        type="date"
        variant="unstyled"
        textStyle="inputText"
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
      ></ChakraInput>
    </Box>
  )
}
export default InputDate
