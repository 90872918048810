import {
  Box,
  Button,
  Flex,
  Editable,
  EditablePreview,
  EditableInput,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { Note } from '@contracts/misc'
import log from '../../common/log'
import HtmlPane from '../richTextEditor/HtmlPane'
import { Icon } from '../ui/Icon'
import Tiptap from '../richTextEditor/Tiptap'
import { getContentTailOfAttachedImages } from '../attachments/attachmentHelper'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import { useArea } from '../../common/area-context'
import { useMultipleAttachments } from '../../apiClients/attachmentsApiClient'
import Heading from '../ui/Heading'
import { useNoteParts } from './notesHelper'

const NoteCardContent: React.FC<{
  note: Note
  showHtml?: boolean
  propertyId?: string
  projectId?: string
  onNoteHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
}> = ({
  note,
  propertyId,
  projectId,
  showHtml = false,
  onNoteHasUnsavedChanges,
}) => {
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)
  const [name, setName] = useState(note?.name ?? '')
  const [description, setDescription] = useState(note?.description ?? '')

  const { mutate, updateNote } = useNoteParts({ propertyId, projectId })
  const { currentUserCanEdit } = useCollaboratorParts({
    propertyId: propertyId,
    projectId: projectId,
  })
  const applicationInsights = useAppInsightsContext()

  let attachedImgIds: string[] | undefined = undefined
  if (note && note.attachmentsJson && note.attachmentsJson.length) {
    const attachedImgs = note.attachmentsJson.filter(attachmentSummary => {
      return attachmentSummary.mimeType.includes('image')
    })
    attachedImgIds = attachedImgs.map(image => {
      return image.id
    })
  }

  const { data: attachedImages } = useMultipleAttachments(attachedImgIds)

  const saveNoteHandler = async () => {
    const requestData = note
    requestData.description = description
    requestData.name = name

    await updateNote(note.id, requestData)
      .then(() => {
        applicationInsights.trackEvent({
          name:
            noteArea.area === 'property'
              ? 'Property note updated'
              : 'Project note updated',
        })
        if (onNoteHasUnsavedChanges) {
          onNoteHasUnsavedChanges(false)
        }
        mutate ? void mutate() : ''
      })
      .catch(err => {
        log.error({ err: err }, 'Failed to update note')
      })
  }

  return (
    <>
      <Flex flexDir="row" justifyContent="space-between" mr={6}>
        <Editable
          textStyle="h4"
          mx={6}
          defaultValue={name}
          onSubmit={() => {
            void saveNoteHandler()
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} />
          <EditableInput
            onChange={e => {
              setName(e.currentTarget.value)
            }}
            onSubmit={() => {
              void saveNoteHandler()
            }}
          />
        </Editable>
        {!isEditMode && currentUserCanEdit && (
          <Icon
            name={'MdOutlineEdit'}
            color="gray.600"
            _hover={{ color: 'gray.400', cursor: 'pointer' }}
            onClick={() => {
              setIsEditMode(true)
            }}
            mr={2}
            tooltip={t('notes.editDescription')}
          />
        )}
        {isEditMode && (
          <Flex flexDir="row">
            <Button
              mt={2}
              variant="ghost"
              onClick={() => {
                setIsEditMode(false)
                setDescription(note.description ?? '')
              }}
              border="1px"
              borderColor="gray.400"
              size="sm"
            >
              {t('ui.button.cancel')}
            </Button>
            <Button
              variant="primary"
              mt={2}
              ml={2}
              border="1px"
              borderColor="gray.400"
              size="sm"
              onClick={() => {
                void saveNoteHandler()
                setIsEditMode(false)
              }}
            >
              {t('ui.button.update')}
            </Button>
          </Flex>
        )}
      </Flex>

      <Box
        my={4}
        mx={4}
        border="1px"
        borderColor="gray.300"
        borderRadius="lg"
        pos="relative"
        // minH={20}
      >
        {!isEditMode && (
          <>
            <HtmlPane content={description} maxH="Container.sm" />

            {attachedImages?.length && (
              <>
                <Heading textStyle="cardHeading" mx={2} mt={4} mb={2}>
                  Nedan visas bifogade bilder
                </Heading>
                <HtmlPane
                  textStyle="cardText"
                  content={`${' '} ${getContentTailOfAttachedImages(
                    attachedImages,
                  )}`}
                  maxH="Container.sm"
                />
              </>
            )}
          </>
        )}
        {isEditMode && (
          <Tiptap
            content={note.description ?? ''}
            onChange={text => {
              setDescription(text)
              if (onNoteHasUnsavedChanges) {
                onNoteHasUnsavedChanges(true)
              }
            }}
            w="100%"
            area={
              noteArea.area === 'property'
                ? 'PropertyNote.description'
                : 'ProjectNote.description'
            }
            areaId={note?.id}
            parentArea={noteArea.area === 'property' ? 'Property' : 'Project'}
            parentAreaId={noteArea.area === 'property' ? propertyId : projectId}
            container={'private'}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onImgAdded={() => {}}
          />
        )}
      </Box>
      {showHtml && (
        <Box
          my={8}
          mx={4}
          p={2}
          maxH="container.xl"
          overflow="clip"
          border="1px"
          borderColor="gray.300"
          borderRadius="lg"
        >
          <HtmlPane
            maxH="container.sm"
            variant="HTML_OUTPUT"
            content={description}
          />
        </Box>
      )}
    </>
  )
}
export default NoteCardContent
