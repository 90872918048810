import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { ArticleInfo } from '@contracts/support'

import { useNavigate } from 'react-router-dom'
import { getDateStrFromDate } from '../../common/util'
import fallBackImg from '../../img/articles/rectangle2_md.png'
import HtmlPane from '../richTextEditor/HtmlPane'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ArticlePromoSmall: React.FC<{
  articleInfo: ArticleInfo
  mb?: number
  mr?: number | string
  mt?: number
  variant: 'Row' | 'Column'
  w?: number | string
  path?: string
}> = ({ articleInfo, mb, mr = 10, mt, variant, w = '2xs', path }) => {
  const width = '2xs'
  const navigate = useNavigate()
  const [isLaptopOrLarger] = useMediaQuery('(min-width: 481px)')

  const getImgId = () => {
    if (articleInfo.attachmentIdSmall) {
      return articleInfo.attachmentIdSmall
    }
    if (articleInfo.attachmentIdMedium) {
      return articleInfo.attachmentIdMedium
    }
    if (articleInfo.attachmentIdLarge) {
      return articleInfo.attachmentIdLarge
    }
    return undefined
  }
  const imgId = getImgId()

  if (variant === 'Row' && isLaptopOrLarger) {
    return (
      <Flex
        flexDir="row"
        mb={mb}
        mr={{ tablet: mr }}
        mt={mt}
        onClick={() => {
          if (path) {
            navigate(`/${path}/${articleInfo.id}/`)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        }}
        _hover={{ cursor: 'pointer' }}
        maxW="550px"
      >
        <Box mr={4}>
          {imgId && (
            <Image
              src={`${baseUrl}${imgId}`}
              h={32}
              w={{ mobile: '100%', tablet: w }}
              objectFit="cover"
            />
          )}
          {!imgId && (
            <Image src={fallBackImg} objectFit="cover" h={32} w={width} />
          )}
        </Box>
        <Flex flexDir="column" justifyContent="center" maxW="280px" ml={6}>
          <Text fontWeight="bold" fontSize="20px">
            {articleInfo?.heading}
          </Text>
        </Flex>
      </Flex>
    )
  }
  return (
    // variant === 'Column'
    <Flex
      flexDir="column"
      mb={mb}
      mr={{ tablet: mr }}
      maxH={96}
      overflow="clip"
      mt={mt}
      onClick={() => {
        if (path) {
          navigate(`/${path}/${articleInfo.id}`)
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      }}
      _hover={{ cursor: 'pointer' }}
      w={{ mobile: '100%', tablet: w }}
    >
      <Box>
        {imgId && (
          <Image
            src={`${baseUrl}${imgId}`}
            h={32}
            w={{ xxs: '100%', tablet: w }}
            objectFit="cover"
          />
        )}
        {!imgId && (
          <Image
            src={fallBackImg}
            objectFit="cover"
            h={32}
            w={{ xxs: '100%', tablet: w }}
          />
        )}
      </Box>

      <Flex flexDir="column" justifyContent="center">
        <Box overflowWrap="anywhere">
          <Text textStyle="cardText" my={2}>
            {articleInfo?.publishedAt
              ? getDateStrFromDate(new Date(articleInfo?.publishedAt))
              : ''}
          </Text>
          <Text textStyle="h5" w={w}>
            {articleInfo?.heading}
          </Text>
          <Box
            textStyle="CardText"
            my={4}
            h={32}
            w={{ mobile: '100%', tablet: w }}
          >
            <HtmlPane
              content={articleInfo?.intro}
              variant="PLAIN_TEXT_OUTPUT"
              bg="transparent"
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}
export default ArticlePromoSmall
