import { CheckCircleIcon } from '@chakra-ui/icons'
import { Circle, Flex, Show, Text } from '@chakra-ui/react'
import { ProjectPhase } from '@contracts/projects'
import { useTranslation } from 'react-i18next'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import ConstructionProcessCardModal from '../provider/ConstructionProcessCardModal'
import Heading from '../ui/Heading'

const Step: React.FC<{
  phase: ProjectPhase
  isCompleted: boolean
  isActive?: boolean

  variant?: 'full' | 'mobile'
}> = ({ phase, isCompleted, isActive, variant = 'full' }) => {
  const { t } = useTranslation()

  const getStatusIcon = () => {
    {
      if (isActive) {
        return (
          <>
            <Show above="desktop">
              <BsFillArrowRightCircleFill color="#CF8723" fontSize={38} />
            </Show>
            <Show below="desktop">
              <BsFillArrowRightCircleFill color="#CF8723" fontSize={26} />
            </Show>
          </>
        )
      }
      if (!isCompleted) {
        return (
          <Circle
            size={{ xxs: 7, desktop: 10 }}
            bg="white"
            border="2px solid"
            borderColor="primary_orange02"
          />
        )
      }
    }
    if (isCompleted) {
      return (
        <CheckCircleIcon
          color="primary_orange02"
          fontSize={{ xxs: '2xl', desktop: '4xl' }}
        />
      )
    }
  }

  return (
    <ConstructionProcessCardModal
      projectPhase={phase}
      // w={{ xxs: 24, tablet: 36, laptop: 44, desktop: 52 }}
    >
      {/* <Flex
        alignItems="center"
        gap={2}
        flexBasis={w}
        flexGrow={0}
        flexShrink={0}
        _hover={{ cursor: 'pointer', bg: 'primary_grey04' }}
        py={1}
        px={{ xxs: 0, tablet: 2 }}
        borderRadius="lg"
      > */}
      <>
        {getStatusIcon()}
        <Flex flexDir="column">
          <Show above="tablet">
            {/* <Heading textStyle="h7" h={{ xxs: 5, desktop: 5 }}> */}
            <Heading textStyle="h7" lineHeight={{ xxs: 5, desktop: 5 }}>
              {t(`projects.phaseSteps.${phase.toLowerCase()}.title`)}
            </Heading>
          </Show>
          <Show below="tablet">
            <Heading textStyle="h7" lineHeight={{ xxs: 5, desktop: 5 }}>
              {t(`projects.phaseSteps.${phase.toLowerCase()}.shortTitle`)}
            </Heading>
          </Show>
          {variant === 'full' && (
            <Text textStyle="regularLightResponsive">
              {t(`projects.phaseSteps.${phase.toLowerCase()}.description`)}
            </Text>
          )}
        </Flex>
        {/* </Flex> */}
      </>
    </ConstructionProcessCardModal>
  )
}
export default Step
