import { Box } from '@chakra-ui/react'

const LinkArea: React.FC<{
  top?: number
  left?: number | string
  bottom?: number
  right?: number | string
  w?: number | string
  h?: number | string
  onClick: () => void
  isActive?: boolean
}> = ({ top, left, bottom, right, w = 'lg', h = 20, onClick, isActive }) => {
  const bg = 'gray'
  const opacity = 0.2

  return (
    <Box
      pos="absolute"
      top={
        top
          ? { xxs: top + 2, mobile: +top + 2, tablet: top + 1, laptop: top }
          : undefined
      }
      left={left}
      bottom={
        bottom
          ? { xxs: bottom + 2.5, tablet: bottom + 2, laptop: bottom + 1 }
          : undefined
      }
      right={right}
      w={w}
      h={h}
      bg={isActive ? bg : ''}
      opacity={isActive ? opacity : ''}
      borderColor={isActive ? 'gray:600' : 'gray.300'}
      _hover={{
        bg: `${bg}`,
        opacity: `${opacity}`,
        cursor: 'pointer',
      }}
      borderRadius="lg"
      onClick={onClick}
    />
  )
}
export default LinkArea
