import { Badge, ResponsiveValue } from '@chakra-ui/react'
import { ProjectPlacePhase, ProjectPlaceStatus } from '@contracts/projects'
import { useTranslation } from 'react-i18next'
import log from '../../common/log'

const ProjectPlaceBadge: React.FC<{
  variant: 'status' | 'phase'
  status?: ProjectPlaceStatus
  phase?: ProjectPlacePhase
  w?: ResponsiveValue<string | number>
}> = ({ variant, w, status, phase }) => {
  const { t } = useTranslation()

  let title = ''
  let colorScheme
  let bg
  let color

  if (variant === 'phase' && !phase) {
    log.error('Error - must provide phase')
    return null
  }
  if (variant === 'status' && !status) {
    log.error('Error - must provide status')
    return null
  }
  if (variant === 'phase') {
    title = t(`projectPlaces.phase.${phase ?? 'noData'}`)
    switch (phase) {
      case 'Offer':
        color = 'logo_green01'
        bg = 'primary_green01'
        break
      case 'Design':
        color = 'logo_orange'
        bg = '#FFF0D2'
        // colorScheme = 'gray'
        break
      case 'Production':
        color = 'logo_purple'
        bg = '#FFDBED'
        // colorScheme = 'pink'
        break
      case 'Warranty':
        color = 'primary_grey04'
        bg = '#CCD4C5'
        // colorScheme = 'gray'
        break
      default:
        colorScheme = 'red'
    }
  }
  if (variant === 'status') {
    title = t(`projectPlaces.status.${status ?? 'noData'}`)
    switch (status) {
      case 'Draft':
        color = 'logo_brown'
        bg = '#FDE0CD'
        // colorScheme = 'purple'
        break
      case 'Ongoing':
        color = 'primary'
        bg = '#CAEBEA'
        // colorScheme = 'cyan'
        break
      case 'Cancelled':
        // color = ''
        // bg = ''
        colorScheme = 'red'
        break
      case 'Done':
        color = 'primary_orange03'
        bg = '#E9C799'
        // colorScheme = 'blue'
        break
      default:
        // color = ''
        // bg = ''
        colorScheme = 'red'
    }
  }

  return (
    <Badge
      w={w}
      colorScheme={colorScheme}
      bg={bg}
      color={color}
      borderRadius="full"
      px={2}
    >
      {title}
    </Badge>
  )
}
export default ProjectPlaceBadge
