import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { usePropertyInfo } from '../../apiClients/propertiesApiClient/properties'
import { Link } from '../../ui'
import { useImageUrl } from '../attachments/attachmentHelper'

const PropertyOrProjectAvatar: React.FC<{
  area: 'property' | 'project'
  areaId: string
  variant: 'plain' | 'avatarAndName'
  mr?: number | string
  mt?: number | string
  mb?: number | string
  link?: string
  w?: string | number
}> = ({ area, areaId, variant, mr, mt, mb, link, w }) => {
  const { data: property } = usePropertyInfo(
    area === 'property' ? areaId : undefined,
  )
  const { data: project } = useProjectInfo(
    area === 'property' ? undefined : areaId,
  )
  const imgUrl = useImageUrl(
    area === 'property'
      ? property?.imageAttachmentId
      : project?.imageAttachmentId,
  )
  const name = area === 'property' ? property?.name : project?.name

  switch (variant) {
    case 'avatarAndName':
      return (
        <Flex
          flexDir="row"
          justifyContent="left"
          alignItems="center"
          mr={mr}
          mt={mt}
          mb={mb}
          w={w}
        >
          {link && (
            <Link to={link}>
              <Flex flexDir="row" alignItems="center">
                <Avatar name={name} src={imgUrl} size="sm" />
                <Text ml={3} fontStyle={!project ? 'italic' : ''}>
                  {name}
                </Text>
                <ExternalLinkIcon ml={2} color="gray.400" />
              </Flex>
            </Link>
          )}
          {!link && (
            <>
              <Avatar name={name} src={imgUrl} size="sm" />

              <Text ml={3} fontStyle={!project ? 'italic' : ''}>
                {name}
              </Text>
            </>
          )}
        </Flex>
      )
    case 'plain':
      return <Avatar name={name} src={imgUrl} size="sm" mr={mr} />
  }
}
export default PropertyOrProjectAvatar
