import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import { getDateStrFromDate } from '../../common/util'
import { Logo } from '../ui/Logo'

const AccountNameTag: React.FC<{
  account: AccountData
  mt?: string | number
  mr?: string | number
  mb?: string | number
  ml?: ResponsiveValue<string | number>
}> = ({ account, mt, mr, mb, ml }) => {
  const { t } = useTranslation()
  return (
    <Flex flexDir="column" mt={mt} mr={mr} mb={mb} ml={ml} zIndex="base">
      <Text textStyle="h2">
        {account.firstName} {account.lastName}
      </Text>
      <Flex
        flexDir="row"
        wrap="wrap"
        textStyle="xlLight"
        alignItems="center"
        flexWrap="wrap"
        pb={1}
        borderBottomRadius="lg"
      >
        <Logo name="Berikar" h={7} />
        <Text ml={1} textStyle="smLight">
          {t('accounts.withBerikarSince', {
            createdAt: getDateStrFromDate(new Date(account.createdAt)),
          })}
        </Text>
      </Flex>
    </Flex>
  )
}
export default AccountNameTag
