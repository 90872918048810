import { Badge, Box, Flex, HStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { navLinksAdmin } from '../../common/nav'
import { Icon } from '../../components/ui/Icon'
import Link from '../../components/ui/Link'

const AdminNav = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const pathnameWithoutTrailingSlack = pathname.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname

  return (
    <Flex
      flexDir="row"
      background="primaryBackground"
      pt={4}
      pl={2}
      alignItems="flex-start"
      zIndex="inherit"
    >
      <Flex
        flexDir="row"
        flexWrap="wrap"
        pt={1}
        mt={{ xxs: 12, laptop: 12 }}
        w="100vw"
        maxW={{ xxs: 'lg', laptop: 'container.sm', desktop: 'container.xl' }}
        alignItems="baseline"
        gap={6}
      >
        <Badge
          background="logo_green01"
          color="white"
          lineHeight={8}
          px={2}
          borderRadius={6}
          h={8}
        >
          {t('admin.adminName')}
        </Badge>
        {navLinksAdmin.map(link => {
          return (
            <HStack key={link.to} position="relative">
              <Link
                to={link.to}
                textStyle={
                  pathnameWithoutTrailingSlack === link.to ? 'h5' : 'h6'
                }
                color={
                  pathnameWithoutTrailingSlack === link.to
                    ? 'logo_purple'
                    : 'black'
                }
              >
                {t(link.value)}
              </Link>
              {link.underConstruction && (
                <Box transform="translate(-.9rem,-.6rem)">
                  <Icon name="underConstruction" h={5} />
                </Box>
              )}
            </HStack>
          )
        })}
      </Flex>
    </Flex>
  )
}
export default AdminNav
