import { useContext } from 'react'
import {
  Avatar,
  Badge,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthContext from '../common/auth-context'
import {
  PATH_ADMIN_PAGES,
  PATH_MY_PAGES_PROFILE,
  PATH_MY_PRIVATE_PAGES,
  PATH_MY_PROVIDER_PAGES,
  PATH_MY_PROVIDER_PAGES_USER_PROFILE,
  PATH_PROVIDER_PROFILE,
  PATH_PUBLIC_WEB,
} from '../common/nav'
import { useImageUrl } from '../components/attachments/attachmentHelper'

const ProfileAvatarMenu = () => {
  const {
    isAdmin,
    isConsumer,
    isProvider,
    currentUser,
    currentOrganization,
    logout,
  } = useContext(AuthContext)

  const imgUrl = useImageUrl(currentUser?.profileAttachmentId)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const name = `${currentUser?.firstName} ${currentUser?.lastName}`
  const menuItemPaddingy = 2
  const menuItemPaddingX = 6

  return (
    <Flex zIndex="overlay">
      <Menu computePositionOnMount={true}>
        <MenuButton pos="relative">
          <Avatar
            // pos="relative"
            name={name}
            bg={!imgUrl ? 'secondary' : ''}
            zIndex="overlay"
            src={imgUrl}
          />
        </MenuButton>
        <MenuList boxShadow="lg" borderColor="gray.300" bgSize="cover" w="64">
          <>
            <HStack my={4} mx={4} spacing={4}>
              <Avatar
                size="md"
                name={name}
                bg={!imgUrl ? 'secondary' : ''}
                src={imgUrl}
              />
              <Text fontWeight="bold">{name}</Text>
            </HStack>

            {isProvider && (
              <Badge bg="primary" ml={2}>
                <Text textStyle="textXSmall" color="white">
                  {t('general.company')}
                </Text>
              </Badge>
            )}
            <Text textStyle="h7" color="primary" px={menuItemPaddingX}>
              {isProvider ? '' : t('general.privateAccount')}
            </Text>
            {isProvider && (
              <Text
                textStyle="h7"
                color="primary"
                px={menuItemPaddingX}
                pb={menuItemPaddingy}
              >
                {currentOrganization
                  ? currentOrganization.name
                  : t('portal.sidebar.provider.noOrgConnected')}
              </Text>
            )}
            <MenuDivider mx={6} />
            <MenuItem
              py={menuItemPaddingy}
              px={menuItemPaddingX}
              textStyle="textThin"
              onClick={() => {
                window.open(PATH_PUBLIC_WEB)
              }}
            >
              berikar.se
            </MenuItem>

            <MenuItem
              py={menuItemPaddingy}
              px={menuItemPaddingX}
              textStyle="textThin"
              onClick={() => {
                isConsumer
                  ? navigate(PATH_MY_PRIVATE_PAGES)
                  : navigate(PATH_MY_PROVIDER_PAGES)
              }}
            >
              {isConsumer ? t('general.myPages') : t('general.myProviderPages')}
            </MenuItem>

            {isProvider && (
              <MenuItem
                py={menuItemPaddingy}
                px={menuItemPaddingX}
                textStyle="textThin"
                onClick={() => {
                  navigate(PATH_PROVIDER_PROFILE)
                }}
              >
                {t('portal.sidebar.provider.companyProfile')}
              </MenuItem>
            )}
            {isAdmin && !pathname.startsWith(PATH_ADMIN_PAGES) && (
              <MenuItem
                py={menuItemPaddingy}
                px={menuItemPaddingX}
                textStyle="textThin"
                onClick={() => {
                  navigate(PATH_ADMIN_PAGES)
                }}
              >
                {t('general.adminPages')}
              </MenuItem>
            )}
            <MenuItem
              py={menuItemPaddingy}
              px={menuItemPaddingX}
              textStyle="textThin"
              onClick={() => {
                navigate(
                  isProvider
                    ? PATH_MY_PROVIDER_PAGES_USER_PROFILE
                    : PATH_MY_PAGES_PROFILE,
                )
              }}
            >
              {isConsumer
                ? t('accounts.editProfile')
                : t('portal.sidebar.provider.myUserProfile')}
            </MenuItem>

            <MenuDivider mx={menuItemPaddingX} />
            <MenuItem
              py={menuItemPaddingy}
              px={menuItemPaddingX}
              onClick={logout}
              textStyle="textThin"
            >
              {t('auth.logout')}
            </MenuItem>
          </>
        </MenuList>
      </Menu>
    </Flex>
  )
}
export default ProfileAvatarMenu
