import { Box, Flex, Image } from '@chakra-ui/react'
import { useContext } from 'react'
import {
  useInstagramMedia,
  useOrganizationProfile,
  useOrganizationProfileAdmin,
} from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import InstaPostCardModal from '../accounts/InstaPostCardModal'
import { isOutOfDateOrInvalid } from '../../common/util'
import log from '../../common/log'
import GalleryCard from '../ui/GalleryCard'
import { Icon } from '../ui/Icon'

const InstaPostsSection: React.FC<{
  organizationId: string
}> = ({ organizationId }) => {
  const iconPadding = 2
  const { isAdmin } = useContext(AuthContext)

  const { data: organizationProfileNotAdmin } = useOrganizationProfile(
    isAdmin ? undefined : organizationId,
  )
  const { data: organizationProfileAdmin } = useOrganizationProfileAdmin(
    isAdmin ? organizationId : undefined,
  )

  const organizationProfile = isAdmin
    ? organizationProfileAdmin
    : organizationProfileNotAdmin

  const { data: instaPosts } = useInstagramMedia(
    isOutOfDateOrInvalid(
      organizationProfile?.instagramTokenValidTo ?? undefined,
    )
      ? undefined
      : organizationId,
  )

  if (!organizationProfile) {
    return null
  }

  return (
    <Flex flexDir="column" mt={4}>
      <Flex gap={2} wrap="wrap">
        {instaPosts &&
          instaPosts.length > 0 &&
          instaPosts.map(instaPost => {
            if (instaPost.media_type === 'IMAGE') {
              return (
                <InstaPostCardModal
                  key={instaPost.id}
                  src={instaPost.media_url}
                  caption={instaPost.caption}
                  timestamp={instaPost.timestamp}
                  variant="IMAGE"
                >
                  <Image
                    src={instaPost.media_url}
                    boxSize={{ xxs: '175px', tablet: '200px' }}
                    // border="1px solid"
                    // borderColor="gray.300"
                    // borderRadius="lg"
                    fit="cover"
                  />
                </InstaPostCardModal>
              )
            }
            if (instaPost.media_type === 'VIDEO') {
              return (
                <InstaPostCardModal
                  key={instaPost.id}
                  src={instaPost.media_url}
                  caption={instaPost.caption}
                  timestamp={instaPost.timestamp}
                  variant="VIDEO"
                >
                  <Box
                    // borderRadius="lg"
                    // border="1px solid red"
                    maxW={{ xxs: '175px', tablet: '200px' }}
                    maxH={{ xxs: '175px', tablet: '200px' }}
                    overflow="scroll"
                    pos="relative"
                  >
                    <video style={{ zIndex: -1 }}>
                      <source src={instaPost.media_url} />
                    </video>
                    <Box pos="absolute" top={iconPadding} right={iconPadding}>
                      <Icon name={'videoInsta'} />
                    </Box>
                  </Box>
                </InstaPostCardModal>
              )
            }
            if (instaPost.media_type === 'CAROUSEL_ALBUM') {
              if (!instaPost.children?.length) {
                return (
                  <InstaPostCardModal
                    key={instaPost.id}
                    src={instaPost.media_url}
                    caption={instaPost.caption}
                    timestamp={instaPost.timestamp}
                    variant="IMAGE"
                  >
                    <Image
                      src={instaPost.media_url}
                      boxSize={{ xxs: '175px', tablet: '200px' }}
                      // border="1px solid"
                      // borderColor="gray.300"
                      fit="cover"
                    />
                  </InstaPostCardModal>
                )
              }

              return (
                <InstaPostCardModal
                  key={instaPost.id}
                  src={instaPost.media_url}
                  caption={instaPost.caption}
                  timestamp={instaPost.timestamp}
                  variant="CAROUSEL_ALBUM"
                  carouselChildren={instaPost.children}
                >
                  <Box pos="relative">
                    <GalleryCard
                      boxSize={{ xxs: '175px', tablet: '200px' }}
                      items={instaPost.children}
                      showNav={false}
                    />
                    <Box pos="absolute" top={iconPadding} right={iconPadding}>
                      <Icon name={'carouselInsta'} />
                    </Box>
                  </Box>
                </InstaPostCardModal>
              )
            }

            log.warn(
              `Insta media type ${instaPost.media_type} is not supported, will not show`,
            )
          })}
      </Flex>
    </Flex>
  )
}
export default InstaPostsSection
