import { CloseIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBuildingPart } from '../../apiClients/contentApiClient'
import log from '../../common/log'
import Heading from '../ui/Heading'

const BuildingPartCard: React.FC<{
  code: string
  onClose?: () => void
  w?: number | string
}> = ({ code, onClose, w = 72 }) => {
  const buildingPart = useBuildingPart(code)
  const { t } = useTranslation()

  if (!buildingPart) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    log.error(`No building part found for id '${buildingPart}'`)
    return null
  }
  return (
    <Flex
      flexDir="column"
      bg="white"
      boxShadow="lg"
      border="1px"
      borderColor="gray.200"
      px={6}
      py={3}
      textAlign="center"
      borderRadius="md"
      w={w}
    >
      <CloseIcon
        pos="absolute"
        right={3}
        boxSize={3}
        mr={0}
        color="gray.300"
        onClick={onClose}
        _hover={{ cursor: 'pointer', color: 'gray.600' }}
      />
      <Heading textStyle="h5">{buildingPart.title.toUpperCase()}</Heading>
      <Heading textStyle="h3" color="primary">
        {`${buildingPart.partOfTotalImpact}%`}
      </Heading>
      <Text color="primary" fontSize="2xs" fontWeight="light">
        {t('climate.ofTotalImpact')}
      </Text>
      <Text fontSize=".9rem" fontWeight="light" textAlign="center">
        {buildingPart.description}
      </Text>
    </Flex>
  )
}
export default BuildingPartCard
