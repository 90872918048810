import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../common/auth-context'
import EditProfile from '../../components/accounts/EditProfile'
import EditOrgAccountRoleForm from '../../components/organizations/EditOrgAccountForm'
import EditPasswordSection from '../../components/sections/portal/EditPasswordSection'

import ProfileHeaderSection from '../../components/sections/portal/ProfileHeaderSection'
import Divider from '../../components/ui/Divider'

const AccountProfilePage = () => {
  const { t } = useTranslation()
  const { currentUser, currentOrganization } = useContext(AuthContext)
  return (
    <>
      <Helmet>
        <title>{t('pageTitle.profile')}</title>
      </Helmet>
      <ProfileHeaderSection />

      <EditProfile px={{ xxs: 2, tablet: '5%' }} mt={6} maxW="lg" />
      <Divider />
      {currentUser && currentOrganization && (
        <>
          <EditOrgAccountRoleForm
            orgId={currentOrganization?.id}
            accountId={currentUser?.id}
            px={{ xxs: 2, tablet: '5%' }}
            maxW="lg"
          />
          <Divider />
        </>
      )}
      <EditPasswordSection px={{ xxs: 2, tablet: '5%' }} maxW="lg" />
    </>
  )
}
export default AccountProfilePage
