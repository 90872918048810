import { Text } from '@chakra-ui/react'

import { HelpRequestResponse } from '@contracts/misc'
import { useContext } from 'react'
import { useOrganization } from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'

const HelpRequestStatusMessage: React.FC<{ helpReq: HelpRequestResponse }> = ({
  helpReq,
}) => {
  const { isConsumer, isProvider } = useContext(AuthContext)
  const { data: org } = useOrganization(helpReq.organizationId)
  /*
  // Provider Statuses
  //
  // | 'New' - unAnswered
  // | 'Draft' - unAnswered
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  //
  // Consumer Statuses
  //
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  */
  const getMessage = () => {
    if (isConsumer && org) {
      // Consumer Statuses
      // | 'Ongoing' - active
      // | 'Accepted' - archived
      // | 'Rejected' - archived
      // | 'Deleted' - archived
      switch (helpReq.consumerStatus) {
        case 'Ongoing':
          switch (helpReq.providerStatus) {
            case 'New':
              return `${org.name} har mottagit din förfrågan men inte svarat på den än. Om du har några frågor skicka ett meddelande till ${org.name} nedan`
            case 'Draft':
              return `${org.name} har börjat arbeta med din förfrågan.`
            case 'Ongoing':
              return `Du har fått en offert från ${org.name} som väntar på ditt svar. Om du har några frågor skicka ett meddelande till ${org.name} nedan `
            case 'Rejected':
              return `${'byggaren'} har tackat nej till din förfrågan. Skicka gärna ett meddelande nedan om du har några frågor.`
            case 'Accepted':
              // Decide how to handle provider "Accept"
              return `Du har fått en offert från ${org.name} som väntar på ditt svar. Om du har några frågor skicka ett meddelande till ${org.name} nedan `
            case 'Deleted':
              return `${'byggaren'} har tackat nej till din förfrågan. Skicka gärna ett meddelande nedan om du har några frågor.`
          }

        // eslint-disable-next-line no-fallthrough
        case 'Accepted':
          return `GRATTIS! Du har accepterat ${org.name} offert. Bjud in dem till projektet för att skapa ett bra samarbete.`
        case 'Rejected':
          return `Du har avslagit ${org.name} offert. Skicka gärna ett meddelande nedan och förklara varför.`
        case 'Deleted':
          return 'Förfrågan är borttagen och gäller inte längre'
      }
    }
    if (isProvider && org) {
      switch (helpReq.consumerStatus) {
        case 'Accepted':
          return 'GRATTIS! Kunden har accepterat er offert! Be om att bli inbjuden till projektet för att starta ett bra samarbete.'
        case 'Deleted':
          return 'Förfrågan har tagits bort av kund'
        case 'Rejected':
          return `${'Kund'} har tackat nej till er offert. Ska ni göra några justeringar på er offert eller vill ni avsluta och arkivera den?`
        case 'Ongoing':
          switch (helpReq.providerStatus) {
            case 'Ongoing':
              return `Ni har skickat er offert. ${'Kund'} har inte accepterat eller avslagit den än.`
            case 'Accepted':
              return ''
            case 'Deleted':
              return 'Förfrågan har tagits bort af leverantör'
            case 'Draft':
              return `${'Kund'} väntar på er offert Har ni några frågor hör av er via meddelanden nedan.`
            case 'New':
              return `Ni har fått en ny förfrågan. Meddela ${'kund'} redan nu om ni kommer lämna offert eller ej och ställ gärna kompletterande frågor i meddelanden nedan så har ni allt samlat på ett ställe.`
            case 'Rejected':
              return ''
          }
      }
    }
  }

  return <Text textStyle="infoTextLight">{getMessage()}</Text>
}
export default HelpRequestStatusMessage
