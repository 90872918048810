import { Button, Flex, HStack, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  AttachmentSummary,
  HelpRequestResponse,
  UpdateHelpRequest,
} from '@contracts/misc'
import { Attachment } from '@contracts/support'
import Spinner from '../Spinner'
import Tiptap from '../richTextEditor/Tiptap'
import {
  addAttachment,
  // Don't delete any attachments until check if used elsewhere is in place
  // deleteAttachment,
  // updateFilename,
} from '../../apiClients/attachmentsApiClient'
import { copyAttachments } from '../attachments/attachmentHelper'
import {
  updateHelpRequest,
  updateHelpRequestOffer,
  useHelpRequestsByOrganizationId,
} from '../../apiClients/projectsApiClient/helpRequests'
import log from '../../common/log'
// import FileManager from '../ui/FileManager'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import FileManager from '../ui/FileManager'
// import FileManagerWithoutContextId from '../ui/FileManagerBeforeContextId'
import { AreaData } from '../notes/AddOrEditNoteFiles'

export const MAX_LENGTH_NOTE_TITLE = 255
interface AddOrEditHelpRequestValues {
  description: string
  attachments: File[]
}

const AddOrEditOffer: React.FC<{
  helpReq: HelpRequestResponse
  mr?: number | string
  mt?: number | string
  mb?: number | string
  mx?: number | string
  my?: number | string
  w?: number | string
}> = ({ helpReq, mr, mt, mb, mx, my, w }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const toast = useToast()
  const { mutate: mutateHelpRequestsByOrgId } = useHelpRequestsByOrganizationId(
    helpReq.organizationId,
  )
  const { mutate: mutateMessageSection } = useConversationsByArea(
    'HelpRequest',
    [helpReq.id],
  )

  const [description, setDescription] = useState(helpReq.agreement ?? '<p></p>')
  const [submitType, setSubmitType] = useState<'save-draft' | 'send-proposal'>(
    'save-draft',
  )
  // const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  // const [myFilesSelectionToAdd, setMyFilesSelectionToAdd] = useState<
  //   AttachmentSummary[]
  // >([])
  const [attachmentsJson, setAttachmentsJson] = useState<AttachmentSummary[]>(
    helpReq.attachmentsJson ?? [],
  )
  // const [attachmentIdsToDelete, setAttachmentIdsToDelete] = useState<string[]>(
  //   [],
  // )
  // const [attachmentsToChangeName, setAttachmentsToChangeName] = useState<
  //   { attachmentId: string; newName: string; mimeType: string }[]
  // >([])
  // const [newFiles, setNewFiles] = useState<File[]>([])

  const initialValues: AddOrEditHelpRequestValues = {
    description: helpReq?.agreement ?? '',
    attachments: [],
  }

  const areaData: AreaData = {
    area: 'HelpRequest',
    areaId: helpReq.id,
    parentArea: 'HelpRequestTemplate',
    parentAreaId: helpReq.helpRequestTemplateId,
    grandParentArea: 'Project',
    grandParentAreaId: helpReq.helpRequestTemplate?.projectId ?? '',
  }

  const uploadFilesHandler = async (newFilesToAdd: File[]): Promise<void> => {
    const persistedAttachments = await Promise.all(
      newFilesToAdd.map(async file => {
        const formData = new FormData()
        formData.set('image', file)

        formData.set('area', 'HelpRequest')
        formData.set('areaId', helpReq.id)
        formData.set('parentArea', 'HelpRequestTemplate')
        formData.set('parentAreaId', helpReq.helpRequestTemplateId)
        formData.set('grandParentArea', 'Project')
        formData.set(
          'grandParentAreaId',
          helpReq.helpRequestTemplate?.projectId ?? '',
        )

        const newAttachment = await addAttachment(formData, true)
        return newAttachment
      }),
    )
    void addAttachmentsToHelpReqHandler(persistedAttachments)
  }

  const updateHelpReqHandler = (requestData: UpdateHelpRequest) => {
    updateHelpRequest(helpReq.id, requestData)
      .then(res => {
        log.info('Successfully updated offer')
        applicationInsights.trackEvent({
          name: 'Help-request updated',
        })

        void mutateHelpRequestsByOrgId()
        void mutateMessageSection()

        toast({
          title: t(
            helpReq.providerStatus === 'New'
              ? submitType === 'save-draft'
                ? 'helpRequests.draftOfferSuccessMessage'
                : 'helpRequests.sendOfferSuccessMessage'
              : 'helpRequests.sendOfferSuccessMessage',
            {
              name: helpReq.name,
            },
          ),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        setAttachmentsJson(res.data.attachmentsJson)
      })
      .catch(err => {
        log.error('Failed to update note', err)
        toast({
          title: t('helpRequests.updateOfferErrorMessage', {
            name: helpReq.name,
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
    // .finally(() => {
    // setSubmitting(false)
    // })
  }

  const addAttachmentsToHelpReqHandler = (
    attachmentsToAdd: Attachment[] | AttachmentSummary[],
  ) => {
    const requestData: UpdateHelpRequest = {
      starred: helpReq.starred,
      organizationId: helpReq.organizationId,
      helpRequestTemplateId: helpReq.helpRequestTemplateId,
      name: helpReq.name,
      attachmentsJson: [
        ...(helpReq.attachmentsJson ?? []),
        ...attachmentsToAdd,
      ],
      providerStatus: helpReq.providerStatus,
      consumerStatus: helpReq.consumerStatus,
      agreement: helpReq.agreement,
    }

    updateHelpReqHandler(requestData)
  }

  const deleteAttachmentsFromHelpReqHandler = (
    attachmentIdsToDelete: string[],
  ) => {
    // Remove attachment
    // await deleteAttachment(attachmentIdToDelete)

    const updatedAttachmentsJson = helpReq.attachmentsJson
      ? helpReq.attachmentsJson.filter(a => {
          // console.log(`${a.id} ==? xxx`)
          if (attachmentIdsToDelete.includes(a.id)) {
            return false
          }
          return true
        })
      : []

    // Remove attachment from attachmentsJson
    const requestData = helpReq
    requestData.attachmentsJson = updatedAttachmentsJson

    void updateHelpRequest(helpReq.id, requestData)

    // Mutate note
    // void mutateNote()
    // return Promise.resolve()
  }

  const {
    handleSubmit,

    errors,

    isSubmitting,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values, { setSubmitting }) => {
      const requestData: UpdateHelpRequest = {
        // 'Draft' if user press "Save" button
        // 'Ongoing' if user press "Send" button
        providerStatus: submitType === 'save-draft' ? 'Draft' : 'Ongoing',
        agreement: description,
        attachmentsJson: helpReq.attachmentsJson,
        starred: helpReq.starred,
        organizationId: helpReq.organizationId,
        helpRequestTemplateId: helpReq.helpRequestTemplateId,
        name: helpReq.name,
      }

      updateHelpRequestOffer(helpReq.id, requestData)
        .then(() => {
          log.info('Successfully updated offer')
          applicationInsights.trackEvent({
            name: 'Help-request updated',
          })

          void mutateHelpRequestsByOrgId()
          void mutateMessageSection()
          // eslint-disable-next-line no-console
          console.log('helpReq: ', helpReq.providerStatus)
          toast({
            title: t(
              helpReq.providerStatus === 'New'
                ? submitType === 'save-draft'
                  ? 'helpRequests.draftOfferSuccessMessage'
                  : 'helpRequests.sendOfferSuccessMessage'
                : 'helpRequests.sendOfferSuccessMessage',
              {
                name: helpReq.name,
              },
            ),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(err => {
          log.error('Failed to update note', err)
          toast({
            title: t('helpRequests.updateOfferErrorMessage', {
              name: helpReq.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const onChangeDescriptionHandler = (text: string) => {
    setDescription(text)
  }

  return (
    <Flex
      flexDir="column"
      mt={mt}
      mr={mr}
      mb={mb}
      mx={mx}
      my={my}
      w={w}
      borderRadius="lg"
    >
      <form onSubmit={handleSubmit} id="add-or-edit-note-form">
        <Text my={1}>
          Beskriv offertinnehållet, hur ni arbetar och eventuella reservationer
          så tydligt som möjligt
        </Text>
        <Tiptap
          border="1px solid"
          borderColor="gray.300"
          content={helpReq?.agreement ?? '<p></p>'}
          w="100%"
          maxH="sm"
          h={40}
          mb={4}
          toolbarVariant="basic"
          withTypographyExtension={true}
          withLinkExtension={true}
          onChange={onChangeDescriptionHandler}
          editorLabel={t('notes.addOrUpdateForm.description')}
          area="HelpRequest.offer"
          areaId={helpReq.id}
          parentArea="HelpRequestTemplate"
          parentAreaId={helpReq.helpRequestTemplateId}
          grandParentArea="Project"
          grandParentAreaId={helpReq.helpRequestTemplate?.projectId}
          container={'private'}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onImgAdded={() => {}}
        />

        <FileManager
          variant="menu"
          buttonW={{ xxs: '100%', tablet: 64 }}
          my={4}
          attachmentsJson={attachmentsJson}
          onUploadFiles={uploadFilesHandler}
          onAddExistingAttachments={async (
            attachmentsToAdd: Attachment[] | AttachmentSummary[],
          ) => {
            const copiedAttachments = await copyAttachments(
              attachmentsToAdd,
              areaData,
            )

            void addAttachmentsToHelpReqHandler(copiedAttachments)
          }}
          onDeleteAttachments={deleteAttachmentsFromHelpReqHandler}
        />

        <HStack
          spacing={2}
          mt={2}
          mb={{ xxs: 32, laptop: 0 }}
          justifyContent="right"
        >
          {(helpReq.providerStatus === 'New' ||
            helpReq.providerStatus === 'Draft') && (
            <Button
              type="submit"
              my={2}
              w={{ xxs: '100%', tablet: 36 }}
              variant={
                Object.keys(errors).length !== 0 ? 'disabled' : 'outline'
              }
              disabled={isSubmitting}
              id="save-offer-draft"
              size="sm"
              onClick={() => {
                setSubmitType('save-draft')
              }}
            >
              {t('ui.button.saveDraft')}
            </Button>
          )}
          <Button
            type="submit"
            my={2}
            w={{ xxs: '100%', tablet: 36 }}
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isSubmitting}
            id="send-offer"
            size="sm"
            onClick={() => {
              setSubmitType('send-proposal')
            }}
          >
            {helpReq.providerStatus === 'New' ||
            helpReq.providerStatus === 'Draft'
              ? t('helpRequests.sendHelpRequestOffer')
              : t('helpRequests.updateSentHelpRequestOffer')}
          </Button>
        </HStack>

        {isSubmitting && <Spinner />}
      </form>
    </Flex>
  )
}
export default AddOrEditOffer
