import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { Attachment } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { useAccount } from '../../apiClients/accountsApiClient'
import AccountCard from '../accounts/AccountTextCard'

const AttachmentCard: React.FC<{
  attachment: Attachment
  editAttachment?: (attachment: Attachment) => void
  isPrivate?: boolean
}> = ({ attachment, isPrivate = true }) => {
  const { t } = useTranslation()
  const { data } = useAccount(attachment.accountId)
  const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/${
    isPrivate ? 'private' : 'public'
  }/`

  const account = data

  if (!attachment) {
    return null
  }

  const renderFile = (a: Attachment) => {
    if (a.mimeType.includes('image')) {
      return <Image src={`${baseUrl}${a.id}`} boxSize={48} />
    }
    return (
      <Box border="1px" borderColor="gray.400" boxSize={48} p={3}>
        <Text mb={2}>{t('attachments.cannotRenderFileType')}</Text>
        <Text>{a.mimeType}</Text>
      </Box>
    )
  }

  return (
    <Box>
      <Flex flexDir="row" flexWrap="wrap">
        <Flex flexDir="column">
          <Box mt={4}>{renderFile(attachment)}</Box>
        </Flex>
        <AccountCard account={account} />
      </Flex>
      <Text>{`${baseUrl}${attachment.id}`}</Text>
    </Box>
  )
}
export default AttachmentCard
