import { Button, Flex } from '@chakra-ui/react'
import { Note } from '@contracts/misc'
import {
  ContextArea,
  ConversationArea,
  CreateMessageRequest,
  UpdateMessageRequest,
} from '@contracts/support'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from '@contracts/support'

import {
  postMessage,
  updateMessage,
  useConversationsByArea,
} from '../../apiClients/messagesApiClient'

import log from '../../common/log'
import Tiptap from '../richTextEditor/Tiptap'
import Spinner from '../Spinner'
import { Icon } from '../ui/Icon'
import {
  deleteAttachment,
  getAttachmentsByAreaQuery,
  updateContextAreaId,
} from '../../apiClients/attachmentsApiClient'
import {
  connectEmbeddedImagesNotConnected,
  findEmbeddedImgs,
  removeConnectedImagesNotEmbedded,
} from '../attachments/attachmentHelper'

type NewType = React.FC<{
  area: ContextArea
  areaId?: string
  parentArea?: ConversationArea
  parentAreaId?: string
  grandParentArea?: ConversationArea
  grandParentAreaId?: string
  conversationArea?: ConversationArea // Obsolete, will be removed
  areaFriendlyName: string
  areaFriendlyParentName: string
  message?: Message
  exludeSystemMessages?: boolean
  mx?: number | string
  mb?: number | string
  mt?: number | string
  showOnlyIfUpdatedSince?: number
  heading?: string
  headingStyle?: string
  link?: string
  projectId?: string
  projectNote?: Note
  pt?: number
  onDoneEditing?: () => void
}>

const AddOrEditMessage: NewType = ({
  area,
  areaId,
  parentArea,
  parentAreaId,
  grandParentArea,
  grandParentAreaId,
  conversationArea,
  areaFriendlyName,
  areaFriendlyParentName,
  exludeSystemMessages = false,
  message,
  mt,
  onDoneEditing,
}) => {
  const [addedImgIds, setAddedImgIds] = useState<string[] | undefined>()
  const imgIds: string[] = []

  if (!conversationArea) {
    conversationArea = area as ConversationArea
  }
  const { t } = useTranslation()
  const [newMessage, setNewMessage] = useState(message?.text ?? '')
  const [isLoading, setIsLoading] = useState(false)
  const [onClearContent, setOnClearContent] = useState(false)
  const [postMessageIsEnabled, setPostMessageIsEnabled] = useState(false)

  const { mutate: mutateConversation } = useConversationsByArea(
    conversationArea,
    [areaId ?? ''],
    exludeSystemMessages,
  )

  let isUpdatingExistingMessage = false
  let isPostingNewMessage = false

  if (area && areaId && !message) {
    isPostingNewMessage = true
  } else if (message) {
    isUpdatingExistingMessage = true
  } else {
    log.warn(
      'Failed to provide message form, either area and areaId, OR messageId and message must be provided',
    )
    return null
  }

  const imgAddedHandler = (imgId: string) => {
    imgIds.push(imgId)
    setAddedImgIds(imgIds)
  }

  const processMessageHandler = async (text: string) => {
    setIsLoading(true)

    const embeddedImgIds: string[] | undefined = findEmbeddedImgs(text)

    // POST new message
    if (!message && conversationArea && areaId) {
      const requestData: CreateMessageRequest = {
        eventType: 'Message',
        area: conversationArea,
        areaId,
        parentArea,
        parentAreaId,
        grandParentArea,
        grandParentAreaId,
        text,
        areaName: areaFriendlyName,
        areaParentName: areaFriendlyParentName,
      }

      try {
        const resMessage = await postMessage(requestData)

        if (addedImgIds?.length) {
          addedImgIds.map(async imgId => {
            if (
              embeddedImgIds?.length &&
              embeddedImgIds.find(embeddedImgId => {
                return embeddedImgId === imgId
              })
            ) {
              await updateContextAreaId(imgId, resMessage.id)
            } else {
              await deleteAttachment(imgId, true)
            }
          })
        }
        // const storedImgForMessage = useAttachmentsByAreaQuery([{area: 'Message', areaId: m}])

        setNewMessage('')
        setOnClearContent(true)

        void mutateConversation()
        setIsLoading(false)
      } catch (e) {
        log.error({ err: e }, 'Error sending message')

        setNewMessage('')
        setOnClearContent(true)
        void mutateConversation()
        setIsLoading(false)
        if (onDoneEditing) {
          onDoneEditing()
        }
      }
    } else if (message && conversationArea && areaId) {
      const updateRequest: UpdateMessageRequest = {
        id: message.id,
        area: conversationArea,
        areaId,
        text,
        areaName: areaFriendlyName,
        areaParentName: areaFriendlyParentName,
      }

      try {
        await updateMessage(updateRequest)
        const connectedImages = await getAttachmentsByAreaQuery([
          { area: 'Message', areaId: message.id },
        ])
        await connectEmbeddedImagesNotConnected(text, {
          area: 'Message',
          areaId: message.id,
          parentArea: area,
          parentAreaId: areaId,
          grandParentArea: parentArea,
          grandParentAreaId: parentAreaId,
        })

        if (connectedImages?.length) {
          await removeConnectedImagesNotEmbedded(text, {
            area: 'Message',
            areaId: message.id,
            parentArea: parentArea,
            parentAreaId: parentAreaId,
            grandParentArea: grandParentArea,
            grandParentAreaId: grandParentAreaId,
          })
        }

        void mutateConversation()
      } catch (e) {
        log.error({ err: e }, 'Failed to update message')
      } finally {
        setIsLoading(false)
        if (onDoneEditing) {
          onDoneEditing()
        }
      }
    }
  }

  return (
    <Flex flexDir="column" w="100%" zIndex={0}>
      <Tiptap
        toolbarVariant="compact"
        content={newMessage}
        onChange={text => {
          // findEmbeddedImgs(text)
          setNewMessage(text)
          setOnClearContent(false)
          if (text.length > 7) {
            setPostMessageIsEnabled(true)
          } else {
            setPostMessageIsEnabled(false)
          }
        }}
        w="100%"
        placeholder={t('messages.tiptapPlaceholder')}
        mt={mt}
        border="1px solid"
        borderColor="gray.300"
        onClearContent={onClearContent}
        withMentionSuggestion={true}
        area="Message"
        areaId={message?.id}
        parentArea={area}
        parentAreaId={areaId}
        grandParentArea={parentArea}
        grandParentAreaId={parentAreaId}
        container={'private'}
        onImgAdded={imgAddedHandler}
      />

      <Flex
        flexDir="row"
        justifyContent="right"
        pos="absolute"
        bottom="42px"
        right="2px"
      >
        {isUpdatingExistingMessage && (
          <Button
            variant="ghost"
            border="1px solid"
            borderColor="gray.300"
            size="sm"
            zIndex={200000000000}
            onClick={() => {
              if (onDoneEditing) {
                onDoneEditing()
              }
              setNewMessage('')
            }}
            mr={2}
          >
            {t('general.cancel')}
          </Button>
        )}
        <Button
          variant="primary"
          w={{ xxs: 8, tablet: 16 }}
          // mt={2}
          size="sm"
          onClick={() => {
            void processMessageHandler(newMessage)
            setNewMessage('')
          }}
          isDisabled={!postMessageIsEnabled || isLoading || !newMessage.length}
          mr={2}
          zIndex={20}
        >
          {isUpdatingExistingMessage && (
            <Icon name="IoSendSharp" h={20} color="white" />
          )}
          {isPostingNewMessage && (
            <Icon name="IoSendSharp" h={20} color="white" />
          )}
        </Button>
      </Flex>
      {isLoading && <Spinner bottom={20} />}
    </Flex>
  )
}
export default AddOrEditMessage
