import {
  RadioGroup as ChakraRadioGroup,
  Tooltip,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'
const RadioGroup: React.FC<{
  title: string
  message?: string
  value: string
  isMandatory?: boolean
  isError?: boolean
  errorText?: string
  children: ReactElement
  tooltip?: string
}> = ({
  title,
  tooltip,
  children,
  value,
  isMandatory,
  isError,
  errorText,
  message,
}) => {
  return (
    <ChakraRadioGroup value={value}>
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Tooltip
          hasArrow
          label={tooltip}
          bg="primaryBackground"
          color="black"
          placement="top"
          cursor="pointer"
        >
          <Flex flexDir="column">
            <Flex textStyle="infoText" mt={4} alignItems="center">
              {title}
              {isMandatory && <h1>*&nbsp;</h1>}
              <Text textStyle="inputPlaceholder">
                {tooltip && <FaRegQuestionCircle color="inputPlaceholder" />}
              </Text>
            </Flex>
            <Text textStyle="infoTextLight" fontSize="sm">
              {message}
            </Text>
          </Flex>
        </Tooltip>
      </Box>
      <Box textStyle="errorSmall" m=".5rem 1rem 0 1rem">
        {isError && errorText}
      </Box>
      <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight">
        {children}
      </Flex>
    </ChakraRadioGroup>
  )
}
export default RadioGroup
