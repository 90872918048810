import React, { useState } from 'react'
import { Editor } from '@tiptap/react'
import { Button, HStack, Input } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { RichTextButton } from '../ui/RichTextButton'
import Divider from '../ui/Divider'
import { setLink } from '../../common/richTextHelpers'

const ToolbarExtended: React.FC<{ editor: Editor }> = ({ editor }) => {
  const [srcUrl, updateSrcUrl] = useState<string>('')
  const [showAddImage, setShowAddImage] = useState(false)
  const { t } = useTranslation()

  const createImageNode = (editor: Editor) => {
    if (!srcUrl) return
    const urlComponents = srcUrl.split('.')
    const isVideo = urlComponents[urlComponents.length - 1] === 'mp4'
    editor
      .chain()
      .focus()
      .setImage({ src: srcUrl, video: isVideo.toString() })
      .run()
  }
  return (
    <>
      <HStack mx={4} py={2} wrap="wrap" justifyContent="space-around">
        <RichTextButton
          iconName="RiBold"
          onClick={() => {
            editor.chain().focus().toggleBold().run()
          }}
          tooltip={t('richtext.bold')}
        />
        <RichTextButton
          iconName="RiItalic"
          onClick={() => {
            editor.chain().focus().toggleItalic().run()
          }}
          tooltip={t('richtext.italic')}
        />
        <RichTextButton
          iconName="RiUnderline"
          onClick={() => {
            editor.chain().focus().toggleUnderline().run()
          }}
          tooltip={t('richtext.underline')}
        />
        <RichTextButton
          iconName="RiStrikethrough"
          onClick={() => {
            editor.chain().focus().toggleStrike().run()
          }}
          tooltip={t('richtext.strikethrough')}
        />
        <RichTextButton
          iconName="RiCodeSSlashLine"
          onClick={() => {
            editor.chain().focus().toggleCode().run()
          }}
          tooltip={t('richtext.code')}
        />
        <Divider orientation="vertical" h={5} color="gray.400" border={1} />
        <RichTextButton
          iconName="RiH1"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }}
          tooltip={t('richtext.h1')}
        />
        <RichTextButton
          iconName="RiH2"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }}
          tooltip={t('richtext.h2')}
        />
        <RichTextButton
          iconName="RiH3"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }}
          tooltip={t('richtext.h3')}
        />
        <RichTextButton
          iconName="RiH4"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }}
          tooltip={t('richtext.h4')}
        />
        <RichTextButton
          iconName="RiH5"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }}
          tooltip={t('richtext.h5')}
        />
        <RichTextButton
          iconName="RiH6"
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }}
          tooltip={t('richtext.h6')}
        />
        <RichTextButton
          iconName="RiParagraph"
          onClick={() => {
            editor.chain().focus().setParagraph().run()
          }}
          tooltip={t('richtext.setParagrapgh')}
        />
        <RichTextButton
          iconName="RiListOrdered"
          onClick={() => {
            editor.chain().focus().toggleOrderedList().run()
          }}
          tooltip={t('richtext.orderedList')}
        />
        <RichTextButton
          iconName="RiListUnordered"
          onClick={() => {
            editor.chain().focus().toggleBulletList().run()
          }}
          tooltip={t('richtext.unorderedList')}
        />
        <RichTextButton
          iconName="RiImageAddLine"
          onClick={() => {
            setShowAddImage(!showAddImage)
          }}
          tooltip={t('richtext.addImage')}
        />
        <Divider orientation="vertical" h={5} color="gray.400" border={1} />
        {/* <RichTextButton
          iconName="RiLink"
          onClick={() => {
            setLink(editor)
          }}
          tooltip={t('richtext.link')}
        /> */}
        <RichTextButton
          iconName="RiLinkUnlink"
          onClick={() => {
            setLink(editor)
          }}
          tooltip={t('richtext.unLink')}
        />
        <Divider orientation="vertical" h={5} color="gray.400" border={1} />
        <RichTextButton
          iconName="RiDoubleQuotesL"
          onClick={() => {
            editor.chain().focus().toggleBlockquote().run()
          }}
          tooltip={t('richtext.blockQuotes')}
        />
        <RichTextButton
          iconName="RiSeparator"
          onClick={() => {
            editor.chain().focus().setHorizontalRule().run()
          }}
          tooltip={t('richtext.horizontalRule')}
        />
        <Divider orientation="vertical" h={5} color="gray.400" border={1} />
        <RichTextButton
          iconName="RiTextWrap"
          onClick={() => {
            editor.chain().focus().setHardBreak().run()
          }}
          tooltip={t('richtext.hardBreak')}
        />
        <RichTextButton
          iconName="RiFormatClear"
          onClick={() => {
            editor.chain().focus().clearNodes().run()
          }}
          tooltip={t('richtext.clearNodes')}
        />
        <Divider orientation="vertical" h={5} color="gray.400" border={1} />
        <RichTextButton
          iconName="RiArrowGoBackLine"
          onClick={() => {
            editor.chain().focus().undo().run()
          }}
          tooltip={t('richtext.undo')}
        />
        <RichTextButton
          iconName="RiArrowGoForwardLine"
          onClick={() => {
            editor.chain().focus().redo().run()
          }}
          tooltip={t('richtext.redo')}
        />
      </HStack>
      {showAddImage && (
        <HStack flexDir="row" mx={12} my={2}>
          <Input
            h={8}
            placeholder={t('general.addImgUrl')}
            onChange={event => updateSrcUrl(event.target.value)}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                createImageNode(editor)
              }
            }}
            value={srcUrl}
          />
          <Button
            h={8}
            disabled={!srcUrl}
            onClick={() => createImageNode(editor)}
          >
            {t('general.confirm')}
          </Button>
          <Button h={8} onClick={() => setShowAddImage(false)}>
            {t('general.cancel')}
          </Button>
        </HStack>
      )}
    </>
  )
}
export default ToolbarExtended
