import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Image } from '../../ui/Image'
import PromoHeading from '../../ui/promoGroup/PromoHeading'
import PromoUnderText from '../../ui/promoGroup/PromoUnderText'
import PromoGroup from '../../ui/promoGroup/PromoGroup'

import {
  PATH_CONSTRUCTION_GLOSSARY,
  PATH_CONSTRUCTION_PROCESS,
  PATH_DETAILPLAN_AND_BUILDINGPERMIT,
  PATH_MY_PRIVATE_PAGES,
} from '../../../common/nav'
import detailedPlanAndBuildingPermits from './../../../img/pages/guidance/detailsAndBuildingPermits_l.svg'
import constructionWordList from './../../../img/pages/guidance/constructionWordList_m.svg'
import constructionProcess from './../../../img/pages/guidance/constructionProcessPromo_m.svg'

const GuidancePromoSection: React.FC<{
  pl?: ResponsiveValue<number | string>
  mb?: number | string
  mx?: number | string
  maxW?: number | string
}> = ({ pl, mb, mx, maxW = '1500px' }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  let baseUrl = ''
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    baseUrl = PATH_MY_PRIVATE_PAGES
  } else {
    baseUrl = ''
  }
  const promoWidth = '550px'

  const space = 12
  return (
    <Flex
      flexDir="row"
      wrap="wrap"
      pl={pl}
      mb={mb}
      justifyContent={{ xxs: 'space-around', table: 'left' }}
      px={mx}
      w="100%"
      maxW={maxW}
      gap={8}
    >
      <PromoGroup
        w={promoWidth}
        link={`${baseUrl}${PATH_CONSTRUCTION_GLOSSARY}`}
      >
        <>
          <PromoHeading textStyle="h5" mb={3}>
            {t('content.guidancePage.constructionWordListHeading')}
          </PromoHeading>
          <Image src={constructionWordList} />
          <PromoUnderText mt={2}>
            {t('content.guidancePage.constructionWordListHeadingUnderText')}
          </PromoUnderText>
        </>
      </PromoGroup>
      <PromoGroup
        w={promoWidth}
        mr={space}
        link={`${baseUrl}${PATH_DETAILPLAN_AND_BUILDINGPERMIT}`}
      >
        <>
          <PromoHeading mb={3}>
            {t('content.guidancePage.detailPlanAndBuildingPermit')}
          </PromoHeading>
          <Image src={detailedPlanAndBuildingPermits} />
          <PromoUnderText>
            {t('content.guidancePage.detailPlanAndBuildingPermitUnderText')}
          </PromoUnderText>
        </>
      </PromoGroup>

      <PromoGroup mr={space} link={`${baseUrl}${PATH_CONSTRUCTION_PROCESS}`}>
        <>
          <PromoHeading mb={3}>
            {t('content.guidancePage.constructionProcess')}
          </PromoHeading>
          <Image src={constructionProcess} />
        </>
      </PromoGroup>
    </Flex>
  )
}
export default GuidancePromoSection
