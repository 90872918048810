// export const PORTAL_URL = process.env.PUBLIC_URL
export const TAG_MOST_POPULAR = 'mostPopular'
export const TAG_CLIMATE_SMART_CONSTRUCTION = 'climateSmartConstruction'
export const TAG_SELECTED = 'selected'
export const TAG_INSPIRATION = 'inspiration'
export const NO_OF_TOP_ARTICLES = 3

export const ACCOUNT_PROFILE_IMAGE_PREFIX = '$profile_'
export const ACCOUNT_PROFILE_BACKGROUND_IMAGE_PREFIX = '$background_'

export const PROPERTY_PROFILE_IMAGE_PREFIX = '$profile_'
export const PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX = '$background_'

export const PROJECT_PROFILE_IMAGE_PREFIX = '$profile_'
export const PROJECT_PROFILE_BACKGROUND_IMAGE_PREFIX = '$background_'

export const PUBLIC_HOME_INTRO_MOBILE = '$publicHomeIntro_mobile_'
export const PUBLIC_HOME_INTRO_TABLET_COL2_ROW1 =
  '$publicHomeIntro_tablet_col2_row1_'
export const PUBLIC_HOME_INTRO_TABLET_COL2_ROW2 =
  '$publicHomeIntro_tablet_col2_row2_'
export const PUBLIC_HOME_INTRO_LAPTOP_COL12 = '$publicHomeIntro_laptop_col12_'
export const PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW1 =
  '$publicHomeIntro_laptop_col4_row1_'
export const PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW2 =
  '$publicHomeIntro_laptop_col4_row2_'

export const IMG_PICKER_MAX_FILESIZE = 10
export const FILE_PICKER_MAX_FILESIZE = 20
export const IMG_PICKER_MIN_FILESIZE = 0
export const IMG_PICKER_MIN_NO_FILES = 1
export const IMG_PICKER_MAX_NO_FILES = 10
export const IMG_PICKER_ALLOWED_IMG_FILE_TYPES = [
  '.jpg',
  '.png',
  '.gif',
  '.svg',
  '.heif',
]

export const TIPTAP_IMAGE_WIDTH = '200px'

export const COLORS_INSPIRATION: (
  | 'primary_orange01'
  | 'logo_pink_light'
  | 'primary_grey02'
  | 'logo_green02'
  | 'logo_yellow_light'
  | 'logo_brown_light'
)[] = [
  'logo_brown_light',
  'primary_orange01',
  'logo_pink_light',
  'primary_grey02',
  'logo_green02',
  'logo_yellow_light',
]

export interface ContentItem {
  content: 'ARTICLE' | 'IMAGE' | 'COLORED_BOX'
  colSpan?: number
  imgSrc?: string
  imgFallbackSrc?: string
  color?:
    | 'primary_orange01'
    | 'logo_pink_light'
    | 'primary_grey02'
    | 'logo_green02'
    | 'logo_yellow_light'
    | 'logo_brown_light'
}

import kitchen from './../img/pages/inspiration/inspiration_kitchen1_m.png'
import hut from './../img/pages/inspiration/inspiration_hut_m.png'
import livingroom from './../img/pages/inspiration/inspiration_livingroom_m.png'
import tool from './../img/pages/inspiration/inspiration_tool_m.png'
import garden from './../img/pages/inspiration/inspiration_garden_m.png'

export const INSPIRATION_CONTENT_SETUP: ContentItem[] = [
  {
    content: 'IMAGE',
    colSpan: 2,
    imgSrc: '$inspirationPage_1_',
    imgFallbackSrc: kitchen,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_2_',
    imgFallbackSrc: hut,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 3,
    imgSrc: '$inspirationPage_3_',
    imgFallbackSrc: livingroom,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_4_',
    imgFallbackSrc: tool,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_5_',
    imgFallbackSrc: hut,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_6_',
    imgFallbackSrc: garden,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 3,
    imgSrc: '$inspirationPage_7_',
    imgFallbackSrc: livingroom,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_8_',
    imgFallbackSrc: tool,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_9_',
    imgFallbackSrc: hut,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$inspirationPage_10_',
    imgFallbackSrc: garden,
  },
]

import houseProject from './../img/articles/houesProject_l.jpg'
import oldTools from './../img/articles/oldTools_l.jpg'
import tools from './../img/articles/tools_l.jpg'
import paintingCouple from './../img/articles/paintingCouple_m.jpg'

export const GUIDANCE_CONTENT_ROW1_SETUP: ContentItem[] = [
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 2,
    imgSrc: '$guidancePageRowOne_1',
    imgFallbackSrc: paintingCouple,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 2,
    imgSrc: '$guidancePageRowOne_2',
    imgFallbackSrc: tools,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 3,
    imgSrc: '$guidancePageRowOne_3_',
    imgFallbackSrc: livingroom,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowOne_4_',
    imgFallbackSrc: tool,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowOne_5_',
    imgFallbackSrc: hut,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowOne_6_',
    imgFallbackSrc: garden,
  },
]
export const GUIDANCE_CONTENT_ROW2_SETUP: ContentItem[] = [
  {
    content: 'IMAGE',
    colSpan: 3,
    imgSrc: '$guidancePageRowTwo_1_',
    imgFallbackSrc: houseProject,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowTwo_2_',
    imgFallbackSrc: kitchen,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 2,
    imgSrc: '$guidancePageRowTwo_3_',
    imgFallbackSrc: hut,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowTwo_4_',
    imgFallbackSrc: oldTools,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowTwo_5_',
    imgFallbackSrc: hut,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowTwo_6_',
    imgFallbackSrc: garden,
  },
]
export const GUIDANCE_CONTENT_ROW3_SETUP: ContentItem[] = [
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowTHREE_1',
    imgFallbackSrc: tools,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 2,
    imgSrc: '$guidancePageRowThree_2',
    imgFallbackSrc: tool,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 3,
    imgSrc: '$guidancePageRowThree_3_',
    imgFallbackSrc: livingroom,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowThree_4_',
    imgFallbackSrc: tool,
  },
  {
    content: 'ARTICLE',
    colSpan: 1,
  },
  {
    content: 'ARTICLE',
    colSpan: 2,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowThree_5_',
    imgFallbackSrc: kitchen,
  },
  {
    content: 'IMAGE',
    colSpan: 1,
    imgSrc: '$guidancePageRowThree_6_',
    imgFallbackSrc: livingroom,
  },
]
