/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Flex, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import {
  CreateProjectPlaceRequest,
  ProjectPlaceResponse,
} from '@contracts/projects'
import { useContext } from 'react'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'

import AuthContext from '../../common/auth-context'

import {
  updateProjectPlace,
  useProjectPlace,
} from '../../apiClients/projectsApiClient/projectPlaces'

interface EditProjectPlaceFormValues {
  customerName: string
  customerEmail: string
}
interface EditProjectPlaceErrors {
  customerName?: string
  customerEmail?: string
}

const EditProjectPlaceCustomerInfo: React.FC<{
  projectPlace: ProjectPlaceResponse
}> = ({ projectPlace }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { currentOrganization } = useContext(AuthContext)
  const toast = useToast()
  const { mutate: mutateProjectPlace } = useProjectPlace(projectPlace.id)

  if (!currentOrganization) {
    return null
  }

  const initialValues: EditProjectPlaceFormValues = {
    customerName: projectPlace.customerName,
    customerEmail: projectPlace.customerEmail ?? '',
  }

  const validate = (values: EditProjectPlaceFormValues) => {
    const errors: EditProjectPlaceErrors = {}

    const tmpRes = validateString(
      values.customerName,
      t('general.customerName').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.customerName = tmpRes.errorMsg
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const updateProjectPlaceRequest: CreateProjectPlaceRequest = {
        organizationId: currentOrganization?.id,
        name: projectPlace.name,
        street: projectPlace.street,
        zipCode: projectPlace.zipCode,
        city: projectPlace.city,
        status: projectPlace.status,
        phase: projectPlace.phase,
        propertyTypeId: projectPlace.propertyTypeId,

        customerName: values.customerName,
        customerEmail: values.customerEmail,
        projectName: projectPlace.name,
      }

      updateProjectPlace(projectPlace.id, updateProjectPlaceRequest)
        .then(({ dataValues: res }) => {
          applicationInsights.trackEvent(
            { name: 'Update project place' },
            { projectName: res.name },
          )

          toast({
            title: t('projectPlaces.updateSuccessMessage', {
              name: res.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          void mutateProjectPlace()
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to update project place, ${projectPlace.name} `,
          )
          toast({
            title: t('projectPlaces.updateErrorMessage', {
              name: projectPlace.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
          // onCloseModal()
        })
    },
  })

  return (
    <>
      <form onSubmit={handleSubmit} data-cy="update-projectPlace">
        <Flex flexDir="column" gap={3} maxW="xl">
          <Input
            title={t('general.customerName')}
            name="customerName"
            defaultValue={initialValues.customerName}
            type="text"
            isMandatory={true}
            isError={!!errors.customerName}
            errorText={
              touched.customerName && errors.customerName
                ? errors.customerName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
            variant="plain"
          />
          <Input
            title={t('projectPlaces.email')}
            name="customerEmail"
            defaultValue={initialValues.customerEmail}
            type="email"
            isMandatory={false}
            isError={!!errors.customerName}
            errorText={
              touched.customerEmail && errors.customerEmail
                ? errors.customerEmail
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
            variant="plain"
          />

          <Text ml="1rem" textStyle="infoSmall">
            *{t('input.mandatoryFields')}
          </Text>

          {/* <Button
            type="submit"
            margin="1rem 0"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
          >
            {t('input.submitForm')}
          </Button> */}
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            my={4}
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
            w={{ xxs: '100%', laptop: 32 }}
          >
            {t('ui.button.save')}
          </Button>
        </Flex>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default EditProjectPlaceCustomerInfo
