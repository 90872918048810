/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Flex, Radio, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  CreateProjectPlaceRequest,
  ProjectPlaceResponse,
} from '@contracts/projects'
import { useContext } from 'react'
import log from '../../common/log'
import Spinner from '../Spinner'
import { usePropertyTypes } from '../../apiClients/propertiesApiClient'
import AuthContext from '../../common/auth-context'
import RadioGroup from '../ui/RadioGroup'
import { updateProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'

interface EditProjectPlaceFormValues {
  // projectCategoryIds: string[]
  propertyTypeId: string
}
interface EditProjectPlaceErrors {
  // projectCategoryIds?: string
  propertyTypeId?: string
}

const EditProjectPlaceProjectType: React.FC<{
  projectPlace: ProjectPlaceResponse
}> = ({ projectPlace }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  // const { data: projectCategories } = useProjectCategories()
  const { data: propertyTypes } = usePropertyTypes()

  const { currentOrganization } = useContext(AuthContext)
  const toast = useToast()

  if (!currentOrganization) {
    return null
  }

  const initialValues: EditProjectPlaceFormValues = {
    // projectCategoryIds: [projectPlace.propertyTypeId],
    propertyTypeId: projectPlace.propertyTypeId,
  }

  const validate = (values: EditProjectPlaceFormValues) => {
    const errors: EditProjectPlaceErrors = {}

    if (values.propertyTypeId.length < 8) {
      errors.propertyTypeId = t('general.chooseAnOption')
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,

    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const updateProjectPlaceRequest: CreateProjectPlaceRequest = {
        organizationId: currentOrganization?.id,
        name: projectPlace.name,
        street: projectPlace.street,
        zipCode: projectPlace.zipCode,
        city: projectPlace.city,
        status: projectPlace.status,
        phase: projectPlace.phase,

        customerName: projectPlace.customerName,
        customerEmail: projectPlace.customerEmail,
        projectName: projectPlace.name,
        propertyTypeId: values.propertyTypeId,
      }

      updateProjectPlace(projectPlace.id, updateProjectPlaceRequest)
        .then(({ dataValues: res }) => {
          // void mutate()
          applicationInsights.trackEvent(
            { name: 'Update project place' },
            { projectName: res.name },
          )

          toast({
            title: t('projectPlaces.updateSuccessMessage', {
              name: res.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to update project place, ${projectPlace.name} `,
          )
          toast({
            title: t('projectPlaces.updateErrorMessage', {
              name: projectPlace.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
          // onCloseModal()
        })
    },
  })

  return (
    <>
      <form onSubmit={handleSubmit} data-cy="add-project">
        <Flex flexDir="column" gap={3} maxW="md">
          {/* {projectCategories && (
            <CheckboxGroup
              title={t('projects.projectTypes')}
              // isError={!!formikValues.projectCategoryIds}
              isError={!!errors.projectCategoryIds}
              errorText={
                // t('projects.addProjectErrorMessages.categoryIsMissing')

                touched.projectCategoryIds && errors.projectCategoryIds
                  ? // touched.projectCategoryIds && !!formikValues.projectCategoryIds
                    t('projects.addProjectErrorMessages.categoryIsMissing')
                  : ''
              }
              isMandatory={true}
              tooltip={t('projects.addProjectFormTooltips.projectCategories')}
            >
              <>
                {projectCategories.map(p => {
                  return (
                    <Checkbox
                      key={p.id}
                      name="projectCategoryIds"
                      value={p.id}
                      padding=".5rem 1rem"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {t(`projects.projectCategoryItems.${p.name ?? ''}`)}
                    </Checkbox>
                  )
                })}
              </>
            </CheckboxGroup>
          )} */}
          {propertyTypes && (
            <>
              <RadioGroup
                title={t('properties.propertyForm.propertyTypeProvider')}
                value={formikValues.propertyTypeId}
                // defaultValue={propertyTypes[0].id}
                isError={!!errors.propertyTypeId}
                // isError={true}
                errorText={t('general.chooseAnOption')}
                isMandatory
              >
                <>
                  {propertyTypes.map(p => {
                    return (
                      <Radio
                        key={p.id}
                        w={36}
                        name="propertyTypeId"
                        value={p.id}
                        padding=".5rem 1rem"
                        onChange={handleChange}
                      >
                        {p.name}
                      </Radio>
                    )
                  })}
                </>
              </RadioGroup>
            </>
          )}
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            my={4}
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
            w={{ xxs: '100%', laptop: 32 }}
          >
            {t('ui.button.save')}
          </Button>
        </Flex>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default EditProjectPlaceProjectType
