import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { PropertyResponse } from '@contracts/misc'

import EditProperty from '../../properties/EditProperty'
import Collaborators from '../../collaborators/CollaboratorsPage'

const EditPropertySection: React.FC<{
  property: PropertyResponse
}> = ({ property }) => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const tabId = search.get('tab') === 'medlemmar' ? 1 : 0

  return (
    <Flex flexDir="column" maxW="container.xl" pt={12}>
      <Tabs variant="line" defaultIndex={tabId}>
        <TabList justifyContent="space-around">
          <Tab px={{ tablet: '12%' }}>{t('properties.propertyInfo')}</Tab>
          <Tab px={{ tablet: '12%' }}>{t('properties.members')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel mx={{ mobile: 0, tablet: 10, laptop: 16, desktop: 40 }}>
            <EditProperty property={property} />
          </TabPanel>
          <TabPanel>
            <Collaborators property={property} mt={10} pb={64} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
export default EditPropertySection
