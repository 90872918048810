import {
  Circle,
  CloseButton,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { BellIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import { Heading } from '../ui'
import EventNotifications from '../components/eventNotifications/EventNotifications'
import Divider from '../components/ui/Divider'
import { useEventNotifications } from '../apiClients/eventsApiClient'

const NotificationMenu = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { data: eventNotifications } = useEventNotifications()

  const unreadNotifications = eventNotifications?.filter(event => {
    return event.status === 'New'
  })
  return (
    <Flex zIndex="overlay">
      <Menu computePositionOnMount={true} closeOnSelect={false}>
        {({ onClose }) => (
          <>
            <MenuButton pos="relative">
              <Flex>
                <BellIcon
                  color="primary_grey04"
                  boxSize={10}
                  _hover={{ color: 'red', cursor: 'pointer' }}
                />
                <Circle
                  bg="red.600"
                  color="white"
                  borderRadius="full"
                  fontSize={12}
                  size={unreadNotifications?.length ?? 0 < 10 ? 4 : 5}
                  transform="translate(-18px, 0px)"
                  fontWeight="semibold"
                >
                  {unreadNotifications?.length ?? 0}
                </Circle>
              </Flex>
            </MenuButton>

            <MenuList
              boxShadow="lg"
              borderColor="gray.300"
              bgSize="cover"
              // w="md"
              w={{ xxs: 'sm', tablet: 'md' }}
            >
              <>
                <Flex px={3} flexDir="column">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Heading py={2} textStyle="h6">
                      Notiser
                    </Heading>
                    <CloseButton onClick={onClose} />
                  </Flex>
                  <Flex justifyContent="space-around" mb={1} mt={3}>
                    <Flex
                      onClick={() => {
                        setActiveTab(0)
                      }}
                      color={activeTab === 0 ? 'primary' : 'black'}
                      fontWeight={activeTab === 0 ? 'semibold' : 'normal'}
                      _hover={{
                        cursor: 'pointer',
                        color: activeTab === 0 ? '#9CDAD7' : 'gray.400',
                      }}
                      gap={2}
                    >
                      <Text>{`Olästa (${
                        unreadNotifications?.length ?? 0
                      })`}</Text>
                    </Flex>
                    <Text
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      color={activeTab === 1 ? 'primary' : 'black'}
                      fontWeight={activeTab === 1 ? 'semibold' : 'normal'}
                      _hover={{
                        cursor: 'pointer',
                        color: activeTab === 1 ? '#9CDAD7' : 'gray.400',
                      }}
                    >
                      {`Senaste händelser (${eventNotifications?.length ?? 0})`}
                    </Text>
                  </Flex>
                  <Divider my={0} />
                </Flex>
                <EventNotifications
                  bg="white"
                  activeTab={activeTab}
                  onCloseMenu={onClose}
                />
              </>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  )
}

export default NotificationMenu
