import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { ingressBuildingPartsClimateImpactPage } from '../../apiClients/contentApiClient'

import BuildingPartsClimateImpactSection from '../../components/sections/portal/BuildingPartsClimateImpactSection'

const BuildingPartsClimateImpactPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('nav.buildingPartsClimateImpact')}</title>
      </Helmet>
      <ContentPageHeader
        label={t('nav.buildingPartsClimateImpact').toUpperCase()}
        ingress={ingressBuildingPartsClimateImpactPage}
      />
      <BuildingPartsClimateImpactSection />
    </>
  )
}
export default BuildingPartsClimateImpactPage
