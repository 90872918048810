import { Button, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import AddProperty from '../properties/AddProperty'
import Modal from '../ui/Modal'
import { Icon } from './Icon'
const AddPropertyButton: React.FC<{
  mt?: number
  w?: number
  title?: string
  children?: ReactElement
  variant?: 'normal-button' | 'posh-button' | 'icon'
  disabled?: boolean
}> = ({
  title,
  mt,
  children,
  w,
  variant = 'normal-button',
  disabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getButton = () => {
    if (variant === 'normal-button') {
      return (
        <Button onClick={onOpen} mt={mt} variant="secondary" w={w || 32}>
          {children}
        </Button>
      )
    }
    if (variant === 'posh-button') {
      return (
        <Button
          onClick={onOpen}
          mt={mt}
          bg="logo_purple"
          color="white"
          borderRadius="full"
          w={w || 80}
        >
          {title}
          {children}
        </Button>
      )
    }
    if (variant === 'icon') {
      return (
        <Icon
          name="IoAddCircleOutline"
          _hover={{ color: 'gray' }}
          color="primary_orange02"
          onClick={onOpen}
          isDisabled={disabled}
        />
      )
    }
  }
  return (
    <>
      {getButton()}
      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AddProperty onCloseModal={onClose} />
      </Modal>
    </>
  )
}
export default AddPropertyButton
