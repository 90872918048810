import { Box, Flex, Text } from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import { useTranslation } from 'react-i18next'

const AccountTextCard: React.FC<{
  account: AccountData | undefined
}> = ({ account }) => {
  const { t } = useTranslation()

  if (!account) {
    return <Text>{t('account.failedToFetchAccount')}</Text>
  }
  return (
    <Flex
      flexDir="row"
      p={4}
      ml={4}
      mr={4}
      minW="350px"
      borderRadius={8}
      flexWrap="wrap"
    >
      <Flex flexDir="column" mr={12}>
        <Text mb={4}>{t('attachments.belongsToAccount')}</Text>
        <Text mr={1}>{t('accounts.accountForm.email')}:</Text>
        <Text mb={4}>{account.email}</Text>
        <Text mr={1}>{t('accounts.accountForm.firstName')}:</Text>
        <Text mb={4}>{account.firstName}</Text>
        <Text mr={1}>{t('accounts.accountForm.lastName')}:</Text>
        <Text mb={4}>{account.lastName}</Text>
      </Flex>
      <Flex flexDir="column" mt={9}>
        <Text>{t('accounts.createdAt')}:</Text>

        <Text mb={4}>
          {account.createdAt &&
            new Date(account.createdAt).toLocaleString('sv-SE')}
        </Text>
        <Text>{t('accounts.updatedAt')}:</Text>
        <Text mb={4}>
          {account.createdAt &&
            new Date(account.updatedAt).toLocaleString('sv-SE')}
        </Text>
        <Text mr={1}>{t('accounts.accountForm.accountType')}:</Text>
        <Box>
          {account.accountTypes?.map(accountType => {
            return <Text key={accountType}>{accountType}</Text>
          })}
        </Box>
      </Flex>
    </Flex>
  )
}
export default AccountTextCard
