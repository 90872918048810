import { mergeAttributes, nodeInputRule } from '@tiptap/core'
import Image from '@tiptap/extension-image'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    media: {
      setImage: (options: {
        src?: string | null
        alt?: string
        title?: string
        width?: string
        float?: string
        centered?: string
        href?: string
        video?: string
      }) => ReturnType
    }
  }
}

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export const BaseCustomMediaNode = Image.extend({
  draggable: true,
  name: 'media',

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: null,
        parseHTML: element => {
          element.getAttribute('width')
        },
        renderHTML: attributes => {
          return {
            width: attributes.width,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            style: `width: ${attributes.width}`,
          }
        },
      },
      float: {
        default: null,
        parseHTML: element => {
          element.getAttribute('float')
        },
        renderHTML: attributes => {
          return {
            float: attributes.float,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            style: `float: ${attributes.float}; ${
              attributes.float === 'left' ? 'padding-right: 20px' : ''
            }; ${attributes.float === 'right' ? 'padding-left: 20px' : ''}`,
          }
        },
      },
      centered: {
        default: 'true',
        parseHTML: element => {
          element.getAttribute('centered')
        },
        renderHTML: attributes => {
          return {
            centered: attributes.centered,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            style: `display: ${attributes.centered ? 'flex' : null}`,
          }
        },
      },
      video: {
        default: 'false',
        parseHTML: element => {
          element.getAttribute('video')
        },
        renderHTML: attributes => {
          return {
            video: attributes.video,
          }
        },
      },
      href: {
        default: undefined,
        parseHTML: element => {
          element.getAttribute('href')
        },
        renderHTML: attributes => {
          return {
            href: attributes.href,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs(dom) {
          if (typeof dom === 'string') return {}
          const wrapperElement = dom as HTMLElement

          let imageElement: HTMLImageElement | HTMLVideoElement | null =
            wrapperElement.querySelector('img')
          if (!imageElement) {
            imageElement = wrapperElement.querySelector('video')
          }
          const titleElement = wrapperElement.querySelector('div')
          const linkElement = wrapperElement.querySelector('a')
          const href = linkElement?.getAttribute('href')

          const alt = imageElement ? imageElement.getAttribute('alt') : null
          const src = imageElement ? imageElement.getAttribute('src') : null
          const video = imageElement?.getAttribute('video')
          const width = imageElement?.getAttribute('width')
          const centered = imageElement?.getAttribute('centered')
          const float = imageElement?.getAttribute('float')

          const title = titleElement ? titleElement.textContent : null

          return { alt, src, title, width, float, centered, video, href }
        },
      },
      { tag: 'img[src]' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        style: `display: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          HTMLAttributes.centered === 'true' ? 'flex' : null
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }; justify-content: ${HTMLAttributes.centered ? 'center' : null}`,
      },
      [
        HTMLAttributes.href ? 'a' : 'div',

        {
          href: HTMLAttributes.href,
          target: '_blank',
          rel: 'noopener noreferrer nofollow',
        },
        HTMLAttributes.video === 'true'
          ? [
              'video',
              mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                controls: true,
              }),
            ]
          : [
              'img',
              mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            ],
      ],
    ]
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: IMAGE_INPUT_REGEX,
        type: this.type,
        getAttributes: match => {
          const [, alt, src, title] = match
          return {
            src,
            alt,
            title,
          }
        },
      }),
    ]
  },

  addCommands() {
    return {
      setImage:
        options =>
        ({ commands }) => {
          // if we are not updating the src, then its an existing media item
          if (!options.src) {
            return commands.updateAttributes('media', options)
          }
          // otherwise make new content
          return commands.insertContent({
            type: this.type.name,
            attrs: options,
          })
        },
    }
  },
}).configure({
  inline: true,
  HTMLAttributes: {
    class: 'editor-media',
  },
})
