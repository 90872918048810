import { Flex, Text } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { useOrganization } from '../../../apiClients/organizationsApiClient'
import { useOrganizationCollaborator } from '../../../apiClients/organizationsCollaboratorApiClient'

import { useProjects } from '../../../apiClients/projectsApiClient'
import {
  useHelpRequests,
  useHelpRequestsTemplatesByProjectIds,
  useHelpRequestsByOrganizationId,
} from '../../../apiClients/projectsApiClient/helpRequests'

import ExpandableHelpRequestConsumerCard from '../../helpRequests/ExpandableHelpRequestConsumerCard'
import ExpandableHelpRequestTemplateCard from '../../helpRequests/ExpandableHelpRequestTemplateCard'
import Heading from '../../ui/Heading'

const MyHelpRequestSection: React.FC<{
  mb?: number | string
  mt?: number | string
  organizationId?: string
}> = ({ mt, mb, organizationId }) => {
  const [search] = useSearchParams()
  const helpRequestId = search.get('id')
  const { data: projects } = useProjects('Owner')
  const projectIds = projects?.map(project => {
    return project.id
  })
  const { data: helpRequestTemplates } =
    useHelpRequestsTemplatesByProjectIds(projectIds)
  const helpRequestTemplateIds = helpRequestTemplates?.map(template => {
    return template.id
  })
  const { data: allHelpRequests, mutate: mutateAllHelpRequests } =
    useHelpRequests(helpRequestTemplateIds)
  const { data: organizationCollab } =
    useOrganizationCollaborator(organizationId)
  const { data: organization } = useOrganization(organizationId)
  const { data: orgHelpRequests } =
    useHelpRequestsByOrganizationId(organizationId)

  /*
  // Provider Statuses
  //
  // | 'New' - unAnswered
  // | 'Draft' - unAnswered
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  //
  // Consumer Statuses
  //
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  */

  const ml = 2

  const unSentHelpRequestTemplates = helpRequestTemplates?.filter(
    helpReqTemplate => {
      return !allHelpRequests?.find(helpReq => {
        return helpReq.helpRequestTemplateId === helpReqTemplate.id
      })
    },
  )

  const activeHelpRequests = organizationId
    ? orgHelpRequests?.filter(helpReq => {
        return helpReq.consumerStatus === 'Ongoing'
      })
    : allHelpRequests?.filter(helpReq => {
        return helpReq.consumerStatus === 'Ongoing'
      })

  const archivedHelpRequests = organizationId
    ? orgHelpRequests?.filter(helpReq => {
        return (
          helpReq.consumerStatus === 'Accepted' ||
          helpReq.consumerStatus === 'Rejected' ||
          helpReq.consumerStatus === 'Deleted'
        )
      })
    : allHelpRequests?.filter(helpReq => {
        return (
          helpReq.consumerStatus === 'Accepted' ||
          helpReq.consumerStatus === 'Rejected' ||
          helpReq.consumerStatus === 'Deleted'
        )
      })

  return (
    <Flex flexDir="column" mt={mt} mb={mb} rowGap={4}>
      <Heading color="logo_green01" textStyle="h6" isBold ml={ml}>
        Aktiva förfrågningar
      </Heading>
      {!activeHelpRequests ||
        (activeHelpRequests?.length === 0 && (
          <Text ml={ml}>
            {organization
              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Du har inga aktiva förfrågningar hos ${organization.name} `
              : 'Du har inga aktiva förfrågningar'}
          </Text>
        ))}

      {activeHelpRequests &&
        activeHelpRequests.length > 0 &&
        activeHelpRequests.map(helpReq => {
          return (
            <ExpandableHelpRequestConsumerCard
              key={helpReq.id}
              helpReq={helpReq}
              mutateAllHelpRequests={mutateAllHelpRequests}
              isExpanded={helpReq.id === helpRequestId ? true : false}
            />
          )
        })}

      <Heading color="logo_green01" textStyle="h6" isBold mt={4} ml={ml}>
        Avslutade förfrågningar
      </Heading>
      {!archivedHelpRequests ||
        (archivedHelpRequests?.length === 0 && (
          <Text ml={ml}>
            {organization
              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Du har inga avslutade förfrågningar hos ${organization.name} `
              : 'Du har inga avslutade förfrågningar'}
          </Text>
        ))}
      {archivedHelpRequests &&
        archivedHelpRequests.length > 0 &&
        archivedHelpRequests.map(helpReq => {
          return (
            <ExpandableHelpRequestConsumerCard
              key={helpReq.id}
              helpReq={helpReq}
              mutateAllHelpRequests={mutateAllHelpRequests}
              isExpanded={helpReq.id === helpRequestId ? true : false}
            />
          )
        })}

      <Heading color="logo_green01" textStyle="h6" isBold mt={4} ml={ml}>
        Utkast (ej skickade)
      </Heading>
      {!unSentHelpRequestTemplates ||
        (unSentHelpRequestTemplates?.length === 0 && (
          <Text ml={ml}>Du har inga utkast</Text>
        ))}
      {unSentHelpRequestTemplates &&
        unSentHelpRequestTemplates.length > 0 &&
        unSentHelpRequestTemplates.map(helpReqTemplate => {
          return (
            <ExpandableHelpRequestTemplateCard
              key={helpReqTemplate.id}
              helpReqTemplate={helpReqTemplate}
              defaultOrganization={organizationCollab}
            />
          )
        })}
    </Flex>
  )
}
export default MyHelpRequestSection
