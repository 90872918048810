import { Flex, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useOrganization } from '../../apiClients/organizationsApiClient'
import Modal from '../ui/Modal'
import ProviderProfileTemplate from './ProviderProfileTemplate'

const ProviderProfileCardModal: React.FC<{
  organizationId: string

  children: ReactElement
}> = ({ organizationId, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: organization } = useOrganization(organizationId)

  if (!organization) {
    return null
  }
  return (
    <Flex onClick={onOpen} cursor="pointer">
      {children}
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        mb={0}
        px={0}
        py={0}
        pt={0}
        size="3xl"
      >
        <ProviderProfileTemplate
          organization={organization}
          onClose={() => {
            onClose()
          }}
        />
      </Modal>
    </Flex>
  )
}
export default ProviderProfileCardModal
