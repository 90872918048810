import React from 'react'
import {
  Input as ChakraInput,
  Box,
  Tooltip,
  Textarea,
  Text,
  Flex,
  InputRightElement,
  InputGroup,
  ResponsiveValue,
} from '@chakra-ui/react'
import { FaRegQuestionCircle } from 'react-icons/fa'
import {
  CheckCircleIcon,
  ViewIcon,
  ViewOffIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { t } from 'i18next'

const Input: React.FC<{
  title: string
  value?: string
  isMandatory: boolean
  isError?: boolean
  errorText?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeTextarea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlurTextarea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  type?:
    | 'text'
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'email'
    | 'hidden'
    | 'number'
    | 'password'
    | 'datetime-local'
    | 'month'
    | 'image'
    | 'textarea'
    | 'file'
    | 'phoneNumber'
  position?: 'top' | 'middle' | 'bottom' | 'single'
  name?: string
  margin?: string
  tooltip?: string
  background?: string
  defaultValue?: string
  variant?: 'outline' | 'ghost' | 'plain'
  autoComplete?: 'new-password' | 'current-password' | 'username'
  w?: ResponsiveValue<number | string>
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  maxW?: number | string
  readOnly?: boolean
  h?: number
  disabled?: boolean
  isVerified?: boolean
  isNotVerified?: boolean
  bg?: string
  titleMinW?: string | number
}> = ({
  type,
  background,
  position,
  name,
  value,
  onChange,
  onChangeTextarea,
  onBlur,
  onBlurTextarea,
  margin,
  tooltip,
  defaultValue,
  title,
  isMandatory,
  isError,
  errorText,
  variant = 'outline',
  autoComplete,
  w,
  mt,
  mr,
  mb,
  ml,
  readOnly = false,
  h,
  disabled,
  isVerified = false,
  isNotVerified = false,
  maxW,
  titleMinW,
  // bg,
}) => {
  const [show, setShow] = React.useState(false)
  const handleShowPasswordClick = () => setShow(!show)

  const getMargin = () => {
    switch (position) {
      case 'top':
        return '0 1rem .5rem 1rem'
      case 'bottom':
        return '0 1rem .7rem 1rem'
      case 'middle':
        return '0 1rem .5rem 1rem'
      case 'single':
        return '0 1rem .7rem 1rem'
      default:
        return
    }
  }
  const getBorderTopRadius = () => {
    switch (position) {
      case 'top':
        return '13px'
      case 'single':
        return '13px'
      default:
        return ''
    }
  }
  const getBorderBottomRadius = () => {
    switch (position) {
      case 'bottom':
        return '13px'
      case 'single':
        return '13px'
      default:
        return ''
    }
  }
  const getTransform = () => {
    switch (position) {
      case 'top':
        return ''
      case 'single':
        return ''
      default:
        return 'translateY(-1px)'
    }
  }

  const getInputComponent = (type: string) => {
    switch (type) {
      case 'textarea':
        return (
          <Textarea
            id={name}
            name={name}
            value={value}
            variant="unstyled"
            margin={getMargin()}
            w="95%"
            textStyle="inputText"
            onChange={onChangeTextarea}
            onBlur={onBlurTextarea}
            size="sm"
            resize="vertical"
            background={background}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        )
      case 'password':
        return (
          <InputGroup>
            <ChakraInput
              id={name}
              name={name}
              readOnly={readOnly}
              value={value}
              type={show ? 'text' : 'password'}
              variant="unstyled"
              margin={variant !== 'plain' ? getMargin() : ''}
              w={variant !== 'plain' ? '95%' : '100%'}
              textStyle="inputText"
              onChange={onChange}
              onBlur={onBlur}
              background={background}
              defaultValue={defaultValue}
              bg={variant === 'plain' ? 'white' : 'transparent'}
              border={variant === 'plain' ? '1px' : ''}
              borderColor={variant === 'plain' ? 'gray.400' : ''}
              py={variant === 'plain' ? 2 : 0}
              px={variant === 'plain' ? 3 : 0}
              disabled={disabled}
              autoComplete={autoComplete}
            ></ChakraInput>
            <InputRightElement width="4.5rem" alignItems="flex-start" pt={1}>
              {!show && <ViewIcon onClick={handleShowPasswordClick} />}
              {show && <ViewOffIcon onClick={handleShowPasswordClick} />}
            </InputRightElement>
          </InputGroup>
        )
      case 'phoneNumber':
        return (
          <PhoneInput
            country={'se'}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            inputProps={{ name: `${name}` }}
            value={defaultValue}
            regions={'europe'}
            onChange={(phoneNumber, country, e) => {
              onChange ? onChange(e) : ''
            }}
            containerStyle={{
              height: '2.6rem',
            }}
            inputStyle={{
              height: '2.6rem',
              width: '100%',
            }}
            onBlur={onBlur}
          />
        )
      default:
        if (readOnly) {
          return (
            <Box w="95%" ml={4} pb={2} h={8}>
              <Text>{defaultValue}</Text>
            </Box>
          )
        }
        return (
          <InputGroup>
            <ChakraInput
              id={name}
              name={name}
              readOnly={readOnly}
              value={value}
              type={type}
              variant="unstyled"
              margin={variant !== 'plain' ? getMargin() : ''}
              w={variant !== 'plain' ? '95%' : '100%'}
              textStyle="inputText"
              onChange={onChange}
              onBlur={onBlur}
              background={background}
              defaultValue={defaultValue}
              bg={variant === 'plain' ? 'white' : 'transparent'}
              border={variant === 'plain' ? '1px' : ''}
              borderColor={variant === 'plain' ? 'gray.400' : ''}
              py={variant === 'plain' ? 2 : 0}
              px={variant === 'plain' ? 3 : 0}
              disabled={disabled}
              autoComplete={autoComplete}
            ></ChakraInput>
            {isVerified && (
              <Tooltip label={`${t('accounts.userEmailVerifiedTooltip')}`}>
                <InputRightElement
                  children={<CheckCircleIcon color="green.500" fontSize="xl" />}
                />
              </Tooltip>
            )}
            {isNotVerified && !isVerified && (
              <Tooltip label={`${t('accounts.userEmailNotVerifiedTooltip')}`}>
                <InputRightElement
                  children={<WarningTwoIcon color="gray.400" />}
                />
              </Tooltip>
            )}
          </InputGroup>
        )
    }
  }
  if (variant !== 'plain') {
    return (
      <Box
        bg={'white'}
        border={variant === 'ghost' ? '' : '1px solid'}
        borderTopRadius={getBorderTopRadius()}
        borderBottomRadius={getBorderBottomRadius()}
        // Make sure borders are on top of each other and not doubled
        transform={getTransform()}
        borderColor="inputBorder"
        margin={margin ?? ''}
        background={background}
        pr={4}
        w={w}
        mt={mt}
        mr={mr}
        mb={mb}
        ml={ml}
        h={h}
      >
        <Box display="flex" flexWrap="nowrap">
          <Tooltip
            hasArrow
            label={tooltip}
            bg="primaryBackground"
            color="black"
            placement="top"
            cursor="pointer"
          >
            <Flex
              textStyle="inputPlaceholder"
              m=".5rem 1rem 0 1rem"
              flexWrap="nowrap"
              minW={titleMinW}
            >
              <label htmlFor={name}>{title}</label>
              {isMandatory && <span>*&nbsp;</span>}
              {tooltip ? (
                <FaRegQuestionCircle color="inputPlaceholder" />
              ) : null}
            </Flex>
          </Tooltip>
          <Box textStyle="errorSmall" m=".5rem 1rem 0 1rem">
            {isError && errorText}
          </Box>
        </Box>

        {getInputComponent(type || 'text')}
      </Box>
    )
  }
  return (
    <Box mt={mt} mr={mr} mb={mb} ml={ml} w={w} maxW={maxW}>
      <Tooltip label={tooltip} placement="top-start">
        <Flex flexDir="row" alignItems="end" justifyContent="space-between">
          {/* <Flex flexDir="row" w={60} flexWrap="nowrap"> */}
          <Flex flexDir="row" flexWrap="nowrap">
            {title}
            {isMandatory && <h1>*&nbsp;</h1>}
            {tooltip && <FaRegQuestionCircle color="gray" />}
          </Flex>
          <Text color="red" ml={5} textAlign="right" fontSize="sm">
            {isError && errorText}
          </Text>
        </Flex>
      </Tooltip>
      {getInputComponent(type || 'text')}
    </Box>
  )
}
export default Input
