import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useNotes, useProject } from '../../apiClients/projectsApiClient'
import { AreaContext } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import CollaboratorsPage from '../../components/collaborators/CollaboratorsPage'
import GuidancePromoSection from '../../components/sections/articles/GuidancePromoSection'
import EditProjectSection from '../../components/sections/portal/EditProjectSection'
import MyMessageSection from '../../components/sections/portal/MyMessageSection'
import MyNotesSection from '../../components/sections/portal/MyNotesSection'
import ProjectHeaderSection from '../../components/sections/portal/ProjectHeaderSection'
import Divider from '../../components/ui/Divider'

import ProjectHelpRequestSection from '../../components/sections/portal/ProjectHelpRequestPage'

import { useNote } from '../../apiClients/projectsApiClient/notes'
import { usePropertyInfo } from '../../apiClients/propertiesApiClient/properties'
import { PATH_MY_PRIVATE_PROJECTS } from '../../common/nav'
import NoteCardMedium from '../../components/notes/NoteCardMedium'
import DocumentsWidget from '../../components/projects/DocumentsWidget'
import ImagesWidget from '../../components/projects/ImagesWidget'
import Modal from '../../components/ui/Modal'

const ProjectPage = () => {
  const { id: projectId } = useParams()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const noteId = search.get('noteId')

  const { t } = useTranslation()

  const { data: project } = useProject(projectId)
  const { data: projectNotes } = useNotes(projectId)
  const { data: targetNote } = useNote(noteId ?? undefined)

  const { data: propertyInfo } = usePropertyInfo(project?.propertyId)
  const {
    isOpen: isOpenNoteCardMedium,

    onOpen: onOpenNoteCardMedium,
  } = useDisclosure({ defaultIsOpen: true })

  const { isProvider } = useContext(AuthContext)

  let showGuidancePromos = true
  if (isProvider || (projectNotes && projectNotes.length > 2)) {
    showGuidancePromos = false
  }

  if (!projectId || !project) {
    return null
  }
  const onCloseNoteCardMedium = () => {
    navigate(`${PATH_MY_PRIVATE_PROJECTS}/${projectId}`)
  }
  const px = { xxs: 2, tablet: '2%', desktop: '3%', laptop: '5%' }
  return (
    <AreaContext.Provider value={{ area: 'project', areaId: projectId }}>
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>{t('pageTitle.project', { name: project?.name })}</title>
              </Helmet>

              <ProjectHeaderSection project={project} px={px} />
              <Flex
                flexDir="row"
                wrap="wrap"
                mb={showGuidancePromos ? 0 : 40}
                w="100%"
                px={{ xxs: '0%', tablet: '5%' }}
                pt={4}
              >
                <Tabs
                  variant="line"
                  w="100%"
                  isLazy={true}
                  colorScheme="orange"
                  defaultIndex={0}
                  px={0}
                >
                  <TabList flexWrap="wrap" justifyContent="space-evenly">
                    <Tab px={2}>
                      <Text textStyle="h7">Översikt</Text>
                    </Tab>
                    <Tab px={2}>
                      <Text textStyle="h7">Inlägg</Text>
                    </Tab>

                    <Tab px={2}>
                      <Text textStyle="h7">Förfrågningar</Text>
                    </Tab>

                    <Tab px={2}>
                      <Text textStyle="h7">Medlemmar</Text>
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel px={0}>
                      <Flex flexDir="column" px={2}>
                        <Flex
                          w="100%"
                          gap={4}
                          my={4}
                          wrap={{ xxs: 'wrap', laptop: 'nowrap' }}
                          justifyContent={{
                            xxs: 'left',
                            tablet: 'left',
                          }}
                        >
                          <Flex
                            basis={{ xxs: '280px', desktop: '330px' }}
                            grow={1}
                            maxW="sm"
                            // border="1px solid red"
                          >
                            <DocumentsWidget
                              variant="important-documents-project"
                              projectId={projectId}
                              onOpenNoteCard={onOpenNoteCardMedium}
                            />
                          </Flex>
                          <Flex
                            flexGrow={{ xxs: 1, laptop: 0.5 }}
                            basis={{ xxs: '220px', desktop: '330px' }}
                          >
                            <ImagesWidget
                              variant="project"
                              projectId={projectId}
                              thumbnailSize={{
                                xxs: 16,
                                tablet: 20,
                                laptop: 24,
                              }}
                            />
                          </Flex>
                        </Flex>

                        <MyMessageSection
                          area="Project"
                          areaId={project.id}
                          areaFriendlyName={project.name}
                          areaFriendlyParentName={propertyInfo?.name ?? ''}
                          parentArea="Property"
                          parentAreaId={project.propertyId}
                        />

                        {showGuidancePromos && (
                          <>
                            <Divider my={6} w="100%" />
                            <GuidancePromoSection mb={40} />
                          </>
                        )}
                      </Flex>
                    </TabPanel>
                    <TabPanel px={0}>
                      <Flex flexDir="column" px={2}>
                        <MyNotesSection projectId={project?.id} />
                      </Flex>
                    </TabPanel>
                    <TabPanel px={0} mx={0}>
                      <ProjectHelpRequestSection
                        projectId={projectId}
                        mb={40}
                      />
                    </TabPanel>
                    <TabPanel px={0}>
                      <CollaboratorsPage project={project} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
              {targetNote && (
                <Modal
                  isOpen={isOpenNoteCardMedium}
                  onClose={onCloseNoteCardMedium}
                  onOpen={onOpenNoteCardMedium}
                  size="lg"
                >
                  <NoteCardMedium
                    note={targetNote}
                    projectId={projectId}
                    onClose={onCloseNoteCardMedium}
                  />
                </Modal>
              )}
            </>
          }
        />

        <Route
          path="/edit/"
          element={
            <>
              <Helmet>
                <title>{t('pageTitle.project', { name: project?.name })}</title>
              </Helmet>

              <ProjectHeaderSection project={project} isInEditMode />
              <EditProjectSection project={project} px={px} />
            </>
          }
        />
        <Route path="/forfragan/" element={<h1>Förfrågan</h1>} />
      </Routes>
    </AreaContext.Provider>
  )
}
export default ProjectPage
