import {
  AccountOrganization,
  CreateInstagramRequest,
  CreateInstagramResponse,
  CreateInviteResponse,
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  InstagramPostsResponse,
  OrganizationProfile,
  PatchOrganizationRequest,
  ServiceType,
  UpdateAccountOrganizationRequest,
  UpdateOrganizationProfileRequest,
  UpdateOrganizationRequest,
} from '@contracts/accounts'
import {
  Collaborator,
  CreateCollaboratorRequest,
  Organization,
  OrganizationStatus,
} from '@contracts/misc'
import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.ACCOUNTS_SERVICE_URL as string

const organizationFetcher = async (
  urlKey: string,
): Promise<Organization | undefined> => {
  return axios.get<Organization>(`${baseUrl}${urlKey}`).then(({ data }) => {
    return data
  })
}

const organizationsFetcher = async (
  urlKey: string,
): Promise<Organization[] | undefined> => {
  return axios.get<Organization[]>(`${baseUrl}${urlKey}`).then(({ data }) => {
    return data
  })
}

export const useInstagramMedia = (
  organizationId: string | undefined,
): SWRResponse<InstagramPostsResponse | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    InstagramPostsResponse | undefined
  >(
    organizationId
      ? `${baseUrl}/organizations/${organizationId}/integrations/instagram/`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const useCurrentOrganization = (
  userId: string | undefined,
): SWRResponse<Organization | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Organization | undefined
  >(userId ? '/organizations/me' : null, organizationFetcher)
  return { data, error, isValidating, mutate }
}

export const useOrganization = (
  organizationId: string | undefined,
): SWRResponse<Organization | undefined> => {
  return useSWR<Organization | undefined>(
    organizationId ? `${baseUrl}/organizations/${organizationId}` : null,
  )
}

export const useOrganizations = (filter?: {
  name: string | undefined
  type: string | undefined
}): SWRResponse<Organization[] | undefined> => {
  if (filter?.name || filter?.type) {
    return useSWR<Organization[] | undefined>(`${baseUrl}/organizations/`)
  }
  return useSWR<Organization[] | undefined>(`${baseUrl}/organizations/`)
}

// export const useOrganizationProfiles = (): SWRResponse<
//   Organization[] | undefined
// > => {
//   return useSWR<Organization[] | undefined>(`${baseUrl}/organizations/profiles`)
// }

export const useOrganizationByAccount = (
  accountId: string | undefined,
): SWRResponse<Organization | undefined> => {
  return useSWR<Organization | undefined>(
    accountId ? `${baseUrl}/organizations/accounts/${accountId}` : null,
  )
}

export const useServiceTypes = (): SWRResponse<ServiceType[] | undefined> => {
  return useSWR<ServiceType[] | undefined>(`${baseUrl}/service-types/`)
}

export const useOrganizationProfile = (
  id: string | undefined,
): SWRResponse<OrganizationProfile | undefined> => {
  return useSWR<OrganizationProfile | undefined>(
    id ? `${baseUrl}/organizations/${id}/profile` : null,
  )
}

export const useOrganizationAccounts = (
  organizationId: string | undefined,
): SWRResponse<AccountOrganization[] | undefined> => {
  return useSWR<AccountOrganization[] | undefined>(
    organizationId
      ? `${baseUrl}/organizations/${organizationId}/accounts`
      : null,
  )
}

export const useOrganizationAccount = (
  accountId: string | undefined,
): SWRResponse<AccountOrganization | undefined> => {
  return useSWR<AccountOrganization | undefined>(
    accountId ? `${baseUrl}/organizations/accounts/${accountId}` : null,
  )
}

export const useOrganizationsAdmin = (): SWRResponse<
  Organization[] | undefined
> => {
  const { data, error, isValidating, mutate } = useSWR<
    Organization[] | undefined
  >('/admin/organizations/', organizationsFetcher)
  return { data, error, isValidating, mutate }
}

export const useOrganizationProfileAdmin = (
  organizationId: string | undefined,
): SWRResponse<OrganizationProfile | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    OrganizationProfile | undefined
  >(organizationId ? `${baseUrl}/admin/organizations/${organizationId}` : null)
  return { data, error, isValidating, mutate }
}

export const updateOrganizationProfile = async (
  organizationId: string,
  requestData: UpdateOrganizationProfileRequest,
): Promise<OrganizationProfile> => {
  return axios
    .put<UpdateOrganizationProfileRequest, AxiosResponse<OrganizationProfile>>(
      `${baseUrl}/organizations/${organizationId}/profile`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for updated organization profile',
        status,
        data,
      )
      return data
    })
}

export const getOrganizationOfUser = async (): Promise<Organization> => {
  return axios.get<Organization>(`${baseUrl}/oranizations`).then(({ data }) => {
    return data
  })
}

export const addOrganizationAccount = async (
  orgId: string,
  requestData: CreateCollaboratorRequest,
): Promise<Collaborator | CreateInviteResponse> => {
  return axios
    .post<
      CreateCollaboratorRequest,
      AxiosResponse<Collaborator | CreateInviteResponse>
    >(`${baseUrl}/organizations/${orgId}/accounts`, requestData, {
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info(
        'Response from server for new organization account',
        status,
        data,
      )
      return data
    })
}

export const createInstagramConnection = async (
  orgId: string,
  requestData: CreateInstagramRequest,
): Promise<CreateInstagramResponse | undefined> => {
  return axios
    .post<CreateInstagramRequest, AxiosResponse<CreateInstagramResponse>>(
      `${baseUrl}/organizations/${orgId}/integrations/instagram`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for creating instagram integration',
        status,
        data,
      )
      log.debug('accessTokenValidTo: ', data.accessTokenValidTo)
      return data
    })
}

export const endInstagramConnection = async (
  orgId: string,
): Promise<undefined> => {
  return axios
    .put<undefined, AxiosResponse<undefined>>(
      `${baseUrl}/organizations/${orgId}/integrations/instagram`,
    )
    .then(() => {
      log.info('Instagram connection was deleted')

      return undefined
    })
}

export const addOrganization = async (
  requestData: CreateOrganizationRequest,
): Promise<CreateOrganizationResponse> => {
  return axios
    .post<CreateOrganizationRequest, AxiosResponse<CreateOrganizationResponse>>(
      `${baseUrl}/organizations`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new organization', status, data)
      return data
    })
}

export const updateOrganizationAccount = async (
  orgId: string,
  accountId: string,
  requestData: UpdateAccountOrganizationRequest,
): Promise<AccountOrganization> => {
  return axios
    .put<UpdateAccountOrganizationRequest, AxiosResponse<AccountOrganization>>(
      `${baseUrl}/organizations/${orgId}/accounts/${accountId}`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for update organization account',
        status,
        data,
      )
      return data
    })
}

export const updateOrganization = async (
  id: string,
  requestData: UpdateOrganizationRequest,
): Promise<Organization> => {
  return axios
    .put<UpdateOrganizationRequest, AxiosResponse<Organization>>(
      `${baseUrl}/organizations/${id}`,
      requestData,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for updated organization', status, data)
      return data
    })
}

export const updateOrganizationStatus = async (
  id: string,
  status: OrganizationStatus,
): Promise<Organization> => {
  return axios
    .patch<PatchOrganizationRequest, AxiosResponse<Organization>>(
      `${baseUrl}/organizations/${id}`,

      { status },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for patching organization status',
        status,
        data,
      )
      return data
    })
}

export const updateOrganizationStatusAdmin = async (
  id: string,
  status: OrganizationStatus,
): Promise<Organization> => {
  return axios
    .patch<PatchOrganizationRequest, AxiosResponse<Organization>>(
      `${baseUrl}/admin/organizations/${id}`,
      { status },
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for admin  patching organization status',
        status,
        data,
      )
      return data
    })
}
