import { CheckCircleIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { HelpRequestTemplateResponse } from '@contracts/misc'
import { BsFillXCircleFill } from 'react-icons/bs'

const HelpRequestTemplateRowCard: React.FC<{
  helpReqTemplate: HelpRequestTemplateResponse
}> = ({ helpReqTemplate }) => {
  return (
    <Flex justifyContent="space-between" w="100%" alignItems="center">
      <Flex
        textStyle="textLarge"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        gap={4}
      >
        <Text ml={2} flexWrap="wrap">
          {helpReqTemplate.name}
        </Text>
      </Flex>
      {(helpReqTemplate.status === 'Draft' ||
        helpReqTemplate.status === 'Ready') && (
        <CheckCircleIcon color="gray.200" fontSize="xl" />
      )}
      {helpReqTemplate.status === 'Active' && (
        <CheckCircleIcon color="green" fontSize="xl" />
      )}
      {helpReqTemplate.status === 'Deleted' && (
        <BsFillXCircleFill color="red" size={24} />
      )}
    </Flex>
  )
}
export default HelpRequestTemplateRowCard
