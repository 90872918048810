import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  AttachmentSummary,
  CreateNoteRequest,
  Note,
  UpdateNoteRequest,
} from '@contracts/misc'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NoteStatus } from '@contracts/misc'
import { Attachment } from '@contracts/support'
import {
  addAttachment,
  deleteAttachment,
  deleteAttachmentsByAreaQueries,
  updateContextAreaId,
} from '../../apiClients/attachmentsApiClient'
import { useAllNotes as useAllProjectNotes } from '../../apiClients/projectsApiClient/notes'
import { useAllNotes as useAllPropertyNotes } from '../../apiClients/propertiesApiClient/notes'
import { useArea } from '../../common/area-context'
import { validateString } from '../../common/auth-utils'
import log from '../../common/log'
import { getDateStrFromNumber, getTimeStrFromNumber } from '../../common/util'
import FeedbackPopover from '../feedback/FeedbackPopover'
import Tiptap from '../richTextEditor/Tiptap'
import Spinner from '../Spinner'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import CheckboxGroup from '../ui/CheckboxGroup'
import Input from '../ui/Input'
import InputDate from '../ui/InputDate'
import InputTime from '../ui/InputTime'
import {
  deleteConversation,
  useConversationsByArea,
  useIsUserAllowedToDeleteConversation,
} from '../../apiClients/messagesApiClient'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { usePropertyInfo } from '../../apiClients/propertiesApiClient/properties'
import console from '../../common/log'
import {
  connectEmbeddedImagesNotConnected,
  removeConnectedImagesNotEmbedded,
} from '../attachments/attachmentHelper'

import FileManagerBeforeContextId from '../ui/FileManagerBeforeContextId'
import {
  copyExistingAttachmentsToNoteHandler,
  latestNotesMaxAgeInMonths,
  useNoteParts,
} from './notesHelper'

export const MAX_LENGTH_NOTE_TITLE = 255
interface AddOrEditNoteValues {
  title: string
  date?: string
  time?: string
  description?: string
  propertyNoteTypeIds: string[]
  projectNoteTypeId: string
  profileImg?: string
  status?: NoteStatus
  attachments: File[]
}
interface Errors {
  title?: string
  date?: string
  time?: string
  description?: string
  propertyNoteTypeIds?: string
  projectNoteTypeId?: string
}

const AddOrEditNote: React.FC<{
  onCloseModal: (avoidAlertDialog?: boolean) => void
  note?: Note
  onNoteHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  propertyId?: string
  projectId?: string
}> = ({
  propertyId,
  projectId,
  onCloseModal,
  note,
  onNoteHasUnsavedChanges,
}) => {
  const noteArea = useArea()
  // console.log('note: ', note)
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const [isDeleting, setIsDeleting] = useState(false)
  const toast = useToast()

  const { data: conversation } = useConversationsByArea(
    noteArea.area === 'property' ? 'PropertyNote' : 'ProjectNote',
    [note?.id ?? ''],
    true,
  )

  const conversationId = conversation?.length
    ? conversation[0].conversationId
    : undefined

  let isAllowedToDeleteNote: boolean
  const { data: isAllowedToDelete } =
    useIsUserAllowedToDeleteConversation(conversationId)
  if (!conversationId) {
    isAllowedToDeleteNote = true
  } else {
    isAllowedToDeleteNote = isAllowedToDelete ?? false
  }

  // const [myFilesSelectionToAdd, setMyFilesSelectionToAdd] = useState<
  //   AttachmentSummary[]
  // >([])
  const { entityId, noteTypes, mutate, addNote, updateNote, deleteNote } =
    useNoteParts({
      propertyId,
      projectId,
    })
  const { mutate: mutateAllPropertyNotes } = useAllPropertyNotes(
    latestNotesMaxAgeInMonths,
  )
  const { mutate: mutateAllProjectNotes } = useAllProjectNotes(
    latestNotesMaxAgeInMonths,
  )
  const { data: property } = usePropertyInfo(propertyId)
  const { data: project } = useProjectInfo(projectId)

  const [description, setDescription] = useState(note?.description ?? '<p></p>')

  const [attachmentsJson, setAttachmentsJson] = useState<AttachmentSummary[]>(
    note?.attachmentsJson ?? [],
  )

  const [newFiles, setNewFiles] = useState<File[]>([])

  const initialValues: AddOrEditNoteValues = {
    title: note?.name ?? '',
    date: getDateStrFromNumber(note?.noteTimestamp),
    time: getTimeStrFromNumber(note?.noteTimestamp),
    description: note?.description ?? '',
    propertyNoteTypeIds: note?.noteTypeIds ?? [],
    projectNoteTypeId: note?.noteTypeIds[0] ?? '',
    status: note?.status,
    attachments: [],
  }

  // Keep "NotSet" on top to make it default work correctly
  const availableStatuses: NoteStatus[] = [
    'NotSet',
    'Approved',
    'Done',
    'Draft',
    'Ongoing',
    'Rejected',
    'Todo',
  ]

  const [addedAttachmentIds, setAddedAttachmentIds] = useState<
    string[] | undefined
  >()
  const imgIds: string[] = []

  const validate = (values: AddOrEditNoteValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.title,
      'rubrik',
      2,
      MAX_LENGTH_NOTE_TITLE,
      true,
    )
    if (!tmpRes.isValid) {
      errors.title = tmpRes.errorMsg
    }

    if (noteArea.area === 'property' && !values.propertyNoteTypeIds?.length) {
      errors.propertyNoteTypeIds = t(
        'notes.addOrUpdateForm.propertyNoteTypeIdsErrorMsg',
      )
    }
    if (noteArea.area === 'project' && !values.projectNoteTypeId) {
      errors.projectNoteTypeId = t(
        'notes.addOrUpdateForm.projectNoteTypeIdsErrorMsg',
      )
    }
    return errors
  }

  const imgAddedFromRichTextHandler = (imgId: string) => {
    imgIds.push(imgId)
    setAddedAttachmentIds(imgIds)
  }

  const persistNewFilesHandler = async (noteId: string) => {
    const newAttachments: Attachment[] = []
    await Promise.all(
      newFiles.map(async file => {
        const formData = new FormData()
        formData.set('image', file)

        if (noteArea.area === 'property') {
          formData.set('area', 'PropertyNote')
          formData.set('parentArea', 'Property')
          if (propertyId) {
            formData.set('parentAreaId', propertyId)
          }
        } else {
          formData.set('area', 'ProjectNote')
          if (projectId) {
            formData.set('parentArea', 'Project')
            formData.set('parentAreaId', projectId)
            formData.set('grandParentArea', 'Property')
            if (project && project.propertyId) {
              formData.set('grandParentAreaId', project.propertyId)
            }
          }
        }

        formData.set('areaId', noteId)

        // const newAttachment = await addAttachment(formData, true)
        newAttachments.push(await addAttachment(formData, true))
        // return newAttachment
      }),
    )
    return newAttachments
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      const noteTimestamp: Date =
        values.date && values.time
          ? new Date(`${values.date} ${values.time}`)
          : new Date()

      if (note) {
        const requestData: UpdateNoteRequest = {
          name: values.title,
          noteTimestamp: noteTimestamp.getTime(),
          starred: note.starred,
          entityId,
          status: values.status,
          noteTypeIds:
            noteArea.area === 'property'
              ? values.propertyNoteTypeIds
              : [values.projectNoteTypeId],
          noteTypeOther: '',
          attachmentsJson: note.attachmentsJson,

          description: description,
        }
        const grandParentAreaId = property ? undefined : project?.propertyId

        await connectEmbeddedImagesNotConnected(description, {
          area:
            noteArea.area === 'property'
              ? 'PropertyNote.description'
              : 'ProjectNote.description',

          areaId: note?.id,
          parentArea: noteArea.area === 'property' ? 'Property' : 'Project',
          parentAreaId: noteArea.area === 'property' ? propertyId : projectId,

          grandParentArea: property ? undefined : 'Project',
          grandParentAreaId,
        })

        // eslint-disable-next-line no-console
        console.log('removeConnectedImagesNotEmbedded(note.description)')
        await removeConnectedImagesNotEmbedded(description, {
          area:
            noteArea.area === 'property'
              ? 'PropertyNote.description'
              : 'ProjectNote.description',

          areaId: note?.id,
          parentArea: noteArea.area === 'property' ? 'Property' : 'Project',
          parentAreaId: noteArea.area === 'property' ? propertyId : projectId,

          grandParentArea: property ? undefined : 'Project',
          grandParentAreaId,
        })
        // }

        updateNote(note.id, requestData)
          .then(() => {
            applicationInsights.trackEvent({
              name:
                noteArea.area === 'property'
                  ? 'Property Note updated'
                  : 'Project Note updated',
            })
            onNoteHasUnsavedChanges(false)
            mutate ? void mutate() : ''
            toast({
              title: t('notes.updateSuccessMessage', { name: note.name }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateAllPropertyNotes()
            void mutateAllProjectNotes()
          })
          .catch(err => {
            log.error('Failed to update note', err)
            toast({
              title: t('notes.updateErrorMessage', { name: values.title }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            onCloseModal(true)
            setSubmitting(false)
          })
      } else {
        // Manage files to attach
        // 1) Make new attachments incl. context data out of "newFiles"
        // 2) Add new attachmetns to note's attachmentsJson
        // 3) Copy attachments from "attachmentsJson" and set new context data
        // 4) Add new copied attachments to note's attachmentsJson

        const requestData: CreateNoteRequest = {
          name: values.title,
          noteTimestamp: noteTimestamp.getTime(),
          starred: false,
          entityId,
          noteTypeIds:
            noteArea.area === 'property'
              ? values.propertyNoteTypeIds
              : [values.projectNoteTypeId],
          noteTypeOther: '',
          attachmentsJson: [],

          description: description,
          status: values.status,
        }

        addNote(entityId, requestData)
          // .then(() => {
          .then(async resNote => {
            log.info('Successfully created new note')

            // Map images added to rich text properly
            if (addedAttachmentIds?.length) {
              addedAttachmentIds.map(async imgId => {
                await updateContextAreaId(imgId, resNote.id)
              })
            }
            const persistedNewFilesAsAttachments = await persistNewFilesHandler(
              resNote.id,
            )
            const copiedAttachments =
              await copyExistingAttachmentsToNoteHandler(
                attachmentsJson,
                resNote.id,
                noteArea.area === 'property' ? 'PropertyNote' : 'ProjectNote',
                propertyId,
                projectId,
                project,
              )

            if (
              persistedNewFilesAsAttachments.length ||
              copiedAttachments.length
            ) {
              requestData.attachmentsJson = [
                ...(requestData?.attachmentsJson ?? []),
                ...persistedNewFilesAsAttachments,
                ...copiedAttachments,
              ]
              // const result = await updateNote(resNote.id, requestData)
              await updateNote(resNote.id, requestData)
              // console.log('result: ', result)
            }
            applicationInsights.trackEvent({
              name:
                noteArea.area === 'property'
                  ? 'Property Note added'
                  : 'Project Note added',
            })
            mutate ? void mutate() : ''
            toast({
              title: t('notes.createSuccessMessage', { name: values.title }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateAllPropertyNotes()
            void mutateAllProjectNotes()
          })
          .catch(err => {
            log.error('Failed to create new property note', err)
            toast({
              title: t('notes.createErrorMessage', { name: values.title }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            onCloseModal(true)
            mutate ? void mutate() : ''
            setSubmitting(false)
          })
      }
    },
  })

  const onChangeDescriptionHandler = (text: string) => {
    setDescription(text)
    onNoteHasUnsavedChanges(true)
  }

  const onDeleteNoteHandler = () => {
    // 1) Delete note
    // 2) Delete underlying conversation
    // 3) Delete underlying conversation
    // 4) Delete attached files
    // 5) Delete attached embedded images in description
    if (!note || isDeleting) {
      return
    }
    if (!isAllowedToDeleteNote && !conversationId) {
      log.warn(
        'Current user is not allowed to delete note, due to other user has added messages. Only admin is allowed to delete such a note',
      )
      return
    }
    setIsDeleting(true)
    // 1 - Delete Note
    deleteNote(note)
      .then(async () => {
        // 4 - Delete attached files
        if (note.attachmentsJson) {
          await Promise.all(
            note.attachmentsJson.map(async ({ id }) => {
              await deleteAttachment(id, true)
            }),
          )
        }
        log.debug('Successfully deleted note')
        mutate ? void mutate() : ''
        toast({
          title: t('notes.noteDeleteSuccessMessage', { name: note.name }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        if (conversationId) {
          // 2 - delete note conversation
          deleteConversation(conversationId)
            .then(() => {
              log.info('Successfully deleted note conversation')
            })
            .catch(err => {
              log.error(
                { err: err },
                'Failed to delete conversation during note deletion',
              )
            })
        }
        deleteAttachmentsByAreaQueries([
          // 3 - delete rich-text-embedded note description images
          {
            area:
              noteArea.area === 'property'
                ? 'PropertyNote.description'
                : 'ProjectNote.description',
            areaId: note.id,
          },
          // 3 - delete rich-text-embedded note conversation images
          {
            parentArea:
              noteArea.area === 'property' ? 'PropertyNote' : 'ProjectNote',
            parentAreaId: note.id,
          },
        ])
          .then()
          .catch(err => {
            log.error({ err }, 'Failed to delete attachments')
          })
      })
      .catch(err => {
        log.error({ err: err }, `Error deleting note ${note.id}`)
      })
      .finally(() => {
        setIsDeleting(false)
        onCloseModal(true)
      })
  }

  return (
    <>
      <Box
        h={16}
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          {note && noteArea.area === 'property' && t('notes.editPropertyNote')}
          {note && noteArea.area === 'project' && t('notes.editProjectNote')}
          {!note && noteArea.area === 'property' && t('notes.addPropertyNote')}
          {!note && noteArea.area === 'project' && t('notes.addProjectNote')}
        </Box>

        <FeedbackPopover context="notes" />
      </Box>

      <form onSubmit={handleSubmit} id="add-or-edit-note-form">
        <Flex flexDir="column" my={4} px={2}>
          <Input
            title={t('notes.addOrUpdateForm.title')}
            name="title"
            type="text"
            defaultValue={initialValues.title}
            isMandatory={true}
            isError={!!errors.title}
            errorText={touched.title && errors.title ? errors.title : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="single"
            tooltip={t('notes.addOrUpdateForm.titleTooltip')}
          />
          <Flex flexDir="row" justifyContent="left" w="100%" my={0} gap={6}>
            <InputDate
              title={t('notes.addOrUpdateForm.date')}
              name="date"
              value={formikValues.date}
              isError={!!errors.date}
              errorText={touched.date && errors.date ? errors.date : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              position="single"
              my={6}
            />
            <InputTime
              name="time"
              defaultValue={initialValues.time}
              isError={!!errors.time}
              errorText={touched.time && errors.time ? errors.time : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              position="single"
              my={6}
            />
            {noteArea.area === 'project' && (
              <Select
                name="status"
                defaultValue={note?.status ?? 'notSet'}
                size="sm"
                mt={2}
                onChange={e => {
                  handleChange(e)
                }}
                my={6}
              >
                {availableStatuses.map(status => {
                  return (
                    <option key={status} value={status}>
                      {t(`notes.status.${status}`)}
                    </option>
                  )
                })}
              </Select>
            )}
          </Flex>
          <Box w="100%" maxW="container.lg" mr={6}>
            <Tiptap
              border="1px solid red"
              content={note?.description}
              w="100%"
              maxH="sm"
              h={40}
              toolbarVariant="basic"
              withTypographyExtension={true}
              withLinkExtension={true}
              onChange={onChangeDescriptionHandler}
              editorLabel={t('notes.addOrUpdateForm.description')}
              area={
                noteArea.area === 'property'
                  ? 'PropertyNote.description'
                  : 'ProjectNote.description'
              }
              areaId={note?.id}
              parentArea={noteArea.area === 'property' ? 'Property' : 'Project'}
              parentAreaId={
                noteArea.area === 'property' ? propertyId : projectId
              }
              grandParentArea={property ? undefined : 'Project'}
              grandParentAreaId={property ? undefined : project?.propertyId}
              // conversationArea={propertyId ? 'PropertyNote' : 'ProjectNote'}
              container={'private'}
              onImgAdded={imgAddedFromRichTextHandler}
            />
          </Box>

          <Text ml={4} textStyle="infoSmall">
            *{t('input.mandatoryFields')}
          </Text>

          {!note && (
            <FileManagerBeforeContextId
              variant="menu"
              buttonW="100%"
              my={4}
              attachmentsJson={attachmentsJson}
              setAttachmentsJson={setAttachmentsJson}
              newFiles={newFiles}
              setNewFiles={setNewFiles}
            />
          )}

          {noteArea.area === 'property' && (
            <CheckboxGroup
              title={t('notes.addOrUpdateForm.chooseCategory')}
              isError={!!errors.propertyNoteTypeIds}
              errorText={
                touched.propertyNoteTypeIds && errors.propertyNoteTypeIds
                  ? t('notes.addOrUpdateForm.propertyNoteTypeIdsErrorMsg')
                  : ''
              }
              isMandatory={true}
              tooltip={t('notes.addOrUpdateForm.chooseCategoryTooltip')}
              columns={3}
              defaultValue={initialValues.propertyNoteTypeIds}
            >
              <Flex
                w={{ xxs: 'xs', laptop: 'lg' }}
                flexDir="row"
                flexWrap="wrap"
                mb={4}
              >
                {noteTypes?.map(noteType => {
                  return (
                    <Checkbox
                      key={noteType.id}
                      name="propertyNoteTypeIds"
                      value={noteType.id}
                      py={2}
                      w="250px"
                      minW="250px"
                      onChange={e => {
                        handleChange(e)
                        onNoteHasUnsavedChanges(true)
                      }}
                      onBlur={handleBlur}
                    >
                      {noteType.name}
                    </Checkbox>
                  )
                })}
              </Flex>
            </CheckboxGroup>
          )}
          {noteTypes && noteArea.area === 'project' && (
            <RadioGroup defaultValue={initialValues.projectNoteTypeId}>
              <Text mt="1rem" fontStyle="infoText">
                {t('notes.projectNoteType')}
              </Text>
              <Text ml={4} textStyle="errorSmall">
                {touched.projectNoteTypeId && errors.projectNoteTypeId
                  ? t('notes.addOrUpdateForm.projectNoteTypeIdsErrorMsg')
                  : ''}
              </Text>
              <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight" mb={4}>
                {noteTypes.map(p => {
                  return (
                    <Radio
                      key={p.id}
                      name="projectNoteTypeId"
                      value={p.id}
                      py={1}
                      onChange={handleChange}
                      w="230px"
                      minW="230px"
                    >
                      {p.name}
                    </Radio>
                  )
                })}
              </Flex>
            </RadioGroup>
          )}

          <HStack spacing={2} mt={2} mb={{ xxs: 32, laptop: 0 }}>
            {note && isAllowedToDeleteNote && (
              <AlertDialogAndButton
                title={t('notes.deleteNote')}
                message={t('notes.deleteNoteQuestion', { name: note.name })}
                buttonTitle={t('ui.button.delete')}
                buttonW="100%"
                onDestructiveAction={onDeleteNoteHandler}
              />
            )}
            {note && !isAllowedToDeleteNote && (
              <Alert status="warning">
                <AlertIcon />
                {t('notes.notAllowedToDeleteNote')}
              </Alert>
            )}

            <Button
              type="submit"
              margin="1rem 0"
              w="100%"
              variant={
                Object.keys(errors).length !== 0 ? 'disabled' : 'primary'
              }
              disabled={isSubmitting}
              id="save-note"
            >
              {note ? t('ui.button.save') : t('ui.button.add')}
            </Button>
          </HStack>
        </Flex>

        {(isSubmitting || isDeleting) && <Spinner />}
      </form>
    </>
  )
}
export default AddOrEditNote
