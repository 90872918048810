import { Box, Button, Flex, Radio, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { AccountRelation, AccountTypeName, Collaborator } from '@contracts/misc'
import { PropertyResponse } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'

import { useEffect, useState } from 'react'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import RadioGroup from '../ui/RadioGroup'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useAccountInvites } from '../../apiClients/accountsApiClient'
import { useCollaboratorParts } from './collaboratorHelper'

interface FormValues {
  accountType: 'Consumer' | 'Provider' | 'Not-set'
  // accountType: AccountTypeName
  userEmail: string
  accessRights: AccountRelation | 'Client'
}
interface Errors {
  userEmail?: string
  accessRights?: string
  initalAccountType?: string
}

const AddOrEditCollaborator: React.FC<{
  onCloseModal: () => void
  property?: PropertyResponse
  project?: ProjectResponse
  accountId?: string
  isEdit?: boolean
}> = ({ onCloseModal, property, project, accountId, isEdit }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const area: 'Property' | 'Project' = property ? 'Property' : 'Project'
  const areaId = property ? property.id : project?.id
  const applicationInsights = useAppInsightsContext()
  const widthRoleRadio = { xxs: 40, tablet: 52 }
  const [showClientOption, setShowClientOption] = useState(true)

  const { entityId, collaborators, addCollaborator, updateCollaborator } =
    useCollaboratorParts({ propertyId: property?.id, projectId: project?.id })

  const { mutate: mutateInvites } = useAccountInvites(area, areaId)

  const thisCollaborator: Collaborator | undefined = accountId
    ? collaborators?.find(c => {
        return c.accountId === accountId
      })
    : undefined
  const [showUserTypeNotSetError, setShowUsertyepNotSetError] = useState(false)

  useEffect(() => {
    if (thisCollaborator?.accountType === 'Provider') {
      setShowClientOption(false)
    } else if (area === 'Property') {
      setShowClientOption(false)
    }
  }, [])

  const { mutate: mutateConversation } = useConversationsByArea(
    property ? 'Property' : 'Project',
    property ? [property.id] : project ? [project.id] : undefined,
  )

  const validate = (values: FormValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.userEmail,
      t('accounts.usersEmail'),
      2,
      100,
      true,
    )
    if (!tmpRes.isValid) {
      errors.userEmail = tmpRes.errorMsg
    }
    if (values.accountType === 'Not-set') {
      errors.initalAccountType = 'Välj privatperson eller företagare'
    }
    return errors
  }

  const initialValues: FormValues = {
    accountType: 'Not-set',
    userEmail: thisCollaborator?.account?.email ?? '',
    accessRights: thisCollaborator?.client
      ? 'Client'
      : thisCollaborator?.accountRelation ?? 'Viewer',
  }

  if (thisCollaborator) {
    if (thisCollaborator.accountType === 'Admin') {
      initialValues.accountType = 'Not-set'
    } else {
      initialValues.accountType = thisCollaborator.accountType ?? 'Not-set'
    }
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,

    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      let accessRights: AccountRelation
      let isClient: boolean
      if (values.accessRights === 'Client') {
        accessRights = 'Owner'
        isClient = true
      } else {
        accessRights = values.accessRights as AccountRelation
        isClient = false
      }
      if (!accountId) {
        // if (!thisCollaborator) {
        // Add collaborator
        const newCollaboratorRequest = {
          accountEmail: values.userEmail,
          accountRelation: accessRights,

          accountType: values.accountType as AccountTypeName,
          client: isClient,
        }

        addCollaborator(entityId, newCollaboratorRequest)
          .then(() => {
            applicationInsights.trackEvent(
              {
                name: property?.id
                  ? 'Added property collaborator'
                  : 'Added project collaborator',
              },
              newCollaboratorRequest,
            )

            toast({
              title: t(
                `collaborators.${
                  property?.id
                    ? 'addPropertyCollaboratorSuccessMessage'
                    : 'addProjectCollaboratorSuccessMessage'
                }`,
                { name: newCollaboratorRequest.accountEmail },
              ),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })

            void mutateConversation()
            void mutateInvites()
            onCloseModal()
          })
          .catch(e => {
            log.error({ err: e }, 'Failed to add collaborator')
            toast({
              title: t(
                `collaborators.${
                  property?.id
                    ? 'addPropertyCollaboratorFailureMessage'
                    : 'addProjectCollaboratorFailureMessage'
                }`,
                {
                  name: newCollaboratorRequest.accountEmail,
                },
              ),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      } else {
        // Edit collaborator
        const collaboratorRequest = {
          accountId,
          accountRelation: accessRights,
          accountType: values.accountType as AccountTypeName,
          client: isClient,
        }

        updateCollaborator(entityId, collaboratorRequest, values.userEmail)
          .then(() => {
            applicationInsights.trackEvent(
              {
                name: property?.id
                  ? 'Updated property collaborator'
                  : 'Updated project collaborator',
              },
              collaboratorRequest,
            )

            toast({
              title: t('collaborators.updateSuccessMessage', {
                name: thisCollaborator?.account?.email,
              }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateInvites()
            onCloseModal()
          })
          .catch(e => {
            log.error({ err: e }, 'Failed to update collaborator')
            toast({
              title: t('collaborators.updateFailureMessage', {
                name: thisCollaborator?.account?.email,
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="add-edit-collaborator">
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">
          {t(`properties.${isEdit ? 'edit' : 'add'}Collaborator`)} -{' '}
          {property ? property.name : null}
          {project ? project.name : null}
        </Box>
      </Box>
      <Flex flexDir="column" mb={4} mx={6}>
        {!accountId && (
          <>
            <RadioGroup
              title={t('collaborators.addAccountType')}
              isError={showUserTypeNotSetError ? true : false}
              message={
                property
                  ? t('collaborators.addAccountTypeForPropertyMessage')
                  : ''
              }
              value={formikValues.accountType}
              errorText="Välj privatperson eller företagare"
              isMandatory
            >
              <>
                <Radio
                  hidden
                  name="accountType"
                  value="Not-set"
                  onChange={event => {
                    setShowClientOption(true)
                    handleChange(event)
                  }}
                  mr={4}
                />
                <Radio
                  name="accountType"
                  value="Consumer"
                  onChange={event => {
                    setShowClientOption(true)
                    handleChange(event)
                    setShowUsertyepNotSetError(false)
                  }}
                  mr={6}
                >
                  {t('general.privateUser')}
                </Radio>
                <Radio
                  name="accountType"
                  value="Provider"
                  onChange={event => {
                    setShowClientOption(false)
                    formikValues.accessRights = 'Owner'
                    !property ? handleChange(event) : undefined
                    setShowUsertyepNotSetError(false)
                  }}
                  disabled={property ? true : false}
                  cursor={property ? 'not-allowed' : ''}
                >
                  {t('general.companyUser')}
                </Radio>
              </>
            </RadioGroup>
            <Input
              title={t(
                isEdit
                  ? 'accounts.accountForm.email'
                  : 'properties.newCollaboratorEmail',
              )}
              name="userEmail"
              type="email"
              defaultValue={thisCollaborator?.account?.email}
              isMandatory={true}
              isError={!!errors.userEmail}
              errorText={
                touched.userEmail && errors.userEmail ? errors.userEmail : ''
              }
              onChange={handleChange}
              onBlur={e => {
                handleBlur(e)

                if (formikValues.accountType === 'Not-set') {
                  setShowUsertyepNotSetError(true)
                }
              }}
              position="single"
              mt={5}
            />
          </>
        )}
        <RadioGroup
          title={t('properties.accessRights.accessRights')}
          value={
            // thisCollaborator?.client ? 'Client' : formikValues.accessRights
            formikValues.accessRights
          }
          tooltip={t('properties.accessRights.tooltip')}
          isMandatory={true}
        >
          <>
            {showClientOption && (
              <Radio
                key="Client"
                name="accessRights"
                value="Client"
                padding=".5rem 1rem"
                onChange={handleChange}
                minW={widthRoleRadio}
              >
                {t('properties.accessRights.client')}
              </Radio>
            )}
            <Radio
              key="Owner"
              name="accessRights"
              value="Owner"
              padding=".5rem 1rem"
              onChange={handleChange}
              minW={widthRoleRadio}
            >
              {t('properties.accessRights.owner')}
            </Radio>
            <Radio
              key="Editor"
              name="accessRights"
              value="Editor"
              padding=".5rem 1rem"
              onChange={handleChange}
              minW={widthRoleRadio}
            >
              {t('properties.accessRights.editor')}
            </Radio>
            <Radio
              key="Viewer"
              name="accessRights"
              value="Viewer"
              padding=".5rem 1rem"
              onChange={handleChange}
              minW={widthRoleRadio}
            >
              {t('properties.accessRights.viewer')}
            </Radio>
          </>
        </RadioGroup>

        <Button
          onClick={() => {
            // eslint-disable-next-line no-console
            // console.log('formikValues: ', formikValues)
            if (formikValues.accountType === 'Not-set') {
              setShowUsertyepNotSetError(true)
            }
            handleSubmit()
          }}
          margin="1rem 0"
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
          disabled={isLoading}
        >
          {t(
            isEdit
              ? 'ui.button.update'
              : 'properties.sendCollaboratorInvitation',
          )}
        </Button>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}
export default AddOrEditCollaborator
