import { useTranslation } from 'react-i18next'
import { Icon } from './Icon'

const StarIcon: React.FC<{
  isStarred: boolean
  onClick?: () => void
  h?: number
}> = ({ isStarred, onClick, h = 30 }) => {
  const { t } = useTranslation()
  return (
    <Icon
      name={isStarred ? 'AiFillStar' : 'AiOutlineStar'}
      color="logo_yellow"
      h={h}
      onClick={onClick}
      _hover={{ cursor: 'pointer' }}
      tooltip={isStarred ? t('general.unstarMark') : t('general.starMark')}
    />
  )
}
export default StarIcon
