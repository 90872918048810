import { Box, Link as ChakraLink } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Link as ReactLink } from 'react-router-dom'
const Link: React.FC<{
  children: ReactNode
  to: string
  textStyle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'text'
  onClick?: () => void
  padding?: string
  zIndex?: string
  pl?: number
  mb?: number | string
  isDisabled?: boolean
  isExternal?: boolean
  color?: string
  noHover?: boolean
}> = ({
  padding,
  onClick,
  textStyle,
  children,
  to,
  zIndex,
  pl,
  mb,
  isDisabled = false,
  isExternal = false,
  color = 'gray.800',
  noHover = false,
}) => {
  if (isDisabled) {
    return (
      <Box
        padding={padding}
        textStyle={textStyle}
        _hover={{ cursor: 'not-allowed' }}
        zIndex={zIndex}
        pl={pl}
        color="gray.400"
        mb={mb}
      >
        {children}
      </Box>
    )
  }
  if (isExternal) {
    return (
      <ChakraLink
        href={to}
        padding={padding}
        onClick={onClick}
        isExternal
        textStyle={textStyle}
        _hover={noHover ? { color: '' } : { color: 'linkHover' }}
        zIndex={zIndex}
        pl={pl}
        color={color}
        mb={mb}
      >
        {children}
      </ChakraLink>
    )
  }

  return (
    <ChakraLink
      to={to}
      padding={padding}
      onClick={onClick}
      as={ReactLink}
      textStyle={textStyle}
      _hover={noHover ? { color: '' } : { color: 'linkHover' }}
      zIndex={zIndex}
      pl={pl}
      color={color}
      pb={mb}
    >
      {children}
    </ChakraLink>
  )
}
export default Link
