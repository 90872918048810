import { Flex, Text } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  useOrganization,
  useOrganizationAccounts,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import AddOrEditProviderBaseData from '../../components/sections/portal/AddOrEditOrganizationBaseData'
import AddOrEditProviderData from '../../components/sections/portal/AddOrEditOrganizationData'
import AddOrEditProviderProfile from '../../components/sections/portal/AddOrEditOrganizationProfile'
import ProfileSectionCard from '../../components/ui/ProfileSectionCard'
import {
  isMandatoryProviderDataFilledIn,
  isProfileInfoFilledIn,
} from '../../components/provider/providerHelper'
import ListOrganizationAccounts from '../../components/organizations/ListOrgAccounts'
import AddOrEditOrgAccountButton from '../../components/ui/AddOrEditOrgAccountButton'
import ListOrgAccountInvites from '../../components/organizations/ListOrgAccountInvites'
import { isOutOfDateOrInvalid } from '../../common/util'
import ManageInstaSection from '../admin/ManageInstaSection'
import OrganizationPublicCard from '../../components/organizations/ProviderProfilePublicCard'
import ProviderProfileTemplate from '../../components/provider/ProviderProfileTemplate'
import EditProviderAvailability from '../../components/sections/portal/EditProviderAvailability'
import { useOrganizationCollaborators } from '../../apiClients/organizationsCollaboratorApiClient'
import { Heading } from '../../ui'
import ProviderProfilePageHeader from './ProviderProfilePageHeader'
import ProviderContactsSection from './ProviderContactsSection'

const ProviderProfilePage = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const accessCode = search.get('code')
  const { currentUser, currentOrganization } = useContext(AuthContext)
  const [instaConnectionWasCreated, setInstaConnectionWasCreated] =
    useState(false)
  const { data: currentOrganizationData, mutate: mutateContacts } =
    useOrganization(currentOrganization?.id)

  const { data: currentOrganizationProfile, mutate: mutateProfile } =
    useOrganizationProfile(currentOrganization?.id)

  const { data: organizationCollaborators } = useOrganizationCollaborators(
    currentOrganization?.id,
  )

  const { data: orgAccounts } = useOrganizationAccounts(currentOrganization?.id)

  const currentOrgAccount = orgAccounts?.find(orgAccount => {
    return orgAccount.accountId === currentUser?.id
  })

  if (
    !currentOrganization ||
    !currentOrganizationData ||
    !currentOrganizationProfile
  ) {
    return null
  }

  const profileFilledIn = isProfileInfoFilledIn(currentOrganizationProfile)
  const mandatoryProviderDataFilledIn = isMandatoryProviderDataFilledIn(
    currentOrganizationData,
  )

  return (
    <>
      <Helmet>
        <title>{t('providers.profile.title')}</title>
      </Helmet>
      <Flex
        flexDir="column"
        px={{ xxs: 2, tablet: '5%' }}
        justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
        mt={{ laptop: 10 }}
        mb={10}
        gap={4}
      >
        <ProviderProfilePageHeader currentOrganization={currentOrganization} />

        {/* Member section */}
        <ProfileSectionCard
          title={t('providers.profile.sections.manageMembers')}
          hideFilledInIcon
          isFilledIn
        >
          <>
            <AddOrEditOrgAccountButton
              orgId={currentOrganization.id}
              variant={'BUTTON'}
              w={48}
              isDisabled={currentOrgAccount?.accountRelation !== 'Owner'}
              tooltip={
                currentOrgAccount?.accountRelation !== 'Owner'
                  ? t('providers.notAllowedToEditIfNotOwnerTooltip')
                  : ''
              }
            >
              {t('providers.addOrgUser')}
            </AddOrEditOrgAccountButton>
            <ListOrganizationAccounts orgId={currentOrganization.id} my={3} />
            <ListOrgAccountInvites orgId={currentOrganization.id} pb={5} />
          </>
        </ProfileSectionCard>

        {/* Registration information  */}
        <ProfileSectionCard
          title={t('providers.profile.sections.registrationInformation')}
          isFilledIn={currentOrganization ? true : false}
        >
          <AddOrEditProviderBaseData
            currentOrganization={currentOrganization}
          />
        </ProfileSectionCard>
        {currentOrganization && (
          <>
            {/* Provider data */}
            <ProfileSectionCard
              title={t('providers.profile.sections.providerData')}
              isFilledIn={mandatoryProviderDataFilledIn}
            >
              <AddOrEditProviderData
                currentOrganization={currentOrganizationData}
                mutate={mutateContacts}
              />
            </ProfileSectionCard>

            {/* Manage profile data */}
            <ProfileSectionCard
              title={t('providers.profile.sections.profile')}
              isFilledIn={profileFilledIn}
            >
              <AddOrEditProviderProfile
                currentOrganization={currentOrganization}
                currentOrganizationProfile={currentOrganizationProfile}
                mutate={mutateProfile}
              />
            </ProfileSectionCard>

            {/* Manage (profile) availability */}
            <ProfileSectionCard
              title={t('providers.profile.sections.availability')}
              isFilledIn={profileFilledIn}
            >
              <EditProviderAvailability
                currentOrganization={currentOrganization}
                currentOrganizationProfile={currentOrganizationProfile}
                mutate={mutateProfile}
              />
            </ProfileSectionCard>
          </>
        )}
        {/* Manage instagram settings */}
        <ProfileSectionCard
          title={t('providers.profile.sections.instagram')}
          isFilledIn={
            !isOutOfDateOrInvalid(
              currentOrganizationProfile?.instagramTokenValidTo ?? undefined,
            ) || instaConnectionWasCreated
              ? true
              : false
          }
        >
          <ManageInstaSection
            accessCode={accessCode ?? undefined}
            connectionWasCreated={instaConnectionWasCreated}
            setConnectionWasCreated={setInstaConnectionWasCreated}
          />
        </ProfileSectionCard>
        <ProfileSectionCard
          title={t('providers.profile.sections.ourNetwork')}
          isFilledIn={organizationCollaborators?.length ? true : false}
        >
          <Flex flexDir="column" gap={2}>
            <Heading textStyle="h8" my={2}>
              {t('providers.contacts.partner')}
            </Heading>
            <Text textStyle="infoTextLight">
              {t('providers.contacts.partnerInfo')}
            </Text>
            <Heading textStyle="h8" my={2}>
              {t('providers.contacts.validate')}
            </Heading>
            <Text textStyle="infoTextLight">
              {t('providers.contacts.validateInfo')}
            </Text>
            <Text textStyle="" mt={4}>
              {t('providers.contacts.contactsInfo')}
            </Text>
            <ProviderContactsSection mx={0} px={0} py={0} mb={10} maxW="100%" />
          </Flex>
        </ProfileSectionCard>
      </Flex>

      {/* Preview public profile page */}
      <Flex flexDir="column" px={{ xxs: 0, tablet: '5%' }} gap={2}>
        <Text>Förhandsvisning av ert publika profilkort</Text>
        <OrganizationPublicCard org={currentOrganization} isPreview />
        <Text mt={10}>Förhandsvisning av er publika profilsida</Text>
        <Flex
          borderWidth={{ xxs: 0, tablet: '1px' }}
          borderColor="gray.300"
          mb={10}
        >
          <ProviderProfileTemplate
            organization={currentOrganization}
            borderColor="gray.300"
            bg="primaryBackground"
          />
        </Flex>
      </Flex>
    </>
  )
}
export default ProviderProfilePage
