import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import ArticleTemplate1 from '../../components/articleTemplates/ArticleTemplate1'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { useArticle } from '../../apiClients/articlesApiClient'
import { ingressGuidancePage } from '../../apiClients/contentApiClient'
import {
  PATH_MY_PAGES_GUIDANCE,
  PATH_MY_PRIVATE_PAGES,
  PATH_PUBLIC_GUIDANCE,
} from '../../common/nav'
import Divider from '../../components/ui/Divider'
import GuidancePromoSection from '../../components/sections/articles/GuidancePromoSection'
import SimpleArticlesSection from '../../components/sections/articles/SimpleArticlesSection'

const ArticleWithId = () => {
  const navigate = useNavigate()
  const { id: articleId } = useParams()
  const { data: article } = useArticle(articleId)
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)

  return (
    <>
      <Helmet>
        <title>{article?.heading}</title>
      </Helmet>
      <ArticleTemplate1
        articleId={articleId}
        minW="100px"
        maxW="container.xl"
        onClose={() =>
          navigate(
            isWithinMyPages
              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${PATH_MY_PAGES_GUIDANCE}`
              : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${PATH_PUBLIC_GUIDANCE}`,
          )
        }
      />
      <Divider mb={10} />
    </>
  )
}

const GuidancePage = () => {
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)
  const { t } = useTranslation()
  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>{t('portal.sidebar.menu.guidance')}</title>
              </Helmet>
              <ContentPageHeader
                label={`${t('portal.sidebar.menu.guidance').toUpperCase()}`}
                ingress={ingressGuidancePage}
                mb={isWithinMyPages ? 6 : 0}
                isEmbedded={isWithinMyPages ? false : true}
              />
            </>
          }
        />
        <Route path="/:id/" element={<ArticleWithId />} />
      </Routes>

      <GuidancePromoSection mx="5%" />
      <Divider />
      <SimpleArticlesSection
        title={t('articles.latest')}
        pathPortal={PATH_MY_PAGES_GUIDANCE}
        pathPublic={PATH_PUBLIC_GUIDANCE}
        pageTag={'guidance'}
        mb={64}
        mx="5%"
      />
    </>
  )
}
export default GuidancePage
