import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { AccountData, AttachmentSummary } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'
import _ from 'lodash'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  useProjectsByAccount,
  useProjectsByProperties,
} from '../../apiClients/projectsApiClient/projects'
import { useProperties } from '../../apiClients/propertiesApiClient'
import AuthContext from '../../common/auth-context'
import { getLink } from '../../common/nav'
import FileDirectory from '../ui/FileDirectory'
import FileHeader from '../ui/FileHeader'

import FileList from '../ui/FileList'
import Link from '../ui/Link'

const Heading: React.FC<{
  title: string
  currentLevel: number
  setLevel: (level: number) => void
  // area?: string
  area?: 'property' | 'project' | 'propertyNote' | 'projectNote'
  areaId?: string
}> = ({ title, currentLevel, setLevel, area, areaId }) => {
  const { pathname } = useLocation()
  const maxTitleLength = 19
  return (
    <Flex
      h={10}
      bg="primary_grey02"
      borderTopRadius="lg"
      color="white"
      justifyContent={currentLevel === 1 ? 'space-around' : 'space-between'}
      alignItems="center"
      textStyle="h6"
      // border="1px solid red"
    >
      {currentLevel > 1 && (
        <ChevronLeftIcon
          boxSize={8}
          _hover={{ color: 'gray.300' }}
          onClick={() => {
            setLevel(currentLevel - 1)
          }}
        />
      )}
      {currentLevel < 2 && <Box> </Box>}

      {_.truncate(title, { length: maxTitleLength })}
      {area && areaId && (
        <Link to={getLink(pathname, area, areaId)}>
          <ExternalLinkIcon
            color="white"
            _hover={{ color: 'gray.300' }}
            mr={4}
          />
        </Link>
      )}
      {!areaId && <Box> </Box>}
    </Flex>
  )
}

const AccountAttachmentsMobileTreeViewCard: React.FC<{
  account: AccountData
  mt?: string | number
  bg?: string
  minH?: string
  isCheckable?: boolean
  w?: ResponsiveValue<string | number>
  onCloseModal?: () => void
  callback?: (attachments: AttachmentSummary[]) => void
}> = ({
  account,
  mt,
  bg = 'white',
  minH = 'md',
  isCheckable = false,
  w = 'md',
  onCloseModal,
  callback,
}) => {
  const { t } = useTranslation()
  // const columnMinW = 64
  const columnMinW = '370px'
  const paddingLeft = 1

  const { isConsumer, isProvider } = useContext(AuthContext)

  // const { data: allAttachments } = useAccountAttachments(account.id)
  const { data: properties } = useProperties(account.id)
  const { data: allProjects } = useProjectsByAccount(account.id)
  const { data: allPropertyProjects } = useProjectsByProperties(
    properties?.map(property => {
      return property.id
    }),
  )
  const [checkedList, setCheckedList] = useState<AttachmentSummary[]>([])
  const [activeLevel, setActiveLevel] = useState(1)
  const [activeLevelOne, setActiveLevelOne] = useState<string>()
  const [fatherOfLevelTwo, setFatherOfLevelTwo] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [fatherOfLevelThree, setFatherOfLevelThree] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [fatherOfLevelFour, setFatherOfLevelFour] = useState<
    | {
        title: string
        area: 'property' | 'project' | 'propertyNote' | 'projectNote'
        areaId: string
      }
    | undefined
  >(undefined)
  const [activeLevelTwo, setActiveLevelTwo] = useState<string>()
  const [activeLevelThree, setActiveLevelThree] = useState<string>()
  // const sumOfFilesSizes = allAttachments ? getSumOfFiles(allAttachments) : 0

  let orphanProjects: ProjectResponse[] = []

  if (allProjects && allPropertyProjects) {
    orphanProjects = allProjects.filter(project => {
      return !allPropertyProjects.find(propProj => {
        return propProj.id === project.id
      })
    })
  }

  // Level 1 click
  const onPropertyClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevel(2)
    setActiveLevelOne(areaId)
    setActiveLevelTwo(undefined)
    setActiveLevelThree(undefined)
    setFatherOfLevelTwo({
      title,
      area,
      areaId,
    })
    setFatherOfLevelThree(undefined)
    setFatherOfLevelFour(undefined)
  }

  // Level 2 click
  const onProjectOrPropertyNoteClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevel(3)
    setActiveLevelTwo(areaId)
    setActiveLevelThree(undefined)

    setFatherOfLevelThree({
      title,
      area,
      areaId,
    })
    setFatherOfLevelFour(undefined)
  }

  // Level 3 click
  const onProjectNoteClickHandler = (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => {
    setActiveLevel(4)
    setActiveLevelThree(areaId)
    setFatherOfLevelFour({
      title,
      area,
      areaId,
    })
  }

  const onFileCheckedHandler = (attachmentToAdd: AttachmentSummary) => {
    // If list already includes attachmentID, remove it
    if (checkedList.includes(attachmentToAdd)) {
      setCheckedList(
        checkedList.filter(attachment => {
          return attachment.id !== attachmentToAdd.id
        }),
      )
    } else {
      // If list doesn't include attachmentID, add it
      setCheckedList([...checkedList, attachmentToAdd])
    }
  }

  return (
    <Flex
      flexDir="column"
      pos="relative"
      gap={1}
      // mb={8}
      minH={minH}
      mt={mt}
      bg={bg}
      border="1px solid"
      borderRadius="lg"
      borderColor="gray.200"
      boxShadow="lg"
      w={w}
    >
      {/* Level 1 - properties */}
      <Flex
        id="level1"
        flexDir="column"
        gap={0}
        minW={columnMinW}
        pb={16}
        display={activeLevel === 1 ? '' : 'none'}
      >
        <Heading
          title="Mina filer"
          currentLevel={activeLevel}
          setLevel={setActiveLevel}
        />
        <Flex pl={paddingLeft} flexDir="column">
          {/* No properties */}
          {isConsumer &&
            (!properties || properties.length === 0) &&
            (!orphanProjects || orphanProjects.length === 0) && (
              <Text textStyle="cardText" my={2} mx={2}>
                {t('attachments.noPropertiesNorProjectsFound')}
              </Text>
            )}
          {/* No projects */}
          {isProvider && (!orphanProjects || orphanProjects.length === 0) && (
            <Text textStyle="cardText" my={2} mx={2}>
              {t('attachments.noProjectsFound')}
            </Text>
          )}
          {properties && properties.length > 0 && (
            <FileHeader area={'property'} />
          )}
          {properties &&
            properties.length > 0 &&
            properties.map(property => {
              return (
                <Box px={0} overflow="clip" key={property.id}>
                  <FileDirectory
                    title={property.name}
                    area="property"
                    areaId={property.id}
                    isActive={property.id === activeLevelOne}
                    callback={onPropertyClickHandler}
                  />
                </Box>
              )
            })}
          {orphanProjects && orphanProjects.length > 0 && (
            <FileHeader area={'project'} />
          )}
          {orphanProjects &&
            orphanProjects.length > 0 &&
            orphanProjects.map(project => {
              return (
                <Box px={0} overflow="clip" key={project.id}>
                  <FileDirectory
                    title={project.name}
                    area="project"
                    areaId={project.id}
                    isActive={project.id === activeLevelOne}
                    callback={onPropertyClickHandler}
                  />
                </Box>
              )
            })}
        </Flex>
      </Flex>
      {/* Level 2 - projects and property notes */}
      {fatherOfLevelTwo && (
        <Flex
          id="level2"
          flexDir="column"
          minW={columnMinW}
          // maxW={96}
          maxW={w}
          // bg={bg}
          // border="1px solid"
          // borderRadius="lg"
          // borderColor="gray.200"
          // boxShadow="lg"
          pb={16}
          // pb={2}
          display={activeLevel === 2 ? '' : 'none'}
        >
          <Heading
            title={fatherOfLevelTwo.title}
            currentLevel={activeLevel}
            setLevel={setActiveLevel}
            area={fatherOfLevelTwo.area}
            areaId={fatherOfLevelTwo.areaId}
          />
          <Flex pl={paddingLeft} flexDir="column">
            <FileList
              area={fatherOfLevelTwo.area}
              areaId={fatherOfLevelTwo.areaId}
              callback={onProjectOrPropertyNoteClickHandler}
              activeId={activeLevelTwo}
              noFileLink
              isCheckable={isCheckable}
              checkedList={checkedList}
              onFileChecked={onFileCheckedHandler}
            />
          </Flex>

          {/* Always present projects that user is member of but without connection to any property   */}
        </Flex>
      )}
      {/* Level 3 - project notes, property note files */}
      {fatherOfLevelThree && (
        <Flex
          id="level3"
          flexDir="column"
          minW={columnMinW}
          maxW={w}
          // pb={2}
          pb={16}
          display={activeLevel === 3 ? '' : 'none'}
        >
          <Heading
            title={fatherOfLevelThree.title}
            currentLevel={activeLevel}
            setLevel={setActiveLevel}
            area={fatherOfLevelThree.area}
            areaId={fatherOfLevelThree.areaId}
          />
          <Flex pl={paddingLeft} flexDir="column">
            <FileList
              area={fatherOfLevelThree.area}
              areaId={fatherOfLevelThree.areaId}
              callback={onProjectNoteClickHandler}
              activeId={activeLevelThree}
              noFileLink
              isCheckable={isCheckable}
              checkedList={checkedList}
              onFileChecked={onFileCheckedHandler}
            />
          </Flex>
        </Flex>
      )}
      {/* Level 4 - project note files */}
      {fatherOfLevelFour && (
        <Flex
          id="level4"
          flexDir="column"
          minW={columnMinW}
          // bg={bg}
          // border="1px solid"
          // borderRadius="lg"
          // borderColor="gray.200"
          // boxShadow="lg"
          // pb={2}
          pb={16}
          display={activeLevel === 4 ? '' : 'none'}
        >
          <Heading
            title={fatherOfLevelFour.title}
            currentLevel={activeLevel}
            setLevel={setActiveLevel}
            area={fatherOfLevelFour.area}
            areaId={fatherOfLevelFour.areaId}
          />
          <Flex pl={paddingLeft} flexDir="column">
            <FileList
              area="projectNote"
              areaId={fatherOfLevelFour.areaId}
              callback={onProjectNoteClickHandler}
              activeId={activeLevelThree}
              noFileLink
              isCheckable={isCheckable}
              checkedList={checkedList}
              onFileChecked={onFileCheckedHandler}
            />
          </Flex>
        </Flex>
      )}
      {isCheckable && (
        <Flex
          justifyContent="right"
          pos="absolute"
          right={2}
          bottom={2}
          gap={4}
        >
          <Button
            variant="ghost"
            size="sm"
            border="1px solid"
            borderColor="gray.300"
            onClick={() => {
              callback ? callback([]) : ''
              onCloseModal ? onCloseModal() : ''
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              callback ? callback(checkedList) : ''
              onCloseModal ? onCloseModal() : ''
            }}
          >
            Lägg till
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
export default AccountAttachmentsMobileTreeViewCard
