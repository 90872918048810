import { Button, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import AddAttachment from '../attachments/AddAttachment'
import Modal from './Modal'
const AddAttachmentButton: React.FC<{
  mt?: number
  w?: number
  children: ReactElement
}> = ({ mt, children, w }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} mt={mt} variant="primary" w={w || 32}>
        {children}
      </Button>

      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AddAttachment onClose={onClose} />
      </Modal>
    </>
  )
}
export default AddAttachmentButton
