/* eslint-disable react/jsx-no-useless-fragment*/
import { Box, Button, Checkbox, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { Article, Tag } from '@contracts/support'
import Spinner from '../Spinner'

import CheckboxGroup from '../ui/CheckboxGroup'
import { useTags } from '../../apiClients/articlesApiClient'

interface AddTagFormValues {
  checkedTagIds: string[]
}
const AddTag: React.FC<{
  onCloseModal: () => void

  article: Article
  updateCallbackHandler: (tags: Tag[]) => void
}> = ({ article, onCloseModal, updateCallbackHandler }) => {
  const { t } = useTranslation()
  const { data: allTags } = useTags()

  const initialValues: AddTagFormValues = {
    checkedTagIds: article.tags.map(t => {
      return t.id
    }),
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const tags: Tag[] = []

      values.checkedTagIds.forEach(tagId => {
        const tag = allTags?.find(tag => {
          return tag.id === tagId
        })

        if (tag) {
          tags.push(tag)
        }
      })
      updateCallbackHandler(tags)
      onCloseModal()
      setSubmitting(false)
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="add-tags">
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('tags.addTags')}</Box>
      </Box>
      <Flex flexDir="column" mx={2} my={4}>
        {allTags ? (
          <CheckboxGroup defaultValue={initialValues.checkedTagIds}>
            <>
              {allTags?.map(tag => {
                return (
                  <Checkbox
                    key={tag.id}
                    name="checkedTagIds"
                    value={tag.id}
                    py={2}
                    px={4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {tag.name}
                  </Checkbox>
                )
              })}
            </>
          </CheckboxGroup>
        ) : (
          <Spinner />
        )}
        <Button
          type="submit"
          margin="1rem 0"
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
          disabled={isLoading}
        >
          {t('input.submitForm')}
        </Button>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}

export default AddTag
