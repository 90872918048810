import { CloseIcon } from '@chakra-ui/icons'
import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { ClimateResource } from '@contracts/support'
import { useClimateResourceCategories } from '../../apiClients/climateApiClient'
import Divider from '../ui/Divider'

const CompareClimateImpactCard: React.FC<{
  product: ClimateResource
  ml?: number
  onClose: (product: ClimateResource) => void
}> = ({ product, ml, onClose }) => {
  const { data: categories } = useClimateResourceCategories()

  let categoryName: string | undefined = undefined

  if (categories) {
    categoryName = categories?.find(
      category => category.id === product.categoryId,
    )?.name
  }

  const unit = product.impactUnit.replace('CO2 eq.', 'CO₂e')

  return (
    <Flex
      flexDir="column"
      bg="white"
      boxShadow="lg"
      px={6}
      py={3}
      textAlign="center"
      borderRadius="md"
      w={60}
      minW={60}
      ml={ml}
      pos="relative"
    >
      <CloseIcon
        pos="absolute"
        right={3}
        boxSize={3}
        mr={0}
        color="gray.300"
        onClick={() => onClose(product)}
        _hover={{ cursor: 'pointer', color: 'gray.600' }}
      />
      <Tooltip label={product.name}>
        <Text mt={3} fontWeight="bold" h={12} overflow="clip">
          {product.name ?? ' - '}
        </Text>
      </Tooltip>
      <Divider />
      <Text fontWeight="light">{categoryName ?? ' - '}</Text>
      <Divider />
      <Text fontWeight="light">
        {product.resourceImpact.toLocaleString('sv-SE') ?? ' - '} {unit}
      </Text>
      <Divider />
      <Text fontWeight="light">
        {product.transportImpact.toLocaleString('sv-SE') ?? ' - '} {unit}
      </Text>
      <Divider />
      <Text fontWeight="light">
        {product.wasteImpact.toLocaleString('sv-SE') ?? ' - '} {unit}
      </Text>
      <Divider />
      <Text fontWeight="light">{product.serviceLife ?? ' - '}</Text>
      <Divider />
      <Text fontWeight="light">{product.conversion ?? ' - '}</Text>
      <Divider />
      <Text fontWeight="light" fontSize="14px">
        {product.applicability ?? ' - '}
      </Text>
      <Divider />
    </Flex>
  )
}
export default CompareClimateImpactCard
