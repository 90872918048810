import { ArticleInfo } from '@contracts/support'
import { filter, orderBy } from 'lodash'
import { ArticleSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: ArticleSortingOptionType,
  articles?: ArticleInfo[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption
  const filteredArticles = searchStr
    ? filter(articles, ({ heading }) => {
        const searchStrLowercase = searchStr.toLocaleLowerCase()
        return heading.toLocaleLowerCase().includes(searchStrLowercase)
      })
    : articles
  return orderBy(filteredArticles, sortingField, sortOrder)
}
