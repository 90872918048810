import { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { ProjectResponse } from '@contracts/projects'
import AuthContext from '../../common/auth-context'
import { PATH_MY_PRIVATE_PAGES, navLinksConsumerPortal } from '../../common/nav'
import { IconName } from '../../common/icon-utils'
import AddProperty from '../../components/properties/AddProperty'
import { useProperties } from '../../apiClients/propertiesApiClient'
import { useProjects } from '../../apiClients/projectsApiClient'

import Modal from '../../components/ui/Modal'
import AddProject from '../../components/projects/AddProject'
import { Icon } from '../../components/ui/Icon'
import { Logo } from '../../components/ui/Logo'
import { SIDE_BAR_WIDTH_EXPANDED } from '../constants'
import SidebarAreaHeader from './SidebarAreaHeader'
import SidebarItem from './SidebarItem'

const SidebarPortal: React.FC<{
  // isExpanded: boolean
  // onToggle: () => void
}> = () =>
  // {
  // isExpanded, onToggle
  // },
  {
    const { t } = useTranslation()
    const marginLeftHeading = 4
    const navigate = useNavigate()

    const authCtx = useContext(AuthContext)
    const {
      isOpen: isOpenAddPropertyModal,
      onOpen: onOpenAddPropertyModal,
      onClose: onCloseAddPropertyModal,
    } = useDisclosure()
    const {
      isOpen: isOpenAddProjectModal,
      onOpen: onOpenAddProjectModal,
      onClose: onCloseAddProjectModal,
    } = useDisclosure()
    // const toggleExpandHandler = () => {
    //   onToggle()
    // }
    if (!authCtx.currentUser) {
      navigate('/error')
    }

    const { data: properties } = useProperties()
    const { data: projects } = useProjects()

    const orphanProjects: ProjectResponse[] | undefined = projects?.filter(
      project => {
        return !properties?.find(property => {
          return project.propertyId === property.id
        })
      },
    )

    return (
      <>
        <Flex
          pos="fixed"
          padding="2.5rem 0rem"
          flexDir="column"
          // w={isExpanded ? SIDE_BAR_WIDTH_EXPANDED : SIDE_BAR_WIDTH_COLLAPSED}
          w={SIDE_BAR_WIDTH_EXPANDED}
          h="100vh"
          boxShadow="xl"
          background="lightBackground"
          onScroll={() => {
            window.moveTo(50, 300)
          }}
          overflow="scroll"
        >
          {/* {isExpanded && ( */}
          <Logo
            name="BerikarWithName"
            mb={4}
            h={8}
            onClick={() => {
              navigate(PATH_MY_PRIVATE_PAGES)
            }}
            ml={6}
          />
          {/* )} */}
          {/* {!isExpanded && (
            <Logo
              name="Berikar"
              alignSelf="center"
              mb={4}
              h={8}
              onClick={() => {
                navigate(PATH_MY_PRIVATE_PAGES)
              }}
            />
          )} */}

          {/* My Properties section */}
          <SidebarAreaHeader
            mt={4}
            mr={6}
            ml={marginLeftHeading}
            onClick={onOpenAddPropertyModal}
            // isMinimized={!isExpanded}
            toolTipText={t('portal.sidebar.addNewProperty')}
            dataCy="addNewProperty"
          >
            {/* {isExpanded && <Text>{t('general.myProperties')}</Text>} */}
            <Text>{t('general.myProperties')}</Text>
          </SidebarAreaHeader>
          {!properties && (
            <Box
              as="p"
              padding=".1rem .2rem .4rem 4.1rem"
              textStyle="inputPlaceholder"
            >
              {t('portal.loading...')}
            </Box>
          )}
          {properties && properties.length === 0 && (
            <Box
              padding=".1rem .2rem .4rem 4.1rem"
              textStyle="inputPlaceholder"
            >
              {/* {isExpanded && <Text>{t('portal.sidebar.noHomesFound')}</Text>} */}
              <Text>{t('portal.sidebar.noHomesFound')}</Text>
            </Box>
          )}
          {properties &&
            properties.length > 0 &&
            properties.map(property => {
              return (
                <Fragment key={property.id}>
                  <SidebarItem
                    key={property.id}
                    variant="property"
                    pl={2}
                    py={1}
                    mt={3}
                    pr={1}
                    // isMinimized={!isExpanded}
                    toLink={`${PATH_MY_PRIVATE_PAGES}fastighet/${property.id}`}
                  >
                    {property.name}
                  </SidebarItem>
                  {projects &&
                    projects.map(project => {
                      if (project.propertyId === property.id) {
                        return (
                          <SidebarItem
                            key={project.id}
                            variant="project"
                            pl={4}
                            py={1}
                            pr={1}
                            toLink={`${PATH_MY_PRIVATE_PAGES}projekt/${project.id}`}
                            hideLogo
                            isLightFont
                          >
                            {/* {`${PATH_MY_PRIVATE_PAGES}projekt/${project.id}`} - */}
                            {project.name}
                          </SidebarItem>
                        )
                      }
                    })}
                </Fragment>
              )
            })}
          {/* My Projects section */}
          <SidebarAreaHeader
            mt={4}
            mr={6}
            mb={2}
            ml={marginLeftHeading}
            onClick={onOpenAddProjectModal}
            toolTipText={t('portal.sidebar.addNewProject')}
          >
            <Text>{t('general.otherProjects')}</Text>
          </SidebarAreaHeader>
          {orphanProjects &&
            orphanProjects.map(p => {
              return (
                <SidebarItem
                  key={p.id}
                  variant="project"
                  pl={2}
                  py={1}
                  pr={1}
                  toLink={`${PATH_MY_PRIVATE_PAGES}projekt/${p.id}`}
                >
                  {p.name}
                </SidebarItem>
              )
            })}

          {/* Menu section */}
          <SidebarAreaHeader
            mt={4}
            mr={6}
            mb={2}
            ml={marginLeftHeading}
            noAddIcon={true}
          >
            {/* {isExpanded && <Text>{t('portal.sidebar.menu.menu')}</Text>} */}
            <Text>{t('portal.sidebar.menu.menu')}</Text>
          </SidebarAreaHeader>
          {navLinksConsumerPortal &&
            navLinksConsumerPortal.map((item, i) => {
              return (
                <SidebarItem
                  key={`menu${i}`}
                  variant="menu"
                  icon={<Icon name={item.icon as IconName} />}
                  pl={2}
                  py={1}
                  pr={1}
                  // isMinimized={!isExpanded}
                  toLink={item.to}
                  isDisabled={
                    item.isDisabled || (properties?.length === 0 ?? false)
                  }
                >
                  {t(item.value)}
                </SidebarItem>
              )
            })}

          <Box height={4}></Box>
          {/* <HorizontalSliderButton
            onToggle={toggleExpandHandler}
            isExpanded={isExpanded}
          /> */}
        </Flex>

        <Modal
          isOpen={isOpenAddPropertyModal}
          onOpen={onOpenAddPropertyModal}
          onClose={onCloseAddPropertyModal}
          size="xl"
        >
          <AddProperty onCloseModal={onCloseAddPropertyModal} />
        </Modal>
        <Modal
          isOpen={isOpenAddProjectModal}
          onOpen={onOpenAddProjectModal}
          onClose={onCloseAddProjectModal}
          size="xl"
        >
          <AddProject
            properties={properties}
            onCloseModal={onCloseAddProjectModal}
          />
        </Modal>
      </>
    )
  }

export default SidebarPortal
