import { Box, Button, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useNavigate } from 'react-router-dom'
import log from '../../common/log'
import {
  addProperty,
  useProperties,
  usePropertyTypes,
} from '../../apiClients/propertiesApiClient'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import FeedbackPopover from '../feedback/FeedbackPopover'
import { PATH_MY_PROPERTIES } from '../../common/nav'

interface MyFormValues {
  propertyName: string
  street: string
  zipCode: string
  city: string
  propertyTypeId: string
}
interface Errors {
  propertyName?: string
  street?: string
  zipCode?: string
  city?: string
  proertyTypeId?: string
}
const validate = (values: MyFormValues) => {
  const errors: Errors = {}

  let tmpRes = validateString(values.propertyName, 'namn', 2, 30, true)
  if (!tmpRes.isValid) {
    errors.propertyName = tmpRes.errorMsg
  }
  tmpRes = validateString(values.street, 'gata', 2, 30, true)
  if (!tmpRes.isValid) {
    errors.street = tmpRes.errorMsg
  }
  tmpRes = validateString(values.zipCode, 'postnummer', 5, 8, true)
  if (!tmpRes.isValid) {
    errors.zipCode = tmpRes.errorMsg
  }
  tmpRes = validateString(values.city, 'postort', 2, 30, true)
  if (!tmpRes.isValid) {
    errors.city = tmpRes.errorMsg
  }
  return errors
}

const AddProperty: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}) => {
  const applicationInsights = useAppInsightsContext()
  const { data: propertyTypes } = usePropertyTypes()
  const { t } = useTranslation()
  const { mutate } = useProperties()
  const navigate = useNavigate()

  const initialValues: MyFormValues = {
    propertyName: '',
    street: '',
    zipCode: '',
    city: '',
    propertyTypeId: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      addProperty(
        values.propertyName,
        values.propertyTypeId,
        values.street,
        values.zipCode,
        values.city,
      )
        .then(res => {
          log.info('Successfully created new property')
          void mutate()
          applicationInsights.trackEvent(
            { name: 'Created property' },
            {
              propertyName: values.propertyName,
              propertyType: propertyTypes?.find(
                pt => pt.id === values.propertyTypeId,
              )?.name,
            },
          )
          // Navigate to property just created
          if (typeof res === 'object' && 'data' in res) {
            const propertyId = res.data.data.id as string
            navigate(`${PATH_MY_PROPERTIES}/${propertyId}`)
          }
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to create new property')
        })
        .finally(() => {
          onCloseModal()
          setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    if (propertyTypes && propertyTypes?.length > 0) {
      handleChange({
        target: {
          name: 'propertyTypeId',
          value: propertyTypes[0].id,
        },
      })
    }
  }, [handleChange, propertyTypes])

  return (
    <>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={5} data-cy="addPropertyHeader">
          {t('properties.addProperty')}
        </Box>

        <FeedbackPopover context="properties" />
      </Box>

      <form onSubmit={handleSubmit} data-cy="add-property">
        <Box display="flex" flexDirection="column" m="1rem 1.5rem">
          <Input
            title={t('properties.propertyForm.nameToCallHome')}
            name="propertyName"
            type="text"
            isMandatory={true}
            isError={!!errors.propertyName}
            errorText={
              touched.propertyName && errors.propertyName
                ? errors.propertyName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
            tooltip="Välj vilket namn du vill kalla ditt hem"
          />
          <Input
            title="Adress"
            name="street"
            type="text"
            isMandatory={true}
            isError={!!errors.street}
            errorText={touched.street && errors.street ? errors.street : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title="Postnummer"
            name="zipCode"
            isMandatory={true}
            type="text"
            isError={!!errors.zipCode}
            errorText={touched.zipCode && errors.zipCode ? errors.zipCode : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title="Postort"
            name="city"
            isMandatory={true}
            type="text"
            isError={!!errors.city}
            errorText={touched.city && errors.city ? errors.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="bottom"
          />
          <Text ml="1rem" textStyle="infoSmall">
            *Obligatoriskt fält
          </Text>

          {propertyTypes ? (
            <RadioGroup value={formikValues.propertyTypeId}>
              <Text mt="1rem" fontStyle="infoText">
                Typ av hem
              </Text>
              <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight">
                {propertyTypes.map(p => {
                  return (
                    <Radio
                      key={p.id}
                      w={36}
                      name="propertyTypeId"
                      value={p.id}
                      padding=".5rem 1rem"
                      onChange={handleChange}
                    >
                      {p.name}
                    </Radio>
                  )
                })}
              </Flex>
            </RadioGroup>
          ) : (
            <Spinner />
          )}
          <Button
            type="submit"
            margin="1rem 0"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            data-cy="save-new-property"
            disabled={isLoading}
          >
            Lägg till
          </Button>
        </Box>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default AddProperty
