import axios, { AxiosError } from 'axios'
import { SWRConfiguration } from 'swr'
import { LOG_IN_PATH } from './components/accounts/constants'

const fetcher = async (url: string) =>
  axios.get<unknown>(url).then(({ data }) => data)

/**
 * Global SWR config
 */
const apiConfig: SWRConfiguration = {
  fetcher,
  revalidateOnFocus: false,
  dedupingInterval: 10000,
}

export const axiosErrorInterceptor = (
  error: AxiosError<{ error?: string }, unknown>,
) => {
  if (
    axios.isAxiosError(error) &&
    error.response?.status === 401 &&
    error.response?.data?.error === 'No valid session found'
  ) {
    if (
      window.location.pathname.substring(0, LOG_IN_PATH.length) !==
        LOG_IN_PATH &&
      window.location.pathname.substring(0, 11) !== '/registrera' &&
      !window.location.pathname.includes('emailVerifyCode')
    ) {
      window.location.href = `//${window.location.host}${LOG_IN_PATH}?noValidSession`
    }
  }
  return Promise.reject(error)
}

/**
 * Intercept all axios calls and redirect the user to the login if
 * they don't have a valid session.
 */
axios.interceptors.response.use(undefined, axiosErrorInterceptor)

export default apiConfig
