import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountRelation } from '@contracts/misc'

import { AccountOrganization } from '@contracts/accounts'
import AlertDialogButton from '../ui/AlertDialogAndButton'
import AuthContext from '../../common/auth-context'
import { Icon } from '../ui/Icon'
// import AddOrEditPropertyCollaboratorButton from '../ui/AddOrEditCollaboratorButton'

import AccountProfileCardModal from '../accounts/AccountProfileCardModal'
import AddOrEditOrgAccountButton from '../ui/AddOrEditOrgAccountButton'
import { deleteOrgAccount } from '../../apiClients/accountsApiClient'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'

const OrgAccountAdminCard: React.FC<{
  orgAccount: AccountOrganization
  orgId: string
  currentUserAccessRights: AccountRelation
  isOnlyAdmin: boolean
}> = ({
  orgAccount,
  orgId,

  currentUserAccessRights,
  isOnlyAdmin,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)

  const { mutate } = useOrganizationAccounts(orgId)
  const account = orgAccount.account

  let isAllowedToEdit = false
  let isAllowedToDelete = false
  let tooltipText = ''
  const vSpacing = 4

  if (
    currentUserAccessRights === 'Owner' &&
    currentUser?.status === 'Verified'
  ) {
    if (!isOnlyAdmin) {
      isAllowedToEdit = true
      isAllowedToDelete = true
    } else {
      tooltipText = t('providers.notAllowedToDeleteOnlyAdminTooltip')
    }
  } else {
    if (
      currentUserAccessRights !== 'Owner' ||
      currentUser?.status !== 'Verified'
    ) {
      tooltipText = t('providers.notAllowedToEditTooltip')
    }
  }

  return (
    <Flex
      flexDir="row"
      borderRadius={8}
      flexWrap="wrap"
      justifyContent="space-around"
      alignItems="center"
    >
      {account && (
        <AccountProfileCardModal
          accountId={orgAccount.account?.id}
          variant="organizationAccount"
        >
          <>
            <Flex flexDir="column" mb={vSpacing}>
              <Text mr={1}>{t('properties.propertyForm.name')}</Text>
              <Text>
                {account?.firstName} {account?.lastName}
              </Text>
            </Flex>
            <Flex flexDir="column" ml={{ xxs: 4, laptop: 20 }} mb={vSpacing}>
              <Text>{t('accounts.accountForm.email')}</Text>
              <Text>{account?.email}</Text>
            </Flex>
            <Flex flexDir="column" ml={{ xxs: 4, laptop: 20 }} mb={vSpacing}>
              <Text>{t('accounts.accountForm.phoneNumber')}</Text>
              <Text>
                {account?.phoneNumber ??
                  t('accounts.accountForm.missingPhoneNumber')}
              </Text>
            </Flex>
          </>
        </AccountProfileCardModal>
      )}
      {!account && (
        <Text mb={vSpacing}>{t('properties.collaboratorNotRegistered')}</Text>
      )}
      <Flex flexDir="row" ml={{ xxs: 4, laptop: 20 }}>
        <AddOrEditOrgAccountButton
          orgId={orgId}
          orgAccountId={orgAccount.accountId}
          variant={'ICON'}
          isDisabled={!isAllowedToEdit}
          tooltip={
            isAllowedToEdit ? (isAllowedToEdit ? '' : tooltipText) : tooltipText
          }
        />
        {isAllowedToDelete && (
          <AlertDialogButton
            title={t('providers.deleteUser')}
            message={t('providers.deleteUserQuestion', {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              name: `${account?.firstName} ${account?.lastName}`,
            })}
            buttonTitle={t('ui.button.delete')}
            buttonW="100%"
            onDestructiveAction={() => {
              void deleteOrgAccount(orgId, orgAccount.accountId)
              void mutate()
            }}
            variant="children"
          >
            <Icon
              name="DeleteIcon"
              color="secondary"
              h={5}
              ml={3}
              mt={0.5}
              _hover={{ color: 'gray' }}
              isDisabled={!isAllowedToDelete}
              tooltip={isAllowedToDelete ? '' : tooltipText}
            />
          </AlertDialogButton>
        )}
        {!isAllowedToDelete && (
          <Icon
            name="DeleteIcon"
            color="secondary"
            h={5}
            ml={3}
            _hover={{ color: 'gray' }}
            isDisabled={!isAllowedToDelete}
            tooltip={isAllowedToDelete ? '' : tooltipText}
          />
        )}
      </Flex>
    </Flex>
  )
}
export default OrgAccountAdminCard
