import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Circle, Flex, Image, ResponsiveValue } from '@chakra-ui/react'
import { useState } from 'react'

const GalleryCard: React.FC<{
  items: { media_url: string; media_type: string }[]
  showNav?: boolean
  border?: string
  borderColor?: string
  borderRadius?: string | number
  boxSize?: ResponsiveValue<number | string>
}> = ({
  items,
  border,
  borderColor,
  borderRadius,
  showNav = true,
  boxSize,
}) => {
  const [activeItem, setActiveItem] = useState(0)

  return (
    <Flex
      flexDir="column"
      border={border}
      borderColor={borderColor}
      borderRadius={borderRadius}
      pos="relative"
    >
      {items.map((item, index) => {
        if (item.media_type === 'IMAGE') {
          return (
            <Image
              display={index === activeItem ? '' : 'none'}
              key={item.media_url}
              src={item.media_url}
              alignSelf="center"
              boxSize={boxSize}
            />
          )
        } else if (item.media_type === 'VIDEO') {
          return (
            <Box
              borderRadius="lg"
              maxW={{ xxs: '175px', tablet: '200px' }}
              maxH={{ xxs: '175px', tablet: '200px' }}
              overflow="scroll"
            >
              <video style={{ zIndex: -1 }}>
                <source src={item.media_url} />
              </video>
            </Box>
          )
        }
      })}
      <Flex justifyContent="center" pos="absolute" w="100%" bottom={5} gap={1}>
        {items.map((item, index) => {
          return (
            <Circle
              key={item.media_url}
              size={3}
              border="2px solid"
              borderColor="gray.300"
              bg={index === activeItem ? 'white' : 'transparent'}
            />
          )
        })}
      </Flex>
      {showNav && (
        <Flex
          justifyContent="space-between"
          pos="absolute"
          w="100%"
          bottom={10}
          gap={4}
        >
          <ChevronLeftIcon
            color="white"
            boxSize={24}
            onClick={() => {
              if (activeItem > 0) {
                setActiveItem(activeItem - 1)
              } else {
                setActiveItem(items.length - 1)
              }
            }}
          />
          <ChevronRightIcon
            color="white"
            boxSize={24}
            onClick={() => {
              if (activeItem < items.length - 1) {
                setActiveItem(activeItem + 1)
              } else {
                setActiveItem(0)
              }
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}
export default GalleryCard
