import { Button, Checkbox, Flex, Text } from '@chakra-ui/react'
import {
  OrganizationCollaborator,
  UpdateOrganizationCollaboratorRequest,
} from '@contracts/accounts'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyedMutator } from 'swr'
import {
  deleteOrganizationCollaborator,
  updateOrganizationCollaborator,
} from '../../apiClients/organizationsCollaboratorApiClient'
import AuthContext from '../../common/auth-context'
import log from '../../common/log'
import AccountAvatar from '../accounts/AccountAvatar'
import OrganizationAvatar from '../accounts/OrganizationAvatar'
import Tiptap from '../richTextEditor/Tiptap'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import Divider from '../ui/Divider'
import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import { Icon } from '../ui/Icon'
import TextCard from '../ui/TextCard'

const ExpandableOrganizationCollaboratorCard: React.FC<{
  collaborator: OrganizationCollaborator
  isExpanded?: boolean
  mutateOrganizationCollaborators: KeyedMutator<
    OrganizationCollaborator[] | undefined
  >
}> = ({
  collaborator,
  isExpanded = false,
  mutateOrganizationCollaborators,
}) => {
  const { currentOrganization } = useContext(AuthContext)
  const { t } = useTranslation()
  const [isPartner, setIsPartner] = useState(collaborator.partner)
  const [isEndorse, setIsEndorse] = useState(collaborator.endorse)
  const [comment, setComment] = useState('<p></p>')
  return (
    <ExpandableSectionCard
      variant="arrow-far-right"
      mx={{ xxs: 0, tablet: 0 }}
      w="100%"
      isExpanded={isExpanded}
      headerChildren={
        <OrganizationAvatar
          organizationId={collaborator.organizationId ?? undefined}
          email={collaborator.email ?? undefined}
          variant="avatarAndName"
        />
      }
      noDivider
      boxShadow="none"
    >
      <Flex
        wrap="nowrap"
        py={4}
        px={{ xxs: 4, tablet: 6 }}
        bg="primary_grey01"
        borderRadius="lg"
      >
        <Flex wrap="wrap" gap={6}>
          <TextCard heading={t('general.address.email')} w={64}>
            <Text>{collaborator.email}</Text>
          </TextCard>
          <TextCard heading={t('organizationCollaborators.addedBy')} w={64}>
            <AccountAvatar accountId={collaborator.createdBy} variant="name" />
          </TextCard>
          <Divider my={0} />
          <TextCard heading={t('organizationCollaborators.isPartnerQuestion')}>
            <Checkbox
              // defaultChecked={collaborator.partner ? true : false}
              defaultChecked={isPartner ? true : false}
              onChange={event => {
                let updateRequest: UpdateOrganizationCollaboratorRequest
                if (event.currentTarget.checked === true) {
                  setIsPartner(true)
                  updateRequest = {
                    partner: true,
                    endorse: isEndorse,
                  }
                } else {
                  setIsPartner(false)
                  updateRequest = {
                    partner: false,
                    endorse: isEndorse,
                  }
                }
                void updateOrganizationCollaborator(
                  currentOrganization?.id ?? '',
                  collaborator.id,
                  updateRequest,
                )
                // KEEP! - Has to be two mutates to work (or solve in a better way)
                // should be solvable by using an await instad of void
                void mutateOrganizationCollaborators()
                void mutateOrganizationCollaborators()
              }}
              borderColor="gray.400"
            />
          </TextCard>
          <Divider my={0} />
          <TextCard
            heading={`${t(
              'organizationCollaborators.isValidatedQuestion.part1',
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            )} "${currentOrganization?.name}" ${t(
              'organizationCollaborators.isValidatedQuestion.part2',
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            )} "${collaborator.organization?.name}" ${t(
              'organizationCollaborators.isValidatedQuestion.part3',
            )} `}
          >
            <>
              <Checkbox
                // defaultChecked={collaborator.endorse ? true : false}
                defaultChecked={isEndorse ? true : false}
                onChange={event => {
                  let updateRequest: UpdateOrganizationCollaboratorRequest
                  if (event.currentTarget.checked === true) {
                    setIsEndorse(true)
                    updateRequest = {
                      partner: isPartner,
                      endorse: true,
                    }
                  } else {
                    setIsEndorse(false)
                    updateRequest = {
                      partner: isPartner,
                      endorse: false,
                    }
                  }
                  // const updateRequest: UpdateOrganizationCollaboratorRequest = {
                  //   partner: isPartner,
                  //   endorse: !isEndorse,
                  //   // partner: collaborator.partner,
                  //   // endorse: !collaborator.endorse,
                  // }
                  // setIsPartner(!isPartner)
                  void updateOrganizationCollaborator(
                    currentOrganization?.id ?? '',
                    collaborator.id,
                    updateRequest,
                  )
                  // KEEP! - Has to be two mutates to work (or solve in a better way)
                  // should be solvable by using an await instad of void
                  void mutateOrganizationCollaborators()
                  void mutateOrganizationCollaborators()
                }}
                borderColor="gray.400"
              />
              {/* <Text mt={2}>{t('organizationCollaborators.writeComment')}</Text> */}
              <Tiptap
                area={'Property'}
                container={'private'}
                onImgAdded={() => {
                  log.debug('Not implemented')
                }}
                toolbarVariant="none"
                w="100%"
                content={collaborator?.comment ?? ''}
                placeholder={t('organizationCollaborators.writeComment')}
                mt={2}
                mb={2}
                onChange={text => {
                  setComment(text)
                }}
              />
              <Flex justifyContent="right">
                <Button
                  variant="secondary"
                  w={28}
                  size="sm"
                  onClick={() => {
                    const updateRequest: UpdateOrganizationCollaboratorRequest =
                      {
                        partner: collaborator.partner,
                        endorse: collaborator.endorse,
                        comment: comment,
                      }
                    void updateOrganizationCollaborator(
                      currentOrganization?.id ?? '',
                      collaborator.id,
                      updateRequest,
                    )
                    // KEEP! - Has to be two mutates to work (or solve in a better way)
                    // should be solvable by using an await instad of void
                    void mutateOrganizationCollaborators()
                    void mutateOrganizationCollaborators()
                  }}
                >
                  {collaborator.comment?.length
                    ? t('ui.button.update')
                    : t('ui.button.save')}
                </Button>
              </Flex>
            </>
          </TextCard>
        </Flex>
        <Flex>
          <AlertDialogAndButton
            title={t('organizationCollaborators.deleteCollaborator')}
            message={t('organizationCollaborators.deleteCollaboratorQuestion', {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              email: `${collaborator.email}`,
            })}
            buttonTitle={t('ui.button.delete')}
            buttonW="100%"
            onDestructiveAction={() => {
              void deleteOrganizationCollaborator(
                currentOrganization?.id ?? '',
                collaborator.id,
              )

              // KEEP! - Has to be two mutates to work (or solve in a better way)
              // should by solvable by using an await instad of void
              void mutateOrganizationCollaborators()
              void mutateOrganizationCollaborators()
            }}
            variant="children"
          >
            <Icon
              name="DeleteIcon"
              color="secondary"
              h={5}
              ml={3}
              mt={0.5}
              _hover={{ color: 'gray' }}
            />
          </AlertDialogAndButton>
        </Flex>
      </Flex>
    </ExpandableSectionCard>
  )
}
export default ExpandableOrganizationCollaboratorCard
