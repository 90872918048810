import { Flex } from '@chakra-ui/react'
import { useCollaborators } from '../../apiClients/projectsApiClient'
import AccountAvatar from '../accounts/AccountAvatar'

const ProjectClientsCard: React.FC<{
  projectId: string
  mt?: number
  flexDir?: 'column' | 'row'
  w?: number | string
  variant?: 'name' | 'avatarAndName' | 'avatar' | 'full' | 'avatarAndNameOneRow'
  gap?: number
  columnGap?: number
  rowGap?: number
}> = ({
  projectId,
  mt,
  flexDir = 'row',
  variant = 'avatarAndName',
  w,
  gap = 2,
  columnGap,
  rowGap,
}) => {
  const { data: projectCollaborators } = useCollaborators(projectId)
  const clients = projectCollaborators?.filter(collab => {
    return collab.client === true
  })

  return (
    <Flex
      gap={gap}
      columnGap={columnGap}
      rowGap={rowGap}
      mt={mt}
      flexDir={flexDir}
      textStyle="infoTextLight"
      w={w}
    >
      {clients &&
        clients.length &&
        clients.map(client => {
          return (
            <AccountAvatar
              key={client.accountId}
              accountId={client.accountId}
              variant={variant}
              lightFont
            />
          )
        })}
    </Flex>
  )
}
export default ProjectClientsCard
