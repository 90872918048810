import { Box } from '@chakra-ui/react'
import { Heading } from '../../ui'

const ListFeedback = () => {
  return (
    <Box pb={40}>
      <Heading textStyle="h3">Feedback</Heading>
    </Box>
  )
}
export default ListFeedback
