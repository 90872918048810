export const PATH_PUBLIC_WEB = window.PUBLIC_WEB_URL
export const PATH_PORTAL = window.PORTAL_URL
export const PATH_PUBLIC_PROVIDER_PAGES = 'foretagare'
export const PATH_MY_PRIVATE_PAGES = '/'
export const PATH_MY_PAGES_PROFILE = '/profil'
export const PATH_MY_PROVIDER_PAGES = '/'
export const PATH_MY_PROVIDER_PAGES_USER_PROFILE = '/anvandarprofil'

export const PATH_MY_PROVIDER_PAGES_ORG_PROFILE = '/foretagsprofil'
export const PATH_MY_PROVIDER_PAGES_REGISTER_COMPANY = '/registrera-foretag'

export const PATH_ADMIN_PAGES = '/admin'

export const PATH_PROPERTY_NOTE = '/anteckning'

export const PATH_PROJECT_NOTE = '/projektinlagg'
export const PATH_ADMIN_PAGES_FIND_EXPERT = '/admin/hitta-expert'
export const PATH_PUBLIC_CLIMATE_SMART_CONSTRUCTION = 'klimatsmart-byggande'
export const PATH_MY_PAGES_CLIMATE_SMART_CONSTRUCTION = 'klimatsmart-byggande'

export const PATH_MY_PAGES_FIND_EXPERT = '/hitta-expert'
export const PATH_PUBLIC_INSPIRATION = 'inspiration'

export const PATH_MY_PAGES_INSPIRATION = 'inspiration'
export const PATH_PUBLIC_GUIDANCE = 'vagledning'
export const PATH_PUBLIC_COOKIE_POLICY = '/cookiepolicy'

export const PATH_MY_PAGES_GUIDANCE = 'vagledning'
export const PUBLIC_DROPDOWN_NAME = 'fulfillHomeDreams'

export const PATH_PROPERTY = 'fastighet'
export const MY_HELP_REQUESTS = 'forfragningar'
export const PATH_BUY_SUBSCRIPTION = 'kopa-prenumeration'

export const PATH_MY_PRIVATE_PROPERTIES = '/fastighet'

export const PATH_MY_PROPERTIES = '/fastighet'

export const PATH_MY_PROVIDER_PROPERTIES = '/fastighet'

export const PATH_MY_PRIVATE_PROJECTS = '/projekt'

export const PATH_MY_PROJECTS = '/projekt'
export const PATH_NOTE_QUERY = '?noteId='

export const PATH_MY_PROVIDER_PROJECTS = '/projekt'
export const PATH_PROJECT_PLACE = '/projektplats'

export const PATH_CONSTRUCTION_GLOSSARY = 'byggtermer/'
export const PATH_COMPARE_CLIMATE_IMPACT = '/jamfor-klimatpaverkan/'
export const PATH_CLIMATE_IMPACT_OF_BUILDING_PARTS =
  '/klimatpaverkan-fran-byggdelar'
export const PATH_CONSTRUCTION_PROCESS = 'byggprocessen'
export const PATH_DETAILPLAN_AND_BUILDINGPERMIT = 'detaljplan-och-bygglov'
export const PATH_PROVIDER_PROFILE = 'foretagsprofil'
export const PATH_ADMIN_ORGANIZATION_LIST = '/admin/foretag'
export const PATH_ADMIN_CONSUMER_ACCOUNTS = '/admin/konton/konsument'
export const PATH_ADMIN_PROVIDER_ACCOUNTS = '/admin/konton/leverantor'
export const PATH_ADMIN_PROJECTS = '/admin/projekt'
export const PATH_ADMIN_PROPERTIES = '/admin/fastighet'
export const PATH_ADMIN_PROJECT_PLACES = '/admin/projektplats'

export interface NavLink {
  key: string
  value: string
  to: string
  underConstruction?: boolean
  isDisabled?: boolean
}

export const getHomePath = (pathname: string): string => {
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    return PATH_MY_PRIVATE_PAGES
  }
  return PATH_MY_PROVIDER_PAGES
}

export const getPropertiesPath = (pathname: string): string => {
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    return PATH_MY_PRIVATE_PROPERTIES
  }
  return PATH_MY_PROVIDER_PROPERTIES
}

export const getProjectsPath = (pathname: string): string => {
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    return PATH_MY_PRIVATE_PROJECTS
  }
  return PATH_MY_PROVIDER_PROJECTS
}

export const navLinksPublic: NavLink[] = [
  {
    key: '1',
    value: 'nav.consumers',
    to: '/',
  },
  {
    key: '2',
    value: 'nav.providers',
    to: '/foretagare',
  },
  {
    key: '3',
    value: 'nav.pricing',
    to: '/priser',
  },
]

export const navLinksPublicDropdown = [
  {
    key: '10',
    title: 'nav.climateSmartConstructionTitle',
    text: 'nav.climateSmartConstructionText',
    to: '/klimatsmart-byggande',
    icon: 'climateSmartConstruction',
  },
  {
    key: '11',
    title: 'nav.guidanceTitle',
    text: 'nav.guidanceText',
    to: '/vagledning',
    icon: 'guidance',
  },
]

export const navLinksAdmin: NavLink[] = [
  {
    key: '1',
    value: 'admin.navbar.accountsConsumer',
    to: '/admin/konton/konsument',
  },
  {
    key: '1',
    value: 'admin.navbar.accountsProvider',
    to: '/admin/konton/leverantor',
  },
  {
    key: '8',
    value: 'admin.navbar.organizations',
    to: '/admin/foretag',
  },
  {
    key: '7',
    value: 'admin.navbar.properties',
    to: '/admin/fastighet',
  },
  {
    key: '9',
    value: 'admin.navbar.projects',
    to: '/admin/projekt',
  },
  {
    key: '10',
    value: 'admin.navbar.projectPlaces',
    to: '/admin/projektplats',
  },
  {
    key: '12',
    value: 'admin.navbar.helpRequests',
    to: '/admin/forfragning',
  },
  {
    key: '2',
    value: 'admin.navbar.articles',
    to: '/admin/artiklar',
  },
  {
    key: '3',
    value: 'admin.navbar.attachments',
    to: '/admin/attachments',
  },
  {
    key: '4',
    value: 'admin.navbar.tags',
    to: '/admin/tags',
  },
  {
    key: '5',
    value: 'admin.navbar.system',
    to: '/admin/system',
  },
  {
    key: '6',
    value: 'admin.navbar.featureFlags',
    to: '/admin/featureflags',
  },
  {
    key: '7',
    value: 'admin.navbar.constructionGlossary',
    to: '/admin/byggordlista',
  },
  {
    key: '8',
    value: 'admin.navbar.hittaExpert',
    to: '/admin/hitta-expert',
  },
  {
    key: '9',
    value: 'admin.navbar.tiptap',
    to: '/admin/tiptap',
  },
  {
    key: '10',
    value: 'admin.navbar.instagram',
    to: '/admin/insta',
  },
]

export const navLinksConsumerPortal = [
  {
    value: 'portal.sidebar.menu.myFiles',

    to: '/mina-filer/',
    icon: 'myFiles',
  },
  {
    value: 'portal.sidebar.menu.findExpert',

    to: '/hitta-expert/',
    icon: 'findExpert',
    isDisabled: false,
  },
  {
    value: 'portal.sidebar.menu.helpRequests',

    to: '/forfragningar',
    icon: 'question',
  },
  {
    value: 'portal.sidebar.menu.climateSmartConstruction',

    to: '/klimatsmart-byggande',
    icon: 'climateSmartConstruction',
  },
  {
    value: 'portal.sidebar.menu.compareClimateImpact',

    to: '/jamfor-klimatpaverkan',
    icon: 'compareClimateImpact',
  },
  {
    value: 'portal.sidebar.menu.guidance',

    to: '/vagledning',
    icon: 'guidance',
  },
  {
    value: 'portal.sidebar.menu.constructionGlossary',
    to: '/byggtermer',
    icon: 'constructionGlossary',
  },
]

export const getNavLinksProviderPortal = (hasCurrentOrg: boolean) => {
  return [
    {
      value: 'portal.sidebar.provider.myProjectPlaces',
      to: '/mina-projektplatser',
      icon: 'hammer',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.myFiles',
      to: '/mina-filer',
      icon: 'myFiles',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.helpRequests',

      to: '/forfragningar',
      icon: 'question',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.bookings',

      to: '/bokningar',
      icon: 'calendar',
      isDisabled: hasCurrentOrg ? false : true,
    },

    {
      value: 'portal.sidebar.provider.myContacts',
      to: '/kontakter',
      icon: 'book',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.writeArticle',
      to: '/skriv-artikel',
      icon: 'guidance',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.companyProfile',
      to: '/foretagsprofil',
      icon: 'building',
      isDisabled: hasCurrentOrg ? false : true,
    },
    {
      value: 'portal.sidebar.provider.myUserProfile',
      to: '/anvandarprofil',
      icon: 'findExpert',
    },
  ]
}

export const getLink = (
  pathname: string,
  area: 'property' | 'project' | 'propertyNote' | 'projectNote',
  areaId: string,
) => {
  let isAdminPages = false
  if (pathname.includes(PATH_ADMIN_PAGES)) {
    isAdminPages = true
  }

  let linkPath
  switch (area) {
    case 'property':
      linkPath = isAdminPages
        ? `${PATH_ADMIN_PROPERTIES}?fastighet=${areaId}`
        : `${PATH_MY_PROPERTIES}/${areaId}`
      break
    case 'propertyNote':
      linkPath = `${PATH_PROPERTY_NOTE}/${areaId}`
      break
    case 'project':
      linkPath = isAdminPages
        ? `${PATH_ADMIN_PROJECTS}?projekt=${areaId}`
        : `${PATH_MY_PROJECTS}/${areaId}`
      break
    case 'projectNote':
      linkPath = `${PATH_PROJECT_NOTE}/${areaId}`

      break
    default:
      linkPath = ''
  }
  return linkPath
}

export const menuItemListNewCompany = {
  value: 'portal.sidebar.footer.addProviderAccount',
  to: 'registrera-foretag/',
  icon: 'hammer',
  isDisabled: false,
}

type FooterLink = {
  value: string
  to: string
  disabled?: boolean
  external?: boolean
}
type FooterLinkList = {
  header: string
  items: FooterLink[]
}
export const footerPublic: FooterLinkList[] = [
  {
    header: 'Produkt',
    items: [
      { value: 'Bostadsägare', to: '/' },
      { value: 'Företagare', to: '/foretagare' },
    ],
  },
  {
    header: 'Om Berikar',
    items: [{ value: 'Om oss', to: '/om-oss' }],
  },
  {
    header: 'Villkor',
    items: [
      { value: 'Användarvillkor', to: '/anvandarvillkor' },
      { value: 'Cookiepolicy', to: '/cookiepolicy' },
      { value: 'Integritetspolicy', to: '/integritetspolicy' },
    ],
  },
  {
    header: 'Kontakt & Support',
    items: [
      { value: 'hej@berikar.se', to: 'mailto:hej@berikar.se', external: true },
    ],
  },
]
