import {
  Button,
  Flex,
  ResponsiveValue,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { AccountTypeName } from '@contracts/misc'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Logo } from '../ui/Logo'
import Heading from '../ui/Heading'
import RegisterAccountForm from './RegisterAccountForm'

const RegisterAccount: React.FC<{
  toggleForm?: () => void
  m?: number
  currentAccountType: AccountTypeName
  setCurrentAccountType: Dispatch<SetStateAction<AccountTypeName>>
  currentUserEmail: string
  setCurrentUserEmail: Dispatch<SetStateAction<string>>
  // defaultEmail?: string
  forwardMessage?: string
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  mt?: ResponsiveValue<number | string>
}> = ({
  toggleForm,
  currentAccountType,
  setCurrentAccountType,
  currentUserEmail,
  setCurrentUserEmail,
  // defaultEmail,
  forwardMessage,
  px = { xxs: 1, tablet: 2 },
  mt = '5%',
  py,
}) => {
  const { t } = useTranslation()
  const [accountCreated, setAccountCreated] = useState(false)

  let defaultTabIndex = 0

  if (currentAccountType === 'Provider') {
    defaultTabIndex = 1
  }

  return (
    <Flex
      flexDir="column"
      w="100%"
      maxW="md"
      px={px}
      py={py}
      mt={mt}
      borderRadius="lg"
      alignItems="center"
      mb={40}
      // border="1px solid green"
    >
      <>
        <Logo name="BerikarWithName" mb={8} h={8} />
        {forwardMessage && <Text mb={6}>{forwardMessage}</Text>}
        {currentAccountType === 'Consumer' && (
          <Flex flexDir="column" textAlign="center" gap={3} pt={4} pb={8}>
            <Heading textStyle="h8">Påbörja resan mot ditt drömhem!</Heading>
            <Text textStyle="textMediumLight">
              För att kunna använda Berikar behöver du skapa ett konto. Det tar
              bara några sekunder, är helt kostnadsfritt och ger dig omedelbar
              tillgång till alla våra funktioner.
            </Text>
          </Flex>
        )}
        <Tabs
          isLazy
          defaultIndex={defaultTabIndex}
          onChange={e => {
            setCurrentAccountType(e === 0 ? 'Consumer' : 'Provider')
          }}
        >
          <TabList>
            <Tab
              _selected={{ borderColor: 'primary', color: 'primary' }}
              color="gray.400"
              w="50%"
            >
              <Text textStyle="h4">{t('general.privateUser')}</Text>
            </Tab>
            <Tab
              _selected={{ borderColor: 'primary', color: 'primary' }}
              color="gray.400"
              w="50%"
            >
              <Text textStyle="h4">{t('general.companyUser')}</Text>
            </Tab>
          </TabList>

          {/* <TabPanels borderColor="red"> */}
          <TabPanels borderColor="red" borderTop="1px solid lightgray">
            <TabPanel p={0} mt={4}>
              <RegisterAccountForm
                accountType="Consumer"
                accountCreated={accountCreated}
                setAccountCreated={setAccountCreated}
                // defaultEmail={defaultEmail}
                subscription={forwardMessage}
                currentUserEmail={currentUserEmail}
                setCurrentUserEmail={setCurrentUserEmail}
              />
            </TabPanel>
            <TabPanel p={0} mt={4}>
              <>
                {!accountCreated && (
                  <>
                    <Text color="gray.600">
                      {t('auth.registerProviderInfo1')}
                    </Text>
                    <Text color="gray.600" my={4}>
                      {t('auth.registerProviderInfo2')}
                    </Text>
                  </>
                )}

                <RegisterAccountForm
                  accountType="Provider"
                  accountCreated={accountCreated}
                  setAccountCreated={setAccountCreated}
                  // defaultEmail={defaultEmail}
                  subscription={forwardMessage}
                  currentUserEmail={currentUserEmail}
                  setCurrentUserEmail={setCurrentUserEmail}
                />
              </>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <> */}
        {!accountCreated && (
          <Flex
            textAlign="center"
            textStyle=""
            mt={4}
            justifyContent="center"
            gap={1}
          >
            <Text>{t('auth.doYouHaveAnAccount')}</Text>
            <Text
              color="logo_purple"
              onClick={() => {
                toggleForm ? toggleForm() : ''
              }}
              _hover={{ cursor: 'pointer', color: 'darkgray' }}
            >
              {t('auth.login')}
            </Text>
          </Flex>
        )}
        {accountCreated && (
          <Button
            margin="1rem 0"
            variant="secondary"
            onClick={toggleForm}
            w="100%"
          >
            {t('auth.login')}
          </Button>
        )}
      </>
    </Flex>
  )
}
export default RegisterAccount
