import { Box, Image, Text } from '@chakra-ui/react'
import { Author } from '@contracts/support'

const AuthorProfile: React.FC<{ author: Author }> = ({ author }) => {
  return (
    <Box display="flex" flexDir="column" textStyle={'textThin'}>
      {author.profileImg && (
        <Image
          src={author.profileImg}
          boxSize={20}
          objectFit="cover"
          mb={4}
          borderRadius="full"
          alignSelf="center"
        />
      )}
      <Text mb={4}>{author.name}</Text>
      <Text>{author.title}</Text>
      <Text>{author.company}</Text>
    </Box>
  )
}
export default AuthorProfile
