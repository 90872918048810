import { ProjectPlacePhase, ProjectPlaceStatus } from '@contracts/projects'

const ASC = 'asc'
const DESC = 'desc'

type SortOrder = typeof ASC | typeof DESC

export type AccountSortingOptionType = [string, string, SortOrder]
export const AccountSortingOption: Record<string, AccountSortingOptionType> = {
  FIRST_NAME_DESC: ['First name (desc)', 'firstName', DESC],
  FIRST_NAME_ASC: ['First name (asc)', 'firstName', ASC],
  LAST_NAME_DESC: ['Last name (desc)', 'lastName', DESC],
  LAST_NAME_ASC: ['Last name (asc)', 'lastName', ASC],
  LAST_LOGIN_DESC: ['Last login (desc)', 'lastLoginAt', DESC],
  LAST_LOGIN_ASC: ['Last login (asc)', 'lastLoginAt', ASC],
  EMAIL_DESC: ['Email (desc)', 'email', DESC],
  EMAIL_ASC: ['Email (asc)', 'email', ASC],
  PHONE_DESC: ['Phone number (desc)', 'phoneNumber', DESC],
  PHONE_ASC: ['Phone number (asc)', 'phoneNumber', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type OrganizationSortingOptionType = [string, string, SortOrder]
export const OrganizationSortingOption: Record<
  string,
  OrganizationSortingOptionType
> = {
  NAME_DESC: ['Name (desc)', 'name', DESC],
  NAME_ASC: ['Name (asc)', 'name', ASC],
  REGISTRATION_NUMBER_DESC: [
    'Registration number (desc)',
    'registrationNumber',
    DESC,
  ],
  REGISTRATION_NUMBER_ASC: [
    'Registration number (asc)',
    'registrationNumber',
    ASC,
  ],
  EMAIL_DESC: ['Email (desc)', 'email', DESC],
  EMAIL_ASC: ['Email (asc)', 'email', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type PropertySortingOptionType = [string, string, SortOrder]
export const PropertySortingOption: Record<string, PropertySortingOptionType> =
  {
    NAME_DESC: ['Name (desc)', 'name', DESC],
    NAME_ASC: ['Name (asc)', 'name', ASC],
    CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
    CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
    UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
    UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
  }
export type ProjectPlaceSortingOptionType = [string, string, SortOrder]
export const ProjectPlaceSortingOption: Record<
  string,
  ProjectPlaceSortingOptionType
> = {
  NAME_DESC: ['Name (desc)', 'name', DESC],
  NAME_ASC: ['Name (asc)', 'name', ASC],
  CUSTOMER_NAME_DESC: ['Customer name (desc)', 'customerName', DESC],
  CUSTOMER_NAME_ASC: ['Customer name (asc)', 'customerName', ASC],
  STATUS_DESC: ['Status (desc)', 'status', DESC],
  STATUS_ASC: ['Status (asc)', 'status', ASC],
  PHASE_DESC: ['Phase (desc)', 'phase', DESC],
  PHASE_ASC: ['Phase (asc)', 'phase', ASC],
  STREET_DESC: ['Street (desc)', 'street', DESC],
  STREET_ASC: ['Street (asc)', 'street', ASC],
  BEGIN_AT_DESC: ['Begin at (desc)', 'beginAt', DESC],
  BEGIN_AT_ASC: ['Begin at (asc)', 'beginAt', ASC],
  END_AT_DESC: ['End at (desc)', 'endAt', DESC],
  END_AT_ASC: ['End at (asc)', 'endAt', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type HelpRequestSortingOptionType = [string, string, SortOrder]
export const HelpRequestSortingOption: Record<
  string,
  ProjectPlaceSortingOptionType
> = {
  NAME_DESC: ['Name (desc)', 'name', DESC],
  NAME_ASC: ['Name (asc)', 'name', ASC],
  // CUSTOMER_NAME_DESC: ['Customer name (desc)', 'customerName', DESC],
  // CUSTOMER_NAME_ASC: ['Customer name (asc)', 'customerName', ASC],
  CONSUMER_STATUS_DESC: ['Status (desc)', 'consumerStatus', DESC],
  CONSUMER_STATUS_ASC: ['Status (asc)', 'consumerStatus', ASC],
  PROVIDER_STATUS_DESC: ['Status (desc)', 'providerStatus', DESC],
  PROVIDER_STATUS_ASC: ['Status (asc)', 'providerStatus', ASC],
  // PHASE_DESC: ['Phase (desc)', 'phase', DESC],
  // PHASE_ASC: ['Phase (asc)', 'phase', ASC],
  // STREET_DESC: ['Street (desc)', 'street', DESC],
  // STREET_ASC: ['Street (asc)', 'street', ASC],
  // BEGIN_AT_DESC: ['Begin at (desc)', 'beginAt', DESC],
  // BEGIN_AT_ASC: ['Begin at (asc)', 'beginAt', ASC],
  // END_AT_DESC: ['End at (desc)', 'endAt', DESC],
  // END_AT_ASC: ['End at (asc)', 'endAt', ASC],
  CREATED_AT_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_AT_ASC: ['Created date (asc)', 'createdAt', ASC],
  // CREATED_BY_DESC: ['Created date (desc)', 'createdBy', DESC],
  // CREATED_BY_ASC: ['Created date (asc)', 'createdBy', ASC],
  // UPDATED_AT_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  // UPDATED_AT_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type ProjectSortingOptionType = [string, string, SortOrder]
export const ProjectSortingOption: Record<string, ProjectSortingOptionType> = {
  NAME_DESC: ['Name (desc)', 'name', DESC],
  NAME_ASC: ['Name (asc)', 'name', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type ArticleSortingOptionType = [string, string, SortOrder]
export const ArticleSortingOption: Record<string, ArticleSortingOptionType> = {
  ID_DESC: ['ID (desc)', 'ID', DESC],
  ID_ASC: ['ID (asc)', 'ID', ASC],
  HEADING_DESC: ['Heading (desc)', 'heading', DESC],
  HEADING_ASC: ['Heading (asc)', 'heading', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type AttachmentSortingOptionType = [string, string, SortOrder]
export const AttachmentSortingOption: Record<
  string,
  AttachmentSortingOptionType
> = {
  AREA_DESC: ['Area (desc)', 'area', DESC],
  AREA_ASC: ['Area (asc)', 'area', ASC],
  FILE_NAME_DESC: ['File name (desc)', 'originalFilename', DESC],
  FILE_NAME_ASC: ['File name (asc)', 'originalFilename', ASC],
  FILE_SIZE_DESC: ['File size (desc)', 'fileSizeBytes', DESC],
  FILE_SIZE_ASC: ['File size (asc)', 'fileSizeBytes', ASC],
  MIME_TYPE_DESC: ['Mime type (desc)', 'mimeType', DESC],
  MIME_TYPE_ASC: ['Mime type (asc)', 'mimeType', ASC],
  CREATED_DATE_DESC: ['Created date (desc)', 'createdAt', DESC],
  CREATED_DATE_ASC: ['Created date (asc)', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['Last updated (desc)', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['Last updated (asc)', 'updatedAt', ASC],
}
export type TagSortingOptionType = [string, string, SortOrder]
export const TagSortingOption: Record<string, TagSortingOptionType> = {
  ID_DESC: ['', 'ID', DESC],
  ID_ASC: ['', 'ID', ASC],
  NAME_DESC: ['', 'name', DESC],
  NAME_ASC: ['', 'name', ASC],
  CODE_DESC: ['', 'code', DESC],
  CODE_ASC: ['', 'code', ASC],
  CREATED_DATE_DESC: ['', 'createdAt', DESC],
  CREATED_DATE_ASC: ['', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['', 'updatedAt', ASC],
}
export type NoteSortingOptionType = [string, string, SortOrder]
export const NoteSortingOption: Record<string, NoteSortingOptionType> = {
  NAME_DESC: ['', 'name', DESC],
  NAME_ASC: ['', 'name', ASC],
  TIMESTAMP_DESC: ['', 'noteTimestamp', DESC],
  TIMESTAMP_ASC: ['', 'noteTimestamp', ASC],
  CREATED_DATE_DESC: ['', 'createdAt', DESC],
  CREATED_DATE_ASC: ['', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['', 'updatedAt', ASC],
}

export type ConstructionGlossarySortingOptionType = [string, string, SortOrder]
export const ConstructionGlossarySortingOption: Record<
  string,
  ConstructionGlossarySortingOptionType
> = {
  ID_DESC: ['', 'ID', DESC],
  ID_ASC: ['', 'ID', ASC],
  NAME_DESC: ['', 'name', DESC],
  NAME_ASC: ['', 'name', ASC],
  CREATED_DATE_DESC: ['', 'createdAt', DESC],
  CREATED_DATE_ASC: ['', 'createdAt', ASC],
  UPDATED_DATE_DESC: ['', 'updatedAt', DESC],
  UPDATED_DATE_ASC: ['', 'updatedAt', ASC],
}

export type CollaboratorSortingOptionType = [string, string, SortOrder]
export const CollaboratorSortingOption: Record<
  string,
  CollaboratorSortingOptionType
> = {
  EMAIL_DESC: ['', 'accountEmail', DESC],
  EMAIL_ASC: ['', 'accountEmail', ASC],
  ACCESS_RIGHTS_DESC: ['', 'accountRelation', DESC],
  ACCESS_RIGHTS_ASC: ['', 'accountRelation', ASC],
  MEMBER_SINCE_DESC: ['', 'createdAt', DESC],
  MEMBER_SINCE_ASC: ['', 'createdAt', ASC],
}

export type InvitesSortingOptionType = [string, string, SortOrder]
export const InvitesSortingOption: Record<string, InvitesSortingOptionType> = {
  EMAIL_DESC: ['', 'email', DESC],
  EMAIL_ASC: ['', 'email', ASC],
  ACCESS_RIGHTS_DESC: ['', 'relation', DESC],
  ACCESS_RIGHTS_ASC: ['', 'relation', ASC],
  MEMBER_SINCE_DESC: ['', 'createdAt', DESC],
  MEMBER_SINCE_ASC: ['', 'createdAt', ASC],
}

export const projectPlaceStatuses: ProjectPlaceStatus[] = [
  'Draft',
  'Ongoing',
  'Cancelled',
  'Done',
]
export const projectPlacePhases: ProjectPlacePhase[] = [
  'Offer',
  'Design',
  'Production',
  'Warranty',
]
