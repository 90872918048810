import { Flex, ResponsiveValue, Show, Text } from '@chakra-ui/react'
import { ProjectResponse } from '@contracts/projects'
import { FaArchive } from 'react-icons/fa'
import { Icon } from '../ui/Icon'

const ProjectNameTag: React.FC<{
  project: ProjectResponse
  propertyName: string | undefined
  mt?: string | number
  mb?: string | number
  ml?: ResponsiveValue<string | number>
  px?: ResponsiveValue<string | number>
}> = ({ project, propertyName, mt, mb, px, ml }) => {
  const projectCategoriesAsFriendlyString = project.projectCategories
    ?.map(pc => {
      return pc.name
    })
    .join(', ')

  return (
    <Flex flexDir="column" mt={mt} px={px} mb={mb} gap={1} ml={ml}>
      <Flex alignItems="center" gap={3}>
        <Text textStyle="h4" maxW="sm">
          {project.name}
        </Text>
        {project?.status === 'Archived' && (
          <FaArchive color="#B86890" size={18} />
        )}
        {/* TODO - Status Deleted temporarily removed */}
        {/* {project?.status === 'Deleted' && (
          <FaRegTrashAlt color="#B86890" size={18} />
        )} */}
        {project?.status === 'Active' && <Icon name="activeProject" />}
      </Flex>

      <Show above="laptop">
        <Flex
          flexDir="row"
          wrap="wrap"
          zIndex="base"
          textStyle="lgLight"
          alignItems="baseline"
          flexWrap="wrap"
          maxH={12}
          borderBottomRadius="lg"
          pb={1}
          // border="1px solid red"
          maxW={{ laptop: 80, desktop: 'xl' }}
        >
          <Text>
            {projectCategoriesAsFriendlyString} - {propertyName}
          </Text>
        </Flex>
      </Show>
    </Flex>
  )
}
export default ProjectNameTag
