import { HStack, Text } from '@chakra-ui/react'
import { Attachment } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { deleteAttachment } from '../../apiClients/attachmentsApiClient'
import IconButton from '../ui/IconButton'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ManageAttachmentPanel: React.FC<{
  attachment: Attachment
  onMutate: () => void
}> = ({ attachment, onMutate }) => {
  const { t } = useTranslation()

  if (attachment) {
    return (
      <HStack justifyContent="right" mr={8}>
        <IconButton
          data-cy="downloadButton"
          buttonType="DOWNLOAD"
          m={1}
          onClick={() => {
            window.location.href = `${baseUrl}${attachment.id}`
          }}
          tooltipLabel={t('attachments.downloadTooltip')}
        />
        <IconButton
          data-cy="deleteButton"
          buttonType="DELETE"
          m={1}
          onClick={() => {
            if (
              window.confirm(
                t('attachments.deleteAttachmentQuestion', {
                  fileId: attachment.id,
                  fileName: attachment.originalFilename,
                }),
              )
            ) {
              void deleteAttachment(attachment.id).then(() => {
                void onMutate()
              })
            }
          }}
          tooltipLabel={t('attachments.deleteTooltip')}
        />
      </HStack>
    )
  }
  return <Text>{t('attachments.noAttachmentAvailable')}</Text>
}
export default ManageAttachmentPanel
