import {
  ChatIcon,
  CheckIcon,
  WarningIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons'
import { Button, Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const Alert: React.FC<{
  variant: 'Success' | 'Error' | 'Warning' | 'Message'
  title: string
  message: string
  forwardUri: string
  borderRadius?: string
  w?: ResponsiveValue<string | number>
  mt?: ResponsiveValue<string | number>
  mr?: ResponsiveValue<string | number>
  mb?: ResponsiveValue<string | number>
  ml?: ResponsiveValue<string | number>
  mx?: ResponsiveValue<string | number>
  px?: ResponsiveValue<string | number>
  py?: ResponsiveValue<string | number>
}> = ({
  variant,
  title,
  message,
  forwardUri,
  borderRadius,
  w, // = '100%',
  mt = 4,
  mr = 4,
  mb = 4,
  ml = 4,
  px = 4,
  py = 4,
  mx = '5%',
}) => {
  const navigate = useNavigate()
  const fontSize = 60
  const iconMargin = 6
  const getIcon = () => {
    switch (variant) {
      case 'Success':
        return <CheckIcon color="green" fontSize={fontSize} m={iconMargin} />
    }
    switch (variant) {
      case 'Warning':
        return (
          <WarningTwoIcon color="orange" fontSize={fontSize} m={iconMargin} />
        )
    }
    switch (variant) {
      case 'Error':
        return <WarningIcon color="red" fontSize={fontSize} m={iconMargin} />
    }
    switch (variant) {
      case 'Message':
        return <ChatIcon color="green" fontSize={fontSize} m={iconMargin} />
    }
  }

  return (
    <Flex
      pos="relative"
      flexDir="column"
      bg="white"
      boxShadow="lg"
      borderEndRadius="lg"
      borderRadius={borderRadius}
      px={px}
      py={py}
      mx={mx}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      alignItems="center"
      justifyContent="right"
      w={w}
    >
      <Flex flexDir="row" alignItems="center">
        {getIcon()}
        <Flex flexDir="column" textAlign="left" ml={4} mr={3} mt={4}>
          <Text textStyle="h4" mb={2}>
            {title}
          </Text>
          <Text>{message}</Text>
        </Flex>
      </Flex>
      <Flex flexDir="row" w="100%" justifyContent="right">
        <Button
          variant="ghost"
          color="gray.400"
          onClick={() => {
            navigate(forwardUri)
          }}
          size="sm"
        >
          Fortsätt
        </Button>
      </Flex>
    </Flex>
  )
}
export default Alert
