import { Box, Flex, Image } from '@chakra-ui/react'
import { ProjectPhase } from '@contracts/projects'
import React, { useState } from 'react'

import {
  PhaseCardDesign,
  PhaseCardGuarantee,
  PhaseCardIdeaAndPlanning,
  PhaseCardProduction,
} from '../../constructionProcess/ProcessPhaseCards'

import LinkArea from '../../ui/LinkArea'

import constructionProcessOverview from './../../../img/pages/constructionProcessMain_m.svg'

const ConstructionLifeCycleSection: React.FC<{
  currentPhase: ProjectPhase
}> = ({ currentPhase }) => {
  // const [activePhase, setActivePhase] = useState(1)
  const [activePhase, setActivePhase] = useState(currentPhase || 'Idea')

  const overlayWidth = '38%'
  const overlayHeight = '45%'
  const overlayTop = -3
  const overlayBottom = -4

  return (
    <Flex flexDir="column" w="100%" justifyContent="left" wrap="wrap" mb={32}>
      <Flex
        flexDir="row"
        pos="relative"
        maxW="container.lg"
        mt={12}
        ml="2%"
        mb={20}
      >
        <Image src={constructionProcessOverview} fit="contain" />
        <LinkArea
          top={overlayTop}
          left={-2}
          w={overlayWidth}
          h={overlayHeight}
          onClick={() => {
            setActivePhase('Idea')
          }}
          isActive={activePhase === 'Idea' ? true : false}
        />
        <LinkArea
          top={overlayTop}
          left="40%"
          w={overlayWidth}
          h={overlayHeight}
          onClick={() => {
            setActivePhase('Production')
          }}
          isActive={activePhase === 'Production' ? true : false}
        />

        <LinkArea
          bottom={overlayBottom}
          left="19.5%"
          w={overlayWidth}
          h={overlayHeight}
          onClick={() => {
            setActivePhase('Design')
          }}
          isActive={activePhase === 'Design' ? true : false}
        />
        <LinkArea
          bottom={overlayBottom}
          left="60.5%"
          w={overlayWidth}
          h={overlayHeight}
          onClick={() => {
            setActivePhase('Warranty')
          }}
          isActive={activePhase === 'Warranty' ? true : false}
        />
      </Flex>
      <Box mx="5%">
        {activePhase === 'Idea' && <PhaseCardIdeaAndPlanning />}
        {activePhase === 'Design' && <PhaseCardDesign />}
        {activePhase === 'Production' && <PhaseCardProduction />}
        {activePhase === 'Warranty' && <PhaseCardGuarantee />}
      </Box>
    </Flex>
  )
}
export default ConstructionLifeCycleSection
