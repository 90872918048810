import { Box, Flex, Text, Image } from '@chakra-ui/react'
import _ from 'lodash'
import { Note } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import StarIcon from '../ui/StarIcon'
import { getDateStrFromNumber } from '../../common/util'

import { Icon } from '../ui/Icon'
import log from '../../common/log'

import { useArea } from '../../common/area-context'
import Heading from '../ui/Heading'
import { useNoteParts } from './notesHelper'
import { getImageForNote } from './getImageForNote'
import NoteCardMediumButton from './NoteCardMediumButton'
import NoteStatus from './NoteStatus'
import fallbackNoteCardCover from './../../img/notes/fallback_note-card-cover.png'

const NoteCardMicro: React.FC<{
  note: Note
  propertyId?: string
  projectId?: string
  mx?: string | number
  mr?: string | number
}> = ({ note, propertyId, projectId, mx, mr }) => {
  const { t } = useTranslation()
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const { mutate, toggleStar } = useNoteParts({
    propertyId,
    projectId,
  })
  const paddingX = 2
  const borderRadius = 8

  const toggleStarHandlerLocal = async () => {
    await toggleStar(note).then(() => {
      mutate ? void mutate() : ''
    })
  }
  const cardW = '160px'
  const headerW = '125px'
  const imageH = '100px'
  return (
    <NoteCardMediumButton
      note={note}
      propertyId={propertyId}
      projectId={projectId}
    >
      <Box
        background="white"
        width={cardW}
        borderRadius={borderRadius}
        boxShadow="lg"
        mx={mx}
        mr={mr}
        pt={1}
        position="relative"
        _hover={{
          boxShadow: 'dark-lg',
          transform: 'translate(-1px,-1px)',
          cursor: 'pointer',
        }}
        id={`note-card-compact-${note.id}`}
      >
        <Heading
          ml={paddingX}
          textStyle="h6Fixed"
          w={headerW}
          h={6}
          overflow="clip"
        >
          {_.truncate(note.name, { length: 17 })}
        </Heading>
        <Box position="absolute" top={1} right={1}>
          <StarIcon
            h={20}
            isStarred={note.starred}
            onClick={() => {
              void toggleStarHandlerLocal()
            }}
          />
        </Box>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          pr={2}
        >
          <Flex
            py={1}
            fontSize={12}
            px={paddingX}
            textStyle="textSmallFixed"
            overflowY="clip"
          >
            {note.noteTimestamp
              ? getDateStrFromNumber(note?.noteTimestamp)
              : ''}
          </Flex>
          {note.status && (
            <NoteStatus status={note.status} textStyle="textSmallFixed" />
          )}
        </Flex>
        {getImageForNote(note) && (
          <Image
            w={cardW}
            h={imageH}
            src={getImageForNote(note)}
            objectFit="cover"
            borderBottomRadius={8}
            fallbackSrc={fallbackNoteCardCover}
          />
        )}
        {!getImageForNote(note) && (
          <Flex
            flexDir="row"
            w={cardW}
            h={imageH}
            bg={
              noteArea.area === 'property'
                ? 'logo_yellow_light'
                : 'logo_pink_light'
            }
            px={2}
            borderBottomRadius="lg"
            justifyContent="space-between"
            alignItems="center"
            columnGap={2}
          >
            <Icon name="camera" h={24} />
            <Text textStyle="xsLight">{t('notes.noCoverAttachment')}</Text>
          </Flex>
        )}
        {note.attachmentsJson && note.attachmentsJson?.length > 0 && (
          <Box pos="absolute" bottom={1} right={1}>
            <Icon name="attachmentWithBackground" h={6} />
          </Box>
        )}
      </Box>
    </NoteCardMediumButton>
  )
}
export default NoteCardMicro
