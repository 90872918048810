import { ReactNode } from 'react'
import { Flex, HStack, ResponsiveValue, Text } from '@chakra-ui/react'

import { getIconFromName, IconName } from '../../common/icon-utils'
import { Heading } from '../../ui'

const Card: React.FC<{
  heading?: string
  headingTextStyle?: string
  contentTextStyle?: string
  children?: ReactNode
  maxW?: string | number | Record<string, string | number>
  minW?: string | number | Record<string, string | number>
  p?: string
  ml?: string | number
  mr?: string | number
  mb?: string | number
  mt?: string | number
  my?: ResponsiveValue<string | number>
  mx?: ResponsiveValue<string | number>
  w?: string | Record<string, string | number>
  iconName?: IconName
  bg?: string
  text?: string
  boxShadow?: string
  px?: string | number
  py?: string | number
}> = ({
  heading,
  headingTextStyle,
  contentTextStyle,
  iconName,
  children,
  bg = 'primary_grey01',
  text,

  boxShadow = 'lg',
  mt,
  mr,
  mb,
  ml,
  my,
  mx,
  px,
  py,
  maxW,
  minW,
}) => {
  const icon = iconName ? getIconFromName({ iconName, h: 20 }) : undefined

  return (
    <Flex
      pos="relative"
      flexDir="column"
      bg={bg}
      borderRadius="lg"
      // py={6}
      // px={6}
      boxShadow={boxShadow}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      my={my}
      mx={mx}
      maxW={maxW}
      minW={minW}
      px={px}
      py={py}
    >
      <HStack
        display="flex"
        flexDir="row"
        fontSize={70}
        alignItems="center"
        spacing={5}
        pb={4}
        ml={6}
      >
        {icon}

        <Heading textStyle={headingTextStyle || 'h5'}>{heading}</Heading>
      </HStack>
      <Text px={5} textStyle={contentTextStyle || 'infoTextLight'}>
        {text}
      </Text>
      {children}
    </Flex>
  )
}
export default Card
