import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image as ChakraImage,
  ResponsiveValue,
  Show,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import {
  ProjectPlaceResponse,
  ProjectResponse,
  UpdateProjectRequest,
} from '@contracts/projects'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import fallBackProfileImg from '../../../img/projects/fallbackProject.png'

import { deleteAttachment } from '../../../apiClients/attachmentsApiClient'
import {
  updateProject,
  useProject,
} from '../../../apiClients/projectsApiClient'
import { useProjectPlacesByProject } from '../../../apiClients/projectsApiClient/projectPlaces'
import { usePropertyInfo } from '../../../apiClients/propertiesApiClient/properties'
import AuthContext from '../../../common/auth-context'
import log from '../../../common/log'
import {
  getHomePath,
  getProjectsPath,
  getPropertiesPath,
  PATH_MY_PRIVATE_PROJECTS,
  PATH_MY_PROVIDER_PAGES,
  PATH_MY_PROVIDER_PROJECTS,
  PATH_PROJECT_PLACE,
} from '../../../common/nav'
import { PROJECT_PROFILE_IMAGE_PREFIX } from '../../../pages/constants'
import { useImageUrl } from '../../attachments/attachmentHelper'
import ProjectNameTag from '../../projects/ProjectNameTag'
import ProjectPhaseStepper from '../../projects/ProjectPhaseStepper'
import IconButton from '../../ui/IconButton'
import ImgPickerButton from '../../ui/ImgPickerButton'
import MembersGroup from './MembersGroup'

const ProjectHeaderSection: React.FC<{
  project: ProjectResponse | undefined
  isInEditMode?: boolean
  px?: ResponsiveValue<number | string>
  mt?: number | string
}> = ({ project, isInEditMode = false, px, mt }) => {
  const { t } = useTranslation()
  const { isConsumer } = useContext(AuthContext)
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const [isLarger] = useMediaQuery('(max-width: 1000px)')
  const navigate = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()
  const { data: property } = usePropertyInfo(project?.propertyId)
  const { data: projectPlaces } = useProjectPlacesByProject(
    isConsumer ? undefined : project?.id,
  )
  const { currentOrganization } = useContext(AuthContext)

  let projectPlace: ProjectPlaceResponse | undefined
  if (projectPlaces) {
    projectPlace = projectPlaces.find(projPlace => {
      return projPlace.organizationId === currentOrganization?.id
    })
  }

  let propertyLink = ''
  if (property) {
    propertyLink = `${getPropertiesPath(pathname)}/${property?.id}`
  }

  const profileImg = useImageUrl(project?.imageAttachmentId)

  const oldImgId = project?.imageAttachmentId ?? ''

  const { mutate } = useProject(project?.id)

  if (!project) {
    return <Box width="100%">{t('project.noProjectFound')}</Box>
  }

  const updateProjectImgHandler = (
    inputProp: string | undefined,
    inputValue: string,
  ) => {
    const updateRequest: UpdateProjectRequest = {
      ...project,
      projectCategoryIds: project.projectCategories
        ? project.projectCategories.map(pc => {
            return pc.id
          })
        : [],
      imageAttachmentId: inputValue,
    }
    updateProject(project.id, updateRequest)
      .then(() => {
        // Delete blob only if private
        if (profileImg?.includes('/private/') && project?.imageAttachmentId) {
          log.debug('Deleting old project image: ', oldImgId)
          void deleteAttachment(oldImgId, true)
        }
        project.imageAttachmentId = inputValue
        void mutate()
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update Project img for project')
        toast({
          title: t('projects.updateImageFailureMessage', {
            name: project.name,
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  if (isTabletOrSmaller) {
    return (
      <>
        {/* Header background image */}
        <ChakraImage
          h={32}
          w="100vw"
          fit="cover"
          src={profileImg ? profileImg : fallBackProfileImg}
          backgroundPosition="center"
        />

        {/* Edit profile button */}
        {!isInEditMode && (
          <Flex pos="absolute" top={20} right={4} gap={3}>
            {!profileImg && (
              <ImgPickerButton
                pos="absolute"
                imgChangeHandler={updateProjectImgHandler}
                iconColor="gray.500"
                variant={'GHOST-ICON'}
                ContextArea="Project"
                attachmentPrefix={PROJECT_PROFILE_IMAGE_PREFIX}
              />
            )}
            {projectPlace && projectPlace.id && (
              <IconButton
                buttonType={'PROJECT-PLACE'}
                onClick={() => {
                  if (projectPlace) {
                    navigate(`${PATH_PROJECT_PLACE}/${projectPlace?.id}`)
                  }
                }}
              />
            )}
            <IconButton
              buttonType={'EDIT_LARGE'}
              onClick={() => {
                navigate(`edit`)
              }}
            />
          </Flex>
        )}
        {isInEditMode && (
          <Flex pos="absolute" top={20} right={4} gap={3}>
            <ImgPickerButton
              imgChangeHandler={updateProjectImgHandler}
              iconColor="gray.500"
              variant={'GHOST-ICON'}
              ContextArea="Project"
              attachmentPrefix={PROJECT_PROFILE_IMAGE_PREFIX}
            />

            <IconButton
              buttonType={'BACK_LARGE'}
              onClick={() => {
                navigate(`${getProjectsPath(pathname)}/${project.id}`)
              }}
            />
          </Flex>
        )}
        <Flex flexDir="column" px="2%">
          <ProjectPhaseStepper
            currentPhase={project.phase}
            pt={{ xxs: 1, laptop: 0 }}
            variant="show-3-phases"
            stepVariant="mobile"
          />
          <Breadcrumb pt={mt} px={px} mt={2} textStyle="breadCrumbs">
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  navigate(getHomePath(pathname))
                }}
              >
                {t('general.home')}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  navigate(propertyLink)
                }}
              >
                {property?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#" isCurrentPage>
                {project.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <ProjectNameTag
            project={project}
            propertyName={property?.name}
            mt={2}
            // ml="5%"
            px={px}
          />
        </Flex>
      </>
    )
  } else {
    // Screens larger than tablet
    return (
      <Box pos="relative">
        <Flex top={0} left={0} bottom={0}>
          <Flex bg="primary_grey02" h={28} w="100%" alignItems="flex-start">
            <Show above="desktop">
              <ProjectPhaseStepper
                currentPhase={project.phase}
                ml={{ xxs: 2, tablet: 4, laptop: 6, desktop: 14, xl: 48 }}
                mr="16%"
                pt={{ xxs: 4, desktop: 4, xl: 8 }}
                variant="show-all-phases"
              />
            </Show>
            <Show below="desktop">
              <ProjectPhaseStepper
                currentPhase={project.phase}
                ml={{ xxs: 2, tablet: 4, laptop: 6, desktop: 14, xl: 48 }}
                mr="16%"
                pt={{ xxs: 4, desktop: 4, xl: 8 }}
                variant="show-3-phases"
              />
            </Show>
          </Flex>

          {/* Background image */}
          {/* <ChakraImage
            h={{ xxs: '140px', laptop: '180px' }}
            w="100vw"
            fit="cover"
            src={
              backgroundImg && backgroundImg.length > 0
                ? `${baseUrlPublic}${backgroundImg[0].id}`
                : fallBackProfileImg
            }
            backgroundPosition="center"
          /> */}

          {/* Profile image */}
          <ChakraImage
            pos="absolute"
            src={profileImg ? profileImg : fallBackProfileImg}
            fit="cover"
            backgroundPosition="center"
            // boxSize={{ xxs: 24, tablet: 32, laptop: 40 }}
            boxSize={{ xxs: 24, tablet: 32, xl: 40 }}
            borderRadius="full"
            border="5px solid"
            borderColor="primary_grey02"
            // mt={{ xxs: 28, tablet: 24, laptop: 32 }}
            mt={{ xxs: 28, tablet: 24, laptop: 20, xl: 16 }}
            // ml={{ xxs: 2, tablet: 4, laptop: 12 }}
            ml={{ xxs: 2, tablet: 8, laptop: 12 }}
            zIndex="base"
          />
        </Flex>

        {!isInEditMode && (
          <Flex pos="absolute" top={32} right={4} gap={3}>
            {!isLarger && (
              <MembersGroup
                projectId={project.id}
                variant="avatars-compact"
                pt={0}
                max={5}
              />
            )}
            {projectPlace && projectPlace.id && (
              <IconButton
                buttonType={'PROJECT-PLACE'}
                onClick={() => {
                  if (projectPlace) {
                    navigate(`${PATH_PROJECT_PLACE}/${projectPlace?.id}`)
                  }
                }}
              />
            )}
            <IconButton
              buttonType={'EDIT_LARGE'}
              onClick={() => {
                navigate(`edit`)
              }}
            />
          </Flex>
        )}

        {(isInEditMode || !profileImg) && (
          <Box
            pos="absolute"
            top={{ tablet: '170px', xl: '170px' }}
            left={{ tablet: '145px', xl: '175px' }}
          >
            <ImgPickerButton
              imgChangeHandler={updateProjectImgHandler}
              iconColor="gray.500"
              variant={'ICON'}
              ContextArea="Project"
              attachmentPrefix={PROJECT_PROFILE_IMAGE_PREFIX}
            />
          </Box>
        )}
        {isInEditMode && (
          <Flex pos="absolute" top={32} right={4} gap={3}>
            {!isLarger && (
              <MembersGroup
                projectId={project.id}
                variant="avatars-compact"
                pt={0}
                max={5}
              />
            )}
            <IconButton
              buttonType={'BACK_LARGE'}
              onClick={() => {
                if (pathname.startsWith(PATH_MY_PROVIDER_PAGES)) {
                  navigate(`${PATH_MY_PROVIDER_PROJECTS}/${project.id}`)
                } else {
                  navigate(`${PATH_MY_PRIVATE_PROJECTS}/${project.id}`)
                }
              }}
            />
          </Flex>
        )}
        <ProjectNameTag
          project={project}
          propertyName={property?.name}
          ml={{ xxs: 56, tablet: 48, xl: 56 }}
          mt={3}
          mb={4}
        />
      </Box>
    )
  }
}
export default ProjectHeaderSection
