/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import {
  useEditor,
  EditorContent,
  Extensions,
  ReactRenderer,
} from '@tiptap/react'
import Mention from '@tiptap/extension-mention'

import { useTranslation } from 'react-i18next'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Underline from '@tiptap/extension-underline'
import Superscript from '@tiptap/extension-superscript'
import {
  Box,
  Flex,
  ResponsiveValue,
  Text as TextChakra,
} from '@chakra-ui/react'
import Placeholder from '@tiptap/extension-placeholder'

import { ContextArea } from '@contracts/support'
import { BaseCustomMediaNode } from './CustomImageNode'
import ImageBubbleMenu from './ImageBubbleMenu'
import ToolbarBasic from './ToolbarBasic'
import ToolbarExtended from './ToolbarExtended'
import { MentionList } from './MentionList'
import ToolbarCompact from './ToolbarCompact'

type TiptapProps = {
  editorLabel?: string
  limit?: number
  onChange?: (str: string) => void
  background?: string
  border?: string
  borderColor?: string
  content?: string
  w?: number | string
  editable?: boolean
  placeholder?: string
  withTypographyExtension?: boolean
  withLinkExtension?: boolean
  withCodeBlockLowlightExtension?: boolean
  withTaskListExtension?: boolean
  withPlaceholderExtension?: boolean
  withMentionSuggestion?: boolean
  withEmojiSuggestion?: boolean
  withEmojisReplacer?: boolean
  withHexColorsDecorator?: boolean
  withWordCount?: boolean
  h?: ResponsiveValue<number | string>
  pr?: number | string
  mt?: number | string
  mb?: number | string
  maxH?: number | string
  maxW?: number | string
  toolbarVariant?:
    | 'none'
    | 'compact'
    | 'basic'
    | 'extended'
    | 'compact-no-images'
    | 'basic-no-images'
  onClearContent?: boolean
  area: ContextArea
  areaId?: string
  parentArea?: ContextArea
  parentAreaId?: string
  grandParentArea?: ContextArea
  grandParentAreaId?: string
  container: 'private' | 'public'
  onImgAdded: (imgId: string) => void
}

const Tiptap: React.FC<TiptapProps> = ({
  limit,
  onChange,
  background = 'white',
  border,
  borderColor = 'black',
  content = '',
  w = 'container.lg',
  editable = true,
  withTypographyExtension = false,
  withLinkExtension = true,
  withTaskListExtension = true,
  h = { xxs: 28, desktop: 28 },
  maxH,
  maxW,
  placeholder,
  withWordCount = false,
  toolbarVariant = 'basic',
  pr,
  mt,
  mb,
  onClearContent = false,
  withMentionSuggestion = false,
  area,
  areaId,
  parentArea,
  parentAreaId,
  grandParentArea,
  grandParentAreaId,
  container = 'private',
  onImgAdded,
}: // onImagesAdded
TiptapProps) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extensions: Extensions | any = [
    StarterKit.configure({
      dropcursor: {
        color: '#eb2f96',
        width: 2,
        class: 'drop-cursor',
      },
    }),
    // Link.configure({
    //   openOnClick: false,
    // }),
    Underline,

    Mention.configure({
      HTMLAttributes: { class: 'mentionNode' },
      suggestion: {
        render: () => {
          let reactRenderer: ReactRenderer

          return {
            onStart: props => {
              reactRenderer = new ReactRenderer(MentionList, {
                props,
                editor: props.editor,
              })
            },

            onUpdate(props) {
              reactRenderer?.updateProps(props)
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                reactRenderer?.destroy()
                return true
              }

              return (reactRenderer?.ref as any)?.onKeyDown(props)
            },

            onExit() {
              reactRenderer.destroy()
            },
            // </>
            // )}
          }
        },
      },
    }),
    // Disable these for now, don't remove
    // Document,
    // Paragraph,
    // Text,
    CharacterCount.configure({
      limit,
    }),
    Placeholder.configure({
      placeholder: placeholder
        ? placeholder
        : t('richtext.placeholderPlaceholder'),
      // showOnlyCurrent: false,
    }),
    Superscript.configure({
      HTMLAttributes: {
        class: 'my-custom-class',
      },
    }),
    BaseCustomMediaNode,
  ]
  if (withTypographyExtension) {
    extensions.push(Typography)
  }
  if (withLinkExtension) {
    extensions.push(
      Link.configure({
        linkOnPaste: false,
        openOnClick: false,
      }),
    )
  }

  if (withTaskListExtension) {
    extensions.push(TaskList, TaskItem)
  }

  useEffect(() => {
    if (onClearContent) {
      editor?.chain().clearContent().run()
    }
  }, [onClearContent])

  const editor = useEditor({
    content,
    extensions,
    editable,

    onUpdate: ({ editor }) => {
      if (onChange) {
        onChange(editor.getHTML())
      }
    },
  })

  const onSetFocus = () => {
    editor?.chain().focus()
  }

  if (!editor) {
    return null
  }

  return (
    <>
      <Flex
        flexDir="column"
        width={w}
        pr={pr}
        maxW={maxW}
        mt={mt}
        mb={mb}
        onClick={() => {
          onSetFocus()
        }}
        // border="1px solid red"
        zIndex={8}
      >
        <Flex
          flexDir="row"
          justifyContent="space-between"
          textStyle="infoTextLight"
        >
          {withWordCount && (
            <Box>
              {limit && (
                <TextChakra>
                  {t('richtext.wordAndCharCountWithLimit', {
                    noOfChars: editor.storage.characterCount.characters(),
                    limit: limit,
                    noOfWords: editor.storage.characterCount.words(),
                  })}
                </TextChakra>
              )}
              {!limit && (
                <TextChakra>
                  {t('richtext.wordAndCharCount', {
                    noOfChars: editor.storage.characterCount.characters(),
                    noOfWords: editor.storage.characterCount.words(),
                  })}
                </TextChakra>
              )}
            </Box>
          )}
        </Flex>
        <Box
          minHeight={withMentionSuggestion ? 20 : 24}
          // minHeight={withMentionSuggestion ? 44 : 44}
          borderRadius={8}
          background={background}
          border={border}
          borderColor={borderColor}
        >
          <Box>
            {toolbarVariant === 'compact' && (
              <ToolbarCompact
                editor={editor}
                area={area}
                areaId={areaId}
                parentArea={parentArea}
                parentAreaId={parentAreaId}
                grandParentArea={grandParentArea}
                grandParentAreaId={grandParentAreaId}
                container={container}
                onImgAdded={onImgAdded}
              />
            )}
            {toolbarVariant === 'compact-no-images' && (
              <ToolbarCompact
                editor={editor}
                area={area}
                areaId={areaId}
                parentArea={parentArea}
                parentAreaId={parentAreaId}
                grandParentArea={grandParentArea}
                grandParentAreaId={grandParentAreaId}
                container={container}
                onImgAdded={onImgAdded}
                disableImages={true}
              />
            )}
            {toolbarVariant === 'basic' && (
              <ToolbarBasic
                editor={editor}
                area={area}
                areaId={areaId}
                parentArea={parentArea}
                parentAreaId={parentAreaId}
                grandParentArea={grandParentArea}
                grandParentAreaId={grandParentAreaId}
                container={container}
                onImgAdded={onImgAdded}
              />
            )}
            {toolbarVariant === 'basic-no-images' && (
              <ToolbarBasic
                editor={editor}
                area={area}
                areaId={areaId}
                parentArea={parentArea}
                parentAreaId={parentAreaId}
                grandParentArea={grandParentArea}
                grandParentAreaId={grandParentAreaId}
                container={container}
                onImgAdded={onImgAdded}
                disableImages={true}
              />
            )}
            {toolbarVariant === 'extended' && (
              <ToolbarExtended editor={editor} />
            )}
          </Box>
          <Box
            pt={{ xxs: 2, desktop: 4 }}
            pb={12}
            px={{ xxs: 2, tablet: 4 }}
            minH={h}
            overflow="scroll"
            maxH={maxH}
            // border="1px solid green"
            textStyle="textMedium"
          >
            <>
              {editable && <ImageBubbleMenu editor={editor} />}
              <EditorContent editor={editor} />
            </>
          </Box>
        </Box>
      </Flex>
      {withMentionSuggestion && (
        <Flex
          transform="translateY(-40px)"
          color="gray.400"
          fontSize="2xl"
          // mt={1}
          cursor="pointer"
          w={8}
          // h={8}
          // pt={0}
          // mt={0}
          ml={4}
          // my={1}
          zIndex={10}
          // border="1px solid red"
          gap={2}
          alignItems="center"
        >
          <Box
            _hover={{ color: 'gray.500' }}
            onClick={() => {
              editor.chain().insertContent('@').run()
            }}
          >
            {'@'}
          </Box>
          <Box
            pt={1}
            fontSize="xl"
            onClick={() => {
              editor.chain().insertContent('👍').run()
            }}
          >
            {'👍'}
          </Box>
        </Flex>
      )}
    </>
  )
}
export default Tiptap
