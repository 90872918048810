import { Flex, useDisclosure, Text } from '@chakra-ui/react'
import { ClimateResource } from '@contracts/support'
import { ReactElement } from 'react'
import { Icon } from '../ui/Icon'
import Modal from '../ui/Modal'
import AddCompareClimateImpactItem from './AddCompareClimateImpactProduct'

const AddCompareClimateImpactItemButton: React.FC<{
  mt?: number
  w?: number
  children?: ReactElement
  ml?: number
  selectedProducts?: ClimateResource[]
  onCompare: (updateProductsToCompare: ClimateResource[]) => void
}> = ({ mt, children, w = 60, ml, onCompare, selectedProducts }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        flexDir="row"
        bg="white"
        boxShadow="lg"
        px={6}
        py={3}
        textAlign="center"
        borderRadius="md"
        w={w}
        ml={ml}
        h={14}
        justifyContent="center"
        alignItems="center"
        onClick={onOpen}
        mt={mt}
        _hover={{ cursor: 'pointer' }}
      >
        <Icon
          name="IoAddCircleOutline"
          _hover={{ color: 'gray' }}
          color="primary_orange02"
          onClick={onOpen}
          h={28}
        />
        <Text ml={3}>{children}</Text>
      </Flex>

      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AddCompareClimateImpactItem
          onCloseModal={onClose}
          onCompare={onCompare}
          propsSelectedProducts={selectedProducts}
        />
      </Modal>
    </>
  )
}
export default AddCompareClimateImpactItemButton
