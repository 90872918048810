/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Button, Checkbox, Radio, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useNavigate } from 'react-router-dom'
import { CreateProjectPlaceRequest } from '@contracts/projects'
import { useContext } from 'react'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'

import {
  addProjectPlaceAndProject,
  useProjectCategories,
  useProjects,
} from '../../apiClients/projectsApiClient'
import CheckboxGroup from '../ui/CheckboxGroup'

import FeedbackPopover from '../feedback/FeedbackPopover'

import { usePropertyTypes } from '../../apiClients/propertiesApiClient'
import AuthContext from '../../common/auth-context'
import RadioGroup from '../ui/RadioGroup'
import { PATH_PROJECT_PLACE } from '../../common/nav'

interface AddProjectPlaceFormValues {
  customerName: string
  projectName: string
  street: string
  zip: string
  city: string
  projectCategoryIds: string[]
  propertyTypeId: string
}
interface AddProjectPlaceErrors {
  customerName?: string
  projectName?: string
  street?: string
  zip?: string
  city?: string
  projectCategoryIds?: string
  propertyTypeId?: string
}

const AddProjectPlace: React.FC<{
  onCloseModal: () => void
}> = ({ onCloseModal }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { data: projectCategories } = useProjectCategories()
  const { data: propertyTypes } = usePropertyTypes()

  const { mutate } = useProjects()
  const navigate = useNavigate()
  const { currentOrganization } = useContext(AuthContext)
  const toast = useToast()

  if (!currentOrganization) {
    return null
  }

  let tmpPropTypeId = ''
  if (propertyTypes?.length) {
    tmpPropTypeId = propertyTypes[0].id ?? ''
  }
  const initialValues: AddProjectPlaceFormValues = {
    customerName: '',
    projectName: '',
    street: '',
    zip: '',
    city: '',
    projectCategoryIds: [],
    propertyTypeId: tmpPropTypeId,
  }

  // eslint-disable-next-line no-console
  // console.log('initVal: ', initialValues)

  const validate = (values: AddProjectPlaceFormValues) => {
    const errors: AddProjectPlaceErrors = {}

    let tmpRes = validateString(
      values.customerName,
      t('general.customerName').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.customerName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.projectName,
      t('general.projectName').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.projectName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.street,
      t('general.address.street').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.street = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.zip,
      t('general.address.zipCode').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.zip = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.city,
      t('general.address.city').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.city = tmpRes.errorMsg
    }

    if (!values.projectCategoryIds.length) {
      errors.projectCategoryIds = 'Error'
    }

    if (values.propertyTypeId.length < 8) {
      errors.propertyTypeId = t('general.chooseAnOption')
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      // eslint-disable-next-line no-console
      console.log('Submitting', values)

      const newProjectPlaceRequest: CreateProjectPlaceRequest = {
        organizationId: currentOrganization?.id,
        name: values.projectName,
        street: values.street,
        zipCode: values.zip,
        city: values.city,
        status: 'Draft',
        phase: 'Offer',
        propertyTypeId: values.propertyTypeId,

        customerName: values.customerName,
        projectName: values.projectName,
      }
      // eslint-disable-next-line no-console
      console.log('newProjectPlace: ', newProjectPlaceRequest)

      addProjectPlaceAndProject(
        newProjectPlaceRequest,

        values.projectCategoryIds,
      )
        .then(({ data: newProjectPlaceResponse }) => {
          // eslint-disable-next-line no-console
          console.log('data: ', newProjectPlaceResponse.id)
          log.info(
            `Successfully created new project place, ${values.projectName} `,
          )
          void mutate()
          applicationInsights.trackEvent(
            { name: 'Created project place' },
            { projectName: values.projectName },
          )
          toast({
            title: t('projects.createSuccessMessage', {
              name: values.projectName,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })

          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          navigate(`${PATH_PROJECT_PLACE}/${newProjectPlaceResponse.id}`)
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to create new project place, ${values.projectName} `,
          )
          toast({
            title: t('projects.createErrorMessage', {
              name: values.projectName,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
          onCloseModal()
        })
    },
  })

  return (
    <>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          {t('projects.startProject')}
        </Box>
        <FeedbackPopover context="projects" />
      </Box>
      <form onSubmit={handleSubmit} data-cy="add-project">
        <Box display="flex" flexDirection="column" m="1rem 1.5rem">
          <Input
            title={t('general.customerName')}
            name="customerName"
            type="text"
            isMandatory={true}
            isError={!!errors.customerName}
            errorText={
              touched.customerName && errors.customerName
                ? errors.customerName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
          />
          <Input
            title={t('general.projectName')}
            name="projectName"
            type="text"
            isMandatory={true}
            isError={!!errors.projectName}
            errorText={
              touched.projectName && errors.projectName
                ? errors.projectName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title={t('general.address.street')}
            name="street"
            type="text"
            isMandatory={true}
            isError={!!errors.street}
            errorText={touched.street && errors.street ? errors.street : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title={t('general.address.zipCode')}
            name="zip"
            type="text"
            isMandatory={true}
            isError={!!errors.zip}
            errorText={touched.zip && errors.zip ? errors.zip : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title={t('general.address.city')}
            name="city"
            type="text"
            isMandatory={true}
            isError={!!errors.city}
            errorText={touched.city && errors.city ? errors.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="bottom"
          />

          <Text ml="1rem" textStyle="infoSmall">
            *{t('input.mandatoryFields')}
          </Text>

          {projectCategories && (
            <CheckboxGroup
              title={t('projects.projectTypes')}
              // isError={!!formikValues.projectCategoryIds}
              isError={!!errors.projectCategoryIds}
              errorText={
                // t('projects.addProjectErrorMessages.categoryIsMissing')

                touched.projectCategoryIds && errors.projectCategoryIds
                  ? // touched.projectCategoryIds && !!formikValues.projectCategoryIds
                    t('projects.addProjectErrorMessages.categoryIsMissing')
                  : ''
              }
              isMandatory={true}
              tooltip={t('projects.addProjectFormTooltips.projectCategories')}
            >
              <>
                {projectCategories.map(p => {
                  return (
                    <Checkbox
                      key={p.id}
                      name="projectCategoryIds"
                      value={p.id}
                      padding=".5rem 1rem"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {t(`projects.projectCategoryItems.${p.name ?? ''}`)}
                    </Checkbox>
                  )
                })}
              </>
            </CheckboxGroup>
          )}
          {propertyTypes && (
            <>
              <RadioGroup
                title={t('properties.propertyForm.propertyTypeProvider')}
                value={formikValues.propertyTypeId}
                // defaultValue={propertyTypes[0].id}
                isError={!!errors.propertyTypeId}
                // isError={true}
                errorText={t('general.chooseAnOption')}
                isMandatory
              >
                <>
                  {propertyTypes.map(p => {
                    return (
                      <Radio
                        key={p.id}
                        w={36}
                        name="propertyTypeId"
                        value={p.id}
                        padding=".5rem 1rem"
                        onChange={handleChange}
                      >
                        {p.name}
                      </Radio>
                    )
                  })}
                </>
              </RadioGroup>
            </>
          )}
          <Button
            type="submit"
            margin="1rem 0"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
          >
            {t('input.submitForm')}
          </Button>
        </Box>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default AddProjectPlace
