import { Flex } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { KeyedMutator } from 'swr'
import { AreaContext } from '../../common/area-context'
import HelpRequestTemplateCard from '../sections/portal/HelpRequestTemplateCard'
import MyMessageSection from '../sections/portal/MyMessageSection'
import CreatedOrUpdatedDateCard from '../ui/CreatedOrUpdatedDateCard'
import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import HelpRequestConsumerRowCard from './HelpRequestConsumerRowCard'
import OfferCard from './OfferCard'

const ExpandableHelpRequestConsumerCard: React.FC<{
  helpReq: HelpRequestResponse
  mutateAllHelpRequests?: KeyedMutator<HelpRequestResponse[] | undefined>
  isExpanded?: boolean
}> = ({ helpReq, mutateAllHelpRequests, isExpanded = false }) => {
  return (
    <ExpandableSectionCard
      key={helpReq.id}
      variant="arrow-left"
      headerChildren={<HelpRequestConsumerRowCard helpReq={helpReq} />}
      mx={{ xxs: 1, tablet: 4 }}
      w="100%"
      isExpanded={isExpanded}
    >
      <Flex flexDir="column" mb={4} textStyle="infoText" key={helpReq.id}>
        <ExpandableSectionCard
          title="Förfrågningsunderlag"
          boxShadow="none"
          noDivider
          mx={0}
        >
          <HelpRequestTemplateCard
            helpRequestTemplateId={helpReq.helpRequestTemplateId}
            inviteMessage={helpReq.inviteMessage ?? ''}
            isReadOnly
          />
        </ExpandableSectionCard>

        <Flex flexDir="column" px={0}>
          <ExpandableSectionCard
            title="Offert"
            boxShadow="none"
            mx={0}
            noDivider
          >
            <OfferCard
              helpReq={helpReq}
              mutateAllHelpRequests={mutateAllHelpRequests}
            />
          </ExpandableSectionCard>
          <AreaContext.Provider
            value={{ area: 'helpRequest', areaId: helpReq.id }}
          >
            <MyMessageSection
              areaId={helpReq.id}
              area="HelpRequest"
              areaFriendlyName="Förfrågning"
              areaFriendlyParentName={helpReq.helpRequestTemplate?.name ?? ''}
              heading="Meddelande"
              headingColor="black"
              pt={2}
              parentArea="HelpRequestTemplate"
              parentAreaId={helpReq.helpRequestTemplateId}
              grandParentArea="Project"
              grandParentAreaId={helpReq.helpRequestTemplate?.projectId}
              conversationArea="HelpRequest"
            />
          </AreaContext.Provider>
          <CreatedOrUpdatedDateCard
            variant="created"
            createdAt={helpReq.createdAt}
            authorAccountId={helpReq.createdBy}
          />
        </Flex>
      </Flex>
    </ExpandableSectionCard>
  )
}
export default ExpandableHelpRequestConsumerCard
