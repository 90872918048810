import { Button, Flex, Text } from '@chakra-ui/react'
import { Organization, OrganizationStatus } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import {
  updateOrganizationStatus,
  useOrganization,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'

import log from '../../common/log'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import {
  isMandatoryProviderDataFilledIn,
  isProfileInfoFilledIn,
} from '../../components/provider/providerHelper'
import ProviderStatusBadge from './ProviderStatusBadge'

const ProviderProfilePageHeader: React.FC<{
  currentOrganization: Organization
}> = ({ currentOrganization }) => {
  if (!currentOrganization) {
    return null
  }
  const { t } = useTranslation()
  const { data: currentOrganizationContacts, mutate } = useOrganization(
    currentOrganization.id,
  )

  const { data: currentOrganizationProfile } = useOrganizationProfile(
    currentOrganization.id,
  )
  if (!currentOrganizationProfile) {
    return null
  }
  const profileFilledIn = isProfileInfoFilledIn(currentOrganizationProfile)

  if (!currentOrganizationContacts) {
    return null
  }

  const contactsFilledIn = isMandatoryProviderDataFilledIn(
    currentOrganizationContacts,
  )

  const onChangeStatusHandler = (newStatus: OrganizationStatus) => {
    if (!currentOrganization) {
      return null
    }
    updateOrganizationStatus(currentOrganization.id, newStatus)
      .then(res => {
        log.debug('Successfully updated organization status', res)
        void mutate()
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update organization status')
      })
  }

  return (
    <ContentPageHeader
      label={
        currentOrganizationContacts.status === 'New'
          ? t('providers.profile.newOrganizationGreeting', {
              name: currentOrganization?.name,
            })
          : t('providers.profile.title')
      }
      px={0}
      isEmbedded={false}
      variant="ghost"
    >
      <Flex flexDir="column" maxW="container.lg" pt={0}>
        {currentOrganizationContacts.status !== 'New' && (
          <ProviderStatusBadge
            status={currentOrganizationContacts.status}
            variant="badge-and-text"
            badgeVariant="solid"
            mt={6}
          />
        )}

        {currentOrganizationContacts.status === 'New' && (
          <>
            <Text textStyle="regularLight" mt={6}>
              {t('providers.profile.pageDescription1')}
            </Text>
            <Text textStyle="regularLight" mt={6}>
              {t('providers.profile.pageDescription2')}
            </Text>
          </>
        )}

        {currentOrganizationContacts.status === 'Active' && (
          <Button
            variant="outline"
            colorScheme="red"
            size="sm"
            disabled={!contactsFilledIn || !profileFilledIn}
            w="100%"
            maxW={40}
            mt={6}
            onClick={() => {
              onChangeStatusHandler('PausedByOrg')
            }}
          >
            {t('providers.profile.hideMyProfile')}
          </Button>
        )}
        {currentOrganizationContacts.status === 'PausedByOrg' && (
          <Button
            variant="solid"
            colorScheme="green"
            size="sm"
            disabled={!contactsFilledIn || !profileFilledIn}
            w="100%"
            maxW={40}
            mt={6}
            onClick={() => {
              onChangeStatusHandler('Active')
            }}
          >
            {t('providers.profile.rePublishMyProfile')}
          </Button>
        )}
        {currentOrganizationContacts.status === 'New' && (
          <Button
            variant="primary"
            colorScheme="green"
            size="sm"
            disabled={!contactsFilledIn || !profileFilledIn}
            w="100%"
            maxW="md"
            alignSelf="end"
            mt={6}
            onClick={() => {
              onChangeStatusHandler('WaitForApproval')
            }}
          >
            {t('providers.profile.readyToPublishProfile')}
          </Button>
        )}
      </Flex>
    </ContentPageHeader>
  )
}

export default ProviderProfilePageHeader
