import { Box, Flex, ResponsiveValue, Text } from '@chakra-ui/react'

import { PropertyResponse } from '@contracts/misc'
import { useNavigate } from 'react-router-dom'
import {
  updateProperty,
  useProperty,
} from '../../apiClients/propertiesApiClient'
import log from '../../common/log'
import { PATH_MY_PROPERTIES } from '../../common/nav'
import { PROPERTY_PROFILE_IMAGE_PREFIX } from '../../pages/constants'
import { useImageUrl } from '../attachments/attachmentHelper'
import ImgPickerButton from '../ui/ImgPickerButton'

const PropertyCard: React.FC<{
  property: PropertyResponse
  mt?: number
  mr?: ResponsiveValue<number | string>
  mb?: number
  ml?: number
  h?: number | string
  key?: number | string
  w?: number | string
}> = ({ property, mt, mr, mb, ml, h = 40, w = 240 }) => {
  const navigate = useNavigate()

  const imgUrl = useImageUrl(property.imageAttachmentId)
  const { mutate } = useProperty(property.id)

  const updatePropertyImgHandler = (
    inputProp: string | undefined,
    inputValue: string,
  ) => {
    updateProperty(property.id, {
      id: property.id,
      name: property.name,
      imageAttachmentId: inputValue,
    })
      .then(() => {
        property.imageAttachmentId = inputValue
        void mutate()
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update Property img for property')
      })
  }

  return (
    <Flex
      key={property.id}
      pos="relative"
      color="white"
      bg="primary_grey02"
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      h={h}
      w={w}
      borderRadius="lg"
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      onClick={() => {
        navigate(`${PATH_MY_PROPERTIES}/${property.id}`)
      }}
      _hover={{
        boxShadow: '2xl',
        transform: 'translate(-1px,-1px)',
        cursor: 'pointer',
      }}
      boxShadow="lg"
      bgImage={imgUrl}
      bgPos="center"
      bgBlendMode="overlay"
      bgSize="cover"
    >
      <Text textStyle="h4" color="black">
        {property.name}
      </Text>
      {!imgUrl && (
        <Box pos="absolute" bottom={2} right={2}>
          <ImgPickerButton
            pos="absolute"
            imgChangeHandler={updatePropertyImgHandler}
            iconColor="gray.500"
            variant={'GHOST-ICON'}
            ContextArea="Property"
            attachmentPrefix={PROPERTY_PROFILE_IMAGE_PREFIX}
          />
        </Box>
      )}
    </Flex>
  )
}
export default PropertyCard
