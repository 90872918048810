import { Routes, Route } from 'react-router-dom'
import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import AuthContext from '../../common/auth-context'
import ManageArticles from '../../pages/admin/ManageArticles'
import AddOrEditArticle from '../../pages/admin/AddOrEditArticle'
import ManageProperties from '../../pages/admin/ManageProperties'
import ManageProjects from '../../pages/admin/ManageProjects'
import ManageAccounts from '../../pages/admin/ListAccounts'
import ManageAttachments from '../../pages/admin/ManageAttachments'
import ManageTags from '../../pages/admin/ManageTags'
import ManageFeedback from '../../pages/admin/ManageFeedback'
import ManageSystem from '../../pages/admin/ManageSystem'
import ManageConstructionGlossary from '../../pages/admin/ManageConstructionGlossary'
import FeatureFlags from '../../pages/admin/FeatureFlags'
import ManageOrganizations from '../../pages/admin/ManageOrganizations'
import FindExpertPage from '../../pages/public/FindExpertPage'
import ManageTiptap from '../../pages/admin/ManageTiptap'
import ManageProjectPlaces from '../../pages/admin/ManageProjectPlaces'
import ManageHelpRequests from '../../pages/admin/ManageHelpRequests'
import AdminNav from './AdminNav'

const AdminManagement = () => {
  const authCtx = useContext(AuthContext)
  return (
    <>
      {authCtx.isAdmin && <AdminNav />}
      <Stack my={4}>
        <Routes>
          <Route index element={<ManageAccounts />} />
          <Route path="/konton/" element={<ManageAccounts />} />
          <Route
            path="/konton/konsument"
            element={<ManageAccounts accountType="Consumer" />}
          />
          <Route
            path="/konton/leverantor"
            element={<ManageAccounts accountType="Provider" />}
          />
          <Route path="/foretag/" element={<ManageOrganizations />} />
          <Route path="/fastighet" element={<ManageProperties />} />
          <Route path="/projekt" element={<ManageProjects />} />
          <Route path="/projektplats" element={<ManageProjectPlaces />} />
          <Route path="/forfragning" element={<ManageHelpRequests />} />
          <Route path="/artiklar/" element={<ManageArticles />} />
          <Route path="/artiklar/:articleId" element={<AddOrEditArticle />} />
          <Route path="/attachments/" element={<ManageAttachments />} />
          <Route path="/tags/" element={<ManageTags />} />
          <Route path="/feedback/" element={<ManageFeedback />} />
          <Route path="/featureflags/" element={<FeatureFlags />} />
          <Route path="/hitta-expert/*" element={<FindExpertPage />} />
          <Route
            path="/byggordlista/"
            element={<ManageConstructionGlossary />}
          />
          <Route path="/system/" element={<ManageSystem />} />
          <Route path="/tiptap/" element={<ManageTiptap />} />
        </Routes>
      </Stack>
    </>
  )
}
export default AdminManagement
