import { HStack, Text } from '@chakra-ui/react'
import { Article } from '@contracts/support'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  deleteArticle,
  useArticlesInfoAllStates,
} from '../../apiClients/articlesApiClient'
import IconButton from '../ui/IconButton'

const ManageArticlePanel: React.FC<{
  article: Article
  onTogglePreview: () => void
}> = ({ article, onTogglePreview }) => {
  const { t } = useTranslation()
  const { mutate } = useArticlesInfoAllStates()
  const [showPreview, setShowPreview] = useState(false)

  const togglePreview = () => {
    onTogglePreview()
    setShowPreview(!showPreview)
  }
  if (!article) {
    return <Text>{t('articles.noArticleAvailable')}</Text>
  }
  return (
    <HStack justifyContent="right" mr={8}>
      <IconButton
        data-cy={showPreview ? 'previewOffButton' : 'previewButton'}
        buttonType={showPreview ? 'PREVIEW_OFF' : 'PREVIEW'}
        m={1}
        onClick={togglePreview}
        tooltipLabel={t('articles.previewTooltip')}
      />

      <Link to={String(article.id)}>
        <IconButton
          data-cy="editButton"
          buttonType="EDIT"
          m={1}
          tooltipLabel={t('articles.editTooltip')}
        />
      </Link>
      <IconButton
        data-cy="deleteButton"
        buttonType="DELETE"
        m={1}
        onClick={() => {
          if (
            window.confirm(
              t('articles.eraseArticleQuestion', {
                articleId: article.id,
                heading: article.heading,
              }),
            )
          ) {
            void deleteArticle(article.id).then(() => {
              void mutate()
            })
          }
        }}
        tooltipLabel={t('articles.deleteTooltip')}
      />
    </HStack>
  )
}
export default ManageArticlePanel
