import { Box, Flex, Text } from '@chakra-ui/react'
import { ProjectResponse } from '@contracts/projects'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useNotes } from '../../apiClients/projectsApiClient/notes'
import { AreaContext } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import { PATH_MY_PROJECTS, PATH_PROJECT_NOTE } from '../../common/nav'
import { Link } from '../../ui'

import NoteCardMicro from '../notes/NoteCardMicro'
import MyMessageSection from '../sections/portal/MyMessageSection'

const ProjectUpdatesSummaryCard: React.FC<{ project: ProjectResponse }> = ({
  project,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const projectLinkPath = `${PATH_MY_PROJECTS}/${project.id}`

  const { data: projectNotes } = useNotes(project.id)

  let allProjectNoteIds: string[] = []
  if (projectNotes) {
    allProjectNoteIds = projectNotes.map(note => {
      return note.id
    })
  }
  // Changes since last login
  const newNotes = projectNotes?.filter(note => {
    return note.createdAt > (currentUser?.lastLoginAt ?? 0)
  })

  const { data: projectMessages } = useConversationsByArea(
    'Project',
    [project.id],
    true,
  )
  const { data: noteMessages } = useConversationsByArea(
    'ProjectNote',
    allProjectNoteIds,
    true,
  )

  const updatedProjectMessages = projectMessages?.filter(message => {
    return message.updatedAt > (currentUser?.lastLoginAt ?? 0)
  })

  const updatedNoteMessages = noteMessages?.filter(message => {
    return message.updatedAt > (currentUser?.lastLoginAt ?? 0)
  })

  let newOrUpdatedMessagesCount = 0

  if (updatedProjectMessages || updatedNoteMessages) {
    newOrUpdatedMessagesCount =
      (updatedProjectMessages ? updatedProjectMessages.length : 0) +
      (updatedNoteMessages ? updatedNoteMessages.length : 0)
  }

  const updatedNotes = projectNotes?.filter(note => {
    return (
      note.updatedAt > (currentUser?.lastLoginAt ?? 0) &&
      note.createdAt < (currentUser?.lastLoginAt ?? 0)
    )
  })
  let newOrUpdatedNotesCount = 0
  if (newNotes && updatedNotes) {
    newOrUpdatedNotesCount = newNotes.length + updatedNotes.length
  }

  if (!newOrUpdatedNotesCount && !newOrUpdatedMessagesCount) {
    return (
      <Flex justifyContent="space-between" wrap="wrap" px={2} rowGap={2}>
        <Text fontStyle="italic">
          Det finns inga nya projektuppdateringar sedan din senaste inloggning
        </Text>
        <Link to={projectLinkPath}>
          <Text fontSize="sm" color="primary" _hover={{ color: 'gray' }}>
            Gå till projektet
          </Text>
        </Link>
      </Flex>
    )
  }

  return (
    <AreaContext.Provider value={{ area: 'project', areaId: project.id }}>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        columnGap={4}
        wrap="wrap"
        bg="lightBackground"
        borderRadius="lg"
        px={2}
        pt={4}
        pb={4}
        rowGap={8}
        justifyContent="space-around"
      >
        <Box pos="absolute" right={{ xxs: 4, tablet: 10, laptop: 24 }}>
          <Link to={projectLinkPath}>
            <Text fontSize="sm" color="primary" _hover={{ color: 'gray' }}>
              Gå till projektet
            </Text>
          </Link>
        </Box>
        {newNotes && newNotes.length > 0 && (
          <Flex flexDir="column" w="330px" mt={6}>
            <Text fontWeight="bold" fontSize="sm" mb={2}>
              Nya projektinlägg (
              {newNotes.length === 0 ? t('general.none') : newNotes.length})
            </Text>

            <Flex flexDir="row" wrap="wrap" gap={2}>
              {newNotes &&
                newNotes.length > 0 &&
                newNotes.map(note => {
                  return (
                    <NoteCardMicro
                      note={note}
                      projectId={project.id}
                      key={note.id}
                    />
                  )
                })}
            </Flex>
          </Flex>
        )}
        {updatedNotes && updatedNotes.length > 0 && (
          <Flex flexDir="column" w="330px" mt={6}>
            <Text fontWeight="bold" fontSize="sm" mb={2}>
              Uppdaterade projektinlägg (
              {updatedNotes.length === 0
                ? t('general.none')
                : updatedNotes.length}
              )
            </Text>

            <Flex flexDir="row" wrap="wrap" gap={2}>
              {updatedNotes &&
                updatedNotes.length > 0 &&
                updatedNotes.map(note => {
                  return (
                    <NoteCardMicro
                      note={note}
                      projectId={project.id}
                      key={note.id}
                    />
                  )
                })}
            </Flex>
          </Flex>
        )}
        {newOrUpdatedMessagesCount > 0 && (
          <Flex flexDir="column" w="330px" mt={6}>
            <Text fontWeight="bold" fontSize="sm" mb={2}>
              Nya meddelanden (
              {newOrUpdatedMessagesCount === 0
                ? t('general.none')
                : newOrUpdatedMessagesCount}
              )
            </Text>

            <Flex flexDir="row" wrap="wrap" gap={2}>
              {projectMessages && projectMessages.length > 0 && (
                <MyMessageSection
                  area="Project"
                  areaId={project.id}
                  variant="showLatestUpdates"
                  showOnlyIfUpdatedSince={currentUser?.lastLoginAt}
                  headingStyle="h8"
                  link={`${PATH_MY_PROJECTS}/${project.id}`}
                  pt={0}
                  areaFriendlyName={project.name}
                  areaFriendlyParentName={''}
                  parentArea="Property"
                  parentAreaId={project.propertyId}
                  // grandParentArea={''}
                  // grandParentAreaId={''}
                  conversationArea="Project"
                />
              )}
              {projectNotes &&
                projectNotes.length > 0 &&
                projectNotes.map(note => {
                  const path = `${PATH_PROJECT_NOTE}/${note.id}`

                  return (
                    <MyMessageSection
                      key={note.id}
                      areaId={note.id}
                      area="ProjectNote"
                      excludeSystemMessages={true}
                      variant="showLatestUpdates"
                      showOnlyIfUpdatedSince={currentUser?.lastLoginAt}
                      heading={`Inlägg "${note.name}"`}
                      headingStyle="h8"
                      link={path}
                      projectNote={note}
                      projectId={project.id}
                      pt={0}
                      areaFriendlyName={note.name}
                      areaFriendlyParentName={project.name}
                      parentArea="Project"
                      parentAreaId={project.id}
                      // grandParentArea={''}
                      // grandParentAreaId={''}
                      conversationArea="ProjectNote"
                    />
                  )
                })}
            </Flex>
          </Flex>
        )}
      </Flex>
    </AreaContext.Provider>
  )
}
export default ProjectUpdatesSummaryCard
