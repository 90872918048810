import { Flex, Button, Text, Box, HStack, useToast } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { sendVerifyEmail } from '../../apiClients/accountsApiClient'

const EmailVerificationRequiredCard: FC<{
  area: 'Organization' | 'Property' | 'Project'
  emailToVerify: string | undefined
  onCloseModal: () => void
}> = ({ area = 'Property', emailToVerify, onCloseModal }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const verifyEmail = () => {
    sendVerifyEmail()
      .then(() => {
        toast({
          title: t('accounts.verifyEmailSentMessage', { emailToVerify }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(() => {
        toast({
          title: t('accounts.verifyEmailFailedMessage'),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
    onCloseModal()
  }

  return (
    <>
      <Flex
        h={14}
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Text textStyle="h5">{t('accounts.verifyEmail')}</Text>
      </Flex>
      {area === 'Property'}
      <Text mt={6}>{t(`accounts.verifyEmailRequired.intro${area}`)}</Text>

      <Flex flexDir="row" mt={6}>
        <Text>{t('accounts.verifyEmailRequired.description')}&nbsp;&nbsp;</Text>
        <Text fontWeight="bold">{emailToVerify}</Text>
      </Flex>
      <Text mt={6}>{t('accounts.verifyEmailRequired.noMessageRecieved')}</Text>
      <Box ml={4}>
        <ul>
          <li>
            <Text>
              {t('accounts.verifyEmailRequired.noMessageRecievedHelp1')}
            </Text>
          </li>
          <li>
            <Text>
              {t('accounts.verifyEmailRequired.noMessageRecievedHelp2', {
                berikarDomain: window.location.hostname,
              })}
            </Text>
          </li>
        </ul>
      </Box>
      <Text mt={6}>
        {t('accounts.verifyEmailRequired.verifiedStateNotUpdated')}
      </Text>

      <HStack
        flexDir="row"
        justifyContent="space-around"
        spacing="6"
        mt={8}
        mb={4}
      >
        <Button w="100%" variant="outline" onClick={onCloseModal}>
          {t('general.cancel')}
        </Button>
        <Button w="100%" type="submit" variant="primary" onClick={verifyEmail}>
          {t('accounts.verifyEmail')}
        </Button>
      </HStack>
    </>
  )
}
export default EmailVerificationRequiredCard
