import { Flex, ResponsiveValue, Text, useDisclosure } from '@chakra-ui/react'

import { ContextArea } from '@contracts/support'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { useAttachmentsByAreaQuery } from '../../apiClients/attachmentsApiClient'
import FileCardCompact from '../attachments/AttachmentsCardCompact'
import Heading from '../ui/Heading'
import Modal from '../ui/Modal'
import { groupBy } from '../attachments/attachmentHelper'

const ImagesWidget: React.FC<{
  variant: 'project' | 'property'
  propertyId?: string
  projectId?: string
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  border?: string
  minW?: ResponsiveValue<number | string>
  maxW?: ResponsiveValue<number | string>
  minH?: ResponsiveValue<number | string>
  maxH?: ResponsiveValue<number | string>
  h?: ResponsiveValue<number | string>
  thumbnailSize?: ResponsiveValue<number | string>
}> = ({
  projectId,
  propertyId,
  variant,
  px = 2,
  py = 2,
  border,
  // h = '150px',
  h,
  thumbnailSize = 16,
  minW,
  maxW,
  minH,
  maxH = '210px',
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  let areaQueries: {
    area?: ContextArea
    parentArea?: ContextArea
    grandParentArea?: ContextArea
    areaId?: string
    parentAreaId?: string | undefined
    grandParentAreaId?: string | undefined
  }[] = []

  if (variant === 'project') {
    // Without project id -> images from all projects for current user
    // With project id -> only images for specified project
    // areas = ['Project', 'ProjectNote', 'ProjectNote.description']
    areaQueries = [
      { parentArea: 'Project', parentAreaId: projectId },
      {
        grandParentArea: 'Project',
        grandParentAreaId: projectId,
      },
    ]
  } else if (variant === 'property') {
    // Without property id -> images from all properties for current user
    // With project id -> only images for specified property
    areaQueries = [
      { parentArea: 'Property', parentAreaId: propertyId },
      {
        parentArea: 'PropertyNote',
        grandParentArea: 'Property',
        grandParentAreaId: propertyId,
      },
    ]
  }

  const { data: attachments } = useAttachmentsByAreaQuery(areaQueries)
  const { images: groupedImages } = groupBy(attachments ?? [])
  const images = groupedImages.map(images => {
    return images[0]
  })
  // const images = attachments?.filter(attachment => {
  //   return attachment.mimeType.includes('image')
  // })

  return (
    <>
      <Flex
        flexDir="column"
        gap={images && images.length ? 2 : 0.5}
        py={py}
        px={px}
        alignItems="flex-start"
        border={border}
        bg="white"
        borderRadius="lg"
        boxShadow="lg"
        flexShrink={1}
        w="100%"
        minW={minW}
        maxW={maxW}
        minH={minH}
        maxH={maxH}
      >
        <Flex
          gap={2}
          alignItems="center"
          onClick={() => {
            onOpen()
          }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {propertyId ? 'Bilder från hemmet' : 'Projektbilder'}
          </Heading>
          <InfoOutlineIcon
            fontSize={14}
            color="logo_orange"
            _hover={{ cursor: 'pointer', color: 'orange' }}
            onClick={() => {
              onOpen()
            }}
          />
        </Flex>

        <Flex
          maxH={h}
          wrap="wrap"
          gap={2}
          overflow="scroll"
          // overflow="clip"
          p={0}
          // border="1px solid red"
        >
          {images &&
            images.length > 0 &&
            images.map(img => {
              return (
                <FileCardCompact
                  key={img.id}
                  variant="thumbnail"
                  attachmentId={img.id}
                  thumbnailSize={thumbnailSize}
                />
              )
            })}
        </Flex>
        {!images ||
          (!images.length && (
            <Flex flexDir="column">
              <Text textStyle="infoTextLight" fontStyle="italic">
                {variant === 'property' &&
                  t('widgets.imagesWidget.property.noImagesInfoText')}
                {variant === 'project' &&
                  t('widgets.imagesWidget.project.noImagesInfoText')}
              </Text>
            </Flex>
          ))}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {variant === 'property'
              ? t('widgets.imagesWidget.property.title')
              : t('widgets.imagesWidget.project.title')}
          </Heading>
          <Text textStyle="infoTextLight" fontStyle="italic">
            {variant === 'property' &&
              t('widgets.imagesWidget.property.noImagesInfoText')}
            {variant === 'project' &&
              t('widgets.imagesWidget.project.noImagesInfoText')}
          </Text>
        </Flex>
      </Modal>
    </>
  )
}
export default ImagesWidget
