/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Flex, Select, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  CreateProjectPlaceRequest,
  ProjectPlacePhase,
  ProjectPlaceResponse,
  ProjectPlaceStatus,
} from '@contracts/projects'
import { useContext } from 'react'
import log from '../../common/log'

import Input from '../ui/Input'
import Spinner from '../Spinner'

import AuthContext from '../../common/auth-context'

import {
  updateProjectPlace,
  useProjectPlace,
} from '../../apiClients/projectsApiClient/projectPlaces'
import { projectPlaceStatuses, projectPlacePhases } from '../../common/enums'
import Heading from '../ui/Heading'
import { getDateStrFromNumberIfExist } from '../../common/util'

interface EditProjectPlaceFormValues {
  beginAt?: number
  endAt?: number
  status: ProjectPlaceStatus
  phase: ProjectPlacePhase
}
interface EditProjectPlaceErrors {
  beginAt?: string
  endAt?: string
  status?: ProjectPlaceStatus
  phase?: ProjectPlacePhase
}

const EditProjectPlaceProductionInfo: React.FC<{
  projectPlace: ProjectPlaceResponse
}> = ({ projectPlace }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()

  const { currentOrganization } = useContext(AuthContext)
  const toast = useToast()
  const { mutate: mutateProjectPlace } = useProjectPlace(projectPlace.id)

  if (!currentOrganization) {
    return null
  }

  const initialValues: EditProjectPlaceFormValues = {
    beginAt: projectPlace.beginAt,
    endAt: projectPlace.endAt,
    status: projectPlace.status,
    phase: projectPlace.phase,
  }

  const validate = (values: EditProjectPlaceFormValues) => {
    const errors: EditProjectPlaceErrors = {}

    if (values.beginAt && values.endAt) {
      if (values.beginAt > values.endAt) {
        errors.beginAt = t('projectPlaces.beginAtBeforeEndAtErrorText')
        errors.endAt = t('projectPlaces.beginAtBeforeEndAtErrorText')
      }
    }
    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const updateProjectPlaceRequest: CreateProjectPlaceRequest = {
        organizationId: currentOrganization?.id,
        name: projectPlace.name,
        customerName: projectPlace.customerName,
        customerEmail: projectPlace.customerEmail,
        street: projectPlace.street,
        zipCode: projectPlace.zipCode,
        city: projectPlace.city,
        propertyTypeId: projectPlace.propertyTypeId,
        beginAt: values.beginAt,
        endAt: values.endAt,
        status: values.status,
        phase: values.phase,
      }
      // eslint-disable-next-line no-console
      console.log('newProjectPlace: ', updateProjectPlaceRequest)

      updateProjectPlace(projectPlace.id, updateProjectPlaceRequest)
        .then(({ dataValues: res }) => {
          // void mutate()
          applicationInsights.trackEvent(
            { name: 'Update project place' },
            { projectName: res.name },
          )

          toast({
            title: t('projectPlaces.updateSuccessMessage', {
              name: res.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          void mutateProjectPlace()
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to update project place, ${projectPlace.name} `,
          )
          toast({
            title: t('projectPlaces.updateErrorMessage', {
              name: projectPlace.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form onSubmit={handleSubmit} data-cy="add-project">
        {/* <Flex flexDir="column" gap={3}> */}
        <Flex gap={6} wrap="wrap" alignItems="flex-end">
          <Input
            title={t('projectPlaces.beginAt')}
            name="beginAt"
            defaultValue={getDateStrFromNumberIfExist(initialValues.beginAt)}
            type="date"
            isMandatory={false}
            isError={!!errors.beginAt}
            errorText={touched.beginAt && errors.beginAt ? errors.beginAt : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="plain"
            // maxW={64}
            w={64}
          />
          <Input
            title={t('projectPlaces.endAt')}
            name="endAt"
            defaultValue={getDateStrFromNumberIfExist(initialValues.endAt)}
            type="date"
            isMandatory={false}
            isError={!!errors.endAt}
            errorText={touched.endAt && errors.endAt ? errors.endAt : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
            variant="plain"
            // maxW={64}
            w={64}
          />

          <Flex gap={6} wrap="wrap">
            <Flex flexDir="column" minW="64">
              <Heading mb={1} textStyle="h8">
                {t('projectPlaces.status.status')}*
              </Heading>
              <>
                <Select
                  title={t('properties.propertyForm.propertyTypeProvider')}
                  name="status"
                  defaultValue={projectPlace.status}
                  onChange={handleChange}
                >
                  <>
                    {projectPlaceStatuses.map(status => {
                      return (
                        <option key={status} value={status}>
                          {t(`projectPlaces.status.${status}`)}
                        </option>
                      )
                    })}
                  </>
                </Select>
              </>
              <Text ml="1rem" textStyle="infoSmall">
                *{t('input.mandatoryFields')}
              </Text>
            </Flex>
            <Flex flexDir="column" minW="64">
              <Heading mb={1} textStyle="h8">
                {t('projectPlaces.phase.phase')}*
              </Heading>
              <>
                <Select
                  name="phase"
                  defaultValue={projectPlace.phase}
                  onChange={handleChange}
                >
                  <>
                    {projectPlacePhases.map(phase => {
                      return (
                        <option
                          key={phase}
                          value={phase}
                          // padding=".5rem 1rem"
                        >
                          {t(`projectPlaces.phase.${phase}`)}
                        </option>
                      )
                    })}
                  </>
                </Select>
              </>
              <Text ml="1rem" textStyle="infoSmall">
                *{t('input.mandatoryFields')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            my={4}
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
            w={{ xxs: '100%', laptop: 32 }}
          >
            {t('ui.button.save')}
          </Button>
        </Flex>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default EditProjectPlaceProductionInfo
