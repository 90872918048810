import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, ResponsiveValue, Show, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import { useCollaborators } from '../../apiClients/projectsApiClient'
import { useProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'
import AuthContext from '../../common/auth-context'
import {
  PATH_ADMIN_PAGES,
  PATH_ADMIN_PROJECTS,
  PATH_MY_PROJECTS,
  PATH_PROJECT_PLACE,
} from '../../common/nav'
import { getDateStrFromNumberIfExist } from '../../common/util'

import AccountAvatar from '../accounts/AccountAvatar'
import Link from '../ui/Link'
import TextCard from '../ui/TextCard'

const ProjectPlaceSummaryCard: React.FC<{
  projectPlaceId: string
  mr?: number
  mt?: number
  ml?: ResponsiveValue<number | string>
  headerBg?: string
}> = ({ projectPlaceId, mr, mt, ml }) => {
  const { t } = useTranslation()
  const { data: projectPlace } = useProjectPlace(projectPlaceId)
  const { pathname } = useLocation()

  const { isAdmin, currentUser } = useContext(AuthContext)

  const projectPlaceLink = `${PATH_PROJECT_PLACE}/${projectPlaceId}`
  let projectLink = ''
  if (projectPlace?.projectId) {
    if (isAdmin && pathname.startsWith(PATH_ADMIN_PAGES)) {
      projectLink = `${PATH_ADMIN_PROJECTS}?projekt=${projectPlace?.projectId}`
    } else {
      projectLink = `${PATH_MY_PROJECTS}/${projectPlace.projectId}`
    }
  }

  const { data: allCollabs } = useCollaborators(projectPlace?.projectId)
  const { data: allOrgAccounts } = useOrganizationAccounts(
    projectPlace?.organizationId,
  )

  // Find intesect bts allCollabs and allOrgAccounts
  const orgCollabs = allCollabs?.filter(collab => {
    return allOrgAccounts?.find(orgAccount => {
      return orgAccount.accountId === collab.accountId
    })
  })

  let currentUserHasAccessToProject = false
  if (
    currentUser &&
    allCollabs?.find(collab => {
      return collab.accountId === currentUser.id
    })
  ) {
    currentUserHasAccessToProject = true
  }

  return (
    <Flex
      flexDir="row"
      mr={mr}
      mt={mt}
      pb={4}
      columnGap={4}
      rowGap={6}
      ml={ml}
      justifyContent={{ xxs: 'left', tablet: 'space-around' }}
      wrap="wrap"
    >
      <TextCard heading={t('projectPlaces.customer')}>
        <Flex flexDir="column">{projectPlace?.customerName}</Flex>
      </TextCard>

      <Show below="laptop">
        <TextCard heading={t('projectPlaces.beginAt')}>
          <Text>{getDateStrFromNumberIfExist(projectPlace?.beginAt)}</Text>
        </TextCard>
        <TextCard heading={t('projectPlaces.endAt')}>
          <Text>{getDateStrFromNumberIfExist(projectPlace?.endAt)}</Text>
        </TextCard>
      </Show>

      <TextCard
        heading={`Involverade medarbetare (${orgCollabs?.length ?? 0})`}
      >
        <Flex
          flexDir="row"
          gap={4}
          maxW="lg"
          // border="1px solid red"
          wrap="wrap"
        >
          {orgCollabs &&
            orgCollabs.length > 0 &&
            orgCollabs.map(collaborator => {
              return (
                <AccountAvatar
                  key={collaborator.accountId}
                  w={60}
                  accountId={collaborator.accountId}
                  variant="avatarAndName"
                />
              )
            })}
        </Flex>
      </TextCard>
      <Flex flexDir="column" fontSize="sm">
        {/* <Link to={`${PATH_PROJECT_PLACE}/${projectPlace?.id ?? ''}`}> */}
        <Link to={projectPlaceLink}>
          <Flex gap={2} h={10} alignItems="center">
            <ExternalLinkIcon />
            <Text>Gå till projektplats</Text>
          </Flex>
        </Link>
        {currentUserHasAccessToProject && (
          <Link to={projectLink}>
            <Flex gap={2} h={10} alignItems="center">
              <ExternalLinkIcon />
              <Text>Gå till kundprojekt</Text>
            </Flex>
          </Link>
        )}
        {!currentUserHasAccessToProject && (
          <Text color="red.400">{t('projectPlaces.noAccessRights')}</Text>
        )}
      </Flex>
    </Flex>
  )
}
export default ProjectPlaceSummaryCard
