import { Text, Flex, AlertIcon, Alert } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { Organization } from '@contracts/misc'
import {
  useOrganizationProfile,
  useOrganizationProfileAdmin,
  useServiceTypes,
} from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import TextCard from '../ui/TextCard'
import OrganizationAvailableWhereCard from '../organizations/OrganizationAvailableWhereCard'
import HtmlPane from '../richTextEditor/HtmlPane'

const ProviderProfileAboutUsHeader: React.FC<{
  organization: Organization
}> = ({ organization }) => {
  const { isAdmin } = useContext(AuthContext)
  const [showAvailabilityComment, setShowAvailabilityComment] = useState(false)

  const { data: organizationProfileNotAdmin } = useOrganizationProfile(
    isAdmin ? undefined : organization.id,
  )
  const { data: organizationProfileAdmin } = useOrganizationProfileAdmin(
    isAdmin ? organization.id : undefined,
  )

  const organizationProfile = isAdmin
    ? organizationProfileAdmin
    : organizationProfileNotAdmin

  const { data: allServiceTypes } = useServiceTypes()
  const { t } = useTranslation()

  if (!organizationProfile) {
    return null
  }

  return (
    <>
      <Flex
        border="1px solid"
        borderColor="gray.400"
        borderRadius="lg"
        wrap="wrap"
        gap={2}
        maxW="container.lg"
        py={{ xxs: 2, tablet: 3 }}
        px={{ xxs: 2, tablet: 6 }}
      >
        <TextCard
          heading={t('providers.profile.ourServices')}
          textStyle="lgLight"
          gap={0}
          w={64}
          flexGrow={1}
        >
          <Flex flexDir="row" wrap="wrap" columnGap={1}>
            {allServiceTypes &&
              organizationProfile.serviceTypeIds?.map(
                (serviceTypeId, count) => {
                  return (
                    <Flex key={serviceTypeId}>
                      <Text key={serviceTypeId} textStyle="h4">
                        {
                          allServiceTypes.find(st => {
                            return st.id === serviceTypeId
                          })?.name
                        }
                      </Text>
                      {organizationProfile &&
                        organizationProfile?.serviceTypeIds &&
                        organizationProfile?.serviceTypeIds.length >
                          count + 1 && <Text textStyle="h4">,</Text>}
                    </Flex>
                  )
                },
              )}
          </Flex>
        </TextCard>
        <TextCard
          heading={t('providers.profile.ourAvailability')}
          gap={0}
          textStyle="lgLight"
          comment={organizationProfile.availabilityComment}
          showComment={showAvailabilityComment}
          setShowComment={setShowAvailabilityComment}
          maxW={64}
          w={64}
          flexGrow={0.3}
        >
          <Text textStyle="h4">
            {t(
              `providers.profile.availabilityOptions.${organizationProfile.availability}`,
            )}
          </Text>
        </TextCard>
        <TextCard
          heading={t('providers.profile.ourDeliveryArea')}
          gap={0}
          textStyle="lgLight"
          w={64}
          flexGrow={0.3}
        >
          <OrganizationAvailableWhereCard
            org={organization}
            textStyle="h4"
            gap={{ xxs: 1, tablet: 2 }}
          />
        </TextCard>
      </Flex>
      {showAvailabilityComment && (
        <Alert mt={2} status="info" borderRadius="lg">
          <AlertIcon />
          <HtmlPane
            content={organizationProfile.availabilityComment}
            bg="transparent"
            py={0}
          />
        </Alert>
      )}
    </>
  )
}
export default ProviderProfileAboutUsHeader
