import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react'
import { OrganizationStatus } from '@contracts/misc'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ProviderStatusBadge: React.FC<{
  status: OrganizationStatus
  variant?: 'badge-only' | 'badge-and-text'
  tooltip?: boolean
  badgeVariant?: 'outline' | 'solid' | 'subtle'
  mt?: number | string
}> = ({
  status,
  variant,
  badgeVariant = 'outline',
  tooltip = false,
  mt = 2,
}) => {
  const { t } = useTranslation()
  const maxW = 52
  const py = 1
  const borderRadius = 'lg'

  if (status === 'Active') {
    return (
      <>
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusActiveDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="green"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusActive')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusActiveDescription')}
          </Text>
        )}
      </>
    )
  }

  if (status === 'New') {
    return (
      <>
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusNewDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="blue"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusNew')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusNewDescription')}
          </Text>
        )}
      </>
    )
  }
  if (status === 'WaitForApproval') {
    return (
      <>
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusNewDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="orange"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusWaitForApproval')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusWaitForApprovalDescription')}
          </Text>
        )}
      </>
    )
  }
  if (status === 'PausedByOrg') {
    return (
      <>
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusNewDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="red"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusPausedByOrg')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusPausedByOrgDescription')}
          </Text>
        )}
      </>
    )
  }
  if (status === 'PausedByBerikar') {
    return (
      <Flex flexDir="column">
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusNewDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="red"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusPausedByBerikar')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusPausedByBerikarDescription')}
          </Text>
        )}
      </Flex>
    )
  }
  if (status === 'Deleted') {
    return (
      <>
        <Tooltip
          label={
            tooltip ? t('providers.profile.profileStatusNewDescription') : ''
          }
          hasArrow
          placement="bottom"
        >
          <Badge
            maxW={maxW}
            textAlign="center"
            py={py}
            colorScheme="red"
            borderRadius={borderRadius}
            variant={badgeVariant}
            mt={mt}
          >
            {t('providers.profile.profileStatusDeleted')}
          </Badge>
        </Tooltip>
        {variant !== 'badge-only' && (
          <Text textStyle="regularLight" mt={2}>
            {t('providers.profile.profileStatusDeletedDescription')}
          </Text>
        )}
      </>
    )
  }
  return (
    <Badge
      maxW={maxW}
      textAlign="center"
      py={py}
      colorScheme="red"
      borderRadius={borderRadius}
      variant={badgeVariant}
      mt={mt}
    >
      ?
    </Badge>
  )
}
export default ProviderStatusBadge
