import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { useCollaborators } from '../../../apiClients/projectsApiClient'
import { useHelpRequestsTemplate } from '../../../apiClients/projectsApiClient/helpRequests'
import AuthContext from '../../../common/auth-context'
import { getDateStrFromDate, minutesDiff } from '../../../common/util'
import FileManagerCard from '../../attachments/FileManagerCard'

import { useProjectInfo } from '../../../apiClients/projectsApiClient/projects'
import { usePropertyAddress } from '../../../apiClients/propertiesApiClient/properties'
import AddOrEditHelpRequestTemplateButton from '../../helpRequests/AddOrEditHelpRequestTemplateButton'
import ProjectClientsCard from '../../projects/ProjectClientsCard'
import AddressCard from '../../properties/AddressCard'
import HtmlPane from '../../richTextEditor/HtmlPane'
import CreatedOrUpdatedDateCard from '../../ui/CreatedOrUpdatedDateCard'
import TextCard from '../../ui/TextCard'

const HelpRequestTemplateCard: React.FC<{
  helpRequestTemplateId: string
  mb?: number | string
  inviteMessage?: string
  isReadOnly?: boolean
}> = ({ helpRequestTemplateId, mb, inviteMessage, isReadOnly = false }) => {
  const { t } = useTranslation()
  const { data: helpRequestTemplate } = useHelpRequestsTemplate(
    helpRequestTemplateId,
  )

  // eslint-disable-next-line no-console
  // console.log(
  //   'helpReqTemplate.attachmentsJson: ',
  //   helpRequestTemplate?.attachmentsJson,
  // )

  const { data: project } = useProjectInfo(helpRequestTemplate?.projectId)
  const { data: projectCollaborators } = useCollaborators(
    helpRequestTemplate?.projectId,
  )
  const { currentUser } = useContext(AuthContext)

  const { data: address } = usePropertyAddress(project?.propertyId)
  const paddingBtwHeadingAndText = 2

  const currentUserAccessRights = projectCollaborators?.find(collab => {
    return collab.accountId === currentUser?.id
  })?.accountRelation

  let isAllowedToEdit = false
  if (
    currentUserAccessRights === 'Owner' ||
    currentUserAccessRights === 'Editor'
  ) {
    isAllowedToEdit = true
  }

  return (
    <Flex
      flexDir="column"
      mb={mb}
      rowGap={4}
      textStyle="textRegular"
      bg="lightBackground"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="lg"
      px={4}
      py={4}
      w="100%"
    >
      {inviteMessage && inviteMessage.length > 7 && (
        <Flex flexDir="column" gap={paddingBtwHeadingAndText}>
          <Text>Personligt meddelande</Text>
          <HtmlPane
            content={inviteMessage ?? '<p></p>'}
            textStyle="cardText"
            bg="lightBackground"
            border="1px solid"
          />
        </Flex>
      )}

      <Flex justifyContent="space-between">
        <TextCard heading={t('general.information')} gap={0}>
          <Flex flexDir="column">
            <Flex alignItems="flex-start">
              <Text minW={20} pt={1}>
                {t('properties.address')}:
              </Text>
              <AddressCard address={address} variant="1-row" noHeader />
            </Flex>
            <Flex>
              <Text minW={20}>{t('general.project')}:</Text>
              {project?.name}
            </Flex>
          </Flex>
        </TextCard>
        <Flex>
          {!isReadOnly && isAllowedToEdit && helpRequestTemplate?.projectId && (
            <AddOrEditHelpRequestTemplateButton
              helpReqTemplateId={helpRequestTemplateId}
              projectId={helpRequestTemplate.projectId}
              variant="icon"
            />
          )}
        </Flex>
      </Flex>
      <TextCard heading={t('general.name')} gap={0}>
        <Text>{helpRequestTemplate?.name}</Text>
      </TextCard>
      <TextCard heading="Önskad tidpunkt för genomförandet" gap={0}>
        <Text>
          {helpRequestTemplate?.helpTimestamp
            ? getDateStrFromDate(new Date(helpRequestTemplate?.helpTimestamp))
            : 'Ingen tidpunkt vald'}
        </Text>
      </TextCard>
      <Flex flexDir="column" gap={paddingBtwHeadingAndText}>
        <Text>{t('general.description')}</Text>
        <HtmlPane
          content={helpRequestTemplate?.description ?? ' '}
          textStyle="cardText"
          bg="lightBackground"
          border="1px solid"
        />
      </Flex>

      <FileManagerCard
        my={4}
        attachmentIds={
          helpRequestTemplate?.attachmentsJson?.map(({ id }) => id) ?? []
        }
      />

      {helpRequestTemplate?.projectId && (
        <Flex flexDir="column">
          <Flex alignItems="center">
            <Text>{t('helpRequests.template.clients')}</Text>

            <Text textStyle="infoTextLight">
              &nbsp;{t('helpRequests.template.clientsIngress')}
            </Text>
          </Flex>
          <Flex mt={1}>
            <ProjectClientsCard
              projectId={helpRequestTemplate?.projectId}
              gap={3}
            />
          </Flex>
        </Flex>
      )}

      <Flex flexDir="column" mt={4}>
        {helpRequestTemplate?.createdAt && (
          <CreatedOrUpdatedDateCard
            variant="created"
            createdAt={helpRequestTemplate.createdAt}
            authorAccountId={helpRequestTemplate.createdBy}
          />
        )}

        {helpRequestTemplate?.createdAt &&
          helpRequestTemplate?.updatedAt &&
          minutesDiff(
            helpRequestTemplate?.updatedAt,
            helpRequestTemplate?.createdAt,
          ) > 1 && (
            <CreatedOrUpdatedDateCard
              variant="updated"
              updatedAt={helpRequestTemplate.updatedAt}
              authorAccountId={helpRequestTemplate?.updatedBy ?? undefined}
            />
          )}
      </Flex>
    </Flex>
  )
}
export default HelpRequestTemplateCard
