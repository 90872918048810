import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useOrganization } from '../../apiClients/organizationsApiClient'
import HtmlPane from '../richTextEditor/HtmlPane'
import OrganizationLogoCard from './OrganizationLogoCard'

const OrganizationEndorsmentCard: React.FC<{
  organizationId: string
  mt?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
  w?: ResponsiveValue<number | string>
  comment: string | undefined
}> = ({ organizationId, mt, px, w, comment }) => {
  const { t } = useTranslation()
  const { data: organization } = useOrganization(organizationId)

  return (
    <Flex
      mt={mt}
      px={px}
      bg="primary_green01"
      justifyContent="space-around"
      w={w}
    >
      <OrganizationLogoCard
        organizationId={organizationId}
        boxSize={{ xxs: 16, tablet: 20 }}
        variant="logo-only"
      />
      <Flex
        flexDir="column"
        ml={4}
        // border="1px solid red"
        textAlign="left"
        maxW={{ xxs: '280px', tablet: '80%' }}
        justifyContent="center"
      >
        <Text>
          {`${organization?.name ?? ''} ${t(
            'organizationCollaborators.validatesOurServices',
          )}`}
        </Text>
        {comment && comment.length > 7 && (
          <HtmlPane
            content={comment}
            bg="transparent"
            px={0}
            py={0}
            flexDir="row"
            textStyle="smLight"
            textAlign="left"
          />
        )}
      </Flex>
    </Flex>
  )
}
export default OrganizationEndorsmentCard
