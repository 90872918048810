import { Show, Td, Tr } from '@chakra-ui/react'
import { ProjectPlaceResponse } from '@contracts/projects'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { PATH_PROJECT_PLACE } from '../../common/nav'
import { getDateStrFromNumberIfExist } from '../../common/util'
import ProjectPlaceBadge from '../../components/projects/ProjectPlaceBadge'
import StringShortener from '../../components/ui/StringShortener'

const ListProjectPlacesRow: React.FC<{
  bg: string
  accordionsToShow: string[]
  projectPlace: ProjectPlaceResponse
  toggleAccordion: (id: string) => void
}> = ({ projectPlace, bg, accordionsToShow, toggleAccordion }) => {
  const navigate = useNavigate()
  const addressStr = `${projectPlace.street}, ${projectPlace.city}`
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const projectPlaceLink = `${PATH_PROJECT_PLACE}/${projectPlace.id}`

  return (
    <Tr bg={bg} data-cy="projectPlaces">
      <Td color="primary_orange02" _hover={{ color: 'primary_orange01' }}>
        {!accordionsToShow.includes(projectPlace.id) && (
          <AiOutlinePlusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(projectPlace.id)
            }}
          />
        )}
        {accordionsToShow.includes(projectPlace.id) && (
          <AiOutlineMinusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(projectPlace.id)
            }}
          />
        )}
      </Td>

      <Td
        onClick={() => {
          navigate(projectPlaceLink)
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <StringShortener title={projectPlace.name} maxLength={15} />
      </Td>
      <Td
        onClick={() => {
          navigate(projectPlaceLink)
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <StringShortener title={addressStr} maxLength={25} />
      </Td>

      <Td
        onClick={() => {
          navigate(projectPlaceLink)
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <ProjectPlaceBadge variant="status" status={projectPlace.status} />
      </Td>
      <Td
        onClick={() => {
          navigate(projectPlaceLink)
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <ProjectPlaceBadge variant="phase" phase={projectPlace.phase} />
      </Td>
      <Show above="laptop">
        <Td
          w={18}
          onClick={() => {
            navigate(projectPlaceLink)
          }}
          _hover={{ cursor: 'pointer' }}
        >
          {getDateStrFromNumberIfExist(projectPlace.beginAt)}
        </Td>
      </Show>
      <Show above="laptop">
        <Td
          onClick={() => {
            navigate(projectPlaceLink)
          }}
          _hover={{ cursor: 'pointer' }}
        >
          {getDateStrFromNumberIfExist(projectPlace.endAt)}
        </Td>
      </Show>
    </Tr>
  )
}
export default ListProjectPlacesRow
