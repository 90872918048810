import {
  Divider as ChakraDivider,
  Flex,
  ResponsiveValue,
} from '@chakra-ui/react'
import Heading from './Heading'

const Divider: React.FC<{
  orientation?: 'horizontal' | 'vertical'
  h?: number | string
  color?: string
  border?: number | string
  mx?: number | string
  my?: number | string
  mt?: number | string
  mb?: number | string
  pl?: ResponsiveValue<number | string>
  w?: string | number
  title?: string
  textStyle?: string
  flexBasis?: ResponsiveValue<number | string>
}> = ({
  orientation = 'horizontal',
  h,
  color = 'gray.400',
  border,
  mx,
  my,
  mt,
  pl,
  mb,
  w = orientation === 'horizontal' ? '100%' : undefined,
  title,
  textStyle = 'h8',
  flexBasis,
}) => {
  if (my === undefined && orientation === 'horizontal') {
    my = 4
  }
  // Set mx default to 4 if not set - only if vertical
  if (mx === undefined && orientation === 'vertical') {
    mx = 4
  }

  if (orientation === 'vertical') {
    return (
      <ChakraDivider
        orientation={orientation}
        h={h}
        borderColor={color}
        border={border}
        mx={mx}
        my={my}
        mt={mt}
        mb={mb}
        w={w}
      />
    )
  }
  return (
    <Flex flexDir="column" display="flow-root" pl={pl} w={w}>
      {title && (
        <Heading textStyle={textStyle} mb={2} isBold>
          {title}
        </Heading>
      )}
      <ChakraDivider
        orientation={orientation}
        h={h}
        borderColor={color}
        border={border}
        mx={mx}
        my={my}
        mt={mt}
        mb={mb}
        w={w}
        flexBasis={flexBasis}
        flexGrow={1}
        flexShrink={1}
      />
    </Flex>
  )
}
export default Divider
