import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { ingressDetailedPlanAndBuildingPermitPage } from '../../apiClients/contentApiClient'
import headerDetailedPlanAndBuildingPermit from '../../img/pages/headerDetailedPlanAndBuildingPermin.svg'
import { CardDetailPlan } from '../../components/detailedPlanningAndBuildingPermit/DetailedPlanCard'
import { CardBuildingPermit } from '../../components/detailedPlanningAndBuildingPermit/BuildingPermitCard'
const DetailedPlanAndBuildingPermitPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('nav.detailedPlanAndBuildingPermitPage')}</title>
      </Helmet>
      <ContentPageHeader
        label={t('nav.detailedPlanAndBuildingPermitPage').toUpperCase()}
        ingress={ingressDetailedPlanAndBuildingPermitPage}
        backgroundImgSrc={headerDetailedPlanAndBuildingPermit}
        textStyleIngress="inputText"
        isEmbedded
      />
      <Flex flexDir="row" w="100%" maxW="container.xl" mt={8}>
        <Tabs variant="line" w="100%" align="center">
          <TabList w="100%">
            <Tab px="12%">{t('general.detailPlan')}</Tab>
            <Tab px="12%">{t('general.buildingPermit')}</Tab>
          </TabList>

          <TabPanels pt={10}>
            <TabPanel textAlign="left" px={0}>
              <CardDetailPlan px="5%" />
            </TabPanel>
            <TabPanel textAlign="left" px={0}>
              <CardBuildingPermit px="5%" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  )
}
export default DetailedPlanAndBuildingPermitPage
