import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Text, Flex } from '@chakra-ui/react'
import { Dispatch, ReactElement, SetStateAction } from 'react'

const TextCard: React.FC<{
  heading: string
  children?: ReactElement
  mr?: number | string
  mt?: number | string
  mb?: number | string
  variant?: 'plain' | 'border' | 'ghost'
  minW?: string | number
  maxW?: string | number
  px?: string | number
  py?: string | number
  w?: string | number
  headerBg?: string
  color?: string
  gap?: number
  textStyle?: string
  comment?: string
  showComment?: boolean
  setShowComment?: Dispatch<SetStateAction<boolean>>
  border?: string
  flexGrow?: number
  flexShrink?: number
}> = ({
  heading,
  children,
  mr,
  mt,
  mb,
  variant = 'plain',
  minW = 40,
  headerBg = 'white',
  maxW = 'xs',
  py = 2,
  px = 2,
  color = 'black',
  gap = 1.5,
  w = '100%',
  textStyle = 'cardHeading',
  comment,
  showComment,
  setShowComment,
  border,
  flexGrow,
  flexShrink,
}) => {
  switch (variant) {
    case 'border':
      return (
        <Flex
          gap={4}
          wrap="wrap"
          flexDir="row"
          alignItems="left"
          textStyle="cardText"
          mr={mr}
          mt={mt}
          mb={mb}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="lg"
          py={py}
          px={px}
          pos="relative"
          minW={minW}
          w={w}
          maxW={maxW}
          color={color}
        >
          <Text
            textStyle={textStyle}
            pos="absolute"
            px={1}
            left={2}
            top={-3}
            bg={headerBg}
          >
            {heading}
          </Text>
          <Flex>{children}</Flex>
        </Flex>
      )

    default:
      return (
        <Flex
          flexDir="column"
          alignItems="left"
          textStyle="cardText"
          mr={mr}
          mt={mt}
          mb={mb}
          color={color}
          minW={minW}
          gap={gap}
          wrap="wrap"
          border={border}
          flexBasis={w}
          flexGrow={flexGrow}
          flexShrink={flexShrink}
        >
          <Flex alignItems="center" gap={2}>
            {heading && <Text textStyle={textStyle}>{heading}</Text>}
            {comment && (
              <InfoOutlineIcon
                color="primary_orange02"
                _hover={{ cursor: 'pointer', color: 'primary_orange01' }}
                onClick={() => {
                  setShowComment ? setShowComment(!showComment) : ''
                }}
              />
            )}
          </Flex>
          <Flex flexDir="column">{children}</Flex>
        </Flex>
      )
  }
}
export default TextCard
