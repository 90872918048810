import { Box, Flex, Show, Text } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { getDateStrFromDate } from '../../common/util'
import OrganizationAvatar from '../accounts/OrganizationAvatar'
import HelpRequestStatusIcon from './HelpRequestStatusIcon'
import HelpRequestStatusMessage from './HelpRequestStatusMessage'

const HelpRequestConsumerRowCard: React.FC<{
  helpReq: HelpRequestResponse
}> = ({ helpReq }) => {
  const helpReqTemplate = helpReq.helpRequestTemplate
  const { data: project } = useProjectInfo(helpReqTemplate?.projectId)

  return (
    <Flex
      flexDir="column"
      pl={{ xxs: 2, tablet: 4 }}
      // border="1px solid red"
      w="100%"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex
          textStyle="textLarge"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          gap={4}
        >
          <Text
            flexWrap="wrap"
            minW={{ xxs: 32, tablet: 44 }}
            textStyle="textRegular"
            // border="1px solid red"
          >
            {helpReq.name}
          </Text>
          <OrganizationAvatar
            organizationId={helpReq.organizationId}
            variant="avatarAndNameOneRow"
            textStyle="textSmall"
            columnGap={2}
            h={8}
            // border="1px solid red"
            w={{ xxs: 32, tablet: 44 }}
          />
          <Show above="desktop">
            <Text
              textStyle="textSmallRegular"
              w={36}
              overflow="clip"
              // border="1px solid red"
            >
              {project?.name}
            </Text>

            {helpReqTemplate?.helpTimestamp && (
              <Text textStyle="textSmallFixed" w={24}>
                {getDateStrFromDate(new Date(helpReqTemplate.helpTimestamp))}
              </Text>
            )}
            {!helpReqTemplate?.helpTimestamp && <Box w={24}></Box>}
          </Show>
          <HelpRequestStatusIcon helpReq={helpReq} />
        </Flex>
      </Flex>
      <HelpRequestStatusMessage helpReq={helpReq} />
    </Flex>
  )
}
export default HelpRequestConsumerRowCard
