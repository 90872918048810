import { Flex, Text } from '@chakra-ui/react'
import { ArticleInfo } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { getDateStrFromDate } from '../../common/util'
import TagBadge from '../tags/TagBadge'

const ArticleInfoCard: React.FC<{
  article: ArticleInfo | undefined
  variant?: 'SMALL' | 'FULL'
}> = ({ article, variant = 'FULL' }) => {
  const { t } = useTranslation()

  if (!article) {
    return <Text>{t('articles.article.noArticleAvailable')}</Text>
  }

  if (variant === 'SMALL') {
    return (
      <Flex flexDir="column" p={4} ml={2} mr={4} minW="350px" borderRadius={8}>
        <Text mb={4}>{t('articles.articleInformation')}</Text>
        <Text mr={1}>{t('articles.heading')}:</Text>
        <Text mb={4}>{article.heading}</Text>
        <Text>{t('articles.published')}:</Text>
        <Text mb={4}>
          {article.publishedAt
            ? getDateStrFromDate(new Date(article.publishedAt))
            : t('articles.notPublished')}
        </Text>
      </Flex>
    )
  }
  return (
    <Flex
      flexDir="column"
      p={4}
      ml={2}
      mr={4}
      border="2px"
      borderColor="gray.300"
      borderRadius={8}
      boxShadow="md"
      w="100%"
      minW={10}
      maxW={60}
    >
      <Text mb={4}>{`${t('articles.article')} ${article.id}`}</Text>
      <Text mb={4}>"{article.heading}"</Text>
      <Text>{t('articles.published')}:</Text>
      <Text mb={4}>
        {article.publishedAt
          ? new Date(article.publishedAt).toLocaleString('sv-SE')
          : t('articles.notPublished')}
      </Text>
      <Flex flexDir="row" wrap="wrap">
        {article.tags &&
          article.tags.map(tag => {
            return <TagBadge key={tag.id} tag={tag} mb={2} />
          })}
      </Flex>
    </Flex>
  )
}
export default ArticleInfoCard
