import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../ui'
import AddContactButton from '../../components/ui/AddOrganizationCollaboratorButton'
import { useOrganizationCollaborators } from '../../apiClients/organizationsCollaboratorApiClient'
import AuthContext from '../../common/auth-context'
import Divider from '../../components/ui/Divider'
import ExpandableOrganizationCollaboratorCard from '../../components/helpRequests/ExpandableOrganizationCollaboratorCard'

const ProviderContactsSection: React.FC<{
  mx?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  mb?: ResponsiveValue<number | string>
  maxW?: ResponsiveValue<number | string>
}> = ({
  mx = { xxs: '1%', tablet: '5%' },
  px = 4,
  py = 4,
  maxW = 'container.md',
  mb = 32,
}) => {
  const { t } = useTranslation()
  const { currentOrganization } = useContext(AuthContext)
  const { data: organizationCollaborators, mutate } =
    useOrganizationCollaborators(currentOrganization?.id)

  return (
    <Flex
      flexDir="column"
      backgroundColor="white"
      py={py}
      px={px}
      mx={mx}
      bg="white"
      maxW={maxW}
      borderRadius="lg"
      mt={8}
      mb={mb}
    >
      <Flex gap={4}>
        <Heading>
          {t('organizationCollaborators.organizationCollaborators')}
        </Heading>
      </Flex>
      <Divider />
      <Flex flexDir="column">
        {organizationCollaborators &&
          organizationCollaborators?.length > 0 &&
          organizationCollaborators.map(collab => {
            return (
              <ExpandableOrganizationCollaboratorCard
                key={collab.id}
                collaborator={collab}
                mutateOrganizationCollaborators={mutate}
              />
            )
          })}
      </Flex>
      <AddContactButton
        variant={'BUTTON'}
        mt={6}
        w={{ xxs: '100%', tablet: 24 }}
      >
        {t('ui.button.add')}
      </AddContactButton>
    </Flex>
  )
}
export default ProviderContactsSection
