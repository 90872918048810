import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useHelpRequestsByOrganizationId } from '../../../apiClients/projectsApiClient/helpRequests'
import AuthContext from '../../../common/auth-context'
import ExpandableHelpRequestProviderCard from '../../helpRequests/ExpandableHelpRequestProviderCard'

import Heading from '../../ui/Heading'

const ProviderHelpRequestSection: React.FC<{
  mb?: number | string
}> = ({ mb }) => {
  const [search] = useSearchParams()
  const helpRequestId = search.get('id')
  const { currentOrganization } = useContext(AuthContext)
  const { data: allHelpRequests } = useHelpRequestsByOrganizationId(
    currentOrganization?.id,
  )

  /*
  // Provider Statuses
  //
  // | 'New' - unAnswered
  // | 'Draft' - unAnswered
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  //
  // Consumer Statuses
  //
  // | 'Ongoing' - active
  // | 'Accepted' - archived
  // | 'Rejected' - archived
  // | 'Deleted' - archived
  */

  const unAnsweredHelpRequests = allHelpRequests?.filter(helpReq => {
    return (
      helpReq.providerStatus === 'New' || helpReq.providerStatus === 'Draft'
    )
  })
  const activeHelpRequests = allHelpRequests?.filter(helpReq => {
    return helpReq.providerStatus === 'Ongoing'
  })

  const archivedHelpRequests = allHelpRequests?.filter(helpReq => {
    return (
      helpReq.providerStatus === 'Accepted' ||
      helpReq.providerStatus === 'Rejected' ||
      helpReq.providerStatus === 'Deleted'
    )
  })

  return (
    <Flex flexDir="column" mb={mb} rowGap={4}>
      <Heading color="logo_green01" textStyle="h6" isBold ml={2}>
        Obesvarade förfrågningar
      </Heading>
      {unAnsweredHelpRequests &&
        unAnsweredHelpRequests.length > 0 &&
        unAnsweredHelpRequests.map(helpReq => {
          return (
            <ExpandableHelpRequestProviderCard
              helpReq={helpReq}
              key={helpReq.id}
              isExpanded={helpReq.id === helpRequestId ? true : false}
            />
          )
        })}
      {!unAnsweredHelpRequests ||
        (unAnsweredHelpRequests.length === 0 && (
          <Text ml={2} mb={4} textStyle="infoTextLight" fontStyle="italic">
            Du har inga obesvarade förfrågningar.
          </Text>
        ))}
      <Heading color="logo_green01" textStyle="h6" isBold ml={2}>
        Aktiva förfrågningar
      </Heading>
      {activeHelpRequests &&
        activeHelpRequests.length > 0 &&
        activeHelpRequests.map(helpReq => {
          return (
            <ExpandableHelpRequestProviderCard
              helpReq={helpReq}
              key={helpReq.id}
              isExpanded={helpReq.id === helpRequestId ? true : false}
            />
          )
        })}
      {!activeHelpRequests ||
        (activeHelpRequests.length === 0 && (
          <Text ml={2} mb={4} textStyle="infoTextLight" fontStyle="italic">
            Du har inga aktiva förfrågningar.
          </Text>
        ))}

      <Heading color="logo_green01" textStyle="h6" isBold ml={2}>
        Arkiverade förfrågningar
      </Heading>
      {archivedHelpRequests &&
        archivedHelpRequests.length > 0 &&
        archivedHelpRequests.map(helpReq => {
          return (
            <ExpandableHelpRequestProviderCard
              helpReq={helpReq}
              key={helpReq.id}
              isExpanded={helpReq.id === helpRequestId ? true : false}
            />
          )
        })}
      {!archivedHelpRequests ||
        (archivedHelpRequests.length === 0 && (
          <Text ml={2} mb={4} textStyle="infoTextLight" fontStyle="italic">
            Du har inga arkiverade förfrågningar.
          </Text>
        ))}
    </Flex>
  )
}
export default ProviderHelpRequestSection
