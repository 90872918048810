import { Circle, Flex, Text } from '@chakra-ui/react'
import { NoteStatus as Statuses } from '@contracts/misc'
import { useTranslation } from 'react-i18next'

const NoteStatus: React.FC<{
  status: Statuses
  textStyle?: string
  ml?: string | number
}> = ({ status, textStyle = 'textMediumLight', ml }) => {
  const { t } = useTranslation()

  const getColor = (status: Statuses) => {
    switch (status) {
      case 'Approved':
        return 'logo_green01'
      case 'Done':
        return 'logo_petroleum01'
      case 'Draft':
        return 'primary_grey01'
      case 'Ongoing':
        return 'primary_green02'
      case 'Rejected':
        return 'logo_purple'
      case 'Todo':
        return 'primary_orange01'
      default:
        return 'primary_grey01'
    }
  }

  if (status === 'NotSet') {
    return null
  }

  return (
    <Flex alignItems="center" gap={1.5} ml={ml}>
      <Circle bg={getColor(status)} size={3} />
      <Text textStyle={textStyle}>{t(`notes.status.${status}`)}</Text>
    </Flex>
  )
}

export default NoteStatus
