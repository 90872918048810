import { Box, Button, useToast, Text, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { AccountTypeName } from '@contracts/misc'
import { forgotPassword } from '../../apiClients/accountsApiClient'
import Input from '../ui/Input'
import Spinner from '../Spinner'

const ForgotPassword: React.FC<{
  onClose: () => void
  initialEmail?: string
  accountType: AccountTypeName
}> = ({ onClose, initialEmail = '', accountType }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const applicationInsights = useAppInsightsContext()
  const [email, setEmail] = useState(initialEmail)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setIsError(false)
    }
    setEmail(e.currentTarget.value)
  }

  const submitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    forgotPassword(email, accountType)
      .then(() => {
        applicationInsights.trackEvent(
          { name: 'Email to reset password sent' },
          { email },
        )
        toast({
          title: t('accounts.resetPassword.sendEmailSuccessMessage'),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        onClose()
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <form
      onSubmit={e => {
        void submitHandler(e)
      }}
    >
      <Flex flexDir="column" bg="transparent" maxW="36rem" minW="20rem">
        <Text textStyle="h4" my={2}>
          {accountType === 'Consumer'
            ? t('auth.forgotPasswordPrivate')
            : t('auth.forgotPasswordProvider')}
        </Text>
        <Text mb={8}>
          {t('accounts.resetPassword.forgotPasswordDescription')}
        </Text>

        <Flex display="flex" flexDirection="column">
          <Input
            title={t('auth.email')}
            isMandatory={true}
            type="email"
            autoComplete="username"
            value={email}
            onChange={emailChangeHandler}
            position="single"
          />
          {isError && (
            <Box m="0 0 .2rem 1rem" textStyle="errorSmall">
              {t('accounts.resetPassword.errorSendingResetEmail', { email })}
            </Box>
          )}
          <Button
            type="submit"
            margin="1rem 0"
            variant="primary"
            disabled={!(email && email.includes('@'))}
          >
            {t('accounts.resetPassword.sendEmail')}
          </Button>
        </Flex>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}
export default ForgotPassword
