import { Button, useDisclosure, Text, ResponsiveValue } from '@chakra-ui/react'
import { Note } from '@contracts/misc'
import React from 'react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useArea } from '../../common/area-context'
import { IconName } from '../../common/icon-utils'
import log from '../../common/log'
import AddOrEditNote from '../notes/AddOrEditNote'
import AlertDialogUnsavedChanges from './AlertDialogUnsavedChanges'
import { Icon } from './Icon'
import IconButton from './IconButton'
import Modal from './Modal'
// import AddOrEditNoteFiles from '../notes/AddOrEditNoteFiles'

const AddOrEditNoteButton: React.FC<{
  propertyId?: string
  projectId?: string
  mt?: number
  w?: ResponsiveValue<number | string>
  children?: ReactElement
  iconName?: IconName
  iconColor?: string
  note?: Note
  variant?: 'icon' | 'iconButton' | 'button'
  // type?: 'editNote' | 'editNoteFiles'
  buttonStyleVariant?: 'primary' | 'secondary'
  size?: ResponsiveValue<'xs' | 'md' | 'lg'>
  disabled?: boolean
  'data-cy'?: string
  id?: string
  onClose?: () => void
}> = ({
  iconName = 'IoAddCircleOutline',
  iconColor = 'primary_orange02',
  note,
  variant = 'icon',
  w,
  children,
  propertyId,
  projectId,
  buttonStyleVariant = 'primary',
  size = 'md',
  disabled,
  'data-cy': dataCy,
  id,
  onClose: onCloseParent,
  // type = 'editNote',
}) => {
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const [noteHasUnsavedChanges, setNoteHasUnsavedChanges] = useState(false)

  const { t } = useTranslation()

  const closeHandler = (avoidAlertDialog?: boolean) => {
    if (noteHasUnsavedChanges && !avoidAlertDialog) {
      onOpenAlertDialog()
    } else {
      onClose()
      if (onCloseParent) {
        onCloseParent()
      }
    }
  }

  return (
    <>
      <div
        id={id}
        data-cy={dataCy}
        className={cn({
          disabled,
        })}
      >
        {variant === 'icon' && (
          <Icon
            name={iconName}
            _hover={{ color: 'gray' }}
            color={iconColor}
            onClick={onOpen}
            isDisabled={disabled}
          />
        )}
        {variant === 'iconButton' && (
          <IconButton buttonType={'EDIT'} onClick={onOpen} data-cy={dataCy} />
        )}
        {variant === 'button' && (
          <Button
            variant={buttonStyleVariant}
            onClick={onOpen}
            w={w}
            size={size}
            color="white"
            disabled={disabled}
          >
            {children && <Text>{children}</Text>}
            {!children && (
              <Text fontSize={{ xxs: '10px', tablet: '14px', desktop: '20px' }}>
                {t('notes.addNote')}
              </Text>
            )}
          </Button>
        )}
      </div>

      <Modal isOpen={isOpen} onOpen={onOpen} onClose={closeHandler}>
        <>
          <AlertDialogUnsavedChanges
            isOpen={isOpenAlertDialog}
            onClose={onCloseAlertDialog}
            leastDestructiveRef={cancelRef}
            onConfirmClose={onClose}
          />

          <AddOrEditNote
            note={note}
            propertyId={propertyId}
            projectId={projectId}
            onCloseModal={closeHandler}
            onNoteHasUnsavedChanges={setNoteHasUnsavedChanges}
          />
        </>
      </Modal>
    </>
  )
}

export default AddOrEditNoteButton
