import { filter, orderBy } from 'lodash'
import { Organization } from '@contracts/misc'
import { OrganizationSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: OrganizationSortingOptionType,
  organizations?: Organization[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption

  const filteredOrganizations = searchStr
    ? filter(organizations, ({ name, registrationNumber }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return (
          name.toLowerCase().includes(searchStrLowercase) ||
          registrationNumber.toLowerCase().includes(searchStrLowercase)
        )
      })
    : organizations

  return orderBy(filteredOrganizations, sortingField, sortOrder)
}
