import {
  Flex,
  Text,
  Button,
  useToast,
  RadioGroup,
  Radio,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useState } from 'react'
import {
  OrganizationProfile,
  UpdateOrganizationProfileRequest,
} from '@contracts/accounts'
import { Organization, OrganizationAvailability } from '@contracts/misc'

import { KeyedMutator } from 'swr'
import { updateOrganizationProfile } from '../../../apiClients/organizationsApiClient'
import log from '../../../common/log'
import Spinner from '../../Spinner'

import Tiptap from '../../richTextEditor/Tiptap'

// import {
//   addAttachment,
//   deleteAttachment,
// } from '../../../apiClients/attachmentsApiClient'

// import { compressFileAsync } from '../../attachments/attachmentHelper'

// const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public`

const allAvailabilityOptions: OrganizationAvailability[] = [
  'Now',
  'Soon',
  'Later',
  'Latest',
]

interface ProfileValues {
  availability?: OrganizationAvailability
  availabilityComment: string
}

const EditProviderAvailability: React.FC<{
  currentOrganization: Organization
  currentOrganizationProfile: OrganizationProfile
  maxW?: string
  mt?: string | number
  px?: string | number
  mutate?: KeyedMutator<UpdateOrganizationProfileRequest | undefined>
}> = ({
  currentOrganization,
  maxW,
  mt,
  px,
  currentOrganizationProfile,
  mutate,
}) => {
  const { t } = useTranslation()

  const toast = useToast()

  const initialValues: ProfileValues = {
    availability: currentOrganizationProfile.availability,
    availabilityComment: currentOrganizationProfile?.availabilityComment ?? '',
  }

  const [availabilityComment, setavailabilityComment] = useState(
    initialValues.availabilityComment ?? '<p></p>',
  )

  const { handleSubmit, handleChange, isSubmitting } = useFormik({
    initialValues: initialValues,

    onSubmit: (values, { setSubmitting }) => {
      const requestData: UpdateOrganizationProfileRequest = {
        availability: values.availability ?? 'Now',
        availabilityComment: availabilityComment,

        // Just pass in current values
        consultant: currentOrganizationProfile.consultant,
        craftsman: currentOrganizationProfile.craftsman,
        provideRemoteServices: currentOrganizationProfile.provideRemoteServices,
        profileText: currentOrganizationProfile.profileText,
        profileAttachmentId: currentOrganizationProfile?.profileAttachmentId,
        backgroundAttachmentId:
          currentOrganizationProfile?.backgroundAttachmentId,
        serviceTypeIds: currentOrganizationProfile.serviceTypeIds,
      }

      updateOrganizationProfile(currentOrganization.id, requestData)
        .then(res => {
          log.debug('Successfully updated organization profile', res)
          mutate ? void mutate() : ''
          toast({
            title: t('accounts.providers.profile.updateProfileSuccessMessage'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          mutate ? void mutate() : ''
        })
        .catch(err => {
          log.error({ err }, 'Failed to update organization profile data')
          toast({
            title: t('accounts.providers.profile.updateProfileFailMessage'),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const onChangeavailabilityCommentHandler = (text: string) => {
    setavailabilityComment(text)
  }

  return (
    <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
      <Flex
        flexDir="row"
        justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
        wrap="wrap"
      >
        <form onSubmit={handleSubmit} data-cy="add-new-provider">
          <Flex flexDir="column" mb={4}>
            <Flex display="flex" flexDirection="column" mt={3}>
              <Text mt={0} mb={2}>
                {t('providers.profile.availabilityOptions.availabilityHeading')}
              </Text>

              <RadioGroup
                defaultValue={initialValues.availability}
                pl={{ xxs: 2, tablet: 6 }}
              >
                <Flex rowGap={1} wrap="wrap">
                  {allAvailabilityOptions.map(availbilityOption => {
                    return (
                      <Radio
                        key={availbilityOption}
                        value={availbilityOption}
                        name="availability"
                        onChange={e => {
                          handleChange(e)
                        }}
                        w={{ xxs: 40, tablet: 64 }}
                      >
                        {t(
                          `providers.profile.availabilityOptions.${availbilityOption}`,
                        )}
                      </Radio>
                    )
                  })}
                </Flex>
              </RadioGroup>

              <Text mt={6} mb={2}>
                {t('providers.profile.availabilityOptions.commentHeading')}
              </Text>
              <Box w="100%" maxW="container.lg" mr={6}>
                <Tiptap
                  border="1px solid"
                  borderColor="gray.300"
                  content={availabilityComment}
                  w="100%"
                  withTypographyExtension={true}
                  withLinkExtension={true}
                  onChange={onChangeavailabilityCommentHandler}
                  editorLabel={t('notes.addOrUpdateForm.description')}
                  area="Organization"
                  areaId={currentOrganization.id}
                  container={'private'}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onImgAdded={() => {}}
                  toolbarVariant="compact-no-images"
                />
              </Box>
            </Flex>
            <Button type="submit" variant="primary" h={10} mt={4}>
              {t('ui.button.update')}
            </Button>
            <Text fontStyle="italic">
              {t('accounts.providers.profile.viewPreviewText')}
            </Text>
          </Flex>
        </form>
      </Flex>

      {isSubmitting && <Spinner />}
    </Flex>
  )
}
export default EditProviderAvailability
