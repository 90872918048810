import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ProjectPhase } from '@contracts/projects'
import { Box, Flex } from '@chakra-ui/react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { ingressConstructionProcessPage } from '../../apiClients/contentApiClient'
import ConstructionProcessSection from '../../components/sections/portal/ConstructionProcessSection'
import { Icon } from '../../components/ui/Icon'

const ConstructionProcessPage: React.FC<{
  activePhase?: ProjectPhase
  onClose?: () => void
}> = ({ activePhase, onClose }) => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const currentPhase = search.get('projektfas') as ProjectPhase
  return (
    <>
      <Helmet>
        <title>{t('nav.constructionProcessPage')}</title>
      </Helmet>
      <Flex flexDir="column">
        {onClose && (
          <Box pos="absolute" top={4} left={4} bg="gray.300" zIndex="tooltip">
            <Icon name={'AiOutlineClose'} onClick={onClose} />
          </Box>
        )}
        <ContentPageHeader
          label={t('nav.constructionProcessPage').toUpperCase()}
          ingress={ingressConstructionProcessPage}
        />
        {/* <ConstructionProcessSection currentPhase={currentPhase ?? 'Idea'} /> */}
        <ConstructionProcessSection
          currentPhase={currentPhase ?? activePhase}
        />
      </Flex>
    </>
  )
}
export default ConstructionProcessPage
