import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Switch,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { FaSearch } from 'react-icons/fa'
import { useEffect, useMemo, useState } from 'react'
import { Organization } from '@contracts/misc'
import { OrganizationProfile, ServiceType } from '@contracts/accounts'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import Divider from '../../components/ui/Divider'
import { ingressFindExpertPage } from '../../apiClients/contentApiClient'
import {
  PATH_ADMIN_PAGES,
  PATH_ADMIN_PAGES_FIND_EXPERT,
  PATH_MY_PAGES_FIND_EXPERT,
  PATH_MY_PRIVATE_PAGES,
} from '../../common/nav'
import {
  useOrganization,
  useOrganizations,
  useServiceTypes,
} from '../../apiClients/organizationsApiClient'
import ProviderProfileTemplate from '../../components/provider/ProviderProfileTemplate'
import ListProviderProfilesSection from '../../components/sections/portal/ListProviderProfilesSection'
import headerBackground from '../../img/pages/topbarHittaExpert.png'
import { Heading } from '../../ui'

const ProfileWithId = () => {
  const { id: orgId } = useParams()
  const { data: org } = useOrganization(orgId)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isWithinAdminPages = pathname.startsWith(PATH_ADMIN_PAGES)

  if (!org) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{org.name}</title>
      </Helmet>

      <ProviderProfileTemplate
        organization={org}
        onClose={() =>
          navigate(
            isWithinAdminPages
              ? `${PATH_ADMIN_PAGES_FIND_EXPERT}`
              : `${PATH_MY_PAGES_FIND_EXPERT}`,
          )
        }
      />
      <Divider />
    </>
  )
}

const FindExpertPage = () => {
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)
  const { t } = useTranslation()
  const { data: allOrganizations } = useOrganizations()
  const { data: allServiceTypes } = useServiceTypes()

  // Filter out only currently used service types
  // let usedExpertServiceTypes: string[] = []
  // let usedNonExpertServiceTypes: string[] = []

  // const orgProfile = org as unknown as OrganizationProfile

  // allServiceTypes?.map(st => {
  //   if(st.consultant) {
  //     if(st)
  //   }
  // })
  const [allExpertServiceTypes, setAllExpertServiceTypes] = useState<
    ServiceType[]
  >([])
  const [allNonExpertServiceTypes, setAllNonExpertServiceTypes] = useState<
    ServiceType[]
  >([])
  // const allExpertServiceTypes = allServiceTypes?.filter(st => {
  //   return st.consultant
  // })
  // const allNonExpertServiceTypes: ServiceType[] = []
  // const allNonExpertServiceTypes = allServiceTypes?.filter(st => {
  //   return !st.consultant
  // })
  // const availableStatuses: NoteStatus[] = [
  //   'Approved',
  //   'Done',
  //   'Draft',
  //   'Ongoing',
  //   'Rejected',
  //   'Todo',
  //   'NotSet',
  // ]

  const [searchStr, setSearchStr] = useState<string | undefined>()
  const [filteredOrgs, setFilteredOrgs] = useState<Organization[] | undefined>(
    undefined,
  )
  const [filterExpertServiceTypeIds, setFilterExpertServiceTypeIds] = useState<
    string[]
  >(
    allExpertServiceTypes?.map(st => {
      return st.id
    }) ?? [],
  )
  const [filterNonExpertServiceTypeIds, setFilterNonExpertServiceTypeIds] =
    useState<string[]>(
      allNonExpertServiceTypes?.map(st => {
        return st.id
      }) ?? [],
    )
  // eslint-disable-next-line no-console
  // console.log('filterServiceyTypes: ', filterServiceTypeIds)
  const [
    allFilterExpertServiceTypesSelected,
    setAllFilterExpertServiceTypesSelected,
  ] = useState(true)
  const [
    allFilterNonExpertServiceTypesSelected,
    setAllFilterNonExpertServiceTypesSelected,
  ] = useState(true)

  const [filterIsActive, setFilterIsActive] = useState(false)

  useMemo(() => {
    // eslint-disable-next-line no-console
    console.log('in useMEMO')

    const tmpExpertsArray: ServiceType[] = []
    const tmpNonExpertsArray: ServiceType[] = []
    allOrganizations?.map(org => {
      const orgProfile = org as unknown as OrganizationProfile

      orgProfile.serviceTypes?.map(st => {
        const currentServiceType = allServiceTypes?.find(serviceType => {
          return serviceType.id === st.id
        })

        if (currentServiceType?.consultant) {
          if (!tmpExpertsArray?.includes(currentServiceType)) {
            tmpExpertsArray.push(currentServiceType)
          }
        } else if (currentServiceType) {
          if (!tmpNonExpertsArray?.includes(currentServiceType)) {
            tmpNonExpertsArray.push(currentServiceType)
          }
        }
      })
    })
    setAllExpertServiceTypes(tmpExpertsArray)
    // setFilterExpertServiceTypeIds(
    //   tmpExpertsArray.map(st => {
    //     return st.id
    //   }) ?? [],
    // )
    setAllNonExpertServiceTypes(tmpNonExpertsArray)
    setFilteredOrgs(allOrganizations)

    setFilterExpertServiceTypeIds(
      tmpExpertsArray?.map(st => {
        return st.id
      }) ?? [],
    )
    setFilterNonExpertServiceTypeIds(
      tmpNonExpertsArray?.map(st => {
        return st.id
      }) ?? [],
    )

    // eslint-disable-next-line no-console
    console.log('allExpertServiceTypes: ', allExpertServiceTypes)
    // resetFilter()
  }, [allServiceTypes, allOrganizations])

  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('filter changed')

    setFilteredOrgs(
      allOrganizations?.filter(org => {
        const orgProfile = org as unknown as OrganizationProfile

        if (
          searchStr &&
          !org.name.toLowerCase().includes(searchStr.toLowerCase())
        ) {
          return false
        }

        // Does service types filter include any of org service types?
        if (
          orgProfile.serviceTypes?.find(st => {
            if (filterExpertServiceTypeIds.includes(st.id)) {
              return true
            }
          })
        ) {
          return true
        } else if (
          orgProfile.serviceTypes?.find(st => {
            if (filterNonExpertServiceTypeIds.includes(st.id)) {
              return true
            }
          })
        ) {
          return true
        }
        return false
      }),
    )

    if (
      filterExpertServiceTypeIds.length === allExpertServiceTypes?.length &&
      filterNonExpertServiceTypeIds.length ===
        allNonExpertServiceTypes?.length &&
      (!searchStr || !searchStr?.length)
    ) {
      setFilterIsActive(false)
    } else {
      setFilterIsActive(true)
    }
  }, [filterExpertServiceTypeIds, filterNonExpertServiceTypeIds, searchStr])

  const toggleFilterExpertServiceTypeIds = (serviceTypeId: string) => {
    if (
      filterExpertServiceTypeIds.find(stId => {
        return serviceTypeId === stId
      })
    ) {
      // eslint-disable-next-line no-console
      // console.log('Remove id: ', serviceTypeId)
      // remove from array
      setFilterExpertServiceTypeIds(
        filterExpertServiceTypeIds.filter(typeId => {
          return typeId !== serviceTypeId
        }),
      )
    } else {
      // eslint-disable-next-line no-console
      // console.log('Add id: ', serviceTypeId)
      // add to array

      setFilterExpertServiceTypeIds([
        ...filterExpertServiceTypeIds,
        // ...filterNonExpertServiceTypeIds,
        serviceTypeId,
      ])
    }
    // eslint-disable-next-line no-console
    // console.log(filterServiceTypeIds)
  }
  const toggleFilterNonExpertServiceTypeIds = (serviceTypeId: string) => {
    // eslint-disable-next-line no-console
    // console.log('Toggle id: ', serviceTypeId)
    // if (filterTypes?.includes(filterType)) {
    // if (serviceTypes?.includes(serviceTypeName)) {

    if (
      filterNonExpertServiceTypeIds.find(stId => {
        return serviceTypeId === stId
      })
    ) {
      // eslint-disable-next-line no-console
      // console.log('Remove id: ', serviceTypeId)
      // remove from array
      setFilterNonExpertServiceTypeIds(
        filterNonExpertServiceTypeIds.filter(typeId => {
          return typeId !== serviceTypeId
        }),
      )
    } else {
      // eslint-disable-next-line no-console
      // console.log('Add id: ', serviceTypeId)
      // add to array

      setFilterNonExpertServiceTypeIds([
        ...filterNonExpertServiceTypeIds,
        // ...filterNonExpertServiceTypeIds,
        serviceTypeId,
      ])
    }
    // eslint-disable-next-line no-console
    // console.log(filterServiceTypeIds)
  }

  const resetFilter = () => {
    setFilterIsActive(false)

    setAllFilterExpertServiceTypesSelected(true)
    setAllFilterNonExpertServiceTypesSelected(true)
    setFilterExpertServiceTypeIds(
      allExpertServiceTypes?.map(st => {
        return st.id
      }) ?? [],
    )
    setFilterNonExpertServiceTypeIds(
      allNonExpertServiceTypes?.map(st => {
        return st.id
      }) ?? [],
    )
    setSearchStr('')
  }

  const toggleAllFilterExpertServiceTypeIds = () => {
    // if (filterServiceTypeIds?.length) {
    if (allFilterExpertServiceTypesSelected) {
      setFilterExpertServiceTypeIds([])
    } else {
      setFilterExpertServiceTypeIds(
        allExpertServiceTypes?.map(st => {
          return st.id
        }) ?? [],
      )
    }
    // eslint-disable-next-line no-console
    // console.log(filterServiceTypeIds)
    setAllFilterExpertServiceTypesSelected(!allFilterExpertServiceTypesSelected)
  }
  const toggleAllFilterNonExpertServiceTypeIds = () => {
    // if (filterServiceTypeIds?.length) {
    if (allFilterNonExpertServiceTypesSelected) {
      setFilterNonExpertServiceTypeIds([])
    } else {
      setFilterNonExpertServiceTypeIds(
        allNonExpertServiceTypes?.map(st => {
          // allServiceTypes?.map(st => {
          return st.id
        }) ?? [],
      )
    }
    // eslint-disable-next-line no-console
    // console.log(filterServiceTypeIds)
    setAllFilterNonExpertServiceTypesSelected(
      !allFilterNonExpertServiceTypesSelected,
    )
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>
                  {t('portal.sidebar.menu.climateSmartConstruction')}
                </title>
              </Helmet>
              <ContentPageHeader
                label={t('portal.sidebar.menu.findExpert').toUpperCase()}
                ingress={ingressFindExpertPage}
                mb={isWithinMyPages ? 6 : 0}
                isEmbedded={isWithinMyPages ? false : true}
                backgroundImgSrc={headerBackground}
                bgSize="cover"
              />
            </>
          }
        />
        <Route path="/:id/" element={<ProfileWithId />} />
      </Routes>

      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="flex-start"
        columnGap={4}
        wrap="wrap"
        // border="1px solid red"
        my={4}
        maxW="container.xl"
        mx={{ xxs: 1, tablet: '5%' }}
      >
        <Flex
          flexDir="row"
          justifyContent="left"
          alignItems="center"
          columnGap={4}
          mt={2}
          wrap="wrap"
          // border="1px solid green"
        >
          {/* Text search */}
          <InputGroup w={{ xxs: 'xs', tablet: 'sm' }}>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" size={12} />}
            />
            <Input
              type="text"
              placeholder="Sök företagsnamn"
              // defaultValue={searchStr}
              value={searchStr}
              borderColor="gray.400"
              height={10}
              onChange={e => {
                setSearchStr(e.target.value)
                setFilterIsActive(true)
              }}
            />
          </InputGroup>
        </Flex>
        <Flex flexDir="column" alignItems="flex-end" gap={3}>
          <Flex
            flexDir="row"
            justifyContent="right"
            alignItems="center"
            columnGap={4}
            mt={2}
            wrap="wrap"
            // border="1px solid red"
          >
            {/* Filter note types */}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="View options"
                icon={
                  <>
                    <Text ml={2}>Tjänster</Text>
                    <ChevronDownIcon ml={2} mr={2} boxSize={6} />
                  </>
                }
                variant="outline"
                bg="logo_purple"
                _hover={{ bg: 'logo_pink' }}
                _active={{ bg: 'logo_pink' }}
                color="white"
                h={8}
              />

              <MenuList fontSize="16px" px={4}>
                {/* Expert services */}
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading textStyle="h8" isBold>
                    Konsult/Projektör
                  </Heading>
                </FormControl>
                <MenuDivider m={0.5} />
                <FormControl
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormLabel
                    htmlFor="expert"
                    // border="1px solid blue"
                    p={0}
                    m={0}
                    fontWeight="bold"
                  >
                    {t('general.all')}
                  </FormLabel>
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={allFilterExpertServiceTypesSelected}
                    // isChecked={
                    //   filterTypes?.length === noteTypes?.length ? true : false
                    // }
                    onChange={() => {
                      toggleAllFilterExpertServiceTypeIds()
                    }}
                  />
                </FormControl>

                {allExpertServiceTypes?.map(serviceType => {
                  return (
                    <FormControl
                      key={serviceType.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                    >
                      <FormLabel htmlFor="sms-alerts" mb="0">
                        {
                          allServiceTypes?.find(st => {
                            return st.id === serviceType.id
                          })?.name
                        }
                      </FormLabel>
                      <Switch
                        size="md"
                        colorScheme="teal"
                        isChecked={
                          !filterExpertServiceTypeIds ||
                          filterExpertServiceTypeIds.includes(serviceType.id)
                            ? true
                            : false
                        }
                        // isChecked={false}
                        onChange={() => {
                          toggleFilterExpertServiceTypeIds(serviceType.id)
                        }}
                      />
                    </FormControl>
                  )
                })}

                {/* Non-experts services */}
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading textStyle="h8" isBold mt={6}>
                    Hantverkare
                  </Heading>
                </FormControl>
                <MenuDivider m={0.5} />
                <FormControl
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormLabel
                    htmlFor="expert"
                    // border="1px solid blue"
                    p={0}
                    m={0}
                    fontWeight="bold"
                  >
                    {t('general.all')}
                  </FormLabel>
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={allFilterNonExpertServiceTypesSelected}
                    // isChecked={
                    //   filterTypes?.length === noteTypes?.length ? true : false
                    // }
                    onChange={() => {
                      toggleAllFilterNonExpertServiceTypeIds()
                    }}
                  />
                </FormControl>
                {/* {filterServiceTypeIds?.map(serviceTypeId => { */}
                {allNonExpertServiceTypes?.map(serviceType => {
                  return (
                    <FormControl
                      key={serviceType.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                    >
                      <FormLabel htmlFor="sms-alerts" mb="0">
                        {
                          allServiceTypes?.find(st => {
                            return st.id === serviceType.id
                          })?.name
                        }
                      </FormLabel>
                      <Switch
                        size="md"
                        colorScheme="teal"
                        isChecked={
                          !filterNonExpertServiceTypeIds ||
                          filterNonExpertServiceTypeIds.includes(serviceType.id)
                            ? true
                            : false
                        }
                        // isChecked={false}
                        onChange={() => {
                          toggleFilterNonExpertServiceTypeIds(serviceType.id)
                        }}
                      />
                    </FormControl>
                  )
                })}
              </MenuList>
            </Menu>

            {/* <Menu>
              <MenuButton
                as={IconButton}
                aria-label="View options"
                icon={
                  <>
                    <Text ml={2}>{t('notes.notesStatus')} 222</Text>
                    <ChevronDownIcon ml={2} mr={2} boxSize={6} />
                  </>
                }
                variant="outline"
                bg="logo_purple"
                _hover={{ bg: 'logo_pink' }}
                _active={{ bg: 'logo_pink' }}
                color="white"
                h={8}
              />

              <MenuList fontSize="16px" px={4}>
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  
                  <Text>Hej</Text>
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={
                      filterStatuses?.length === availableStatuses?.length
                        ? true
                        : false
                    }
                    onChange={() => {
                      // toggleAllFilterStatuses()
                    }}
                  />
                </FormControl>
                {availableStatuses.map(status => {
                  return (
                    <FormControl
                      key={status}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                    >
                      <FormLabel htmlFor="sms-alerts" mb="0">
                        {t(`notes.status.${status}`)}
                      </FormLabel>
                      <Switch
                        size="md"
                        colorScheme="teal"
                        isChecked={
                          !filterStatuses || filterStatuses.includes(status)
                            ? true
                            : false
                        }
                        onChange={() => {
                          // toggleFilterStatuses(status)
                        }}
                      />
                    </FormControl>
                  )
                })}
              </MenuList>
            </Menu>
            
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="View options"
                icon={<ChevronDownIcon ml={2} mr={2} boxSize={6} />}
                variant="outline"
                p={1}
                bg="logo_purple"
                color="white"
                _hover={{ bg: 'logo_pink' }}
                _active={{ bg: 'logo_pink' }}
                h={8}
              />

              <MenuList fontSize="16px">
                <MenuItem
                  icon={<CheckIcon />}
                  onClick={() => {
                    // setViewOption('compact')
                  }}
                >
                  {t('notes.compactView')}
                </MenuItem>
                <MenuItem
                  icon={<CheckIcon />}
                  onClick={() => {
                    // setViewOption('micro')
                  }}
                >
                  {t('notes.microView')}
                </MenuItem>
              </MenuList>
            </Menu> */}
          </Flex>
          {filterIsActive && (
            <Button
              size="xs"
              bg="primary_grey04"
              color="white"
              _hover={{ bg: 'primary_grey02' }}
              onClick={resetFilter}
            >
              Rensa filter
            </Button>
          )}
        </Flex>
      </Flex>

      <ListProviderProfilesSection
        organizations={filteredOrgs}
        mx={{ xxs: 0, tablet: '5%' }}
        mb={40}
      />
    </>
  )
}
export default FindExpertPage
