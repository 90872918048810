import { useTranslation } from 'react-i18next'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import Heading from '../ui/Heading'
import { useMultipleAttachments } from '../../apiClients/attachmentsApiClient'
import Modal from '../ui/Modal'
import AttachmentCardCompact from './AttachmentsCardCompact'
import AttachmentCardCompactVersioned from './AttachmentCardCompactVersioned'
import { groupBy } from './attachmentHelper'

const FileManagerCard: React.FC<{
  attachmentIds: string[]

  mt?: number
  ml?: number
  mb?: number
  mr?: number
  my?: number
}> = ({ attachmentIds, mt, mr, mb, ml, my }) => {
  const { t } = useTranslation()
  const { data: attachments } = useMultipleAttachments(attachmentIds)

  const {
    isOpen: isInfoModalOpen,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()

  const { files: groupedFiles, images: groupedImages } = groupBy(
    attachments ?? [],
  )

  return (
    <>
      <Flex flexDir="column" mt={mt} mr={mr} mb={mb} ml={ml} my={my}>
        <Flex
          gap={2}
          alignItems="center"
          onClick={() => {
            onOpenInfoModal()
          }}
        >
          <Heading textStyle="cardHeading">
            {t('attachments.attachedFiles')}
          </Heading>
          <InfoOutlineIcon
            fontSize={14}
            color="logo_orange"
            _hover={{ cursor: 'pointer', color: 'orange' }}
          />
        </Flex>
        {!attachmentIds?.length && (
          <Text textStyle="cardText">{t('attachments.noAttachedFiles')}</Text>
        )}

        {groupedFiles?.map(attachments => {
          if (attachments.length === 1) {
            return (
              <AttachmentCardCompact
                attachment={attachments[0]}
                key={attachments[0].id}
              />
            )
          }
          if (attachments.length) {
            return (
              <AttachmentCardCompactVersioned
                attachments={attachments}
                key={attachments[0].id}
              />
            )
          }
          return null
        })}
        {groupedImages?.map(attachments => {
          if (attachments.length === 1) {
            return (
              <AttachmentCardCompact
                attachmentId={attachments[0].id}
                key={attachments[0].id}
              />
            )
          }
          if (attachments.length) {
            return (
              <AttachmentCardCompactVersioned
                attachments={attachments}
                key={attachments[0].id}
              />
            )
          }
          return null
        })}
      </Flex>
      <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {t('attachments.fileManagerInfoTitle')}
          </Heading>

          <Text textStyle="infoTextLight" fontStyle="italic">
            {t('attachments.fileManagerInfoText')}
          </Text>
        </Flex>
      </Modal>
    </>
  )
}
export default FileManagerCard
