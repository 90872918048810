import {
  Divider,
  Flex,
  HStack,
  Text,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useMediaQuery,
  Tooltip,
  Switch,
  FormLabel,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Note, NoteStatus } from '@contracts/misc'
import { CheckIcon, ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { FaSearch } from 'react-icons/fa'
import NotesListView from '../../notes/NotesListView'
import AddNoteButton from '../../ui/AddOrEditNoteButton'
import { Icon } from '../../ui/Icon'
import promoPropertyNotes from '../../../img/properties/noPropertyNotesPromo.png'
import promoProjectNotes from '../../../img/projects/noProjectNotesPromo.png'
import {
  useNotes as usePropertyNotes,
  useNoteTypes as usePropertyNoteTypes,
} from '../../../apiClients/propertiesApiClient'
import {
  useNotes as useProjectNotes,
  useNoteTypes as useProjectNoteTypes,
} from '../../../apiClients/projectsApiClient'
import log from '../../../common/log'
import { useArea } from '../../../common/area-context'

import { useCollaboratorParts } from '../../collaborators/collaboratorHelper'
import NotesTilesView from '../../notes/NotesTilesView'
import PromoCardSimple from '../../ui/PromoCardSimple'
import { calculateSortedItemsWrapper } from '../../notes/notesListUtils'
import { NoteSortingOption } from '../../../common/enums'

const MyNotesSection: React.FC<{
  propertyId?: string
  projectId?: string
  mx?: number | string
  mb?: number | string
}> = ({ propertyId, projectId, mx, mb }) => {
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const { t } = useTranslation()
  const noteArea = useArea()

  const { data: propertyNoteTypes } = usePropertyNoteTypes()
  const { data: projectNoteTypes } = useProjectNoteTypes()

  const [viewOption, setViewOption] = useState<'list' | 'compact' | 'micro'>(
    isTabletOrSmaller ? 'micro' : 'compact',
  )

  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const { data: propertyNotes } = usePropertyNotes(propertyId)
  const { data: projectNotes } = useProjectNotes(projectId)

  let nrOfNotes: number
  if (noteArea.area === 'property') {
    nrOfNotes = propertyNotes?.length ?? 0
  } else {
    nrOfNotes = projectNotes?.length ?? 0
  }

  const { currentUserCanEdit } = useCollaboratorParts({
    propertyId: propertyId,
    projectId: projectId,
  })

  const noteTypes = useMemo(() => {
    if (noteArea.area === 'property') {
      return (
        propertyNoteTypes?.map(noteType => {
          return { id: noteType.id, name: noteType.name }
        }) ?? []
      )
    } else {
      return (
        projectNoteTypes?.map(noteType => {
          return { id: noteType.id, name: noteType.name }
        }) ?? []
      )
    }
  }, [propertyNoteTypes, projectNoteTypes])

  const availableStatuses: NoteStatus[] = [
    'Approved',
    'Done',
    'Draft',
    'Ongoing',
    'Rejected',
    'Todo',
    'NotSet',
  ]

  const [searchStr, setSearchStr] = useState<string | undefined>()
  const [filterTypes, setFilterTypes] = useState<string[]>([])
  const [filterStatuses, setFilterStatuses] =
    useState<string[]>(availableStatuses)

  const findCommonElements = (array1: string[], array2: string[]) => {
    return array1.some(item => array2.includes(item))
  }

  useEffect(() => {
    if (isTabletOrSmaller) {
      setViewOption('micro')
    } else {
      setViewOption('compact')
    }
  }, [isTabletOrSmaller])

  useEffect(() => {
    setFilterTypes(
      noteTypes.map(noteType => {
        return noteType.id
      }),
    )
  }, [noteTypes])

  let allNotes: Note[] = []

  const filteredNotes = useMemo(() => {
    allNotes = [...(propertyNotes ?? []), ...(projectNotes ?? [])]
    let filteredNotes = allNotes

    if (searchStr?.length) {
      filteredNotes = calculateSortedItemsWrapper(
        NoteSortingOption.TIMESTAMP_DESC,
        allNotes,
        searchStr,
      ) as Note[]
    }

    if (filterTypes.length) {
      filteredNotes = filteredNotes.filter(note => {
        return findCommonElements(note.noteTypeIds, filterTypes)
      })
    } else {
      filteredNotes = []
    }
    if (filterStatuses.length) {
      filteredNotes = filteredNotes.filter(note => {
        return filterStatuses.includes(note.status ?? 'NotSet')
      })
    } else {
      filteredNotes = []
    }
    return filteredNotes
  }, [propertyNotes, projectNotes, searchStr, filterTypes, filterStatuses])

  const toggleFilterTypes = (filterType: string) => {
    if (filterTypes?.includes(filterType)) {
      // remove from array
      setFilterTypes(
        filterTypes.filter(type => {
          return type !== filterType
        }),
      )
    } else {
      // add to array
      setFilterTypes([...(filterTypes ?? []), filterType])
    }
  }

  const toggleFilterStatuses = (filterStatus: string) => {
    if (filterStatuses?.includes(filterStatus)) {
      // remove from array
      setFilterStatuses(
        filterStatuses.filter(status => {
          return status !== filterStatus
        }),
      )
    } else {
      // add to array
      setFilterStatuses([...(filterStatuses ?? []), filterStatus])
    }
  }

  const toggleAllFilterTypes = () => {
    if (filterTypes?.length) {
      setFilterTypes([])
    } else {
      setFilterTypes(
        noteTypes.map(noteType => {
          return noteType.id
        }),
      )
    }
  }

  const toggleAllFilterStatuses = () => {
    if (filterStatuses?.length) {
      setFilterStatuses([])
    } else {
      setFilterStatuses(
        availableStatuses.map(status => {
          return status
        }),
      )
    }
  }

  return (
    <Flex flexDir="column" w="100%" pt={6} mb={mb}>
      <Flex flexDir="column" mx={mx}>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          fontSize={24}
          color="primary_grey04"
          mr={4}
        >
          <HStack>
            <Tooltip
              hasArrow
              isDisabled={currentUserCanEdit}
              label={
                noteArea.area === 'property'
                  ? t('properties.propertyForm.canNotEditProperty')
                  : t('projects.canNotEditProject')
              }
            >
              <Flex flexDir="row" gap={2} alignItems="center">
                <Text textStyle="textRegular">
                  {noteArea.area === 'property'
                    ? t('notes.propertyNotes')
                    : t('notes.projectNotes')}
                </Text>
                {!currentUserCanEdit && (
                  <Icon name="WarningTriangle" h={20} color="orange.500" />
                )}
              </Flex>
            </Tooltip>

            <AddNoteButton
              id="over-list-add-note-button"
              propertyId={propertyId}
              projectId={projectId}
              disabled={!currentUserCanEdit}
            />
          </HStack>
        </Flex>

        <Divider borderColor="black" mt={2} />

        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          columnGap={4}
          mt={2}
          wrap="wrap"
        >
          <Flex
            flexDir="row"
            justifyContent="left"
            alignItems="center"
            columnGap={4}
            mt={2}
            wrap="wrap"
          >
            {/* Text search */}
            <InputGroup w={{ xxs: 'xs', tablet: 'sm' }}>
              <InputLeftElement
                pointerEvents="none"
                children={<FaSearch color="gray.200" size={12} />}
              />
              <Input
                type="text"
                placeholder={
                  noteArea.area === 'property'
                    ? t('notes.searchPropertyNotes')
                    : t('notes.searchProjectNotes')
                }
                borderColor="gray.400"
                height={10}
                onChange={e => setSearchStr(e.target.value)}
              />
            </InputGroup>
          </Flex>
          <Flex
            flexDir="row"
            justifyContent="right"
            alignItems="center"
            columnGap={4}
            mt={2}
            wrap="wrap"
          >
            {/* Filter note types */}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="View options"
                icon={
                  <>
                    <Text ml={2}>{t('notes.notesType')}</Text>
                    <ChevronDownIcon ml={2} mr={2} boxSize={6} />
                  </>
                }
                variant="outline"
                bg="logo_purple"
                _hover={{ bg: 'logo_pink' }}
                _active={{ bg: 'logo_pink' }}
                color="white"
                h={8}
              />

              <MenuList fontSize="16px" px={4}>
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormLabel htmlFor="email-alerts">
                    {t('general.all')}
                  </FormLabel>
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={
                      filterTypes?.length === noteTypes?.length ? true : false
                    }
                    onChange={() => {
                      toggleAllFilterTypes()
                    }}
                  />
                </FormControl>
                {noteTypes?.map(noteType => {
                  return (
                    <FormControl
                      key={noteType.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      my={2}
                    >
                      <FormLabel htmlFor="sms-alerts" mb="0">
                        {noteType.name}
                      </FormLabel>
                      <Switch
                        size="md"
                        colorScheme="teal"
                        isChecked={
                          !filterTypes || filterTypes.includes(noteType.id)
                            ? true
                            : false
                        }
                        onChange={() => {
                          toggleFilterTypes(noteType.id)
                        }}
                      />
                    </FormControl>
                  )
                })}
              </MenuList>
            </Menu>

            {/* Filter note status */}
            {noteArea.area === 'project' && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="View options"
                  icon={
                    <>
                      <Text ml={2}>{t('notes.notesStatus')}</Text>
                      <ChevronDownIcon ml={2} mr={2} boxSize={6} />
                    </>
                  }
                  variant="outline"
                  bg="logo_purple"
                  _hover={{ bg: 'logo_pink' }}
                  _active={{ bg: 'logo_pink' }}
                  color="white"
                  h={8}
                />

                <MenuList fontSize="16px" px={4}>
                  <FormControl
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormLabel htmlFor="email-alerts" mb="0">
                      {t('general.all')}
                    </FormLabel>
                    <Switch
                      size="md"
                      colorScheme="teal"
                      isChecked={
                        filterStatuses?.length === availableStatuses?.length
                          ? true
                          : false
                      }
                      onChange={() => {
                        toggleAllFilterStatuses()
                      }}
                    />
                  </FormControl>
                  {availableStatuses.map(status => {
                    return (
                      <FormControl
                        key={status}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={2}
                      >
                        <FormLabel htmlFor="sms-alerts" mb="0">
                          {t(`notes.status.${status}`)}
                        </FormLabel>
                        <Switch
                          size="md"
                          colorScheme="teal"
                          isChecked={
                            !filterStatuses || filterStatuses.includes(status)
                              ? true
                              : false
                          }
                          onChange={() => {
                            toggleFilterStatuses(status)
                          }}
                        />
                      </FormControl>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="View options"
                icon={
                  <>
                    {viewOption === 'list' && (
                      <Icon name="BsList" h={20} ml={2} />
                    )}
                    {viewOption === 'compact' && (
                      <Icon name="BsGrid" h={20} ml={2} />
                    )}
                    {viewOption === 'micro' && (
                      <Icon name="MinitureGrid" h={20} ml={2} />
                    )}
                    {/* <Icon name="sorter" h={10} ml={2} mr={2} /> */}
                    <ChevronDownIcon ml={2} mr={2} boxSize={6} />
                  </>
                }
                variant="outline"
                p={1}
                bg="logo_purple"
                color="white"
                _hover={{ bg: 'logo_pink' }}
                _active={{ bg: 'logo_pink' }}
                h={8}
              />

              <MenuList fontSize="16px">
                <MenuItem
                  icon={
                    <CheckIcon
                      color={viewOption === 'list' ? 'green' : 'transparent'}
                    />
                  }
                  onClick={() => {
                    setViewOption('list')
                  }}
                >
                  {t('notes.listView')}
                </MenuItem>
                <MenuItem
                  icon={
                    <CheckIcon
                      color={viewOption === 'compact' ? 'green' : 'transparent'}
                    />
                  }
                  onClick={() => {
                    setViewOption('compact')
                  }}
                >
                  {t('notes.compactView')}
                </MenuItem>
                <MenuItem
                  icon={
                    <CheckIcon
                      color={viewOption === 'micro' ? 'green' : 'transparent'}
                    />
                  }
                  onClick={() => {
                    setViewOption('micro')
                  }}
                >
                  {t('notes.microView')}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        {allNotes.length != filteredNotes.length && (
          <Text mt={2} textStyle="textMedium">
            {noteArea.area === 'property' &&
              t('notes.searchInfoPropertyNotes', {
                total: nrOfNotes,
                filtered: filteredNotes.length,
              })}
            {noteArea.area === 'project' &&
              t('notes.searchInfoProjectNotes', {
                total: nrOfNotes,
                filtered: filteredNotes.length,
              })}
          </Text>
        )}
      </Flex>
      {!filteredNotes.length && (
        <Box pos="relative" mx={mx}>
          {noteArea.area === 'property' && (
            <>
              {allNotes.length !== 0 && (
                <Flex flexDir="row" mt={4} alignItems="center">
                  <>
                    <InfoOutlineIcon color="logo_orange" fontSize={24} mr={4} />
                    <Text color="logo_orange" textStyle="textRegular" w="sm">
                      {t('notes.noPropertyNotesFiltered')}
                    </Text>
                  </>
                </Flex>
              )}
              <PromoCardSimple
                bg="transparent"
                variant={isTabletOrSmaller ? 'wide' : 'full'}
                noBorder
              >
                <Image src={promoPropertyNotes} mt={6} />
              </PromoCardSimple>
            </>
          )}
          {noteArea.area === 'project' && (
            <>
              {allNotes.length !== 0 && (
                <Flex flexDir="row" mt={4} alignItems="center">
                  <InfoOutlineIcon color="logo_orange" fontSize={24} mr={4} />
                  <Text color="logo_orange" textStyle="textRegular" w="sm">
                    {t('notes.noProjectNotesFiltered')}
                  </Text>
                </Flex>
              )}
              <PromoCardSimple
                bg="transparent"
                variant={isTabletOrSmaller ? 'wide' : 'full'}
                noBorder
              >
                <Image src={promoProjectNotes} mt={6} w="4xl" />
              </PromoCardSimple>
            </>
          )}
        </Box>
      )}
      {viewOption === 'list' && (
        <NotesListView
          propertyId={propertyId}
          projectId={projectId}
          filteredNotes={filteredNotes}
          mx={mx}
        />
      )}
      {viewOption === 'compact' && (
        <NotesTilesView
          propertyId={propertyId}
          projectId={projectId}
          filteredNotes={filteredNotes}
          variant="compact"
          mx={mx}
        />
      )}
      {viewOption === 'micro' && (
        <NotesTilesView
          propertyId={propertyId}
          projectId={projectId}
          filteredNotes={filteredNotes}
          variant="micro"
          mx={mx}
        />
      )}
    </Flex>
  )
}
export default MyNotesSection
