import { Box, Button } from '@chakra-ui/react'
import { ReactElement, useEffect } from 'react'
import { useFilePicker } from 'use-file-picker'
import { IconName } from '../../common/icon-utils'
import {
  IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
  IMG_PICKER_MAX_FILESIZE,
} from '../../pages/constants'
import { Icon } from './Icon'

const SimpleImagePickerButton: React.FC<{
  variant: 'ICON' | 'BUTTON'
  mt?: number
  mb?: number
  ml?: number
  w?: number | string
  iconName?: IconName
  iconColor?: string
  children?: ReactElement | string
  h?: number
  label?: string
  transform?: string

  onAddLocalImage?: (localImage: File) => void
}> = ({
  mt,
  mb,
  ml,
  children,
  w,
  h,
  iconName = 'FiCamera',
  iconColor = 'primary_orange02',
  label,
  variant,
  onAddLocalImage,
  transform,
}) => {
  const [openFileSelector, { plainFiles }] = useFilePicker({
    multiple: true,
    accept: IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
    limitFilesConfig: {
      max: 1,
    },
    readAs: 'DataURL',
    maxFileSize: IMG_PICKER_MAX_FILESIZE,
  })

  useEffect(() => {
    if (plainFiles.length > 0) {
      if (onAddLocalImage) {
        onAddLocalImage(plainFiles[0])
      }
    }
  }, [plainFiles])

  const onClickHandler = () => {
    openFileSelector()
  }

  const getUIComponent = () => {
    if (variant === 'ICON') {
      return (
        <Box
          bg="white"
          w={10}
          h={10}
          border="1px"
          borderRadius="full"
          borderColor="gray.500"
          color="gray.500"
          p="7px"
          zIndex="tooltip"
          transform={transform}
        >
          <Icon
            name={iconName}
            _hover={{ color: 'primary_orange02' }}
            color={iconColor}
            onClick={onClickHandler}
            ml={ml}
            h={24}
          />
        </Box>
      )
    }
    return (
      <Button
        onClick={onClickHandler}
        mt={mt}
        mb={mb}
        variant="secondary"
        w={w || 32}
        h={h}
        transform={transform}
      >
        {children}
        {label}
      </Button>
    )
  }
  return <>{getUIComponent()}</>
}
export default SimpleImagePickerButton
