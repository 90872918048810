import { useContext } from 'react'
import { Box, Flex, Show, useDisclosure } from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'

import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'

import AuthContext from '../../common/auth-context'
import Modal from '../../components/ui/Modal'
import AddProperty from '../../components/properties/AddProperty'
import AddProject from '../../components/projects/AddProject'
import { useProperties } from '../../apiClients/propertiesApiClient'

import { Link } from '../../ui'
import ProfileAvatarMenu from '../ProfileAvatarMenu'
import {
  PATH_ADMIN_PAGES,
  PATH_MY_PRIVATE_PAGES,
  PATH_MY_PAGES_PROFILE,
  PATH_MY_PROJECTS,
  PATH_MY_PROPERTIES,
  PATH_MY_PROVIDER_PAGES,
} from '../../common/nav'
import MessagesMenu from '../NotificationMenu'
import MenuDrawerPrivate from './MenuDrawerConsumer'
import MenuDrawerProvider from './MenuDrawerProvider'

const HeaderNavbarPortal: React.FC<{
  showHamburger: boolean
  isAdminPortal?: boolean
}> = ({ showHamburger, isAdminPortal = false }) => {
  const { isAdmin, isConsumer, isProvider } = useContext(AuthContext)
  const { t } = useTranslation()
  const { data: properties } = useProperties()
  const { pathname } = useLocation()

  let hasPageWithImageHeader = false
  if (
    pathname.includes(PATH_MY_PROPERTIES) ||
    pathname.includes(PATH_MY_PROJECTS) ||
    pathname.includes(PATH_MY_PAGES_PROFILE)
  ) {
    hasPageWithImageHeader = true
  }

  const {
    isOpen: isOpenMenuDrawer,
    onOpen: onOpenMenuDrawer,
    onClose: onCloseMenuDrawer,
  } = useDisclosure()
  const {
    isOpen: isOpenAddPropertyModal,
    onOpen: onOpenAddPropertyModal,
    onClose: onCloseAddPropertyModal,
  } = useDisclosure()
  const {
    isOpen: isOpenAddProjectModal,
    onOpen: onOpenAddProjectModal,
    onClose: onCloseAddProjectModal,
  } = useDisclosure()

  const onAddPropertyHandler = () => {
    onOpenAddPropertyModal()
  }

  return (
    <>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        w="100vw"
        position="fixed"
        top={0}
        right={0}
        zIndex={1}
      >
        {showHamburger && (
          <Box
            fontStyle="h1"
            zIndex="docked"
            color="gray.800"
            ml={4}
            borderRadius="md"
            p={1}
            bg={hasPageWithImageHeader ? 'gray.400' : ''}
            opacity={0.6}
            _hover={{ color: 'gray.500' }}
            pos="absolute"
            top={2}
          >
            <GiHamburgerMenu fontSize={32} onClick={onOpenMenuDrawer} />
          </Box>
        )}
        <Flex
          flexDir="row"
          justifyItems="right"
          pos="absolute"
          right={0}
          top={2}
          alignItems="center"
          justifyContent="right"
          mr={4}
          // columnGap={4}
        >
          {isAdmin && (
            <Show above="tablet">
              {isAdminPortal ? (
                <Box
                  bg={hasPageWithImageHeader ? 'gray.400' : ''}
                  opacity={0.6}
                  px={3}
                  py={0.5}
                  borderRadius="full"
                >
                  {isProvider ? (
                    <Link to={PATH_MY_PROVIDER_PAGES} zIndex="overlay">
                      {t('general.myProviderPages')}
                    </Link>
                  ) : (
                    <Link to={PATH_MY_PRIVATE_PAGES} zIndex="overlay">
                      {t('general.myPages')}
                    </Link>
                  )}
                </Box>
              ) : (
                <Box
                  bg={hasPageWithImageHeader ? 'gray.400' : ''}
                  opacity={0.6}
                  px={3}
                  py={0.5}
                  borderRadius="full"
                  mr={4}
                >
                  <Link to={PATH_ADMIN_PAGES} zIndex="overlay">
                    {t('general.admin')}
                  </Link>
                </Box>
              )}
            </Show>
          )}
          <MessagesMenu />
          <ProfileAvatarMenu />
        </Flex>
      </Flex>
      {isProvider && (
        <MenuDrawerProvider
          isOpenMenuDrawer={isOpenMenuDrawer}
          onOpenMenuDrawer={onOpenMenuDrawer}
          onCloseMenuDrawer={onCloseMenuDrawer}
        />
      )}
      {isConsumer && (
        <>
          <MenuDrawerPrivate
            isOpenMenuDrawer={isOpenMenuDrawer}
            onOpenMenuDrawer={onOpenMenuDrawer}
            onCloseMenuDrawer={onCloseMenuDrawer}
            onAddProperty={onAddPropertyHandler}
            onOpenAddProjectModal={onOpenAddProjectModal}
          />
          <Modal
            isOpen={isOpenAddPropertyModal}
            onOpen={onOpenAddPropertyModal}
            onClose={onCloseAddPropertyModal}
            size="xl"
          >
            <AddProperty onCloseModal={onCloseAddPropertyModal} />
          </Modal>
          <Modal
            isOpen={isOpenAddProjectModal}
            onOpen={onOpenAddProjectModal}
            onClose={onCloseAddProjectModal}
            size="xl"
          >
            <AddProject
              properties={properties}
              onCloseModal={onCloseAddProjectModal}
            />
          </Modal>
        </>
      )}
    </>
  )
}
export default HeaderNavbarPortal
