import { Image } from '@chakra-ui/react'
import BerikarWithName from '../img/logos/berikarWithNameLogo.svg'
import Berikar from '../img/logos/berikarLogo.svg'
import BerikarProvider from '../img/logos/berikarProviderLogo.svg'
import BerikarLovesHomes from '../img/logos/berikarLovesHomesLogo.svg'

export const getLogoFromName = (logoName: LogoName, h?: number | string) => {
  switch (logoName) {
    case 'BerikarWithName':
      return <Image src={BerikarWithName} h={h} />
    case 'Berikar':
      return <Image src={Berikar} h={h} />
    case 'BerikarProvider':
      return <Image src={BerikarProvider} h={h} />
    case 'BerikarLovesHomes':
      return <Image src={BerikarLovesHomes} h={h} />

    default:
      return <Image src={Berikar} h={h} />
  }
}
export type LogoName =
  | 'Berikar'
  | 'BerikarWithName'
  | 'BerikarLovesHomes'
  | 'BerikarProvider'
