import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import {
  ProjectPlaceResponse,
  CreateProjectPlaceRequest,
  ProjectPlace,
} from '@contracts/projects'
import { useContext } from 'react'
import AuthContext from '../../common/auth-context'
import log from '../../common/log'

const baseUrl = window.PROJECTS_SERVICE_URL

const projectPlacesFetcher = async (
  urlKey: string,
): Promise<ProjectPlaceResponse[] | undefined> => {
  return axios.get<ProjectPlaceResponse[]>(`${urlKey}`).then(({ data }) => {
    return data
  })
}

export const useProjectPlace = (
  projectPlaceId: string | undefined,
): SWRResponse<ProjectPlaceResponse | undefined> => {
  return useSWR<ProjectPlaceResponse | undefined>(
    projectPlaceId ? `${baseUrl}/project-places/${projectPlaceId}` : null,
  )
}
export const useProjectPlacesByProject = (
  projectId: string | undefined,
): SWRResponse<ProjectPlaceResponse[] | undefined> => {
  return useSWR<ProjectPlaceResponse[] | undefined>(
    projectId ? `${baseUrl}/project-places/${projectId}/projects` : null,
  )
}
export const useProjectPlaces = (
  organizationId: string | undefined,
): SWRResponse<ProjectPlaceResponse[] | undefined> => {
  return useSWR<ProjectPlaceResponse[] | undefined>(
    organizationId
      ? `${baseUrl}/project-places/organizations/${organizationId}`
      : null,
    projectPlacesFetcher,
  )
}
export const useProjectPlacesAdmin = (
  organizationId: string | undefined,
): SWRResponse<ProjectPlaceResponse[] | undefined> => {
  return useSWR<ProjectPlaceResponse[] | undefined>(
    // `${baseUrl}/projectPlaces/`,
    organizationId
      ? `${baseUrl}/admin/project-places/organizations/${organizationId}`
      : null,
    projectPlacesFetcher,
  )
}
// export const useProjects = (): SWRResponse<ProjectResponse[] | undefined> => {
//   return useSWR<ProjectResponse[] | undefined>(
//     `${baseUrl}/projects/`,
//     projectsFetcher,
//   )
// }

export const useAllProjectPlacesAdmin = (): SWRResponse<
  ProjectPlaceResponse[] | undefined
> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<ProjectPlaceResponse[] | undefined>(
    isAdmin ? `${baseUrl}/admin/project-places/organizations/` : null,
    projectPlacesFetcher,
  )
}

// export const useProjectsByAccount = (
//   accountId: string | undefined,
// ): SWRResponse<ProjectResponse[] | undefined> => {
//   const { isAdmin } = useContext(AuthContext)
//   return useSWR<ProjectResponse[] | undefined>(
//     isAdmin && accountId
//       ? `${baseUrl}/admin/projects/accounts/${accountId}`
//       : `${baseUrl}/projects/accounts/`,
//     projectsFetcher,
//   )
// }

// export const useProject = (
//   projectId: string | undefined,
// ): SWRResponse<ProjectResponse | undefined> => {
//   return useSWR<ProjectResponse | undefined>(
//     projectId ? `${baseUrl}/projects/${projectId}` : null,
//   )
// }

// export const useProjectsByProperty = (
//   propertyId: string,
// ): SWRResponse<ProjectResponse[] | undefined> => {
//   return useSWR<ProjectResponse[] | undefined>(
//     `${baseUrl}/projects/properties/${propertyId}`,
//     projectsFetcher,
//   )
// }

// export const useProjectsByProperties = (
//   propertyIds: string[] | undefined,
// ): SWRResponse<ProjectResponse[] | undefined> => {
//   const querySring = encodeURIComponent(JSON.stringify(propertyIds)) ?? ''

//   return useSWR<ProjectResponse[] | undefined>(
//     propertyIds
//       ? `${baseUrl}/projects/properties/propertyIds?propertyIds=${querySring}`
//       : null,
//     projectsFetcher,
//   )
// }

// export const useProjectsByPropertyAdmin = (
//   propertyId: string,
// ): SWRResponse<ProjectResponse[] | undefined> => {
//   return useSWR<ProjectResponse[] | undefined>(
//     `${baseUrl}/admin/projects/properties/${propertyId}`,
//     projectsFetcher,
//   )
// }

export const addProjectPlaceAndProject = async (
  // organizationId: string,
  projectPlaceRequest: CreateProjectPlaceRequest,
  projectCategoryIds: string[],
  // projectName: string,
  // propertyId: string,
  // projectCategories: ProjectCategory[],
) => {
  return axios.post(
    `${baseUrl}/project-places`,
    {
      // organizationId,
      ...projectPlaceRequest,
      projectCategoryIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateProjectPlace = (id: string, requestData: ProjectPlace) => {
  return axios
    .put(`${baseUrl}/project-places/${id}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated project place', status, data)
      return data
    })
}
// export const addProject = async (
//   projectName: string,
//   propertyId: string,
//   projectCategories: ProjectCategory[],
// ) => {
//   return axios.post(
//     `${baseUrl}/projects`,
//     { name: projectName, propertyId, projectCategories },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     },
//   )
// }
// export const updateProject = (
//   id: string,
//   requestData: UpdateProjectRequest,
// ) => {
//   return axios
//     .put(`${baseUrl}/projects/${id}`, requestData, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     .then(({ status, data }) => {
//       log.info('Successfully updated project', status, data)
//       return data
//     })
// }

// export const deleteProject = (projectId: string) => {
//   return axios.delete(`${baseUrl}/projects/${projectId}`, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
// }
