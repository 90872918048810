import { Flex, Text, Button, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { UpdateOrganizationRequest } from '@contracts/accounts'
import { Organization } from '@contracts/misc'

import { KeyedMutator } from 'swr'
import { Input } from '../../../ui'

import { updateOrganization } from '../../../apiClients/organizationsApiClient'
import log from '../../../common/log'

import Spinner from '../../Spinner'
import { validateString } from '../../../common/auth-utils'

interface ProviderValues {
  vatNumber: string | null
  street: string
  zipCode: string
  city: string
  email: string
  phoneNumber: string
}

interface Errors {
  vatNumber?: string
  street?: string
  zipCode?: string
  city?: string
  email?: string
  phoneNumber?: string
}

const AddOrEditOrganizationData: React.FC<{
  currentOrganization: Organization | undefined
  maxW?: string
  mt?: string | number
  px?: string | number
  mutate?: KeyedMutator<Organization | undefined>
}> = ({ maxW, mt, px, currentOrganization, mutate }) => {
  if (!currentOrganization) {
    return null
  }

  const { t } = useTranslation()
  const toast = useToast()

  const initialValues: ProviderValues = {
    vatNumber: currentOrganization.vatNumber ?? null,
    street: currentOrganization.address?.street ?? '',
    zipCode: currentOrganization.address?.zipCode ?? '',
    city: currentOrganization.address?.city ?? '',
    email: currentOrganization.email ?? '',
    phoneNumber: currentOrganization.phoneNumber ?? '',
  }

  const validate = (values: ProviderValues) => {
    const errors: Errors = {}
    let tmpRes = validateString(
      values.vatNumber ?? '',
      t('accounts.providers.vatNumber'),
      6,
      30,
      false,
    )
    if (!tmpRes.isValid) {
      errors.vatNumber = tmpRes.errorMsg
    }

    tmpRes = validateString(
      values.street,
      t('general.address.street'),
      6,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.street = tmpRes.errorMsg
    }

    tmpRes = validateString(
      values.zipCode,
      t('general.address.zipCode'),
      5,
      8,
      true,
    )
    if (!tmpRes.isValid) {
      errors.zipCode = tmpRes.errorMsg
    }

    tmpRes = validateString(values.city, t('general.address.city'), 1, 30, true)
    if (!tmpRes.isValid) {
      errors.city = tmpRes.errorMsg
    }

    tmpRes = validateString(
      values.email,
      t('general.address.email'),
      3,
      255,
      true,
    )
    if (!tmpRes.isValid) {
      errors.email = tmpRes.errorMsg
    }

    tmpRes = validateString(
      values.phoneNumber,
      t('accounts.providers.phoneNumber'),
      6,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.phoneNumber = tmpRes.errorMsg
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      // const vatNumber2: string | null | undefined = values.vatNumber
      //  && values.vatNumber.length ? values.vatNumber : null
      const requestData: UpdateOrganizationRequest = {
        name: currentOrganization.name,
        // vatNumber: vatNumber2,
        vatNumber: values.vatNumber,
        email: values.email,
        phoneNumber: values.phoneNumber,
        address: {
          street: values.street,
          zipCode: values.zipCode,
          city: values.city,
        },
      }

      updateOrganization(currentOrganization.id, requestData)
        .then(res => {
          log.debug('Successfully updated organization', res)
          mutate ? void mutate() : ''
          toast({
            title: t('accounts.providers.profile.updateProfileSuccessMessage'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(err => {
          log.error({ err }, 'Failed to update organization contacts data')
          toast({
            title: t('accounts.providers.profile.updateProfileFailMessage'),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
      <Flex flexDir="row" justifyContent="left" wrap="wrap">
        <form onSubmit={handleSubmit} data-cy="add-new-provider">
          <Flex flexDir="column">
            <Flex
              display="flex"
              flexDirection="column"
              mt={6}
              w="100%"
              maxW={maxW}
            >
              <Input
                title={t('general.address.street')}
                name="street"
                type="text"
                isMandatory
                isError={!!errors.street}
                errorText={touched.street && errors.street ? errors.street : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.street}
                mb={4}
              />
              <Input
                title={t('general.address.zipCode')}
                name="zipCode"
                type="text"
                isMandatory={true}
                isError={!!errors.zipCode}
                errorText={
                  touched.zipCode && errors.zipCode ? errors.zipCode : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.zipCode}
                mb={4}
              />
              <Input
                title={t('general.address.city')}
                name="city"
                type="text"
                isMandatory={true}
                isError={!!errors.city}
                errorText={touched.city && errors.city ? errors.city : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.city}
                mb={4}
              />
              <Input
                title={t('accounts.providers.email')}
                name="email"
                type="text"
                isMandatory={true}
                isError={!!errors.email}
                errorText={touched.email && errors.email ? errors.email : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.email}
                mb={4}
              />
              <Input
                title={t('accounts.providers.phoneNumber')}
                name="phoneNumber"
                type="phoneNumber"
                isMandatory={true}
                isError={!!errors.phoneNumber}
                errorText={
                  touched.phoneNumber && errors.phoneNumber
                    ? errors.phoneNumber
                    : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.phoneNumber}
              />
              <Text ml={4} textStyle="infoSmall" mb={4}>
                *{t('input.mandatoryFields')}
              </Text>
              <Input
                title={t('accounts.providers.vatNumber')}
                name="vatNumber"
                type="text"
                isMandatory={false}
                isError={!!errors.vatNumber}
                errorText={
                  touched.vatNumber && errors.vatNumber ? errors.vatNumber : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.vatNumber ?? ''}
                mb={4}
              />
            </Flex>

            <Text fontWeight="light" mt={4}>
              {t('sections.registerNewProviderSection.explanation')}
            </Text>
            <Button type="submit" variant="primary" h={10} my={5} w="100%">
              {t('ui.button.update')}
            </Button>
          </Flex>
        </form>
      </Flex>
      {isSubmitting && <Spinner />}
    </Flex>
  )
}
export default AddOrEditOrganizationData
