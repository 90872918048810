import { Flex, Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

const PromoGroup: React.FC<{
  children: ReactElement
  color?: string
  bg?: string
  mr?: number
  mb?: number
  mt?: number
  isExample?: boolean
  px?: number
  py?: number
  w?: string | number
  h?: string | number
  border?: string
  link?: string
  tooltip?: string
}> = ({
  children,
  color = 'black',
  bg = 'transparent',
  mr,
  mb,
  mt,
  px,
  py,
  w,
  h,
  border,
  link,
  tooltip,
}) => {
  const navigate = useNavigate()
  return (
    <Tooltip label={tooltip} hasArrow={true} placement="top-start">
      <Flex
        flexDir="column"
        position="relative"
        px={{ desktop: px }}
        py={py}
        bg={bg}
        color={color}
        overflow="clip"
        alignItems="flex-start"
        justifyContent="left"
        justifyItems="left"
        textAlign="left"
        mt={mt}
        mr={{ desktop: mr }}
        mb={mb}
        w={w}
        h={h}
        border={border}
        onClick={() => {
          if (link) {
            navigate(link)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        }}
      >
        {children}
      </Flex>
    </Tooltip>
  )
}
export default PromoGroup
