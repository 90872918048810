import React, { CSSProperties } from 'react'
import { Flex, Tooltip } from '@chakra-ui/react'
import { getIconFromName, IconName } from '../../common/icon-utils'

export const Icon: React.FC<{
  name: IconName
  h?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  _hover?: CSSProperties
  color?: string
  onClick?: () => void
  tooltip?: string
  fontSize?: string
  transform?: string
  isDisabled?: boolean
  onPulseAnimate?: boolean
}> = ({
  name,
  h,
  _hover,
  color,
  onClick,
  tooltip,
  fontSize = '1.8rem',
  mt,
  mr,
  mb,
  ml,
  transform,
  isDisabled = false,
  onPulseAnimate = false,
}) => {
  return (
    <Tooltip label={tooltip} hasArrow>
      <Flex
        fontSize={fontSize}
        mt={mt}
        mr={mr}
        mb={mb}
        ml={ml}
        transform={transform}
        justifyContent="center"
      >
        {getIconFromName({
          iconName: name,
          h,
          _hover,
          color: isDisabled ? 'gray.300' : color,
          onClick,
          isDisabled,
          onPulseAnimate,
        })}
      </Flex>
    </Tooltip>
  )
}
