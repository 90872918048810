import { HStack, Flex, ResponsiveValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../ui/Icon'
import { Heading } from '../../../ui'

const WelcomeNewProviderSection: React.FC<{
  name: string
  maxW?: string
  mt?: ResponsiveValue<string | number>
}> = ({ name, maxW, mt }) => {
  const { t } = useTranslation()

  return (
    <Flex
      mt={mt}
      ml={12}
      flexDir="column"
      justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
      wrap="wrap"
      maxW={maxW}
    >
      <HStack spacing={4} textAlign="left">
        <Icon name="wavingHand" h={12} />
        <Heading textStyle="h2">
          {t('sections.welcomeNewUser.hello')} {name}!
        </Heading>
      </HStack>
      {/* TODO Hiding this text until we have gathered the latest updates */}
      {/* <Text textStyle="textLargeLight" mt={4}>
        {t('content.provider.newProviderWelcomeText')}
      </Text> */}
    </Flex>
  )
}
export default WelcomeNewProviderSection
