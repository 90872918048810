import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import { ingressConstructionTermsPage } from '../../apiClients/contentApiClient'
import ConstructionTermsSection from '../../components/sections/portal/ConstructionGlossarySection'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'

const ConstructionGlossaryPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)
  return (
    <>
      <Helmet>
        <title>{t('portal.sidebar.menu.constructionGlossaryPageTitle')}</title>
      </Helmet>
      <ContentPageHeader
        label={t(
          'portal.sidebar.menu.constructionGlossaryPageTitle',
        ).toUpperCase()}
        ingress={ingressConstructionTermsPage}
        isEmbedded={isWithinMyPages ? false : true}
      />
      <ConstructionTermsSection mx="5%" />
    </>
  )
}
export default ConstructionGlossaryPage
