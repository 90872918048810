import { ReactNode, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ResponsiveValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from '@chakra-ui/icons'
import IconButton from './IconButton'

const AlertDialogAndButton: React.FC<{
  title: string
  message: string
  onDestructiveAction: () => void
  buttonTitle: string
  buttonW?: ResponsiveValue<string | number>
  variant?: 'button' | 'deleteIconButton' | 'children' | 'deleteIcon'
  children?: ReactNode
}> = ({
  title,
  message,
  onDestructiveAction,
  buttonTitle,
  buttonW = 20,
  variant = 'button',
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()

  return (
    <>
      {variant === 'button' && (
        <Button
          colorScheme="red"
          onClick={onOpen}
          w={buttonW}
          variant="outline"
          color="gray.400"
        >
          {buttonTitle}
        </Button>
      )}
      {variant === 'deleteIconButton' && (
        <IconButton
          data-cy="deleteButton"
          onClick={onOpen}
          buttonType="DELETE"
        />
      )}
      {variant === 'deleteIcon' && (
        <DeleteIcon
          boxSize={4}
          color="gray.500"
          _hover={{ color: 'gray.400' }}
          onClick={onOpen}
        />
      )}
      {variant === 'children' && children && (
        <Box onClick={onOpen}>{children}</Box>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody data-cy="AlertDialogBody">
              {message}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('general.cancel')}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onDestructiveAction()
                  onClose()
                }}
                data-cy="AlertDialogButton"
                ml={3}
              >
                {t('general.continue')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
export default AlertDialogAndButton
