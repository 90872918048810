import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { Organization } from '@contracts/misc'

import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import AccountOrganizationAvatar from '../accounts/AccountOrganizationAvatar'

import Skeleton from '../ui/Skeleton'

const OrganizationAccountsCard: React.FC<{
  org: Organization
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  gap?: ResponsiveValue<number>
  border?: string
  variant?: 'normal' | 'card'
}> = ({ org, px = 4, py = 4, gap = 4, border, variant = 'normal' }) => {
  const { data: orgAccounts } = useOrganizationAccounts(org.id)

  if (org) {
    return (
      <Flex
        flexDir="row"
        gap={gap}
        py={py}
        px={px}
        wrap="wrap"
        justifyContent="left"
        flexGrow={0}
        border={border}
      >
        {variant === 'normal' &&
          orgAccounts &&
          orgAccounts.length > 0 &&
          orgAccounts.map(orgAccount => {
            return (
              <AccountOrganizationAvatar
                key={orgAccount.accountId}
                accountId={orgAccount.accountId}
                variant={'full'}
                w={64}
              />
            )
          })}
        {variant === 'card' &&
          orgAccounts &&
          orgAccounts.length > 0 &&
          orgAccounts.map(orgAccount => {
            return (
              <Flex
                key={orgAccount.accountId}
                w="100%"
                borderRadius="lg"
                bg="lightBackground"
                px={2}
                py={2}
              >
                <AccountOrganizationAvatar
                  key={orgAccount.accountId}
                  accountId={orgAccount.accountId}
                  variant={'full'}
                />
              </Flex>
            )
          })}
      </Flex>
    )
  } else {
    return <Skeleton />
  }
}
export default OrganizationAccountsCard
