import { AttachmentSummary, Note } from '@contracts/misc'

export const findFirstAttachedImageForNote = (note: {
  // name: string
  attachmentsJson: AttachmentSummary[] | null
}): string | undefined => {
  if (note?.attachmentsJson && note?.attachmentsJson?.length > 0) {
    return note.attachmentsJson.find(
      ({ mimeType }) =>
        mimeType.includes('image/jpg') ||
        mimeType.includes('image/jpeg') ||
        mimeType.includes('image/png'),
    )?.id
  }
  return undefined
}

export const findLastAttachedImageForNote = (note: {
  // name: string
  attachmentsJson: AttachmentSummary[] | null
}): string | undefined => {
  if (note?.attachmentsJson && note?.attachmentsJson?.length > 0) {
    const tmp = note.attachmentsJson.filter(file => {
      if (
        file.mimeType.includes('image/jpg') ||
        file.mimeType.includes('image/jpeg') ||
        file.mimeType.includes('image/png')
      ) {
        return file.id
      }
    })
    if (tmp.length) {
      return tmp[tmp.length - 1].id
    }
  }
  return undefined
}

export const getImageForNote = (note: Note): string | undefined => {
  const lastAttachedImageId = findLastAttachedImageForNote(note)
  // eslint-disable-next-line no-console
  // console.log('img id: ', lastAttachedImageId)

  // Verify that image exist in blob storage

  // if (note.name === 'Söndag igen') {
  //   // eslint-disable-next-line no-console
  //   console.log('note: ', note)
  //   console.log('first attachment id: ', attachedImageId)
  //   console.log('last attachment id: ', lastAttachedImageId)
  // }
  if (lastAttachedImageId) {
    // return `${window.AZURE_BLOB_STORAGE_BASE_URL}/private/${attachedImageId}`
    return `${window.AZURE_BLOB_STORAGE_BASE_URL}/private/${lastAttachedImageId}`
  }

  return undefined
}
