import { Flex, ResponsiveValue } from '@chakra-ui/react'
import ProjectPlaceSummaryCard from './ProjectPlaceSummaryCard'

const ProjectPlaceAdminSummaryCard: React.FC<{
  projectPlaceId: string
  mr?: number
  mt?: number
  ml?: ResponsiveValue<number | string>
  headerBg?: string
}> = ({ projectPlaceId, mr, mt, ml }) => (
  <Flex flexDir="column" mr={mr} mt={mt} ml={ml}>
    <ProjectPlaceSummaryCard projectPlaceId={projectPlaceId} />
  </Flex>
)
export default ProjectPlaceAdminSummaryCard
