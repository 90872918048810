import _ from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Show, Table, Tbody, Td, Tr, Text, Flex } from '@chakra-ui/react'

import { PropertyResponse } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Icon } from '../ui/Icon'
import { getDateStrFromDate } from '../../common/util'

import log from '../../common/log'
import Heading from '../ui/Heading'
import AccountAvatar from '../accounts/AccountAvatar'

import { Link } from '../../ui'
import {
  PATH_ADMIN_CONSUMER_ACCOUNTS,
  PATH_ADMIN_PROVIDER_ACCOUNTS,
} from '../../common/nav'
import CollaboratorCard from './CollaboratorCard'
import { useCollaboratorParts } from './collaboratorHelper'

const ListCollaborators: React.FC<{
  property?: PropertyResponse
  project?: ProjectResponse
  mt?: number | string
  pb?: number | string
  variant?: 'normal' | 'link'
}> = ({ property, project, mt, pb, variant = 'normal' }) => {
  const { collaborators, currentUserCollaboratorRelation } =
    useCollaboratorParts({
      propertyId: property?.id,
      projectId: project?.id,
    })

  const { t } = useTranslation()

  const area = property ? 'Property' : 'Project'
  const areaId = property ? property.id : project?.id

  if (!area || !areaId) {
    log.error('Error listing collaborators, missing area and/or areaId')
    return null
  }

  const ownerCollaborators = collaborators?.filter(c => {
    return c.accountRelation === 'Owner'
  })

  const clientCollaborators = ownerCollaborators?.filter(c => {
    return c.client === true
  })

  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  return (
    <Box pb={pb} mt={mt}>
      <Heading textStyle="h9" ml={2} mb={6}>
        {area === 'Property'
          ? t('properties.listingCollaborators')
          : t('projects.listingCollaborators')}
      </Heading>
      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <Tbody>
          {collaborators && collaborators.length > 0 ? (
            collaborators.map((collaborator, i) => {
              const isOnlyOwner =
                (ownerCollaborators?.length ?? 0) <= 1 &&
                collaborator.accountRelation === 'Owner'
              const isOnlyClient =
                (clientCollaborators?.length ?? 0) <= 1 &&
                collaborator.client === true

              const createdAt = getDateStrFromDate(
                new Date(collaborator.createdAt),
              )

              return (
                <Fragment key={collaborator.accountId}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                    onClick={() => {
                      toggleAccordion(collaborator.accountId)
                    }}
                  >
                    <Show above="laptop">
                      <Td maxW={8}>
                        {!accordionsToShow.includes(collaborator.accountId) && (
                          <Icon
                            name={'AiOutlinePlusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(collaborator.accountId)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(collaborator.accountId) && (
                          <Icon
                            name={'AiOutlineMinusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(collaborator.accountId)
                            }}
                          />
                        )}
                      </Td>
                      <Td overflow="clip" wordBreak="break-word" minW={64}>
                        <Flex flexDir="row" alignItems="center">
                          <AccountAvatar
                            accountId={collaborator.accountId}
                            // variant="plain"
                            variant="avatarAndName"
                            mr={4}
                          />
                        </Flex>
                      </Td>
                      <Td>
                        {collaborator.accountType === 'Consumer'
                          ? t('general.consumerUser')
                          : t('general.providerUser')}
                      </Td>

                      {!collaborator.client && (
                        <Td minW={{ tablet: 44 }}>
                          {t(
                            `properties.accessRights.${collaborator.accountRelation.toLowerCase()}`,
                          )}
                        </Td>
                      )}
                      {area === 'Project' && collaborator.client === true && (
                        <Td minW={{ tablet: 44 }}>
                          {t('properties.accessRights.client')}
                        </Td>
                      )}
                    </Show>
                    <Show below="laptop">
                      <Td maxW={14}>
                        {!accordionsToShow.includes(collaborator.accountId) && (
                          <Icon
                            name={'AiOutlinePlusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(collaborator.accountId)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(collaborator.accountId) && (
                          <Icon
                            name={'AiOutlineMinusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(collaborator.accountId)
                            }}
                          />
                        )}
                      </Td>
                      <Td maxW={12}>
                        <AccountAvatar
                          accountId={collaborator.accountId}
                          variant="avatar"
                        />
                      </Td>
                      <Td
                        colSpan={2}
                        overflow="clip"
                        wordBreak="break-word"
                        maxW={72}
                      >
                        <Text fontWeight="bold">
                          {`${collaborator.account?.firstName ?? ''} ${
                            collaborator.account?.lastName ?? ''
                          }`}
                        </Text>
                        <Text>
                          {collaborator.accountType === 'Consumer'
                            ? t('general.consumerUser')
                            : t('general.providerUser')}
                        </Text>

                        {/* {collaborator.client === false && ( */}
                        {!collaborator.client && (
                          <Text>
                            {t(
                              `properties.accessRights.${collaborator.accountRelation.toLowerCase()}`,
                            )}
                          </Text>
                        )}
                        {collaborator.client === true && (
                          <Text>{t('properties.accessRights.client')}</Text>
                        )}
                      </Td>
                    </Show>
                    <Show above="laptop">
                      <Td
                        maxW={{ xxs: 40, tablet: 96, laptop: '' }}
                        overflow="clip"
                      >
                        {createdAt}
                      </Td>
                      {variant === 'link' && (
                        <Td>
                          <Link
                            to={`${
                              collaborator.account?.accountTypes?.includes(
                                'Consumer',
                              )
                                ? PATH_ADMIN_CONSUMER_ACCOUNTS
                                : PATH_ADMIN_PROVIDER_ACCOUNTS
                            }/?konto=${collaborator.accountId}`}
                          >
                            <ExternalLinkIcon />
                          </Link>
                        </Td>
                      )}
                    </Show>
                  </Tr>
                  {accordionsToShow.includes(collaborator.accountId) && (
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={8}>
                        <CollaboratorCard
                          property={property}
                          project={project}
                          collaborator={collaborator}
                          currentUserAccessRights={
                            currentUserCollaboratorRelation ?? 'Viewer'
                          }
                          isOnlyAdmin={isOnlyOwner}
                          isOnlyClient={isOnlyClient}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={8}>{t('properties.noCollaboratorsFound')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListCollaborators
