import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ResponsiveValue,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentSummary } from '@contracts/misc'
import { ChevronDownIcon } from '@chakra-ui/icons'

import AuthContext from '../../common/auth-context'
import AccountAttachmentsMobileTreeViewCard from '../attachments/AccountAttachmentsMobileTreeViewCard'
import { groupBy, groupByFiles } from '../attachments/attachmentHelper'
import {
  updateFilename,
  updateMultipleFilenames,
  useMultipleAttachments,
} from '../../apiClients/attachmentsApiClient'

import EditFileCardCompactVersioned from '../attachments/EditAttachmentsCardCompactVersioned'
// import log from '../../common/log'
import EditFileCardCompact from '../attachments/EditFileCardCompact'
import EditAttachmentCardCompact from '../attachments/EditAttachmentCardCompact'
import FilePickerButton from './FilePickerButton'
import Heading from './Heading'
import Modal from './Modal'
import { Icon } from './Icon'

const FileManagerBeforeContextId: React.FC<{
  attachmentsJson: AttachmentSummary[]
  setAttachmentsJson: Dispatch<SetStateAction<AttachmentSummary[]>>
  newFiles: File[]
  setNewFiles: Dispatch<SetStateAction<File[]>>
  mt?: number
  ml?: number
  mb?: number
  mr?: number
  px?: ResponsiveValue<number | string>
  my?: number
  hideButton?: boolean
  hideFileList?: boolean
  variant?: 'downloadOnly' | 'menu'
  buttonW?: ResponsiveValue<string | number>
  mutateParent?: () => Promise<void>
}> = ({
  attachmentsJson,
  setAttachmentsJson,
  newFiles,
  setNewFiles,
  mt,
  mr,
  mb,
  ml,
  my,
  px,
  hideButton = false,
  hideFileList = false,
  variant = 'downloadOnly',
  buttonW = 64,
  // mutateParent,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)

  // const { data: attachments, mutate: mutateAttachments } =
  const { data: attachments, mutate: mutateAttachments } =
    useMultipleAttachments(
      attachmentsJson.map(attachmentSummary => {
        return attachmentSummary.id
      }),
    )

  const {
    isOpen: isOpenMyFiles,
    onOpen: onOpenMyFiles,
    onClose: onCloseMyFiles,
  } = useDisclosure()

  const removeFilesHandler = (filenames: string[]) => {
    // eslint-disable-next-line no-console
    console.log('remove file: ', filenames)
    setNewFiles(
      newFiles.filter(file => {
        return !filenames.includes(file.name)
      }),
    )
  }

  const removeAttachmentsHandler = (attachmentIds: string[]) => {
    // eslint-disable-next-line no-console
    console.log('remove attachments: ', attachmentIds)

    setAttachmentsJson(
      attachmentsJson.filter(attachment => {
        !attachmentIds.includes(attachment.id)
      }),
    )
  }

  const addNewFilesHandler = async (newFilesToAdd: File[]): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('add new files: ', newFilesToAdd)

    setNewFiles([...newFiles, ...newFilesToAdd])
    // await onUploadFiles(newFilesToAdd)
    return await Promise.resolve()
  }

  const attachmentNameChangeHandler = async (
    attachmentIdToChangeName: string,
    newFileName: string,
    // mimeType: string,
  ) => {
    // eslint-disable-next-line no-console
    // console.log('Change file name, mimeType: ', mimeType)

    await updateFilename(attachmentIdToChangeName, newFileName)
    // void mutateAttachments()
    await mutateAttachments()
    // mutateParent ? void mutateParent() : ''
  }

  const multipleAttachmentNameChangeHandler = async (
    attachmentIdToChangeName: string[],
    newFileName: string,
    mimeType: string,
  ): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('Change file name, mimeType: ', mimeType)

    await updateMultipleFilenames(attachmentIdToChangeName, newFileName)
    await mutateAttachments()
    // mutateParent ? void mutateParent() : ''
  }

  const addAttachmentsFromMyFilesHandler = (
    listOfAttachmentsToAdd: AttachmentSummary[],
  ) => {
    // eslint-disable-next-line no-console
    console.log('Add attachments from "My Files": ', listOfAttachmentsToAdd)

    setAttachmentsJson([...attachmentsJson, ...listOfAttachmentsToAdd])
    // void onAddExistingAttachments(listOfAttachmentsToAdd)
  }

  const { files: groupedAttachmentFiles, images: groupedAttachmentImages } =
    groupBy(attachments ?? [])
  const { files: groupedFileFiles, images: groupedFileImages } = groupByFiles(
    newFiles ?? [],
  )
  // eslint-disable-next-line no-console
  // console.log('groupedFileFiles: ', groupedFileFiles)
  // eslint-disable-next-line no-console
  // console.log('groupedFileImages: ', groupedFileImages)

  return (
    <>
      {!hideFileList && (
        <Flex
          flexDir="column"
          mt={mt}
          mr={mr}
          mb={mb}
          ml={ml}
          my={my}
          px={px}
          // border="1px solid red"
        >
          {/* <Flex
            gap={2}
            alignItems="center"
            onClick={() => {
              onOpenInfoModal()
            }}
          > */}
          <Heading textStyle="cardHeading">
            {/* {t('attachments.attachedFiles')} */}
            Filer att bifoga
          </Heading>
          {/* <InfoOutlineIcon
              fontSize={14}
              color="logo_orange"
              _hover={{ cursor: 'pointer', color: 'orange' }}
            />
          </Flex> */}

          {/* {!attachmentsJson?.length && !newFiles && ( */}
          {!attachmentsJson?.length && !newFiles.length && (
            <Text textStyle="cardText">{t('attachments.noAttachedFiles')}</Text>
          )}

          {/* {groupedFiles?.map(attachments => { */}
          {groupedAttachmentFiles?.map(attachments => {
            if (attachments.length === 1) {
              return (
                <EditAttachmentCardCompact
                  key={attachments[0].id}
                  // variant="attachment"
                  mimeType={attachments[0].mimeType}
                  attachmentId={attachments[0].id}
                  onChangeAttachmentName={attachmentNameChangeHandler}
                  onDeleteAttachments={removeAttachmentsHandler}
                  changeNameIsDisabled
                />
              )
            }
            if (attachments.length) {
              return (
                <EditFileCardCompactVersioned
                  attachments={attachments}
                  key={attachments[0].id}
                  onChangeVersionedAttachmentName={
                    multipleAttachmentNameChangeHandler
                  }
                  onDeleteAttachments={removeAttachmentsHandler}
                />
              )
            }
            return null
          })}
          {groupedFileFiles?.map(files => {
            return (
              <EditFileCardCompact
                key={files[0].name}
                file={files[0]}
                onDeleteFiles={removeFilesHandler}
              />
            )

            // }
            // if (files.length) {
            //   return (
            //     <EditFileCardCompactVersioned
            //       files={files}
            //       key={files[0].id}
            //       onChangeVersionedAttachmentName={
            //         multipleAttachmentNameChangeHandler
            //       }
            //       onDeleteAttachments={removeAttachmentsHandler}
            //     />
            //   )
            // }
            // return null
          })}
          {groupedAttachmentImages?.map(attachments => {
            if (attachments.length === 1) {
              return (
                <EditAttachmentCardCompact
                  key={attachments[0].id}
                  // variant="attachment"
                  mimeType={attachments[0].mimeType}
                  attachmentId={attachments[0].id}
                  onChangeAttachmentName={attachmentNameChangeHandler}
                  onDeleteAttachments={removeAttachmentsHandler}
                  changeNameIsDisabled
                />
              )
            }
            // if (attachments.length) {
            //   return (
            //     <EditFileCardCompactVersioned
            //       attachments={attachments}
            //       key={attachments[0].id}
            //       onChangeVersionedAttachmentName={
            //         multipleAttachmentNameChangeHandler
            //       }
            //       onDeleteFiles={removeFilesHandler}
            //     />
            //   )
            // }
            return null
          })}
          {groupedFileImages?.map(files => {
            // if (files.length === 1) {
            return (
              <EditFileCardCompact
                key={files[0].name}
                file={files[0]}
                onDeleteFiles={removeFilesHandler}
              />
            )
            // }
          })}
        </Flex>
      )}

      {!hideButton && variant === 'downloadOnly' && (
        <FilePickerButton
          variant="BUTTON"
          w="100%"
          mt={4}
          mb={6}
          ContextArea={'Note'}
          label={t('attachments.attachImgOrFile')}
          pickerVariant="NO-DIALOG"
          onUpdateFileList={addNewFilesHandler}
        />
      )}
      {!hideButton && variant === 'menu' && (
        // <Flex px={{ xxs: 2, tablet: 0 }}>
        <Flex px={px}>
          <Menu>
            <MenuButton
              as={Button}
              bg="secondary"
              color="white"
              rightIcon={<ChevronDownIcon boxSize={7} />}
              w={buttonW}
              _hover={{ background: 'secondaryDark' }}
              _active={{ bg: 'secondaryDark' }}
              mt={4}
              mb={6}
              // px={px}
            >
              {/* Bifoga filer */}
              Lägg till filer
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  onOpenMyFiles()
                }}
              >
                <Icon name={'myFiles'} mr={2} />

                <span>Mina filer</span>
              </MenuItem>
              <MenuItem p={0}>
                <FilePickerButton
                  variant="MENU_ITEM"
                  w="100%"
                  ContextArea={'Note'}
                  label={t('attachments.attachImgOrFile')}
                  pickerVariant="NO-DIALOG"
                  onUpdateFileList={addNewFilesHandler}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
      <Modal
        isOpen={isOpenMyFiles}
        onOpen={onOpenMyFiles}
        onClose={onCloseMyFiles}
        px={0}
        py={0}
        size="sm"
      >
        <>
          {currentUser && (
            <AccountAttachmentsMobileTreeViewCard
              account={currentUser}
              isCheckable
              w="100%"
              onCloseModal={onCloseMyFiles}
              callback={addAttachmentsFromMyFilesHandler}
            />
          )}
          {/* <h1>No files found</h1> */}
        </>
      </Modal>
      {/* <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {t('attachments.fileManagerInfoTitle')}
          </Heading>

          <Text textStyle="infoTextLight" fontStyle="italic">
            {t('attachments.fileManagerInfoText')}
          </Text>
        </Flex>
      </Modal> */}
    </>
  )
}

export default FileManagerBeforeContextId
