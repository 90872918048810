import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { useCollaborators } from '../../apiClients/projectsApiClient'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { Link } from '../../ui'
import { useImageUrl } from '../attachments/attachmentHelper'

const ProjectAvatar: React.FC<{
  projectId: string
  accountId?: string
  variant: 'plain' | 'avatarAndName' | 'owner-and-city'
  mr?: number | string
  mt?: number | string
  mb?: number | string
  link?: string
  w?: string | number
}> = ({ projectId, accountId, variant, mr, mt, mb, link, w }) => {
  const { data: project } = useProjectInfo(projectId)
  const { data: collaborators } = useCollaborators(
    accountId ? projectId : undefined,
  )
  const imgUrl = useImageUrl(project?.imageAttachmentId)
  const name = project?.name

  let isProvidedAccountMemberOfProject = false
  if (accountId && collaborators) {
    if (
      collaborators.find(collaborator => {
        return collaborator.accountId === accountId
      })
    ) {
      isProvidedAccountMemberOfProject = true
    }
  }

  switch (variant) {
    case 'avatarAndName':
      return (
        <Flex
          flexDir="row"
          justifyContent="left"
          alignItems="center"
          mr={mr}
          mt={mt}
          mb={mb}
          w={w}
        >
          {link && (
            <Link to={link}>
              <Flex flexDir="row" alignItems="center">
                <Avatar name={name} src={imgUrl} size="sm" />
                <Flex flexDir="column" ml={3}>
                  <Flex flexDir="row" alignItems="center">
                    <Text fontStyle={!project ? 'italic' : ''}>{name}</Text>
                    <ExternalLinkIcon ml={2} color="gray.400" />
                  </Flex>
                  {accountId && isProvidedAccountMemberOfProject && (
                    <Text fontSize="xs">Account has access</Text>
                  )}
                  {accountId && !isProvidedAccountMemberOfProject && (
                    <Text fontSize="xs" color="red">
                      <b>Account has NO access</b>
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Link>
          )}
          {!link && (
            <>
              <Avatar name={name} src={imgUrl} size="sm" />

              <Text ml={3} fontStyle={!project ? 'italic' : ''}>
                {name}
              </Text>
            </>
          )}
        </Flex>
      )
    case 'plain':
      return <Avatar name={name} src={imgUrl} size="sm" mr={mr} />
    case 'owner-and-city':
      return <Avatar name={name} src={imgUrl} size="sm" mr={mr} />
  }
}
export default ProjectAvatar
