import { Container } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { impersonate } from '../../apiClients/accountsApiClient'
import AuthContext from '../../common/auth-context'
import { PATH_MY_PRIVATE_PAGES, PATH_MY_PROVIDER_PAGES } from '../../common/nav'

const ImpersonatePage = () => {
  const [search] = useSearchParams()
  const ctx = search.get('ctx')
  const authCtx = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (ctx) {
      void (async () => {
        await impersonate(ctx).then(currentUser => {
          authCtx.setCurrentUser(currentUser)
          navigate(
            currentUser.accountTypes?.includes('Provider')
              ? PATH_MY_PROVIDER_PAGES
              : PATH_MY_PRIVATE_PAGES,
          )
        })
      })()
    }
  }, [ctx])

  return (
    <Container
      alignContent="center"
      textAlign="center"
      mt="10%"
      pb={64}
    ></Container>
  )
}
export default ImpersonatePage
