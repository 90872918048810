import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerAsks.png'
import AuthContext from '../../common/auth-context'
import MyHelpRequestSection from '../../components/sections/portal/MyHelpRequestSection'

const MyHelpRequestsPage = () => {
  const { t } = useTranslation()
  const { isConsumer } = useContext(AuthContext)

  return (
    <>
      <Flex flexDir="column" width="100%">
        <Helmet>
          <title>{t('portal.sidebar.provider.helpRequests')}</title>
        </Helmet>
        <ContentPageHeader
          label={
            isConsumer
              ? `${t('portal.sidebar.menu.helpRequests').toUpperCase()}`
              : `${t('portal.sidebar.provider.helpRequests').toUpperCase()}`
          }
          h={32}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          backgroundImgSrc={headerBackground}
          bgSize="cover"
        />
      </Flex>

      <Flex
        flexDir="column"
        backgroundColor="white"
        py={6}
        mx={{ xxs: 0, tablet: '2%' }}
        maxW="container.md"
        bg="primaryBackground"
        // border="1px solid blue"
      >
        <MyHelpRequestSection />
      </Flex>
    </>
  )
}
export default MyHelpRequestsPage
