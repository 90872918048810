import { Box, Image, Tooltip } from '@chakra-ui/react'
import {
  RiBold,
  RiItalic,
  RiStrikethrough,
  RiCodeSSlashLine,
  RiEmotionLine,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiParagraph,
  RiListOrdered,
  RiListUnordered,
  RiCodeBoxLine,
  RiLink,
  RiLinkUnlink,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiImageAddLine,
  RiUnderline,
  RiSuperscript,
} from 'react-icons/ri'
import { BiText } from 'react-icons/bi'
import { BsListCheck } from 'react-icons/bs'

import { QuestionOutlineIcon } from '@chakra-ui/icons'
import addImgIcon_colored from '../../img/icons/addImgIcon_colored.svg'

export const RichTextButton: React.FC<{
  iconName: IconName
  onClick: () => void
  variant?: 'outline' | 'gost'
  tooltip?: string
  size?: string
}> = ({ iconName, onClick, variant, tooltip, size = 'xl' }) => {
  return (
    <Tooltip label={tooltip}>
      <Box
        as="button"
        type="button"
        display="flex"
        border={variant === 'outline' ? '1px' : ''}
        borderColor="gray.300"
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        _hover={{ background: 'gray.400', color: 'white' }}
        _active={{ background: 'gray.300' }}
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        onClick={onClick}
      >
        <Box cursor={'pointer'} p={1} fontSize={size}>
          {getIcon(iconName ?? '')}
        </Box>
      </Box>
    </Tooltip>
  )
}

const getIcon = (name: string) => {
  switch (name) {
    case 'RiBold':
      return <RiBold />
    case 'RiItalic':
      return <RiItalic />
    case 'RiUnderline':
      return <RiUnderline />
    case 'RiStrikethrough':
      return <RiStrikethrough />
    case 'RiCodeSSlashLine':
      return <RiCodeSSlashLine />
    case 'RiEmotionLine':
      return <RiEmotionLine />
    case 'RiH1':
      return <RiH1 />
    case 'RiH2':
      return <RiH2 />
    case 'RiH3':
      return <RiH3 />
    case 'RiH4':
      return <RiH4 />
    case 'RiH5':
      return <RiH5 />
    case 'RiH6':
      return <RiH6 />
    case 'RiParagraph':
      return <RiParagraph />
    case 'RiListOrdered':
      return <RiListOrdered />
    case 'RiListUnordered':
      return <RiListUnordered />
    case 'RiCodeBoxLine':
      return <RiCodeBoxLine />
    case 'RiLink':
      return <RiLink />
    case 'RiLinkUnlink':
      return <RiLinkUnlink />
    case 'RiDoubleQuotesL':
      return <RiDoubleQuotesL />
    case 'RiSeparator':
      return <RiSeparator />
    case 'RiTextWrap':
      return <RiTextWrap />
    case 'RiFormatClear':
      return <RiFormatClear />
    case 'RiArrowGoBackLine':
      return <RiArrowGoBackLine />
    case 'RiArrowGoForwardLine':
      return <RiArrowGoForwardLine />
    case 'RiImageAddLine':
      return <RiImageAddLine />
    case 'BiText':
      return <BiText />
    case 'BsListCheck':
      return <BsListCheck />
    case 'RiSuperscript':
      return <RiSuperscript />
    case 'addImgIcon_colored':
      return <Image src={addImgIcon_colored} boxSize={8} />
    default:
      return <QuestionOutlineIcon />
  }
}

export type IconName =
  | 'RiBold'
  | 'RiItalic'
  | 'RiStrikethrough'
  | 'RiCodeSSlashLine'
  | 'RiEmotionLine'
  | 'RiH1'
  | 'RiH2'
  | 'RiH3'
  | 'RiH4'
  | 'RiH5'
  | 'RiH6'
  | 'RiParagraph'
  | 'RiListOrdered'
  | 'RiListUnordered'
  | 'RiCodeBoxLine'
  | 'RiLink'
  | 'RiLinkUnlink'
  | 'RiDoubleQuotesL'
  | 'RiSeparator'
  | 'RiTextWrap'
  | 'RiFormatClear'
  | 'RiArrowGoBackLine'
  | 'RiArrowGoForwardLine'
  | 'RiImageAddLine'
  | 'RiUnderline'
  | 'addImgIcon_colored'
  | 'BiText'
  | 'BsListCheck'
  | 'RiSuperscript'
