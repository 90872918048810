import { Center } from '@chakra-ui/react'

const SearchLetter: React.FC<{
  letter: string
  isActive?: boolean
  onClick: (letter: string) => void
}> = ({ letter, isActive = false, onClick }) => {
  return (
    <Center
      borderRadius="full"
      h={6}
      bg={isActive ? 'secondary' : 'transparent'}
      color={isActive ? 'white' : 'black'}
      onClick={() => {
        onClick(letter)
      }}
      _hover={{ cursor: 'pointer', bg: isActive ? 'secondary' : 'gray.300' }}
      w={6}
      m={1}
    >
      {letter}
    </Center>
  )
}
export default SearchLetter
