import { Flex, ResponsiveValue, Show, Text } from '@chakra-ui/react'
import { Organization } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import { useOrganizationProfile } from '../../apiClients/organizationsApiClient'
import { Icon } from '../ui/Icon'
import Skeleton from '../ui/Skeleton'

const OrganizationAvailableWhereCard: React.FC<{
  org: Organization
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  gap?: ResponsiveValue<number>
  border?: string
  textStyle?: string
}> = ({ org, px, py, gap, border, textStyle = 'infoText' }) => {
  const { t } = useTranslation()

  const { data: orgProfile } = useOrganizationProfile(org.id)

  if (org) {
    return (
      <Flex
        flexDir="row"
        alignItems="center"
        wrap="nowrap"
        px={px}
        py={py}
        border={border}
        gap={gap}
        textStyle={textStyle}
      >
        {org.address?.city && <Text>{org.address?.city}</Text>}
        {orgProfile?.provideRemoteServices && (
          <>
            <Text>+</Text>
            <Show above="tablet">
              <Icon
                name="videoMeeting"
                tooltip={t('providers.profile.offerHeadingDistanceShort')}
              />
            </Show>
            <Show below="tablet">
              <Icon
                name="videoMeeting"
                tooltip={t('providers.profile.offerHeadingDistanceShort')}
                h={4}
              />
            </Show>
          </>
        )}
      </Flex>
    )
  } else {
    return <Skeleton />
  }
}
export default OrganizationAvailableWhereCard
