import { Box, Flex, Image } from '@chakra-ui/react'
import { useState } from 'react'
import BuildingPartCard from '../../buildingPartsClimateImpact/BuildingPartCard'
import ClickableTarget from '../../buildingPartsClimateImpact/ClickableTarget'
import buildingPartsClimateImpact from './../../../img/pages/buildingPartsClimateImpactPage/buildingPartsClimateImpactSection_l.jpg'

const BuildingPartsClimateImpactSection = () => {
  const [showRoof, setShowRoof] = useState(false)
  const [showBase, setShowBase] = useState(false)
  const [showFacade, setShowFacade] = useState(false)
  const [showStructure, setShowStructure] = useState(true)
  const [showStructureCompletion, setShowStructureCompletion] = useState(false)
  const [showRoomCompletion, setShowRoomCompletion] = useState(false)
  const [showInstallations, setShowInstallations] = useState(false)
  const [showEnergy, setShowEnergy] = useState(false)

  return (
    <Flex
      pos="relative"
      flexDir="row"
      w="100%"
      maxW="container.xl"
      bg="white"
      mb={96}
    >
      <Image src={buildingPartsClimateImpact} pt={20} />

      {/* **************************************** */}
      {/* Clickable targets for all building parts */}
      {/* **************************************** */}

      {/* Roof - yttertak */}
      <Box pos="absolute" top={{ mobile: '29%', desktop: '25%' }} left="39%">
        <ClickableTarget
          onShow={showRoof}
          toggleShow={() => {
            setShowRoof(!showRoof)
          }}
        />
      </Box>

      {/* Base - Husunderbyggnad */}
      <Box
        pos="absolute"
        bottom={{ mobile: '19%', desktop: '23%' }}
        left={'55%'}
      >
        <ClickableTarget
          onShow={showBase}
          toggleShow={() => {
            setShowBase(!showBase)
          }}
        />
      </Box>
      {/* Facade - fasad */}
      <Box
        pos="absolute"
        top="46%"
        left={{ mobile: '59.5%', tablet: '61%', laptop: '61%' }}
      >
        <ClickableTarget
          onShow={showFacade}
          toggleShow={() => {
            setShowFacade(!showFacade)
          }}
        />
      </Box>

      {/* Installations - installationer */}
      <Box
        pos="absolute"
        bottom={{ mobile: '44%', laptop: '47%', desktop: '49%' }}
        left={{ mobile: '39%', desktop: '41%' }}
      >
        <ClickableTarget
          onShow={showInstallations}
          toggleShow={() => {
            setShowInstallations(!showInstallations)
          }}
        />
      </Box>
      {/* Structure Completion - Stomkomplettering  */}
      <Box pos="absolute" top="69%" left={'37%'}>
        <ClickableTarget
          onShow={showStructureCompletion}
          toggleShow={() => {
            setShowStructureCompletion(!showStructureCompletion)
          }}
        />
      </Box>
      {/* Room Completion - rumskomplettering */}
      <Box pos="absolute" top="61%" left={'25%'}>
        <ClickableTarget
          onShow={showRoomCompletion}
          toggleShow={() => {
            setShowRoomCompletion(!showRoomCompletion)
          }}
        />
      </Box>

      {/* Energy - energi */}
      <Box pos="absolute" bottom="41%" right={'10%'}>
        <ClickableTarget
          onShow={showEnergy}
          toggleShow={() => {
            setShowEnergy(!showEnergy)
          }}
        />
      </Box>

      <Box pos="absolute" bottom="50%" left={'16%'}>
        <ClickableTarget
          onShow={showStructure}
          toggleShow={() => {
            setShowStructure(!showStructure)
          }}
        />
      </Box>

      {/* **************************** */}
      {/* Cards for all Building Parts */}
      {/* **************************** */}
      <Box
        pos="absolute"
        display={showStructure ? '' : 'none'}
        top={{
          xxs: '-130%',
          mobile: '-110%',
          tablet: '-55%',
          laptop: '-35%',
          desktop: '-20%',
        }}
        left={{ xxs: '14%', tablet: 6 }}
      >
        <BuildingPartCard
          code={'structure'}
          onClose={() => {
            setShowStructure(false)
          }}
        />
      </Box>

      <Box
        pos="absolute"
        display={showFacade ? '' : 'none'}
        top={{ xxs: '-80%', tablet: '-30%', laptop: '-18%', desktop: '-8%' }}
        left={{
          xxs: '14%',
          tablet: '39%',
          laptop: '49%',
          desktop: '51%',
        }}
      >
        <BuildingPartCard
          code={'facade'}
          onClose={() => {
            setShowFacade(false)
          }}
        />
      </Box>
      <Box
        pos="absolute"
        display={showRoomCompletion ? '' : 'none'}
        bottom={{
          xxs: '-56%',
          tablet: '-45%',
          laptop: '-35%',
          desktop: '-30',
        }}
        left={'4%'}
      >
        <BuildingPartCard
          code={'roomCompletion'}
          onClose={() => {
            setShowRoomCompletion(false)
          }}
          w={80}
        />
      </Box>

      <Box
        pos="absolute"
        display={showStructureCompletion ? '' : 'none'}
        bottom={{
          xxs: '-96%',
          mobile: '-80%',
          tablet: '-50%',
          laptop: '-26%',
          desktop: '-10%',
        }}
        left={{ xxs: '4%', mobile: '4%', tablet: '28%' }}
      >
        <BuildingPartCard
          code={'structureCompletion'}
          onClose={() => {
            setShowStructureCompletion(false)
          }}
          w="80"
        />
      </Box>
      <Box
        pos="absolute"
        display={showRoof ? '' : 'none'}
        top={{ xxs: '-80%', tablet: '-40%', laptop: '-23%', desktop: '-14%' }}
        left={{ xxs: '12%', tablet: '25%' }}
      >
        <BuildingPartCard
          code={'roof'}
          onClose={() => {
            setShowRoof(false)
          }}
        />
      </Box>
      <Box
        pos="absolute"
        display={showBase ? '' : 'none'}
        bottom={{
          xxs: '-90%',
          mobile: '-75%',
          tablet: '-60%',
          laptop: '-20%',
          desktop: '-10%',
        }}
        right={{ xxs: '6%', tablet: '24%' }}
      >
        <BuildingPartCard
          code={'base'}
          onClose={() => {
            setShowBase(false)
          }}
          w={80}
        />
      </Box>
      <Box
        pos="absolute"
        display={showInstallations ? '' : 'none'}
        top={{ xxs: '-80%', tablet: '-30%', laptop: '2%', desktop: '12%' }}
        right={'1%'}
      >
        <BuildingPartCard
          code={'installations'}
          onClose={() => {
            setShowInstallations(false)
          }}
        />
      </Box>
      <Box
        pos="absolute"
        display={showEnergy ? '' : 'none'}
        bottom={{
          xxs: '-60%',
          tablet: '-30%',
          laptop: '0%',
          desktop: '10%',
        }}
        right={2}
      >
        <BuildingPartCard
          code={'energy'}
          onClose={() => {
            setShowEnergy(false)
          }}
        />
      </Box>
    </Flex>
  )
}
export default BuildingPartsClimateImpactSection
