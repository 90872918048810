import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import {
  useProperty,
  usePropertyTypes,
} from '../../apiClients/propertiesApiClient'
import { PATH_ADMIN_PROPERTIES } from '../../common/nav'
import { useImageUrl } from '../attachments/attachmentHelper'
import TextCard from '../ui/TextCard'
import AddressCard from './AddressCard'

const AdminPropertyCard: React.FC<{
  propertyId: string
  mt?: number
  mr?: ResponsiveValue<number | string>
  mb?: number
  ml?: number
  h?: number | string
  key?: number | string
  w?: number | string
}> = ({ propertyId, mt, mr, mb, ml, h = 40, w = 240 }) => {
  // const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: property } = useProperty(propertyId)
  const imgUrl = useImageUrl(property?.imageAttachmentId)
  const { data: propertyTypes } = usePropertyTypes()

  return (
    <Flex columnGap={4}>
      <Flex
        pos="relative"
        color="white"
        bg="primary_grey02"
        mt={mt}
        mr={mr}
        mb={mb}
        ml={ml}
        h={h}
        w={w}
        borderRadius="lg"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        onClick={() => {
          navigate(`${PATH_ADMIN_PROPERTIES}/?fastighet=${propertyId}`)
        }}
        _hover={{
          boxShadow: '2xl',
          transform: 'translate(-1px,-1px)',
          cursor: 'pointer',
        }}
        boxShadow="lg"
        bgImage={imgUrl}
        bgPos="center"
        bgBlendMode="overlay"
        bgSize="cover"
      >
        <Text textStyle="h4" color="black">
          {property?.name}
          <br />
          {`(${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            propertyTypes &&
            propertyTypes.find(pt => {
              return pt.id === property?.propertyTypeId
            })?.name
          })`}
        </Text>
      </Flex>
      <TextCard
        mr={4}
        mt={4}
        // heading={`${t('admin.memberOfProperty')}}`}
        heading="Adress"
        variant="border"
        // minW="container.sm"
        px={4}
        // headerBg={bg}
      >
        <>
          {/* <Text>{property?.propertyTypeId}</Text> */}
          <AddressCard address={property?.address} noHeader />
        </>
      </TextCard>
    </Flex>
  )
}
export default AdminPropertyCard
