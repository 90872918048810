import { Box } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'

interface MentionItemProps extends React.ComponentPropsWithoutRef<'div'> {
  isActive: boolean
}

export const MentionItem = ({
  isActive,
  // className,
  style,

  ...props
}: MentionItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: 'nearest' })
    }
  }, [isActive])

  return (
    <Box
      ref={ref}
      // className={'mentionsItem' + (className ? ` ${className}` : '')}
      px={2}
      py={1}
      maxH={10}
      style={{
        backgroundColor: isActive ? 'lightgrey' : undefined,
        ...style,
      }}
      {...props}
    />
  )
}
