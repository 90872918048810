import { Box, Flex, Show, Text } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { usePropertyAddress } from '../../apiClients/propertiesApiClient/properties'
import { getDateStrFromDate } from '../../common/util'
import ProjectClientsCard from '../projects/ProjectClientsCard'
import HelpRequestStatusIcon from './HelpRequestStatusIcon'
import HelpRequestStatusMessage from './HelpRequestStatusMessage'

const HelpRequestProviderRowCard: React.FC<{
  helpReq: HelpRequestResponse
}> = ({ helpReq }) => {
  const helpReqTemplate = helpReq.helpRequestTemplate

  const { data: project } = useProjectInfo(helpReqTemplate?.projectId)
  const { data: address } = usePropertyAddress(project?.propertyId)
  return (
    <Flex
      flexDir="column"
      pl={{ xxs: 2, tablet: 4 }}
      // border="1px solid green"
      w="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Show above="desktop">
          {helpReqTemplate?.helpTimestamp && (
            <Text textStyle="textSmallRegular" w={28}>
              {getDateStrFromDate(new Date(helpReqTemplate.helpTimestamp))}
            </Text>
          )}
          {!helpReqTemplate?.helpTimestamp && <Box w={28}></Box>}
        </Show>
        <Text
          flexWrap="wrap"
          w={{ xxs: 40, tablet: 40 }}
          // border="1px solid red"
        >
          {helpReq.name}
        </Text>
        <Show above="desktop">
          {helpReqTemplate?.projectId && (
            <ProjectClientsCard
              projectId={helpReqTemplate.projectId}
              flexDir="column"
              variant="name"
              w={32}
            />
          )}
        </Show>

        <Text textStyle="textSmallRegular" mr={3} w={28}>
          {address?.city}
        </Text>

        <HelpRequestStatusIcon helpReq={helpReq} />
      </Flex>
      <HelpRequestStatusMessage helpReq={helpReq} />
    </Flex>
  )
}
export default HelpRequestProviderRowCard
