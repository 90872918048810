import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
} from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'

import { useSearchParams } from 'react-router-dom'
import { AccountSortingOption } from '../../common/enums'
import { useAccounts } from '../../apiClients/accountsApiClient'

import SortableHeading from '../../components/ui/SortableHeading'
import AccountAdminSummaryCard from '../../components/accounts/AccountAdminSummaryCard'
import AccountRoleBadge from '../../components/accounts/AccountRoleBadge'
import { calculateSortedItems } from '../../components/accounts/accountListUtils'
import AccountAvatar from '../../components/accounts/AccountAvatar'

const ListAccounts: React.FC<{
  accountType?: 'Consumer' | 'Provider' | undefined
}> = ({ accountType }) => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const searchedAccountId = search.get('konto')
  const [sortType, setSortType] = useState(
    AccountSortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const { data: allAccounts } = useAccounts(accountType)
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const sortedAccounts = calculateSortedItems(sortType, allAccounts, searchStr)

  const getAccountTypeBadges = (account: AccountData) => {
    if (!account.accountTypes) {
      return ''
    }
    return (
      <Stack>
        {account.accountTypes.map(at => {
          return <AccountRoleBadge key={at} role={at} />
        })}
      </Stack>
    )
  }
  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('accounts.registeredAccounts')}</title>
      </Helmet>

      <Flex flexDir="column" flexWrap="wrap" mt={6} mb={6}>
        <InputGroup width={80}>
          <InputLeftElement
            pointerEvents="none"
            children={<FaSearch color="gray.200" />}
          />
          <Input
            type="text"
            placeholder={t('accounts.searchHomes')}
            borderColor="gray.400"
            height={10}
            onChange={e => setSearchStr(e.target.value)}
          />
        </InputGroup>
        <Text ml={4} textStyle="textMedium">
          {t('accounts.searchInfo', {
            total: allAccounts?.length ?? 0,
            filtered: sortedAccounts.length ?? 0,
          })}
        </Text>
      </Flex>

      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <TableCaption>{t('accounts.listingBerikarAccounts')}</TableCaption>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>
              <SortableHeading>
                {t('accounts.accountForm.accountType')}
              </SortableHeading>
            </Th>
            <Th></Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(AccountSortingOption.EMAIL_ASC)}
                onDescSort={() => setSortType(AccountSortingOption.EMAIL_DESC)}
              >
                {t('accounts.accountForm.email')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AccountSortingOption.FIRST_NAME_ASC)
                }
                onDescSort={() =>
                  setSortType(AccountSortingOption.FIRST_NAME_DESC)
                }
              >
                {t('accounts.accountForm.firstName')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AccountSortingOption.LAST_NAME_ASC)
                }
                onDescSort={() =>
                  setSortType(AccountSortingOption.LAST_NAME_DESC)
                }
              >
                {t('accounts.accountForm.lastName')}
              </SortableHeading>
            </Th>
            {/* <Th>
              <SortableHeading
                onAscSort={() => setSortType(AccountSortingOption.PHONE_ASC)}
                onDescSort={() => setSortType(AccountSortingOption.PHONE_DESC)}
              >
                {t('accounts.accountForm.phoneNumberShort')}
              </SortableHeading>
            </Th> */}
            <Th>
              <SortableHeading>{t('accounts.status')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AccountSortingOption.LAST_LOGIN_ASC)
                }
                onDescSort={() =>
                  setSortType(AccountSortingOption.LAST_LOGIN_DESC)
                }
              >
                {t('accounts.lastLogin')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading>{t('accounts.id')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AccountSortingOption.CREATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(AccountSortingOption.CREATED_DATE_DESC)
                }
              >
                {t('accounts.createdAt')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AccountSortingOption.UPDATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(AccountSortingOption.UPDATED_DATE_DESC)
                }
              >
                {t('accounts.updatedAt')}
              </SortableHeading>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedAccounts && sortedAccounts.length > 0 ? (
            sortedAccounts.map((account, i) => {
              let bg: string
              if (account.id === searchedAccountId) {
                bg = 'red.100'
              } else {
                bg = i % 2 !== 0 ? 'white' : 'primary_grey01'
              }

              return (
                <Fragment key={account.id}>
                  <Tr
                    // bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    bg={bg}
                    data-cy="accountTag"
                  >
                    <Td
                      color="primary_orange02"
                      _hover={{ color: 'primary_orange01' }}
                    >
                      {!accordionsToShow.includes(account.id) && (
                        <FaRegPlusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(account.id)
                          }}
                        />
                      )}
                      {accordionsToShow.includes(account.id) && (
                        <FaRegMinusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(account.id)
                          }}
                        />
                      )}
                    </Td>
                    <Td>{getAccountTypeBadges(account)}</Td>
                    <Td>
                      <AccountAvatar
                        accountId={account.id}
                        variant={'avatar'}
                      />
                    </Td>
                    <Td>{account.email}</Td>
                    <Td>{account.firstName}</Td>
                    <Td>{account.lastName}</Td>
                    {/* <Td>{account.phoneNumber}</Td> */}
                    <Td>{account.status}</Td>
                    {/* <Td>{account.lastLoginAt}</Td> */}
                    <Td>
                      {account.lastLoginAt &&
                        new Date(account.lastLoginAt ?? 0).toLocaleString(
                          'sv-SE',
                        )}
                    </Td>
                    <Td>
                      <Tooltip
                        hasArrow
                        label={account.id}
                        bg="primaryBackground"
                        color="black"
                        cursor="pointer"
                        placement="top"
                      >
                        <Box>{account.id.substring(0, 15)}...</Box>
                      </Tooltip>
                    </Td>

                    <Td>
                      {new Date(account.createdAt).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      {new Date(account.updatedAt).toLocaleString('sv-SE')}
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(account.id) && (
                    <Tr bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}>
                      <Td colSpan={11}>
                        <AccountAdminSummaryCard
                          account={account}
                          bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={9}>{t('accounts.noAccountsFound')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListAccounts
