import {
  Attachment,
  ConversationArea,
  CreateAttachmentRequest,
  CreateAttachmentResponse,
  CreateMessageRequest,
  CreateMessageResponse,
  Message,
  UpdateMessageRequest,
} from '@contracts/support'
import axios, { AxiosResponse } from 'axios'
import { useContext } from 'react'
import useSWR, { SWRResponse } from 'swr'
import AuthContext from '../common/auth-context'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL as string

export const useAttachments = (
  area: 'Article' | 'Account' | 'Property' | 'Project' | 'Note',
  prefix?: string,
): SWRResponse<Attachment[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/attachments?area=${area}${prefix ? `&prefix=${prefix}` : ''}`)
  return { data, error, isValidating, mutate }
}

export const useAttachment = (
  attachmentId: string,
): SWRResponse<Attachment | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<Attachment | undefined>(
    `${baseUrl}/attachments/${attachmentId}`,
  )
  return { data, error, isValidating, mutate }
}

export const useAccountAttachments = (
  accountId: string,
): SWRResponse<Attachment[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/admin/attachments/acccounts/${accountId}`)
  return { data, error, isValidating, mutate }
}

export const useAllAttachments = (): SWRResponse<Attachment[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/attachments`)
  return { data, error, isValidating, mutate }
}

export const useConversations = (
  conversationId: string | undefined,
): SWRResponse<Message[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<Message[] | undefined>(
    conversationId
      ? `${baseUrl}/conversations/${conversationId}/messages`
      : null,
  )
  return { data, error, isValidating, mutate }
}

export const useConversationsByArea = (
  area: ConversationArea | undefined,
  areaIds: string[] | undefined,
  excludeSystemMessages?: boolean,
): SWRResponse<Message[] | undefined> => {
  // eslint-disable-next-line no-console
  // console.log('area: ', area)
  // eslint-disable-next-line no-console
  // console.log('areaIds: ', areaIds)
  const { isAdmin } = useContext(AuthContext)
  const querySring = encodeURIComponent(JSON.stringify(areaIds)) ?? ''
  const returnNull: boolean =
    area === undefined || areaIds === null || areaIds?.length === 0
  let url: string
  if (excludeSystemMessages) {
    url = `${baseUrl}/conversations/messages?area=${
      area ?? ''
    }&areaIds=${querySring}&excludeSystemMessages=true`
  } else {
    url = `${baseUrl}/conversations/messages?area=${
      area ?? ''
    }&areaIds=${querySring}`
  }
  const { data, error, isValidating, mutate } = useSWR<Message[] | undefined>(
    // isAdmin ? (areaIds?.length ? url : null) : areaIds?.length ? url : null,
    isAdmin ? (returnNull ? null : url) : returnNull ? null : url,
    { refreshInterval: 60000 },
  )
  return { data, error, isValidating, mutate }
}

export const useIsUserAllowedToDeleteConversation = (
  conversationId: string | undefined,
): SWRResponse<boolean | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<boolean | undefined>(
    conversationId
      ? `${baseUrl}/conversations/${conversationId}/isUserAllowedToDelete`
      : { data: true },
  )
  return { data, error, isValidating, mutate }
}

export async function addAttachment(
  requestData: FormData,
  isPrivate?: boolean,
): Promise<Attachment> {
  const uri = isPrivate
    ? `${baseUrl}/attachments?private=true`
    : `${baseUrl}/attachments`

  return axios
    .post<CreateAttachmentRequest, AxiosResponse<CreateAttachmentResponse>>(
      uri,
      requestData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error storing attachment')
      throw error
    })
}
// export async function addMessage(conversationId: string, message: CreateMessageRequest
export const postMessage = async (
  requestData: CreateMessageRequest,
): Promise<CreateMessageResponse> => {
  return axios
    .post<CreateMessageRequest, AxiosResponse<CreateMessageResponse>>(
      `${baseUrl}/messages`,
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new message', status, data)
      return data
    })
}

export const updateMessage = async (
  requestData: UpdateMessageRequest,
): Promise<UpdateMessageRequest> => {
  return axios
    .put<UpdateMessageRequest, AxiosResponse<UpdateMessageRequest>>(
      `${baseUrl}/messages/${requestData.id}`,
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for updating message', status, data)
      return data
    })
}

export const deleteConversation = async (
  conversationId: string,
): Promise<Message> => {
  return axios
    .delete<Message, AxiosResponse<Message>>(
      `${baseUrl}/conversations/${conversationId}`,
    )
    .then(({ status, data }) => {
      log.info('Response from server for deleting conversation', status, data)
      return data
    })
}

export const deleteMessage = async (messageId: string): Promise<Message> => {
  return axios
    .delete<Message, AxiosResponse<Message>>(`${baseUrl}/messages/${messageId}`)
    .then(({ status, data }) => {
      log.info('Response from server for deleting message', status, data)
      return data
    })
}

export const updateFilename = async (
  id: string,
  newFilename: string,
): Promise<Attachment> => {
  return axios
    .put(
      `${baseUrl}/attachments/${id}`,
      {
        originalFilename: newFilename,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating attachment')
      throw error
    })
}

export const deleteAttachment = async (id: string, isPrivate?: boolean) => {
  const uri = isPrivate
    ? `${baseUrl}/attachments/${id}?private=true`
    : `${baseUrl}/attachments/${id}`
  await axios.delete(uri).catch((error: Error) => {
    log.error({ err: error }, 'Error deleting attachment')
    return error.message
  })
}
