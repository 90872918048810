import { Box, HStack, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
const Skeleton: React.FC<{ w?: 'sm' | 'md' | 'lg' | string | number }> = ({
  w = 'sm',
}) => {
  return (
    <HStack
      flexDir="row"
      justifyContent="left"
      pl="6"
      // width="100%"
      width={w}
      spacing={12}
    >
      <Box width="15%">
        <SkeletonCircle size="3" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
      <Box width="15%">
        <SkeletonCircle size="3" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
      <Box width="15%">
        <SkeletonCircle size="3" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
      <Box width="15%">
        <SkeletonCircle size="3" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
    </HStack>
  )
}
export default Skeleton
