import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import ListOrganizations from './ListOrganizations'

const ManageOrganizations = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const orgId = search.get('foretag')

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('providers.manageOrganizations')}</title>
      </Helmet>

      <ListOrganizations searchOrg={orgId ?? undefined} />
    </Box>
  )
}
export default ManageOrganizations
