import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import HtmlPane from '../../components/richTextEditor/HtmlPane'
import Tiptap from '../../components/richTextEditor/Tiptap'

const ManageTiptap = () => {
  const { t } = useTranslation()
  const [message, setMessage] = useState(' ')
  const width = 'container.md'

  return (
    <Flex flexDir="column" rowGap={4}>
      <Tiptap
        toolbarVariant="basic"
        content={message}
        onChange={text => {
          setMessage(text)
        }}
        w={width}
        placeholder={t('messages.tiptapPlaceholder')}
        mt={4}
        border="1px solid"
        borderColor="gray.300"
        withMentionSuggestion={true}
        area="Test"
        container={'private'}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onImgAdded={() => {}}
      />
      <HtmlPane variant="HTML_OUTPUT" content={message} w={width} />
      <HtmlPane content={message} w={width} />
    </Flex>
  )
}
export default ManageTiptap
