import { Flex, Text } from '@chakra-ui/react'
import { ArticleInfo, Tag } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import ArticleInfoCard from '../articles/ArticleInfoCard'

const TagCard: React.FC<{
  tag: Tag
  allArticlesInfo: ArticleInfo[]
}> = ({ tag, allArticlesInfo }) => {
  const { t } = useTranslation()
  const filteredArticles = allArticlesInfo?.filter(article => {
    return article.tags.find(t => {
      return t.id === tag.id
    })
  })

  if (!tag) {
    return null
  }

  return (
    <Flex flexDir="column">
      {filteredArticles.length > 0 && (
        <Text>{`${t('articles.articlesWithThisTag')} (${
          filteredArticles.length
        })`}</Text>
      )}
      {filteredArticles.length === 0 && (
        <Text>{t('articles.noArticlesWithThisTag')}</Text>
      )}
      <Flex flexDir="row" width="100%" flexWrap="wrap" pt={4}>
        {filteredArticles.map(article => {
          return <ArticleInfoCard key={article.id} article={article} />
        })}
      </Flex>
    </Flex>
  )
}
export default TagCard
