import { ReactElement } from 'react'
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'

const Modal: React.FC<{
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  children: ReactElement
  pt?: number | string
  px?: number | string
  py?: number | string
  mb?: number | string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '3xl' | '5xl' | '7xl' | 'full'
  fixedSize?: boolean
}> = ({
  isOpen,
  onClose,
  children,
  size = 'xl',
  pt,
  px = { base: 0, sm: 0, md: 5 },
  py,
  mb = { xxs: 64, laptop: 0 },
  fixedSize,
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={
        fixedSize
          ? { xxs: 'sm', laptop: 'md' }
          : { base: 'full', sm: 'full', md: size }
      }
    >
      <ModalOverlay />
      <ModalContent pt={pt} border="none">
        <ModalCloseButton />
        <ModalBody px={px} mb={mb} py={py} border="none">
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}
export default Modal
