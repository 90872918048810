export const ButtonStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      fontSize: ['16px', '16px'],
      fontWeight: 'semibold',
      _hover: {
        bg: 'primaryDark',
        boxShadow: 'lg',
      },
    },
    secondary: {
      bg: 'secondary',
      color: 'white',
      fontSize: ['16px', '16px'],
      fontWeight: 'bold',
      _hover: {
        bg: 'secondaryDark',
        boxShadow: 'lg',
      },
    },
    disabled: {
      bg: 'primary',
      color: 'lightgray',
      fontSize: ['16px', '18px'],
      fontWeight: 'semibold',
      cursor: 'not-allowed',
    },
    outlined: {
      color: 'primary_grey04',
      border: '1px solid',
      borderColor: 'primary_grey04',
      fontSize: ['16px', '16px'],
      fontWeight: 'bold',
      _hover: {
        boxShadow: 'md',
        bg: 'primary_grey01',
        borderColor: 'primary_grey04',
        fontWeight: 'semibold',
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
}
