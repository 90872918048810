import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import AddConstructionGlossary from '../../components/constructionGlossary/AddConstructionGlossaryItemButton'
import ListConstructionGlossary from '../../components/constructionGlossary/ListConstructionGlossary'

const ManageConstructionGlossary = () => {
  const { t } = useTranslation()

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('constructionGlossary.manageGlossary')}</title>
      </Helmet>
      <Box pos="absolute" top={12} right={20} zIndex="hide">
        <AddConstructionGlossary w={44}>
          {t('constructionGlossary.addGlossaryItem')}
        </AddConstructionGlossary>
      </Box>
      <ListConstructionGlossary />
    </Box>
  )
}
export default ManageConstructionGlossary
