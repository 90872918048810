import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useProjects } from '../../apiClients/projectsApiClient'

import ProjectCard from '../projects/ProjectFrontCard'

const ListProjectsSection: React.FC<{
  px?: ResponsiveValue<number | string>
}> = ({ px }) => {
  const { data: projects } = useProjects()

  return (
    <Flex flexDir="row" px={px} gap={{ xxs: 4, laptop: 8 }} wrap="wrap">
      {projects &&
        projects.length > 0 &&
        projects.map(project => {
          return (
            <ProjectCard
              projectId={project.id}
              key={project.id}
              h="160px"
              w="160px"
            />
          )
        })}
    </Flex>
  )
}
export default ListProjectsSection
