import { useContext } from 'react'
import { Heading, Text, Image, Box, Badge, Flex } from '@chakra-ui/react'
import { getDateStrFromDate } from '../../common/util'
import { useArticle } from '../../apiClients/articlesApiClient'
import AuthorProfile from '../articles/AuthorProfile'
import SocialMediaShareComponent from '../articles/SocialMediaShareComponent'
import HtmlPane from '../richTextEditor/HtmlPane'
import { Icon } from '../ui/Icon'
import './../../styles/RichText.scss'
import AuthContext from '../../common/auth-context'
import fallbackImg from './../../img/articles/house_green-hanging-plants.png'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ArticleTemplate1: React.FC<{
  articleId?: string
  w?: string
  minW?: string
  maxW?: string
  transform?: string
  onClose?: () => void
}> = ({ articleId, w = '100%', minW, maxW, transform, onClose }) => {
  const { isAdmin } = useContext(AuthContext)
  const { data: article } = useArticle(articleId, isAdmin)

  const getImgId = () => {
    if (article?.attachmentIdLarge) {
      return article.attachmentIdLarge
    }
    if (article?.attachmentIdMedium) {
      return article.attachmentIdMedium
    }
    if (article?.attachmentIdSmall) {
      return article.attachmentIdSmall
    }
    return undefined
  }
  const imgId = getImgId()
  return (
    <Flex
      width="100%"
      position="relative"
      flexDir="column"
      w={w}
      minW={minW}
      maxW={maxW}
      transform={transform}
    >
      {article && (
        <>
          {imgId && (
            <Image
              src={`${baseUrl}${imgId}`}
              height="245px"
              objectFit="cover"
            />
          )}
          {!imgId && (
            <Image src={fallbackImg} height="245px" objectFit="cover" />
          )}
          <Box pos="absolute" top={4} left={4} bg="gray.300" zIndex="tooltip">
            <Icon name={'AiOutlineClose'} onClick={onClose} />
          </Box>
          <Flex
            flexDir="column"
            textAlign={{ mobile: 'center', tablet: 'left' }}
            m={{
              mobile: '4rem 1rem',
              tablet: '4rem 5rem',
              laptop: '4rem 7rem',
              desktop: '4rem 8rem',
            }}
            position="absolute"
            top={0}
            left={0}
          >
            <Heading
              as="h1"
              size={{ mobile: 'xl', table: '4xl', desktop: '3xl' }}
              fontWeight="900"
              color="white"
              pb={{ mobile: 4, tablet: 4, desktop: 8 }}
            >
              {article.heading.toUpperCase()}
            </Heading>
            <Flex
              flexDir={{ tablet: 'row' }}
              alignItems="center"
              flexWrap="wrap"
            >
              {article.tags?.map(tag => {
                return (
                  <Badge
                    key={tag.id}
                    textStyle="h2"
                    p=".1rem .6rem"
                    mx={2}
                    color="gray.600"
                    mb={{ xxs: 1, laptop: 3 }}
                  >
                    {tag.name}
                  </Badge>
                )
              })}
            </Flex>
          </Flex>
          {article.publishedAt && (
            <Text mt={8} ml={20} textStyle="textThin">
              Publicerad {getDateStrFromDate(new Date(article.publishedAt))}
            </Text>
          )}
          <Flex
            flexDir={{ mobile: 'column', tablet: 'column', desktop: 'row' }}
          >
            <Box p={{ mobile: '2rem 1rem', laptop: '4rem 1rem 4rem 8rem' }}>
              <Box overflow="clip">
                <HtmlPane
                  content={article.intro}
                  bg="transparant"
                  lineHeight="default"
                />
              </Box>
              <br></br>
              <br></br>
              <Flex px={{ mobile: 2, tablet: 7 }} overflow="clip">
                {article && article.content && (
                  <HtmlPane content={article.content} bg="transparent" />
                )}
              </Flex>
            </Box>
            {article.author && (
              <Box
                display="flex"
                flexDir="column"
                p={{ mobile: '2rem 1rem', laptop: '4rem 2rem 4rem 2rem' }}
                minW={64}
                alignItems="center"
                textAlign="center"
              >
                <AuthorProfile author={article.author} />
                <SocialMediaShareComponent mt={16} mb={4} />
              </Box>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
export default ArticleTemplate1
