import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { CreateNoteRequest, NoteType, Note } from '@contracts/misc'

import { useContext } from 'react'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROJECTS_SERVICE_URL

export const useNoteTypes = (): SWRResponse<NoteType[] | undefined> => {
  return useSWRImmutable<NoteType[] | undefined>(`${baseUrl}/note-types`)
}

export const useNotes = (
  projectId: string | undefined,
  noteTypeIds?: string[],
): SWRResponse<Note[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  let queryStr = ''
  let noteTypeIdsStr = ''
  if (!projectId) {
    queryStr = ''
  } else {
    if (noteTypeIds?.length) {
      noteTypeIdsStr = encodeURIComponent(JSON.stringify(noteTypeIds))

      if (isAdmin) {
        queryStr = `${baseUrl}/admin/projects/${projectId}/notes?noteTypeIds=${noteTypeIdsStr}`
      } else {
        queryStr = `${baseUrl}/projects/${projectId}/notes?noteTypeIds=${noteTypeIdsStr}`
      }
    } else {
      if (isAdmin) {
        queryStr = `${baseUrl}/admin/projects/${projectId}/notes`
      } else {
        queryStr = `${baseUrl}/projects/${projectId}/notes`
      }
    }
  }

  return useSWR<Note[] | undefined>(projectId ? queryStr : null)
}

export const useNote = (
  noteId: string | undefined,
): SWRResponse<Note | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<Note | undefined>(
    isAdmin
      ? noteId
        ? `${baseUrl}/admin/projects/notes/${noteId}`
        : null
      : noteId
      ? `${baseUrl}/projects/notes/${noteId}`
      : null,
  )
}

export const useAllNotes = (
  maxAgeMonths: number,
  noteTypeIds?: string[],
): SWRResponse<Note[] | undefined> => {
  // eslint-disable-next-line no-console
  // log.debug('Häääääär')
  // console.log('noteTypeNamesStr: ', noteTypeNamesStr)
  let queryStr = ''
  let noteTypeIdsStr = ''
  if (noteTypeIds?.length) {
    noteTypeIdsStr = encodeURIComponent(JSON.stringify(noteTypeIds))
    queryStr = `${baseUrl}/projects/notes?maxAgeMonths=${maxAgeMonths}&noteTypeIds=${noteTypeIdsStr}`
  } else {
    queryStr = `${baseUrl}/projects/notes?maxAgeMonths=${maxAgeMonths}`
  }
  // const noteTypeNamesStr = noteTypeNames
  // ? encodeURI(JSON.stringify(noteTypeNames))
  // : ''
  // eslint-disable-next-line no-console
  // console.log('noteTypeNamesStr: ', noteTypeNamesStr)
  return useSWR<Note[] | undefined>(
    queryStr,
    // `${baseUrl}/projects/notes?maxAgeMonths=${maxAgeMonths}&noteTypeNames=${noteTypeNamesStr}`,
    // `${baseUrl}/projects/notes?maxAgeMonths=${maxAgeMonths}`,
  )
}

export const addNote = async (
  projectId: string,
  requestData: CreateNoteRequest,
) => {
  return axios.post(`${baseUrl}/projects/${projectId}/notes`, requestData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const updateNote = async (
  noteId: string,
  requestData: CreateNoteRequest,
) => {
  return axios
    .put(`${baseUrl}/projects/notes/${noteId}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated project note', status, data)
      return data
    })
}

export const deleteNote = async (noteId: string): Promise<string> => {
  return axios
    .delete(`${baseUrl}/projects/notes/${noteId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => 'Successfully deleted project note')
}
