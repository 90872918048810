import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ListProperties from './ListProperties'

const ManageProperties = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const propertyId = search.get('fastighet')
  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('admin.manageProperties')}</title>
      </Helmet>

      <ListProperties searchPropertyId={propertyId ?? undefined} />
    </Box>
  )
}
export default ManageProperties
