import {
  Button,
  ResponsiveValue,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { ProjectResponse } from '@contracts/projects'
import { PropertyResponse } from '@contracts/misc'
import { ReactElement, useContext } from 'react'
import AuthContext from '../../common/auth-context'
import AddOrEditCollaborator from '../collaborators/AddOrEditCollaborator'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import EmailVerificationRequiredCard from '../collaborators/EmailVerificationRequiredCard'
import { Icon } from './Icon'
import Modal from './Modal'

const AddOrEditCollaboratorButton: React.FC<{
  property?: PropertyResponse
  project?: ProjectResponse
  mt?: number
  ml?: ResponsiveValue<number | string>
  w?: number
  accountId?: string
  accountEmail?: string
  children?: ReactElement
  variant: 'BUTTON' | 'ICON'
  isDisabled?: boolean
  tooltip?: string
}> = ({
  mt,
  ml,
  children,
  w,
  property,
  project,
  accountId,
  variant,
  isDisabled,
  tooltip,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentUserCollaboratorRelation } = useCollaboratorParts({
    propertyId: property?.id,
    projectId: project?.id,
  })
  const { currentUser } = useContext(AuthContext)

  const allowedToEdit =
    currentUser?.status === 'Verified' &&
    currentUserCollaboratorRelation === 'Owner'

  if (variant === 'BUTTON') {
    return (
      <>
        <Tooltip label={tooltip}>
          <Button
            onClick={onOpen}
            mt={mt}
            ml={ml}
            variant="secondary"
            w={w || 32}
            disabled={isDisabled}
            size={{ xxs: 'sm', laptop: 'md' }}
          >
            {children}
          </Button>
        </Tooltip>

        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          {allowedToEdit ? (
            <AddOrEditCollaborator
              onCloseModal={onClose}
              property={property}
              project={project}
              accountId={accountId}
            />
          ) : (
            <EmailVerificationRequiredCard
              area={property ? 'Property' : 'Project'}
              onCloseModal={onClose}
              emailToVerify={currentUser?.email}
            />
          )}
        </Modal>
      </>
    )
  }

  if (variant === 'ICON') {
    return (
      <>
        {!accountId && (
          // Add new collaborator
          <Icon
            name="IoAddCircleOutline"
            _hover={{
              color: 'gray',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            color={isDisabled ? 'gray.300' : 'primary_orange02'}
            onClick={isDisabled ? undefined : onOpen}
            isDisabled={isDisabled}
            tooltip={tooltip}
          />
        )}
        {accountId && (
          // Edit existing collaborator
          <Icon
            name="AiOutlineEdit"
            _hover={{
              color: 'gray',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            color={isDisabled ? 'gray.300' : 'primary_orange02'}
            onClick={isDisabled ? undefined : onOpen}
            isDisabled={isDisabled}
            tooltip={tooltip}
          />
        )}
        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <>
            {allowedToEdit && (
              <AddOrEditCollaborator
                accountId={accountId}
                onCloseModal={onClose}
                property={property}
                project={project}
                isEdit={true}
              />
            )}
            {!allowedToEdit && (
              <EmailVerificationRequiredCard
                area={property ? 'Property' : 'Project'}
                onCloseModal={onClose}
                emailToVerify={currentUser?.email}
              />
            )}
          </>
        </Modal>
      </>
    )
  }
  return null
}
export default AddOrEditCollaboratorButton
