import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import _ from 'lodash'
import { Icon } from './Icon'

const FileDirectory: React.FC<{
  title: string
  area: 'property' | 'project' | 'propertyNote' | 'projectNote'
  areaId: string
  isActive?: boolean

  callback: (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => void
}> = ({ title, area, areaId, isActive = false, callback }) => {
  const iconSize = 5
  const maxTitleLength = 20
  const getIcon = () => {
    switch (area) {
      case 'property':
        return <Icon name="myProperty" h={iconSize} />
      case 'project':
        return <Icon name="myProject" h={iconSize} />
      case 'propertyNote':
        return <Icon name="FileIconPropertyNote" />
      case 'projectNote':
        return <Icon name="FileIconProjectNote" />
      default:
        return '*'
    }
  }

  return (
    <Box
      maxW={{ xxs: '100%', laptop: 64 }}
      px={2}
      py={0.5}
      borderRadius={4}
      color={isActive ? { xxs: 'black', laptop: 'white' } : 'black'}
      bg={isActive ? { xxs: '', laptop: 'primary' } : ''}
      onClick={() => {
        callback(title, area, areaId)
      }}
      _hover={{ bg: 'primary', color: 'white', cursor: 'pointer' }}
      flexWrap="nowrap"
    >
      <Tooltip
        label={title.length > maxTitleLength ? title : undefined}
        hasArrow
      >
        <Flex columnGap={2} alignItems="center">
          {getIcon()}
          <Flex maxW={40} overflow="clip" wrap="nowrap">
            <Text>{_.truncate(title, { length: maxTitleLength })}</Text>
          </Flex>
          <ChevronRightIcon />
        </Flex>
      </Tooltip>
    </Box>
  )
}
export default FileDirectory
