import { ReactNode } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { usePublishedArticlesInfo } from '../../../apiClients/articlesApiClient'
import { TAG_MOST_POPULAR, TAG_SELECTED } from '../../../pages/constants'
import ArticlePromoMedium from '../../articleTemplates/ArticlePromoMedium'
import ArticlePromoSmall from '../../articleTemplates/ArticlePromoSmall'
import Divider from '../../ui/Divider'
import { PATH_MY_PRIVATE_PAGES } from '../../../common/nav'

const ArticlesSection: React.FC<{
  pathPortal: string
  pathPublic: string
  pageTag?: string
  children?: ReactNode
  mb?: number
  mx?: number | string
}> = ({ pathPortal, pathPublic, pageTag, children, mb, mx }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  let linkPath = ''
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    linkPath = pathPortal
  } else {
    linkPath = pathPublic
  }

  const { data: articles } = usePublishedArticlesInfo({
    includeTags: pageTag ? [pageTag] : undefined,
    excludeTags: [TAG_SELECTED, TAG_MOST_POPULAR],
  })

  const { data: selectedArticles } = usePublishedArticlesInfo({
    includeTags: [pageTag ?? '', TAG_SELECTED],
    excludeTags: [],
  })

  const firstSelectedArticle =
    selectedArticles && selectedArticles[0] ? selectedArticles[0] : undefined
  const secondSelectedArticle =
    selectedArticles && selectedArticles[1] ? selectedArticles[1] : undefined

  const { data: mostPopularArticles } = usePublishedArticlesInfo({
    includeTags: [TAG_MOST_POPULAR, pageTag ?? ''],
    excludeTags: [TAG_SELECTED],
  })

  return (
    <>
      <Flex flexDir="row" px={mx} wrap="wrap">
        <Flex flexDir="column" mb={4}>
          {firstSelectedArticle && (
            <ArticlePromoMedium
              path={linkPath}
              articleInfo={firstSelectedArticle}
              mb={{ tablet: 6, xxl: 0 }}
              mr={{ desktop: 8 }}
            />
          )}

          {!firstSelectedArticle && (
            <Text>{t('articles.noArticlesFound')}</Text>
          )}
        </Flex>
        <Flex flexDir="column">
          {children}
          {secondSelectedArticle && (
            <ArticlePromoSmall
              path={linkPath}
              articleInfo={secondSelectedArticle}
              variant="Row"
            />
          )}
        </Flex>
      </Flex>
      <Flex flexDir="column" px={mx} flexWrap="wrap" mb={mb}>
        <Divider />

        {mostPopularArticles && mostPopularArticles?.length > 0 && (
          <>
            <Text textStyle="h4">{t('articles.mostlyRead')}</Text>
            <Flex flexDir="row" mt={2} minW="300px" flexWrap="wrap">
              {mostPopularArticles?.map(article => {
                return (
                  <ArticlePromoSmall
                    path={linkPath}
                    key={article.id.toString()}
                    articleInfo={article}
                    variant="Column"
                    mt={4}
                  />
                )
              })}
              {articles &&
                articles.map(article => {
                  return (
                    <ArticlePromoSmall
                      path={linkPath}
                      key={article.id.toString()}
                      articleInfo={article}
                      variant="Column"
                      mt={4}
                    />
                  )
                })}
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}
export default ArticlesSection
