import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'
import { getDateStrFromNumberIfExist } from '../../common/util'
import AccountAvatar from '../accounts/AccountAvatar'
import Heading from '../ui/Heading'
import { Icon } from '../ui/Icon'

import TextCard from '../ui/TextCard'
import TextCouple from '../ui/TextCouple'
import ProjectPlaceBadge from './ProjectPlaceBadge'

const ProjectPlaceProjectOverviewCard: React.FC<{
  projectPlaceId: string
  mr?: number
  mt?: number
  ml?: ResponsiveValue<number | string>
  headerBg?: string
}> = ({ projectPlaceId, mr, mt, ml }) => {
  const { t } = useTranslation()
  const { data: projectPlace } = useProjectPlace(projectPlaceId)
  const navigate = useNavigate()
  const columnWidth = 20
  const columnWidth2 = 32
  const rowGap = 2
  return (
    <Flex
      flexDir="column"
      mr={mr}
      mt={mt}
      pb={4}
      gap={4}
      ml={ml}
      justifyContent="space-around"
      bg="white"
      boxShadow="lg"
      maxW="container.md"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      px={{ xxs: 2, tablet: 4 }}
      py={4}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading textStyle="h7Wide" isBold>
          {t('projectPlaces.projectOverview')}
        </Heading>
        <Icon
          name="AiOutlineEdit"
          _hover={{ color: 'gray.500' }}
          onClick={() => {
            navigate(`edit`)
          }}
        />
      </Flex>
      <Flex wrap="wrap" rowGap={rowGap}>
        <TextCard heading={''} minW="360px">
          <Flex flexDir="column" fontWeight="normal" rowGap={rowGap}>
            <TextCouple
              title={t('projectPlaces.customer')}
              value={projectPlace?.customerName}
              firstColumnWidth={columnWidth}
            />
            <TextCouple
              title={t('projectPlaces.address')}
              value={`${projectPlace?.street ?? ''}, ${
                projectPlace?.zipCode ?? ''
              } ${projectPlace?.city ?? ''}`}
              firstColumnWidth={columnWidth}
            />
            <TextCouple
              title={t('projectPlaces.email')}
              value={projectPlace?.customerEmail}
              firstColumnWidth={columnWidth}
            />
            <TextCouple
              title={t('projectPlaces.status.status')}
              value={
                <ProjectPlaceBadge
                  variant={'status'}
                  status={projectPlace?.status}
                />
              }
              firstColumnWidth={columnWidth}
            />
            <TextCouple
              title={t('projectPlaces.phase.phase')}
              value={
                <ProjectPlaceBadge
                  variant={'phase'}
                  phase={projectPlace?.phase}
                />
              }
              firstColumnWidth={columnWidth}
            />
          </Flex>
        </TextCard>

        <TextCard heading={''} minW="360px">
          <Flex flexDir="column" fontWeight="normal" rowGap={rowGap}>
            <TextCouple
              title={t('projectPlaces.beginAt')}
              value={getDateStrFromNumberIfExist(projectPlace?.beginAt)}
              firstColumnWidth={columnWidth2}
            />
            <TextCouple
              title={t('projectPlaces.endAt')}
              value={getDateStrFromNumberIfExist(projectPlace?.endAt)}
              firstColumnWidth={columnWidth2}
            />
            <TextCouple
              title={t('projectPlaces.createdBy')}
              value={
                projectPlace?.createdBy ? (
                  <Flex alignItems="flex-end" wrap="wrap">
                    <AccountAvatar
                      accountId={projectPlace.createdBy}
                      variant="avatarAndNameOneRow"
                    />
                    <Text textStyle="infoTextLight" fontSize="sm" ml={2}>
                      {getDateStrFromNumberIfExist(projectPlace.updatedAt)}
                    </Text>
                  </Flex>
                ) : (
                  ''
                )
              }
              firstColumnWidth={columnWidth2}
            />
            <TextCouple
              title={t('projectPlaces.updatedBy')}
              value={
                projectPlace?.updatedBy ? (
                  <Flex alignItems="flex-end" wrap="wrap">
                    <AccountAvatar
                      accountId={projectPlace.updatedBy}
                      variant="avatarAndNameOneRow"
                    />
                    <Text textStyle="infoTextLight" fontSize="sm" ml={2}>
                      {getDateStrFromNumberIfExist(projectPlace.updatedAt)}
                    </Text>
                  </Flex>
                ) : (
                  ''
                )
              }
              firstColumnWidth={columnWidth2}
            />
          </Flex>
        </TextCard>
      </Flex>
    </Flex>
  )
}
export default ProjectPlaceProjectOverviewCard
