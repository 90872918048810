import { useEffect, useState } from 'react'
import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { AccountTypeName } from '@contracts/misc'
import { useSearchParams } from 'react-router-dom'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { confirmInvite } from '../../apiClients/accountsApiClient'
import Spinner from '../../components/Spinner'
import Alert from '../../components/ui/Alert'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'
import log from '../../common/log'
import Card from '../../components/ui/Card'
import LoginAndRegistrationPage from './LoginAndRegistrationPage'

const VerifyInvitePage = () => {
  const [search] = useSearchParams()
  const [verified, setVerified] = useState(false)
  const [email, setEmail] = useState('')
  const [invitedAs, setInvitedAs] = useState<AccountTypeName | undefined>(
    undefined,
  )
  const [inviter, setInviter] = useState('')
  const [isBusy, setBusy] = useState(true)
  const { t } = useTranslation()
  const inviteId = search.get('id')

  useEffect(() => {
    if (inviteId) {
      confirmInvite(inviteId)
        .then(({ email, invitedAs, createdBy }) => {
          setEmail(email)
          setInvitedAs(invitedAs)
          setVerified(true)
          setInviter(createdBy.email)
        })
        .catch(e => {
          log.error(
            { err: e },
            `Error verifying invitation using inviteId: ${inviteId}`,
          )
        })
        .finally(() => {
          setBusy(false)
        })
    }
  }, [])

  if (isBusy) {
    return <Spinner />
  }

  if (!inviteId || !verified) {
    return (
      <Container alignContent="center" textAlign="center" mt="10%" pb={64}>
        <Helmet>
          <title>{t('accounts.verifyInvite.heading')}</title>
        </Helmet>
        <Alert
          variant="Warning"
          title={t('accounts.verifyInvite.corruptHeading')}
          message={t('accounts.verifyInvite.corruptMessage')}
          forwardUri={PATH_MY_PRIVATE_PAGES}
        />
      </Container>
    )
  }

  return (
    <Container alignContent="center" textAlign="center" mt="6%" mb={64}>
      {verified && (
        <>
          <Card
            heading={t('general.welcome')}
            bg="white"
            boxShadow="lg"
            iconName="berikarLogo"
            py={2}
          >
            <Box textAlign="left" px={4} pb={2}>
              <Text>
                <b>{inviter}</b> {t('accounts.verifyInvite.messagePart1')}
              </Text>
              <Text>
                {t('accounts.verifyInvite.messagePart2', {
                  accountType:
                    invitedAs === 'Consumer' ? 'privatperson' : 'företagare',
                })}{' '}
                <b>{email}</b>
              </Text>
              <Text my={6}>{t('accounts.verifyInvite.messagePart3')}</Text>
              <Flex flexDir="row" alignItems="center">
                <WarningTwoIcon
                  color="orange.300"
                  fontSize={32}
                  ml={4}
                  mr={8}
                />
                <Text>
                  {t('accounts.verifyInvite.messagePart4', {
                    accountName:
                      invitedAs === 'Consumer'
                        ? 'privatkonto'
                        : 'företagarkonto',
                    email: email,
                  })}
                </Text>
              </Flex>
            </Box>
          </Card>

          <Box borderRadius="lg" my={10} bg="transparent" pb={2}>
            <LoginAndRegistrationPage />
          </Box>
        </>
      )}
    </Container>
  )
}
export default VerifyInvitePage
