import { Spinner as ChakraSpinner } from '@chakra-ui/react'
import { FC } from 'react'

const Spinner: FC<{
  top?: string | number
  bottom?: string | number
  left?: string | number
}> = ({ top, left = '50%', bottom }) => {
  top = bottom ? '' : '20%'
  return (
    <ChakraSpinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
      pos="absolute"
      top={top}
      left={left}
      bottom={bottom}
    />
  )
}
export default Spinner
