import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { HelpRequestTemplateResponse } from '@contracts/misc'
import FeedbackPopover from '../feedback/FeedbackPopover'
import AddOrEditHelpRequestTemplateForm from './AddOrEditHelpRequestTemplateForm'
export const MAX_LENGTH_NOTE_TITLE = 255

const AddOrEditHelpRequestTemplate: React.FC<{
  onCloseModal: (avoidAlertDialog?: boolean) => void
  helpRequestTemplate?: HelpRequestTemplateResponse // CreateHelpRequestResponse
  onParentHasUnsavedChanges: (hasUnsavedChanges: boolean) => void

  projectId: string
  // isEmbedded?: boolean
}> = ({
  projectId,
  onCloseModal,
  helpRequestTemplate,
  onParentHasUnsavedChanges,
}) => {
  // const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  // const [isDeleting, setIsDeleting] = useState(false)
  // const toast = useToast()

  // const { mutate: mutateHelpReqTemplates } = useHelpRequestsTemplates(projectId)
  // const { mutate: mutateCurrentHelpReq } = useHelpRequestsTemplate(
  // helpRequestTemplate?.id,
  // )

  // const [description, setDescription] = useState(
  // helpRequestTemplate?.description ?? '<p></p>',
  // )

  // const [myFilesSelectionToAdd, setMyFilesSelectionToAdd] = useState<
  //   AttachmentSummary[]
  // >([])
  // const [attachmentsJson, setAttachmentsJson] = useState<AttachmentSummary[]>(
  //   helpRequestTemplate?.attachmentsJson ?? [],
  // )
  // const [attachmentIdsToDelete, setAttachmentIdsToDelete] = useState<string[]>(
  //   [],
  // )
  // const [attachmentsToChangeName, setAttachmentsToChangeName] = useState<
  //   { attachmentId: string; newName: string; mimeType: string }[]
  // >([])
  // const [newFiles, setNewFiles] = useState<File[]>([])

  // const initialValues: AddOrEditHelpRequestTemplateValues = {
  //   name: helpRequestTemplate?.name ?? '',
  //   description: helpRequestTemplate?.description ?? '',
  //   helpTimestamp: helpRequestTemplate?.helpTimestamp
  //     ? getDateStrFromNumber(helpRequestTemplate.helpTimestamp as number)
  //     : undefined,
  //   attachments: [],
  // }

  // useEffect(() => {
  //   formikValues.attachments = []
  //   newFiles.map(newFile => {
  //     compressFile(newFile, 'm', newFile => {
  //       formikValues.attachments.push(newFile)
  //     })
  //   })
  // }, [newFiles])

  // Keep "NotSet" on top to make it default work correctly
  // const availableStatuses: NoteStatus[] = [
  //   'NotSet',
  //   'Approved',
  //   'Done',
  //   'Draft',
  //   'Ongoing',
  //   'Rejected',
  //   'Todo',
  // ]

  // const validate = (values: AddOrEditHelpRequestTemplateValues) => {
  //   const errors: Errors = {}

  //   const tmpRes = validateString(
  //     values.name,
  //     'namn',
  //     2,
  //     MAX_LENGTH_NOTE_TITLE,
  //     true,
  //   )
  //   if (!tmpRes.isValid) {
  //     errors.description = tmpRes.errorMsg
  //   }

  //   return errors
  // }

  // const {
  //   handleSubmit,
  //   handleChange,
  //   handleBlur,
  //   touched,
  //   errors,
  //   values: formikValues,
  //   isSubmitting,
  // } = useFormik({
  //   initialValues: initialValues,
  //   validate,
  //   onSubmit: async (values, { setSubmitting }) => {
  //     // Don't delete attachments - might be used elsewhere so must include a check
  //     // await Promise.all(
  //     //   attachmentIdsToDelete.map(async attachmentId => {
  //     //     await deleteAttachment(attachmentId, true)
  //     //   }),
  //     // )

  //     const resultsAttachmentsPersisted = await Promise.all(
  //       formikValues.attachments.map(async file => {
  //         const formData = new FormData()
  //         formData.set('image', file)
  //         formData.set('area', 'Note')
  //         const newAttachment = await addAttachment(formData, true)
  //         return newAttachment
  //       }),
  //     )

  //     const attachmentsToStore: AttachmentSummary[] =
  //       resultsAttachmentsPersisted.map(({ id, mimeType }) => ({
  //         id,
  //         mimeType,
  //       }))

  //     // eslint-disable-next-line no-console
  //     // console.log('values: ', values)
  //     // eslint-disable-next-line no-console
  //     // console.log('attachmentsToStore: ', attachmentsToStore)

  //     // if (note) {
  //     if (helpRequestTemplate) {
  //       attachmentsToChangeName.map(attachment => {
  //         void updateFilename(attachment.attachmentId, attachment.newName)
  //       })

  //       const requestData: UpdateHelpRequestTemplate = {
  //         status: helpRequestTemplate.status,
  //         name: values.name,
  //         description: description,
  //         helpTimestamp: values.helpTimestamp,
  //         attachmentsJson: [
  //           ...attachmentsToStore,
  //           ...attachmentsJson,
  //           ...attachmentsToChangeName.map(({ attachmentId, mimeType }) => {
  //             return {
  //               id: attachmentId,
  //               mimeType,
  //             }
  //           }),
  //           ...myFilesSelectionToAdd,
  //         ],
  //       }

  // eslint-disable-next-line no-console
  // console.log(requestData)

  //       updateHelpRequestTemplate(
  //         helpRequestTemplate.id,
  //         projectId,
  //         requestData,
  //       )
  //         .then(() => {
  //           log.info('Successfully created new note')
  //           applicationInsights.trackEvent({
  //             name: 'Help-request-template updated',
  //           })
  //           void mutateCurrentHelpReq()
  //           void mutateHelpReqTemplates()
  //           toast({
  //             title: t('helpRequests.template.updateSuccessMessage', {
  //               name: values.name,
  //             }),
  //             status: 'success',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'top',
  //           })
  //         })
  //         .catch(err => {
  //           log.error('Failed to update note', err)
  //           toast({
  //             title: t('helpRequests.template.updateErrorMessage', {
  //               name: values.name,
  //             }),
  //             status: 'error',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'top',
  //           })
  //         })
  //         .finally(() => {
  //           onCloseModal(true)
  //           setSubmitting(false)
  //         })
  //     } else {
  //       const requestData: CreateHelpRequestTemplate = {
  //         name: values.name,
  //         description: description,
  //         helpTimestamp: values.helpTimestamp,
  //         attachmentsJson: [
  //           ...attachmentsToStore,
  //           ...attachmentsJson,
  //           ...attachmentsToChangeName.map(({ attachmentId, mimeType }) => {
  //             return {
  //               id: attachmentId,
  //               mimeType,
  //             }
  //           }),
  //           ...myFilesSelectionToAdd,
  //         ],
  //       }
  //       // eslint-disable-next-line no-console
  //       // console.log(requestData)

  //       addHelpRequestTemplate(projectId, requestData)
  //         .then(() => {
  //           log.info('Successfully created new note')
  //           applicationInsights.trackEvent({
  //             name: 'Help-request-template added',
  //           })

  //           toast({
  //             title: t('helpRequests.template.createSuccessMessage', {
  //               name: values.name,
  //             }),
  //             status: 'success',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'top',
  //           })

  //           void mutateHelpReqTemplates()
  //         })
  //         .catch(err => {
  //           log.error('Failed to create new property note', err)
  //           toast({
  //             title: t('helpRequests.template.createErrorMessage', {
  //               name: values.name,
  //             }),
  //             status: 'error',
  //             duration: 9000,
  //             isClosable: true,
  //             position: 'top',
  //           })
  //         })
  //         .finally(() => {
  //           onCloseModal(true)
  //           // mutate ? void mutate() : ''

  //           setSubmitting(false)
  //         })
  //     }
  //   },
  // })

  // const onChangeDescriptionHandler = (text: string) => {
  //   setDescription(text)
  //   onParentHasUnsavedChanges(true)
  // }

  // const onDeleteHandler = () => {
  //   if (!helpRequestTemplate || isDeleting) {
  //     return
  //   }
  //   setIsDeleting(true)
  //   deleteHelpRequestTemplate(projectId, helpRequestTemplate.id)
  //     .then(() => {
  //       // Don't delete attachments until check if used elsewhere is in place
  //       // .then(async () => {
  //       // await Promise.all(
  //       //   attachmentsJson.map(async ({ id }) => {
  //       //     await deleteAttachment(id, true)
  //       //   }),
  //       // )
  //       log.debug('Successfully deleted help-request-template')
  //       mutateHelpReqTemplates ? void mutateHelpReqTemplates() : ''
  //       toast({
  //         title: t(
  //           'helpRequests.template.helpReqTemplateDeleteSuccessMessage',
  //           { name: helpRequestTemplate.name },
  //         ),
  //         status: 'success',
  //         duration: 9000,
  //         isClosable: true,
  //         position: 'top',
  //       })
  //     })
  //     .catch(err => {
  //       log.error({ err: err }, `Error deleting note ${helpRequestTemplate.id}`)
  //     })
  //     .finally(() => {
  //       setIsDeleting(false)
  //       onCloseModal(true)
  //     })
  // }

  return (
    <>
      <Box
        h={16}
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          {!helpRequestTemplate && t('helpRequests.newHelpRequestTemplate')}
          {helpRequestTemplate && t('helpRequests.helpRequestTemplate')}
        </Box>

        <FeedbackPopover context="helpRequestTemplate" />
      </Box>
      <AddOrEditHelpRequestTemplateForm
        helpRequestTemplate={helpRequestTemplate}
        onParentHasUnsavedChanges={onParentHasUnsavedChanges}
        projectId={projectId}
        onCloseModal={onCloseModal}
      />
    </>
  )
}
export default AddOrEditHelpRequestTemplate
