import { extendTheme } from '@chakra-ui/react'
import { ButtonStyles as Button } from './components/buttonStyles'
import '@fontsource/lato/900.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/300.css'
import '@fontsource/lato/100.css'

const breakpoints = {
  xxs: '50px',
  mobile: '320px', // mobile - one column
  xs: '420px', // mobile - one column
  tablet: '480px', // tablet - two columns
  sm: '480px', // tablet - two columns
  laptop: '768px', // laptop - three columns
  md: '768px', // laptop - three columns
  desktop: '1024px', // desktop - four columns
  lg: '1024px', // desktop - four columns
  xl: '1280px', // xl - four columns
  xxl: '1400px', // xxl - four columns + marginal
}

export const berikarTheme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        bg: 'primaryBackground',
      },
    },
  },
  colors: {
    // Berikar color palette (Figma is master)
    logo_green01: '#35744F',
    logo_green01_light: '#35744F',
    logo_green02: '#8C9D80',
    logo_green02_light: '#8C9D80',
    primary_green01: '#D2DFC9',
    primary_green02: '#8C9D80',
    primary_green03: '#5D744C',
    logo_petroleum01: '#08A29C',
    logo_yellow: '#F8CD76',
    logo_yellow_light: '#F3D48F',
    logo_brown: '#864114',
    logo_brown_light: '#A57957',
    primary_grey01: '#EEF0ED',
    primary_grey02: '#BAC1B4',
    primary_grey04: '#82867F',
    primary_orange01: '#DEB276',
    primary_orange02: '#CF8723',
    primary_orange03: '#B06E11',
    logo_orange: '#CF8723',
    grey_light: '#C1CBC5',
    logo_pink: '#D394B1',
    logo_pink_light: '#D9B3C1',
    logo_purple: '#B86890',

    // Web/component colors
    primary: '#08A29C',
    primaryDark: '#0C7773',
    secondary: '#DEB276',
    secondaryDark: '#CF8723',
    primaryBackground: '#E3EADE',
    secondaryBackground: '#D2DFC9',
    lightBackground: '#EEF0ED',
    darkBackground: '#D1D3D0',
    primaryText: '#82867f',
    highlight: 'yellow',
    warning: 'orange',
    danger: 'red',
    inputBorder: '#BAC1B4',
    inputPlaceholder: '#BAC1B4',
    linkHover: '#82867F',
    sidebarItemMarker: '#CF8723',
  },
  components: {
    Button,
  },
  fonts: {
    body: `'Lato', sans-serif`,
    heading: `'Lato', sans-serif`,
  },
  textStyles: {
    hXxl: {
      fontSize: ['48px', '72px', '72px', '96px', '128px'],
      fontWeight: 'regular',
      lineHeight: '110%',
      letterSpacing: '1.4rem',
      color: 'primary_grey04',
    },
    h1: {
      fontSize: {
        xxs: '20px',
        mobile: '24px',
        tablet: '32px',
        laptop: '36px',
        desktop: '40px',
      },
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: 4,
    },
    h2: {
      fontSize: {
        xxs: 'px',
        mobile: '22px',
        tablet: '28px',
        laptop: '32px',
        desktop: '36px',
      },
      fontWeight: 'semibold',
      lineHeight: '130%',
      letterSpacing: 1,
    },
    h3Wide: {
      fontSize: { mobile: '20px', tablet: '24px', desktop: '32px' },
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: 4,
    },
    h3: {
      fontSize: { mobile: '20px', tablet: '24px', desktop: '32px' },
      fontWeight: 'semibold ',
      lineHeight: '110%',
      letterSpacing: 1,
    },
    h3Regular: {
      fontSize: { mobile: '26px', tablet: '28px', laptop: '32px' },
      fontWeight: 'regular',
      lineHeight: '120%',
      letterSpacing: 1,
    },
    h3Thin: {
      fontSize: { mobile: '26px', tablet: '28px', laptop: '32px' },
      fontWeight: 'thin',
      lineHeight: '110%',
      letterSpacing: '.6rem',
    },

    h3Small: {
      fontSize: { mobile: '22px', tablet: '28px', desktop: '28px' },
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: 1,
    },
    h4: {
      fontSize: {
        xxs: '14px',
        mobile: '16px',
        tablet: '18px',
        laptop: '20px',
        desktop: '24px',
      },
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    h5: {
      fontSize: { xxs: '16px', tablet: '18px', laptop: '22px' },
      fontWeight: '700',
      lineHeight: '110%',
      letterSpacing: '.2px',
    },
    h6: {
      fontSize: {
        xxs: '12px',
        mobile: '14px',
        tablet: '16px',
        desktop: '20px',
      },
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    h6Regular: {
      fontSize: { mobile: '16px', tablet: '20px', desktop: '20px' },
      fontWeight: 'regular',
    },
    h6Fixed: {
      fontSize: '16px',
      fontWeight: 'regular',
    },
    h7: {
      fontSize: {
        xxs: '10px',
        mobile: '12px',
        tablet: '14px',
        desktop: '18px',
      },
      fontWeight: 'semibold',
    },
    h7Wide: {
      fontSize: {
        xxs: '14px',
        mobile: '14px',
        tablet: '14px',
        desktop: '18px',
      },
      letterSpacing: 2,
    },
    h7Regular: {
      fontSize: { mobile: '18px', tablet: '18px', desktop: '18px' },
      fontWeight: 'normal',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    h8: {
      fontSize: { mobile: '15px', tablet: '16px', desktop: '16px' },
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    h9: {
      fontSize: { mobile: '12px', tablet: '12px', desktop: '12px' },
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },

    // textXSmall: 9
    // textSmall: 12pt
    // textMedium: 16pt
    // textLarge: 20pt
    // textXLarge: 24pt

    inputPlaceholder: {
      fontSize: '14px',
      fontWeight: '400',
      color: 'gray.400',
    },

    infoText: {
      fontSize: { xxs: '9pt', tablet: '14px', laptop: '16px' },
      // fontWeight: 'normal',
      color: 'black',
    },
    infoTextLight: {
      fontSize: { xxs: '9pt', tablet: '14px', laptop: '16px' },
      fontWeight: 'light',
      color: 'black',
    },
    inputText: {
      fontSize: [{ mobile: '14px' }, { tablet: '18px' }, { laptop: '20px' }],
      fontWeight: 'normal',
      color: 'black',
    },
    inputHeader: {
      fontSize: ['12px', '14px'],
      fontWeight: 'normal',
      color: 'gray.400',
    },
    errorSmall: {
      fontSize: ['12px', '14px'],
      fontWeight: 'normal',
      color: 'red',
    },
    infoSmall: {
      fontSize: ['9px'],
      fontWeight: 'normal',
      color: 'primary_grey04',
    },
    navbarHeading: {
      fontSize: '13px',
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-1%',
      color: 'logo_green01',
    },
    textXSmall: {
      fontSize: '9px',
      fontWeight: 'medium',
      color: 'black',
    },
    textSmall: {
      fontSize: '12px',
      fontWeight: 'medium',
      color: 'black',
    },
    textRegular: {
      fontSize: { xxs: '14px', desktop: '16px' },
      // fontWeight: 'medium',
      color: 'black',
      // letterSpacing: '-1%',
    },
    textRegularWhite: {
      fontSize: { xxs: '14px', desktop: '16px' },
      // fontWeight: 'medium',
      color: 'white',
      // letterSpacing: '-1%',
    },
    textMedium: {
      fontSize: {
        xxs: '12px',
        tablet: '14px',
        laptop: '14px',
        desktop: '16px',
      },
      fontWeight: 'medium',
      color: 'black',
    },
    textMediumLight: {
      fontSize: {
        xxs: '12px',
        tablet: '14px',
        laptop: '16px',
      },
      fontWeight: 'light',
      color: 'black',
    },
    textSmallFixed: {
      fontSize: '11px',

      color: 'black',
    },
    textMediumFixed: {
      fontSize: '15px',

      color: 'black',
    },
    textSmallRegular: {
      fontSize: {
        xxs: '10px',
        tablet: '11px',
        laptop: '12px',
        desktop: '14px',
      },
      fontWeight: 'medium',
      color: 'black',
    },
    textSmallLight: {
      fontSize: {
        xxs: '10px',
        tablet: '11px',
        laptop: '12px',
        desktop: '14px',
      },
      fontWeight: 'light',
      color: 'black',
    },

    textError: {
      fontSize: '16px',
      fontWeight: 'medium',
      color: 'red',
    },
    textLarge: {
      fontSize: {
        xxs: '14px',
        tablet: '16px',
        laptop: '18px',
        desktop: '18px',
      },

      fontWeight: 'regular',
      color: 'black',
      letterSpacing: 1.2,
    },
    textLargeLight: {
      fontSize: { mobile: '18px', tablet: '20px' },
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 1.2,
    },

    textLargeThin: {
      fontSize: { mobile: '18px', tablet: '20px' },
      fontWeight: 'thin',
      color: 'black',
      letterSpacing: 1.2,
    },
    textXLarge: {
      fontSize: { mobile: '18px', tablet: '24px' },
      color: 'black',
    },
    textXLargeThin: {
      fontSize: { mobile: '18px', tablet: '24px' },
      fontWeight: 'thin',
      color: 'black',
    },
    textXLargeLight: {
      fontSize: { mobile: '18px', tablet: '24px' },
      fontWeight: 'light',
      color: 'black',
    },
    textThin: {
      fontSize: '16px',
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'widest',
    },
    textBold: {
      fontSize: 'md',
      fontWeight: 'bold',
      color: 'black',
      letterSpacing: 'wide',
    },
    cardHeading: {
      fontSize: 'md',
      fontWeight: 'medium',
      color: 'black',
      letterSpacing: 'wide',
    },
    cardText: {
      fontSize: 'md',
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    xxsLight: {
      fontSize: '2xs',
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    xsLight: {
      fontSize: 'xs',
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    smLight: {
      fontSize: { xxs: 'xs', laptop: 'sm' },
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    lg: {
      fontSize: { xxs: 'md', laptop: 'lg' },
      // fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    lgLight: {
      fontSize: { xxs: 'md', laptop: 'lg' },
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    xlLight: {
      fontSize: { xxs: 'lg', laptop: 'xl' },
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    regularLight: {
      fontSize: 'md',
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    regularLightResponsive: {
      fontSize: { xxs: 'sm', desktop: 'md' },
      fontWeight: 'light',
      color: 'black',
      letterSpacing: 'wide',
    },
    breadCrumbs: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'primary_grey04',
    },
  },
})
