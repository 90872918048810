import { Button, Flex, Text, useToast } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { KeyedMutator } from 'swr'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useOrganization } from '../../apiClients/organizationsApiClient'
import { updateHelpRequestConsumerStatus } from '../../apiClients/projectsApiClient/helpRequests'
import log from '../../common/log'
import { minutesDiff } from '../../common/util'
import FileManagerCard from '../attachments/FileManagerCard'
import HtmlPane from '../richTextEditor/HtmlPane'
import CreatedOrUpdatedDateCard from '../ui/CreatedOrUpdatedDateCard'

const OfferCard: React.FC<{
  helpReq: HelpRequestResponse
  mutateAllHelpRequests?: KeyedMutator<HelpRequestResponse[] | undefined>
  mr?: number | string
  mt?: number | string
  mb?: number | string
  mx?: number | string
  my?: number | string
  w?: number | string
}> = ({ helpReq, mutateAllHelpRequests, mr, mt, mb, mx, my, w }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { data: org } = useOrganization(helpReq.organizationId)
  const toast = useToast()
  const { mutate: mutateMessageSection } = useConversationsByArea(
    'HelpRequest',
    [helpReq.id],
  )

  const updateOfferHandler = (answer: 'Accepted' | 'Rejected') => {
    updateHelpRequestConsumerStatus(helpReq.id, answer)
      .then(() => {
        log.info('Successfully updated offer')
        applicationInsights.trackEvent({
          name: 'Help-request updated',
        })

        mutateAllHelpRequests ? void mutateAllHelpRequests() : ''
        void mutateMessageSection()
        toast({
          title:
            answer === 'Accepted'
              ? t('helpRequests.offerAcceptedMessage', {
                  name: helpReq.name,
                })
              : t('helpRequests.offerRejectedMessage', {
                  name: helpReq.name,
                }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(err => {
        log.error('Failed to update note', err)
        toast({
          title: t('helpRequests.updateOfferErrorMessage', {
            name: helpReq.name,
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }
  return (
    <Flex
      flexDir="column"
      bg="lightBackground"
      mt={mt}
      mr={mr}
      mb={mb}
      mx={mx}
      my={my}
      w={w}
      p={4}
      borderRadius="lg"
    >
      {(helpReq.providerStatus === 'New' ||
        helpReq.providerStatus === 'Draft' ||
        (!helpReq.agreement && !helpReq.attachmentsJson)) && (
        <Text textStyle="infoTextLight">
          {`${org?.name ?? 'Företagaren'} har inte lagt in någon offert ännu`}
        </Text>
      )}
      {(helpReq.agreement || helpReq.attachmentsJson) && (
        <>
          <HtmlPane
            content={
              helpReq?.agreement ?? '<p>Det finns ännu ingen text inlagd...</p>'
            }
            textStyle="infoTextLight"
            px={0}
            py={0}
            bg="transperant"
          />
          <FileManagerCard
            attachmentIds={
              helpReq?.attachmentsJson
                ? helpReq?.attachmentsJson.map(({ id }) => id)
                : []
            }
            mt={6}
            mb={4}
          />
          <Flex
            // border="1px solid red"
            flexDir={{ xxs: 'column', laptop: 'row' }}
            justifyContent="space-between"
            rowGap={4}
            alignItems="flex-end"
          >
            <Flex flexDir="column">
              {helpReq?.createdAt && (
                <CreatedOrUpdatedDateCard
                  variant="created"
                  createdAt={helpReq.createdAt}
                  authorAccountId={helpReq.createdBy}
                />
              )}

              {helpReq?.createdAt &&
                helpReq?.updatedAt &&
                minutesDiff(helpReq?.updatedAt, helpReq?.createdAt) > 1 && (
                  <CreatedOrUpdatedDateCard
                    variant="updated"
                    updatedAt={helpReq.updatedAt}
                    authorAccountId={helpReq?.updatedBy ?? undefined}
                  />
                )}
            </Flex>

            <Flex gap={4}>
              <Button
                colorScheme="red"
                size="xs"
                onClick={() => {
                  updateOfferHandler('Rejected')
                }}
                disabled={helpReq.providerStatus === 'Ongoing' ? false : true}
              >
                Avslå offert
              </Button>
              <Button
                colorScheme="green"
                size="xs"
                onClick={() => {
                  updateOfferHandler('Accepted')
                }}
                disabled={helpReq.providerStatus === 'Ongoing' ? false : true}
              >
                Godkänn offert
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}
export default OfferCard
