import {
  Badge,
  Checkbox,
  Divider,
  Flex,
  ResponsiveValue,
  Text,
} from '@chakra-ui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContextArea,
  ConversationArea,
  Message,
  Message as MessageModel,
} from '@contracts/support'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Note } from '@contracts/misc'
import { useConversationsByArea } from '../../../apiClients/messagesApiClient'
import MessageGroup from '../../conversation/MessageGroup'
import Link from '../../ui/Link'
import NoteCardMediumButton from '../../notes/NoteCardMediumButton'
import AddOrEditMessage from '../../messages/AddOrEditMessage'
import {
  hasReadEventNotifications,
  useEventNotifications,
} from '../../../apiClients/eventsApiClient'
import log from '../../../common/log'

const MyMessageSection: React.FC<{
  area: ContextArea
  areaId: string
  parentArea?: ConversationArea
  parentAreaId?: string
  grandParentArea?: ConversationArea
  grandParentAreaId?: string
  conversationArea?: ConversationArea
  areaFriendlyName: string
  // Should be populated for notes but not for property/project
  areaFriendlyParentName: string
  excludeSystemMessages?: boolean
  mx?: number | string
  px?: ResponsiveValue<number | string>
  mb?: number | string
  my?: number | string
  mt?: number | string
  variant?: 'normal' | 'showLatestUpdates'
  showOnlyIfUpdatedSince?: number
  heading?: string
  headingStyle?: string
  headingColor?: string
  isHeadingBold?: boolean
  link?: string
  projectId?: string
  projectNote?: Note
  pt?: number
}> = ({
  area,
  areaId,
  areaFriendlyName,
  areaFriendlyParentName,
  parentArea,
  parentAreaId,
  grandParentArea,
  grandParentAreaId,
  conversationArea,
  excludeSystemMessages,
  mx,
  px,
  mb,
  my,
  mt,
  pt = 6,
  variant = 'normal',
  showOnlyIfUpdatedSince,
  heading,
  headingStyle,
  headingColor,
  isHeadingBold,
  link,
  projectId,
  projectNote,
}) => {
  const { t } = useTranslation()
  const [showSystemMessages, setShowSystemMessages] = useState(false)

  const { data: conversation, mutate: mutateConversation } =
    useConversationsByArea(
      area as ConversationArea,
      [areaId],
      excludeSystemMessages,
    )

  const { data: eventNotifications, mutate: mutateEventNotifications } =
    useEventNotifications()

  const unreadMessageNotifications = eventNotifications?.filter(event => {
    if (
      event.event.parentAreaId === areaId &&
      event.status === 'New' &&
      (event.event.type === 'Message' || event.event.type === 'Mention')
    ) {
      return true
    }
  })

  let idOfFirstUnreadMessage: string | undefined = undefined

  if (unreadMessageNotifications?.length) {
    idOfFirstUnreadMessage =
      unreadMessageNotifications[unreadMessageNotifications.length - 1]?.event
        .areaId ?? undefined
  }

  let filteredConversation: Message[] | undefined

  if (conversation) {
    if (!showSystemMessages || excludeSystemMessages) {
      filteredConversation = conversation.filter(message => {
        return message.isSystemMessage === false
      })
    } else {
      filteredConversation = conversation
    }
  }

  let noUpdatesToShow = true
  if (
    filteredConversation &&
    variant === 'showLatestUpdates' &&
    showOnlyIfUpdatedSince
  ) {
    if (
      filteredConversation?.find(message => {
        return message.updatedAt > showOnlyIfUpdatedSince
      })
    ) {
      noUpdatesToShow = false
    }
  }

  const [groupedConversation, setGroupedConversation] = useState<
    [MessageModel[]]
  >([[]])

  const groupingTime = 1800000 // 30 min * 60 s * 1000 = 1 800 000 ms
  const isTimeDiffWithinLimit = (dateA: number, dateB: number): boolean => {
    return new Date(dateA).getTime() - new Date(dateB).getTime() < groupingTime
  }

  const shouldBeGrouped = (
    messageA: MessageModel,
    messageB: MessageModel,
  ): boolean => {
    if (messageA.accountId !== messageB.accountId) {
      return false
    }
    if (messageA.isSystemMessage || messageB.isSystemMessage) {
      return false
    }
    return isTimeDiffWithinLimit(messageA.createdAt, messageB.createdAt)
  }

  useEffect(() => {
    let group: MessageModel[] = []
    const tmpGroupedConversation: [MessageModel[]] = [[]]

    if (filteredConversation) {
      filteredConversation.forEach(message => {
        if (group.length === 0) {
          group.push(message)
          return
        }

        if (shouldBeGrouped(group[group.length - 1], message)) {
          group.unshift(message)
        } else {
          tmpGroupedConversation.push(group)
          group = []

          group.unshift(message)
        }
      })
    }
    // Don't forget to push the last group
    tmpGroupedConversation.push(group)

    setGroupedConversation(tmpGroupedConversation)
  }, [conversation, showSystemMessages])

  const markMessageEventsAsReadHandler = async () => {
    if (unreadMessageNotifications?.length) {
      // setHasReadEventNotifications(
      await hasReadEventNotifications(
        unreadMessageNotifications.map(event => {
          return event.id
        }),
      )
        .then()
        .catch(err => {
          log.error({ err }, 'Failed to mark event notifications as read')
        })

      void mutateEventNotifications()
      // void mutateEventNotifications()
    }
  }

  const getMessageSectionName = () => {
    if (heading) {
      return heading
    }
    switch (area) {
      case 'HelpRequest':
        return t('messages.helpRequestMessagesHeading')
      case 'Project':
        return t('messages.projectMessagesHeading')
      case 'ProjectNote':
        return t('messages.projectNoteMessagesHeading')
      case 'Property':
        return t('messages.propertyMessagesHeading')
      case 'PropertyNote':
        return t('messages.propertyNoteMessagesHeading')
      case 'ProjectPlace':
        return t('messages.projectPlaceMessagesHeading')
    }
  }
  if (variant === 'showLatestUpdates' && noUpdatesToShow) {
    return null
  }

  return (
    <Flex flexDir="column" w="100%" pt={pt} mb={mb} mt={mt} my={my} px={px}>
      <Flex flexDir="column" mx={mx}>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          fontSize={24}
          color="primary_grey04"
        >
          <Flex width="100%">
            <Flex
              flexDir="row"
              columnGap={2}
              alignItems="center"
              mb={projectNote ? 1.5 : 0}
              width="100%"
              justifyContent="space-between"
            >
              <Flex>
                <Text
                  mr={2}
                  textColor={headingColor}
                  textStyle={headingStyle ? headingStyle : 'h8'}
                  fontWeight={isHeadingBold ? 'bold' : 'normal'}
                >
                  {getMessageSectionName()}
                </Text>
                {unreadMessageNotifications &&
                  unreadMessageNotifications?.length > 0 && (
                    <Badge
                      colorScheme="pink"
                      px={2}
                      borderRadius="lg"
                      fontSize={{ xxs: 10, tablet: 12 }}
                      h={4}
                    >
                      {` ${unreadMessageNotifications?.length} ${
                        unreadMessageNotifications.length === 1 ? 'Nytt' : 'Nya'
                      }`}
                    </Badge>
                  )}
              </Flex>
              {variant !== 'showLatestUpdates' && !excludeSystemMessages && (
                <Checkbox
                  defaultChecked={false}
                  onChange={() => {
                    setShowSystemMessages(!showSystemMessages)
                  }}
                  borderColor="gray.400"
                >
                  <Text fontSize="xs">{t('messages.showSystemMessages')}</Text>
                </Checkbox>
              )}

              {variant === 'showLatestUpdates' &&
                (area !== 'ProjectNote' || !projectId || !projectNote) && (
                  <Link to={link ?? ''}>
                    <ExternalLinkIcon boxSize={5} mb={1} />
                  </Link>
                )}
              {variant === 'showLatestUpdates' &&
                area === 'ProjectNote' &&
                projectNote &&
                projectId && (
                  <NoteCardMediumButton
                    note={projectNote}
                    projectId={projectId}
                  >
                    <ExternalLinkIcon
                      boxSize={5}
                      mb={0.5}
                      color="black"
                      _hover={{ color: 'gray.400' }}
                    />
                  </NoteCardMediumButton>
                )}
            </Flex>
          </Flex>

          <Flex flexDir="row" justifyContent="right" columnGap={4}></Flex>
        </Flex>
        <Divider borderColor="gray.500" mt={2} />
      </Flex>

      <Flex
        mx={mx}
        mt={{ xxs: 0, tablet: 4 }}
        flexDir="column"
        maxW="container.lg"
        pos="relative"
      >
        <Flex
          rowGap={4}
          maxH="lg"
          overflowY="auto"
          wrap="nowrap"
          flexFlow="column-reverse"
        >
          {groupedConversation &&
            groupedConversation.length > 0 &&
            groupedConversation.map(messageGroup => {
              let placeNewMessagesDividerBeforeNextGroup = false
              let dividerGoesWithinCurrentMessageGroup = false

              if (!messageGroup.length) {
                return null
              }

              if (idOfFirstUnreadMessage) {
                if (
                  messageGroup.find(message => {
                    return message.id === idOfFirstUnreadMessage
                  })
                ) {
                  // Found first new message - let's place the "new divider"
                  if (messageGroup[0].id === idOfFirstUnreadMessage) {
                    // Place new divider before next group
                    placeNewMessagesDividerBeforeNextGroup = true
                  } else {
                    // Place new divider wihtin next group
                    // ie provider group with correct parameters
                    // dividerGoesWithinCurrentMessageGroup = true
                    dividerGoesWithinCurrentMessageGroup = true
                  }
                }
              }

              return (
                <Fragment key={messageGroup[0].id}>
                  <MessageGroup
                    messageGroup={messageGroup}
                    bg="white"
                    mutateConversation={mutateConversation}
                    area={area}
                    areaId={areaId}
                    parentArea={parentArea}
                    parentAreaId={parentAreaId}
                    grandParentArea={grandParentArea}
                    grandParentAreaId={grandParentAreaId}
                    areaFriendlyName={areaFriendlyName}
                    areaFriendlyParentName={areaFriendlyParentName}
                    conversationArea={conversationArea}
                    idOfFirstUnreadMessage={
                      dividerGoesWithinCurrentMessageGroup
                        ? idOfFirstUnreadMessage
                        : undefined
                    }
                    onMarkMessagesAsRead={
                      markMessageEventsAsReadHandler ?? undefined
                    }
                  />

                  {placeNewMessagesDividerBeforeNextGroup && (
                    <Flex
                      flexDir="column"
                      textAlign="right"
                      textStyle="textSmall"
                    >
                      <Flex alignItems="flex-start" gap={2} color="logo_purple">
                        <Divider borderColor="logo_purple" mt={2} mb={0} />

                        <Text fontWeight="semibold">Nytt</Text>
                      </Flex>
                      <Flex
                        color="primary"
                        flexDir="column"
                        _hover={{
                          color: 'secondary',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                          void markMessageEventsAsReadHandler()
                        }}
                      >
                        <Text>Markera som läst</Text>
                      </Flex>
                    </Flex>
                  )}
                </Fragment>
              )
            })}
        </Flex>

        <AddOrEditMessage
          mt={4}
          area={area}
          areaId={areaId}
          parentArea={parentArea}
          parentAreaId={parentAreaId}
          grandParentArea={grandParentArea}
          grandParentAreaId={grandParentAreaId}
          conversationArea={conversationArea}
          areaFriendlyName={areaFriendlyName}
          areaFriendlyParentName={areaFriendlyParentName}
        />
      </Flex>
    </Flex>
  )
}
export default MyMessageSection
