import { createContext, useContext } from 'react'

type AreaType = {
  area: 'property' | 'project' | 'projectPlace' | 'helpRequest'
  areaId?: string
}

export const AreaContext = createContext<AreaType>({
  area: 'property',
})

/**
 * Which part ('area') of the application is the user interacting with at the moment.
 *
 * Multiple features can handle both properties or projects with reusable components.
 *
 * @returns 'property' | 'project'
 */
export const useArea = () => {
  return useContext(AreaContext)
}
