import { Box, Button, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import log from '../../common/log'
import {
  addAttachment,
  useAttachments,
} from '../../apiClients/attachmentsApiClient'

import Input from '../ui/Input'
import Spinner from '../Spinner'
import { compressFile } from './attachmentHelper'

const url = window.SUPPORT_SERVICE_URL

const AddAttachment: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)
  const areaValues = ['Article', 'Account', 'Property', 'Project', 'Note']
  const defaultArea = areaValues[0]
  const [area, setArea] = useState(defaultArea)
  const { mutate: mutateArticle } = useAttachments(['Article'])
  const { mutate: mutateAccount } = useAttachments(['Account'])
  const { mutate: mutateProperty } = useAttachments(['Property'])
  const { mutate: mutateProject } = useAttachments(['Property'])
  const { mutate: mutateNote } = useAttachments([
    'ProjectNote',
    'PropertyNote',
    'ProjectNote.description',
    'PropertyNote.description',
  ])
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const mutate = () => {
    switch (area) {
      case 'Article':
        void mutateArticle()
        break
      case 'Account':
        void mutateAccount()
        break
      case 'Project':
        void mutateProject()
        break
      case 'Property':
        void mutateProperty()
        break
      case 'Note':
        void mutateNote()
        break
    }
  }

  const handleUploadClick = () => {
    if (!file) {
      return
    }
    setIsLoading(true)
    compressFile(file, 'm', persistFile)
  }

  const persistFile = (file: File) => {
    const formData = new FormData()
    formData.set('image', file)
    formData.set('area', area)

    addAttachment(formData)
      .catch(e => {
        log.error({ err: e }, 'Error saving attachment')
        setIsLoading(false)
      })
      .finally(() => {
        mutate()
        onClose()
        setIsLoading(false)
      })
  }
  const changeAreaHandler = (nextValue: string) => {
    setArea(nextValue)
  }

  // if (isLoading) {
  //   return <Spinner />
  // } else {
  return (
    <form
      data-cy="add-attachment"
      action={`${url}/attachments`}
      method="POST"
      encType="multipart/form-data"
    >
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('attachments.addAttachment')}</Box>
      </Box>

      <RadioGroup
        ml={10}
        name="area"
        defaultValue="Article"
        onChange={changeAreaHandler}
      >
        <Text mt="1rem" fontStyle="infoText">
          {t('attachments.chooseArea')}
        </Text>
        <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight">
          {areaValues?.map(a => {
            return (
              <Radio
                key={a}
                value={a}
                padding=".5rem 1rem"
                defaultValue={defaultArea}
              >
                {t(`attachments.area.${a.toLowerCase()}`)}
              </Radio>
            )
          })}
        </Flex>
      </RadioGroup>

      <Box display="flex" flexDirection="column" my={4} mx={6}>
        <Input
          title={t('attachments.chooseFile')}
          name="image"
          isMandatory={false}
          type="file"
          variant="ghost"
          position="single"
          onChange={handleFileChange}
        />

        <Button
          my={4}
          variant="primary"
          onClick={handleUploadClick}
          isDisabled={isLoading}
        >
          {t('input.submitForm')}
        </Button>

        {isLoading && <Spinner />}
      </Box>
    </form>
  )
}
export default AddAttachment
