import { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import {
  getProviderFeatureDescriptions,
  SimpleArticle,
} from '../../apiClients/contentApiClient'
import Card from '../ui/Card'
import { Heading } from '../../ui'

const ProviderFeatureSection: React.FC<{
  bg?: string
  maxW?: string
  px?: number | string
}> = ({ bg, maxW, px }) => {
  const { t } = useTranslation()
  const [providerFeatureDescriptions, setProviderFeatureDescriptions] =
    useState<SimpleArticle[] | undefined>(undefined)
  useEffect(() => {
    const fetchProductData = async () => {
      const data = await getProviderFeatureDescriptions()
      setProviderFeatureDescriptions(data)
    }
    void fetchProductData()
  }, [])

  return (
    <Flex
      flexDir="column"
      pt={6}
      pb={10}
      wrap={{
        xxs: 'wrap',
        mobile: 'wrap',
        tablet: 'nowrap',
        laptop: 'nowrap',
      }}
      bg={bg}
      maxW={maxW}
      textAlign={{ xxs: 'center', desktop: 'left' }}
    >
      <Heading textStyle="h3" px="3%" py={6}>
        {t('content.publicHomePageProvider.berikarIntroPromo.heading')}
      </Heading>

      <Flex
        flexDir="row"
        justifyContent="center"
        alignItems="stretch"
        wrap="wrap"
        px={px}
        textAlign="left"
      >
        {providerFeatureDescriptions?.map(article => {
          return (
            <Card
              key={article.title}
              maxW={{ laptop: '95%', desktop: '31%' }}
              my={2}
              mx={{ tablet: 2 }}
              heading={article.heading || ''}
              minW={{ tablet: '400px' }}
              iconName={article.icon || undefined}
              bg="white"
              text={article.content}
              px={4}
              py={4}
            >
              {article.comingSoon && (
                <Box
                  color="logo_purple"
                  position="absolute"
                  right={2}
                  top={4}
                  transform="rotate(15deg)"
                  fontWeight="bold"
                  fontSize="14px"
                >
                  {t('general.comingSoon')}
                </Box>
              )}
            </Card>
          )
        })}
      </Flex>
    </Flex>
  )
}
export default ProviderFeatureSection
