import { Badge, Box, Button, Flex, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Organization, OrganizationStatus } from '@contracts/misc'
import { KeyedMutator } from 'swr'
import { useState } from 'react'
import {
  updateOrganizationStatusAdmin,
  useOrganizationAccounts,
  useOrganizationProfileAdmin,
  useServiceTypes,
} from '../../apiClients/organizationsApiClient'
import Skeleton from '../ui/Skeleton'
import TextCard from '../ui/TextCard'
import AddressCard from '../properties/AddressCard'
import ProviderProfileTemplate from '../provider/ProviderProfileTemplate'
import log from '../../common/log'
import AddOrEditProviderProfile from '../sections/portal/AddOrEditOrganizationProfile'
import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import ListProjectPlaces from '../../pages/admin/ListProjectPlaces'
import { useProjectPlaces } from '../../apiClients/projectsApiClient/projectPlaces'
import ListOrganizationAccounts from './ListOrgAccounts'

const OrganizationAdminSummaryCard: React.FC<{
  org: Organization
  mr?: number
  headerBg?: string
  mutateOrganizations?: KeyedMutator<Organization[] | undefined>
}> = ({ org, mr, headerBg, mutateOrganizations }) => {
  const { t } = useTranslation()
  const [isEditProfileMode, setIsEditProfileMode] = useState(false)
  const { data: allOrgAccounts } = useOrganizationAccounts(org.id)
  const { data: organizationProfile, mutate: adminProfileMutate } =
    useOrganizationProfileAdmin(org.id)
  const { data: serviceTypes } = useServiceTypes()

  const { data: orgProjectPlaces } = useProjectPlaces(org.id)

  if (!org) {
    return null
  }

  const availableStatuses: OrganizationStatus[] = [
    'Active',
    'Deleted',
    'New',
    'PausedByBerikar',
    'PausedByOrg',
    'WaitForApproval',
  ]

  const onChangeHandler = (newStatus: OrganizationStatus) => {
    updateOrganizationStatusAdmin(org.id, newStatus)
      .then(res => {
        log.debug('Successfuly updated orgnization status', res)
        mutateOrganizations ? void mutateOrganizations() : ''
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update organization status')
      })
  }

  if (org) {
    return (
      <Flex flexDir="column" minW="container.xl" my={4} gap={4}>
        <Flex
          flexDir="row"
          flexWrap="wrap"
          width="100%"
          alignItems="start"
          mr={mr}
          gap={4}
          wrap="wrap"
        >
          <Flex flexDir="column" gap={4}>
            <TextCard
              heading={t('accounts.providers.status')}
              variant="border"
              headerBg={headerBg}
              maxW="md"
            >
              <>
                <Box>{`${t('accounts.providers.status')}: ${org.status}`}</Box>
                <Box>
                  {t('providers.changeStatus')}
                  <Select
                    defaultValue={org.status}
                    size="sm"
                    mt={2}
                    onChange={e => {
                      onChangeHandler(e.target.value as OrganizationStatus)
                    }}
                  >
                    {availableStatuses.map(status => {
                      return (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      )
                    })}
                  </Select>
                </Box>
                <Box></Box>
              </>
            </TextCard>
          </Flex>
          <TextCard
            heading={t('general.contacts')}
            variant="border"
            headerBg={headerBg}
            maxW="md"
          >
            <>
              <Box pb={4}>
                {t('accounts.providers.phoneNumber')}: &nbsp;
                {org.phoneNumber}
              </Box>
              <AddressCard
                address={org.address}
                borderVariant="plain"
                headerBg={headerBg}
                noHeader
              />
            </>
          </TextCard>
          <TextCard
            heading={t('providers.profile.title')}
            variant="border"
            headerBg={headerBg}
            maxW="md"
          >
            <>
              <Box>
                {t('accounts.providers.profile.consultant')}:{' '}
                {organizationProfile?.consultant ? 'true' : 'false'}
              </Box>
              <Box>
                0{t('accounts.providers.profile.craftsman')}:{' '}
                {organizationProfile?.craftsman}
              </Box>
              <Box>
                {t('accounts.providers.profile.provideRemoteServices')}:{' '}
                {organizationProfile?.provideRemoteServices}
              </Box>
              <Flex flexDir="row" wrap="wrap">
                {t('accounts.providers.profile.serviceTypes')}:{' '}
                {organizationProfile?.serviceTypeIds &&
                  organizationProfile.serviceTypeIds.map(serviceTypeId => {
                    return (
                      <Badge
                        key={serviceTypeId}
                        variant="solid"
                        colorScheme="green"
                        mx={2}
                        my={1}
                      >
                        {
                          serviceTypes?.find(st => st.id === serviceTypeId)
                            ?.name
                        }
                      </Badge>
                    )
                  })}
              </Flex>
            </>
          </TextCard>
          <TextCard
            heading={t('accounts.providers.accounts')}
            variant="border"
            headerBg={headerBg}
            maxW="md"
          >
            <>
              <Box>
                {t('accounts.providers.email')}: &nbsp;
                {org.email}
              </Box>
              <Box>
                {t('accounts.providers.registrationNumber')}: &nbsp;
                {org.registrationNumber}
              </Box>
            </>
          </TextCard>
        </Flex>
        <ExpandableSectionCard
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          title={`${t('admin.orgAccounts')} (${allOrgAccounts?.length})`}
        >
          <ListOrganizationAccounts orgId={org.id} my={4} variant="link" />
        </ExpandableSectionCard>
        <ExpandableSectionCard title={t('admin.orgProfile')}>
          <>
            <Button
              colorScheme="green"
              mb={4}
              onClick={() => {
                setIsEditProfileMode(!isEditProfileMode)
              }}
            >
              {isEditProfileMode
                ? t('accounts.providers.profile.adminDoneEditingProfile')
                : t('accounts.providers.profile.adminEditProfile')}
            </Button>
            {isEditProfileMode && organizationProfile && (
              <AddOrEditProviderProfile
                currentOrganization={org}
                currentOrganizationProfile={organizationProfile}
                mutate={adminProfileMutate}
              />
            )}
            {!isEditProfileMode && (
              <ProviderProfileTemplate organization={org} />
            )}
          </>
        </ExpandableSectionCard>
        <ExpandableSectionCard
          title={`${t('projectPlaces.projectPlaces')} (${
            orgProjectPlaces?.length ?? 0
          })`}
        >
          <ListProjectPlaces
            variant="admin-by-organization"
            organizationId={org.id}
          />
        </ExpandableSectionCard>
      </Flex>
    )
  } else {
    return <Skeleton />
  }
}
export default OrganizationAdminSummaryCard
