import { useContext } from 'react'
import {
  Box,
  Flex,
  Image as ChakraImage,
  Avatar,
  useToast,
} from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import fallBackImg from '../../../img/accounts/profileBackgroundFallback_m.png'
import ImgPickerButton from '../../ui/ImgPickerButton'
import AuthContext from '../../../common/auth-context'
import {
  ACCOUNT_PROFILE_IMAGE_PREFIX,
  ACCOUNT_PROFILE_BACKGROUND_IMAGE_PREFIX,
} from '../../../pages/constants'
import {
  deleteAttachment,
  useAttachments,
} from '../../../apiClients/attachmentsApiClient'
import { updateAccount } from '../../../apiClients/accountsApiClient'
import log from '../../../common/log'
import { useImageUrl } from '../../attachments/attachmentHelper'
import AccountNameTag from '../../accounts/AccountNameTag'

const baseUrlPublic = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ProfileHeaderSection = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const imgUrl = useImageUrl(currentUser?.profileAttachmentId)
  const { t } = useTranslation()
  const toast = useToast()

  const oldImgId = currentUser?.profileAttachmentId ?? ''
  const { data: backgroundImg } = useAttachments(
    ['Account'],
    ACCOUNT_PROFILE_BACKGROUND_IMAGE_PREFIX,
  )
  const updateProfileImgHandler = (inputProp: string, inputValue: string) => {
    const requestData = currentUser
    if (!requestData) {
      return null
    }
    if (inputProp === 'profileImg') {
      requestData.profileAttachmentId = inputValue
    } else if (inputProp === 'backgroundImg') {
      requestData.backgroundAttachmentId = inputValue
    }
    updateAccount(currentUser.id, requestData)
      .then(() => {
        if (inputProp === 'profileImg') {
          // Delete blob only if private
          if (
            imgUrl?.includes('/private/') &&
            currentUser.profileAttachmentId
          ) {
            log.debug('Deleting old profile image: ', oldImgId)
            void deleteAttachment(oldImgId, true)
          }
        }
        log.debug('Successfully updated account')
        const userData = {
          ...currentUser,
          profileAttachmentId:
            requestData.profileAttachmentId ?? currentUser.profileAttachmentId,
          backgroundAttachmentId:
            requestData.backgroundAttachmentId ??
            currentUser.backgroundAttachmentId,
        }
        setCurrentUser(userData)

        toast({
          title: t('accounts.accountForm.profileUpdatedSuccessMessage'),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update account')
        toast({
          title: t('accounts.accountForm.profileUpdatedFailMessage'),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }
  let name = ''
  if (currentUser?.firstName && currentUser.lastName) {
    name = `${currentUser?.firstName} ${currentUser?.lastName}`
  }
  return (
    <Box pos="relative">
      <Flex>
        <ChakraImage
          h={{ xxs: 24, tablet: '140px', laptop: '180px' }}
          w="100vw"
          fit="cover"
          src={
            backgroundImg && backgroundImg.length > 0
              ? `${baseUrlPublic}${backgroundImg[0].id}`
              : fallBackImg
          }
          backgroundPosition="center"
        />
        {/* Profile image */}

        <Avatar
          pos="absolute"
          w={{ xxs: 32, tablet: 40 }}
          boxSize={{ xxs: 24, tablet: 32, laptop: 40 }}
          src={imgUrl}
          name={imgUrl ? '' : name}
          border="5px solid"
          color="primary_grey02"
          mt={{ xxs: 16, tablet: 24, laptop: 32 }}
          ml={{ xxs: 2, tablet: 4, laptop: 12 }}
          zIndex="base"
        />
      </Flex>
      <Box
        pos="absolute"
        top={{ xxs: 32, tablet: 44, laptop: '240px' }}
        left={{ xxs: 20, tablet: 28, laptop: '175px' }}
      >
        <ImgPickerButton
          imgChangeHandler={updateProfileImgHandler}
          iconColor="gray.500"
          variant={'ICON'}
          ContextArea="Account"
          attachmentPrefix={ACCOUNT_PROFILE_IMAGE_PREFIX}
          inputCallbackName="profileImg"
        />
      </Box>
      {currentUser && (
        <AccountNameTag
          account={currentUser}
          ml={{ xxs: 32, tablet: 44, laptop: 56 }}
          mt={1}
          mb={12}
        />
      )}
    </Box>
  )
}
export default ProfileHeaderSection
