import { Flex, ResponsiveValue, Text } from '@chakra-ui/react'

const TextPane: React.FC<{
  heading?: string
  text: string | undefined
  w?: ResponsiveValue<string | number>
  h?: string
  pt?: number
  background?: string
  border?: string
  borderColor?: string
  textStyle?: string
}> = ({
  heading,
  text,
  w,
  h,
  pt,
  background = 'white',
  border,
  borderColor,
  textStyle,
}) => {
  return (
    <Flex flexDir="column" w={w} pt={pt}>
      <Text textStyle="h3">{heading}</Text>
      <Flex
        as="pre"
        background={background}
        maxHeight={60}
        borderRadius={8}
        p={3}
        overflowY="scroll"
        wordBreak="break-all"
        h={h}
        minH={20}
        border={border}
        borderColor={borderColor}
        textStyle={textStyle}
        wrap="wrap"
      >
        {text}
      </Flex>
    </Flex>
  )
}
export default TextPane
