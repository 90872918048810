import { Flex, ResponsiveValue, useDisclosure } from '@chakra-ui/react'
import { ProjectPhase } from '@contracts/projects'
import { ReactElement } from 'react'
import ConstructionProcessPage from '../../pages/portal/ConstructionProcessPage'
import Modal from '../ui/Modal'

const ConstructionProcessCardModal: React.FC<{
  projectPhase?: ProjectPhase

  children: ReactElement
  w?: ResponsiveValue<number | string>
}> = ({ projectPhase = 'Idea', children, w }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      // border="1px solid red"
      onClick={onOpen}
      cursor="pointer"
      flexBasis={w}
      flexGrow={0}
      flexShrink={0}
      alignItems="center"
      gap={2}
      _hover={{ cursor: 'pointer', bg: 'primary_grey04' }}
      py={1}
      px={{ xxs: 1, tablet: 2 }}
      borderRadius="lg"
    >
      {children}
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        mb={0}
        px={0}
        py={0}
        pt={0}
        size="3xl"
      >
        <ConstructionProcessPage activePhase={projectPhase} onClose={onClose} />
      </Modal>
    </Flex>
  )
}
export default ConstructionProcessCardModal
