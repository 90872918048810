import { Flex, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import { FaHammer } from 'react-icons/fa'
import { useProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { AreaContext } from '../../common/area-context'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import EditProjectPlaceCustomerInfo from '../../components/projects/EditProjectPlaceCustomerInfo'
import EditProjectPlaceProductionInfo from '../../components/projects/EditProjectPlaceProductionInfo'
import EditProjectPlaceProjectInfo from '../../components/projects/EditProjectPlaceProjectInfo'
import EditProjectPlaceProjectType from '../../components/projects/EditProjectPlaceProjectType'
import ProjectFrontCard from '../../components/projects/ProjectFrontCard'
import ProjectPlaceCollaboratorsOverviewCard from '../../components/projects/ProjectPlaceCollaboratorsCard'
import ProjectPlaceProjectOverviewCard from '../../components/projects/ProjectPlaceProjectOverviewCard'
import MyMessageSection from '../../components/sections/portal/MyMessageSection'
import Divider from '../../components/ui/Divider'
import ProfileSectionCard from '../../components/ui/ProfileSectionCard'
import { IconButton } from '../../ui'

const ProjectPlacePage = () => {
  const { id: projectPlaceId } = useParams()
  const { t } = useTranslation()
  const { data: projectPlace } = useProjectPlace(projectPlaceId)
  const projectId = projectPlace?.projectId
  const { data: project } = useProjectInfo(projectId)
  const navigate = useNavigate()

  if (!projectPlaceId || !projectId) {
    return null
  }
  if (!projectPlace || !project) {
    return null
  }

  // const onEditHandler = () => {

  // }

  return (
    <AreaContext.Provider
      value={{ area: 'projectPlace', areaId: projectPlaceId }}
    >
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>{t('pageTitle.project', { name: project.name })}</title>
              </Helmet>
              {/* <ContentPageHeader label={projectPlace.name} pb={6}> */}
              <ContentPageHeader
                label={
                  <Flex>
                    <FaHammer size={24} color="gray" />
                    <Text ml={3}>{projectPlace.name}</Text>
                  </Flex>
                }
                pb={6}
              >
                <Text fontWeight="light" maxW="container.md">
                  {t('projectPlaces.projectPlaceText')}
                </Text>
              </ContentPageHeader>
              <Flex
                py={10}
                px={{ xxs: '0', tablet: '5%' }}
                wrap="wrap"
                gap={4}
                alignItems="flex-start"
              >
                <ProjectPlaceProjectOverviewCard
                  projectPlaceId={projectPlaceId}
                />
                <ProjectPlaceCollaboratorsOverviewCard
                  projectPlaceId={projectPlaceId}
                />
              </Flex>
              {projectId && (
                <AreaContext.Provider
                  value={{ area: 'project', areaId: projectId }}
                >
                  <Flex px={{ xxs: '0', tablet: '5%' }} flexDir="column">
                    <Divider
                      title={`${t('projectPlaces.customerProject')} - ${t(
                        'projectPlaces.commonWorkSpace',
                      )}`}
                      border="1px solid"
                      color="gray.500"
                      mb={6}
                      pl={{ xxs: 2, tablet: 0 }}
                    />
                    <Flex>
                      <ProjectFrontCard projectId={projectId} variant="full" />
                    </Flex>
                  </Flex>
                </AreaContext.Provider>
              )}

              <MyMessageSection
                area="ProjectPlace"
                areaId={projectPlaceId}
                headingStyle="h8"
                headingColor="black"
                isHeadingBold
                mt={3}
                mb={40}
                px={{ xxs: 2, tablet: '5%' }}
                areaFriendlyName={projectPlace.name}
                areaFriendlyParentName={project.name}
                parentArea="Project"
                parentAreaId={projectId}
                grandParentArea="Property"
                grandParentAreaId={project.propertyId}
                conversationArea="ProjectPlace"
              />
            </>
          }
        />
        <Route
          path="/edit/"
          element={
            <>
              <Helmet>
                <title>{t('pageTitle.project', { name: project.name })}</title>
              </Helmet>

              <ContentPageHeader
                label={
                  <Flex>
                    <FaHammer size={24} color="gray" />
                    <Text ml={3}>{projectPlace.name}</Text>
                  </Flex>
                }
                pb={6}
              >
                <Text fontWeight="light" maxW="container.md">
                  {t('projectPlaces.projectPlaceText')}
                </Text>
                <IconButton
                  buttonType={'BACK_LARGE'}
                  // top="120px"
                  bottom={2}
                  right={{ xxs: 2, tablet: 6 }}
                  onClick={() => {
                    navigate('')
                  }}
                />
              </ContentPageHeader>
              <Flex
                py={10}
                px={{ xxs: '0', tablet: '5%' }}
                wrap="wrap"
                gap={4}
                alignItems="flex-start"
                mb={32}
              >
                <ProfileSectionCard
                  title={t('projectPlaces.profile.sections.projectInfo')}
                  isFilledIn={projectPlace ? true : false}
                  w="100%"
                >
                  <EditProjectPlaceProjectInfo
                    projectPlace={projectPlace}
                    // mutate={mutate}
                  />
                </ProfileSectionCard>
                <ProfileSectionCard
                  title={t('projectPlaces.profile.sections.customerInfo')}
                  isFilledIn={
                    projectPlace.customerName && projectPlace.customerEmail
                      ? true
                      : false
                  }
                  w="100%"
                >
                  <EditProjectPlaceCustomerInfo
                    projectPlace={projectPlace}
                    // mutate={mutate}
                  />
                </ProfileSectionCard>
                <ProfileSectionCard
                  title={t('projectPlaces.profile.sections.productionInfo')}
                  isFilledIn={
                    projectPlace.beginAt && projectPlace.endAt ? true : false
                  }
                  w="100%"
                >
                  <EditProjectPlaceProductionInfo
                    projectPlace={projectPlace}
                    // mutate={mutate}
                  />
                </ProfileSectionCard>
                <ProfileSectionCard
                  title={t('projectPlaces.profile.sections.projectType')}
                  isFilledIn={projectPlace.propertyTypeId ? true : false}
                  w="100%"
                >
                  <EditProjectPlaceProjectType
                    projectPlace={projectPlace}
                    // mutate={mutate}
                  />
                </ProfileSectionCard>
              </Flex>
            </>
          }
        />
      </Routes>
    </AreaContext.Provider>
  )
}
export default ProjectPlacePage
