import { Flex, Text } from '@chakra-ui/react'
import { AttachmentSummary } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import {
  useNotes as useProjectNotes,
  useProjectsByProperty,
} from '../../apiClients/projectsApiClient'
import { useNote as useProjectNotesById } from '../../apiClients/projectsApiClient/notes'
import {
  useNotes,
  useNote as usePropertyNotesById,
} from '../../apiClients/propertiesApiClient/notes'
import FileCardCompact from '../attachments/AttachmentsCardCompact'
import FileDirectory from './FileDirectory'
import FileHeader from './FileHeader'
import FileLink from './FileLink'

const FileList: React.FC<{
  area: 'property' | 'project' | 'propertyNote' | 'projectNote'
  areaId: string
  activeId?: string
  noFileLink?: boolean
  callback: (
    title: string,
    area: 'property' | 'project' | 'propertyNote' | 'projectNote',
    areaId: string,
  ) => void
  isCheckable?: boolean
  checkedList?: AttachmentSummary[]
  onFileChecked?: (attachment: AttachmentSummary) => void
}> = ({
  area,
  areaId,
  callback,
  activeId,
  noFileLink = false,
  isCheckable = false,
  checkedList,
  onFileChecked,
}) => {
  // eslint-disable-next-line no-console
  // console.log('onFlieChecked: ', onFileChecked)
  const { t } = useTranslation()
  const { data: projects } = useProjectsByProperty(areaId)

  const { data: propertyNotes } = useNotes(
    area === 'property' ? areaId : undefined,
  )

  const { data: projectNotes } = useProjectNotes(
    area === 'project' ? areaId : undefined,
  )
  const { data: propertyNote } = usePropertyNotesById(
    area === 'propertyNote' ? areaId : undefined,
  )
  const { data: projectNote } = useProjectNotesById(
    area === 'projectNote' ? areaId : undefined,
  )

  if (area === 'project' && projectNotes) {
    return (
      <Flex flexDir="column">
        {!noFileLink && <FileLink area={area} areaId={areaId} />}
        {projectNotes && projectNotes.length > 0 && (
          <FileHeader area="projectNote" />
        )}
        {(!projectNotes || projectNotes.length === 0) && (
          <>
            <FileHeader area="projectNote" />
            <Text textStyle="cardText" my={0} mx={2}>
              {t('notes.noProjectNotesFound')}
            </Text>
          </>
        )}
        {projectNotes &&
          projectNotes.length > 0 &&
          projectNotes.map(projectNote => {
            return (
              <FileDirectory
                key={projectNote.id}
                title={projectNote.name}
                area="projectNote"
                areaId={projectNote.id}
                isActive={projectNote.id === activeId ? true : false}
                callback={() => {
                  callback(projectNote.name, 'projectNote', projectNote.id)
                }}
              />
            )
          })}
      </Flex>
    )
  }
  if (area === 'propertyNote' && propertyNote) {
    return (
      <Flex flexDir="column" px={2} wordBreak="break-all">
        {!noFileLink && <FileLink area={area} areaId={areaId} />}

        {propertyNote.attachmentsJson &&
          propertyNote.attachmentsJson.map(attachment => {
            return (
              <FileCardCompact
                attachmentId={attachment.id}
                key={attachment.id}
                isCheckable={isCheckable}
                isChecked={checkedList?.includes(attachment)}
                onFileChecked={onFileChecked}
              />
            )
          })}

        {!propertyNote.attachmentsJson?.length && (
          <Text my={2} textStyle="cardText">
            {t('attachments.noAttachmentAvailable')}
          </Text>
        )}
      </Flex>
    )
  }
  if (area === 'projectNote' && projectNote) {
    return (
      <Flex flexDir="column" px={2} wordBreak="break-word">
        {!noFileLink && <FileLink area={area} areaId={areaId} />}
        {projectNote.attachmentsJson &&
          projectNote.attachmentsJson.map(attachment => {
            return (
              <FileCardCompact
                attachmentId={attachment.id}
                key={attachment.id}
                isCheckable={isCheckable}
                isChecked={checkedList?.includes(attachment)}
                onFileChecked={onFileChecked}
              />
            )
          })}
        {!projectNote.attachmentsJson?.length && (
          <Text my={2} textStyle="cardText">
            {t('attachments.noAttachmentAvailable')}
          </Text>
        )}
      </Flex>
    )
  }

  if (area === 'property') {
    return (
      <Flex
        flexDir="column"
        // onClick={() => {
        //   onFileChecked ? onFileChecked('112') : ''
        // }}
        // border="1px solid red"
        // bg="red"
      >
        {!noFileLink && <FileLink area={area} areaId={areaId} />}
        {projects && projects.length > 0 && <FileHeader area="project" />}

        {projects &&
          projects.length > 0 &&
          projects.map(project => {
            return (
              <FileDirectory
                key={project.id}
                title={project.name}
                area="project"
                areaId={project.id}
                isActive={project.id === activeId ? true : false}
                callback={() => {
                  callback(project.name, 'project', project.id)
                }}
              />
            )
          })}
        {propertyNotes && propertyNotes.length > 0 && (
          <FileHeader area="propertyNote" />
        )}
        {(!propertyNotes || !propertyNotes.length) && (
          <>
            <FileHeader area="propertyNote" />
            <Text textStyle="cardText" my={0} mx={2}>
              {t('notes.noPropertyNotesFound')}
            </Text>
          </>
        )}

        {/* {(!projectNotes || projectNotes.length === 0) && (
          <>
            <FileHeader area="projectNote" />
            <Text textStyle="cardText" my={0} mx={2}>
              {t('notes.noProjectNotesFound')}
            </Text>
          </>
        )} */}

        {propertyNotes &&
          propertyNotes.length > 0 &&
          propertyNotes.map(propertyNote => {
            return (
              <FileDirectory
                key={propertyNote.id}
                title={propertyNote.name}
                area="propertyNote"
                areaId={propertyNote.id}
                isActive={propertyNote.id === activeId ? true : false}
                callback={() => {
                  callback(propertyNote.name, 'propertyNote', propertyNote.id)
                }}
              />
            )
          })}
      </Flex>
    )
  }
  return null
}
export default FileList
