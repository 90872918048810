import { filter, orderBy } from 'lodash'
import { PropertyResponse } from '@contracts/misc'
import { PropertySortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: PropertySortingOptionType,
  properties?: PropertyResponse[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption

  const filteredProperties = searchStr
    ? filter(properties, ({ name }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return name.toLowerCase().includes(searchStrLowercase)
      })
    : properties

  return orderBy(filteredProperties, sortingField, sortOrder)
}
