import { ConstructionGlossary } from '@contracts/support'
import { filter, orderBy } from 'lodash'
import { ConstructionGlossarySortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: ConstructionGlossarySortingOptionType,
  glossary?: ConstructionGlossary[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption
  const filteredGlossary = searchStr
    ? filter(glossary, ({ name, description }) => {
        const searchStrLowercase = searchStr.toLocaleLowerCase()
        return (
          name?.toLocaleLowerCase().includes(searchStrLowercase) ||
          description?.toLocaleLowerCase().includes(searchStrLowercase)
        )
      })
    : glossary
  return orderBy(filteredGlossary, sortingField, sortOrder)
}
