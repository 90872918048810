import { Icon } from '../ui/Icon'

const ClickableTarget: React.FC<{
  onShow: boolean
  toggleShow: () => void
  variant?: 'darkBackground' | 'lightBackground'
}> = ({ onShow, toggleShow, variant = 'lightBackground' }) => {
  if (onShow) {
    return (
      <Icon
        name="AiOutlineMinusCircle"
        color={variant === 'lightBackground' ? 'gray.500' : 'gray.600'}
        onClick={toggleShow}
        h={40}
        _hover={{
          color: variant === 'lightBackground' ? 'secondary' : 'gray.600',
        }}
        onPulseAnimate={true}
      />
    )
  }
  return (
    <Icon
      name="AiOutlinePlusCircle"
      color={variant === 'lightBackground' ? 'orange' : 'gray.600'}
      onClick={toggleShow}
      h={40}
      _hover={{
        color: variant === 'lightBackground' ? 'secondary' : 'gray.600',
        transform: 'scale(1.2)',
      }}
      onPulseAnimate={true}
    />
  )
}
export default ClickableTarget
