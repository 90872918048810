import { Box, Button, Flex } from '@chakra-ui/react'
import { ReactElement, useEffect } from 'react'
import { RiUploadCloud2Line } from 'react-icons/ri'
import { useFilePicker } from 'use-file-picker'
import { IconName } from '../../common/icon-utils'
import { FILE_PICKER_MAX_FILESIZE } from '../../pages/constants'
import { Icon } from './Icon'

const FilePickerButton: React.FC<{
  variant: 'ICON' | 'BUTTON' | 'MENU_ITEM'
  mt?: number
  mb?: number
  ml?: number
  w?: number | string
  iconName?: IconName
  iconColor?: string
  children?: ReactElement | string
  h?: number
  label?: string
  transform?: string
  imageId?: string
  isFilePreview?: boolean
  onUpdateFileList?: (fileList: File[]) => Promise<void>
  onSaveFiles?: (inputProp: string, inputValue: string) => void
  inputCallbackName?: string
  ContextArea: 'Article' | 'Account' | 'Property' | 'Project' | 'Note'
  attachmentPrefix?: string
  pickerVariant?: 'LIGHT' | 'MEDIUM' | 'FULL' | 'NO-DIALOG'
}> = ({
  mt,
  mb,
  ml,
  children,
  w,
  h,
  iconName = 'FiCamera',
  iconColor = 'primary_orange02',
  label,
  variant,
  onUpdateFileList,
  pickerVariant = 'MEDIUM',
  transform,
}) => {
  const [openFileSelector, { plainFiles }] = useFilePicker({
    multiple: true,
    // For future documentation -
    // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    readAs: 'DataURL',
    maxFileSize: FILE_PICKER_MAX_FILESIZE,
  })

  useEffect(() => {
    if (plainFiles.length > 0) {
      if (onUpdateFileList) {
        void onUpdateFileList(plainFiles)
      }
    }
  }, [plainFiles])

  const onClickHandler = () => {
    if (pickerVariant === 'NO-DIALOG') {
      openFileSelector()
    }
  }

  const getUIComponent = () => {
    if (variant === 'ICON') {
      return (
        <Box
          bg="white"
          w={10}
          h={10}
          border="1px"
          borderRadius="full"
          borderColor="gray.500"
          color="gray.500"
          p="7px"
          zIndex="tooltip"
          transform={transform}
        >
          <Icon
            name={iconName}
            _hover={{ color: 'primary_orange02' }}
            color={iconColor}
            onClick={onClickHandler}
            ml={ml}
            h={24}
          />
        </Box>
      )
    }
    if (variant === 'MENU_ITEM') {
      return (
        <Flex onClick={onClickHandler} alignItems="center" w={w} px={3} py={1}>
          <Box mr={3} color="secondaryDark">
            <RiUploadCloud2Line size={20} fontWeight="bold" />
          </Box>

          <span>Ladda upp</span>
        </Flex>
      )
    }
    return (
      <Button
        onClick={onClickHandler}
        mt={mt}
        mb={mb}
        variant="secondary"
        w={w || 32}
        h={h}
        transform={transform}
      >
        {children}
        {label}
      </Button>
    )
  }
  return <>{getUIComponent()}</>
}
export default FilePickerButton
