import _ from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Show,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react'
import { PropertyResponse } from '@contracts/misc'
import { ProjectResponse } from '@contracts/projects'
import { Icon } from '../ui/Icon'
import { getDateStrFromDate } from '../../common/util'
import { useAccountInvites } from '../../apiClients/accountsApiClient'
import log from '../../common/log'
import Heading from '../ui/Heading'
import InviteCard from './InviteCard'
import { useCollaboratorParts } from './collaboratorHelper'

const ListInvites: React.FC<{
  property?: PropertyResponse
  project?: ProjectResponse
  mt?: number | string
  pb?: number | string
}> = ({ property, project, mt, pb }) => {
  const { t } = useTranslation()
  const area: 'Property' | 'Project' = property ? 'Property' : 'Project'
  const areaId = property ? property.id : project?.id
  const { currentUserCollaboratorRelation } = useCollaboratorParts({
    propertyId: property?.id,
    projectId: project?.id,
  })

  if (!area || !areaId) {
    log.error('Error listing collaborators, missing area and/or areaId')
    return null
  }
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }
  const { data: invites, mutate } = useAccountInvites(area, areaId)

  return (
    <Box pb={pb} mt={mt}>
      {invites && invites.length > 0 && (
        <>
          <Heading textStyle="h9" mt={16} mb={2}>
            {t('properties.listingInvites')}
          </Heading>
          <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
            <Thead>
              <Tr>
                <Th></Th>
                <Show above="laptop">
                  <Th wordBreak="break-all">{t('general.user')}</Th>
                  <Th></Th>
                </Show>

                <Show above="laptop">
                  <Th wordBreak="break-all">{t('accounts.accessRights')}</Th>
                </Show>

                <Show above="laptop">
                  <Th>{t('accounts.inviteSent')}</Th>
                </Show>
              </Tr>
            </Thead>
            <Tbody>
              {invites.map((invite, i) => {
                const createdAt = getDateStrFromDate(new Date(invite.createdAt))
                return (
                  <Fragment key={invite.id}>
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      data-cy="accountTag"
                      onClick={() => {
                        toggleAccordion(invite.id)
                      }}
                    >
                      <Show above="laptop">
                        <Td maxW={8}>
                          {!accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlinePlusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                          {accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlineMinusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                        </Td>
                        <Td
                          overflow="clip"
                          wordBreak="break-word"
                          maxW={72}
                          minW={44}
                        >
                          {invite.email}
                        </Td>
                        <Td>
                          {invite.invitedAs === 'Consumer'
                            ? t('general.consumerUser')
                            : t('general.providerUser')}
                        </Td>
                        <Td minW={{ tablet: 44 }}>
                          {t(
                            `properties.accessRights.${invite.relation.toLowerCase()}`,
                          )}
                        </Td>
                      </Show>
                      <Show below="laptop">
                        <Td maxW={8}>
                          {!accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlinePlusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                          {accordionsToShow.includes(invite.id) && (
                            <Icon
                              name={'AiOutlineMinusCircle'}
                              h={24}
                              onClick={() => {
                                toggleAccordion(invite.id)
                              }}
                            />
                          )}
                        </Td>
                        <Td
                          colSpan={3}
                          overflow="clip"
                          wordBreak="break-word"
                          maxW={72}
                          minW={44}
                        >
                          <Text fontWeight="bold">{invite.email}</Text>

                          <Text>
                            {invite.invitedAs === 'Consumer'
                              ? t('general.consumerUser')
                              : t('general.providerUser')}
                          </Text>
                          <Text>
                            {t(
                              `properties.accessRights.${invite.relation.toLowerCase()}`,
                            )}
                          </Text>
                        </Td>
                      </Show>

                      <Show above="laptop">
                        <Td
                          maxW={{ xxs: 40, tablet: 96, laptop: '' }}
                          overflow="clip"
                        >
                          {t('properties.collaboratorInviteSentNotRegistered', {
                            date: createdAt,
                          })}
                        </Td>
                      </Show>
                    </Tr>
                    {accordionsToShow.includes(invite.id) && (
                      <Tr
                        bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                        borderTopWidth={1}
                      >
                        <Td colSpan={8}>
                          <InviteCard
                            // property={property}
                            // project={project}
                            area={area}
                            areaId={areaId}
                            invite={invite}
                            currentUserAccessRights={
                              currentUserCollaboratorRelation ?? 'Viewer'
                            }
                            // isOnlyAdmin={false}
                            mutate={mutate}
                          />
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                )
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  )
}

export default ListInvites
