import { Flex, Image } from '@chakra-ui/react'
import { Attachment } from '@contracts/support'
import { Icon } from '../ui/Icon'
import { isImage, isVideo, downloadAttachmentUrl } from './attachmentHelper'

const FileIcon: React.FC<{
  attachment?: Attachment
  file?: File

  minW?: number | string
  maxW?: number | string
  isMultipleFiles?: boolean
}> = ({
  attachment,
  file,

  minW,
  // minW = '52',
  maxW, // = 'md',
  isMultipleFiles = false,
}) => {
  const getFileIcon = () => {
    if (
      attachment?.originalFilename &&
      isImage(attachment.originalFilename ?? '')
      // attachment?.originalFilename
      //   ? isImage(attachment.originalFilename ?? '')
      //   : isImage(file?.name ?? '')
    ) {
      return (
        <Image
          w={16}
          h={12}
          my={2}
          src={
            attachment
              ? downloadAttachmentUrl(
                  attachment.id,
                  attachment.originalFilename,
                )
              : ''
          }
          objectFit="cover"
          // borderRadius="lg"
        />
      )
    }
    if (attachment ? isVideo(attachment.mimeType) : isVideo(file?.type ?? '')) {
      return <Icon name="FcVideoFile" h={20} />
    }

    if (isMultipleFiles) {
      return <Icon name="files" />
    }
    return <Icon name="file" />
  }

  return (
    <Flex
      // flexDir="row"
      // alignItems=""
      // justifyContent="space-between"
      minW={minW}
      maxW={maxW}
      _hover={{ color: 'gray.500', cursor: 'pointer' }}
      // border="1px solid red"
    >
      <Flex>{getFileIcon()}</Flex>
    </Flex>
  )
}
export default FileIcon
