import { Outlet } from 'react-router-dom'
import { Grid, GridItem, Show } from '@chakra-ui/react'

const LayoutPublic2 = () => {
  return (
    <Grid templateRows="70px 1fr 200px" templateColumns="0 1fr 0">
      {/* Margin left */}
      <GridItem rowSpan={2}></GridItem>
      <Show></Show>
      <GridItem>{/* <HeaderNavbarPublic /> */}</GridItem>
      {/* Margin right */}
      <GridItem></GridItem>
      <GridItem>
        <Outlet />
      </GridItem>
      <GridItem colStart={2}>{/* <Footer /> */}</GridItem>
    </Grid>
  )
}
export default LayoutPublic2
