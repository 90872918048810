import { Box, Flex, Tooltip } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
const SidebarAreaHeader: React.FC<{
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  children: ReactNode
  noAddIcon?: boolean
  onClick?: () => void
  isMinimized?: boolean
  toolTipText?: string
  dataCy?: string
}> = ({
  mt,
  mr,
  mb,
  ml,
  children,
  noAddIcon,
  isMinimized,
  toolTipText,
  onClick,
  dataCy,
}) => {
  return (
    <Box
      as="header"
      textStyle="navbarHeading"
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
      <Flex
        boxSize="1.5rem"
        _hover={{ cursor: 'pointer', color: 'primary_orange02' }}
        color="primary_grey02"
      >
        {!isMinimized && !noAddIcon && (
          <Tooltip
            hasArrow
            label={toolTipText}
            bg="primaryBackground"
            color="black"
            placement="top"
          >
            <Box
              fontSize={28}
              alignSelf="center"
              data-cy={dataCy}
              color="secondary"
            >
              <IoAddCircleOutline onClick={onClick} />
            </Box>
          </Tooltip>
        )}
      </Flex>
    </Box>
  )
}
export default SidebarAreaHeader
