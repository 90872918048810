/* eslint-disable react/jsx-no-useless-fragment */
import {
  Button,
  Checkbox,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import {
  ProjectPhase,
  ProjectResponse,
  ProjectStatus,
  UpdateProjectRequest,
} from '@contracts/projects'
import log from '../../common/log'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import CheckboxGroup from '../ui/CheckboxGroup'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import { useProperties } from '../../apiClients/propertiesApiClient'
import Spinner from '../Spinner'
import {
  updateProject,
  useProject,
  useProjectCategories,
  useProjects,
  deleteProject,
} from '../../apiClients/projectsApiClient/projects'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'

interface MyFormValues {
  projectName: string
  propertyId: string
  projectCategoryIds?: string[]
  status: ProjectStatus
  phase: ProjectPhase
}

interface Errors {
  projectName?: string
  propertyId?: string
  projectCategoryIds?: string
}

const availableStatuses: ProjectStatus[] = ['Active', 'Archived']
const availablePhases: ProjectPhase[] = [
  'Idea',
  'Design',
  'Production',
  'Warranty',
]

const EditProject: React.FC<{
  project: ProjectResponse
}> = ({ project }) => {
  const { data: properties } = useProperties()
  const { mutate: mutateAllProjects } = useProjects()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: allProjectCategories } = useProjectCategories()
  const { mutate: mutateThisProject } = useProject(project.id)
  const toast = useToast()
  const { currentUserCollaboratorRelation, currentUserCanEdit } =
    useCollaboratorParts({
      projectId: project.id,
    })

  const initialValues: MyFormValues = {
    projectName: project.name,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    propertyId: project.propertyId!,
    projectCategoryIds: project.projectCategories
      ? project.projectCategories.map(category => {
          return category.id
        })
      : [],
    status: project.status,
    phase: project.phase,
  }

  const validate = (values: MyFormValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.projectName,
      t('projects.projectForm.name'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.projectName = tmpRes.errorMsg
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const requestData: UpdateProjectRequest = {
        id: project.id,
        propertyId: values.propertyId,
        name: values.projectName,
        status: values.status,
        phase: values.phase,
        projectCategoryIds: values.projectCategoryIds ?? [],
      }

      updateProject(project.id, requestData)
        .then(() => {
          log.info('Successfully updated project')
          void mutateAllProjects()
          void mutateThisProject()
          toast({
            title: t('projects.updateSuccessMessage', {
              name: project.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to update project')
          toast({
            title: t('projects.updateFailureMessage', {
              name: project.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const onDeleteProject = () => {
    deleteProject(project.id)
      .then(() => {
        void mutateAllProjects()
        toast({
          title: t('projects.deleteSuccessMessage', {
            name: project.name,
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        navigate(PATH_MY_PRIVATE_PAGES)
      })
      .catch((err: AxiosError<{ error?: string }>) => {
        log.error('Failed to delete project', err)
        toast({
          title: t(
            `projects.${
              err.response?.data.error === 'NOTES_EXIST_ON_PROJECT'
                ? 'deleteFailureMessageNotesOnProject'
                : 'deleteFailureMessage'
            }`,
            {
              name: project.name,
            },
          ),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  return (
    <form onSubmit={handleSubmit} data-cy="edit-project">
      {!currentUserCanEdit ? (
        <Text mt="1rem" fontStyle="infoText">
          {t('projects.canNotEditProject')}
        </Text>
      ) : null}
      <Flex
        display="flex"
        flexDirection="column"
        mt={6}
        mb={{ xxs: 32, tablet: 6 }}
        maxW="md"
      >
        <Input
          defaultValue={project.name}
          title={t('projects.name')}
          name="projectName"
          type="text"
          isMandatory={true}
          isError={!!errors.projectName}
          errorText={
            touched.projectName && errors.projectName ? errors.projectName : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          position="single"
          variant="plain"
          disabled={isLoading || !currentUserCanEdit}
        />
        <Text ml="1rem" textStyle="infoSmall">
          *{t('input.mandatoryFields')}
        </Text>

        <RadioGroup defaultValue={project.propertyId}>
          <Text mt={4} fontStyle="infoText">
            {t('projects.chooseProperty')}
          </Text>
          <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight">
            <>
              {properties?.map(p => {
                return (
                  <Radio
                    key={p.id}
                    name="propertyId"
                    borderColor="gray.400"
                    value={p.id}
                    w={56}
                    py={2}
                    pl={4}
                    onChange={handleChange}
                    overflow="clip"
                    disabled={isLoading || !currentUserCanEdit}
                  >
                    {p.name}
                  </Radio>
                )
              })}
            </>
          </Flex>
        </RadioGroup>
        {allProjectCategories ? (
          <CheckboxGroup
            defaultValue={project.projectCategories?.map(pc => {
              return pc.id
            })}
            title={t('projects.projectTypes')}
            isError={!!values.projectCategoryIds}
            errorText={
              touched.projectCategoryIds && errors.projectCategoryIds
                ? t('projects.addProjectErrorMessages.categoryIsMissing')
                : ''
            }
            isMandatory={true}
            tooltip={t('projects.addProjectFormTooltips.projectCategories')}
          >
            <>
              {allProjectCategories.map(p => {
                return (
                  <Checkbox
                    borderColor="gray.400"
                    key={p.id}
                    name="projectCategoryIds"
                    value={p.id}
                    padding=".5rem 1rem"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isLoading || !currentUserCanEdit}
                  >
                    {t(`projects.projectCategoryItems.${p.name ?? ''}`)}
                  </Checkbox>
                )
              })}
            </>
          </CheckboxGroup>
        ) : (
          <Spinner />
        )}

        <Flex gap={6} my={4} w="100%">
          <Flex flexDir="column" gap={2}>
            <Text>{t('projects.status')}</Text>
            <Select
              name="status"
              defaultValue={project.status}
              size="sm"
              // mt={2}
              onChange={e => {
                handleChange(e)
              }}
              // my={6}
              // w={40}
              w={44}
              bg="white"
              borderRadius="lg"
              border="1px solid"
              borderColor="gray.400"
            >
              {availableStatuses.map(status => {
                return (
                  <option key={status} value={status}>
                    {t(`projects.statuses.${status}`)}
                  </option>
                )
              })}
            </Select>
          </Flex>
          <Flex flexDir="column" gap={2}>
            <Text>{t('projects.phase')}</Text>
            <Select
              name="phase"
              defaultValue={project.phase}
              size="sm"
              // mt={2}
              // w="50%"
              w={44}
              onChange={e => {
                handleChange(e)
              }}
              // my={6}
              bg="white"
              borderRadius="lg"
              border="1px solid"
              borderColor="gray.400"
            >
              {availablePhases.map(phase => {
                return (
                  <option key={phase} value={phase}>
                    {t(`projects.phases.${phase}`)}
                  </option>
                )
              })}
            </Select>
          </Flex>
        </Flex>

        <HStack>
          <Tooltip
            isDisabled={currentUserCanEdit}
            label={t('projects.canNotEditProject')}
          >
            <Button
              type="submit"
              margin="1rem 0"
              width="80%"
              variant={
                Object.keys(errors).length !== 0 ? 'disabled' : 'primary'
              }
              disabled={isLoading || !currentUserCanEdit}
            >
              {t('ui.button.update')}
            </Button>
          </Tooltip>

          {currentUserCollaboratorRelation === 'Owner' ? (
            <AlertDialogAndButton
              title={t('projects.deleteProject')}
              message={t('projects.deleteProjectQuestion', {
                name: project.name,
              })}
              buttonTitle={t('ui.button.delete')}
              buttonW="100"
              variant="deleteIconButton"
              onDestructiveAction={onDeleteProject}
            />
          ) : null}
        </HStack>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}

export default EditProject
