import { HStack, Flex, Text, ResponsiveValue } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import { Icon } from '../../ui/Icon'
import { Heading } from '../../../ui'

import AddPropertyButton from '../../ui/AddPropertyButton'
import { Logo } from '../../ui/Logo'

const WelcomeNewUserSection: React.FC<{
  userName: string
  maxW?: string
  mx?: ResponsiveValue<string | number>
}> = ({ userName, maxW, mx }) => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDir="row"
      justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
      wrap="wrap"
      my={14}
      mx={mx}
      maxW={maxW}
    >
      <Flex flexDir="column">
        <HStack spacing={4} textAlign="left">
          <Icon name="wavingHand" h={12} />
          <Heading textStyle="h2">
            {t('sections.welcomeNewUser.hello')} {userName}!
          </Heading>
        </HStack>
        <Flex
          flexDir="column"
          flexWrap="wrap"
          justifyContent="space-between"
          mt={6}
        >
          <Text textStyle="textLarge">
            {t('content.portalHome.welcomeNewUser')}
          </Text>
          <AddPropertyButton variant="posh-button" mt={8}>
            {t('content.portalHome.addHomeButtonText')}
          </AddPropertyButton>
          <Logo name="BerikarLovesHomes" h={60} maxW="lg" px={12} />
        </Flex>
      </Flex>
    </Flex>
  )
}
export default WelcomeNewUserSection
