import { Flex, Text, Button, useToast, ResponsiveValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useContext } from 'react'
import {
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
} from '@contracts/accounts'
import { Organization } from '@contracts/misc'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../../ui'
import { validateString } from '../../../common/auth-utils'
import AuthContext from '../../../common/auth-context'
import {
  addOrganization,
  updateOrganization,
  useCurrentOrganization,
} from '../../../apiClients/organizationsApiClient'
import log from '../../../common/log'
import { PATH_PROVIDER_PROFILE } from '../../../common/nav'
import Spinner from '../../Spinner'

interface NewProviderValues {
  companyName: string
  registrationNumber: string
}

interface Errors {
  companyName?: string
  registrationNumber?: string
}

const AddOrEditOrganizationBaseData: React.FC<{
  currentOrganization?: Organization | undefined
  maxW?: string
  mt?: ResponsiveValue<string | number>
  px?: ResponsiveValue<string | number>
  message?: string
}> = ({ maxW, mt, px, currentOrganization, message }) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const toast = useToast()
  const navigate = useNavigate()
  const { mutate } = useCurrentOrganization(currentUser?.id)

  const name = `${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`
  const initialValues: NewProviderValues = {
    companyName: currentOrganization?.name ?? '',
    registrationNumber: currentOrganization?.registrationNumber ?? '',
  }
  const validate = (values: NewProviderValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.companyName,
      t('accounts.providers.name'),
      2,
      50,
      true,
    )
    if (!tmpRes.isValid) {
      errors.companyName = tmpRes.errorMsg
    }

    tmpRes = validateString(
      values.registrationNumber,
      t('accounts.providers.registrationNumberError'),
      10,
      13,
      true,
    )
    if (!tmpRes.isValid) {
      errors.registrationNumber = tmpRes.errorMsg
    }
    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validate,

    onSubmit: (values, { setSubmitting }) => {
      if (currentOrganization) {
        const requestData: UpdateOrganizationRequest = {
          name: values.companyName,
          vatNumber: currentOrganization.vatNumber ?? '',
          email: currentOrganization.email ?? '',
          phoneNumber: currentOrganization.phoneNumber ?? '',
          address: currentOrganization.address,
        }

        updateOrganization(currentOrganization.id, requestData)
          .then(res => {
            log.debug('Successfully updated organization', res)
            void mutate()
            toast({
              title: t(
                'accounts.providers.profile.updateProfileSuccessMessage',
                {
                  name: requestData.name,
                },
              ),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .catch(err => {
            log.error({ err }, 'Failed to update organization base data')
            toast({
              title: t('accounts.providers.profile.updateProfileFailMessage'),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      } else {
        const requestData: CreateOrganizationRequest = {
          name: values.companyName,
          registrationNumber: values.registrationNumber,
        }

        addOrganization(requestData)
          .then(() => {
            log.debug('Successfully created new organization')
            void mutate()
            toast({
              title: t('accounts.providers.createOrganizationSuccessMessage', {
                name: requestData.name,
                regNumber: requestData.registrationNumber,
              }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            navigate(PATH_PROVIDER_PROFILE)
          })
          .catch(err => {
            log.error({ err: err }, 'Failed to create new organization')
            let errorMessage: string
            if (err.response.data.type === 'UNIQUE_CONSTRAINT') {
              errorMessage = t(
                'accounts.providers.createOrganizationFailMessageOrgnrTaken',
              )
            } else if (err.response.status === 400) {
              errorMessage = t(
                'accounts.providers.createOrganizationFailMessageIncorrectOrgnr',
              )
            } else {
              errorMessage = t(
                'accounts.providers.createOrganizationFailMessageGeneral',
              )
            }
            toast({
              title: errorMessage,
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            setSubmitting(false)
          })
      }
    },
  })

  return (
    <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
      <Flex
        flexDir="row"
        justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
        wrap="wrap"
      >
        <form onSubmit={handleSubmit} data-cy="add-new-provider">
          <Flex flexDir="column">
            <Text>{message}</Text>
            <Flex display="flex" flexDirection="column" mt={6}>
              <Input
                title={t('accounts.providers.name')}
                name="companyName"
                type="text"
                isMandatory={true}
                isError={!!errors.companyName}
                errorText={
                  touched.companyName && errors.companyName
                    ? errors.companyName
                    : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.companyName}
                mb={4}
              />
              <Input
                title={t('accounts.providers.registrationNumber')}
                name="registrationNumber"
                type="text"
                isMandatory={true}
                disabled={currentOrganization ? true : false}
                isError={!!errors.registrationNumber}
                errorText={
                  touched.registrationNumber && errors.registrationNumber
                    ? errors.registrationNumber
                    : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                variant="plain"
                defaultValue={initialValues.registrationNumber}
                mb={4}
              />
            </Flex>
            <Text>{t('general.administrator')}</Text>
            <Text fontWeight="light" mt={2}>
              {name}
            </Text>
            <Text fontWeight="light" mt={1}>
              {currentUser?.email}
            </Text>

            <Text fontWeight="light" mt={4}>
              {t('sections.registerNewProviderSection.explanation')}
            </Text>
            <Button type="submit" variant="primary" h={10} my={5}>
              {currentOrganization
                ? t('ui.button.update')
                : t('accounts.providers.submit')}
            </Button>
          </Flex>
        </form>
      </Flex>
      {isSubmitting && <Spinner />}
    </Flex>
  )
}
export default AddOrEditOrganizationBaseData
