import { CalendarIcon, ExternalLinkIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  Image,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { Note } from '@contracts/misc'
import _ from 'lodash'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccount } from '../../apiClients/accountsApiClient'
import { useAttachmentsByAreaQuery } from '../../apiClients/attachmentsApiClient'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import { usePropertyInfo } from '../../apiClients/propertiesApiClient/properties'
import { AreaContext } from '../../common/area-context'
import log from '../../common/log'
import {
  getHomePath,
  getProjectsPath,
  getPropertiesPath,
  PATH_PROJECT_NOTE,
  PATH_PROPERTY_NOTE,
} from '../../common/nav'
import {
  getDateStrFromNumber,
  getTimeStrFromNumber,
  minutesDiff,
} from '../../common/util'
import {
  getContentTailOfAttachedImages,
  removeConnectedImagesNotEmbedded,
} from '../attachments/attachmentHelper'
import FileManagerCard from '../attachments/FileManagerCard'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import HtmlPane from '../richTextEditor/HtmlPane'
import MyMessageSection from '../sections/portal/MyMessageSection'
import AddOrEditNoteButton from '../ui/AddOrEditNoteButton'
import Divider from '../ui/Divider'
import Heading from '../ui/Heading'
import AddOrEditNoteFilesButton from '../ui/AddOrEditNoteFilesButton'
import NoteCardContentButton from './NoteCardContentButton'
import { useNoteParts } from './notesHelper'
import NoteStatus from './NoteStatus'

const baseUrlPublic = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public` as string

const NoteCardMedium: React.FC<{
  note: Note
  propertyId?: string
  projectId?: string
  onClose?: () => void
}> = ({ note, propertyId, projectId, onClose }) => {
  const profileImg = undefined
  const { t } = useTranslation()
  const { data: createdBy } = useAccount(note.createdBy)
  const { data: updatedBy } = useAccount(note.updatedBy)
  const { noteTypes } = useNoteParts({ propertyId, projectId })
  const toast = useToast()
  const { currentUserCanEdit } = useCollaboratorParts({
    propertyId: propertyId,
    projectId: projectId,
  })
  const navigate = useNavigate()
  let areaLink = ''
  const { data: property } = usePropertyInfo(propertyId)
  const { data: project } = useProjectInfo(projectId)

  // eslint-disable-next-line no-console
  // console.log('note.name: ', note.name)
  // eslint-disable-next-line no-console
  // console.log('note.attachmentsJson: ', note.attachmentsJson)

  const { data: attachments } = useAttachmentsByAreaQuery([
    {
      area: propertyId ? 'PropertyNote' : 'ProjectNote',
      areaId: note.id,
    },
  ])
  const attachedImages = attachments?.filter(attachment => {
    // return attachment.mimeType.includes('image')
    if (attachment.mimeType.includes('image')) {
      // Remove all types included below
      if (attachment.mimeType.includes('svg')) {
        return false
      }
      return true
    }
  })

  const { data: attachedDescriptionImages } = useAttachmentsByAreaQuery([
    {
      area: propertyId ? 'PropertyNote.description' : 'ProjectNote.description',
      areaId: note.id,
    },
  ])

  const maxLengthPropOrProjyName = 15
  const maxLengthNoteName = 20
  const { pathname } = useLocation()

  useEffect(() => {
    // Temporarily to populate area data for attachment
    // if (note && note.description) {
    //   // eslint-disable-next-line no-console
    //   console.log('connectEmbeddedImagesNotConnect(note.description)')
    //   void connectEmbeddedImagesNotConnected(note.description, {
    //     area: propertyId
    //       ? 'PropertyNote.description'
    //       : 'ProjectNote.description',
    //     areaId: note.id,
    //     parentArea: propertyId ? 'Property' : 'Project',
    //     parentAreaId: propertyId ?? projectId,
    //     grandParentArea: property ? undefined : 'Property',
    //     grandParentAreaId: property ? undefined : project?.propertyId,
    //   })
    // }
    // Temporarily to populate area data for attachments
    // if (note.attachmentsJson?.length) {
    //   note.attachmentsJson.map(attachmentSummary => {
    //     void updateContextAreaData(attachmentSummary.id, {
    //       area: propertyId ? 'PropertyNote' : 'ProjectNote',
    //       areaId: note.id,
    //       parentArea: propertyId ? 'Property' : 'Project',
    //       parentAreaId: propertyId ?? projectId,
    //       grandParentArea: property ? undefined : 'Property',
    //       grandParentAreaId: property ? undefined : project?.propertyId,
    //     })
    //   })
    // }
    if (attachedDescriptionImages?.length && note.description) {
      if (attachedDescriptionImages[0].createdAt > note.updatedAt) {
        log.debug(
          'Image found younger than note updatedAt - then it should be removed!',
        )
        // eslint-disable-next-line no-console
        console.log('removeConnectedImagesNotEmbedded(note.description)')
        void removeConnectedImagesNotEmbedded(note.description, {
          area: propertyId
            ? 'PropertyNote.description'
            : 'ProjectNote.description',
          areaId: note.id,
          parentArea: propertyId ? 'Property' : 'Project',
          parentAreaId: propertyId ?? projectId,
          grandParentArea: property ? undefined : 'Property',
          grandParentAreaId: property ? undefined : project?.propertyId,
        })
      }
    }
  }, [])

  if (propertyId) {
    areaLink = `${getPropertiesPath(pathname)}/${propertyId}`
  } else if (projectId) {
    areaLink = `${getProjectsPath(pathname)}/${projectId}`
  }

  const onCopyLinkHandler = () => {
    if (propertyId) {
      const path = `http://${window.location.host}${PATH_PROPERTY_NOTE}/${note.id}`
      void navigator.clipboard.writeText(path)
    } else if (projectId) {
      void navigator.clipboard.writeText(
        `http://${window.location.host}${PATH_PROJECT_NOTE}/${note.id}`,
      )
    }
    toast({
      title: t('notes.copyLinkToast'),
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top',
    })
  }

  return (
    <>
      <Breadcrumb pt={1} pb={4} px={4}>
        <BreadcrumbItem textStyle="breadCrumbs">
          <BreadcrumbLink
            onClick={() => {
              onClose ? onClose() : ''
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
              navigate(getHomePath(pathname))
            }}
          >
            {t('general.home')}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              onClose ? onClose() : ''
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
              navigate(areaLink)
            }}
          >
            {property
              ? _.truncate(property.name, { length: maxLengthPropOrProjyName })
              : _.truncate(project?.name, { length: maxLengthPropOrProjyName })}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">
            {_.truncate(note.name, { length: maxLengthNoteName })}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex
        flexDir="row"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
        pb={4}
      >
        <Flex textStyle="h5" mx={2} mt={0} wrap="nowrap" wordBreak="break-word">
          {note.name}
        </Flex>
        <Box pb={0.5} mr={10}>
          <Tooltip
            label={t(
              `notes.shareNote.${propertyId ? 'propertyNote' : 'projectNote'}`,
            )}
            hasArrow
            arrowSize={15}
            closeDelay={500}
            placement="bottom-end"
          >
            <ExternalLinkIcon
              color="primary"
              boxSize={6}
              onClick={onCopyLinkHandler}
              _hover={{ color: 'gray' }}
            />
          </Tooltip>
        </Box>
      </Flex>

      <Flex flexDir="column" my={4} mx={2}>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          w="100%"
          alignItems="center"
          mb={3}
        >
          <Flex flexDir="row" justifyContent="left" alignItems="center">
            <CalendarIcon boxSize={4} color="gray.600" />
            <Text ml={2} py={2} fontSize={12}>
              {note.noteTimestamp
                ? getDateStrFromNumber(note.noteTimestamp)
                : ''}
            </Text>
            <TimeIcon boxSize={4} color="gray.600" ml={6} />
            <Text ml={2} py={2} fontSize={12}>
              {note.noteTimestamp
                ? getTimeStrFromNumber(note.noteTimestamp)
                : ''}
            </Text>
            {note.status && <NoteStatus status={note.status} ml={6} />}
          </Flex>
          <NoteCardContentButton
            note={note}
            propertyId={propertyId ?? ''}
            projectId={projectId ?? ''}
          />
        </Flex>
        {profileImg && (
          <>
            <Heading textStyle="cardHeading">{t('notes.coverImage')}</Heading>
            <Center w="100%" border="1px solid green" h={20} my={4}>
              <Image
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                src={`${baseUrlPublic}/${profileImg}`}
                borderRadius="lg"
                h={16}
              />
            </Center>
          </>
        )}
        <Box
          w="100%"
          maxW="container.lg"
          border="1px"
          borderColor="gray.300"
          borderRadius="md"
        >
          {note.description && (
            <>
              <HtmlPane content={note.description} maxH="Container.sm" />

              {attachedImages && attachedImages.length > 0 && (
                <>
                  <Divider />
                  <Heading textStyle="cardHeading" mx={4} mt={4}>
                    Nedan visas bifogade bilder
                  </Heading>
                  <HtmlPane
                    textStyle="cardText"
                    content={`${' '} ${getContentTailOfAttachedImages(
                      attachedImages,
                    )}`}
                    maxH="Container.sm"
                  />
                </>
              )}
            </>
          )}
        </Box>
        <Flex flexDir="row" wrap="nowrap" mt={4} overflow="clip" mr={4} h={8}>
          {note.noteTypeIds?.map(noteTypeId => {
            return (
              <Badge
                key={noteTypeId}
                py={0.1}
                px={1}
                mt={1}
                mr={1}
                borderWidth={3}
                borderColor="gray.300"
                borderRadius="md"
                fontWeight="light"
                h={6}
              >
                {
                  noteTypes?.find(nt => {
                    return nt.id === noteTypeId
                  })?.name
                }
              </Badge>
            )
          })}
        </Flex>
        <FileManagerCard
          my={4}
          attachmentIds={note.attachmentsJson?.map(({ id }) => id) ?? []}
        />
        <Text fontSize="small" fontWeight="light">
          {createdBy
            ? t('notes.createdInfo', {
                date: `${getDateStrFromNumber(
                  note.createdAt,
                )} ${getTimeStrFromNumber(note.createdAt)}`,
                author: `${createdBy.firstName} ${createdBy.lastName}`,
              })
            : ''}
        </Text>

        {minutesDiff(note.createdAt, note.updatedAt) < -0.1 && (
          <Text fontSize="small" fontWeight="light">
            {updatedBy
              ? t('notes.updatedInfo', {
                  date: `${getDateStrFromNumber(
                    note.updatedAt,
                  )} ${getTimeStrFromNumber(note.updatedAt)}`,
                  author: `${updatedBy.firstName} ${updatedBy.lastName}`,
                })
              : t('notes.updatedInfoNoAuthor', {
                  date: `${getDateStrFromNumber(
                    note.updatedAt,
                  )} ${getTimeStrFromNumber(note.updatedAt)}`,
                })}
          </Text>
        )}
        {currentUserCanEdit ? (
          <Flex mt={4} mb={{ xxs: 32, laptop: 0 }} gap={2}>
            <AddOrEditNoteButton
              variant="button"
              note={note}
              propertyId={propertyId}
              projectId={projectId}
              w="100%"
              // Incluse below if you want to close parent as weel
              // when AddOrEditNoteFiles-modal is being closed
              // onClose={onClose}
            >
              {/* {t('ui.button.edit')} */}
              <Text>{`Redigera ${propertyId ? 'anteckning' : 'inlägg'}`}</Text>
            </AddOrEditNoteButton>
            <AddOrEditNoteFilesButton
              variant="button"
              note={note}
              propertyId={propertyId}
              projectId={projectId}
              w="100%"
              px={{ xxs: 2, tablet: 1 }}

              // Incluse below if you want to close parent as weel
              // when AddOrEditNoteFiles-modal is being closed
              // onClose={onClose}
            >
              <Text>
                {note.attachmentsJson?.length
                  ? 'Redigera filer'
                  : 'Bifoga filer'}
              </Text>
            </AddOrEditNoteFilesButton>
          </Flex>
        ) : null}
        <AreaContext.Provider
          value={{
            area: property ? 'property' : 'project',
            areaId: note.entityId,
          }}
        >
          <MyMessageSection
            area={propertyId ? 'PropertyNote' : 'ProjectNote'}
            areaId={note.id}
            areaFriendlyName={note.name}
            areaFriendlyParentName={
              propertyId ? property?.name ?? '' : project?.name ?? ''
            }
            mx={0}
            parentArea={propertyId ? 'Property' : 'Project'}
            parentAreaId={propertyId ?? projectId}
            grandParentArea={property ? undefined : 'Property'}
            grandParentAreaId={property ? undefined : project?.propertyId}
            conversationArea={propertyId ? 'PropertyNote' : 'ProjectNote'}
          />
        </AreaContext.Provider>
      </Flex>
    </>
  )
}
export default NoteCardMedium
