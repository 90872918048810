import { VStack, Flex, Image } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Heading } from '../../ui'
import ImgPromoSectionHowItWorks from './../../img/pages/portalHome/promoPublicHowItWorks.png'

const HowBerikarWorksConsumerSection: React.FC<{ bg?: string }> = ({
  bg = 'primary_grey01',
}) => {
  const { t } = useTranslation()
  return (
    <VStack
      wrap={{
        xxs: 'wrap',
        mobile: 'wrap',
        tablet: 'nowrap',
        laptop: 'nowrap',
      }}
      spacing={8}
      background="white"
      justifyContent={['center', 'space-between', 'left']}
    >
      <Flex
        flexDir="column"
        justifyContent="left"
        w="100%"
        px={8}
        pb={4}
        bg={bg}
        py={10}
      >
        <Heading ml={10} textStyle="h2">
          {t('content.howItWorks')}
        </Heading>
        <Image src={ImgPromoSectionHowItWorks} w="container.lg"></Image>
      </Flex>
    </VStack>
  )
}
export default HowBerikarWorksConsumerSection
