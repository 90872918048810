import { Flex, useMediaQuery } from '@chakra-ui/react'
import { Note } from '@contracts/misc'
import { useArea } from '../../common/area-context'

import log from '../../common/log'
import Spinner from '../Spinner'
import NoteCardCompact from './NoteCardCompact'
import NoteCardMicro from './NoteCardMicro'
import { useNoteParts } from './notesHelper'

const NotesTilesView: React.FC<{
  propertyId?: string
  projectId?: string
  filteredNotes?: Note[]
  variant?: 'micro' | 'compact'
  mx?: number | string
}> = ({ propertyId, projectId, mx, variant, filteredNotes }) => {
  const noteArea = useArea()
  const [tabletOrBelow] = useMediaQuery('(max-width: 768px)')
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  if (!variant) {
    if (tabletOrBelow) {
      variant = 'micro'
    } else {
      variant = 'compact'
    }
  }
  let notes: Note[] | undefined = undefined
  const { notes: notesFromDB } = useNoteParts({ propertyId, projectId })
  notes = filteredNotes ?? notesFromDB
  return (
    <Flex
      flexDir="row"
      justifyContent="left"
      ml={mx}
      gap={{ xxs: 4, laptop: 8 }}
      mt={6}
      wrap="wrap"
    >
      {notes ? (
        notes.map(note => {
          if (variant === 'compact') {
            return (
              <NoteCardCompact
                note={note}
                key={note.id}
                propertyId={propertyId}
                projectId={projectId}
              />
            )
          } else {
            return (
              <NoteCardMicro
                note={note}
                key={note.id}
                propertyId={propertyId}
                projectId={projectId}
              />
            )
          }
        })
      ) : (
        <Spinner />
      )}
    </Flex>
  )
}
export default NotesTilesView
