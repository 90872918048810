import { AttachmentSummary, Note, NoteRequest, NoteType } from '@contracts/misc'
import { KeyedMutator } from 'swr'
import {
  Attachment,
  ContextArea,
  CopyAttachmentRequest,
  CreateAttachmentRequest,
} from '@contracts/support'
import { ProjectInfoResponse } from '@contracts/projects'
import {
  useNotes as usePropertyNotes,
  addNote as addPropertyNote,
  updateNote as updatePropertyNote,
  deleteNote as deletePropertyNote,
  useNoteTypes as usePropertyNoteTypes,
} from '../../apiClients/propertiesApiClient'
import {
  useNoteTypes as useProjectNoteTypes,
  useNotes as useProjectNotes,
  addNote as addProjectNote,
  updateNote as updateProjectNote,
  deleteNote as deleteProjectNote,
} from '../../apiClients/projectsApiClient'
import { useArea } from '../../common/area-context'
import { copyAttachment } from '../../apiClients/attachmentsApiClient'
import { AreaData } from './AddOrEditNoteFiles'

export const latestNotesMaxAgeInMonths = 2
export const latesNotesMaxNoNotes = 6

interface NoteParts {
  entityId: string
  notes: Note[] | undefined
  noteTypes: NoteType[] | undefined
  mutate: KeyedMutator<Note[] | undefined>
  addNote: (entityId: string, newNote: NoteRequest) => Promise<Note>
  updateNote: (id: string, noteRequest: NoteRequest) => Promise<void>
  toggleStar: (note: Note) => Promise<void>
  updateName: (note: Note, newName: string) => Promise<void>
  deleteNote: (note: Note) => Promise<void>
}

/**
 * Handle differences when handling collaborators for a 'property' or a 'project'.
 */
export const useNoteParts = ({
  propertyId,
  projectId,
}: {
  propertyId?: string
  projectId?: string
}): NoteParts => {
  const { area } = useArea()
  if (area === 'property' && propertyId) {
    const { data, mutate } = usePropertyNotes(propertyId)
    const { data: noteTypes } = usePropertyNoteTypes()
    return {
      entityId: propertyId,
      notes: data,
      noteTypes,
      mutate,
      addNote: async (entityId: string, newNote: NoteRequest) =>
        addPropertyNote(entityId, newNote).then(res => {
          void mutate()
          return res.data
        }),

      updateNote: async (noteId: string, noteRequest: NoteRequest) =>
        updatePropertyNote(noteId, noteRequest).then(() => {
          void mutate()
        }),

      toggleStar: async (note: Note) =>
        togglePropertyStarHandler(note).then(() => {
          void mutate()
        }),

      updateName: async (note: Note, newName: string) =>
        updatePropertyNoteNameHandler(note, newName).then(() => {
          void mutate
        }),

      deleteNote: async (note: Note) =>
        deletePropertyNote(note.id).then(() => {
          void mutate()
        }),
    }
  } else if (area === 'project' && projectId) {
    const { data, mutate } = useProjectNotes(projectId)
    const { data: noteTypes } = useProjectNoteTypes()
    return {
      entityId: projectId,
      notes: data,
      noteTypes,
      mutate,
      addNote: async (entityId: string, newNote: NoteRequest) =>
        addProjectNote(entityId, newNote).then(res => {
          void mutate()
          return res.data
        }),
      updateNote: async (entityId: string, noteRequest: NoteRequest) =>
        updateProjectNote(entityId, noteRequest).then(() => {
          void mutate()
        }),

      toggleStar: async (note: Note) =>
        toggleProjectStarHandler(note).then(() => {
          void mutate()
        }),

      updateName: async (note: Note, newName: string) =>
        updateProjectNoteNameHandler(note, newName).then(() => {
          void mutate
        }),

      deleteNote: async (note: Note) =>
        deleteProjectNote(note.id).then(() => {
          void mutate()
        }),
    }
  }
  throw new Error(
    'To get correct collaborator parts the component must be in an AreaContext.',
  )
}

export const togglePropertyStarHandler = async (note: Note) => {
  note.starred = !note.starred
  await updatePropertyNote(note.id, note)
}
export const toggleProjectStarHandler = async (note: Note) => {
  note.starred = !note.starred
  await updateProjectNote(note.id, note)
}
export const updatePropertyNoteNameHandler = async (
  note: Note,
  newName: string,
) => {
  note.name = newName
  await updatePropertyNote(note.id, note)
}
export const updateProjectNoteNameHandler = async (
  note: Note,
  newName: string,
) => {
  note.name = newName
  await updateProjectNote(note.id, note)
}

export const getProjectNoteTypeName = (id: string) => {
  const { data: projectNoteTypes } = useProjectNoteTypes()
  if (projectNoteTypes) {
    return projectNoteTypes.find(noteType => {
      return noteType.id === id
    })?.name
  } else {
    return null
  }
}

export const copyExistingAttachmentsToNoteHandler = async (
  attachmentsToCopy: Attachment[] | AttachmentSummary[],
  noteId: string,
  area: ContextArea,
  propertyId?: string,
  projectId?: string,
  project?: ProjectInfoResponse,
): Promise<Attachment[]> => {
  let copiedAttachments: Attachment[] = []

  let areaData: AreaData

  if (area === 'Property') {
    areaData = {
      area: 'PropertyNote',
      parentArea: 'Property',
    }
    if (propertyId) {
      areaData.parentAreaId = propertyId
    }
  } else {
    areaData = {
      area: 'ProjectNote',
    }
    if (projectId) {
      areaData.parentArea = 'Project'
      areaData.parentAreaId = projectId
      areaData.grandParentArea = 'Property'
      if (project && project.propertyId) {
        areaData.grandParentAreaId = project.propertyId
      }
    }
  }
  areaData.areaId = noteId

  const attachmentRequest: CreateAttachmentRequest = {
    area: areaData.area,
    areaId: areaData.areaId ?? '',
    parentArea: areaData.parentArea ?? null,
    parentAreaId: areaData.parentAreaId ?? null,
    grandParentArea: areaData.grandParentArea ?? null,
    grandParentAreaId: areaData.grandParentAreaId ?? null,
    container: null,
  }
  const attachmentIdsToCopy = attachmentsToCopy.map(a => {
    return a.id
  })
  const copyRequestData: CopyAttachmentRequest = {
    attachmentIdsToCopy: attachmentIdsToCopy,
    attachmentRequest: attachmentRequest,
  }

  copiedAttachments = await copyAttachment(copyRequestData, true)

  return copiedAttachments
}
