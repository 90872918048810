import { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthContext from './common/auth-context'
import AdminManagement from './layout/admin/AdminManagement'
import { LOG_IN_PATH } from './components/accounts/constants'
import GuidancePage from './pages/public/GuidancePage'
import VerifyEmailPage from './pages/public/VerifyEmailPage'
import ResetPasswordPage from './pages/public/ResetPasswordPage'
import ClimateSmartConstructionPage from './pages/public/ClimateSmartConstructionPage'
import HomePortal from './pages/portal/HomePortal'
import PropertyPage from './pages/portal/PropertyPage'
import ProjectPage from './pages/portal/ProjectPage'
import LayoutPortal from './layout/LayoutPortal'
import LayoutAdmin from './layout/LayoutAdmin'
import ProfilePage from './pages/portal/AccountProfilePage'
import CompareClimateImpactPage from './pages/portal/CompareClimateImpactPage'
import ConstructionGlossaryPage from './pages/portal/ConstructionGlossaryPage'
import ConstructionProcessPage from './pages/portal/ConstructionProcessPage'
import ConstructionPartsClimateImpactPage from './pages/portal/BuildingPartsClimateImpactPage'
import DetailedPlanAndBuildingPermitPage from './pages/portal/DetailedPlanAndBuildingPermitPage'
import RegisterProviderPage from './pages/provider/RegisterProviderPage'
import ProviderProfilePage from './pages/provider/ProviderProfilePage'
import HomeProviderPortal from './pages/portal/HomeProviderPortal'
import ProviderBookingsPage from './pages/provider/ProviderBookingsPage'
import ProviderContactsPage from './pages/provider/ProviderContactsPage'
import ProviderProjectPlacesPage from './pages/provider/ProviderProjectPlacesPage'
import ProviderWriteArticlePage from './pages/provider/ProviderWriteArticlePage'
import VerifyInvitePage from './pages/public/VerifyInvitePage'
import MyFilesPage from './pages/portal/MyFilesPage'
import ProjectPlacePage from './pages/provider/ProjectPlacePage'
import AddOrEditProviderBaseData from './components/sections/portal/AddOrEditOrganizationBaseData'
import MyHelpRequestsPage from './pages/portal/MyHelpRequestsPage'
import ProviderHelpRequestsPage from './pages/portal/ProviderHelpRequestsPage'
import LoginAndRegistrationPage from './pages/public/LoginAndRegistrationPage'
import LayoutPublic2 from './layout/LayoutPublic2'
import FindExpertPage from './pages/public/FindExpertPage'
import ImpersonatePage from './pages/public/ImpersonatePage'

const AppRouter = () => {
  const { isLoggedIn, isProvider, isAdmin, isConsumer, currentOrganization } =
    useContext(AuthContext)
  const { t } = useTranslation()

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPublic2 />}>
          {!isLoggedIn && (
            <>
              <Route index element={<LoginAndRegistrationPage />} />
              {/* TODO: Add 404 page */}
              {/* <Route path="*" element={<404Page />} /> */}
              <Route path="*" element={<LoginAndRegistrationPage />} />
              <Route
                path={LOG_IN_PATH}
                element={<LoginAndRegistrationPage />}
              />
              <Route path="login" element={<LoginAndRegistrationPage />} />
              <Route
                path="login-foretagare"
                element={
                  <LoginAndRegistrationPage defaultAccountType="Provider" />
                }
              />

              <Route
                path="registrera"
                element={<LoginAndRegistrationPage showRegistrationForm />}
              />
              <Route
                path="registrera-foretagare"
                element={
                  <LoginAndRegistrationPage
                    showRegistrationForm
                    defaultAccountType="Provider"
                  />
                }
              />
            </>
          )}

          <Route path="verifiera-email" element={<VerifyEmailPage />} />
          <Route path="verifiera-inbjudan" element={<VerifyInvitePage />} />
          <Route path="aterstall-losenord" element={<ResetPasswordPage />} />
          <Route path="impersonate" element={<ImpersonatePage />} />
        </Route>

        {isLoggedIn && isConsumer && (
          <Route path="/" element={<LayoutPortal context="consumer" />}>
            <Route index element={<HomePortal />} />
            <Route
              path="anteckning/:id/*"
              element={<HomePortal targetArea="property" />}
            />
            <Route
              path="projektinlagg/:id/*"
              element={<HomePortal targetArea="project" />}
            />

            <Route path="fastighet/:id/*" element={<PropertyPage />} />
            <Route path="projekt/:id/*" element={<ProjectPage />} />
            <Route path="profil/*" element={<ProfilePage />} />
            <Route path="mina-filer/*" element={<MyFilesPage />} />
            <Route path="hitta-expert/*" element={<FindExpertPage />} />
            <Route path="forfragningar/*" element={<MyHelpRequestsPage />} />
            <Route
              path="byggprocessen/*"
              element={<ConstructionProcessPage />}
            />
            {/* TODO: Add a 404 instead? */}
            <Route path="*" element={<HomePortal />} />
            <Route
              path="klimatsmart-byggande/*"
              element={<ClimateSmartConstructionPage />}
            />
            <Route path="byggtermer/*" element={<ConstructionGlossaryPage />} />
            <Route
              path="detaljplan-och-bygglov/*"
              element={<DetailedPlanAndBuildingPermitPage />}
            />
            <Route
              path="klimatpaverkan-fran-byggdelar/*"
              element={<ConstructionPartsClimateImpactPage />}
            />

            <Route path="vagledning/*" element={<GuidancePage />} />

            <Route
              path="jamfor-klimatpaverkan/*"
              element={<CompareClimateImpactPage />}
            />
          </Route>
        )}

        {isLoggedIn && isAdmin && (
          <Route path="/admin/" element={<LayoutAdmin />}>
            <Route index element={<AdminManagement />} />
            <Route path="*" element={<AdminManagement />} />
          </Route>
        )}

        {isLoggedIn && isProvider && (
          // <Route path="portal" element={<LayoutPortal context="provider" />}>
          <Route path="/" element={<LayoutPortal context="provider" />}>
            <Route index element={<HomeProviderPortal />} />
            <Route
              path="anteckning/:id/*"
              element={<HomeProviderPortal targetArea="property" />}
            />
            <Route
              path="projektinlagg/:id/*"
              element={<HomeProviderPortal targetArea="project" />}
            />
            {/* <Route path="forfragningar/*" element={<ProviderAsksPage />} /> */}
            <Route
              path="forfragningar/*"
              element={<ProviderHelpRequestsPage />}
            />
            <Route path="mina-filer/*" element={<MyFilesPage />} />
            <Route path="bokningar/*" element={<ProviderBookingsPage />} />
            <Route path="fastighet/:id/*" element={<PropertyPage />} />

            <Route
              path="projekt/:id/*"
              element={
                currentOrganization ? (
                  <ProjectPage />
                ) : (
                  <AddOrEditProviderBaseData
                    px={{ xxs: 2, tablet: '5%' }}
                    mt={20}
                    maxW="container.lg"
                    message={t(
                      'accounts.providers.profile.noRegisteredOrgMessage',
                    )}
                  />
                )
              }
            />
            <Route path="projektplats/:id/*" element={<ProjectPlacePage />} />
            <Route
              path="mina-projektplatser/*"
              element={<ProviderProjectPlacesPage />}
            />
            <Route path="kontakter/*" element={<ProviderContactsPage />} />
            <Route
              path="skriv-artikel/*"
              element={<ProviderWriteArticlePage />}
            />
            <Route path="foretagsprofil/*" element={<ProviderProfilePage />} />
            <Route
              path="registrera-foretag/"
              element={<RegisterProviderPage />}
            />
            <Route path="anvandarprofil/*" element={<ProfilePage />} />
            {/* TODO: Add a 404 instead? */}
            <Route path="*" element={<HomeProviderPortal />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  )
}
export default AppRouter
