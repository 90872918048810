import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Image,
  Checkbox,
  Link as LinkChakra,
  ResponsiveValue,
} from '@chakra-ui/react'
import { AttachmentSummary } from '@contracts/misc'
import { useNavigate } from 'react-router-dom'
import { Attachment } from '@contracts/support'
import { useAttachment } from '../../apiClients/attachmentsApiClient'
import {
  PATH_MY_PRIVATE_PROJECTS,
  PATH_MY_PRIVATE_PROPERTIES,
  PATH_NOTE_QUERY,
} from '../../common/nav'
import { getDateStrFromNumber } from '../../common/util'
import Link from '../ui/Link'
import { downloadAttachmentUrl } from './attachmentHelper'
import FileIcon from './FileIcon'

const AttachmentsCardCompact: React.FC<{
  attachment?: Attachment
  attachmentId?: string
  minW?: number | string
  maxW?: number | string
  isCheckable?: boolean
  isChecked?: boolean
  onFileChecked?: (attachment: AttachmentSummary) => void
  variant?: 'list-item' | 'normal' | 'thumbnail'
  link?: string
  onOpenNoteCard?: () => void
  thumbnailSize?: ResponsiveValue<number | string>
}> = ({
  attachment,
  attachmentId,
  minW = '52',
  maxW,
  isCheckable = false,
  isChecked = false,
  onFileChecked,
  variant = 'normal',
  link,
  onOpenNoteCard,
  thumbnailSize = 14,
}) => {
  const navigate = useNavigate()
  const { data: attachmentFromDb } = useAttachment(
    attachment ? undefined : attachmentId,
  )
  if (attachmentFromDb) {
    attachment = attachmentFromDb
  }

  if (!attachment) {
    return null
  }

  const openAttachmentInNewTab = () => {
    if (!attachment) {
      return
    }
    window.open(
      downloadAttachmentUrl(attachment.id, attachment.originalFilename),
      '_blank',
    )
  }

  const getAreaLink = () => {
    if (!attachment) {
      return ''
    }
    if (
      attachment.area.startsWith('PropertyNote') &&
      attachment.parentAreaId &&
      attachment.areaId
    ) {
      // Path to property note
      return `${PATH_MY_PRIVATE_PROPERTIES}/${attachment.parentAreaId}${PATH_NOTE_QUERY}${attachment.areaId}`
    }
    if (
      attachment.area === 'Message' &&
      attachment.parentArea === 'PropertyNote' &&
      attachment.grandParentAreaId &&
      attachment.parentAreaId
    ) {
      // Path to property note
      return `${PATH_MY_PRIVATE_PROPERTIES}/${attachment.grandParentAreaId}${PATH_NOTE_QUERY}${attachment.parentAreaId}`
    }
    if (
      attachment.area.startsWith('ProjectNote') &&
      attachment.parentAreaId &&
      attachment.areaId
    ) {
      // Path to project note
      return `${PATH_MY_PRIVATE_PROJECTS}/${attachment.parentAreaId}${PATH_NOTE_QUERY}${attachment.areaId}`
    }
    if (
      attachment.area === 'Message' &&
      attachment.parentArea === 'ProjectNote' &&
      attachment.grandParentAreaId &&
      attachment.parentAreaId
    ) {
      // Path to project note
      return `${PATH_MY_PRIVATE_PROJECTS}/${attachment.grandParentAreaId}${PATH_NOTE_QUERY}${attachment.parentAreaId}`
      // return `${PATH_MY_PRIVATE_PROPERTIES}/${attachment.grandParentAreaId}${PATH_NOTE_QUERY}${attachment.parentAreaId}`
    }
    return ''
  }

  const onChange = () => {
    if (!attachment) {
      return
    }
    onFileChecked ? void onFileChecked(attachment) : ''
  }

  if (variant === 'thumbnail') {
    if (!attachment.areaId) {
      return null
    }
    return (
      <Flex
        flexDir="column"
        pos="relative"
        border="1px solid"
        borderRadius="lg"
        borderColor="gray.200"
        _hover={{ borderColor: 'gray.400' }}
      >
        <LinkChakra as="a" onClick={openAttachmentInNewTab}>
          <Image
            src={downloadAttachmentUrl(
              attachment.id,
              attachment.originalFilename,
            )}
            boxSize={thumbnailSize}
            fit="cover"
            borderRadius="lg"
          />
        </LinkChakra>

        {attachment.areaId && (
          <ExternalLinkIcon
            pos="absolute"
            right={0}
            bottom={0}
            // m={1}
            p={1}
            m={1}
            boxSize={6}
            onClick={() => {
              navigate(getAreaLink())
            }}
            _hover={{ cursor: 'pointer', color: 'gray.400' }}
            bg="gray.200"
            opacity={0.6}
            borderRadius="md"
          />
        )}
      </Flex>
    )
  }
  return (
    <Flex
      flexDir="row"
      alignItems=""
      justifyContent="space-between"
      minW={minW}
      maxW={maxW}
      _hover={{ color: 'gray.500', cursor: 'pointer' }}
    >
      {variant === 'normal' && (
        <Flex flexDir="row" alignItems="center">
          {isCheckable && (
            <Checkbox mr={3} defaultChecked={isChecked} onChange={onChange} />
          )}
          <Flex onClick={openAttachmentInNewTab} alignItems="center">
            <FileIcon attachment={attachment} />
            <LinkChakra as="a" onClick={openAttachmentInNewTab}>
              <Text ml={2} textStyle="cardText">
                {attachment.originalFilename}
              </Text>
            </LinkChakra>
          </Flex>
        </Flex>
      )}
      {variant === 'list-item' && (
        <Flex
          flexDir="row"
          alignItems="center"
          textStyle="infoText"
          w="100%"
          // border="1px solid green"
        >
          {isCheckable && (
            <Checkbox mr={3} defaultChecked={isChecked} onChange={onChange} />
          )}

          <Flex
            justifyContent="space-between"
            w="100%"
            gap={2}
            alignItems="center"
          >
            <LinkChakra
              as="a"
              onClick={openAttachmentInNewTab}
              textStyle="infoTextLight"
              w="100%"
              _hover={{ textDecoration: 'none' }}
            >
              <Flex
                justifyContent="space-between"
                w="100%"
                gap={2}
                alignItems="center"
              >
                <Text
                  h={{ xxs: 5, tablet: 7 }}
                  wordBreak="break-all"
                  overflow="clip"
                  // border="1px solid orange"
                >
                  {attachment.originalFilename}
                </Text>

                <Flex
                  gap={2}
                  alignItems="center"
                  minW={16}
                  // border="1px solid orange"
                >
                  <Text textStyle="textSmallFixed">
                    {getDateStrFromNumber(attachment.createdAt)}
                  </Text>
                </Flex>
              </Flex>
            </LinkChakra>

            {link && (
              <Link to={link} onClick={onOpenNoteCard}>
                <ExternalLinkIcon mb={1} fontSize="14px" ml={0} />
              </Link>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
export default AttachmentsCardCompact
