import React, { ReactNode } from 'react'
import { Text } from '@chakra-ui/react'

const PromoHeading: React.FC<{
  children: ReactNode
  textStyle?: string
  color?: string
  px?: number
  py?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
}> = ({ children, color, px, py, mt, mr, mb, ml, textStyle = 'h5' }) => {
  return (
    <Text
      textStyle={textStyle}
      textColor={color}
      px={px}
      py={py}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
    >
      {children}
    </Text>
  )
}

export default PromoHeading
