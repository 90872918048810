import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Flex,
  Editable,
  EditablePreview,
  EditableInput,
} from '@chakra-ui/react'

import { Tag } from '@contracts/support'
import SortableHeading from '../ui/SortableHeading'

import { Heading, IconButton } from '../../ui'

import { TagSortingOption } from '../../common/enums'

import {
  deleteTag,
  updateTag,
  useArticlesInfoAllStates,
  useTags,
} from '../../apiClients/articlesApiClient'
import { calculateSortedItems } from './tagListUtils'
import TagCard from './TagCard'

const MAX_LENGTH_STRING = 50

const ListTags: React.FC = () => {
  const { t } = useTranslation()

  const { data: allTags, mutate } = useTags()

  const [sortType, setSortType] = useState(TagSortingOption.CREATED_DATE_DESC)
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const { data: allArticlesInfo } = useArticlesInfoAllStates()

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const deleteTagHandler = async (id: string) => {
    await deleteTag(id)
    void mutate()
  }

  const submitHandler = async (
    tag: Tag,
    newName: string | undefined,
    newCode: string | undefined,
  ) => {
    if (
      (newName && newName !== tag.name) ||
      (newCode && newCode !== tag.code)
    ) {
      await updateTag(tag.id, newName, newCode)
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > MAX_LENGTH_STRING) {
      e.currentTarget.value = _.truncate(e.currentTarget.value, {
        length: MAX_LENGTH_STRING,
        omission: '',
      })
    }
  }

  const getEditableName = (tag: Tag, name: string | undefined) => {
    return (
      <Box>
        <Editable
          defaultValue={name}
          onSubmit={newValue => {
            void submitHandler(tag, newValue, '')
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} />
          <EditableInput onChange={changeHandler} />
        </Editable>
      </Box>
    )
  }
  const getEditableCode = (tag: Tag, code: string | undefined) => {
    return (
      <Box>
        <Editable
          defaultValue={code}
          onSubmit={newValue => {
            void submitHandler(tag, '', newValue)
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} />
          <EditableInput onChange={changeHandler} />
        </Editable>
      </Box>
    )
  }

  const sortedTags = calculateSortedItems(sortType, allTags, searchStr) as Tag[]

  return (
    <Box pb={40} mt={12}>
      <Heading textStyle="h3">{t('tags.manageTags')}</Heading>

      <Flex
        flexDir="row"
        mt={6}
        mb={12}
        alignItems="center"
        flexWrap="wrap"
        w="100%"
      >
        <Box w="400px">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" />}
            />
            <Input
              type="text"
              placeholder={t('tags.searchTag')}
              width="30%"
              borderColor="gray.400"
              height={10}
              onChange={e => setSearchStr(e.target.value)}
              w="100%"
            />
          </InputGroup>
        </Box>
      </Flex>

      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <TableCaption>{t('tags.listingBerikarTags')}</TableCaption>
        <Thead key="thead">
          <Tr key="theadTr">
            <Th></Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.NAME_ASC)}
                onDescSort={() => setSortType(TagSortingOption.NAME_DESC)}
              >
                {t('tags.name')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.CODE_ASC)}
                onDescSort={() => setSortType(TagSortingOption.CODE_DESC)}
              >
                {t('tags.code')}
              </SortableHeading>
            </Th>

            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.CREATED_DATE_ASC)}
                onDescSort={() =>
                  setSortType(TagSortingOption.CREATED_DATE_DESC)
                }
              >
                {t('tags.createdAt')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.UPDATED_DATE_ASC)}
                onDescSort={() =>
                  setSortType(TagSortingOption.UPDATED_DATE_DESC)
                }
              >
                {t('tags.updatedAt')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading>{t('articles.id')}</SortableHeading>
            </Th>

            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {sortedTags && sortedTags.length > 0 ? (
            sortedTags.map((tag, i) => {
              return (
                <Fragment key={tag.id}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                  >
                    <Td
                      color="primary_orange02"
                      _hover={{ color: 'primary_orange01' }}
                    >
                      {!accordionsToShow.includes(tag.id) && (
                        <FaRegPlusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(tag.id)
                          }}
                        />
                      )}
                      {accordionsToShow.includes(tag.id) && (
                        <FaRegMinusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(tag.id)
                          }}
                        />
                      )}
                    </Td>

                    <Td>
                      <Tooltip
                        label={t('general.clickToEdit')}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                        placement="top-start"
                      >
                        <Box w={60}>{getEditableName(tag, tag.name)}</Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      <Tooltip
                        label={t('general.clickToEdit')}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                        placement="top-start"
                      >
                        <Box w={60}>{getEditableCode(tag, tag.code)}</Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      {new Date(tag.createdAt ?? 0).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      {new Date(tag.updatedAt ?? 0).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      <Tooltip
                        label={tag.id}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                      >
                        {_.truncate(tag.id, { length: 15 })}
                      </Tooltip>
                    </Td>
                    <Td>
                      <IconButton
                        data-cy="deleteButton"
                        buttonType="DELETE"
                        m={1}
                        onClick={() => {
                          if (
                            window.confirm(
                              t('tags.deleteTagQuestion', {
                                tagId: tag.id,
                                tagName: tag.name,
                              }),
                            )
                          ) {
                            void deleteTagHandler(tag.id)
                          }
                        }}
                        tooltipLabel={t('tag.deleteTooltip')}
                      />
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(tag.id) && (
                    <Tr
                      key={`${tag.id}-2`}
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={7}>
                        <TagCard
                          tag={tag}
                          allArticlesInfo={allArticlesInfo ?? []}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr key="none">
              <Td colSpan={9} textAlign="center">
                {t('articles.noArticlesFound')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListTags
