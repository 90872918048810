import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Button,
  Flex,
} from '@chakra-ui/react'

import { Heading } from '../../ui'
import {
  _FeatureFlagKeys,
  FEATURE_FLAG,
  setFeatureFlagOverride,
  useFeatureFlagActive,
  clearFeatureFlagOverrides,
} from '../../common/feature-flags'

const FeatureFlags = () => {
  const { t } = useTranslation()

  return (
    <Container minWidth="container.xl">
      <Helmet>
        <title>{t('featureFlags.headerFeatureFlagManagement')}</title>
      </Helmet>

      <Box pb={40}>
        <Heading textStyle="h3">
          {t('featureFlags.headerFeatureFlagManagement')}
        </Heading>

        <Button
          variant="outline"
          backgroundColor="white"
          borderColor="black"
          mt="5"
          mb="5"
          onClick={() => {
            clearFeatureFlagOverrides()
          }}
        >
          {t('featureFlags.clearFeatureFlagOverrides')}
        </Button>

        <Table variant="unstyled" colorScheme="gray" width="80%">
          <Thead>
            <Tr>
              <Th>{t('featureFlags.name')}</Th>
              <Th>{t('featureFlags.valueForEnvironment')}</Th>
              <Th>{t('featureFlags.valueForYou')}</Th>
              <Th>{t('featureFlags.override')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.keys(FEATURE_FLAG).map((key, i) => {
              const flag = FEATURE_FLAG[key as _FeatureFlagKeys]
              return (
                <Tr
                  key={flag}
                  bg={i % 2 === 0 ? 'white' : 'primary_grey01'}
                  data-cy="accountTag"
                >
                  <Td>{flag}</Td>
                  <Td>{useFeatureFlagActive(flag, true) ? '✅' : '❌'}</Td>
                  <Td>
                    {useFeatureFlagActive(flag, true) !==
                    useFeatureFlagActive(flag)
                      ? useFeatureFlagActive(flag)
                        ? '✅'
                        : '❌'
                      : null}
                  </Td>
                  <Td>
                    <Flex direction="column">
                      <Button
                        variant="outline"
                        backgroundColor="white"
                        borderColor="black"
                        onClick={() => {
                          setFeatureFlagOverride(flag, true)
                        }}
                        width="100px"
                        marginBottom="1rem"
                      >
                        {t('featureFlags.forceTrue')}
                      </Button>

                      <Button
                        variant="outline"
                        backgroundColor="white"
                        borderColor="black"
                        onClick={() => {
                          setFeatureFlagOverride(flag, false)
                        }}
                        width="100px"
                      >
                        {t('featureFlags.forceFalse')}
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
    </Container>
  )
}

export default FeatureFlags
