import {
  Box,
  Flex,
  Image,
  ResponsiveValue,
  Text,
  useToast,
} from '@chakra-ui/react'
import { UpdateProjectRequest } from '@contracts/projects'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { FaArchive } from 'react-icons/fa'
import { updateProject, useProject } from '../../apiClients/projectsApiClient'
import { useProperty } from '../../apiClients/propertiesApiClient'
import log from '../../common/log'
import { getProjectsPath } from '../../common/nav'
import { PROJECT_PROFILE_IMAGE_PREFIX } from '../../pages/constants'
import AccountAvatar from '../accounts/AccountAvatar'
import { useImageUrl } from '../attachments/attachmentHelper'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import AddressCard from '../properties/AddressCard'
import ImgPickerButton from '../ui/ImgPickerButton'
import projectFallBackProfileImg from '../../img/projects/fallbackProject.png'
import AuthContext from '../../common/auth-context'
import { Icon } from '../ui/Icon'

const ProjectFrontCard: React.FC<{
  projectId: string
  mt?: number
  mr?: ResponsiveValue<number | string>
  mb?: number
  ml?: number
  h?: number | string
  key?: number | string
  w?: number | string
  bg?: string
  color?: string
  variant?: 'compact' | 'micro' | 'full'
}> = ({
  projectId,
  mt,
  mr,
  mb,
  ml,
  h,
  w,
  variant = 'micro',
  bg = 'primary_grey02',
  color = 'black',
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const toast = useToast()
  const { data: project, mutate } = useProject(projectId)
  const imgUrl = useImageUrl(project?.imageAttachmentId)
  const { data: property } = useProperty(project?.propertyId)
  const { collaborators } = useCollaboratorParts({ projectId: projectId })
  const { currentUser } = useContext(AuthContext)

  let currentUserHasAccess = false
  if (
    currentUser &&
    collaborators?.find(collab => {
      return collab.accountId === currentUser.id
    })
  ) {
    currentUserHasAccess = true
  }

  const sortedOwners = collaborators
    ?.filter(collaborator => {
      return collaborator.client === true
    })
    .sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
  // Choose the oldest 'client'
  let chosenCollaborator = sortedOwners?.find(owner => {
    return owner.account?.accountTypes?.includes('Consumer')
  })
  if (!chosenCollaborator) {
    chosenCollaborator = sortedOwners?.length ? sortedOwners[0] : undefined
  }

  if (variant === 'compact') {
    h = 40
    w = 60
  } else if (variant === 'full') {
    h = '114px'
    w = '700px'
  } else {
    h = '160px'
    w = '160px'
  }
  const updateProjectImgHandler = (
    inputProp: string | undefined,
    inputValue: string,
  ) => {
    if (!project) {
      return null
    }
    const updateRequest: UpdateProjectRequest = {
      ...project,
      projectCategoryIds: project.projectCategories
        ? project.projectCategories.map(pc => {
            return pc.id
          })
        : [],
      imageAttachmentId: inputValue,
    }
    updateProject(project.id, updateRequest)
      .then(() => {
        project.imageAttachmentId = inputValue
        void mutate()
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update Project img for project')
        toast({
          title: t('projects.updateImageFailureMessage', {
            name: project.name,
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }
  if (!currentUserHasAccess) {
    return <Flex mx={4}>{t('projectPlaces.noAccessRights')}</Flex>
  }

  if (variant === 'full') {
    return (
      <Flex
        key={project?.id}
        pos="relative"
        bg={bg}
        mt={mt}
        mr={mr}
        mb={mb}
        ml={ml}
        w={w}
        maxW={w}
        borderRadius="lg"
        alignItems="center"
        onClick={() => {
          if (project && currentUserHasAccess) {
            navigate(`${getProjectsPath(pathname)}/${project.id}`)
          }
        }}
        _hover={{
          boxShadow: '2xl',
          transform: 'translate(-1px,-1px)',
          cursor: currentUserHasAccess ? 'pointer' : 'not-allowed',
        }}
        boxShadow="lg"
        bgPos="center"
        bgBlendMode="overlay"
        bgSize="cover"
        pl={2}
        color={color}
        wrap="wrap"
      >
        <Flex wrap="nowrap" alignItems="center" h={h}>
          <Image
            src={imgUrl}
            // fallbackSrc="https://via.placeholder.com/100"
            fallbackSrc={projectFallBackProfileImg}
            borderRadius="lg"
            w="100px"
            h="100px"
          />
          <Flex flexDir="column" mx={4} w={52} overflow="clip">
            <Text
              fontSize="18px"
              fontWeight="normal"
              // color="black"
              overflow="clip"
              h={8}
            >
              {project?.name}
            </Text>
            <AddressCard
              address={property?.address}
              noHeader
              variant="2-rows"
              color={color}
            />
          </Flex>
        </Flex>
        <Flex wrap="nowrap" alignItems="center" h={h}>
          <Flex flexDir="column" ml={4}>
            <AccountAvatar
              accountId={chosenCollaborator ? chosenCollaborator.accountId : ''}
              variant="full"
              w="290px"
              color={color}
            />
          </Flex>
        </Flex>
        {project?.status === 'Archived' && (
          <Box pos="absolute" right={3} top={3}>
            <FaArchive color="#B86890" size={24} />
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      key={project?.id}
      pos="relative"
      color={color}
      bg="primary_grey02"
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      h={h}
      w={w}
      maxW={w}
      borderRadius="lg"
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      onClick={() => {
        if (project && currentUserHasAccess) {
          navigate(`${getProjectsPath(pathname)}/${project.id}`)
        }
      }}
      _hover={{
        boxShadow: '2xl',
        transform: 'translate(-1px,-1px)',
        cursor: 'pointer',
      }}
      boxShadow="lg"
      bgImage={imgUrl}
      bgPos="center"
      bgBlendMode="overlay"
      bgSize="cover"
    >
      <Text textStyle="h4" color="black">
        {project?.name}
      </Text>
      {!imgUrl && (
        <Box pos="absolute" bottom={2} right={2}>
          <ImgPickerButton
            pos="absolute"
            imgChangeHandler={updateProjectImgHandler}
            iconColor="gray.500"
            variant={'GHOST-ICON'}
            ContextArea="Project"
            attachmentPrefix={PROJECT_PROFILE_IMAGE_PREFIX}
          />
        </Box>
      )}
      {project?.status === 'Archived' && (
        <Box pos="absolute" right={3} top={3}>
          <FaArchive color="#B86890" size={24} />
        </Box>
      )}
      {project?.status === 'Active' && (
        <Box pos="absolute" right={3} top={3}>
          <Icon name="activeProject" />
        </Box>
      )}
      {project?.status === 'Active' && (
        <Box pos="absolute" right={3} top={3}>
          <Icon name="activeProject" />
        </Box>
      )}
    </Flex>
  )
}
export default ProjectFrontCard
