import {
  Box,
  Button,
  Flex,
  ResponsiveValue,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { UpdateAccountRequest } from '@contracts/accounts'
import { AccountData } from '@contracts/misc'

import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'

import AuthContext from '../../common/auth-context'
import {
  updateAccount,
  sendVerifyEmail,
} from '../../apiClients/accountsApiClient'
import log from '../../common/log'
import Spinner from '../Spinner'

interface MyFormValues {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

interface Errors {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
}

const EditProfile: React.FC<{
  px?: ResponsiveValue<number | string>
  mt?: number | string
  maxW?: number | string
}> = ({ px, mt, maxW }) => {
  const ctxAuth = useContext(AuthContext)
  const { currentUser } = useContext(AuthContext)
  const { t } = useTranslation()
  const toast = useToast()

  if (!currentUser) {
    return null
  }

  const initialValues: MyFormValues = {
    firstName: currentUser?.firstName ?? '',
    lastName: currentUser?.lastName ?? '',
    email: currentUser?.email ?? '',
    phoneNumber: currentUser?.phoneNumber ?? '',
  }
  const validate = (values: MyFormValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.firstName,
      t('accounts.accountForm.firstName'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.firstName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.lastName,
      t('accounts.accountForm.lastName'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.lastName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.email,
      t('accounts.accountForm.email'),
      2,
      60,
      true,
    )
    if (!tmpRes.isValid) {
      errors.email = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.phoneNumber,
      t('accounts.accountForm.phoneNumber'),
      2,
      30,
      false,
    )
    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const rawValue = values.phoneNumber.match(/(\d|\+)+/g)?.join('')

      const requestData: UpdateAccountRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: rawValue,
      }
      updateAccount(currentUser.id, requestData)
        .then(() => {
          log.debug('Successfully updated account')

          const userData: AccountData = {
            id: currentUser.id,
            firstName: requestData.firstName ?? currentUser.firstName,
            lastName: requestData.lastName ?? currentUser.lastName,
            email: requestData.email ?? currentUser.email,
            status: currentUser.status,
            phoneNumber: requestData.phoneNumber ?? currentUser.phoneNumber,
            profileAttachmentId: currentUser.profileAttachmentId,
            backgroundAttachmentId: currentUser.backgroundAttachmentId,
            createdAt: currentUser.createdAt,
            updatedAt: new Date().getTime(),
            accountTypes: currentUser.accountTypes,
          }
          ctxAuth.setCurrentUser(userData)
          toast({
            title: t('accounts.accountForm.profileUpdatedSuccessMessage'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to update account')
          toast({
            title: t('accounts.accountForm.profileUpdatedFailMessage'),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const verifyEmail = () => {
    sendVerifyEmail()
      .then(() => {
        toast({
          title: t('accounts.verifyEmailSentMessage', {
            emailToVerify: currentUser.email,
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(() => {
        toast({
          title: t('accounts.verifyEmailFailedMessage'),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  return (
    <form onSubmit={handleSubmit} data-cy="edit-profile">
      <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
        {/* <Flex display="flex" flexDir="column" maxW="md"> */}
        <Input
          title={t('accounts.accountForm.firstName')}
          name="firstName"
          type="text"
          isMandatory={true}
          isError={!!errors.firstName}
          errorText={
            touched.firstName && errors.firstName ? errors.firstName : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={currentUser?.firstName}
          mb={4}
        />
        <Input
          title={t('accounts.accountForm.lastName')}
          name="lastName"
          type="text"
          isMandatory={true}
          isError={!!errors.lastName}
          errorText={touched.lastName && errors.lastName ? errors.lastName : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={currentUser?.lastName}
          mb={4}
        />
        <Flex flexDir="column">
          <Input
            title={t('accounts.accountForm.email')}
            name="email"
            type="text"
            isMandatory={true}
            isError={!!errors.email}
            errorText={touched.email && errors.email ? errors.email : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="plain"
            defaultValue={currentUser?.email}
            mb={4}
            isVerified={currentUser.status === 'Verified' ? true : false}
            isNotVerified={currentUser.status !== 'Verified' ? true : false}
          />
          {currentUser.status === 'New' && (
            <Box textAlign="right">
              <Button
                onClick={verifyEmail}
                variant="secondary"
                w={32}
                size="sm"
              >
                {t('accounts.verifyEmail')}
              </Button>
            </Box>
          )}
        </Flex>

        <Input
          title={t('accounts.accountForm.phoneNumber')}
          name="phoneNumber"
          type="phoneNumber"
          isMandatory={false}
          isError={!!errors.phoneNumber}
          errorText={
            touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={currentUser?.phoneNumber}
        />
        <Text ml={4} textStyle="infoSmall">
          *{t('input.mandatoryFields')}
        </Text>

        <Button
          type="submit"
          my={4}
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
        >
          {t('auth.update')}
        </Button>
      </Flex>
      {isLoading && <Spinner />}
    </form>
  )
}
export default EditProfile
