import { Box, Container, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useArticle } from '../../apiClients/articlesApiClient'
import ArticleTemplate1 from '../articleTemplates/ArticleTemplate1'
import Tiptap from '../richTextEditor/Tiptap'
import ImagesCard from '../ui/ImagesCard'
import ArticleInfoCard from './ArticleInfoCard'
import AuthorCard from './AuthorCard'
import ManageArticlePanel from './ManageArticlePanel'

const ArticleCard: React.FC<{
  articleId: string
}> = ({ articleId }) => {
  const { data: article } = useArticle(articleId, true)
  const [showPreview, setShowpPreview] = useState(false)
  const { t } = useTranslation()

  const togglePreviewHandler = () => {
    setShowpPreview(!showPreview)
  }
  if (!article) {
    return null
  }

  return (
    <Flex flexDir="column">
      <Flex flexDir="row" w="80vw" alignItems="flex-start" flexWrap="wrap">
        <ArticleInfoCard article={article} variant="SMALL" />
        <ImagesCard
          label={t('articles.images')}
          w={96}
          ml={6}
          images={[
            { id: article.attachmentIdSmall ?? undefined, name: 'SM' },
            { id: article.attachmentIdMedium ?? undefined, name: 'MD' },
            { id: article.attachmentIdLarge ?? undefined, name: 'LG' },
          ]}
        />
        <AuthorCard author={article.author} />
        <ManageArticlePanel
          article={article}
          onTogglePreview={togglePreviewHandler}
        />
      </Flex>
      <Flex flexDir="column">
        <Box mt={4}>
          <Tiptap
            content={article.intro}
            w="100%"
            editable={false}
            limit={200}
            editorLabel={t('articles.ingressText')}
            border="1px"
            borderColor="gray.400"
            area="Article"
            areaId={articleId}
            container={'public'}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onImgAdded={() => {}}
          />
        </Box>
        <Box mt={8}>
          <Tiptap
            content={article.content}
            w="100%"
            editable={false}
            editorLabel={t('articles.bodyText')}
            border="1px"
            borderColor="gray.400"
            area="Article"
            areaId={articleId}
            container={'public'}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onImgAdded={() => {}}
          />
        </Box>
      </Flex>
      {showPreview && (
        <Container border="1px solid gray" maxW="container.xl" mt={16}>
          {article && <ArticleTemplate1 articleId={article.id.toString()} />}
        </Container>
      )}
    </Flex>
  )
}
export default ArticleCard
