import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { Organization } from '@contracts/misc'
import {
  useOrganizationProfile,
  useOrganizationProfileAdmin,
} from '../../apiClients/organizationsApiClient'
import HtmlPane from '../richTextEditor/HtmlPane'

import AuthContext from '../../common/auth-context'

// import Carousel from '../ui/Carousel'
import ContactProviderSection from './ContactProviderSection'
import ProviderPartnersSection from './ProviderPartnersSection'
import ProviderEndorseSection from './ProviderEndorseSection'
import ProviderProfileAboutUsHeading from './ProviderProfileAboutUsHeader'
import InstaPostsSection from './InstaPostsSection'
import ProviderProfileHeader from './ProviderProfileHeader'

const ProviderProfileTemplate: React.FC<{
  organization: Organization
  w?: string
  minW?: string
  maxW?: string
  transform?: string
  border?: string
  borderColor?: string
  bg?: string
  mt?: string | number
  mb?: string | number
  onClose?: () => void
}> = ({
  organization,
  w = '100%',
  minW,
  maxW,
  transform,
  border,
  borderColor,
  bg,
  mt,
  mb = 28,
  onClose,
}) => {
  const { isAdmin } = useContext(AuthContext)

  const { data: organizationProfileNotAdmin } = useOrganizationProfile(
    isAdmin ? undefined : organization.id,
  )
  const { data: organizationProfileAdmin } = useOrganizationProfileAdmin(
    isAdmin ? organization.id : undefined,
  )

  const organizationProfile = isAdmin
    ? organizationProfileAdmin
    : organizationProfileNotAdmin

  const { t } = useTranslation()

  if (!organizationProfile) {
    return null
  }

  return (
    <Flex
      width="100%"
      position="relative"
      flexDir="column"
      w={w}
      minW={minW}
      maxW={maxW}
      transform={transform}
      border={border}
      borderColor={borderColor}
      bg={bg}
      mt={mt}
      mb={mb}
    >
      <ProviderProfileHeader organization={organization} onClose={onClose} />
      <Flex
        flexDir="column"
        maxW="container.xl"
        pt={10}
        mx={{ xxs: 2, tablet: '5%' }}
      >
        <Tabs variant="line" m={0} p={0}>
          <TabList justifyContent="space-around">
            <Tab px={{ xxs: '10%', laptop: '16%' }}>
              {t('providers.profile.aboutUs')}
            </Tab>
            <Tab px={{ xxs: '10%', laptop: '16%' }}>
              {t('providers.profile.contactUs')}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel p={0} mt={10} mb={4}>
              <>
                <ProviderProfileAboutUsHeading organization={organization} />

                <Box textStyle="infoTextLight" mt={8}>
                  <HtmlPane
                    content={organizationProfile.profileText}
                    px={0}
                    bg="transparent"
                  />
                </Box>
                <InstaPostsSection organizationId={organization.id} />
              </>
            </TabPanel>
            <TabPanel mb={4} mx={0} px={0}>
              <ContactProviderSection organizationId={organization.id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <ProviderPartnersSection mt={14} organizationId={organization.id} />
      <ProviderEndorseSection
        organizationId={organization.id}
        px={{ xxs: 2, tablet: '5%', laptop: '5%' }}
      />
    </Flex>
  )
}
export default ProviderProfileTemplate
