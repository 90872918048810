/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Flex, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  CreateProjectPlaceRequest,
  ProjectPlaceResponse,
} from '@contracts/projects'
import { useContext } from 'react'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import AuthContext from '../../common/auth-context'
import {
  updateProjectPlace,
  useProjectPlace,
} from '../../apiClients/projectsApiClient/projectPlaces'

interface EditProjectPlaceFormValues {
  projectName: string
  street: string
  zip: string
  city: string
}
interface AddProjectPlaceErrors {
  projectName?: string
  street?: string
  zip?: string
  city?: string
}

const EditProjectPlaceProjectInfo: React.FC<{
  projectPlace: ProjectPlaceResponse
}> = ({ projectPlace }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { currentOrganization } = useContext(AuthContext)
  const { mutate: mutateProjectPlace } = useProjectPlace(projectPlace.id)
  const toast = useToast()

  if (!currentOrganization) {
    return null
  }

  const initialValues: EditProjectPlaceFormValues = {
    projectName: projectPlace.name,
    street: projectPlace.street,
    zip: projectPlace.zipCode,
    city: projectPlace.city,
  }

  const validate = (values: EditProjectPlaceFormValues) => {
    const errors: AddProjectPlaceErrors = {}

    let tmpRes = validateString(
      values.projectName,
      t('general.projectName').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.projectName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.street,
      t('general.address.street').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.street = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.zip,
      t('general.address.zipCode').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.zip = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.city,
      t('general.address.city').toLowerCase(),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.city = tmpRes.errorMsg
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    // values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const updateProjectPlaceRequest: CreateProjectPlaceRequest = {
        organizationId: currentOrganization?.id,
        name: values.projectName,
        street: values.street,
        zipCode: values.zip,
        city: values.city,
        status: projectPlace.status,
        phase: projectPlace.phase,
        propertyTypeId: projectPlace.propertyTypeId,

        customerName: projectPlace.customerName,
        projectName: values.projectName,
      }

      updateProjectPlace(projectPlace.id, updateProjectPlaceRequest)
        .then(({ dataValues: res }) => {
          // void mutate()
          applicationInsights.trackEvent(
            { name: 'Update project place' },
            { projectName: res.name },
          )

          toast({
            title: t('projectPlaces.updateSuccessMessage', {
              name: res.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          void mutateProjectPlace()
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to update project place, ${values.projectName} `,
          )
          toast({
            title: t('projectPlaces.updateErrorMessage', {
              name: values.projectName,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form onSubmit={handleSubmit} data-cy="update-projectPlace">
        <Flex flexDir="column" gap={3} maxW="xl">
          <Input
            title={t('general.projectName')}
            name="projectName"
            defaultValue={initialValues.projectName}
            type="text"
            isMandatory={true}
            isError={!!errors.projectName}
            errorText={
              touched.projectName && errors.projectName
                ? errors.projectName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
            variant="plain"
          />
          <Input
            title={t('general.address.street')}
            name="street"
            defaultValue={initialValues.street}
            type="text"
            isMandatory={true}
            isError={!!errors.street}
            errorText={touched.street && errors.street ? errors.street : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
            variant="plain"
          />
          <Input
            title={t('general.address.zipCode')}
            name="zip"
            defaultValue={initialValues.zip}
            type="text"
            isMandatory={true}
            isError={!!errors.zip}
            errorText={touched.zip && errors.zip ? errors.zip : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
            variant="plain"
          />
          <Input
            title={t('general.address.city')}
            name="city"
            defaultValue={initialValues.city}
            type="text"
            isMandatory={true}
            isError={!!errors.city}
            errorText={touched.city && errors.city ? errors.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="bottom"
            variant="plain"
          />

          <Text ml="1rem" textStyle="infoSmall">
            *{t('input.mandatoryFields')}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            my={4}
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
            w={{ xxs: '100%', laptop: 32 }}
          >
            {t('ui.button.save')}
          </Button>
        </Flex>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default EditProjectPlaceProjectInfo
