import { Box, Text } from '@chakra-ui/react'
import { ClimateResource } from '@contracts/support'

const ListItem: React.FC<{
  product: ClimateResource
  onClick: (product: ClimateResource) => void
}> = ({ product, onClick }) => {
  return (
    <Box
      pl={2}
      py={1}
      _hover={{ bg: 'gray.300', cursor: 'default' }}
      borderRadius="base"
      onClick={() => {
        onClick(product)
      }}
    >
      <Text>{product.name}</Text>
    </Box>
  )
}
export default ListItem
