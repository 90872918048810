import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  FaSearch,
  FaRegPlusSquare,
  FaRegMinusSquare,
  FaRegFilePdf,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePowerpoint,
  FaRegFileWord,
  FaRegFile,
} from 'react-icons/fa'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Flex,
  RadioGroup,
  Radio,
  Editable,
  EditablePreview,
  EditableInput,
  Text,
  ResponsiveValue,
} from '@chakra-ui/react'

import SortableHeading from '../ui/SortableHeading'
import {
  updateFilename,
  useAttachments,
} from '../../apiClients/attachmentsApiClient'
import { Heading } from '../../ui'

import { AttachmentSortingOption } from '../../common/enums'
import { getMbFromBytes } from '../../common/util'
import AddAttachmentButton from '../ui/AddAttachmentButton'
import AttachmentCard from './AttachmentCard'
import ManageAttachmentPanel from './ManageAttachmentPanel'
import { calculateSortedItems } from './attachmentListUtils'
import { getSumOfFiles } from './attachmentHelper'
import ListAttachmentsPrefixes from './ListAttachmentsPrefixes'

const MAX_LENGTH_FILENAME = 255

const ListAttachments: React.FC<{ mx?: ResponsiveValue<string | number> }> = ({
  mx = 0,
}) => {
  const { t } = useTranslation()

  const { data: allArticleAttachments, mutate: mutateArticleFilteredArray } =
    useAttachments(['Article'])

  const { data: allAccountAttachments, mutate: mutateAccountFilteredArray } =
    useAttachments(['Account'])

  const { data: allPropertyAttachments, mutate: mutatePropertyFilteredArray } =
    useAttachments(['Property'])
  const { data: allProjectAttachments, mutate: mutateProjectFilteredArray } =
    useAttachments(['Project'])
  const { data: allNoteAttachments, mutate: mutateNoteFilteredArray } =
    useAttachments([
      'PropertyNote',
      'ProjectNote.description',
      'ProjectNote',
      'PropertyNote.description',
    ])
  const [sortType, setSortType] = useState(
    AttachmentSortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const [areaFilter, setAreaFilter] = useState('Article')
  const areas = ['Article', 'Account', 'Property', 'Project', 'Note']

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const getSumOfFileSizes = (areaFilter: string) => {
    if (areaFilter === 'Article') {
      return getSumOfFiles(allArticleAttachments ?? [])
    }
    if (areaFilter === 'Account') {
      return getSumOfFiles(allAccountAttachments ?? [])
    }
    if (areaFilter === 'Property') {
      return getSumOfFiles(allPropertyAttachments ?? [])
    }
    if (areaFilter === 'Project') {
      return getSumOfFiles(allProjectAttachments ?? [])
    }
    if (areaFilter === 'Note') {
      return getSumOfFiles(allNoteAttachments ?? [])
    }
    return ' - '
  }

  const getNrOfFilesForCurrentArea = () => {
    if (areaFilter === 'Article') {
      return allArticleAttachments?.length
    }
    if (areaFilter === 'Account') {
      return allAccountAttachments?.length
    }
    if (areaFilter === 'Property') {
      return allPropertyAttachments?.length
    }
    if (areaFilter === 'Project') {
      return allProjectAttachments?.length
    }
    if (areaFilter === 'Note') {
      return allNoteAttachments?.length
    }
  }

  const getCurrentFilteredArray = () => {
    if (areaFilter === 'Article') {
      return allArticleAttachments
    }
    if (areaFilter === 'Account') {
      return allAccountAttachments
    }
    if (areaFilter === 'Property') {
      return allPropertyAttachments
    }
    if (areaFilter === 'Project') {
      return allProjectAttachments
    }
    if (areaFilter === 'Note') {
      return allNoteAttachments
    }
  }
  const onMutateHandler = () => {
    if (areaFilter === 'Article') {
      void mutateArticleFilteredArray()
    }
    if (areaFilter === 'Account') {
      void mutateAccountFilteredArray()
    }
    if (areaFilter === 'Property') {
      void mutatePropertyFilteredArray()
    }
    if (areaFilter === 'Project') {
      void mutateProjectFilteredArray()
    }
    if (areaFilter === 'Note') {
      void mutateNoteFilteredArray()
    }
  }
  const submitHandler = async (newValue: string, id: string) => {
    await updateFilename(id, newValue)
    void onMutateHandler()
  }

  const fileNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > MAX_LENGTH_FILENAME) {
      e.currentTarget.value = _.truncate(e.currentTarget.value, {
        length: MAX_LENGTH_FILENAME,
        omission: '',
      })
    }
  }

  const getEditable = (id: string, fileName: string) => {
    return (
      <Box>
        <Editable
          defaultValue={fileName}
          w={72}
          onSubmit={newValue => {
            void submitHandler(newValue, id)
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} w={72} />
          <EditableInput onChange={fileNameChangeHandler} />
        </Editable>
      </Box>
    )
  }

  const sortedAttachments = calculateSortedItems(
    sortType,
    getCurrentFilteredArray(),
    searchStr,
  )

  const getFileTypeLogo = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return (
        <Box fontSize={24} pl={4} color="green.400">
          <FaRegFileImage />
        </Box>
      )
    }
    if (mimeType.includes('pdf')) {
      return (
        <Box fontSize={24} pl={4} color="red.600">
          <FaRegFilePdf />
        </Box>
      )
    }
    if (mimeType.includes('sheet' || 'xls')) {
      return (
        <Box fontSize={24} pl={4} color="green.600">
          <FaRegFileExcel />
        </Box>
      )
    }
    if (mimeType.includes('word')) {
      return (
        <Box fontSize={24} pl={4} color="blue.700">
          <FaRegFileWord />
        </Box>
      )
    }
    if (mimeType.includes('powerpoint')) {
      return (
        <Box fontSize={24} pl={4} color="red.700">
          <FaRegFilePowerpoint />
        </Box>
      )
    }

    return (
      <Box fontSize={24} pl={4} color="gray.600">
        <FaRegFile />
      </Box>
    )
  }

  return (
    <>
      <Box pb={40} mt={12} mx={mx}>
        <Heading textStyle="h3">{t('attachments.manageAttachments')}</Heading>

        <Flex
          flexDir="row"
          mt={6}
          mb={12}
          alignItems="center"
          flexWrap="wrap"
          w="100%"
        >
          <Box w="400px" zIndex="base">
            <InputGroup zIndex="base">
              <InputLeftElement
                pointerEvents="none"
                children={<FaSearch color="gray.200" />}
              />
              <Input
                type="text"
                placeholder={t('attachments.searchAttachment')}
                width="30%"
                borderColor="gray.400"
                height={10}
                onChange={e => setSearchStr(e.target.value)}
                w="100%"
              />
            </InputGroup>
            <Text ml={4} textStyle="textMedium">
              {t('attachments.searchInfo', {
                total: getNrOfFilesForCurrentArea(),
                filtered: sortedAttachments.length ?? 0,
              })}
            </Text>
          </Box>

          <Flex flexDir="column" ml={12}>
            <RadioGroup defaultValue="Article">
              {areas.map(area => {
                return (
                  <Radio
                    key={area}
                    value={area}
                    mr={6}
                    onChange={() => {
                      setAreaFilter(area)
                    }}
                  >
                    <Box borderLeft="3px solid" borderColor="gray.400" pl={2}>
                      {area}
                      <Text fontSize="xs" fontWeight="light">
                        {`${getSumOfFileSizes(area)} MB`}
                      </Text>
                    </Box>
                  </Radio>
                )
              })}
            </RadioGroup>
          </Flex>
        </Flex>

        <AddAttachmentButton w={44}>
          {t('attachments.addAttachment')}
        </AddAttachmentButton>

        <Table
          variant="unstyled"
          colorScheme="gray"
          data-cy="accounts-list"
          mt={10}
        >
          <TableCaption>
            {t('attachments.listingBerikarAttachments')}
          </TableCaption>
          <Thead key="thead">
            <Tr key="theadTr">
              <Th></Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.AREA_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.AREA_DESC)
                  }
                >
                  {t('attachments.areaName')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.MIME_TYPE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.MIME_TYPE_DESC)
                  }
                >
                  {t('attachments.fileType')}
                </SortableHeading>
              </Th>

              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.FILE_NAME_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.FILE_NAME_DESC)
                  }
                >
                  {t('attachments.fileName')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.FILE_SIZE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.FILE_SIZE_DESC)
                  }
                >
                  {t('attachments.fileSize')}
                </SortableHeading>
              </Th>

              <Th>
                <SortableHeading>{t('articles.id')}</SortableHeading>
              </Th>
              <Th>
                <SortableHeading>{t('accounts.account')}</SortableHeading>
              </Th>

              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.CREATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.CREATED_DATE_DESC)
                  }
                >
                  {t('articles.created')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(AttachmentSortingOption.UPDATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(AttachmentSortingOption.UPDATED_DATE_DESC)
                  }
                >
                  {t('articles.updated')}
                </SortableHeading>
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {sortedAttachments && sortedAttachments.length > 0 ? (
              sortedAttachments.map((attachment, i) => {
                return (
                  <Fragment key={attachment.id}>
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      data-cy="accountTag"
                    >
                      <Td
                        color="primary_orange02"
                        _hover={{ color: 'primary_orange01' }}
                      >
                        {!accordionsToShow.includes(attachment.id) && (
                          <FaRegPlusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(attachment.id)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(attachment.id) && (
                          <FaRegMinusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(attachment.id)
                            }}
                          />
                        )}
                      </Td>
                      <Td>{attachment.area}</Td>
                      <Td>
                        <Tooltip
                          label={attachment.mimeType}
                          placement="top-start"
                        >
                          {getFileTypeLogo(attachment.mimeType)}
                        </Tooltip>
                      </Td>
                      <Td>
                        <Tooltip
                          label={t('general.clickToEdit')}
                          hasArrow
                          arrowSize={15}
                          closeDelay={500}
                          placement="top-start"
                        >
                          <Box w={64}>
                            {getEditable(
                              attachment.id,
                              attachment.originalFilename,
                            )}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td>
                        {attachment?.fileSizeBytes
                          ? `${getMbFromBytes(attachment.fileSizeBytes)} MB`
                          : ''}{' '}
                      </Td>
                      <Td>
                        <Tooltip
                          label={attachment.id}
                          hasArrow
                          arrowSize={15}
                          closeDelay={500}
                        >
                          {_.truncate(attachment.id, { length: 15 })}
                        </Tooltip>
                      </Td>
                      <Td>
                        <Tooltip
                          label={attachment.id}
                          hasArrow
                          arrowSize={15}
                          closeDelay={500}
                        >
                          {_.truncate(attachment.accountId, { length: 15 })}
                        </Tooltip>
                      </Td>

                      <Td>
                        {new Date(attachment.createdAt).toLocaleString('sv-SE')}
                      </Td>
                      <Td>
                        {new Date(attachment.updatedAt).toLocaleString('sv-SE')}
                      </Td>
                      <Td>
                        <ManageAttachmentPanel
                          attachment={attachment}
                          onMutate={onMutateHandler}
                        />
                      </Td>
                    </Tr>
                    {accordionsToShow.includes(attachment.id) && (
                      <Tr
                        key={`${attachment.id}-2`}
                        bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                        borderTopWidth={1}
                      >
                        <Td colSpan={10}>
                          <AttachmentCard
                            attachment={attachment}
                            isPrivate={false}
                          />
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <Tr key="none">
                <Td colSpan={9} textAlign="center">
                  {t('articles.noArticlesFound')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <ListAttachmentsPrefixes mx="5%" />
    </>
  )
}
export default ListAttachments
