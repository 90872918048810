import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Image,
  Checkbox,
  Link as LinkChakra,
  ResponsiveValue,
  useDisclosure,
  Box,
  Show,
} from '@chakra-ui/react'
import { AttachmentSummary } from '@contracts/misc'
import { useNavigate } from 'react-router-dom'
import { Attachment } from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { MdHistory } from 'react-icons/md'
import { AiOutlineFile } from 'react-icons/ai'
import {
  PATH_MY_PRIVATE_PROJECTS,
  PATH_MY_PRIVATE_PROPERTIES,
  PATH_NOTE_QUERY,
} from '../../common/nav'
import {
  getDateStrFromNumber,
  getUserFriendlyDateStrFromNumber,
} from '../../common/util'
import Link from '../ui/Link'
import Modal from '../ui/Modal'
import Heading from '../ui/Heading'
import { downloadAttachmentUrl } from './attachmentHelper'
import FileIcon from './FileIcon'

const AttachmentCardCompactVersioned: React.FC<{
  attachments: Attachment[]
  minW?: number | string
  maxW?: number | string
  isCheckable?: boolean
  isChecked?: boolean
  onFileChecked?: (attachment: AttachmentSummary) => void
  variant?: 'list-item' | 'normal' | 'thumbnail'
  link?: string
  onOpenNoteCard?: () => void
  thumbnailSize?: ResponsiveValue<number | string>
}> = ({
  attachments,
  minW = '52',
  maxW, // = 'md',
  isCheckable = false,
  isChecked = false,
  onFileChecked,
  variant = 'normal',
  link,
  onOpenNoteCard,
  thumbnailSize = 14,
}) => {
  // eslint-disable-next-line no-console
  // console.log('attachments: ', attachments)
  // eslint-disable-next-line no-console
  // console.log('first: ', attachments[0].id)
  // eslint-disable-next-line no-console
  // console.log('last: ', attachments[attachments.length - 1].id)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    isOpen: isInfoModalOpen,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()

  if (!attachments) {
    return null
  }

  const openAttachmentInNewTab = (attachment: Attachment) => {
    window.open(
      downloadAttachmentUrl(attachment.id, attachment.originalFilename),
      '_blank',
    )
  }

  const getAreaLink = () => {
    if (
      attachments[0].area.startsWith('PropertyNote') &&
      attachments[0].parentAreaId &&
      attachments[0].areaId
    ) {
      // Path to property note
      return `${PATH_MY_PRIVATE_PROPERTIES}/${attachments[0].parentAreaId}${PATH_NOTE_QUERY}${attachments[0].areaId}`
    }
    if (
      attachments[0].area === 'Message' &&
      attachments[0].parentArea === 'PropertyNote' &&
      attachments[0].grandParentAreaId &&
      attachments[0].parentAreaId
    ) {
      // Path to property note
      return `${PATH_MY_PRIVATE_PROPERTIES}/${attachments[0].grandParentAreaId}${PATH_NOTE_QUERY}${attachments[0].parentAreaId}`
    }
    if (
      attachments[0].area.startsWith('ProjectNote') &&
      attachments[0].parentAreaId &&
      attachments[0].areaId
    ) {
      // Path to project note
      return `${PATH_MY_PRIVATE_PROJECTS}/${attachments[0].parentAreaId}${PATH_NOTE_QUERY}${attachments[0].areaId}`
    }
    if (
      attachments[0].area === 'Message' &&
      attachments[0].parentArea === 'ProjectNote' &&
      attachments[0].grandParentAreaId &&
      attachments[0].parentAreaId
    ) {
      // Path to project note
      return `${PATH_MY_PRIVATE_PROJECTS}/${attachments[0].grandParentAreaId}${PATH_NOTE_QUERY}${attachments[0].parentAreaId}`
    }
    return ''
  }

  const onChange = () => {
    onFileChecked ? void onFileChecked(attachments[0]) : ''
  }

  if (variant === 'thumbnail') {
    if (!attachments[attachments.length - 1].areaId) {
      return null
    }
    return (
      <Flex
        flexDir="column"
        pos="relative"
        // border="1px solid"
        borderRadius="lg"
        borderColor="gray.200"
        _hover={{ borderColor: 'gray.400' }}
      >
        <LinkChakra
          as="a"
          onClick={() => {
            openAttachmentInNewTab(attachments[attachments.length - 1])
            // openAttachmentInNewTab(attachments[0])
          }}
        >
          <Image
            src={downloadAttachmentUrl(
              attachments[attachments.length - 1].id,
              attachments[attachments.length - 1].originalFilename,
            )}
            boxSize={thumbnailSize}
            fit="cover"
          />
        </LinkChakra>

        {attachments[0].areaId && (
          <ExternalLinkIcon
            pos="absolute"
            right={0}
            bottom={0}
            // m={1}
            p={1}
            m={1}
            boxSize={6}
            onClick={() => {
              navigate(getAreaLink())
            }}
            _hover={{ cursor: 'pointer', color: 'gray.400' }}
            bg="gray.200"
            opacity={0.6}
            borderRadius="md"
          />
        )}
      </Flex>
    )
  }
  return (
    <>
      <Flex
        flexDir="row"
        alignItems=""
        justifyContent="space-between"
        minW={minW}
        maxW={maxW}
        // w="100%"
        _hover={{ color: 'gray.500', cursor: 'pointer' }}
        // border="1px solid red"
      >
        {variant === 'normal' && (
          <Flex
            flexDir="row"
            alignItems="center"
            w="100%"
            // border="1px solid red"
            justifyContent="space-between"
          >
            {isCheckable && (
              <Checkbox mr={3} defaultChecked={isChecked} onChange={onChange} />
            )}
            <Flex
              onClick={() => {
                // openAttachmentInNewTab(attachments[0])
                openAttachmentInNewTab(attachments[attachments.length - 1])
              }}
              alignItems="center"
            >
              {/* <Flex w={16}>{getFileIcon()}</Flex> */}

              <FileIcon
                attachment={attachments[attachments.length - 1]}
                isMultipleFiles
              />
              <LinkChakra
                as="a"
                onClick={() => {
                  openAttachmentInNewTab(attachments[attachments.length - 1])
                  // openAttachmentInNewTab(attachments[0])
                }}
              >
                <Flex
                  gap={2}
                  // alignItems="flex-end"
                  // border="1px solid red"
                  h={7}
                  overflow="clip"
                  wordBreak="break-all"
                  pr={2}
                >
                  <Show below="tablet">
                    <Text ml={2} textStyle="cardText">
                      {attachments[0].originalFilename.substring(0, 28)}
                    </Text>
                  </Show>
                  <Show above="tablet">
                    <Text ml={2} textStyle="cardText">
                      {attachments[0].originalFilename.substring(0, 40)}
                    </Text>
                  </Show>
                </Flex>
              </LinkChakra>
            </Flex>

            <Flex
              w={20}
              justifyContent="space-between"
              alignItems="center"
              // border="1px solid green"
            >
              <Text textStyle="textSmall" color="gray.500" fontStyle="italic">
                Ver{attachments.length}
              </Text>

              <Box
                color="gray.500"
                _hover={{ color: 'gray.400' }}
                onClick={() => {
                  onOpenInfoModal()
                }}
              >
                <MdHistory size="24px" />
              </Box>
            </Flex>
          </Flex>
        )}
        {variant === 'list-item' && (
          <Flex flexDir="row" alignItems="center" textStyle="infoText" w="100%">
            {isCheckable && (
              <Checkbox mr={3} defaultChecked={isChecked} onChange={onChange} />
            )}

            <Flex
              justifyContent="space-between"
              w="100%"
              gap={2}
              alignItems="center"
            >
              <LinkChakra
                as="a"
                onClick={() => {
                  // openAttachmentInNewTab(attachments[0])
                  openAttachmentInNewTab(attachments[attachments.length - 1])
                }}
                textStyle="infoTextLight"
                w="100%"
                _hover={{ textDecoration: 'none' }}
              >
                <Flex
                  justifyContent="space-between"
                  w="100%"
                  gap={2}
                  alignItems="center"
                >
                  <Text
                    h={{ xxs: 5, tablet: 7 }}
                    wordBreak="break-all"
                    overflow="clip"
                    // border="1px solid orange"
                  >
                    {attachments[0].originalFilename}
                  </Text>

                  <Flex
                    gap={2}
                    alignItems="center"
                    minW={16}
                    // border="1px solid orange"
                  >
                    <Text textStyle="textSmallFixed">
                      {/* <Text textStyle="infoTextSmall"> */}
                      {getDateStrFromNumber(attachments[0].createdAt)}
                    </Text>
                  </Flex>
                </Flex>
              </LinkChakra>

              {link && (
                <Link to={link} onClick={onOpenNoteCard}>
                  <ExternalLinkIcon mb={1} fontSize="14px" ml={0} />
                </Link>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {t('attachments.earlierVersionsTitle')}
          </Heading>

          <Text textStyle="infoText">{attachments[0].originalFilename}</Text>
          {attachments?.map((attachment, index) => {
            return (
              <Flex
                gap={2}
                key={attachment.id}
                alignItems="center"
                w="100%"
                justifyContent="space-between"
              >
                <Flex alignItems="center" gap={2}>
                  <AiOutlineFile />

                  <LinkChakra
                    as="a"
                    onClick={() => {
                      openAttachmentInNewTab(attachment)
                    }}
                  >
                    <Text textStyle="infoTextLight" fontStyle="italic">
                      Version {index + 1}
                    </Text>
                  </LinkChakra>
                </Flex>
                <Text textStyle="infoTextLight">
                  {getUserFriendlyDateStrFromNumber(attachment.createdAt)}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Modal>
    </>
  )
}
export default AttachmentCardCompactVersioned
