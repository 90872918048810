import { Flex, Image } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AddOrEditHelpRequestTemplateButton from '../../helpRequests/AddOrEditHelpRequestTemplateButton'
import helpRequestProcessPromo from '../../../img/content/helpRequestProcessPromo.png'
import { useCollaborators } from '../../../apiClients/projectsApiClient'
import AuthContext from '../../../common/auth-context'
import ProjectHelpRequestTemplateSection from './ProjectHelpRequestTemplateSection'

const ProjectHelpRequestPage: React.FC<{
  projectId: string
  mb?: number | string
}> = ({ projectId, mb }) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const { data: projectCollabs } = useCollaborators(projectId)
  const isReadOnly =
    projectCollabs?.find(collab => {
      return collab.accountId === currentUser?.id
    })?.accountRelation === 'Viewer'

  return (
    <Flex
      flexDir="column"
      px="2%"
      mb={mb}
      textStyle={{ xxs: '', tablet: 'textLarge' }}
      gap={8}
    >
      <AddOrEditHelpRequestTemplateButton
        projectId={projectId}
        variant="button"
        mt={10}
        buttonStyleVariant="secondary"
        disabled={isReadOnly ? true : false}
        tooltip={
          isReadOnly ? t('helpRequests.template.notAllowedToEditTooltip') : ''
        }
      />

      <ProjectHelpRequestTemplateSection projectId={projectId} />
      <Image src={helpRequestProcessPromo} maxW="xl" />
    </Flex>
  )
}
export default ProjectHelpRequestPage
