import React from 'react'
import { Flex, Image as ChakraImage } from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { getImageFromName, ImageName } from '../../common/image-utils'

export const Image: React.FC<{
  name?: ImageName
  h?: number | string
  mb?: number
  ml?: number
  mt?: number
  mr?: number
  maxW?: number | string
  minW?: number | string
  pt?: number
  px?: number
  alignSelf?: string
  onClick?: () => void
  borderRadius?: string
  border?: string
  src?: string
  w?: string | number
}> = ({
  name,
  h,
  mb,
  ml,
  mt,
  mr,
  maxW,
  minW,
  px,
  pt,
  onClick,
  alignSelf,
  borderRadius,
  border,
  src,
  w = '100%',
}) => {
  return (
    <Flex
      mb={mb}
      ml={ml}
      mt={mt}
      mr={mr}
      maxW={maxW}
      minW={minW}
      h={h}
      pt={pt}
      px={px}
      onClick={onClick}
      _hover={{ cursor: 'pointer' }}
      alignSelf={alignSelf}
      alignItems="center"
      justifyContent="center"
    >
      {name && getImageFromName(name, h, borderRadius, border)}
      {!name && src && (
        <ChakraImage src={src} fit="cover" w={w} h={h} border={border} />
      )}
      {!name && !src && <QuestionOutlineIcon fontSize={60} />}
    </Flex>
  )
}
