import { get } from 'lodash'
import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import {
  ChangePasswordRequestData,
  CreateAccountRequestData,
  CreateAccountsResponse,
  GetAllAccountsResponse,
  ImpersonateLoginAccountRequest,
  InviteResponse,
  MyAccountResponse,
  ResetPasswordRequestData,
  UpdateAccountRequest,
} from '@contracts/accounts'
import { AccountData, AccountTypeName } from '@contracts/misc'
import log from '../common/log'
require('./apiClientsCommon')

const baseUrl = window.ACCOUNTS_SERVICE_URL

const accountsFetcher = async (
  urlKey: string,
): Promise<AccountData[] | undefined> => {
  return axios
    .get<GetAllAccountsResponse>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data.data?.attributes?.accounts
    })
}

export const useAccounts = (
  accountType?: 'Consumer' | 'Provider' | undefined,
): SWRResponse<AccountData[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    AccountData[] | undefined
  >(
    accountType
      ? `/admin/accounts/?accountType=${accountType}`
      : '/admin/accounts/',
    accountsFetcher,
    { revalidateOnFocus: false },
  )
  return { data, error, isValidating, mutate }
}

export const useAccountInvites = (
  area: string,
  areaId: string | undefined,
): SWRResponse<InviteResponse[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    InviteResponse[] | undefined
  >(areaId ? `${baseUrl}/invites/?area=${area}&areaId=${areaId}` : null)
  return { data, error, isValidating, mutate }
}

export const useAccount = (
  id?: string,
): SWRResponse<AccountData | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<AccountData | undefined>(
    id && `${baseUrl}/accounts/${id}`,
    { revalidateOnFocus: false },
  )
  return { data, error, isValidating, mutate }
}

export const useAccountsByAccountIds = (
  accountIds: string[] | undefined,
): SWRResponse<AccountData[] | undefined> => {
  const querySring = encodeURIComponent(JSON.stringify(accountIds))
  const { data, error, isValidating, mutate } = useSWR<
    AccountData[] | undefined
  >(accountIds ? `${baseUrl}/accounts?accountIds=${querySring}` : null, {
    revalidateOnFocus: false,
  })
  return { data, error, isValidating, mutate }
}

export const deleteAccount = async (id: string) => {
  await axios.delete(`${baseUrl}/accounts/${id}`).catch((error: Error) => {
    log.error({ err: error }, 'Error deleting account')
    return error.message
  })
}

export const deleteInvite = async (id: string) => {
  await axios.delete(`${baseUrl}/invites/${id}`).catch((error: Error) => {
    log.error({ err: error }, 'Error deleting invite')
    return error.message
  })
}

export async function login(
  email: string,
  password: string,
  accountType: AccountTypeName,
): Promise<AccountData> {
  return axios
    .post<AccountData>(
      `${baseUrl}/accounts/signin`,
      { email, password, accountType },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(res => {
      return res.data
    })
}

export async function impersonate(ctx: string): Promise<AccountData> {
  return axios
    .post<AccountData>(
      `${baseUrl}/accounts/impersonate?ctx=${ctx}`,
      undefined,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(res => {
      return res.data
    })
}

export async function prepareImpersonate(
  req: ImpersonateLoginAccountRequest,
): Promise<string> {
  return axios
    .patch<string>(`${baseUrl}/admin/accounts/impersonate`, req, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
}

export async function logout() {
  return axios
    .post(`${baseUrl}/accounts/signout`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      log.info('Session is terminated, user is logged out')
    })
}

export async function getCurrentUser(): Promise<MyAccountResponse | null> {
  return axios
    .get<MyAccountResponse>(`${baseUrl}/accounts/me`)
    .then(response => {
      if (response.data.currentUser?.id) {
        log.info(
          `Session is active and valid. User is "${response.data.currentUser.email}"`,
        )
        return response.data
      }
      throw new Error()
    })
    .catch(e => {
      if (get(e, 'response.data.name') === 'NoActiveSessionError') {
        log.info('No active session found')
      }
      return null
    })
}

export const confirmInvite = async (inviteId: string) => {
  return axios
    .get(`${baseUrl}/invites/${inviteId}`)
    .then(({ status, data }) => {
      log.info('Successfully verified email', status, data)
      return data
    })
}

export async function addAccount(
  createAccountRequest: CreateAccountRequestData,
) {
  return axios
    .post<CreateAccountsResponse>(
      `${baseUrl}/accounts/`,
      createAccountRequest,
      {
        validateStatus: function (status) {
          return status === 200 || status === 201 || status === 400
        },
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ data }) => {
      return data
    })
    .catch((error: Error) => {
      log.error({ err: error }, 'Error creating account')
      return error.message
    })
}

export const deleteOrgAccount = async (orgId: string, accountId: string) => {
  return axios.delete(
    `${baseUrl}/organizations/${orgId}/accounts/${accountId}`,
    { headers: { 'Content-Type': 'application/json' } },
  )
}

export const updateAccount = async (
  accountId: string,
  requestData: UpdateAccountRequest,
) => {
  return axios
    .put(`${baseUrl}/accounts/${accountId}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated account', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating account')
      throw error
    })
}

export const sendVerifyEmail = async () => {
  return axios
    .patch(`${baseUrl}/accounts/me/verify-email`, undefined, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ data }) => {
      log.info('Successfully sent verify email')
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error sending verify email')
      throw error
    })
}

export const confirmVerifyEmail = async (emailVerifyCode: string) => {
  return axios
    .patch(
      `${baseUrl}/accounts/me/verify-email-confirm?emailVerifyCode=${emailVerifyCode}`,
      undefined,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully verified email', status, data)
      return data
    })
    .catch(error => {
      log.error(
        { err: error },
        `Error verifying email using emailVerifyCode: ${emailVerifyCode}`,
      )
      throw error
    })
}

export const updatePassword = async (
  accountId: string,
  requestData: ChangePasswordRequestData,
) => {
  return axios
    .put(`${baseUrl}/accounts/${accountId}/password`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ data }) => {
      log.info('Successfully updated password')
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating password')
      throw error
    })
}

export const forgotPassword = async (
  email: string,
  accountType: AccountTypeName,
): Promise<undefined> => {
  return axios
    .put(
      `${baseUrl}/accounts/forgot-password`,
      { email, accountType },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(({ data }) => {
      log.info('Successfully sent email to reset password')
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error sending email to reset password')
      throw error
    })
}

export const resetPassword = async (
  requestData: ResetPasswordRequestData,
): Promise<undefined> => {
  return axios
    .put(`${baseUrl}/accounts/set-new-password`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated password', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating password')
      throw error
    })
}
