import React, { useEffect, useState } from 'react'
import { Editor } from '@tiptap/react'
import {
  Button,
  HStack,
  Input,
  Box,
  Flex,
  InputGroup,
  InputLeftAddon,
  Alert,
  AlertIcon,
  Show,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFilePicker } from 'use-file-picker'
import { Attachment, ContextArea } from '@contracts/support'
import { RichTextButton } from '../ui/RichTextButton'
import Divider from '../ui/Divider'

import { addAttachment } from '../../apiClients/attachmentsApiClient'
import log from '../../common/log'
import { Icon } from '../ui/Icon'
import {
  IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
  IMG_PICKER_MAX_FILESIZE,
  IMG_PICKER_MAX_NO_FILES,
  IMG_PICKER_MIN_NO_FILES,
  TIPTAP_IMAGE_WIDTH,
} from '../../pages/constants'
import {
  compressFile,
  downloadAttachmentUrl,
} from '../attachments/attachmentHelper'
import Spinner from '../Spinner'
import FilePickerErrorAlert from './FilePickerErrorAlert'

const baseUrlPrivate = `${window.AZURE_BLOB_STORAGE_BASE_URL}/private/`

const ToolbarBasic: React.FC<{
  editor: Editor
  area: ContextArea
  areaId?: string
  parentArea?: ContextArea
  parentAreaId?: string
  grandParentArea?: ContextArea
  grandParentAreaId?: string
  container: 'private' | 'public'
  onImgAdded: (imgId: string) => void
  disableImages?: boolean
}> = ({
  editor,
  area,
  areaId,
  parentArea,
  parentAreaId,
  grandParentArea,
  grandParentAreaId,
  container = 'private',
  onImgAdded,
  disableImages = false,
}) => {
  const [srcUrl, updateSrcUrl] = useState<string>('')
  const [showAddImage, setShowAddImage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [openFileSelector, { loading, plainFiles, errors: filePickerErrors }] =
    useFilePicker({
      multiple: true,
      // For future documentation -
      // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
      readAs: 'DataURL',
      accept: IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
      limitFilesConfig: {
        min: IMG_PICKER_MIN_NO_FILES,
        max: IMG_PICKER_MAX_NO_FILES,
      },
      maxFileSize: IMG_PICKER_MAX_FILESIZE,
    })
  const createImageNode = (
    editor: Editor,
    imgUrl: string | undefined,
    attachment?: Attachment,
  ) => {
    const image = imgUrl ? imgUrl : srcUrl
    if (!image) return
    const urlComponents = image.split('.')
    const isVideo = urlComponents[urlComponents.length - 1] === 'mp4'
    editor
      .chain()
      .focus()
      .setImage({
        src: image,
        href: attachment
          ? downloadAttachmentUrl(attachment.id, attachment.originalFilename)
          : undefined,
        video: isVideo.toString(),
        width: TIPTAP_IMAGE_WIDTH,
        float: 'left',
        centered: 'false',
      })
      .run()
    editor.chain().focus().setHardBreak().run()
  }

  useEffect(() => {
    if (plainFiles.length > 0) {
      updateImgHandler()
    }
  }, [plainFiles])

  const uploadImgHandler = () => {
    openFileSelector()
  }

  const updateImgHandler = () => {
    setIsLoading(true)
    plainFiles.map(file => {
      compressFile(file, 'm', persistFile)
    })
  }

  const persistFile = (file: File) => {
    const formData = new FormData()
    formData.set('image', file)
    formData.set('area', area)
    {
      areaId && formData.set('areaId', areaId)
    }
    {
      parentArea && formData.set('parentArea', parentArea)
    }
    {
      parentAreaId && formData.set('parentAreaId', parentAreaId)
    }
    if (grandParentArea) {
      formData.set('grandParentArea', grandParentArea)
    }
    if (grandParentAreaId) {
      formData.set('grandParentAreaId', grandParentAreaId)
    }

    addAttachment(formData, container === 'private' ? true : false)
      .then(attachment => {
        const imgeUrl = `${baseUrlPrivate}${attachment.id}`
        createImageNode(editor, imgeUrl, attachment)
        onImgAdded ? onImgAdded(attachment.id) : ''
      })
      .catch(e => {
        log.error({ err: e }, 'Error saving attachment')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <HStack
        px={4}
        py={2}
        wrap="wrap"
        justifyContent="space-around"
        bg="primary_grey01"
        borderTopRadius="lg"
      >
        <RichTextButton
          iconName="RiBold"
          onClick={() => {
            editor.chain().focus().toggleBold().run()
          }}
          tooltip={t('richtext.bold')}
        />
        <RichTextButton
          iconName="RiItalic"
          onClick={() => {
            editor.chain().focus().toggleItalic().run()
          }}
          tooltip={t('richtext.italic')}
        />
        <RichTextButton
          iconName="RiUnderline"
          onClick={() => {
            editor.chain().focus().toggleUnderline().run()
          }}
          tooltip={t('richtext.underline')}
        />

        <Show above="tablet">
          <RichTextButton
            iconName="RiStrikethrough"
            onClick={() => {
              editor.chain().focus().toggleStrike().run()
            }}
            tooltip={t('richtext.strikethrough')}
          />
        </Show>

        <Divider orientation="vertical" h={5} color="gray.400" border={1} />

        <Show above="tablet">
          <RichTextButton
            iconName="BiText"
            size="2xl"
            onClick={() => {
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }}
            tooltip={t('richtext.fontLarge')}
          />
          <RichTextButton
            iconName="BiText"
            onClick={() => {
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }}
            tooltip={t('richtext.fontMedium')}
          />
          <RichTextButton
            iconName="BiText"
            size="sm"
            onClick={() => {
              editor.chain().focus().clearNodes().run()
            }}
            tooltip={t('richtext.fontSmall')}
          />
        </Show>

        {/* <RichTextButton
          iconName="BsListCheck"
          onClick={() => {
            editor.chain().focus().toggleTaskList().run()
          }}
          tooltip={t('richtext.orderedList')}
        /> */}
        <RichTextButton
          iconName="RiListOrdered"
          onClick={() => {
            editor.chain().focus().toggleOrderedList().run()
          }}
          tooltip={t('richtext.orderedList')}
        />
        <RichTextButton
          iconName="RiListUnordered"
          onClick={() => {
            editor.chain().focus().toggleBulletList().run()
          }}
          tooltip={t('richtext.unorderedList')}
        />
        <Show above="tablet">
          <RichTextButton
            iconName="RiSuperscript"
            onClick={() => {
              editor.chain().focus().toggleSuperscript().run()
            }}
            tooltip={t('richtext.superscript')}
          />

          <Divider orientation="vertical" h={5} color="gray.400" border={1} />
          {!disableImages && (
            <RichTextButton
              iconName="RiImageAddLine"
              onClick={() => {
                setShowAddImage(!showAddImage)
              }}
              tooltip={t('richtext.addImageLink')}
            />
          )}
        </Show>
        <RichTextButton
          iconName="addImgIcon_colored"
          onClick={() => {
            uploadImgHandler()
          }}
          tooltip={t('richtext.addImage')}
        />

        <Divider orientation="vertical" h={5} color="gray.400" border={1} />

        <Show above="tablet">
          <RichTextButton
            iconName="RiSeparator"
            onClick={() => {
              editor.chain().focus().setHorizontalRule().run()
            }}
            tooltip={t('richtext.horizontalRule')}
          />

          <Divider orientation="vertical" h={5} color="gray.400" border={1} />

          <RichTextButton
            iconName="RiArrowGoBackLine"
            onClick={() => {
              editor.chain().focus().undo().run()
            }}
            tooltip={t('richtext.undo')}
          />
          <RichTextButton
            iconName="RiArrowGoForwardLine"
            onClick={() => {
              editor.chain().focus().redo().run()
            }}
            tooltip={t('richtext.redo')}
          />
        </Show>
      </HStack>

      {loading && <Box>{t('portal.loading')}</Box>}
      {!loading && showAddImage && (
        <Flex flexDir="column" mx={12} my={2}>
          <HStack flexDir="row">
            <InputGroup>
              <InputLeftAddon
                h={8}
                pointerEvents="none"
                children={
                  <Icon name={'RiImageAddLine'} color="gray.400" h={20} />
                }
              />
              <Input
                h={8}
                placeholder={t('general.addImgUrl')}
                onChange={event => updateSrcUrl(event.target.value)}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    createImageNode(editor, undefined)
                  }
                }}
                value={srcUrl}
              />
            </InputGroup>

            <Button
              h={8}
              disabled={!srcUrl}
              onClick={() => createImageNode(editor, undefined)}
            >
              {t('general.confirm')}
            </Button>
            <Button h={8} onClick={() => setShowAddImage(false)}>
              {t('general.cancel')}
            </Button>
          </HStack>
          <Alert mt={2} status="warning" borderRadius="lg">
            <AlertIcon />
            {t('richtext.imgLinkWarning')}
          </Alert>
        </Flex>
      )}
      <FilePickerErrorAlert errors={filePickerErrors} />
      {isLoading && <Spinner top="50%" />}
    </>
  )
}
export default ToolbarBasic
