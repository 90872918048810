import { Button, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { IconName } from '../../common/icon-utils'
import AddOrEditImage from '../attachments/AddOrEditImage'
import { Icon } from './Icon'
import Modal from './Modal'

const AddImageButton: React.FC<{
  variant: 'ICON' | 'BUTTON'
  mt?: number
  mb?: number
  ml?: number
  w?: number
  iconName?: IconName
  iconColor?: string
  children?: ReactElement | string
  h?: number
  label?: string
  transform?: string
  imageId?: string
  saveImgHandler: (inputProp: string, inputValue: string) => void
  inputCallbackName?: string
}> = ({
  mt,
  mb,
  ml,
  children,
  w,
  h,
  iconName = 'IoAddCircleOutline',
  iconColor = 'primary_orange02',
  label,
  variant,
  transform,
  imageId,
  saveImgHandler,
  inputCallbackName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getUIComponent = () => {
    if (variant === 'ICON') {
      return (
        <Icon
          name={iconName}
          _hover={{ color: 'primary_orange02' }}
          color={iconColor}
          onClick={onOpen}
          ml={ml}
          transform={transform}
        />
      )
    }
    return (
      <Button
        onClick={onOpen}
        mt={mt}
        mb={mb}
        variant="primary"
        w={w || 32}
        h={h}
        transform={transform}
      >
        {children}
        {label}
      </Button>
    )
  }
  return (
    <>
      {getUIComponent()}
      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AddOrEditImage
          onCloseModal={onClose}
          imgId={imageId}
          saveImgHandler={saveImgHandler}
          inputCallbackName={inputCallbackName}
        />
      </Modal>
    </>
  )
}
export default AddImageButton
