/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Button, Checkbox, Radio, Text } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { PropertyResponse } from '@contracts/misc'

import { useNavigate } from 'react-router-dom'
import { KeyedMutator } from 'swr'
import { ProjectResponse } from '@contracts/projects'
import log from '../../common/log'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import AlertChakra from '../ui/AlertChakra'
import {
  addProject,
  useProjectCategories,
  useProjects,
} from '../../apiClients/projectsApiClient'
import CheckboxGroup from '../ui/CheckboxGroup'
import RadioGroup from '../ui/RadioGroup'
import FeedbackPopover from '../feedback/FeedbackPopover'
import { PATH_MY_PRIVATE_PROJECTS } from '../../common/nav'

interface AddProjectFormValues {
  projectName: string
  propertyId: string
  projectCategoryId: string[]
}
interface AddProjectErrors {
  projectName?: string
  propertyId?: string
  projectCategoryId?: string[]
}
const validate = (values: AddProjectFormValues) => {
  const errors: AddProjectErrors = {}

  const tmpRes = validateString(values.projectName, 'namn', 2, 30, true)
  if (!tmpRes.isValid) {
    errors.projectName = tmpRes.errorMsg
  }
  // This will not render, only stop form from being submitted
  if (!values.propertyId || values.propertyId.length === 0) {
    errors.propertyId = 'error'
  }
  if (!values.projectCategoryId || values.projectCategoryId.length === 0) {
    errors.projectCategoryId = ['error']
  }
  return errors
}

const AddProject: React.FC<{
  onCloseModal: () => void
  properties: PropertyResponse[] | undefined
  noForwardRedirect?: boolean
  mutateProp?: KeyedMutator<ProjectResponse[] | undefined>
}> = ({ onCloseModal, properties, noForwardRedirect = false, mutateProp }) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const { data: projectCategories } = useProjectCategories()
  const { mutate } = useProjects()
  const navigate = useNavigate()

  const initialValues: AddProjectFormValues = {
    projectName: '',
    propertyId: properties && properties[0] ? properties[0].id : '',
    projectCategoryId: [],
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      addProject(
        values.projectName,
        values.propertyId,
        values.projectCategoryId.map(id => ({ id })),
      )
        .then(data => {
          log.info(`Successfully created new project, ${values.projectName} `)
          void mutate()
          mutateProp ? void mutateProp() : ''
          applicationInsights.trackEvent(
            { name: 'Created project' },
            { projectName: values.projectName },
          )

          if (!noForwardRedirect) {
            // Navigate to project just created
            const newProjectId = data.data.data.id as string
            navigate(`${PATH_MY_PRIVATE_PROJECTS}/${newProjectId}`)
          }
        })
        .catch(e => {
          log.error(
            { err: e },
            `Failed to create new project, ${values.projectName} `,
          )
        })
        .finally(() => {
          onCloseModal()
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          {t('projects.startProject')}
        </Box>
        <FeedbackPopover context="projects" />
      </Box>
      <form onSubmit={handleSubmit} data-cy="add-project">
        <Box display="flex" flexDirection="column" m="1rem 1.5rem">
          {!properties ||
            (properties.length === 0 && (
              <AlertChakra
                title={t(
                  'projects.addProjectErrorMessages.propertyIsMissingTitle',
                )}
                description={t(
                  'projects.addProjectErrorMessages.propertyIsMissingDescr',
                )}
                status="error"
                mb={5}
              />
            ))}
          <Input
            title="Projektnamn"
            name="projectName"
            type="text"
            isMandatory={true}
            isError={!!errors.projectName}
            errorText={
              touched.projectName && errors.projectName
                ? errors.projectName
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="single"
            tooltip={t('projects.addProjectFormTooltips.projectName')}
          />
          <Text ml="1rem" textStyle="infoSmall">
            *{t('input.mandatoryFields')}
          </Text>
          <RadioGroup
            title={t('projects.chooseProperty')}
            value={formikValues.propertyId}
            tooltip={t('projects.addProjectFormTooltips.property')}
            isMandatory={true}
          >
            <>
              {properties?.map(p => {
                return (
                  <Radio
                    key={p.id}
                    name="propertyId"
                    value={p?.id}
                    padding=".5rem 1rem"
                    onChange={handleChange}
                  >
                    {p?.name}
                  </Radio>
                )
              })}
            </>
          </RadioGroup>
          {projectCategories ? (
            <CheckboxGroup
              title={t('projects.projectTypes')}
              isError={!!formikValues.projectCategoryId}
              errorText={
                touched.projectCategoryId && errors.projectCategoryId
                  ? t('projects.addProjectErrorMessages.categoryIsMissing')
                  : ''
              }
              isMandatory={true}
              tooltip={t('projects.addProjectFormTooltips.projectCategories')}
            >
              <>
                {projectCategories.map(p => {
                  return (
                    <Checkbox
                      key={p.id}
                      name="projectCategoryId"
                      value={p.id}
                      padding=".5rem 1rem"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {t(`projects.projectCategoryItems.${p.name ?? ''}`)}
                    </Checkbox>
                  )
                })}
              </>
            </CheckboxGroup>
          ) : (
            <Spinner />
          )}
          <Button
            type="submit"
            margin="1rem 0"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
          >
            {t('input.submitForm')}
          </Button>
        </Box>

        {isLoading && <Spinner />}
      </form>
    </>
  )
}
export default AddProject
