import { IconName } from '../common/icon-utils'

export interface SimpleArticle {
  title?: string
  heading?: string
  content: string
  icon?: IconName
  comingSoon?: boolean
}
export interface Tag {
  title?: string
  value: string
}
export interface ContentArticle {
  title?: string
  heading: string
  intro: string
  content: string
  tags?: Tag[]
  author?: Author
  date?: string
}
export interface Author {
  name: string
  title: string
  company: string
  profileImg: string
}
export interface PriceTag {
  title: string
  monthlyFee: number
  monthlyFeeSale?: number
  monthlyFeeSaleText?: string
  limitUsers: string
  limitNoActiveProjects: number
  limitAsks: string
  limitHistory: string
  limitSupport: string
  color: string
}

export const PriceList: PriceTag[] = [
  {
    title: 'Liten',
    monthlyFee: 399,
    monthlyFeeSale: 299,
    monthlyFeeSaleText: 'Gäller till 2023-12-31 därefter 399 kr/månad',
    limitUsers: 'oneUser',
    limitNoActiveProjects: 5,
    limitAsks: 'noAsksLimit',
    limitHistory: 'noHistoryLimit',
    limitSupport: 'noSupportLimit',
    color: 'logo_purple',
  },
  {
    title: 'Mellan',
    monthlyFee: 899,
    monthlyFeeSale: 599,
    monthlyFeeSaleText: 'Gäller till 2023-12-31 därefter 899 kr/månad',
    limitUsers: 'twoToFiveUsers',
    limitNoActiveProjects: 20,
    limitAsks: 'noAsksLimit',
    limitHistory: 'noHistoryLimit',
    limitSupport: 'noSupportLimit',
    color: 'primary_orange02',
  },
  {
    title: 'Stor',
    monthlyFee: 1999,
    monthlyFeeSale: 1599,
    monthlyFeeSaleText: 'Gäller till 2023-12-31 därefter 1999 kr/månad',
    limitUsers: 'moreThanFiveUsers',
    limitNoActiveProjects: 50,
    limitAsks: 'noAsksLimit',
    limitHistory: 'noHistoryLimit',
    limitSupport: 'noSupportLimit',
    color: 'primary',
  },
]

export const ProductDescriptions: SimpleArticle[] = [
  {
    title: 'MyHome',
    icon: 'myProperty',
    heading: 'Mitt hem',
    content:
      'Detta är hjärtat i ditt boende. Här får du kontroll på händelser i ditt hem, ser pågående projekt, sparar viktig information och håller koll på kvitton och dokument. Du kan såklart ha flera hem och även dela de med familj eller andra som du delar boende med. Ett enkelt sätt att gemensamt utveckla och ha kontroll på era hem.',
  },
  {
    title: 'MyProjects',
    icon: 'myProject',
    heading: 'Mina projekt',
    content:
      'Ofta har vi projekt i gång eller på gång. Det kan vara stora eller små, oavsett så behöver vi ha koll på dem. Här samarbetar du med andra som är involverade i ditt projekt. Du kan bjuda in både familjemedlemmar, experter och hantverkare för att samarbeta och dela  idéer, bilder eller dokument.',
  },
  {
    title: 'FindExpert',
    icon: 'findExpert',
    heading: 'Hitta expert',
    content:
      'Det är omöjligt att kunna allt själv! Men som tur är finns det en hel värld av kompetenta människor därute :) Vi ser till att du hittar precis den expertis som du behöver för att förverkliga dina bostadsdrömmar.',
  },
  {
    title: 'ClimateSmartConstruction',
    icon: 'climateSmartConstruction',
    heading: 'Klimatsmart byggande',
    content:
      'Det bästa för klimatet är i många fall att inte bygga eller renovera alls, men eftersom byggandet av många anledningar kommer att fortgå måste vi göra det vi kan för att minska vårt klimatavtryck. Här får du en snabb överblick med konkreta tips på hur du kan minska ditt avtryck, var du än är i bygg-, planerings- eller drömprocessen.',
  },
  {
    title: 'CompareClimateImpact',
    icon: 'compareClimateImpact',
    heading: 'Jämför klimatpåverkan',
    content:
      'I vårt jämförelseverktyg kan du ställa olika materialval mot varandra och se dess klimatpåverkan. Du får en indikation på hur dina val påverkar klimatet och vilka val du kan göra för att minska utsläppen av växthusgaser. Beräkningar och jämförelser baseras på verifierad klimatdata från Boverkets klimatdatabas.',
  },
  {
    title: 'Guidance',
    icon: 'guidance',
    heading: 'Vägledning',
    content:
      'Det är mycket att tänka på när du ska bygga nytt eller renovera. Vad tillåter detaljplanen? När krävs det bygglov? Vilket ansvar har jag som byggherre? Samtidigt är det en massa ord och begrepp som jag inte vet vad de betyder. Men du kan vara lugn vi är här för att förenkla det för dig genom tydliga guider, informativa artiklar och en sökbar ordlista för byggtermer!',
  },
]

export const ProductDescriptions2 = [
  {
    id: 1,
    title: 'myProperties',
    heading: 'Mina hem',
    src: 'myProperties',
    content:
      'Här kan du ha överblick av ditt hem, se pågående projekt, spara viktig information så som kvitton och dokument. Du kan såklart ha flera hem och även dela de med familj eller andra som du delar boende med. Ett enkelt sätt att gemensamt utveckla och underhålla era hem, allt på ett och samma ställe!',
  },
  {
    id: 2,
    title: 'myProjects',
    heading: 'Mina projekt',
    src: '',
    content:
      'Ofta har vi projekt i gång eller på gång. Det kan vara stora eller små, oavsett så behöver vi ha kontroll på dem. Här samarbetar du med andra som är involverade i ditt projekt. Du kan bjuda in både familjemedlemmar, experter och hantverkare för att samarbeta och dela idéer, bilder eller dokument.',
  },
  {
    id: 3,
    title: 'findExpert',
    heading: 'Hitta expert',
    src: '',
    content:
      'Det är omöjligt att kunna allt själv! Men som tur är finns det en hel värld av kompetenta människor därute. Här kan du hitta precis den expertis som du behöver för att förverkliga dina bostadsdrömmar. Genom att ta rätt hjälp vid rätt tidpunkt ökar du chanserna att nå en bättre slutprodukt, till förväntad kostnad.',
  },
  {
    id: 4,
    title: 'climateSmartConstruction',
    heading: 'Klimatsmart byggande',
    src: '',
    content:
      'Hur bygger vi på ett klimatsmart sätt? Berikar ger dig guidning och vägledning för att göra bra val, för dig och miljön! Här kan du jämföra produkters klimatpåverkan, läsa artiklar om hållbarhet, samt få ökad förståelse för var den största klimatpåverkan uppstår med hjälp av intuitiva verktyg.',
  },
  {
    id: 5,
    title: 'guidance',
    heading: 'Vägledning',
    src: '',
    content:
      'Det är mycket att tänka på när du ska bygga nytt eller renovera. Vad tillåter detaljplanen? När krävs det bygglov? Vilket ansvar har du som byggherre? Samtidigt är det en massa ord och begrepp som är svåra att förstå. Men du kan vara lugn, vi är här för att förenkla för dig genom tydliga guider, inspirerande artiklar och en sökbar ordlista för byggtermer!',
  },
  // Inspiration is temporarily removed
  // {
  //   id: 6,
  //   title: 'inspiration',
  //   heading: 'Inspiration',
  //   src: '',
  //   content:
  //     'Det är inte så enkelt att få till den där känslan eller stilen som det blir i livsstilstidningar eller hos personer som har öga för inredning. Här får du tips och idéer på hur du kan göra för att uppnå en viss stil eller kombinerar inredning och färgsättning på bästa sätt.',
  // },
]

export const ProviderFeatureDescriptions: SimpleArticle[] = [
  {
    title: 'matching',
    icon: 'networking',
    heading: 'Matchningstjänst',
    content:
      'Genom vår matchningstjänst blir ert företag synligt för kunder som är i uppstarten eller genomförandet av projekt. Ni väljer själva vilken typ av uppdrag ni vill göra, vad som är viktigt för er hos en kund och när ni är tillgängliga. Förfrågningar & offerter hanteras enkelt direkt i plattformen vilket möjliggör spårbarhet och struktur.',
  },
  {
    title: 'projectPlatform',
    icon: 'computerScreen',
    heading: 'Projektplattform',
    content:
      'Samarbeta med era kunder och andra experter i en intuitiv digital plattform där ni kommunicerar och delar inspirationsbilder, ritningar, beskrivningar, tidplaner, ÄTA-underlag eller annat ni behöver för ett lyckat slutresultat. Helt enkelt ett ställe för att ha kontroll på allt kring projektet.',
  },
  {
    title: 'projectOverview',
    icon: 'project',
    heading: 'Projektöversikt',
    content:
      'Som företag har ni sannolikt flera projekt igång samtidigt. För att ni ska ha kontroll på projekten samlar vi de i en tydlig översikt där ni har allt samlat på ett ställe. Även avslutade projekt kan ni arkivera för att i framtiden återbesöka om ni behöver.',
  },
  {
    title: 'guidance',
    icon: 'guidance',
    heading: 'Vägledning',
    content:
      'Tänk om kunderna redan innan ni påbörjade ert samarbete hade en grundläggande förståelse för byggprocessen och vad som kommer krävas för att realisera deras projekt. Genom vår plattform får kunderna tillgång till vägledande material och stöd. Med en ökad insikt och kunskap kring processen blir ert samarbete glädjefyllt, friktionsfritt och ännu mer produktivt.',
  },
  {
    title: 'expertConsulting',
    icon: 'findExpert',
    heading: 'Expertrådgivning',
    comingSoon: true,
    content:
      'Alla frågor behöver inte vara stora eller omfattande. Vi anser att experthjälp och råd borde vara mer lättillgängligt. Med vår tjänst expertrådgivning hjälper ni bostadsägare helt digitalt. Ni får tydliga frågeställningar som ni via videomöten besvarar och resonerar kring. Bokning, betalning och annan administration tar vi hand om, så att ni kan vara produktiva och fokusera på det roliga.',
  },
  {
    title: 'reviews',
    icon: 'yellowStar',
    heading: 'Omdöme & referensprojekt',
    comingSoon: true,
    content:
      'Dela med er av vad ni gjort tidigare och vilken typ av tjänster ni erbjuder. Låt även era tidigare kunder stärka ert anseende genom att göra sina röster hörda. Gör ni ett bra jobb kommer det att synas för era framtida kunder, vilket ger er ett ökat företroende och skapar en bättre utgångspunkt i framtida samarbeten.',
  },
]

export const ingressFindExpertPage =
  'Det är omöjligt att kunna allt själv! Men som tur är finns det en hel värld av kompetenta människor därute. Här kan du hitta precis den expertis som du behöver för att förverkliga dina bostadsdrömmar.'

export const ingressClimateSmartConstructionPage =
  'Bygg- och fastighetssektorn stod 2019 för ca 21% av Sveriges totala utsläpp av växthusgaser, därtill bidrar denna sektor dessutom till stora utsläpp utomlands genom importvaror. Nästan hälften av utsläppen från bygg- och fastighetssektorn är kopplat till byggverksamhet.'

export const ingressInspirationPage =
  'Funderar du kring vilken stil som passar i din bostad och hur du ska kombinera inredning, färgsättning och möbler. Eller vad som skiljer Funkis från Art deco, Jugend eller New England? Då har du kommit rätt, det är precis det vi har samlat till dig här.'

export const ingressConstructionTermsPage =
  'Det blir fort många termer att hålla koll på...och det kan ibland kännas svårt att hänga med i förkortningar och fackspråk. I vår sökbara ordlista reder vi ut begreppen och hjälper dig att få koll på läget.'

export const ingressGuidancePage =
  'Oavsett om du har svårt att sätta ord på dina drömmar eller står inför att söka bygglov för ditt drömboende, så är det mycket att tänka på och sätta sig in i. Vi vill hjälpa dig på vägen, här samlar vi allt du kan behöva för att göra vägen till ditt drömboende så trivsam som det bara går.'

export const ingressCompareClimateImpactPage =
  'Bygg- och fastighetssektorn stod 2019 för ca 21% av Sveriges totala utsläpp av växthusgaser, därtill bidrar denna sektor dessutom till stora utsläpp utomlands genom importvaror. Nästan hälften av utsläppen från bygg- och fastighetssektorn är kopplat till byggverksamhet.'

export const ingressCompareClimateImpactSection =
  'Nedan kan du jämföra olika produkter för att få en indikation på respektive produkts klimatpåverkan. Produkternas utsläpp av västhusgaser (koldioxid, metan, lustgas m.fl.) redovisas som koldioxidekvivalenter (CO₂e) som anger hur mycket koldioxid det motsvarar för att ge samma verkan på klimatet. Viktigt att ta produktens densitet, livslängd och åtgång i beaktning när jämförelse mellan olika produkter görs. Den klimatdata som presenteras nedan kommer från Boverkets Klimatdatabas.'

export const ingressConstructionProcessPage =
  'Hur går jag från idé till verklighet? Processen kan kännas snårig med många olika steg, faser, delar och moment. Byggprocessen kan dessutom variera beroende på typ av projekt, upphandlingsform, kommun där det uppförs och mycket mer. Men här reder vi ut de generella bitarna och vad som är viktigt att tänka på.'

export const ingressDetailedPlanAndBuildingPermitPage =
  'Hur tolkar jag detaljplanen och vad får jag egentligen bygga? Vi reder ut begreppen, vad som gäller och när du behöver söka bygglov.'

export const welcomeNewUser1 =
  'Här börjar resan mot ditt drömboende!<br /><br />Många är besluten och frågorna en ställs inför när det är dags att förändra något i sitt hem. Men du kan vara lugn vi är här för att hjälpa dig och göra den resan så smidig som det bara går. Vi hjälper dig att hitta rätt samarbetspartners för dina behov och inspirerar dig till hur du kan uppfylla dina drömmar och idéer och samtidigt göra så liten påverkan på klimatet som möjligt.'
export const welcomeNewUser2 = 'Lägg till ditt hem för att komma igång!'
export const ingressBuildingPartsClimateImpactPage =
  'Vår påverkan på planeten blir allt mer påtaglig och den byggda miljön står för en fjärdedel av Europas totala utsläpp. I Sverige använder vi till stor del förnyelsebar energi i våra byggnader, vilket är superbra. MEN det medför också att själva byggnationen står för mer än hälften av de totala utsläppen när hela byggnadens livscykeln studeras. <br /><br /> Ett småhus klimatpåverkan under byggnationen domineras av ev en handfull byggdelar. Genom medvetna metod- och materialval kan du göra avgörande val som minskar ditt hus klimatpåverkan. Nedan illustreras klimatpåverkan från olika byggdelar.'

export const getPriceList = (): Promise<PriceTag[]> => {
  return Promise.resolve(PriceList)
}

export const getProductDescriptions = (): Promise<SimpleArticle[]> => {
  return Promise.resolve(ProductDescriptions)
}
export const getProviderFeatureDescriptions = (): Promise<SimpleArticle[]> => {
  return Promise.resolve(ProviderFeatureDescriptions)
}

export const getTextContent = (key: string): Promise<string | undefined> => {
  switch (key) {
    case 'WelcomeNewUser1':
      return Promise.resolve(welcomeNewUser1)
    case 'WelcomeNewUser2':
      return Promise.resolve(welcomeNewUser2)
    default:
      return Promise.resolve(undefined)
  }
}
export interface BuildingPart {
  code: string
  title: string
  partOfTotalImpact: number
  description: string
}

const buildingParts: BuildingPart[] = [
  {
    code: 'roof',
    title: 'Yttertak',
    partOfTotalImpact: 18,
    description:
      'Yttertak kan uppföras med ett antal olika material. Det vanligaste för småhus är betongpannor, som väljs i ungefär 60% av fallen. Andra vanligt förekommande takmaterial är papp, plåt och lertegel. Förutom själva takmaterialet innehåller denna byggdel även stommen för taket, takpanel, isolering, underlagspapp, takfot, gavlar, plåtarbeten osv.',
  },
  {
    code: 'base',
    title: 'Husunderbyggnad',
    partOfTotalImpact: 25,
    description:
      'Husunderbyggnad är det arbete som utförs för att kunna resa ett hus. Det är schaktning, fyllning, markarbeten, grundkonstruktioner, kulvertar och platta på mark. Den vanligaste grundläggningsmetoden för småhus är betongplatta på mark, vilket bidrar till grundens procentuellt höga bidrag till klimatpåverkan. För småhus har det även stor betydelse om det finns en eller två våningar att fördela husunderbyggnadens klimatpåverkan på.',
  },
  {
    code: 'structure',
    title: 'Stomme',
    partOfTotalImpact: 5,
    description:
      'Stomme är de byggdelar som tillsammans utgör den bärande konstruktionen för en byggnad, det kan vara väggar, bjälklag, pelare, balkar osv.  Byggdelarna kan vara av olika material och materialfördelningen i stommen har stor inverkan på dess klimatpåverkan. Stommen i småhus har en relativt låg klimatpåverkan jämfört med andra byggnadstyper. Anledningen till detta är att det dominerande materialvalet för stommen i småhus är trä. Hela 99% av de småhus som byggs i Sverige uppförs med stomme av trä (monteringsfärdiga trähus eller lösvirke).',
  },
  {
    code: 'facade',
    title: 'Fasad',
    partOfTotalImpact: 21,
    description:
      'För småhus är det dominerande fasadmaterialet trä, vilket väljs i ungefär 70% av fallen vid nyproduktion. I övriga fall förekommer betong, tegel eller puts. Denna byggdel innehåller förutom själva fasadbeklädnaden även fönster, dörrar, partier och portar som är monterade i fasaden. Därför påverkas denna byggnadsdels klimatpåverkan vid val av stora glaspartier eller glasfasader.',
  },
  {
    code: 'structureCompletion',
    title: 'Stomkomplettering',
    partOfTotalImpact: 3,
    description:
      'Ett hus innehåller, förutom stommen, även ett antal stomkompletterande delar. Det kan vara ickebärande ytterväggar, innerväggar, innertak, undergolv, invändiga dörrar osv. Stomkomplettering är inte en del av den bärande konstruktionen, dock måste den bära sin egentygnd och tåla klimatlaster (snö, vind, temperatur etc).',
  },
  {
    code: 'roomCompletion',
    title: 'Rumskomplettering',
    partOfTotalImpact: 15,
    description:
      'Rumskomplettering är material som bidrar till färdigställande av ett rum. Det innebär allt från ytskikt på golv/vägg/tak, skåpsinredning för kök/badrum/förvaring, vitvaror, lister, smygbrädor, socklar, foder, fönsterbrädor med mera. Kakel och klinker ingår också i denna byggdel och bidrar relativt mycket till byggdelens totala klimatpåverkan. ',
  },
  {
    code: 'installations',
    title: 'Installationer',
    partOfTotalImpact: 6,
    description:
      'Här avses samtliga tekniska installationer som görs i huset. Det omfattar värme, sanitet, ventilation, el, data samt installation av smarta hem produkter. Ibland ingår även installation av solceller i denna byggdel, men då dess påverkan på enskilda byggnader kan variera kraftigt innehåller inte jämförelsetalet i denna illustration klimatpåverkan från solceller.',
  },
  {
    code: 'energy',
    title: 'Energi (A5)',
    partOfTotalImpact: 7,
    description:
      'Denna kategori avser klimatpåverkan från energikrävande processer på byggplatsen t.ex. användning av el, värme och bränslen för vissa transporter och maskiner.',
  },
]
export const useBuildingParts = (): string[] | undefined => {
  return (
    buildingParts.map(part => {
      return part.code
    }) ?? undefined
  )
}
export const useBuildingPart = (code: string): BuildingPart | undefined => {
  return (
    buildingParts.find(part => {
      return part.code === code
    }) ?? undefined
  )
}
