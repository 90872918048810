import { Flex, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { Note } from '@contracts/misc'
import log from '../../common/log'
import Modal from '../ui/Modal'
import { useArea } from '../../common/area-context'
import NoteCardMedium from './NoteCardMedium'

const NoteCardMediumButton: React.FC<{
  note: Note
  propertyId?: string
  projectId?: string
  children: ReactElement
}> = ({ note, propertyId, projectId, children }) => {
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  const {
    isOpen: isOpenNoteCardMedium,
    onOpen: onOpenNoteCardMedium,
    onClose: onCloseNoteCardMedium,
  } = useDisclosure()
  return (
    <>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          onOpenNoteCardMedium()
        }}
      >
        {children}
      </Flex>
      <Modal
        isOpen={isOpenNoteCardMedium}
        onOpen={onOpenNoteCardMedium}
        onClose={onCloseNoteCardMedium}
        size="lg"
      >
        <NoteCardMedium
          note={note}
          propertyId={propertyId}
          projectId={projectId}
          onClose={onCloseNoteCardMedium}
        />
      </Modal>
    </>
  )
}
export default NoteCardMediumButton
