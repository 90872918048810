import {
  Flex,
  Text,
  Button,
  Image,
  useToast,
  Checkbox,
  RadioGroup,
  Radio,
  Box,
  Center,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useState } from 'react'
import {
  OrganizationProfile,
  UpdateOrganizationProfileRequest,
} from '@contracts/accounts'
import { Organization } from '@contracts/misc'

import { KeyedMutator } from 'swr'
import {
  updateOrganizationProfile,
  useServiceTypes,
} from '../../../apiClients/organizationsApiClient'
import log from '../../../common/log'
import Spinner from '../../Spinner'
import CheckboxGroup from '../../ui/CheckboxGroup'
import Tiptap from '../../richTextEditor/Tiptap'

import SimpleImagePickerButton from '../../ui/SimpleImagePickerButton'

import {
  addAttachment,
  deleteAttachment,
} from '../../../apiClients/attachmentsApiClient'

import { compressFileAsync } from '../../attachments/attachmentHelper'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public`

interface ProfileValues {
  profileAttachmentId?: string
  backgroundAttachmentId?: string
  profileText: string
  consultant: boolean
  craftsman: boolean
  provideRemoteServices: boolean
  serviceTypeIds?: string[]
}

const AddOrEditOrganizationProfile: React.FC<{
  currentOrganization: Organization
  currentOrganizationProfile: OrganizationProfile
  maxW?: string
  mt?: string | number
  px?: string | number
  mutate?: KeyedMutator<UpdateOrganizationProfileRequest | undefined>
}> = ({
  currentOrganization,
  maxW,
  mt,
  px,
  currentOrganizationProfile,
  mutate,
}) => {
  const { t } = useTranslation()

  const toast = useToast()

  const { data: allServiceTypes } = useServiceTypes()

  const consultantServiceTypes = allServiceTypes?.filter(serviceType => {
    return serviceType.consultant === true
  })
  const craftsmanServiceTypes = allServiceTypes?.filter(serviceType => {
    return serviceType.consultant === false
  })

  const initialValues: ProfileValues = {
    profileAttachmentId: currentOrganizationProfile?.profileAttachmentId,
    backgroundAttachmentId: currentOrganizationProfile?.backgroundAttachmentId,
    profileText: currentOrganizationProfile?.profileText ?? '',
    consultant: currentOrganizationProfile?.consultant ?? false,
    craftsman: currentOrganizationProfile?.craftsman ?? false,
    provideRemoteServices:
      currentOrganizationProfile?.provideRemoteServices ?? false,
    serviceTypeIds: currentOrganizationProfile?.serviceTypeIds,
  }

  const [profileText, setProfileText] = useState(
    initialValues.profileText ?? '<p></p>',
  )
  const [localProfileImage, setLocalProfileImage] = useState<File | undefined>(
    undefined,
  )
  const [localBackgroundImage, setLocalBackgroundImage] = useState<
    File | undefined
  >(undefined)

  const [isConsultant, setIsConsultant] = useState(
    currentOrganizationProfile?.consultant,
  )
  const [isCraftsman, setIsCraftsman] = useState(
    currentOrganizationProfile?.craftsman,
  )

  const persistFile = async (file: File) => {
    const formData = new FormData()
    formData.set('image', file)
    formData.set('area', 'Account')

    const newAttachment = await addAttachment(formData, false)
    return newAttachment
  }

  const { handleSubmit, handleChange, handleBlur, isSubmitting } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      const requestData: UpdateOrganizationProfileRequest = {
        profileText: profileText,
        consultant: values.consultant,
        craftsman: values.craftsman,
        provideRemoteServices: values.provideRemoteServices,
        availability: 'Now',
        profileAttachmentId: currentOrganizationProfile?.profileAttachmentId,
        backgroundAttachmentId:
          currentOrganizationProfile?.backgroundAttachmentId,
        serviceTypeIds: values.serviceTypeIds,
      }

      if (localProfileImage) {
        // Remove old file
        if (currentOrganizationProfile?.profileAttachmentId) {
          await deleteAttachment(
            currentOrganizationProfile.profileAttachmentId,
            true,
          )
        }

        // Compress new file
        const compressedFile = await compressFileAsync(localProfileImage, 's')

        // Persist new file
        requestData.profileAttachmentId = (await persistFile(compressedFile)).id
      }

      if (localBackgroundImage) {
        // Remove old file
        if (currentOrganizationProfile?.backgroundAttachmentId) {
          await deleteAttachment(
            currentOrganizationProfile.backgroundAttachmentId,
            true,
          )
        }

        // Compress new file
        const compressedFile = await compressFileAsync(
          localBackgroundImage,
          'm',
        )

        // Persist new file
        requestData.backgroundAttachmentId = (
          await persistFile(compressedFile)
        ).id
      }

      updateOrganizationProfile(currentOrganization.id, requestData)
        .then(res => {
          log.debug('Successfully updated organization profile', res)
          mutate ? void mutate() : ''
          toast({
            title: t('accounts.providers.profile.updateProfileSuccessMessage'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          mutate ? void mutate() : ''
        })
        .catch(err => {
          log.error({ err }, 'Failed to update organization profile data')
          toast({
            title: t('accounts.providers.profile.updateProfileFailMessage'),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const onChangeProfileTextHandler = (text: string) => {
    setProfileText(text)
  }

  return (
    <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
      <Flex
        flexDir="row"
        justifyContent={{ laptop: 'center', desktop: 'center', xxl: 'left' }}
        wrap="wrap"
      >
        <form onSubmit={handleSubmit} data-cy="add-new-provider">
          <Flex flexDir="column" mb={4}>
            <Flex flexDir="row" wrap="wrap">
              <Flex flexDir="column" mr={6}>
                <Text mb={1}>
                  {t('accounts.providers.profile.profileAttachment')}
                </Text>
                {localProfileImage && (
                  <Image
                    src={URL.createObjectURL(localProfileImage)}
                    boxSize={32}
                  />
                )}
                {!localProfileImage &&
                  currentOrganizationProfile?.profileAttachmentId && (
                    <Image
                      src={`${baseUrl}/${currentOrganizationProfile.profileAttachmentId}`}
                      boxSize={32}
                    />
                  )}
                {!localProfileImage &&
                  !currentOrganizationProfile?.profileAttachmentId && (
                    <Center
                      boxSize={32}
                      border="1px solid"
                      borderColor="gray.300"
                      color="gray.500"
                      bg="gray.200"
                    >
                      {t('general.missingImage')}
                    </Center>
                  )}
                <SimpleImagePickerButton
                  variant="BUTTON"
                  mt={4}
                  mb={6}
                  h={8}
                  onAddLocalImage={localImage => {
                    setLocalProfileImage(localImage)
                  }}
                >
                  <Text fontSize="sm" fontWeight="semibold" color="white">
                    {t(
                      'accounts.providers.profile.profileAttachmentUploadButton',
                    )}
                  </Text>
                </SimpleImagePickerButton>
              </Flex>
              <Flex flexDir="column">
                <Text mb={1}>
                  {t('accounts.providers.profile.backgroundAttachment')}
                </Text>
                {localBackgroundImage && (
                  <Image
                    src={URL.createObjectURL(localBackgroundImage)}
                    h={32}
                    w={{ xxs: 64, tablet: 96 }}
                  />
                )}
                {!localBackgroundImage &&
                  currentOrganizationProfile?.backgroundAttachmentId && (
                    <Image
                      src={`${baseUrl}/${currentOrganizationProfile.backgroundAttachmentId}`}
                      h={32}
                      w={{ xxs: 64, tablet: 96 }}
                      fit="cover"
                    />
                  )}
                {!localBackgroundImage &&
                  !currentOrganizationProfile?.backgroundAttachmentId && (
                    <Center
                      h={32}
                      w={{ xxs: 64, tablet: 96 }}
                      border="1px solid"
                      borderColor="gray.300"
                      color="gray.500"
                      bg="gray.200"
                    >
                      {t('general.missingImage')}
                    </Center>
                  )}
                <SimpleImagePickerButton
                  variant="BUTTON"
                  w="100%"
                  mt={4}
                  h={8}
                  onAddLocalImage={localImage => {
                    setLocalBackgroundImage(localImage)
                  }}
                >
                  <Text fontSize="sm" fontWeight="semibold" color="white">
                    {t(
                      'accounts.providers.profile.backgroundAttachmentUploadButton',
                    )}
                  </Text>
                </SimpleImagePickerButton>
              </Flex>
            </Flex>
            {/* Type of services */}
            <Flex display="flex" flexDirection="column" mt={6}>
              <CheckboxGroup
                title={t('accounts.providers.profile.serviceTypesQuestions')}
                isMandatory={false}
                columns={3}
              >
                <Flex
                  w={{ xxs: '80vw', tablet: 'md' }}
                  flexDir="row"
                  flexWrap="wrap"
                >
                  <Checkbox
                    name="consultant"
                    defaultChecked={initialValues.consultant}
                    py={2}
                    px={4}
                    w="32"
                    onChange={e => {
                      handleChange(e)
                      setIsConsultant(e.target.checked)
                    }}
                    onBlur={handleBlur}
                  >
                    {t('accounts.providers.profile.consultant')}
                  </Checkbox>
                  <Checkbox
                    name="craftsman"
                    defaultChecked={initialValues.craftsman}
                    py={2}
                    px={4}
                    w="32"
                    onChange={e => {
                      handleChange(e)

                      setIsCraftsman(e.target.checked)
                    }}
                    onBlur={handleBlur}
                  >
                    {t('accounts.providers.profile.craftsman')}
                  </Checkbox>
                </Flex>
              </CheckboxGroup>

              {/* Service areas */}
              <CheckboxGroup
                title={t('accounts.providers.profile.serviceAreasQuestions')}
                isMandatory={false}
                columns={3}
                defaultValue={initialValues.serviceTypeIds}
              >
                <Flex
                  w={{
                    xxs: '80vw',
                    tablet: '80vw',
                    laptop: '55vw',
                    desktop: '60vw',
                  }}
                  maxW="980px"
                  flexDir="row"
                  flexWrap="wrap"
                >
                  {!isConsultant && !isCraftsman && (
                    <Text ml={4} color="red">
                      {t('accounts.providers.profile.noServiceTypesChosen')}
                    </Text>
                  )}
                  {isConsultant &&
                    consultantServiceTypes?.map(serviceType => {
                      return (
                        <Checkbox
                          key={serviceType.id}
                          name="serviceTypeIds"
                          value={serviceType.id}
                          py={2}
                          px={4}
                          w={60}
                          onChange={e => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          {serviceType.name}
                        </Checkbox>
                      )
                    })}
                  {isCraftsman &&
                    craftsmanServiceTypes?.map(serviceType => {
                      return (
                        <Checkbox
                          key={serviceType.id}
                          name="serviceTypeIds"
                          value={serviceType.id}
                          py={2}
                          px={4}
                          w={60}
                          onChange={e => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          {serviceType.name}
                        </Checkbox>
                      )
                    })}
                </Flex>
              </CheckboxGroup>

              {/* Deliver remote services */}
              <Text mt={6} mb={2}>
                {t('accounts.providers.profile.remoteServicesQuestion')}
              </Text>

              <RadioGroup
                defaultValue={
                  initialValues.provideRemoteServices ? 'yes' : 'no'
                }
                pl={6}
              >
                <Radio
                  value="yes"
                  mr={6}
                  name="provideRemoteServices"
                  onChange={e => {
                    handleChange(e)
                  }}
                >
                  {t('general.yes')}
                </Radio>

                <Radio
                  value="no"
                  mr={6}
                  name="provideRemoteServices"
                  onChange={e => {
                    handleChange(e)
                  }}
                >
                  {t('general.no')}
                </Radio>
              </RadioGroup>

              <Text mt={6} mb={2}>
                {t('accounts.providers.profile.describeServicesQuestion')}
              </Text>
              <Box w="100%" maxW="container.lg" mr={6}>
                <Tiptap
                  border="1px solid red"
                  content={profileText}
                  w="100%"
                  withTypographyExtension={true}
                  withLinkExtension={true}
                  onChange={onChangeProfileTextHandler}
                  editorLabel={t('notes.addOrUpdateForm.description')}
                  area="Organization"
                  areaId={currentOrganization.id}
                  container={'private'}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onImgAdded={() => {}}
                />
              </Box>
            </Flex>
            <Button type="submit" variant="primary" h={10} mt={10}>
              {t('providers.profile.updateProfileData')}
            </Button>
            <Text fontStyle="italic">
              {t('accounts.providers.profile.viewPreviewText')}
            </Text>
          </Flex>
        </form>
      </Flex>

      {isSubmitting && <Spinner />}
    </Flex>
  )
}
export default AddOrEditOrganizationProfile
