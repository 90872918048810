import { useCallback, useState } from 'react'
import { debounce, filter } from 'lodash'
import { Helmet } from 'react-helmet-async'
import { useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'
import {
  Article,
  Tag,
  UpdateArticleStatusRequestData,
  UpdateAuthorRequestData,
} from '@contracts/support'

import {
  updateArticleByValue,
  updateArticleStatus,
  useArticle,
} from '../../apiClients/articlesApiClient'
import log from '../../common/log'
import Input from '../../components/ui/Input'
import Spinner from '../../components/Spinner'
import Tiptap from '../../components/richTextEditor/Tiptap'
import IconButton from '../../components/ui/IconButton'
import TextPane from '../../components/richTextEditor/TextPane'
import { formatHtml } from '../../common/richTextHelpers'
import ArticleTemplate1 from '../../components/articleTemplates/ArticleTemplate1'
import AddTagButton from '../../components/ui/AddTagButton'
import TagBadge from '../../components/tags/TagBadge'
import { getDateStrFromDate } from '../../common/util'
import ImgPickerAdvanced from '../../components/ui/ImgPickerAdvanced'
import { getStatus } from '../../components/articles/articleUtils'

const AddOrEditArticle: React.FC<{
  article: Article
}> = ({ article: articleToEdit }) => {
  const [article, setArticle] = useState(articleToEdit)
  const [publishedAt, setPublishedAt] = useState(article.publishedAt)
  const { mutate } = useArticle(articleToEdit.id.toString())

  const [ingressHtmlContent, setIngressHtmlContent] = useState(article.intro)
  const [bodyTextHtmlContent, setBodyTextHtmlContent] = useState(
    article.content,
  )

  const { t } = useTranslation()
  const debouncedApiCall = useCallback(
    debounce((prop, value) => updateArticleHandler(prop, value), 2000),
    [],
  )
  const updateIngressHandler = (text: string) => {
    debouncedApiCall('intro', text)
    setIngressHtmlContent(text)
  }
  const updateBodyTextHandler = (text: string) => {
    debouncedApiCall('content', text)
    setBodyTextHtmlContent(text)
  }

  const updateArticleHandler = (inputProp: string, inputValue: string) => {
    updateArticleByValue(article.id, { [inputProp]: inputValue })
      .then(res => {
        log.info(`Successfully updated article field "${inputProp}".`, res)
        setArticle(res)
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update article')
      })
  }

  const updateStatusHandler = (inputProp: string, inputValue: string) => {
    let requestData: UpdateArticleStatusRequestData = {
      status: 'Draft',
      publishedAt: null,
    }

    if (inputProp === 'status') {
      switch (inputValue) {
        case 'Draft': {
          requestData = {
            status: 'Draft',
            // Flush published date
            publishedAt: null,
          }
          break
        }
        case 'Review': {
          requestData = {
            status: 'Review',
            // Flush published date
            publishedAt: null,
          }
          break
        }
        case 'Published': {
          requestData = {
            status: 'Done',
            publishedAt: new Date().getTime(),
          }
          break
        }
        case 'NotPublished': {
          requestData = {
            status: 'Done',
            // Flush published date
            publishedAt: null,
          }
          break
        }
        default:
          break
      }
    } else if (inputProp === 'publishedAt') {
      requestData = {
        status: 'Done',
        publishedAt: new Date(inputValue).getTime(),
      }
    } else {
      return
    }

    updateArticleStatus(article.id, requestData)
      .then(() => setPublishedAt(requestData.publishedAt))
      .catch(e => {
        log.error({ err: e }, 'Failed to update article status')
      })
  }

  const [status] = useState(getStatus(article))

  const updateAuthorHandler = (inputProp: string, inputValue: string) => {
    const author: UpdateAuthorRequestData = {
      ...article.author,
      [inputProp]: inputValue,
    }
    updateArticleByValue(article.id, { author })
      .then(res => {
        log.info(`Successfully updated article field "${inputProp}".`, res)
        setArticle(res)
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update author of article')
      })
  }

  const removeTagHandler = (tagId: string) => {
    updateArticleByValue(article.id, {
      tags: filter(article.tags, tag => {
        return tag.id !== tagId
      }),
    })
      .then(res => {
        setArticle(res)
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update tags on article')
      })
  }

  const editTagHandler = (tags: Tag[]) => {
    updateArticleByValue(article.id, { tags: tags })
      .then(res => {
        log.info(`Successfully updated article field tags.`, res)
        setArticle(res)
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update article')
      })
  }

  return (
    <Box>
      <Helmet>
        <title>
          {t('articles.editArticle', {
            articleId: article.id,
            heading: article.heading,
          })}
        </title>
      </Helmet>
      <Box>
        <Link to={'/admin/artiklar'}>
          <IconButton
            buttonType="BACK"
            size="sm"
            label={t('articles.backToArticleList')}
            onClick={() => {
              void mutate()
            }}
          />
        </Link>
      </Box>
      <Box textStyle="h3" ml={4} mt={4}>
        <Text>
          {t('articles.editArticle', {
            articleId: article.id,
            heading: article.heading,
          })}
        </Text>
      </Box>
      <Flex
        flexDir="row"
        justifyContent="left"
        minW={20}
        w="80vw"
        pr="300px"
        flexWrap="wrap"
        p={4}
      >
        <Box minW="30rem" maxW="40rem" m={4}>
          <Input
            title={t('articles.heading')}
            name="heading"
            defaultValue={article.heading}
            type="text"
            isMandatory={true}
            position="single"
            background="white"
            onBlur={e => {
              updateArticleHandler('heading', e.target.value)
            }}
          />
          <Box
            mt={5}
            py={3}
            px={4}
            border="1px"
            borderRadius="xl"
            borderColor="gray.300"
            bg="white"
          >
            <Flex flexDir="row" justifyContent="space-between">
              <Text textStyle="inputHeader">{t('tags.manageTags')}</Text>
              <AddTagButton
                variant="ICON"
                article={article}
                updateCallbackHandler={editTagHandler}
              />
            </Flex>
            <Box>
              {article.tags.length > 0 &&
                article.tags.map(tag => {
                  return (
                    <TagBadge
                      tag={tag}
                      key={tag.id}
                      isEditable={true}
                      mb={2}
                      onRemoveTag={removeTagHandler}
                    />
                  )
                })}
              {article.tags && article.tags.length === 0 && (
                <Text>{t('tags.noTagsSet')}</Text>
              )}
            </Box>
          </Box>
          <Flex
            flexDir="column"
            mt={5}
            py={3}
            px={4}
            border="1px"
            borderRadius="xl"
            borderColor="gray.300"
            bg="white"
          >
            <Text textStyle="inputHeader">{t('articles.manageStatus')}</Text>
            <Flex flexDir="row" justifyContent="space-around">
              <RadioGroup
                defaultValue={status}
                onChange={nextValue => {
                  updateStatusHandler('status', nextValue)
                }}
              >
                <Flex flexDir="row" mt={4}>
                  <Flex flexDir="column">
                    <Radio name="status" value="Draft">
                      {t('articles.statusValues.Draft')}
                    </Radio>
                    <Radio name="status" value="Review">
                      {t('articles.statusValues.Review')}
                    </Radio>
                  </Flex>
                  <Flex flexDir="column" mx={10}>
                    <Radio name="status" value="NotPublished">
                      {t('articles.statusValues.DoneNotPublished')}
                    </Radio>
                    <Radio name="status" value="Published">
                      {t('articles.statusValues.DonePublished')}
                    </Radio>
                  </Flex>
                </Flex>
              </RadioGroup>

              <Input
                title={t('articles.published')}
                name="heading"
                defaultValue={
                  publishedAt
                    ? getDateStrFromDate(new Date(publishedAt))
                    : undefined
                }
                type="date"
                isMandatory={false}
                position="single"
                background="white"
                onBlur={e => {
                  updateStatusHandler('publishedAt', e.target.value)
                }}
              />
            </Flex>
          </Flex>
        </Box>
        <Flex flexDir="column" m={4} minW="30rem" maxW="40rem">
          <Input
            title={t('articles.authorName')}
            name="author.name"
            type="text"
            defaultValue={article.author.name}
            isMandatory={false}
            position="top"
            background="white"
            onBlur={e => {
              if (e.target.value !== article.author.name) {
                updateAuthorHandler('name', e.target.value)
              }
            }}
          />

          <Input
            title={t('articles.authorTitle')}
            name="author.title"
            type="text"
            defaultValue={article.author.title}
            isMandatory={false}
            position="middle"
            background="white"
            onBlur={e => {
              if (e.target.value !== article.author.title) {
                updateAuthorHandler('title', e.target.value)
              }
            }}
          />

          <Input
            title={t('articles.authorCompany')}
            name="author.company"
            type="text"
            defaultValue={article.author.company}
            isMandatory={false}
            position="middle"
            background="white"
            onBlur={e => {
              if (e.target.value !== article.author.company) {
                updateAuthorHandler('company', e.target.value)
              }
            }}
          />
          <Input
            title={t('articles.authorProfileLink')}
            name="author.profileImg"
            type="text"
            isMandatory={false}
            defaultValue={article.author.profileImg}
            position="bottom"
            background="white"
            onBlur={e => {
              if (e.target.value !== article.author.profileImg) {
                updateAuthorHandler('profileImg', e.target.value)
              }
            }}
          />
        </Flex>
        <Flex flexDir="column" ml={4} mt={4}>
          <ImgPickerAdvanced
            mb={2}
            inputTitle={t('articles.imgSmall')}
            earlierValue={article.attachmentIdSmall}
            updateHandler={updateArticleHandler}
            inputCallbackName="attachmentIdSmall"
          />
          <ImgPickerAdvanced
            mb={2}
            inputTitle={t('articles.imgMedium')}
            earlierValue={article.attachmentIdMedium}
            updateHandler={updateArticleHandler}
            inputCallbackName="attachmentIdMedium"
          />
          <ImgPickerAdvanced
            inputTitle={t('articles.imgLarge')}
            earlierValue={article.attachmentIdLarge}
            updateHandler={updateArticleHandler}
            inputCallbackName="attachmentIdLarge"
          />
        </Flex>
        <Flex flexDir="row" flexWrap="wrap">
          <Box w="100%" maxW="container.lg" pt={8} mr={6}>
            <Tiptap
              content={article.intro}
              w="100%"
              toolbarVariant="extended"
              withTypographyExtension={true}
              withLinkExtension={true}
              onChange={updateIngressHandler}
              editorLabel={t('articles.ingressText')}
              area="Article"
              areaId={article.id.toString()}
              container="public"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onImgAdded={() => {}}
            />
            <TextPane
              text={formatHtml(ingressHtmlContent)}
              textStyle="textSmall"
              background="gray.100"
              pt={6}
              border="1px"
              borderColor="gray.400"
            />
            <Box>
              <Tiptap
                content={article.content}
                w="100%"
                toolbarVariant="extended"
                withTypographyExtension={true}
                withLinkExtension={true}
                onChange={updateBodyTextHandler}
                editorLabel={t('articles.bodyText')}
                area="Article"
                areaId={article.id.toString()}
                container="public"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onImgAdded={() => {}}
              />
            </Box>
            <TextPane
              text={formatHtml(bodyTextHtmlContent)}
              textStyle="textSmall"
              background="gray.100"
              pt={6}
              border="1px"
              borderColor="gray.400"
            />
          </Box>
          <Box border="1px solid gray" maxW="container.xl" mt={16}>
            {article && (
              <ArticleTemplate1
                articleId={article.id.toString()}
                w="container.xl"
              />
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

const AddOrEditArticleLoader: React.FC = () => {
  const { articleId } = useParams()
  const { data: article } = useArticle(articleId, true)
  if (!article) {
    return <Spinner />
  }
  return <AddOrEditArticle article={article} />
}

export default AddOrEditArticleLoader
