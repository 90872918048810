import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { AccountTypeName } from '@contracts/misc'
import AuthContext from '../../common/auth-context'
import { login } from '../../apiClients/accountsApiClient'
import Input from '../ui/Input'
import { PATH_MY_PRIVATE_PAGES, PATH_MY_PROVIDER_PAGES } from '../../common/nav'
import Spinner from '../Spinner'
import ForgotPassword from './ForgotPassword'
import { LOG_IN_PATH } from './constants'

const LoginForm2: React.FC<{
  onClose?: () => void
  toggleForm?: () => void
  isOnlyLogin?: boolean
  forwardUri?: string
  accountType?: AccountTypeName
  subscription?: string
  currentUserEmail: string
  setCurrentUserEmail: Dispatch<SetStateAction<string>>
}> = ({
  onClose,
  toggleForm,
  isOnlyLogin = false,
  forwardUri,
  accountType = 'Consumer',
  subscription,
  currentUserEmail,
  setCurrentUserEmail,
}) => {
  const applicationInsights = useAppInsightsContext()
  const email = currentUserEmail ?? ''
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false)
  const [isError, setIsError] = useState(false)

  const authCtx = useContext(AuthContext)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const noValidSession = searchParams.has('noValidSession')

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setIsError(false)
    }
    // setEmail(e.currentTarget.value)
    setCurrentUserEmail(e.currentTarget.value)
  }
  const pwdChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setIsError(false)
    }
    setPassword(e.currentTarget.value)
  }

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)

    await login(email, password, accountType)
      .then(currentUser => {
        authCtx.setCurrentUser(currentUser)

        const default_forward_uri =
          accountType === 'Provider'
            ? PATH_MY_PROVIDER_PAGES
            : PATH_MY_PRIVATE_PAGES

        navigate(
          forwardUri &&
            forwardUri !== '/' &&
            !forwardUri.startsWith(LOG_IN_PATH)
            ? forwardUri
            : default_forward_uri,
        )
        applicationInsights.trackEvent(
          { name: 'User logged in' },
          {
            email: currentUser.email,
            isAdmin: currentUser.accountTypes?.includes('Admin'),
            isConsumer: currentUser.accountTypes?.includes('Consumer'),
            isProvider: currentUser.accountTypes?.includes('Provider'),
          },
        )
        applicationInsights.trackEvent(
          { name: 'Subscription chosen' },
          { subcription: subscription },
        )
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (isForgotPassword) {
    return (
      <ForgotPassword
        initialEmail={email}
        accountType={accountType}
        onClose={() => {
          setIsForgotPassword(false)
          if (onClose) {
            onClose()
          }
        }}
      />
    )
  }

  return (
    <form
      onSubmit={e => {
        void submitHandler(e)
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box mb={1} ml={4} textStyle="errorSmall">
          {isError && accountType === 'Consumer' && (
            <p>{t('auth.wrongUsernameOrPasswordPrivate')}</p>
          )}
          {isError && accountType === 'Provider' && (
            <p>{t('auth.wrongUsernameOrPasswordProvider')}</p>
          )}
          {noValidSession && <p>{t('auth.loginToAccessPage')}</p>}
        </Box>
        <Input
          title={
            accountType === 'Consumer'
              ? t('auth.consumerEmail')
              : t('auth.providerEmail')
          }
          autoComplete="username"
          isMandatory={true}
          name="email"
          type="email"
          value={email}
          onChange={emailChangeHandler}
          position="top"
        />
        <Input
          title={t('auth.password')}
          autoComplete="current-password"
          isMandatory={true}
          name="password"
          type="password"
          value={password}
          onChange={pwdChangeHandler}
          position="bottom"
        />
        <Button type="submit" mt={4} mb={1} variant="primary">
          {t('auth.login')}
        </Button>

        {!isOnlyLogin && (
          <Flex flexDir="column">
            <Flex
              maxW={36}
              onClick={() => setIsForgotPassword(true)}
              color="logo_purple"
              _hover={{ cursor: 'pointer', color: 'darkgray' }}
            >
              {t('auth.forgotPassword')}
            </Flex>

            <Flex
              textAlign="center"
              textStyle=""
              mt={4}
              justifyContent="center"
              gap={1}
            >
              <Text>{t('auth.doYouNotHaveAnAccount2')}</Text>
              <Text
                color="logo_purple"
                onClick={() => {
                  toggleForm ? toggleForm() : ''
                }}
                _hover={{ cursor: 'pointer', color: 'darkgray' }}
              >
                {t('auth.registerForBerikar2')}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>

      {isLoading && <Spinner />}
    </form>
  )
}
export default LoginForm2
