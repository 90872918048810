import { CheckCircleIcon } from '@chakra-ui/icons'
import { Circle } from '@chakra-ui/react'
import { HelpRequestResponse } from '@contracts/misc'
import { BsFillArrowRightCircleFill, BsFillXCircleFill } from 'react-icons/bs'

const HelpRequestStatusIcon: React.FC<{ helpReq: HelpRequestResponse }> = ({
  helpReq,
}) => {
  if (helpReq.providerStatus === 'New') {
    return <Circle size={5} bg="transparent" border="1px solid lightgray" />
  }
  if (helpReq.providerStatus === 'Draft') {
    return <BsFillArrowRightCircleFill color="lightgray" size={22} />
  }
  if (
    helpReq.providerStatus === 'Accepted' ||
    helpReq.consumerStatus === 'Accepted'
  ) {
    return <CheckCircleIcon color="green" fontSize="xl" />
  }
  if (
    helpReq.providerStatus === 'Rejected' ||
    helpReq.consumerStatus === 'Rejected'
  ) {
    return <BsFillXCircleFill color="red" size={22} />
  }

  return <BsFillArrowRightCircleFill color="#CF8723" size={22} />
}
export default HelpRequestStatusIcon
