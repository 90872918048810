import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
  ACCOUNT_PROFILE_IMAGE_PREFIX,
  PROJECT_PROFILE_BACKGROUND_IMAGE_PREFIX,
  PROJECT_PROFILE_IMAGE_PREFIX,
  PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX,
  PROPERTY_PROFILE_IMAGE_PREFIX,
  PUBLIC_HOME_INTRO_LAPTOP_COL12,
  PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW1,
  PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW2,
  PUBLIC_HOME_INTRO_MOBILE,
  PUBLIC_HOME_INTRO_TABLET_COL2_ROW1,
  PUBLIC_HOME_INTRO_TABLET_COL2_ROW2,
} from '../../pages/constants'
import Heading from '../ui/Heading'

const ListAttachmentsPrefixes: React.FC<{
  mt?: number | string
  mx?: number | string
}> = ({ mt, mx }) => {
  return (
    <Flex flexDir="column" mt={mt} mx={mx}>
      <Heading textStyle="h6">List of prefixes used</Heading>
      <Table mb={20} variant="striped" colorScheme="gray" maxW="container.md">
        <Thead>
          <Tr>
            <Th>Key</Th>
            <Th>Prefix value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>ACCOUNT_PROFILE_IMAGE_PREFIX</Td>
            <Td>{ACCOUNT_PROFILE_IMAGE_PREFIX}</Td>
          </Tr>
          <Tr>
            <Td>PROPERTY_PROFILE_IMAGE_PREFIX</Td>
            <Td>{PROPERTY_PROFILE_IMAGE_PREFIX}</Td>
          </Tr>
          <Tr>
            <Td>PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX</Td>
            <Td>{PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX}</Td>
          </Tr>
          <Tr>
            <Td>PROJECT_PROFILE_IMAGE_PREFIX</Td>
            <Td>{PROJECT_PROFILE_IMAGE_PREFIX}</Td>
          </Tr>
          <Tr>
            <Td>PROJECT_PROFILE_BACKGROUND_IMAGE_PREFIX</Td>
            <Td>{PROJECT_PROFILE_BACKGROUND_IMAGE_PREFIX}</Td>
          </Tr>
          <Tr>
            <Td>Inspiration Page Images</Td>
            <Td>'$inspirationPage[index]_'</Td>
          </Tr>
          <Tr>
            <Td>Guidance Page Images</Td>
            <Td>$guidancePageRow[One|Two|Three]_[index]_</Td>
          </Tr>
        </Tbody>
      </Table>

      <Heading textStyle="h6">
        Prefixbilder publik landningssida - översta introsektionen
      </Heading>
      <Table mb={20} variant="striped" colorScheme="gray" maxW="container.md">
        <Thead>
          <Tr>
            <Th>Key</Th>
            <Th>Prefix value</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_MOBILE</Td>
            <Td>{PUBLIC_HOME_INTRO_MOBILE}</Td>
          </Tr>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_TABLET_COL2_ROW1</Td>
            <Td>{PUBLIC_HOME_INTRO_TABLET_COL2_ROW1}</Td>
          </Tr>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_TABLET_COL2_ROW2</Td>
            <Td>{PUBLIC_HOME_INTRO_TABLET_COL2_ROW2}</Td>
          </Tr>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_LAPTOP_COL12</Td>
            <Td>{PUBLIC_HOME_INTRO_LAPTOP_COL12}</Td>
          </Tr>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW1</Td>
            <Td>{PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW1}</Td>
          </Tr>
          <Tr>
            <Td>PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW2</Td>
            <Td>{PUBLIC_HOME_INTRO_LAPTOP_COL4_ROW2}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Flex>
  )
}
export default ListAttachmentsPrefixes
