import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

const TextCouple: React.FC<{
  firstColumnWidth?: number
  secondColumnWidth?: number
  title: string | undefined
  value: string | undefined | ReactNode
}> = ({ firstColumnWidth, secondColumnWidth, title, value }) => {
  return (
    <Flex fontWeight="normal">
      <Text w={firstColumnWidth} color="primary_grey04">
        {title}
      </Text>
      <Box w={secondColumnWidth}>{value}</Box>
    </Flex>
  )
}
export default TextCouple
