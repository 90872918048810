import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useProperty } from '../../apiClients/propertiesApiClient'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import ListCollaborators from '../collaborators/ListCollaborators'
import { useNoteParts } from '../notes/notesHelper'
import NotesListView from '../notes/NotesListView'
import ProjectsByPropertyCard from '../projects/ProjectsByPropertyCard'
import MyMessageSection from '../sections/portal/MyMessageSection'

import ExpandableSectionCard from '../ui/ExpandableSectionCard'

const ProjectAdminSummaryCard: React.FC<{
  propertyId: string
  mr?: number
  mt?: number
  headerBg?: string
}> = ({ propertyId, mr, mt }) => {
  const { t } = useTranslation()
  const { data: property } = useProperty(propertyId)
  const { collaborators } = useCollaboratorParts({
    propertyId: propertyId,
  })
  const { notes } = useNoteParts({ propertyId: propertyId })
  const { data: conversation } = useConversationsByArea('Property', [
    propertyId,
  ])

  return (
    <Flex flexDir="column" mr={mr} mt={mt} pb={4} gap={4}>
      {property && (
        <ProjectsByPropertyCard
          w="container.lg"
          propertyName={property.name}
          propertyId={property.id}
          headerBg="transparent"
        />
      )}
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.collaborators')} (${collaborators?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <ListCollaborators property={property} variant="link" />
      </ExpandableSectionCard>
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.propertyNotes')} (${notes?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <NotesListView propertyId={propertyId} />
      </ExpandableSectionCard>
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.messages')} (${conversation?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <MyMessageSection
          area="Property"
          areaId={propertyId}
          areaFriendlyName={property?.name ?? ''}
          areaFriendlyParentName={''}
          parentArea={'Property'}
          parentAreaId={''}
          // grandParentArea={'Property'}
          // grandParentAreaId={''}
          conversationArea="Property"
        />
      </ExpandableSectionCard>
    </Flex>
  )
}
export default ProjectAdminSummaryCard
