import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  ResponsiveValue,
} from '@chakra-ui/react'

import { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import Heading from '../ui/Heading'
import Divider from '../ui/Divider'
import { useProjects } from '../../apiClients/projectsApiClient'
import AuthContext from '../../common/auth-context'
import { getLastLogin } from '../../common/util'
import ProjectUpdateSummaryCard from './ProjectUpdatesSummaryCard'
import ProjectUpdatesHeader from './ProjectUpdatesHeader'

const ProjectUpdatesSummarySection: React.FC<{
  px?: ResponsiveValue<number | string>
  mt?: number | string
}> = ({ px, mt }) => {
  const { t } = useTranslation()
  const { data: projectsForAccount } = useProjects()
  const { currentUser } = useContext(AuthContext)

  if (!projectsForAccount) {
    return null
  }
  return (
    <Flex
      flexDir="column"
      wrap="wrap"
      px={px}
      mt={mt}
      borderRadius="lg"
      // border="1px solid red"
    >
      <Heading textStyle="h8" my={2}>
        {t('projects.latestUpdatesSummary.heading', {
          date: getLastLogin(currentUser?.lastLoginAt),
        })}
      </Heading>
      <Divider my={0} mx={0} h={0} orientation="horizontal" />
      {/* <Flex flexDir="column" rowGap={2} my={4} border="1px solid green"> */}
      {/* <Flex flexDir="column" rowGap={2} my={4}> */}
      <Accordion allowMultiple p={0}>
        {projectsForAccount &&
          projectsForAccount.map(project => {
            return (
              <AccordionItem key={project.id}>
                <AccordionButton>
                  <ProjectUpdatesHeader project={project} />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} px={{ xxs: 0, tablet: 3, laptop: 5 }}>
                  <ProjectUpdateSummaryCard project={project} />
                </AccordionPanel>
              </AccordionItem>
            )
          })}
      </Accordion>
      {/* </Flex> */}
    </Flex>
  )
}
export default ProjectUpdatesSummarySection
