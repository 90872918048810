import { Box, Button, Flex, Image } from '@chakra-ui/react'
import { useFilePicker } from 'use-file-picker'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { ContextArea } from '@contracts/support'
import {
  addAttachment,
  useAttachments,
} from '../../apiClients/attachmentsApiClient'
import { Icon } from '../ui/Icon'
import log from '../../common/log'
import {
  IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
  IMG_PICKER_MAX_FILESIZE,
  // IMG_PICKER_MAX_NO_FILES,
  IMG_PICKER_MIN_NO_FILES,
} from '../../pages/constants'
import Spinner from '../Spinner'
import { compressFile } from './attachmentHelper'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ImgPicker: React.FC<{
  onCloseModal: () => void
  imgChangeHandler: (inputProp: string, inputValue: string) => void
  inputCallbackName?: string
  // ContextArea: 'Article' | 'Account' | 'Property' | 'Project' | 'Note'
  ContextArea: ContextArea
  attachmentPrefix?: string
  variant?: 'LIGHT' | 'MEDIUM' | 'FULL' | 'NO-DIALOG'
}> = ({
  onCloseModal,
  imgChangeHandler,
  inputCallbackName,
  ContextArea,
  attachmentPrefix,
  variant = 'MEDIUM',
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { data: allImgs } =
    variant !== 'LIGHT' && ContextArea
      ? useAttachments([ContextArea], attachmentPrefix)
      : { data: undefined }

  const [hasImage, setHasImage] = useState(false)

  useEffect(() => {
    let ignore = false

    if (!ignore && !hasImage) {
      openFileSelector()
    }
    return () => {
      ignore = true
    }
  }, [])

  const [openFileSelector, { filesContent, loading, errors, plainFiles }] =
    useFilePicker({
      multiple: true,
      readAs: 'DataURL',
      accept: IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
      limitFilesConfig: {
        min: IMG_PICKER_MIN_NO_FILES,
        // max: IMG_PICKER_MAX_NO_FILES,
        max: 5,
      },
      maxFileSize: IMG_PICKER_MAX_FILESIZE,
    })

  if (!filesContent.length) {
    if (!hasImage) {
      setHasImage(true)
    }
  }
  const imgPickedHandler = (imgId: string) => {
    imgChangeHandler(inputCallbackName ?? '', imgId)
    onCloseModal()
  }

  const updateImgHandler = () => {
    setIsLoading(true)
    // eslint-disable-next-line no-console
    console.log('444 - plainFiles: ', plainFiles)
    compressFile(plainFiles[0], 's', persistFile)
  }

  const persistFile = (file: File) => {
    const formData = new FormData()
    formData.set('image', file)
    formData.set('area', 'Property')
    addAttachment(formData, true)
      .then(attachment => {
        imgPickedHandler(attachment.id)
      })
      .catch(e => {
        log.error({ err: e }, 'Error saving attachment')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getErrorsIfAny = () => {
    if (errors.length) {
      return (
        <Box pl={2} pt={2} color="red">
          {errors[0].fileSizeTooSmall &&
            t('attachements.fileErrors.fileTooSmall')}
          {errors[0].fileSizeToolarge &&
            t('attachements.fileErrors.fleTooLarge')}
          {errors[0].readerError && t('attachements.fileErrors.readError')}
          {errors[0].maxLimitExceeded &&
            t('attachements.fileErrors.maxLimitExceeded')}
          {errors[0].minLimitNotReached &&
            t('attachements.fileErrors.minLimitNotReached')}
        </Box>
      )
    }
    return
  }

  if (loading) {
    return <Box>{t('portal.loading')}</Box>
  }

  return (
    <>
      <Box
        h={14}
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('general.chooseImage')}</Box>
      </Box>
      <Box>{getErrorsIfAny()}</Box>
      <Flex flexDir="row" flexWrap="wrap" mx={4} mt={6} mb={2}>
        <Flex
          key="upload"
          flexDir="row"
          alignItems="center"
          border="1px"
          borderColor="gray.300"
          borderRadius="lg"
          p={0.5}
          m={1}
          mb={2}
          h={32}
          w={32}
          _hover={{
            border: '5px solid',
            borderColor: 'primary',
            p: 0,
          }}
          onClick={() => {
            openFileSelector()
          }}
          justifyContent="center"
        >
          <Icon
            name="FcAddImage"
            h={60}
            onClick={() => {
              openFileSelector()
            }}
          />
        </Flex>
        {allImgs?.map(img => {
          return (
            <Flex
              key={img.id}
              flexDir="row"
              alignItems="center"
              border="1px"
              borderColor="gray.300"
              borderRadius="lg"
              p={0.5}
              m={1}
              h={32}
              w={32}
              justifyContent="center"
              _hover={{
                border: '5px solid',
                borderColor: 'primary',
                p: 0,
              }}
              onClick={() => {
                imgPickedHandler(img.id)
              }}
            >
              <Image
                src={`${baseUrl}${img.id}`}
                alignSelf="center"
                borderRadius="lg"
              />
            </Flex>
          )
        })}
      </Flex>
      {!!filesContent.length && (
        <Flex
          mx={8}
          mb={{ xxs: 32, tablet: 6 }}
          justifyContent="space-between"
          alignItems="end"
        >
          <Flex
            flexDir="row"
            alignItems="center"
            borderRadius="lg"
            m={1}
            h={32}
            w={32}
            justifyContent="center"
            border="5px solid"
            borderColor="primary"
            p={0}
            onClick={() => {
              updateImgHandler()
            }}
          >
            <Image
              h="120px"
              w="120px"
              fit="cover"
              overflow="clip"
              src={filesContent[0].content}
              alignSelf="center"
              borderRadius="lg"
            />
          </Flex>

          <Button
            type="submit"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            onClick={updateImgHandler}
            isDisabled={isLoading ? true : false}
          >
            {t('auth.update')}
          </Button>
          {isLoading && <Spinner />}
        </Flex>
      )}
    </>
  )
}
export default ImgPicker
