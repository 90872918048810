import { useContext } from 'react'
import { Flex, HStack, Show, Text, useDisclosure } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AreaContext } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import LatestNotesSection from '../../components/properties/LatestNotesSection'
import ListProjectsSection from '../../components/properties/ListProjectsSection'
import ListPropertiesSection from '../../components/properties/ListPropertiesSection'
import HowBerikarWorksConsumerSection from '../../components/sections/HowBerikarWorksConsumerSection'
import WelcomeNewUserSection from '../../components/sections/portal/WelcomeNewUserSection'
import PromoSectionProducts from '../../components/sections/PromoSectionProducts'
import { useProperties } from '../../apiClients/propertiesApiClient'
import { Heading } from '../../ui'
import AddPropertyButton from '../../components/ui/AddPropertyButton'
import AddProjectButton from '../../components/ui/AddProjectButton'
import Modal from '../../components/ui/Modal'
import NoteCardMedium from '../../components/notes/NoteCardMedium'
import { useNote as usePropertyNotesById } from '../../apiClients/propertiesApiClient/notes'
import { useNote as useProjectNotesById } from '../../apiClients/projectsApiClient/notes'
import Alert from '../../components/ui/Alert'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'
import ProjectUpdatesSummarySection from '../../components/projects/ProjectUpdatesSummarySection'

const HomePortal: React.FC<{ targetArea?: 'property' | 'project' }> = ({
  targetArea,
}) => {
  const { id: noteId } = useParams()
  const { currentUser } = useContext(AuthContext)
  const { data: properties } = useProperties()

  const { t } = useTranslation()
  const px = { xxs: 2, tablet: '2%', desktop: '3%', laptop: '5%' }
  const { isOpen: isOpenNoteCardMedium, onClose: onCloseNoteCardMedium } =
    useDisclosure({ defaultIsOpen: true })
  const { data: propertyNote } = usePropertyNotesById(
    targetArea === 'property' ? noteId : undefined,
  )
  const { data: projectNote } = useProjectNotesById(
    targetArea === 'project' ? noteId : undefined,
  )

  if (!properties?.length) {
    return (
      <>
        <Helmet>
          <title>Inloggad startsida</title>
        </Helmet>
        <WelcomeNewUserSection
          userName={currentUser?.firstName || ''}
          mx={px}
        />
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Inloggad startsida</title>
        </Helmet>

        {noteId && targetArea && !projectNote && !propertyNote && (
          <Alert
            variant="Warning"
            title={t('notes.invalidLinkTitle')}
            message={
              targetArea === 'property'
                ? t('notes.invalidPropertyNoteLink')
                : t('notes.invalidProjectNoteLink')
            }
            forwardUri={PATH_MY_PRIVATE_PAGES}
            borderRadius="lg"
            mx={px}
            mt="20"
            mb={0}
            py={0}
          />
        )}
        <Flex flexDir="column">
          <HStack spacing={4} mt={16} textAlign="left" px={px} mb={4}>
            <Heading textStyle="h2">
              {t('content.portalHome.welcomeBackUser', {
                name: currentUser?.firstName,
              })}
            </Heading>
          </HStack>
          <Text px={px} textStyle="textLarge" fontWeight="light">
            {t('content.portalHome.greetingsPhrase')}
          </Text>

          <ProjectUpdatesSummarySection px={px} mt={8} />

          <AreaContext.Provider value={{ area: 'property' }}>
            <Heading
              textStyle="h6Fixed"
              color="logo_green01"
              isBold
              px={px}
              mb={2}
              mt={6}
            >
              {t('general.latestPropertyNotes')}
            </Heading>
            <LatestNotesSection px={px} maxNoNotes={6} />
            {propertyNote && targetArea === 'property' && (
              <Modal
                isOpen={isOpenNoteCardMedium}
                onClose={onCloseNoteCardMedium}
                size="lg"
              >
                <NoteCardMedium
                  note={propertyNote}
                  propertyId={propertyNote.entityId}
                  onClose={onCloseNoteCardMedium}
                />
              </Modal>
            )}
          </AreaContext.Provider>
          <AreaContext.Provider value={{ area: 'project' }}>
            <Heading
              textStyle="h6Fixed"
              color="logo_green01"
              isBold
              px={px}
              mb={2}
              mt={6}
            >
              {t('general.latestProjectNotes')}
            </Heading>

            <LatestNotesSection px={px} />

            {projectNote && targetArea === 'project' && (
              <Modal
                isOpen={isOpenNoteCardMedium}
                onClose={onCloseNoteCardMedium}
                size="lg"
              >
                <NoteCardMedium
                  note={projectNote}
                  projectId={projectNote.entityId}
                  onClose={onCloseNoteCardMedium}
                />
              </Modal>
            )}
          </AreaContext.Provider>
          <AreaContext.Provider value={{ area: 'project' }}>
            <Show below="laptop">
              <Flex
                flexDir="row"
                alignItems="center"
                px={px}
                mb={2}
                mt={6}
                gap={2}
              >
                <Heading textStyle="h6Fixed" color="logo_green01" isBold>
                  {t('general.myProperties')}
                </Heading>
                <AddPropertyButton variant="icon" />
              </Flex>
              <ListPropertiesSection px={{ xxs: '3%', tablet: '5%' }} />

              <Flex
                flexDir="row"
                alignItems="center"
                px={px}
                mb={2}
                mt={10}
                gap={2}
              >
                <Heading textStyle="h6Fixed" color="logo_green01" isBold>
                  {t('general.myProjects')}
                </Heading>
                <AddProjectButton variant="ICON" properties={properties} />
              </Flex>
              {/* <ListProjectsSection px={{ xxs: '3%', tablet: '5%' }} /> */}
              <ListProjectsSection px={px} />
            </Show>
          </AreaContext.Provider>
          {/* <PromoSectionProducts px="5%" py={20} mt={20} /> */}
          <PromoSectionProducts px={px} py={20} mt={20} />
          <HowBerikarWorksConsumerSection bg="white" />
        </Flex>
      </>
    )
  }
}

export default HomePortal
