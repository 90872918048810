import { Avatar, Flex, Show, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useAccountInvites } from '../../apiClients/accountsApiClient'
import { useHelpRequests } from '../../apiClients/projectsApiClient/helpRequests'
import OrganizationAvatar from '../accounts/OrganizationAvatar'
import Link from '../ui/Link'
import { MY_HELP_REQUESTS } from '../../common/nav'

const HelpRequestRecipiantsCard: React.FC<{
  helpRequestTemplateId: string
}> = ({ helpRequestTemplateId }) => {
  const { t } = useTranslation()
  const { data: helpRequests } = useHelpRequests([helpRequestTemplateId])
  const { data: invites } = useAccountInvites(
    'HelpRequestTemplate',
    helpRequestTemplateId,
  )
  const organizationIds = _.uniq(
    helpRequests?.map(hr => {
      return hr.organizationId
    }),
  )

  return (
    <Flex flexDir="column" gap={2}>
      <Flex gap={2} wrap="wrap">
        {organizationIds &&
          organizationIds.length > 0 &&
          organizationIds.map(orgId => {
            const helpReqLink = `/${MY_HELP_REQUESTS}`
            return (
              <Flex key={orgId} alignItems="center" gap={4}>
                <Show below="tablet">
                  <OrganizationAvatar
                    organizationId={orgId}
                    variant="avatarAndNameOneRow"
                    h={9}
                  />
                </Show>
                <Show above="tablet">
                  <OrganizationAvatar
                    organizationId={orgId}
                    variant="avatarAndName"
                  />
                </Show>
                <Link to={helpReqLink}>
                  <Flex gap={2} h={10} alignItems="center">
                    <ExternalLinkIcon />
                    <Show above="tablet">
                      <Text textStyle="infoText">Gå till förfrågan</Text>
                    </Show>
                  </Flex>
                </Link>
              </Flex>
            )
          })}
      </Flex>
      <Flex gap={2} wrap="wrap">
        {invites &&
          invites.length > 0 &&
          invites.map(invite => {
            return (
              <Flex alignItems="center" gap={2} key={invite.id}>
                <Avatar size="sm" />
                {invite.email}
                <Text lineHeight={5} key={invite.id} mt={2}></Text>
              </Flex>
            )
          })}
      </Flex>
      {(!organizationIds || organizationIds.length === 0) &&
        (!invites || invites.length === 0) && (
          <Text lineHeight={5}>
            {t('helpRequests.template.hasNotBeenSent')}
          </Text>
        )}
    </Flex>
  )
}
export default HelpRequestRecipiantsCard
