import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ResponsiveValue,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentSummary } from '@contracts/misc'
import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { Attachment } from '@contracts/support'

import AuthContext from '../../common/auth-context'
import AccountAttachmentsMobileTreeViewCard from '../attachments/AccountAttachmentsMobileTreeViewCard'
import { groupBy } from '../attachments/attachmentHelper'
import {
  deleteAttachment,
  // deleteAttachment,
  updateFilename,
  updateMultipleFilenames,
  useMultipleAttachments,
} from '../../apiClients/attachmentsApiClient'
import EditFileCardCompact from '../attachments/EditAttachmentCardCompact'
import EditFileCardCompactVersioned from '../attachments/EditAttachmentsCardCompactVersioned'
// import log from '../../common/log'
import Spinner from '../Spinner'
import FilePickerButton from './FilePickerButton'
import Heading from './Heading'
import Modal from './Modal'
import { Icon } from './Icon'

const FileManager: React.FC<{
  attachmentsJson: AttachmentSummary[]
  onUploadFiles: (newFilesToAdd: File[]) => Promise<void>

  onAddExistingAttachments: (
    attachmentsToAdd: Attachment[] | AttachmentSummary[],
  ) => Promise<void>
  onDeleteAttachments: (attachmentsToDelete: string[]) => void

  mt?: number
  ml?: number
  mb?: number
  mr?: number
  px?: ResponsiveValue<number | string>
  my?: number
  hideButton?: boolean
  hideFileList?: boolean
  variant?: 'downloadOnly' | 'menu'
  buttonW?: ResponsiveValue<string | number>
  mutateParent?: () => Promise<void>
}> = ({
  attachmentsJson,
  onUploadFiles,
  onAddExistingAttachments,
  onDeleteAttachments,
  mt,
  mr,
  mb,
  ml,
  my,
  px,
  hideButton = false,
  hideFileList = false,
  variant = 'downloadOnly',
  buttonW = 64,
  mutateParent,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)

  // const { data: attachments, mutate: mutateAttachments } =
  const { data: attachments, mutate: mutateAttachments } =
    useMultipleAttachments(
      attachmentsJson.map(attachmentSummary => {
        return attachmentSummary.id
      }),
    )

  // eslint-disable-next-line no-console
  // console.log('attachmentsJson: ', attachmentsJson)

  // eslint-disable-next-line no-console
  // console.log('attachments: ', attachments)

  const {
    isOpen: isInfoModalOpen,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()
  const {
    isOpen: isOpenMyFiles,
    onOpen: onOpenMyFiles,
    onClose: onCloseMyFiles,
  } = useDisclosure()

  const removeAttachmentsHandler = (attachmentIds: string[]) => {
    // eslint-disable-next-line no-console
    console.log('remove attachments: ', attachmentIds)
    setIsLoading(true)
    attachmentIds.map(async attachmentId => {
      // Attachment should be deleted as well as
      // attachment in specific context (note, offer, help req template...)

      // eslint-disable-next-line no-console
      // console.log('remove attachment: ', attachmentId)

      await deleteAttachment(attachmentId, true)

      mutateParent ? void mutateParent() : ''
      void mutateAttachments()
    })
    void onDeleteAttachments(attachmentIds)
    setIsLoading(false)
  }

  const addNewFilesHandler = async (newFilesToAdd: File[]): Promise<void> => {
    // eslint-disable-next-line no-console
    // console.log('add new file: ', newFilesToAdd)
    setIsLoading(true)
    await onUploadFiles(newFilesToAdd)
    setIsLoading(false)
  }

  const attachmentNameChangeHandler = async (
    attachmentIdToChangeName: string,
    newFileName: string,
  ) => {
    // eslint-disable-next-line no-console
    console.log('Change file name. New name: ', newFileName)

    const result = await updateFilename(attachmentIdToChangeName, newFileName)
    // eslint-disable-next-line no-console
    console.log('result: ', result)
    await mutateAttachments()
    // mutateParent ? void mutateParent() : ''
  }

  const multipleAttachmentNameChangeHandler = async (
    attachmentIdToChangeName: string[],
    newFileName: string,
    mimeType: string,
  ): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('Change file name, mimeType: ', mimeType)

    await updateMultipleFilenames(attachmentIdToChangeName, newFileName)
    await mutateAttachments()
    // mutateParent ? void mutateParent() : ''
  }

  const addAttachmentsFromMyFilesHandler = (
    listOfAttachmentsToAdd: AttachmentSummary[],
  ) => {
    // eslint-disable-next-line no-console
    // console.log('Add attachments from "My Files": ', listOfAttachmentsToAdd)

    void onAddExistingAttachments(listOfAttachmentsToAdd)
  }

  const { files: groupedFiles, images: groupedImages } = groupBy(
    attachments ?? [],
  )

  return (
    <>
      {!hideFileList && (
        <Flex
          flexDir="column"
          mt={mt}
          mr={mr}
          mb={mb}
          ml={ml}
          my={my}
          px={px}
          // border="1px solid red"
        >
          <Flex
            gap={2}
            alignItems="center"
            onClick={() => {
              onOpenInfoModal()
            }}
          >
            <Heading textStyle="cardHeading">
              {t('attachments.attachedFiles')}
            </Heading>
            <InfoOutlineIcon
              fontSize={14}
              color="logo_orange"
              _hover={{ cursor: 'pointer', color: 'orange' }}
            />
          </Flex>

          {!attachments?.length && (
            <Text textStyle="cardText">{t('attachments.noAttachedFiles')}</Text>
          )}

          {groupedFiles?.map(attachments => {
            if (attachments.length === 1) {
              return (
                <EditFileCardCompact
                  key={attachments[0].id}
                  // variant="attachment"
                  mimeType={attachments[0].mimeType}
                  attachmentId={attachments[0].id}
                  onChangeAttachmentName={attachmentNameChangeHandler}
                  onDeleteAttachments={removeAttachmentsHandler}
                />
              )
            }
            if (attachments.length) {
              return (
                <EditFileCardCompactVersioned
                  attachments={attachments}
                  key={attachments[0].id}
                  onChangeVersionedAttachmentName={
                    multipleAttachmentNameChangeHandler
                  }
                  onDeleteAttachments={removeAttachmentsHandler}
                />
              )
            }
            return null
          })}
          {groupedImages?.map(attachments => {
            if (attachments.length === 1) {
              return (
                <EditFileCardCompact
                  key={attachments[0].id}
                  // variant="attachment"
                  mimeType={attachments[0].mimeType}
                  attachmentId={attachments[0].id}
                  onChangeAttachmentName={attachmentNameChangeHandler}
                  onDeleteAttachments={removeAttachmentsHandler}
                />
              )
            }
            if (attachments.length) {
              return (
                <EditFileCardCompactVersioned
                  attachments={attachments}
                  key={attachments[0].id}
                  onChangeVersionedAttachmentName={
                    multipleAttachmentNameChangeHandler
                  }
                  onDeleteAttachments={removeAttachmentsHandler}
                />
              )
            }
            return null
          })}
        </Flex>
      )}

      {!hideButton && variant === 'downloadOnly' && (
        <FilePickerButton
          variant="BUTTON"
          w="100%"
          mt={4}
          mb={6}
          ContextArea={'Note'}
          label={t('attachments.attachImgOrFile')}
          pickerVariant="NO-DIALOG"
          onUpdateFileList={addNewFilesHandler}
        />
      )}
      {!hideButton && variant === 'menu' && (
        // <Flex px={{ xxs: 2, tablet: 0 }}>
        <Flex px={px}>
          <Menu>
            <MenuButton
              as={Button}
              bg="secondary"
              color="white"
              rightIcon={<ChevronDownIcon boxSize={7} />}
              w={buttonW}
              _hover={{ background: 'secondaryDark' }}
              _active={{ bg: 'secondaryDark' }}
              mt={4}
              mb={6}
              // px={px}
            >
              {/* Bifoga filer */}
              Lägg till filer
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  onOpenMyFiles()
                }}
              >
                <Icon name={'myFiles'} mr={2} />

                <span>Mina filer</span>
              </MenuItem>
              <MenuItem p={0}>
                <FilePickerButton
                  variant="MENU_ITEM"
                  w="100%"
                  ContextArea={'Note'}
                  label={t('attachments.attachImgOrFile')}
                  pickerVariant="NO-DIALOG"
                  onUpdateFileList={addNewFilesHandler}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
      <Modal
        isOpen={isOpenMyFiles}
        onOpen={onOpenMyFiles}
        onClose={onCloseMyFiles}
        px={0}
        py={0}
        size="sm"
      >
        <>
          {currentUser && (
            <AccountAttachmentsMobileTreeViewCard
              account={currentUser}
              isCheckable
              w="100%"
              onCloseModal={onCloseMyFiles}
              callback={addAttachmentsFromMyFilesHandler}
            />
          )}
          {/* <h1>No files found</h1> */}
        </>
      </Modal>
      <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {t('attachments.fileManagerInfoTitle')}
          </Heading>

          <Text textStyle="infoTextLight" fontStyle="italic">
            {t('attachments.fileManagerInfoText')}
          </Text>
        </Flex>
      </Modal>
      {isLoading && <Spinner bottom="50%" />}
    </>
  )
}

export default FileManager
