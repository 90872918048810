import { Text, Tooltip } from '@chakra-ui/react'

const StringShortener: React.FC<{ title: string; maxLength: number }> = ({
  title,
  maxLength,
}) => {
  {
    if (title.length > maxLength) {
      return (
        <Tooltip
          hasArrow
          label={title}
          bg="primaryBackground"
          color="black"
          cursor="pointer"
          placement="top"
        >
          <Text>{title.substring(0, maxLength)}...</Text>
        </Tooltip>
      )
    }

    return <Text>{title}</Text>
  }
}
export default StringShortener
