import { useState } from 'react'
import { Box, Button, Center, Container, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { resetPassword } from '../../apiClients/accountsApiClient'
import Spinner from '../../components/Spinner'
import Alert from '../../components/ui/Alert'
import Input from '../../components/ui/Input'

const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const [newPassword, setNewPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [updatePasswordResult, setUpdatePasswordResult] = useState('')
  const [isBusy, setBusy] = useState(false)

  const email = search.get('email') as string
  const emailVerifyCode = search.get('emailVerifyCode') as string

  const submitResetPassword = () => {
    setBusy(true)
    resetPassword({ email, newPassword, emailVerifyCode })
      .then(() => {
        setUpdatePasswordResult('success')
      })
      .catch(() => {
        setUpdatePasswordResult('error')
      })
      .finally(() => {
        setBusy(false)
      })
  }

  if (isBusy) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    )
  }

  let content: React.ReactElement | null = null

  if (!(email && emailVerifyCode)) {
    // URI is corrupt
    content = (
      <Alert
        variant="Warning"
        title={t('accounts.resetPassword.resetPasswordTokenFailedTitle')}
        message={t('accounts.resetPassword.resetPasswordTokenFailedMessage')}
        forwardUri="/"
      />
    )
  } else {
    switch (updatePasswordResult) {
      case 'success': {
        content = (
          <Alert
            variant="Success"
            title={t('accounts.resetPassword.congrats')}
            message={t('accounts.resetPassword.passwordHasBeenChanged')}
            forwardUri="/"
          />
        )
        break
      }
      case 'error': {
        content = (
          <Alert
            variant="Warning"
            title={t('accounts.resetPassword.failedResetPasswordTitle')}
            message={t('accounts.resetPassword.failedResetPasswordMessage')}
            forwardUri="/"
          />
        )
        break
      }
      default: {
        // Form to set new password
        content = (
          <Box borderRadius="lg" boxShadow="lg" m={1} bg="white" py={2}>
            <Text textStyle="h6" mb="4">
              {t('accounts.resetPassword.chooseNewPassword')}
            </Text>
            <Box m="1rem 1.5rem">
              <Input
                title={t('auth.password')}
                name="password"
                autoComplete="new-password"
                isMandatory={true}
                type="password"
                onChange={e => setNewPassword(e.target.value)}
                position="top"
              />

              <Input
                title={t('auth.passwordRepeat')}
                name="verifyPassword"
                autoComplete="new-password"
                isMandatory={true}
                type="password"
                onChange={e => setVerifyPassword(e.target.value)}
                position="bottom"
              />

              <Button
                mt="4"
                disabled={newPassword !== verifyPassword}
                onClick={() => submitResetPassword()}
              >
                {t('accounts.resetPassword.saveNewPassword')}
              </Button>
            </Box>
          </Box>
        )
        break
      }
    }
  }

  return (
    <Container alignContent="center" textAlign="center" mt="10%">
      <Helmet>
        <title>{t('accounts.resetPassword.chooseNewPassword')}</title>
      </Helmet>
      {content}
    </Container>
  )
}
export default ResetPasswordPage
