import { Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useProject } from '../../apiClients/projectsApiClient/projects'

import { useCollaboratorParts } from '../collaborators/collaboratorHelper'
import ListCollaborators from '../collaborators/ListCollaborators'
import { useNoteParts } from '../notes/notesHelper'
import NotesListView from '../notes/NotesListView'
import AdminPropertyCard from '../properties/AdminPropertyCard'

import MyMessageSection from '../sections/portal/MyMessageSection'

import ExpandableSectionCard from '../ui/ExpandableSectionCard'

const ProjectAdminSummaryCard: React.FC<{
  projectId: string
  mr?: number
  mt?: number
  headerBg?: string
}> = ({ projectId, mr, mt }) => {
  const { t } = useTranslation()
  const { data: project } = useProject(projectId)
  const { collaborators } = useCollaboratorParts({
    projectId: projectId,
  })
  const { notes } = useNoteParts({ projectId: projectId })
  const { data: conversation } = useConversationsByArea('Project', [projectId])

  return (
    <Flex flexDir="column" mr={mr} mt={mt} pb={4} gap={4}>
      {project && project.propertyId && (
        <AdminPropertyCard propertyId={project.propertyId} />
      )}
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.collaborators')} (${collaborators?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <ListCollaborators project={project} variant="link" />
      </ExpandableSectionCard>
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.projectNotes')} (${notes?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <NotesListView projectId={projectId} />
      </ExpandableSectionCard>
      <ExpandableSectionCard
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        title={`${t('admin.messages')} (${conversation?.length})`}
        w="100%"
        pb={4}
        border="1px solid"
        borderColor="gray.200"
      >
        <MyMessageSection
          area="Project"
          areaId={projectId}
          areaFriendlyName={''}
          areaFriendlyParentName={''}
          // parentArea={''}
          // parentAreaId={''}
          // grandParentArea={''}
          // grandParentAreaId={''}
          // conversationArea={''}
        />
      </ExpandableSectionCard>
    </Flex>
  )
}
export default ProjectAdminSummaryCard
