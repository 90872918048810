import { Flex, ResponsiveValue } from '@chakra-ui/react'
import Heading from '../ui/Heading'
import { useEndorsingCollaborators } from '../../apiClients/organizationsCollaboratorApiClient'
import ProviderProfileCardModal from './ProviderProfileCardModal'
import OrganizationEndorsmentCard from './OrganizationEndorsmentCard'

const ProviderEndorseSection: React.FC<{
  organizationId: string
  mt?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
}> = ({ organizationId, mt, px }) => {
  const { data: endorsingCollaborators } =
    useEndorsingCollaborators(organizationId)

  if (endorsingCollaborators?.length) {
    return (
      <Flex
        mt={mt}
        px={px}
        bg="primary_green01"
        justifyContent="space-around"
        pt={5}
        pb={10}
        w="100%"
      >
        <Flex flexDir="column" gap={4} textAlign="center">
          <Heading textStyle="h4">Validering från andra företag</Heading>

          <Flex gap={4} wrap="wrap" pt={4} flexDir="column">
            {endorsingCollaborators &&
              endorsingCollaborators.length > 0 &&
              endorsingCollaborators?.map(collaborator => {
                if (collaborator.organizationId) {
                  return (
                    <ProviderProfileCardModal
                      key={collaborator.id}
                      organizationId={collaborator.organizationId}
                    >
                      <Flex>
                        <OrganizationEndorsmentCard
                          organizationId={collaborator.ownerOrganizationId}
                          comment={collaborator?.comment ?? undefined}
                        />
                      </Flex>
                    </ProviderProfileCardModal>
                  )
                }
              })}
          </Flex>
        </Flex>
      </Flex>
    )
  } else return null
}
export default ProviderEndorseSection
