import {
  Flex,
  ResponsiveValue,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { AccountTypeName } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'
import { PATH_PUBLIC_WEB } from '../../common/nav'
import Link from '../ui/Link'
import { Logo } from '../ui/Logo'
import LoginForm from './LoginForm'

const Login: React.FC<{
  onClose?: () => void
  toggleForm?: () => void
  isOnlyLogin?: boolean
  forwardUri?: string
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  mt?: ResponsiveValue<number | string>
  currentAccountType: AccountTypeName
  setCurrentAccountType: Dispatch<SetStateAction<AccountTypeName>>
  currentUserEmail: string
  setCurrentUserEmail: Dispatch<SetStateAction<string>>
  forwardMessage?: string
  target?: string
}> = ({
  toggleForm,
  forwardUri,
  currentAccountType,
  setCurrentAccountType,
  currentUserEmail,
  setCurrentUserEmail,
  forwardMessage,
  px = { xxs: 1, tablet: 2 },
  mt = '5%',
  py,
}) => {
  const { t } = useTranslation()
  let defaultTabIndex = 0
  if (currentAccountType === 'Provider') {
    defaultTabIndex = 1
  }
  return (
    <Flex
      flexDir="column"
      w={{ xxs: '95vw', tablet: 'lg' }}
      px={px}
      py={py}
      mt={mt}
      borderRadius="lg"
      alignItems="center"
      // border="1px solid green"
    >
      <Link to={PATH_PUBLIC_WEB} isExternal>
        <Logo name="BerikarWithName" mb={8} h={8} />
      </Link>

      {forwardMessage && <Text mb={6}>{forwardMessage}</Text>}
      <Tabs
        defaultIndex={defaultTabIndex}
        isLazy
        mb={40}
        w="100%"
        onChange={e => {
          setCurrentAccountType(e === 0 ? 'Consumer' : 'Provider')
        }}
      >
        <TabList>
          <Tab
            _selected={{ borderColor: 'primary', color: 'primary' }}
            color="gray.400"
            w="50%"
          >
            <Text textStyle="h4">{t('general.privateUser')}</Text>
          </Tab>
          <Tab
            _selected={{ borderColor: 'primary', color: 'primary' }}
            color="gray.400"
            w="50%"
          >
            <Text textStyle="h4">{t('general.companyUser')}</Text>
          </Tab>
        </TabList>

        <TabPanels borderColor="red" borderTop="1px solid lightgray">
          <TabPanel p={0} mt={4}>
            <LoginForm
              accountType="Consumer"
              toggleForm={toggleForm}
              forwardUri={forwardUri}
              subscription={forwardMessage}
              currentUserEmail={currentUserEmail}
              setCurrentUserEmail={setCurrentUserEmail}
            />
          </TabPanel>
          <TabPanel p={0} mt={4}>
            <LoginForm
              accountType="Provider"
              toggleForm={toggleForm}
              forwardUri={forwardUri}
              subscription={forwardMessage}
              currentUserEmail={currentUserEmail}
              setCurrentUserEmail={setCurrentUserEmail}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
export default Login
