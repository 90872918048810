import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ClimateResource } from '@contracts/support'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ingressCompareClimateImpactSection } from '../../../apiClients/contentApiClient'
import AddCompareClimateImpactItemButton from '../../climate/AddCompareClimateImpactItemButton'
import CompareClimateImpactCard from '../../climate/CompareClimateImpactCard'
import Divider from '../../ui/Divider'
import compareProductsPromo from './../../../img/pages/compareClimateImpact/promoCompareClimateImpact.svg'

const CompareClimateImpactSection = () => {
  const { t } = useTranslation()
  const [climateProductsToCompare, setClimateProductsToCompare] = useState<
    ClimateResource[]
  >([])

  const updateProductsToCompareHandler = (
    productsToCompare: ClimateResource[],
  ) => {
    setClimateProductsToCompare(productsToCompare)
  }

  const onCloseCardHandler = (productToRemove: ClimateResource) => {
    setClimateProductsToCompare(
      climateProductsToCompare.filter(product => {
        return product.id !== productToRemove.id
      }),
    )
  }

  return (
    <>
      <Flex flexDir="row" w="100%" justifyContent="left" wrap="wrap">
        <Flex flexDir="column" pt={10} pl={[0, 2, 2, 10, 10, 10, 20, 20]}>
          <Text textStyle="h4">Hur påverkar dina val klimatet?</Text>
          <Box w="100%" maxW="container.lg" mt={2}>
            <Text>{ingressCompareClimateImpactSection}</Text>
          </Box>
        </Flex>
      </Flex>

      <Flex flexDir="row" my={10} mx={10} mb={64}>
        <Flex flexDir="column" w={48} textAlign="right" mr={6} mt="72px">
          <Divider />
          <Text pr={4}>Kategori</Text>
          <Divider />

          <Text pr={4}>{t('climate.compareClimateImpact.A1-A3')}</Text>
          <Divider />
          <Text pr={4}>{t('climate.compareClimateImpact.A4')}</Text>
          <Divider />
          <Text pr={4}>{t('climate.compareClimateImpact.A5')}</Text>
          <Divider />
          <Text pr={4}>
            {t('climate.compareClimateImpact.techLifeExpectance')}
          </Text>
          <Divider />
          <Text pr={4}>
            {t('climate.compareClimateImpact.calulationFactor')}
          </Text>
          <Divider />
          <Text pr={4}>{t('climate.compareClimateImpact.usabilityArea')}</Text>
        </Flex>

        {!climateProductsToCompare ||
          (climateProductsToCompare.length === 0 && (
            <Flex pos="relative">
              <Image
                src={compareProductsPromo}
                boxShadow="lg"
                h="640px"
                minH="640px"
                minW="760px"
                transform="translateY(-3px)"
              />
              <Box
                pos="absolute"
                top="55%"
                left="53%"
                transform="translateX(-50%)"
              >
                <AddCompareClimateImpactItemButton
                  onCompare={updateProductsToCompareHandler}
                  selectedProducts={climateProductsToCompare}
                >
                  {t('climate.chooseProducts')}
                </AddCompareClimateImpactItemButton>
              </Box>
            </Flex>
          ))}
        {climateProductsToCompare &&
          climateProductsToCompare.length > 0 &&
          climateProductsToCompare.map(product => {
            return (
              <CompareClimateImpactCard
                key={product.id}
                ml={5}
                product={product}
                onClose={onCloseCardHandler}
              />
            )
          })}

        <AddCompareClimateImpactItemButton
          ml={5}
          onCompare={updateProductsToCompareHandler}
          selectedProducts={climateProductsToCompare}
        >
          {t('climate.chooseProducts')}
        </AddCompareClimateImpactItemButton>
      </Flex>
    </>
  )
}
export default CompareClimateImpactSection
