import { Avatar, Flex, Image, Text } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import { useAccount } from '../../apiClients/accountsApiClient'
import { useOrganizationByAccount } from '../../apiClients/organizationsApiClient'

import { getDateStrFromDate } from '../../common/util'
import { useImageUrl } from '../attachments/attachmentHelper'
import ProviderBadge from '../ProviderBadge'
import { Logo } from '../ui/Logo'

const AccountProfileCard: React.FC<{ accountId: string }> = ({ accountId }) => {
  const { data: account } = useAccount(accountId)
  const { t } = useTranslation()

  const imgUrl = useImageUrl(account?.profileAttachmentId)
  if (!account || !account.accountTypes) {
    return null
  }

  const accountTypes = account.accountTypes

  const isProvider = accountTypes.includes('Provider')

  const { data: organization } = useOrganizationByAccount(accountId)

  return (
    <Flex flexDir="column" m={0}>
      <Flex
        flexDir="column"
        bg="secondary"
        h={28}
        borderTopRadius="md"
        pl={40}
        pt={6}
      >
        <Text textStyle="textLarge">{`${account.firstName} ${account.lastName}`}</Text>
        <Text>
          {isProvider ? t('general.providerUser') : t('general.consumerUser')}
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        minH={28}
        borderTopRadius="md"
        pl={{ xxs: 28, laptop: 40 }}
        pt={{ xxs: 8, laptop: 4 }}
        pb={2}
      >
        {isProvider && organization && (
          <>
            <ProviderBadge size={'sm'} transform="translate(-10px,0)" />
            <Text textStyle="textLarge">{organization?.name}</Text>
          </>
        )}
        <Text>{account.email}</Text>
        <Text>{account.phoneNumber}</Text>
      </Flex>

      <Flex
        flexDir="row"
        wrap="wrap"
        alignItems="center"
        flexWrap="wrap"
        pb={2}
        borderBottomRadius="lg"
        justifyContent="left"
        px={2}
      >
        <Logo name="Berikar" h={5} />
        <Text ml={1} textStyle="xsLight">
          {t('accounts.withBerikarSince', {
            createdAt: getDateStrFromDate(new Date(account.createdAt)),
          })}
        </Text>
      </Flex>
      {!imgUrl && (
        <Avatar
          pos="absolute"
          name={`${account.firstName} ${account.lastName}`}
          boxSize={32}
          borderRadius="full"
          border="5px solid"
          mt={4}
          ml={2}
          zIndex="base"
        />
      )}

      {imgUrl && (
        <Image
          pos="absolute"
          fit="cover"
          src={imgUrl}
          backgroundPosition="center"
          boxSize={32}
          borderRadius="full"
          border="5px solid"
          borderColor="primary_grey02"
          mt={4}
          ml={2}
          zIndex="base"
        />
      )}
    </Flex>
  )
}
export default AccountProfileCard
