import {
  Button,
  useDisclosure,
  Text,
  ResponsiveValue,
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { OrganizationCollaborator } from '@contracts/accounts'
import { HelpRequestTemplateResponse } from '@contracts/misc'

import React from 'react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AlertDialogUnsavedChanges from '../ui/AlertDialogUnsavedChanges'
import { Icon } from '../ui/Icon'
import IconButton from '../ui/IconButton'
import Modal from '../ui/Modal'
import SendHelpRequest from './AddHelpRequest'

const AddHelpRequestButton: React.FC<{
  helpReqTemplate: HelpRequestTemplateResponse

  mt?: number
  mb?: number
  w?: ResponsiveValue<number | string>
  children?: ReactElement
  iconColor?: string
  variant?: 'icon' | 'iconButton' | 'button'
  buttonStyleVariant?: 'primary' | 'secondary'
  size?: ResponsiveValue<'xs' | 'sm' | 'md' | 'lg'>
  disabled?: boolean
  id?: string
  tooltip?: string
  onClose?: () => void
  defaultOrganization?: OrganizationCollaborator
}> = ({
  helpReqTemplate,
  iconColor = 'primary_orange02',
  variant = 'icon',
  w,
  children,
  mt,
  mb,

  buttonStyleVariant = 'primary',
  size = 'md',
  disabled,
  id,
  onClose: onCloseParent,
  tooltip,
  defaultOrganization,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const { t } = useTranslation()

  const closeHandler = (avoidAlertDialog?: boolean) => {
    if (hasUnsavedChanges && !avoidAlertDialog) {
      onOpenAlertDialog()
    } else {
      onClose()
      if (onCloseParent) {
        onCloseParent()
      }
    }
  }

  if (variant === 'icon') {
    return (
      <Box id={id} mt={mt} mb={mb}>
        <Icon
          name="IoAddCircleOutline"
          _hover={{ color: 'gray' }}
          color={iconColor}
          onClick={onOpen}
          isDisabled={disabled}
        />

        <Modal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={closeHandler}
          size="3xl"
        >
          <>
            <AlertDialogUnsavedChanges
              isOpen={isOpenAlertDialog}
              onClose={onCloseAlertDialog}
              leastDestructiveRef={cancelRef}
              onConfirmClose={onClose}
            />

            <SendHelpRequest
              helpRequestTemplate={helpReqTemplate}
              onCloseModal={closeHandler}
              onParentHasUnsavedChanges={setHasUnsavedChanges}
              defaultOrganization={defaultOrganization}
            />
          </>
        </Modal>
      </Box>
    )
  }
  return (
    <>
      <Box id={id} mt={mt} mb={mb}>
        {variant === 'iconButton' && (
          <IconButton buttonType={'EDIT'} onClick={onOpen} />
        )}
        {variant === 'button' && (
          <Tooltip
            label={tooltip}
            hasArrow
            arrowSize={15}
            closeDelay={500}
            placement="top-start"
          >
            <Button
              variant={buttonStyleVariant}
              onClick={onOpen}
              w={w}
              size={size}
              color="white"
              disabled={disabled}
            >
              {children && <Text>{children}</Text>}
              {!children && (
                <Text textStyle="textRegularWhite">
                  {t('helpRequests.addHelpRequestTemplate')}
                </Text>
              )}
            </Button>
          </Tooltip>
        )}
      </Box>

      <Modal isOpen={isOpen} onOpen={onOpen} onClose={closeHandler} size="3xl">
        <>
          <AlertDialogUnsavedChanges
            isOpen={isOpenAlertDialog}
            onClose={onCloseAlertDialog}
            leastDestructiveRef={cancelRef}
            onConfirmClose={onClose}
          />

          <SendHelpRequest
            helpRequestTemplate={helpReqTemplate}
            onCloseModal={closeHandler}
            onParentHasUnsavedChanges={setHasUnsavedChanges}
            defaultOrganization={defaultOrganization}
          />
        </>
      </Modal>
    </>
  )
}
export default AddHelpRequestButton
