import React from 'react'
import { Box, ResponsiveValue } from '@chakra-ui/react'
import { getLogoFromName, LogoName } from '../../common/logo-utils'

export const Logo: React.FC<{
  name: LogoName
  h?: number | string
  mb?: number
  ml?: number | string
  maxW?: number | string
  minW?: number | string
  pt?: ResponsiveValue<number> | ResponsiveValue<string>
  pb?: ResponsiveValue<number> | ResponsiveValue<string>
  px?: number
  alignSelf?: string
  onClick?: () => void
}> = ({ name, h, mb, ml, maxW, minW, px, pt, pb, onClick, alignSelf }) => {
  return (
    <Box
      mb={mb}
      ml={ml}
      maxW={maxW}
      minW={minW}
      pt={pt}
      pb={pb}
      px={px}
      onClick={onClick}
      _hover={{ cursor: 'pointer' }}
      alignSelf={alignSelf}
    >
      {getLogoFromName(name, h)}
    </Box>
  )
}
