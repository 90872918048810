import { Flex } from '@chakra-ui/react'
import { AccountTypeName } from '@contracts/misc'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import Login from '../../components/accounts/Login'
import RegisterAccount from '../../components/accounts/RegisterAccount'

const LoginAndRegistrationPage: React.FC<{
  showRegistrationForm?: boolean
  isOnlyLogin?: boolean
  forwardUri?: string
  m?: number
  defaultAccountType?: AccountTypeName
  defaultEmail?: string
  forwardMessage?: string
}> = ({
  showRegistrationForm: propsShowRegistrationForm,
  isOnlyLogin = false,
  forwardUri,
  m,
  defaultAccountType,
  defaultEmail,
  forwardMessage,
}) => {
  const { search, pathname } = useLocation()

  if (!forwardUri) {
    forwardUri = pathname + search
  }
  const [currentAccountType, setCurrentAccountType] = useState<AccountTypeName>(
    defaultAccountType ?? 'Consumer',
  )
  const [currentUserEmail, setCurrentUserEmail] = useState(defaultEmail ?? '')
  const [showRegistrationForm, setShowRegistrationForm] = useState(
    propsShowRegistrationForm,
  )
  const toggleForm = () => {
    setShowRegistrationForm(!showRegistrationForm)
  }

  return (
    <Flex
      flexDir="column"
      width="100%"
      // border="1px solid red"
      alignItems="center"
    >
      <Helmet>
        <title>Berikar</title>
      </Helmet>
      {showRegistrationForm && (
        <RegisterAccount
          toggleForm={toggleForm}
          m={m}
          currentAccountType={currentAccountType}
          setCurrentAccountType={setCurrentAccountType}
          currentUserEmail={currentUserEmail}
          setCurrentUserEmail={setCurrentUserEmail}
          forwardMessage={forwardMessage}
        />
      )}
      {!showRegistrationForm && (
        <Flex>
          <Login
            toggleForm={toggleForm}
            isOnlyLogin={isOnlyLogin}
            forwardUri={forwardUri}
            currentAccountType={currentAccountType}
            setCurrentAccountType={setCurrentAccountType}
            currentUserEmail={currentUserEmail}
            setCurrentUserEmail={setCurrentUserEmail}
            forwardMessage={forwardMessage}
          />
        </Flex>
      )}
    </Flex>
  )
}
export default LoginAndRegistrationPage
