import _ from 'lodash'
import { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Show, Table, Tbody, Td, Tr, Text, Flex } from '@chakra-ui/react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Icon } from '../ui/Icon'
import { getDateStrFromDate } from '../../common/util'

import Heading from '../ui/Heading'
import AccountAvatar from '../accounts/AccountAvatar'

import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import { Link } from '../../ui'
import { PATH_ADMIN_PROVIDER_ACCOUNTS } from '../../common/nav'
import OrgAccountCard from './OrgAccountAdminCard'

const ListOrgAccounts: React.FC<{
  orgId: string
  mt?: number | string
  my?: number | string
  pb?: number | string
  variant?: 'normal' | 'link'
}> = ({ orgId, mt, my, pb, variant = 'normal' }) => {
  const { t } = useTranslation()

  const { data: orgAccounts } = useOrganizationAccounts(orgId)

  const ownerOrgAccounts = orgAccounts?.filter(orgAccount => {
    return orgAccount.accountRelation === 'Owner'
  })

  const { currentUser } = useContext(AuthContext)
  const currentUserAccessRights = orgAccounts?.find(orgAccount => {
    return orgAccount.accountId === currentUser?.id
  })?.accountRelation

  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  return (
    <Box pb={pb} mt={mt} my={my}>
      <Heading textStyle="h9" ml={2} mb={2} mt={6}>
        {t('providers.listingUsers')}
      </Heading>
      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <Tbody>
          {orgAccounts && orgAccounts.length > 0 ? (
            orgAccounts.map((orgAccount, i) => {
              const isOnlyOwner =
                orgAccount.accountRelation === 'Owner' &&
                (ownerOrgAccounts?.length ?? 0) <= 1

              const createdAt = getDateStrFromDate(
                new Date(orgAccount.createdAt),
              )

              return (
                <Fragment key={orgAccount.accountId}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                    onClick={() => {
                      toggleAccordion(orgAccount.accountId)
                    }}
                  >
                    <Show above="laptop">
                      <Td maxW={8}>
                        {!accordionsToShow.includes(orgAccount.accountId) && (
                          <Icon
                            name={'AiOutlinePlusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(orgAccount.accountId)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(orgAccount.accountId) && (
                          <Icon
                            name={'AiOutlineMinusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(orgAccount.accountId)
                            }}
                          />
                        )}
                      </Td>
                      <Td overflow="clip" wordBreak="break-word" minW={64}>
                        <Flex flexDir="row" alignItems="center">
                          <AccountAvatar
                            accountId={orgAccount.accountId}
                            variant="avatarAndName"
                            mr={4}
                          />
                        </Flex>
                      </Td>

                      <Td>
                        <Show below="desktop">
                          <Flex flexDir="column">
                            <Text wordBreak="break-all" maxW={60}>
                              {orgAccount.account?.email}
                            </Text>
                            <Text>
                              {t(
                                `providers.accessRights.${orgAccount.accountRelation.toLowerCase()}`,
                              )}
                            </Text>
                          </Flex>
                        </Show>
                        <Show above="desktop">
                          <Text wordBreak="break-all" maxW={60}>
                            {orgAccount.account?.email}
                          </Text>
                        </Show>
                      </Td>
                      <Show above="desktop">
                        {/* <Td minW={{ tablet: 44 }}> */}
                        <Td minW={{ tablet: 6 }}>
                          {t(
                            `providers.accessRights.${orgAccount.accountRelation.toLowerCase()}`,
                          )}
                        </Td>
                      </Show>
                    </Show>
                    <Show below="laptop">
                      <Td maxW={14}>
                        {!accordionsToShow.includes(orgAccount.accountId) && (
                          <Icon
                            name={'AiOutlinePlusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(orgAccount.accountId)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(orgAccount.accountId) && (
                          <Icon
                            name={'AiOutlineMinusCircle'}
                            h={24}
                            onClick={() => {
                              toggleAccordion(orgAccount.accountId)
                            }}
                          />
                        )}
                      </Td>
                      <Td maxW={12}>
                        <AccountAvatar
                          accountId={orgAccount.accountId}
                          variant="avatar"
                        />
                      </Td>
                      <Td
                        colSpan={2}
                        overflow="clip"
                        wordBreak="break-word"
                        maxW={72}
                      >
                        <Text fontWeight="bold">
                          {`${orgAccount.account?.firstName ?? ''} ${
                            orgAccount.account?.lastName ?? ''
                          }`}
                        </Text>

                        <Text>
                          {t(
                            `properties.accessRights.${orgAccount.accountRelation.toLowerCase()}`,
                          )}
                        </Text>
                      </Td>
                    </Show>
                    <Show above="xl">
                      <Td
                        maxW={{ xxs: 40, tablet: 96, laptop: '' }}
                        overflow="clip"
                      >
                        {createdAt}
                      </Td>
                      {variant === 'link' && (
                        <Td>
                          <Link
                            to={`${PATH_ADMIN_PROVIDER_ACCOUNTS}/?konto=${orgAccount.accountId}`}
                          >
                            <ExternalLinkIcon />
                          </Link>
                        </Td>
                      )}
                    </Show>
                  </Tr>
                  {accordionsToShow.includes(orgAccount.accountId) && (
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={8}>
                        <OrgAccountCard
                          orgAccount={orgAccount}
                          orgId={orgId}
                          currentUserAccessRights={
                            currentUserAccessRights ?? 'Viewer'
                          }
                          isOnlyAdmin={isOnlyOwner}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={8}>{t('providers.noUsersFound')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListOrgAccounts
