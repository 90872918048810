import { Image } from '@chakra-ui/react'
import content_rectangle_sm from '../img/content/rectangle_sm.png'
import content_rectangle2_md from '../img/content/rectangle2_md.png'
export const getImageFromName = (
  imageName: ImageName,
  boxSize?: number | string,
  borderRadius?: string,
  border?: string,
) => {
  switch (imageName) {
    case 'content.rectangle_sm':
      return (
        <Image
          src={content_rectangle_sm}
          boxSize={boxSize}
          borderRadius={borderRadius}
          border={border}
        />
      )
    case 'content.rectangle2_md':
      return (
        <Image
          src={content_rectangle2_md}
          boxSize={boxSize}
          borderRadius={borderRadius}
          border={border}
        />
      )
    default:
      return (
        <Image
          src={content_rectangle2_md}
          boxSize={boxSize}
          borderRadius={borderRadius}
          border={border}
        />
      )
  }
}
export type ImageName =
  | 'properties.villa.1'
  | 'properties.villa.2'
  | 'content.rectangle_sm'
  | 'content.rectangle2_md'
