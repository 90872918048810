import Compressor from 'compressorjs'
import { useState } from 'react'

import {
  Attachment,
  ContextArea,
  CopyAttachmentRequest,
  CreateAttachmentRequest,
} from '@contracts/support'
import { AttachmentSummary } from '@contracts/misc'
import log from '../../common/log'
import { getMbFromBytes } from '../../common/util'
import {
  copyAttachment,
  deleteAttachment,
  getAttachmentsByAreaQuery,
  updateContextAreaData,
} from '../../apiClients/attachmentsApiClient'
import { AreaData } from '../notes/AddOrEditNoteFiles'
const baseUrlPublic = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public`
const baseUrlPrivate = `${window.AZURE_BLOB_STORAGE_BASE_URL}/private`

export const isImage = (attachment: string) => {
  const fileEndings = ['svg', 'png', 'jpg', 'jpeg', 'gif']
  return fileEndings.find(fileEnding => {
    return attachment.includes(fileEnding)
  })
}

const getCompressRate = (
  compressResult: 's' | 'm' | 'l',
  originalFileSize: number,
): number => {
  switch (compressResult) {
    case 's':
      if (originalFileSize < 100000) {
        return 1
      }
      if (originalFileSize < 500000) {
        return 0.4
      }
      if (originalFileSize < 1500000) {
        return 0.1
      }
      return 0.1
    case 'm':
      if (originalFileSize < 100000) {
        return 1
      }
      if (originalFileSize < 500000) {
        return 0.8
      }
      if (originalFileSize < 1500000) {
        return 0.2
      }
      return 0.1
    case 'l':
      if (originalFileSize < 500000) {
        return 1
      }
      if (originalFileSize < 1500000) {
        return 0.6
      }
      return 0.3
  }
}

export const connectEmbeddedImagesNotConnected = async (
  richText: string,
  areaData: {
    area: ContextArea
    areaId?: string
    parentArea?: ContextArea
    parentAreaId?: string
    grandParentArea?: ContextArea
    grandParentAreaId?: string
  },
) => {
  const embeddedImgIds: string[] | undefined = findEmbeddedImgs(richText ?? '')

  const connectedImages = await getAttachmentsByAreaQuery([
    { area: areaData.area, areaId: areaData.areaId },
  ])

  if (embeddedImgIds.length) {
    embeddedImgIds.map(async embeddedImgId => {
      if (
        !connectedImages?.length ||
        !connectedImages.find(connectedImage => {
          return connectedImage.id === embeddedImgId
        })
      ) {
        // eslint-disable-next-line no-console
        console.log(
          "Found image that is embedded but not conncted - let's connect it",
        )
        await updateContextAreaData(embeddedImgId, {
          area: areaData.area,
          areaId: areaData.areaId,
          parentArea: areaData.parentArea,
          parentAreaId: areaData.parentAreaId,
          grandParentArea: areaData.grandParentArea,
          grandParentAreaId: areaData.grandParentAreaId,
        })
      }
    })
  }
}

export const removeConnectedImagesNotEmbedded = async (
  richText: string,
  areaData: {
    area: ContextArea
    areaId?: string
    parentArea?: ContextArea
    parentAreaId?: string
    grandParentArea?: ContextArea
    grandParentAreaId?: string
  },
) => {
  // Remove images that are connected but not part of the rich-text

  const embeddedImgIds: string[] | undefined = findEmbeddedImgs(richText ?? '')
  const connectedImages = await getAttachmentsByAreaQuery([
    { area: areaData.area, areaId: areaData.areaId },
  ])

  if (connectedImages?.length) {
    connectedImages.map(async connectedImage => {
      if (
        embeddedImgIds?.length &&
        embeddedImgIds.find(imgId => {
          return imgId === connectedImage.id
        })
      ) {
        // Keep file in DB - keep image
        if (isMissingAreaData(connectedImage)) {
          // If image lacks area data - add it
          await updateContextAreaData(connectedImage.id, {
            area: connectedImage.area ?? areaData.area,
            areaId: connectedImage.areaId ?? areaData.areaId,
            parentArea: connectedImage.parentArea ?? areaData.parentArea,
            parentAreaId: connectedImage.parentAreaId ?? areaData.parentAreaId,
            grandParentArea:
              connectedImage.grandParentArea ?? areaData.grandParentArea,
            grandParentAreaId:
              connectedImage.grandParentAreaId ?? areaData.grandParentAreaId,
          })
        }
      } else {
        // Image has been removed, ie not included in rich-text anymore
        // then image must die!
        // eslint-disable-next-line no-console
        console.log(
          'Connected img not embedded -> remove img: ',
          connectedImage.originalFilename,
        )
        await deleteAttachment(connectedImage.id, true)
      }
    })
  }
}
export const compressFile = (
  file: File,
  compressResult: 's' | 'm' | 'l',
  callback: (file: File) => void,
) => {
  const compressRate = getCompressRate(compressResult, file.size)
  try {
    if (file.type.includes('image') && isImageFormatSupported(file)) {
      new Compressor(file, {
        quality: compressRate,
        success: compressedResult => {
          const newFile = new File([compressedResult], (file as File).name, {
            type: compressedResult.type,
          })
          callback(newFile)
        },
      })
    } else {
      callback(file)
    }
  } catch (e) {
    log.error({ err: e }, 'Failed to compress file, unsupportad format')
  }
}

const compress = async (
  file: File,
  compressResult: 's' | 'm' | 'l',
): Promise<File | Blob> => {
  const compressRate = getCompressRate(compressResult, file.size)
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: compressRate,
      success: resolve,
      error: reject,
    })
  })
}

export const compressFileAsync = async (
  file: File,
  compressResult: 's' | 'm' | 'l',
): Promise<File> => {
  const compressedFile = await compress(file, compressResult)
  const newFile = new File([compressedFile], (file as File).name, {
    type: file.type,
  })
  return newFile
}

export const isImageFormatSupported = (file: File) => {
  const unsupportedFormats = ['heic']
  return !unsupportedFormats.find(format => {
    return file.type.includes(format)
  })
}
export const isDocument = (attachment: string) => {
  const fileEndings = ['.pdf', '.xlsx', '.xls', '.docx', '.doc', '.txt']
  return fileEndings.find(fileEnding => {
    return attachment.includes(fileEnding)
  })
}
export const isVideo = (attachment: string) => {
  const fileEndings = ['.mp4']
  return fileEndings.find(fileEnding => {
    return attachment.includes(fileEnding)
  })
}

export const getPrivateFilesNode = (attachment: Attachment) => {
  return `<p>${
    attachment.originalFilename
  }</p><div><a href='${downloadAttachmentUrl(
    attachment.id,
    attachment.originalFilename,
  )}'><img src='${baseUrlPrivate}/${attachment.id}' width='500px' /></a></div>`
}

export const getContentTailOfAttachedImages = (
  attachedImages: Attachment[] | undefined,
) => {
  if (!attachedImages) {
    return ''
  }
  const { images } = groupBy(attachedImages)
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${images.map(attachments => getPrivateFilesNode(attachments[0]))}` ?? ''
    // `${attachedImages.map(attachment => getPrivateFilesNode(attachment))}` ?? ''
  )
}

export const downloadAttachmentUrl = (attachmentId: string, fileName: string) =>
  `${
    window.SUPPORT_SERVICE_URL
  }/attachments/download/${attachmentId}/${encodeURIComponent(fileName)}`

export const isImgUrl = async (url: string) => {
  const img = new Image()
  img.src = url
  return new Promise(resolve => {
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}

export const findEmbeddedImgs = (richText: string) => {
  const re = new RegExp(/(?<=src=").+?(?=")/g)
  const imageStrings = richText.match(re) ?? undefined
  let embeddedImgIds: string[] = []
  if (imageStrings?.length) {
    embeddedImgIds = imageStrings.map(embeddedImgStr => {
      const tmpStrArray = embeddedImgStr.split('/')
      return tmpStrArray[tmpStrArray.length - 1]
    })
  }
  return embeddedImgIds.filter(imgId => {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    if (regexExp.test(imgId)) {
      return imgId
    }
  })
}

export const isMissingAreaData = (attachment: Attachment) => {
  if (attachment.area.startsWith('PropertyNote')) {
    if (
      !attachment.areaId ||
      !attachment.parentArea ||
      !attachment.parentAreaId
    ) {
      return true
    } else {
      return false
    }
  }
  if (attachment.area.startsWith('ProjectNote')) {
    if (
      !attachment.areaId ||
      !attachment.parentArea ||
      !attachment.parentAreaId ||
      !attachment.grandParentArea ||
      !attachment.grandParentAreaId
    ) {
      return true
    } else {
      return false
    }
  }
  if (attachment.parentArea === 'Property') {
    if (!attachment.area || !attachment.areaId || !attachment.parentAreaId) {
      return true
    } else {
      return false
    }
  }
  if (attachment.parentArea === 'Project') {
    if (
      !attachment.area ||
      !attachment.areaId ||
      !attachment.parentAreaId ||
      !attachment.grandParentArea ||
      !attachment.grandParentAreaId
    ) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const useImageUrl = (
  id: string | undefined,
  container?: 'public' | 'private',
) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined)
  if (!id) {
    return undefined
  }
  if (container === 'public') {
    return `${baseUrlPublic}/${id}`
  }
  if (container === 'private') {
    return `${baseUrlPrivate}/${id}`
  }

  isImgUrl(`${baseUrlPublic}/${id}`)
    .then(res => {
      if (res) {
        setImgUrl(`${baseUrlPublic}/${id}`)
      } else {
        setImgUrl(`${baseUrlPrivate}/${id}`)
      }
    })
    .catch(err => {
      log.error(
        { err: err },
        'Error finding out if file is in private or public blob storage',
      )
    })

  return imgUrl
}

export const getSumOfFiles = (array: Attachment[]) => {
  const sumBytes = Number(
    array?.reduce((accumulator: number, file) => {
      return accumulator + (file.fileSizeBytes ?? 0)
    }, 0),
  )
  return getMbFromBytes(sumBytes, 1)
}

export const groupBy = (
  attachments: Attachment[],
): { files: Attachment[][]; images: Attachment[][] } => {
  // eslint-disable-next-line no-console
  // console.log('attachments : ', attachments)
  const files: Attachment[][] = []
  const images: Attachment[][] = []
  attachments.forEach(attachment => {
    const group = attachments.filter(file => {
      return (
        attachment.originalFilename === file.originalFilename &&
        attachment.mimeType === file.mimeType
      )
    })
    const remainingFiles = attachments.filter(file => {
      if (
        attachment.mimeType !== file.mimeType ||
        attachment.originalFilename !== file.originalFilename
      ) {
        return true
      }
    })
    attachments = remainingFiles
    if (group.length) {
      if (isImage(group[0].mimeType)) {
        images.push(group)
      } else {
        files.push(group)
      }
    }
  })
  return { files, images }
}

export const groupByFiles = (
  files: File[],
): { files: File[][]; images: File[][] } => {
  // eslint-disable-next-line no-console
  // console.log('attachments : ', attachments)
  const fileFiles: File[][] = []
  const fileImages: File[][] = []
  files.forEach(file => {
    const group = files.filter(f => {
      return (
        // attachment.originalFilename === file.originalFilename &&
        // attachment.mimeType === file.mimeType
        file.name === f.name && file.type === f.type
      )
    })
    const remainingFiles = files.filter(f => {
      if (
        file.type !== f.type ||
        file.name !== f.name
        // attachment.mimeType !== file.mimeType ||
        // attachment.originalFilename !== file.originalFilename
      ) {
        return true
      }
    })
    // attachments = remainingFiles
    files = remainingFiles
    if (group.length) {
      if (isImage(group[0].type)) {
        fileImages.push(group)
      } else {
        fileFiles.push(group)
      }
    }
  })
  return { files: fileFiles, images: fileImages }
}

export const copyAttachments = async (
  attachmentsToCopy: Attachment[] | AttachmentSummary[],
  areaData: AreaData,
): Promise<Attachment[]> => {
  let copiedAttachments: Attachment[] = []

  const attachmentRequest: CreateAttachmentRequest = {
    area: areaData.area,
    areaId: areaData.areaId ?? '',
    parentArea: areaData.parentArea ?? null,
    parentAreaId: areaData.parentAreaId ?? null,
    grandParentArea: areaData.grandParentArea ?? null,
    grandParentAreaId: areaData.grandParentAreaId ?? null,
    container: null,
  }

  // eslint-disable-next-line no-console
  // console.log('copyRequest: ', attachmentRequest)
  const attachmentIdsToCopy = attachmentsToCopy.map(a => {
    return a.id
  })
  const copyRequestData: CopyAttachmentRequest = {
    attachmentIdsToCopy: attachmentIdsToCopy,
    attachmentRequest: attachmentRequest,
  }

  copiedAttachments = await copyAttachment(copyRequestData, true)

  return copiedAttachments
}
