import { Box, Button, Flex, HStack, Text, useToast } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  AttachmentSummary,
  CreateHelpRequestTemplate,
  HelpRequestTemplateResponse,
  UpdateHelpRequestTemplate,
} from '@contracts/misc'

import { Attachment } from '@contracts/support'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import { validateString } from '../../common/auth-utils'
import Tiptap from '../richTextEditor/Tiptap'
import {
  addAttachment,
  updateContextAreaId,
  useMultipleAttachments,
} from '../../apiClients/attachmentsApiClient'

import { compressFile, copyAttachments } from '../attachments/attachmentHelper'
import InputDate from '../ui/InputDate'

import {
  addHelpRequestTemplate,
  deleteHelpRequestTemplate,
  updateHelpRequestTemplate,
  useHelpRequestsTemplate,
  useHelpRequestsTemplates,
  useHelpRequestsTemplatesByProjectIds,
} from '../../apiClients/projectsApiClient/helpRequests'
import log from '../../common/log'
import { getDateStrFromNumber } from '../../common/util'
import FileManager from '../ui/FileManager'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import { useProjects } from '../../apiClients/projectsApiClient'
import FileManagerBeforeContextId from '../ui/FileManagerBeforeContextId'
import { AreaData } from '../notes/AddOrEditNoteFiles'

export const MAX_LENGTH_NOTE_TITLE = 255
interface AddOrEditHelpRequestTemplateValues {
  name: string
  description: string
  attachments: File[]
  // helpTimestamp: string | number | null | undefined
  helpTimestamp: string | undefined
}
interface Errors {
  name?: string
  description?: string
  helpTimestamp?: number
}

const AddOrEditHelpRequestTemplateForm: React.FC<{
  onCloseModal?: (avoidAlertDialog?: boolean) => void
  helpRequestTemplate?: HelpRequestTemplateResponse // CreateHelpRequestResponse
  onParentHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void
  projectId: string
  isEmbedded?: boolean

  onSaveWithoutSendHelpReqTemplate?: Dispatch<
    SetStateAction<HelpRequestTemplateResponse | undefined>
  >
  onSaveAndSendHelpReqTemplate?: Dispatch<
    SetStateAction<HelpRequestTemplateResponse | undefined>
  >
}> = ({
  projectId,
  onCloseModal,
  helpRequestTemplate,
  onParentHasUnsavedChanges,
  isEmbedded = false,
  onSaveWithoutSendHelpReqTemplate,
  onSaveAndSendHelpReqTemplate,
}) => {
  // eslint-disable-next-line no-console
  // console.log('isEmbedded', isEmbedded)
  // eslint-disable-next-line no-console
  // console.log('helpReqTemplate', helpRequestTemplate)

  // All projects where current user is 'Owner'
  const { data: projects } = useProjects('Owner')
  const projectIds = projects?.map(project => {
    return project.id
  })
  // All help-request-templates to projects where current user is 'Owner'
  const { mutate: mutateAllHelpRequestTemplates } =
    useHelpRequestsTemplatesByProjectIds(projectIds)
  const { mutate: mutateCurrentHelpReqTemplate } = useHelpRequestsTemplate(
    helpRequestTemplate?.id,
  )
  const { mutate: mutateAttachments } = useMultipleAttachments(
    helpRequestTemplate?.attachmentsJson?.map(a => {
      return a.id
    }),
  )

  const { mutate: mutateProjectHelpRequestTemplates } =
    useHelpRequestsTemplates(projectId)

  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const [isDeleting, setIsDeleting] = useState(false)
  const toast = useToast()

  const [description, setDescription] = useState(
    helpRequestTemplate?.description ?? '<p></p>',
  )

  const [isSaveAndSend, setIsSaveAndSend] = useState(false)
  const [attachmentsJson, setAttachmentsJson] = useState<AttachmentSummary[]>(
    helpRequestTemplate?.attachmentsJson ?? [],
  )

  const [newFiles, setNewFiles] = useState<File[]>([])

  const [addedImgIds, setAddedImgIds] = useState<string[] | undefined>()
  const imgIds: string[] = []

  const areaData: AreaData = {
    area: 'HelpRequestTemplate',
    areaId: helpRequestTemplate?.id,
    parentArea: 'Project',
    parentAreaId: projectId,
    grandParentArea: 'Property',
  }

  const initialValues: AddOrEditHelpRequestTemplateValues = {
    name: helpRequestTemplate?.name ?? '',
    description: helpRequestTemplate?.description ?? '',
    helpTimestamp: helpRequestTemplate?.helpTimestamp
      ? getDateStrFromNumber(helpRequestTemplate.helpTimestamp as number)
      : undefined,
    attachments: [],
  }

  useEffect(() => {
    formikValues.attachments = []
    newFiles.map(newFile => {
      compressFile(newFile, 'm', newFile => {
        formikValues.attachments.push(newFile)
      })
    })
  }, [newFiles])

  // Keep "NotSet" on top to make it default work correctly
  // const availableStatuses: NoteStatus[] = [
  //   'NotSet',
  //   'Approved',
  //   'Done',
  //   'Draft',
  //   'Ongoing',
  //   'Rejected',
  //   'Todo',
  // ]

  const imgAddedHandler = (imgId: string) => {
    imgIds.push(imgId)
    setAddedImgIds(imgIds)
  }

  const validate = (values: AddOrEditHelpRequestTemplateValues) => {
    const errors: Errors = {}

    const tmpRes = validateString(
      values.name,
      'rubrik',
      2,
      MAX_LENGTH_NOTE_TITLE,
      true,
    )
    if (!tmpRes.isValid) {
      errors.name = tmpRes.errorMsg
    }

    return errors
  }

  const persistNewFilesHandler = async (helpReqTemplateId: string) => {
    const newAttachments: Attachment[] = []
    await Promise.all(
      newFiles.map(async file => {
        const formData = new FormData()
        formData.set('image', file)

        formData.set('area', areaData.area)
        formData.set('areaId', helpReqTemplateId)
        if (areaData.parentArea) {
          formData.set('parentArea', areaData.parentArea)
        }
        if (areaData.parentAreaId) {
          formData.set('parentAreaId', areaData.parentAreaId)
        }
        if (areaData.grandParentArea) {
          formData.set('grandParentArea', areaData.grandParentArea)
        }
        if (areaData.grandParentAreaId) {
          formData.set('grandParentAreaId', areaData.grandParentAreaId)
        }

        newAttachments.push(await addAttachment(formData, true))
      }),
    )
    return newAttachments
  }

  // Required for FilesManager (with known context id)
  const uploadFilesHandler = async (newFilesToAdd: File[]): Promise<void> => {
    const persistedAttachments = await Promise.all(
      newFilesToAdd.map(async file => {
        const formData = new FormData()
        formData.set('image', file)

        formData.set('area', 'HelpRequestTemplate')
        formData.set('parentArea', 'Project')
        formData.set('grandParentArea', 'Property')
        if (helpRequestTemplate?.projectId) {
          formData.set('parentAreaId', projectId)
        }

        if (helpRequestTemplate?.id) {
          formData.set('areaId', helpRequestTemplate.id)
        }

        const newAttachment = await addAttachment(formData, true)

        return newAttachment
      }),
    )

    void addAttachmentsToHelpReqeustTemplateHandler(persistedAttachments)
  }

  const deleteAttachmentsFromHelpReqTemplateHandler = (
    attachmentIdsToDelete: string[],
  ) => {
    // Remove attachment
    // await deleteAttachment(attachmentIdToDelete)

    if (!helpRequestTemplate) {
      return null
      // return Promise.resolve()
    }

    const updatedAttachmentsJson = helpRequestTemplate?.attachmentsJson
      ? helpRequestTemplate.attachmentsJson.filter(a => {
          if (attachmentIdsToDelete.includes(a.id)) {
            return false
          }
          return true
        })
      : []

    // Remove attachment from attachmentsJson
    const requestData = helpRequestTemplate
    requestData.attachmentsJson = updatedAttachmentsJson

    void updateHelpRequestTemplate(
      helpRequestTemplate.id,
      projectId,
      requestData,
    )
  }

  const updateHelpRequestHandler = (requestData: UpdateHelpRequestTemplate) => {
    if (!helpRequestTemplate) {
      return null
    }
    updateHelpRequestTemplate(
      helpRequestTemplate?.id ?? '',
      projectId,
      requestData,
    )
      .then(res => {
        log.info('Successfully updated new help request template')

        applicationInsights.trackEvent({
          name: 'Help-request-template updated',
        })

        void mutateAllHelpRequestTemplates()
        void mutateProjectHelpRequestTemplates()

        if (isSaveAndSend) {
          onSaveAndSendHelpReqTemplate
            ? onSaveAndSendHelpReqTemplate(res.data)
            : ''
        } else {
          onSaveWithoutSendHelpReqTemplate
            ? onSaveWithoutSendHelpReqTemplate(res.data)
            : ''
        }
        toast({
          title: t('helpRequests.template.updateSuccessMessage', {
            name: helpRequestTemplate?.name ?? '',
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })

        // Mutate addOrEditHelpReqTemplateForm (this component)
        void mutateAttachments()
        // Mutate help-req-template-card
        void mutateCurrentHelpReqTemplate()
        // setAttachmentsJson(helpRequestTemplate.attachmentsJson ?? [])
        setAttachmentsJson(res.data.attachmentsJson)
      })
      .catch(err => {
        log.error('Failed to update note', err)
        toast({
          title: t('helpRequests.template.updateErrorMessage', {
            name: helpRequestTemplate.name,
          }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .finally(() => {
        // onCloseModal ? onCloseModal(true) : ''
        // setSubmitting(false)
      })
  }

  const addAttachmentsToHelpReqeustTemplateHandler = (
    attachmentsToAdd: Attachment[] | AttachmentSummary[],
  ) => {
    if (!helpRequestTemplate) {
      return null
    }
    const requestData: UpdateHelpRequestTemplate = {
      status: helpRequestTemplate.status,
      name: helpRequestTemplate.name,
      description: description,
      helpTimestamp: helpRequestTemplate.helpTimestamp,
      prioPrice: 1,
      prioQuality: 1,
      prioCommunication: 1,
      attachmentsJson: [
        ...(helpRequestTemplate.attachmentsJson ?? []),
        ...attachmentsToAdd,
      ],
    }
    updateHelpRequestHandler(requestData)
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      if (helpRequestTemplate) {
        const requestData: UpdateHelpRequestTemplate = {
          status: helpRequestTemplate.status,
          name: values.name,
          description: description,
          helpTimestamp: values.helpTimestamp,
          prioPrice: 1,
          prioQuality: 1,
          prioCommunication: 1,
          attachmentsJson: helpRequestTemplate.attachmentsJson,
        }

        updateHelpRequestTemplate(
          helpRequestTemplate.id,
          projectId,
          requestData,
        )
          .then(res => {
            log.info('Successfully updated new help request template')

            applicationInsights.trackEvent({
              name: 'Help-request-template updated',
            })

            void mutateAllHelpRequestTemplates()
            void mutateProjectHelpRequestTemplates()

            if (isSaveAndSend) {
              onSaveAndSendHelpReqTemplate
                ? onSaveAndSendHelpReqTemplate(res.data)
                : ''
            } else {
              onSaveWithoutSendHelpReqTemplate
                ? onSaveWithoutSendHelpReqTemplate(res.data)
                : ''
            }
            toast({
              title: t('helpRequests.template.updateSuccessMessage', {
                name: values.name,
              }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            void mutateCurrentHelpReqTemplate()
          })
          .catch(err => {
            log.error('Failed to update note', err)
            toast({
              title: t('helpRequests.template.updateErrorMessage', {
                name: values.name,
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            onCloseModal ? onCloseModal(true) : ''
            setSubmitting(false)
          })
      } else {
        const requestData: CreateHelpRequestTemplate = {
          name: values.name,
          description: description,
          helpTimestamp: values.helpTimestamp,
          prioPrice: 1,
          prioQuality: 1,
          prioCommunication: 1,
          attachmentsJson: [],
        }

        addHelpRequestTemplate(projectId, requestData)
          .then(async ({ data: resHelpRequestTemplate }) => {
            log.info('Successfully created new help request template')
            applicationInsights.trackEvent({
              name: 'Help-request-template added',
            })

            // Map images added to rich text properly
            if (addedImgIds?.length) {
              addedImgIds.map(async imgId => {
                await updateContextAreaId(imgId, resHelpRequestTemplate.id)
              })
            }

            const persistedNewFilesAsAttachments = await persistNewFilesHandler(
              resHelpRequestTemplate.id,
            )

            areaData.areaId = resHelpRequestTemplate.id

            const copiedAttachments = await copyAttachments(
              attachmentsJson,
              areaData,
            )
            if (
              persistedNewFilesAsAttachments.length ||
              copiedAttachments.length
            ) {
              requestData.attachmentsJson = [
                ...(requestData?.attachmentsJson ?? []),
                ...persistedNewFilesAsAttachments,
                ...copiedAttachments,
              ]
            }
            await updateHelpRequestTemplate(
              resHelpRequestTemplate.id,
              projectId,
              requestData,
            )

            toast({
              title: t('helpRequests.template.createSuccessMessage', {
                name: values.name,
              }),
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })

            void mutateAllHelpRequestTemplates()
            void mutateProjectHelpRequestTemplates()

            if (isSaveAndSend) {
              onSaveAndSendHelpReqTemplate
                ? onSaveAndSendHelpReqTemplate(resHelpRequestTemplate)
                : ''
            } else {
              onSaveWithoutSendHelpReqTemplate
                ? onSaveWithoutSendHelpReqTemplate(resHelpRequestTemplate)
                : ''
            }
          })
          .catch(err => {
            log.error('Failed to create new property note', err)
            toast({
              title: t('helpRequests.template.createErrorMessage', {
                name: values.name,
              }),
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
          })
          .finally(() => {
            onCloseModal ? onCloseModal(true) : ''

            setSubmitting(false)
          })
      }
    },
  })

  const onChangeDescriptionHandler = (text: string) => {
    setDescription(text)
    onParentHasUnsavedChanges ? onParentHasUnsavedChanges(true) : ''
  }

  const onDeleteHandler = () => {
    if (!helpRequestTemplate || isDeleting) {
      return
    }
    setIsDeleting(true)
    deleteHelpRequestTemplate(projectId, helpRequestTemplate.id)
      .then(() => {
        // Don't delete attachments until check if used elsewhere is in place

        log.debug('Successfully deleted help-request-template')
        void mutateAllHelpRequestTemplates()
        void mutateProjectHelpRequestTemplates()
        toast({
          title: t(
            'helpRequests.template.helpReqTemplateDeleteSuccessMessage',
            { name: helpRequestTemplate?.name },
          ),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(err => {
        log.error(
          { err: err },
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `Error deleting help request template ${helpRequestTemplate?.id}`,
        )
      })
      .finally(() => {
        setIsDeleting(false)
        // onCloseModal ? onCloseModal(true) : ''
      })
  }

  return (
    <form onSubmit={handleSubmit} id="add-or-edit-help-request-form">
      <Flex flexDir="column" my={4} gap={4}>
        <Input
          title={t('helpRequests.template.addOrUpdateForm.title')}
          name="name"
          type="text"
          defaultValue={initialValues.name}
          isMandatory={true}
          isError={!!errors.name}
          errorText={touched.name && errors.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          position="single"
          variant="plain"
          maxW="md"
        />
        <Flex flexDir="column" justifyContent="left">
          <Text my={1}>{t('helpRequests.timestampText')}</Text>
          <InputDate
            name="helpTimestamp"
            value={formikValues.helpTimestamp}
            isError={!!errors.helpTimestamp}
            errorText={
              touched.helpTimestamp && errors.helpTimestamp
                ? errors.helpTimestamp
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="single"
            w={40}
          />
        </Flex>
        <Box w="100%" maxW="container.lg" mr={6}>
          <Text my={1}>{t('helpRequests.descriptionText')}</Text>
          <Tiptap
            border="1px solid"
            borderColor="gray.400"
            content={helpRequestTemplate?.description}
            w="100%"
            maxH="sm"
            h={40}
            toolbarVariant="basic"
            withTypographyExtension={true}
            withLinkExtension={true}
            onChange={onChangeDescriptionHandler}
            editorLabel={t('notes.addOrUpdateForm.description')}
            area={'HelpRequestTemplate.description'}
            areaId={helpRequestTemplate?.id}
            parentArea={'Project'}
            parentAreaId={projectId}
            grandParentArea="Property"
            container={'private'}
            onImgAdded={imgAddedHandler}
          />
        </Box>
        <Flex
          flexDir={{ xxs: 'column', laptop: isEmbedded ? 'row' : 'column' }}
          maxW="container.lg"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={4}
        >
          <Flex flexDir="column" w="100%">
            {!helpRequestTemplate && (
              <FileManagerBeforeContextId
                variant="menu"
                buttonW="100%"
                my={4}
                attachmentsJson={attachmentsJson}
                setAttachmentsJson={setAttachmentsJson}
                newFiles={newFiles}
                setNewFiles={setNewFiles}
              />
            )}
            {helpRequestTemplate && (
              <FileManager
                variant="menu"
                buttonW={{ xxs: '100%', tablet: 64 }}
                my={4}
                attachmentsJson={attachmentsJson}
                onUploadFiles={uploadFilesHandler}
                onAddExistingAttachments={async (
                  attachmentsToAdd: Attachment[] | AttachmentSummary[],
                ) => {
                  const copiedAttachments = await copyAttachments(
                    attachmentsToAdd,
                    areaData,
                  )

                  // void addAttachmentsToNoteHandler(copiedAttachments)
                  void addAttachmentsToHelpReqeustTemplateHandler(
                    copiedAttachments,
                  )
                }}
                onDeleteAttachments={
                  deleteAttachmentsFromHelpReqTemplateHandler
                }
              />
            )}
          </Flex>
          <HStack
            spacing={4}
            mb={{ xxs: 32, laptop: 0 }}
            justifyContent="right"
            alignItems="flex-start"
            w="100%"
          >
            {helpRequestTemplate && !isEmbedded && (
              <AlertDialogAndButton
                title={t('helpRequests.template.deleteHelpReqTemplate')}
                message={t(
                  'helpRequests.template.deleteHelpReqTemplateQuestion',
                  {
                    name: helpRequestTemplate.name,
                  },
                )}
                buttonTitle={t('ui.button.delete')}
                buttonW={{ xxs: '100%', laptop: 44 }}
                onDestructiveAction={onDeleteHandler}
              />
            )}
            {isEmbedded && helpRequestTemplate && (
              <Button
                type="button"
                w={{ xxs: '100%', laptop: 44 }}
                variant="outline"
                colorScheme="green"
                color="gray.400"
                isDisabled={Object.keys(errors).length !== 0 ? true : false}
                disabled={isSubmitting}
                id="save-helpRequestTemplate"
                onClick={() => {
                  setIsSaveAndSend(false)
                  handleSubmit()
                }}
              >
                {t('ui.button.save')}
              </Button>
            )}

            <Button
              type="button"
              my={2}
              w={{ xxs: '100%', laptop: 44 }}
              variant={
                Object.keys(errors).length !== 0 ? 'disabled' : 'primary'
              }
              disabled={isSubmitting}
              id="save-helpRequestTemplate"
              onClick={() => {
                setIsSaveAndSend(true)
                handleSubmit()
              }}
            >
              {/* {isEmbedded ? t('ui.button.send') : t('ui.button.save')} */}
              {helpRequestTemplate
                ? isEmbedded
                  ? t('ui.button.send')
                  : t('ui.button.save')
                : t('ui.button.send')}
            </Button>
          </HStack>
        </Flex>
      </Flex>

      {(isSubmitting || isDeleting) && <Spinner />}
    </form>
  )
}
export default AddOrEditHelpRequestTemplateForm
