import { filter, orderBy } from 'lodash'
import { HelpRequestResponse } from '@contracts/misc'
import { HelpRequestSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: HelpRequestSortingOptionType,
  helpRequests?: HelpRequestResponse[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption

  const filteredHelpRequests = searchStr
    ? filter(helpRequests, ({ name }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return name.toLowerCase().includes(searchStrLowercase)
        // street.toLowerCase().includes(searchStrLowercase) ||
        // city.toLowerCase().includes(searchStrLowercase)
      })
    : helpRequests

  return orderBy(filteredHelpRequests, sortingField, sortOrder)
}
