import React from 'react'
import ReactDOM from 'react-dom/client'
import { SWRConfig } from 'swr'
import { ChakraProvider } from '@chakra-ui/react'
import { HelmetProvider } from 'react-helmet-async'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { AuthContextProvider } from './common/auth-context'
import ApplicationInsightsProvider from './common/appInsights'
import { berikarTheme } from './styles/theme'
import './i18n/i18n'
import apiConfig from './apiConfig'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthContextProvider>
        <ApplicationInsightsProvider>
          <ChakraProvider resetCSS theme={berikarTheme}>
            <SWRConfig value={apiConfig}>
              <App />
            </SWRConfig>
          </ChakraProvider>
        </ApplicationInsightsProvider>
      </AuthContextProvider>
    </HelmetProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
