import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerContacts.png'
import ProviderContactsSection from './ProviderContactsSection'

const ProviderContactsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Flex flexDir="column" width="100%" bg="primary_grey01">
        <Helmet>
          <title>{t('portal.sidebar.provider.myContacts')}</title>
        </Helmet>
        <ContentPageHeader
          label={`${t('portal.sidebar.provider.myContacts').toUpperCase()}`}
          h={{ xxs: 24, laptop: 32 }}
          pt={{ xxs: 0, laptop: 4 }}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          bgSize="contain"
          backgroundImgSrc={headerBackground}
        />
      </Flex>

      <Text
        textStyle="infoTextLight"
        mt={{ xxs: 4, tablet: 8 }}
        mb={4}
        px={{ xxs: '1%', tablet: '5%' }}
      >
        Kontaktlistan är ett verktyg som hjälper er att hålla koll på era
        kontakter och samarbetspartners, och att effektivisera ert arbete. Den
        gör det enkelt att skicka förfrågningar, inbjudningar och annat.
      </Text>

      <Text
        textStyle="infoTextLight"
        mt={2}
        mb={8}
        px={{ xxs: '1%', tablet: '5%' }}
      >
        Ni kan även ge er potentiella kunder en bra bild av ert företag genom
        att visa era samarbetspartners på er offentliga profilsida. Detta visar
        att ni är ett professionellt företag med ett stort nätverk av andra
        seriösa aktörer.
      </Text>
      <ProviderContactsSection />
    </>
  )
}
export default ProviderContactsPage
