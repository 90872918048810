import { Box, Text, HStack, Image } from '@chakra-ui/react'
import Facebook from '../../img/logos/facebook.png'
import LinkedIn from '../../img/logos/linkedin.png'
import Twitter from '../../img/logos/twitter.png'
import Add from '../../img/logos/vector.png'

const SocialMediaShareComponent: React.FC<{ mt?: number; mb?: number }> = ({
  mt,
  mb,
}) => {
  return (
    <Box mt={mt} mb={mb}>
      <Text textStyle="textBold">Share</Text>
      <HStack spacing={3} pt={1}>
        <Image src={LinkedIn} boxSize={7} />
        <Image src={Twitter} boxSize={7} />
        <Image src={Facebook} boxSize={7} />
        <Image src={Add} boxSize={7} />
      </HStack>
    </Box>
  )
}
export default SocialMediaShareComponent
