import { Flex, Checkbox, Text } from '@chakra-ui/react'
import { OrganizationCollaborator } from '@contracts/accounts'

import { useContext } from 'react'
import { useOrganizationCollaborators } from '../../apiClients/organizationsCollaboratorApiClient'
import AuthContext from '../../common/auth-context'
import log from '../../common/log'
import OrganizationAvatar from '../accounts/OrganizationAvatar'

const ContactsCard: React.FC<{
  isCheckable?: boolean

  checkedContactsList?: OrganizationCollaborator[]
  onCheckedContact?: (orgCollabToAdd: OrganizationCollaborator) => void
}> = ({
  isCheckable = true,

  checkedContactsList,
  onCheckedContact,
}) => {
  const { currentOrganization } = useContext(AuthContext)
  const { data: organizationCollaborators } = useOrganizationCollaborators(
    currentOrganization?.id,
  )

  if (isCheckable && (!onCheckedContact || !checkedContactsList)) {
    log.error(
      'Must provide onCheckedContact callback as well as checkedContacsList when component isCheckable',
    )
  }

  const onCheckedHandler = (orgCollabToAdd: OrganizationCollaborator) => {
    onCheckedContact ? onCheckedContact(orgCollabToAdd) : ''
  }

  return (
    <Flex flexDir="column" gap={1}>
      {organizationCollaborators && organizationCollaborators?.length > 0 && (
        <Text>Mina kontakter</Text>
      )}
      {organizationCollaborators &&
        organizationCollaborators?.length > 0 &&
        organizationCollaborators.map(collab => {
          let isChecked = false
          if (checkedContactsList) {
            if (
              checkedContactsList.find(contact => {
                contact.id === collab.id
              })
            ) {
              isChecked = true
            }
          }
          return (
            <Flex flexDir="row" alignItems="center" key={collab.id}>
              {isCheckable && checkedContactsList && (
                <Checkbox
                  mr={3}
                  defaultChecked={isChecked}
                  onChange={() => {
                    onCheckedHandler(collab)
                  }}
                  color="gray.300"
                  border="2px solid"
                  borderRadius="sm"
                />
              )}
              <OrganizationAvatar
                organizationId={collab.organizationId ?? undefined}
                variant="avatarAndNameOneRow"
              />
              {!collab.organizationId && <Text>{collab.email}</Text>}
            </Flex>
          )
        })}
    </Flex>
  )
}
export default ContactsCard
