import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ClimateResource } from '@contracts/support'

const ProductBadge: React.FC<{
  product: ClimateResource
  onClick: (product: ClimateResource) => void
}> = ({ product, onClick }) => {
  return (
    <Box
      pl={2}
      py={1}
      my={1}
      _hover={{ bg: 'gray.300', cursor: 'default' }}
      borderRadius="lg"
      bg="secondary"
      color="white"
      onClick={() => {
        onClick(product)
      }}
    >
      <Flex flexDir="row" alignItems="center" justifyContent="space-between">
        <Text>{product.name}</Text>
        <CloseIcon boxSize={2} mr={4} />
      </Flex>
    </Box>
  )
}
export default ProductBadge
