import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  ResponsiveValue,
  Show,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { ProductDescriptions2 } from '../../apiClients/contentApiClient'
import { Heading } from '../../ui'

import image_1 from './../../img/pages/publicHome/promoSectionProducts/myProperties.png'
import image_2 from './../../img/pages/publicHome/promoSectionProducts/myProjects.png'
import image_3 from './../../img/pages/publicHome/promoSectionProducts/findExpert.png'
import image_4 from './../../img/pages/publicHome/promoSectionProducts/climateSmartConstruction.png'
import image_5 from './../../img/pages/publicHome/promoSectionProducts/guidance.png'
// import image_6 from './../../img/pages/publicHome/promoSectionProducts/inspiration.png'

const PromoSectionProducts: React.FC<{
  px?: ResponsiveValue<number | string>
  py?: number | string
  mt?: number | string
  bg?: string
}> = ({ px, py, mt, bg = 'primary_grey01' }) => {
  const { t } = useTranslation()
  const [activeProduct, setActiveProduct] = useState(1)

  const getImage = (id: string) => {
    switch (id) {
      case '1':
        return image_1
      case '2':
        return image_2
      case '3':
        return image_3
      case '4':
        return image_4
      case '5':
        return image_5
      // case '6':
      //   return image_6
    }
  }

  return (
    <Flex
      id="section-tell-me-more"
      flexDir="column"
      // justifyContent="left"
      alignItems="center"
      w="100%"
      bg={bg}
      py={py}
      px={px}
      mt={mt}
    >
      <Flex flexDir="column" w="100%" maxW="1000px">
        <Heading textStyle="h3">
          {t('content.publicHomePage.promoSectionProducts.heading')}
        </Heading>
        {/* <Show above="laptop"> */}
        <Show above="desktop">
          <Flex flexDir="row" wrap="wrap">
            {ProductDescriptions2.map(product => {
              return (
                <Button
                  key={product.id}
                  variant={activeProduct === product.id ? 'secondary' : 'ghost'}
                  bg={activeProduct === product.id ? 'secondary' : 'white'}
                  color={activeProduct === product.id ? 'white' : 'gray.500'}
                  w={44}
                  mr={{ xxs: 2, tablet: 4 }}
                  mt={5}
                  h={8}
                  onClick={() => {
                    setActiveProduct(product.id)
                  }}
                  borderRadius="full"
                >
                  {t(
                    `content.publicHomePage.promoSectionProducts.products.${
                      product.title ?? ''
                    }`,
                  )}
                </Button>
              )
            })}
          </Flex>
        </Show>
        {/* <Show below="laptop"> */}
        <Show below="desktop">
          <Flex
            flexDir="row"
            flexWrap="nowrap"
            justifyContent="space-evenly"
            alignItems="center"
            mt={5}
          >
            <ChevronLeftIcon
              fontSize={44}
              color="gray.400"
              onClick={() => {
                setActiveProduct(
                  activeProduct === 1
                    ? ProductDescriptions2.length
                    : activeProduct - 1,
                )
              }}
            />
            {ProductDescriptions2.map(product => {
              return (
                <Badge
                  key={product.id}
                  variant={activeProduct === product.id ? 'secondary' : 'ghost'}
                  bg={activeProduct === product.id ? 'secondary' : 'white'}
                  color={activeProduct === product.id ? 'white' : 'gray.500'}
                  mr={{ xxs: 2, tablet: 4 }}
                  w={{ xxs: 5, tablet: 6 }}
                  h={{ xxs: 5, tablet: 6 }}
                  border="1px solid"
                  borderColor="gray.400"
                  onClick={() => {
                    setActiveProduct(product.id)
                  }}
                  borderRadius="full"
                />
              )
            })}
            <ChevronRightIcon
              fontSize={44}
              color="gray.400"
              onClick={() => {
                setActiveProduct(
                  activeProduct === ProductDescriptions2.length
                    ? 1
                    : activeProduct + 1,
                )
              }}
            />
          </Flex>
        </Show>
        <Flex
          flexDir="row"
          wrap="wrap"
          // justifyContent={{ xxs: 'center', laptop: 'left' }}
          justifyContent={{ xxs: 'center', desktop: 'left' }}
          alignItems="center"
          mt={10}
          // ml={{ desktop: '2.5%' }}
          columnGap={12}
        >
          <Box maxW={{ tablet: 'lg', desktop: 'xs' }} mb={10}>
            <Heading color="primary_orange02" mb={2}>
              {
                ProductDescriptions2.find(product => {
                  return product.id === activeProduct
                })?.heading
              }
            </Heading>
            <Text>
              {
                ProductDescriptions2.find(product => {
                  return product.id === activeProduct
                })?.content
              }
            </Text>
          </Box>

          <Image
            src={getImage(activeProduct.toString())}
            boxShadow="2xl"
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default PromoSectionProducts
