import { Button, Flex, Text } from '@chakra-ui/react'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import '../../apiClients/organizationsApiClient'
import {
  createInstagramConnection,
  endInstagramConnection,
  useInstagramMedia,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import log from '../../common/log'
import {
  PATH_MY_PROVIDER_PAGES_ORG_PROFILE,
  PATH_PORTAL,
} from '../../common/nav'
import { isOutOfDateOrInvalid } from '../../common/util'
import { Icon } from '../../components/ui/Icon'

const ManageInstaSection: React.FC<{
  accessCode?: string
  connectionWasCreated?: boolean
  setConnectionWasCreated?: Dispatch<SetStateAction<boolean>>
}> = ({ accessCode, connectionWasCreated, setConnectionWasCreated }) => {
  let redUri = `${PATH_PORTAL}${PATH_MY_PROVIDER_PAGES_ORG_PROFILE}`
  const [failedToConnectInsta, setFailedToConnectInsta] = useState(false)
  const navigate = useNavigate()

  if (redUri.includes('localhost')) {
    redUri = `https://localhost:3000${PATH_MY_PROVIDER_PAGES_ORG_PROFILE}`
  }

  const { currentOrganization } = useContext(AuthContext)

  const { data: currentOrgProfile, mutate: mutateProfile } =
    useOrganizationProfile(currentOrganization?.id)

  const appId = '3345769445714471'
  const url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`

  const { data: instaPosts, mutate: mutateInstaPosts } = useInstagramMedia(
    isOutOfDateOrInvalid(currentOrgProfile?.instagramTokenValidTo ?? undefined)
      ? undefined
      : currentOrganization?.id,
  )
  let instaAccountUserName: string | undefined = undefined

  instaAccountUserName = instaPosts?.length ? instaPosts[0].username : undefined

  const startInsta = () => {
    window.open(url, '_blank')?.focus()
  }

  const createInstagramHandler = () => {
    if (currentOrganization && accessCode) {
      createInstagramConnection(currentOrganization?.id, {
        code: accessCode,
        redirectUrl: redUri,
      })
        .then(async res => {
          log.debug('Successfylly connected insta account: ', res)
          setConnectionWasCreated ? setConnectionWasCreated(true) : ''
          // eslint-disable-next-line no-console
          // console.log('profile: ', currentOrgProfile)
          // eslint-disable-next-line no-console
          // console.log('instaPosts before: ', instaPosts)
          // eslint-disable-next-line no-console
          // console.log('Before mutate')
          await mutateProfile()
          await mutateInstaPosts()
          // eslint-disable-next-line no-console
          // console.log('Efter mutate')
          // eslint-disable-next-line no-console
          // console.log('profile: ', currentOrgProfile)
          // eslint-disable-next-line no-console
          // console.log('instaPosts after: ', instaPosts)
        })
        .catch(err => {
          log.error('Failed to connect insta account:  ', err)
          setFailedToConnectInsta(true)
        })
      navigate(PATH_MY_PROVIDER_PAGES_ORG_PROFILE)
      void mutateProfile()
    }
  }
  useEffect(() => {
    if (
      accessCode &&
      currentOrganization &&
      isOutOfDateOrInvalid(
        currentOrgProfile?.instagramTokenValidTo ?? undefined,
      )
    ) {
      void createInstagramHandler()
    }
  }, [accessCode, currentOrganization])

  if (
    isOutOfDateOrInvalid(
      currentOrgProfile?.instagramTokenValidTo ?? undefined,
    ) &&
    !connectionWasCreated
  ) {
    // Has NOT valid insta connection
    return (
      <Flex flexDir="column" mx={0} my={4} maxW="container.sm" gap={4}>
        <Text fontWeight="bold">
          Håll er profil uppdaterad utan att uppdatera på flera ställen
          {/* Keep your profile updated without the need to update content in
          several places */}
        </Text>
        <Text>
          Anslut ert Instagram-konto och håll er företagsprofil uppdaterad utan
          att uppdatera på flera ställen. När ni publicerar ett nytt inlägg på
          Instagram kommer det automatiskt att visas på er profilsida här på
          Berikar. Ni stärker er profil och potentiella kunder får en bättre
          inblick och förståelse för er verksamhet och projekt.
          {/* Connect your Instagram account and keep your provider profile here
          within Berikar updated without having to update content i numerous
          places. Your Instagram posts will then automatically be presented on
          your profile page for any logged-in consumer to view. By doing this
          you will strengthen your own brand and better attract potential
          clients. */}
        </Text>
        <Text>
          Det här är en enkel och smidig lösning som gör att ni sparar massor av
          tid och energi.
          {/* This is a simple and straight-forward solution that will save you a
          lot of time and energy. */}
        </Text>
        {failedToConnectInsta && (
          <Text textStyle="errorSmall" my={2}>
            Kopplingen till valt Instagram-konto misslyckades
            {/* The connection to provided Instagram account failed */}
          </Text>
        )}
        {/* Has an insta connection that is out of date */}
        {currentOrgProfile?.instagramTokenValidTo && (
          <Text textStyle="errorSmall" my={2}>
            Koppling till insta-konto har gått ut, den behöver förnyas
          </Text>
        )}
        {/* <Button onClick={() => startInsta()} variant="secondary" maxW={48}> */}
        <Button onClick={() => startInsta()} variant="secondary" maxW={60}>
          Anslut instagram-konto
          {/* Connect Instagram account */}
        </Button>
      </Flex>
    )
  }

  // Has valid insta connection
  return (
    <Flex flexDir="column" mx={0} my={4} maxW="container.sm" gap={2}>
      <Text fontWeight="bold">
        Följande instagram-konto är anslutet till er företagsprofil
        {/* The following Instagram account is connected to your provider profile
        (see your provider profile end-result further below) */}
      </Text>

      <Text textStyle="regularLight">
        När ni publicerar ett nytt inlägg på Instagram kommer det automatiskt
        att visas på er profilsida här på Berikar. (se slutresultatet nedanför)
        {/* When you publish a new Instagram post it will automatically be presented
        in your profile page here within Berikar (see the end-result further
        below) */}
      </Text>
      <Flex gap={2} my={4}>
        <Icon name="insta" h={7} />{' '}
        <Text textStyle="h4ima">{instaAccountUserName}</Text>
      </Flex>
      <Button
        onClick={() => {
          if (currentOrganization?.id) {
            endInstagramConnection(currentOrganization.id)
              .then(() => {
                void mutateProfile()
                setConnectionWasCreated ? setConnectionWasCreated(false) : ''
              })
              .catch(err => {
                log.error('Failed to remove instagram connection', err)
              })
          }
        }}
        variant="secondary"
        // maxW={{ xxs: '100%', tablet: 44 }}
        maxW={{ xxs: '100%', tablet: 60 }}
      >
        Ta bort koppling till Instagram
        {/* Remove Instagram connection */}
      </Button>
    </Flex>
  )
}
export default ManageInstaSection
