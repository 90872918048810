import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import {
  Address,
  PropertyInfoResponse,
  PropertyResponse,
} from '@contracts/misc'
import {
  PropertiesResponseData,
  PropertyType,
  UpdatePropertyRequest,
} from '@contracts/properties'
import { useContext } from 'react'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROPERTIES_SERVICE_URL

const propertiesFetcher = async (
  urlKey: string,
): Promise<PropertyResponse[] | undefined> => {
  return axios
    .get<PropertiesResponseData>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data.properties
    })
}

export const usePropertyTypes = (): SWRResponse<PropertyType[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWRImmutable<
    PropertyType[] | undefined
  >(`${baseUrl}/property-types`, { revalidateOnFocus: false })
  return { data, error, isValidating, mutate }
}

export const useProperty = (
  propertyId: string | undefined,
): SWRResponse<PropertyResponse | undefined> => {
  return useSWR<PropertyResponse | undefined>(
    propertyId ? `${baseUrl}/properties/${propertyId}` : null,
    { revalidateOnFocus: false },
  )
}

export const usePropertyInfo = (
  propertyId: string | undefined,
): SWRResponse<PropertyInfoResponse | undefined> => {
  return useSWR<PropertyInfoResponse | undefined>(
    propertyId ? `${baseUrl}/properties/${propertyId}/info` : null,
    { revalidateOnFocus: false },
  )
}

export const usePropertyAddress = (
  propertyId: string | undefined,
): SWRResponse<Address | undefined> => {
  return useSWR<Address | undefined>(
    propertyId ? `${baseUrl}/properties/${propertyId}/address` : null,
    { revalidateOnFocus: false },
  )
}

// Only admin can provide accountId, normal users can only fetch for self/currentUser
export const useProperties = (
  accountId?: string,
): SWRResponse<PropertyResponse[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<PropertyResponse[] | undefined>(
    // accountId ? `/admin/properties?accountId=${accountId}` : '/properties/',
    isAdmin && accountId
      ? `/admin/properties?accountId=${accountId}`
      : '/properties/',
    propertiesFetcher,
  )
}

export const useAllPropertiesAdmin = (): SWRResponse<
  PropertyResponse[] | undefined
> => {
  return useSWR<PropertyResponse[] | undefined>(
    '/admin/properties',
    propertiesFetcher,
  )
}
export const usePropertiesByAccount = (
  accountId: string | undefined,
): SWRResponse<PropertyResponse[] | undefined> => {
  return useSWR<PropertyResponse[] | undefined>(
    accountId ? `/properties/accounts?accountId=${accountId}` : null,
    propertiesFetcher,
  )
}

export const addProperty = async (
  name: string,
  propertyTypeId: string,
  street: string,
  zipCode: string,
  city: string,
) => {
  return axios.post(
    `${baseUrl}/properties/`,
    { name, propertyTypeId, street, zipCode, city },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateProperty = (
  id: string,
  requestData: UpdatePropertyRequest,
) => {
  return axios
    .put(`${baseUrl}/properties/${id}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated property', status, data)
      return data
    })
}

export const deleteProperty = async (propertyId: string) => {
  return axios.delete(`${baseUrl}/properties/${propertyId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
