import { Helmet } from 'react-helmet-async'
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext } from 'react'
import EditPropertySection from '../../components/sections/portal/EditPropertySection'
import MyNotesSection from '../../components/sections/portal/MyNotesSection'
import MyProjectsSection from '../../components/sections/portal/MyProjectsSection'
import PropertyHeaderSection from '../../components/sections/portal/PropertyHeaderSection'
import {
  useProperty,
  usePropertyTypes,
} from '../../apiClients/propertiesApiClient'
import { AreaContext } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import MyMessageSection from '../../components/sections/portal/MyMessageSection'
import CollaboratorsPage from '../../components/collaborators/CollaboratorsPage'
import Modal from '../../components/ui/Modal'
import NoteCardMedium from '../../components/notes/NoteCardMedium'
import { useNote } from '../../apiClients/propertiesApiClient/notes'
import ImagesWidget from '../../components/projects/ImagesWidget'
import { PATH_MY_PRIVATE_PROPERTIES } from '../../common/nav'
import DocumentsWidget from '../../components/projects/DocumentsWidget'

const PropertyPage = () => {
  const { id: propertyId } = useParams()
  const [search] = useSearchParams()
  const noteId = search.get('noteId')
  const navigate = useNavigate()

  const { isProvider } = useContext(AuthContext)
  const { t } = useTranslation()
  const { isConsumer } = useContext(AuthContext)

  const { data: property } = useProperty(propertyId)
  const { data: allPropertyTypes } = usePropertyTypes()
  const { data: targetNote } = useNote(noteId ?? undefined)

  const { isOpen: isOpenNoteCardMedium, onOpen: onOpenNoteCardMedium } =
    useDisclosure({ defaultIsOpen: true })

  if (!property || !allPropertyTypes) {
    return null
  }

  if (!propertyId) {
    return null
  }

  const onCloseNoteCardMedium = () => {
    navigate(`${PATH_MY_PRIVATE_PROPERTIES}/${propertyId}`)
  }

  return (
    <AreaContext.Provider value={{ area: 'property', areaId: propertyId }}>
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>
                  {t('pageTitle.property', { name: property.name })}
                </title>
              </Helmet>
              <PropertyHeaderSection property={property} />

              <Flex
                flexDir="row"
                wrap="wrap"
                mt={8}
                // mb={showGuidancePromos ? 0 : 40}
                w="100%"
                px={{ xxs: '0%', tablet: '5%' }}
                pt={4}
              >
                <Tabs
                  variant="line"
                  w="100%"
                  isLazy={true}
                  colorScheme="orange"
                  defaultIndex={0}
                  // border="1px solid red"
                  px={0}
                >
                  <TabList flexWrap="wrap" justifyContent="space-evenly">
                    <Tab px={2}>
                      <Text textStyle="h7">Översikt</Text>
                    </Tab>
                    <Tab px={2}>
                      <Text textStyle="h7">Anteckningar</Text>
                    </Tab>

                    {/* <Tab px={2}>
                      <Text textStyle="h7">Förfrågningar</Text>
                    </Tab> */}

                    <Tab px={2}>
                      <Text textStyle="h7">Medlemmar</Text>
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel px={0}>
                      <Flex flexDir="column" px={2}>
                        {!isProvider && (
                          <>
                            <Flex
                              w="100%"
                              gap={4}
                              my={4}
                              wrap={{ xxs: 'wrap', laptop: 'nowrap' }}
                              justifyContent={{
                                // xxs: 'space-around',
                                xxs: 'left',
                                tablet: 'left',
                              }}
                            >
                              <Flex
                                basis={{ xxs: '280px', desktop: '330px' }}
                                grow={1}
                                maxW="sm"
                              >
                                <DocumentsWidget
                                  variant="important-documents-property"
                                  propertyId={propertyId}
                                  onOpenNoteCard={onOpenNoteCardMedium}
                                  minW={{ xxs: '280px', desktop: '330px' }}
                                  maxW="md"
                                  minH="150px"
                                  maxH="150px"
                                />
                              </Flex>
                              <Flex
                                flexGrow={{ xxs: 1, laptop: 0.5 }}
                                basis={{ xxs: '220px', desktop: '330px' }}
                              >
                                {/* <NoteImagesWidget */}
                                <ImagesWidget
                                  variant="property"
                                  propertyId={propertyId}
                                  thumbnailSize={{
                                    xxs: 16,
                                    tablet: 20,
                                    laptop: 24,
                                  }}
                                  minH="150px"
                                  maxH="150px"
                                />
                              </Flex>
                            </Flex>
                            <MyMessageSection
                              area="Property"
                              areaId={property.id}
                              areaFriendlyName={property.name}
                              areaFriendlyParentName={''}
                            />
                          </>
                        )}

                        {!isProvider && (
                          <MyProjectsSection
                            property={property}
                            // mx="5%"
                            mb={40}
                          />
                        )}
                      </Flex>
                    </TabPanel>
                    <TabPanel px={0}>
                      <MyNotesSection propertyId={property.id} mx={2} />
                    </TabPanel>
                    {/* <TabPanel px={0}></TabPanel> */}
                    <TabPanel px={0}>
                      <CollaboratorsPage property={property} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
              {targetNote && (
                <Modal
                  isOpen={isOpenNoteCardMedium}
                  onClose={onCloseNoteCardMedium}
                  onOpen={onOpenNoteCardMedium}
                  size="lg"
                >
                  <NoteCardMedium
                    note={targetNote}
                    propertyId={propertyId}
                    onClose={onCloseNoteCardMedium}
                  />
                </Modal>
              )}
            </>
          }
        />
        {isConsumer && (
          <Route
            path="/edit/"
            element={
              <>
                <Helmet>
                  <title>
                    {t('pageTitle.property', { name: property.name })}
                  </title>
                </Helmet>

                <PropertyHeaderSection property={property} isInEditMode />
                <EditPropertySection property={property} />
              </>
            }
          />
        )}
      </Routes>
    </AreaContext.Provider>
  )
}
export default PropertyPage
