import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Text,
  Avatar,
} from '@chakra-ui/react'

import { PropertySortingOption } from '../../common/enums'
import SortableHeading from '../../components/ui/SortableHeading'
import { AreaContext } from '../../common/area-context'
import { usePropertyTypes } from '../../apiClients/propertiesApiClient'
import { calculateSortedItems } from '../../components/properties/propertiesListUtils'
import PropertyAdminSummaryCard from '../../components/properties/PropertyAdminSummaryCard'
import { useAllPropertiesAdmin } from '../../apiClients/propertiesApiClient/properties'
const baseUrlPrivate = `${window.AZURE_BLOB_STORAGE_BASE_URL}/private`

const ListProperties: React.FC<{ searchPropertyId?: string | undefined }> = ({
  searchPropertyId,
}) => {
  const { t } = useTranslation()

  const [sortType, setSortType] = useState(
    PropertySortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const { data: allProperties } = useAllPropertiesAdmin()
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const { data: propertyTypes } = usePropertyTypes()
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const sortedProperties = calculateSortedItems(
    sortType,
    allProperties,
    searchStr,
  )

  const getPropertyNameAndType = (propertyTypeId: string) => {
    return propertyTypes?.find(pt => {
      return pt.id === propertyTypeId
    })?.name
  }

  return (
    <Box pb={40}>
      <>
        <Helmet>
          <title>{t('providers.registeredAccounts')}</title>
        </Helmet>

        <Flex flexDir="row" alignItems="center" flexWrap="wrap" mt={6} mb={6}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" />}
            />
            <Input
              type="text"
              placeholder={t('accounts.searchHomes')}
              maxW="md"
              borderColor="gray.400"
              height={10}
              onChange={e => setSearchStr(e.target.value)}
            />
          </InputGroup>
          <Text ml={4} textStyle="textMedium">
            {t('properties.searchInfo', {
              total: allProperties?.length ?? 0,
              filtered: sortedProperties.length ?? 0,
            })}
          </Text>
        </Flex>

        <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
          <TableCaption>{t('accounts.listingBerikarAccounts')}</TableCaption>
          <Thead>
            <Tr>
              <Th></Th>

              <Th>{t('properties.propertyForm.propertyType')}</Th>
              <Th>
                <SortableHeading
                  onAscSort={() => setSortType(PropertySortingOption.NAME_ASC)}
                  onDescSort={() =>
                    setSortType(PropertySortingOption.NAME_DESC)
                  }
                >
                  {t('properties.propertyForm.name')}
                </SortableHeading>
              </Th>

              <Th>
                <SortableHeading>{t('properties.id')}</SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(PropertySortingOption.CREATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(PropertySortingOption.CREATED_DATE_DESC)
                  }
                >
                  {t('accounts.createdAt')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(PropertySortingOption.UPDATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(PropertySortingOption.UPDATED_DATE_DESC)
                  }
                >
                  {t('accounts.updatedAt')}
                </SortableHeading>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedProperties && sortedProperties.length ? (
              sortedProperties.map((property, i) => {
                let bg: string
                if (property.id === searchPropertyId) {
                  bg = 'red.100'
                } else {
                  bg = i % 2 !== 0 ? 'white' : 'primary_grey01'
                }

                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const imgUrl = `${baseUrlPrivate}/${property.imageAttachmentId}`
                return (
                  <Fragment key={property.id}>
                    <Tr bg={bg} data-cy="accountTag">
                      <Td
                        color="primary_orange02"
                        _hover={{ color: 'primary_orange01' }}
                      >
                        {!accordionsToShow.includes(property.id) && (
                          <FaRegPlusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(property.id)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(property.id) && (
                          <FaRegMinusSquare
                            fontSize={20}
                            fontWeight="thin"
                            onClick={() => {
                              toggleAccordion(property.id)
                            }}
                          />
                        )}
                      </Td>
                      <Td px={0} py={1}>
                        <Flex flexDir="row" gap={1} maxW={44} wrap="wrap">
                          {/* {property.projectCategories?.map(category => {
                            return (
                              <Badge key={category.id}>{category.name}</Badge>
                            )
                          })} */}
                          {getPropertyNameAndType(property.propertyTypeId)}
                        </Flex>
                      </Td>
                      <Td>
                        <Flex alignItems="center">
                          <Avatar
                            name={property.name}
                            src={imgUrl}
                            size="md"
                            mr={2}
                          />
                          {property.name}
                        </Flex>
                      </Td>

                      <Td>
                        <Tooltip
                          hasArrow
                          label={property.id}
                          bg="primaryBackground"
                          color="black"
                          cursor="pointer"
                          placement="top"
                        >
                          <Box>{property.id.substring(0, 15)}...</Box>
                        </Tooltip>
                      </Td>

                      <Td>
                        {new Date(property.createdAt).toLocaleString('sv-SE')}
                      </Td>
                      <Td>
                        {new Date(property.updatedAt).toLocaleString('sv-SE')}
                      </Td>
                    </Tr>
                    {accordionsToShow.includes(property.id) && (
                      <Tr bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}>
                        <AreaContext.Provider value={{ area: 'property' }}>
                          <Td colSpan={9}>
                            <PropertyAdminSummaryCard
                              propertyId={property.id}
                              headerBg={
                                i % 2 !== 0 ? 'white' : 'primary_grey01'
                              }
                            />
                          </Td>
                        </AreaContext.Provider>
                      </Tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>{t('accounts.noAccountsFound')}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </>
    </Box>
  )
}
export default ListProperties
