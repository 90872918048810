import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMbFromBytes } from '../../common/util'
import { useAttachments } from '../../apiClients/attachmentsApiClient'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const AddOrEditImage: React.FC<{
  imgId?: string
  onCloseModal: () => void
  saveImgHandler: (inputProp: string, inputValue: string) => void
  inputCallbackName?: string
}> = ({ onCloseModal, imgId, saveImgHandler, inputCallbackName }) => {
  const { t } = useTranslation()

  const { data: allArticleImgs } = useAttachments(['Article'])

  const [image, setImage] = useState(
    allArticleImgs?.find(img => {
      return img.id === imgId
    }),
  )

  return (
    <>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('articles.chooseImage')}</Box>
      </Box>
      <Flex flexDir="column" my={6} mx={8}>
        {image?.id && (
          <Image
            src={`${baseUrl}${image.id}`}
            w="sm"
            mt={4}
            alignSelf="center"
          />
        )}
        <Flex flexDir="column" ml={10}>
          <Text>{image?.id}</Text>
          <Text>{image?.originalFilename}</Text>
          <Text>
            {image?.fileSizeBytes
              ? `${getMbFromBytes(image?.fileSizeBytes)} MB`
              : ''}
          </Text>
        </Flex>
      </Flex>

      <Box display="flex" flexDirection="column" my={4} mx={6}>
        <Button
          my={4}
          variant="primary"
          onClick={() => {
            if (image?.id && inputCallbackName) {
              saveImgHandler(inputCallbackName, image?.id)
            }
            onCloseModal()
          }}
        >
          {t('input.submitForm')}
        </Button>
      </Box>
      <Flex flexDir="row" flexWrap="wrap" mx={8}>
        {allArticleImgs?.map(img => {
          return (
            <Flex
              key={img.id}
              flexDir="row"
              alignItems="center"
              border="1px"
              borderColor="gray.300"
              borderRadius="lg"
              w="lg"
              mb={2}
              _hover={{
                bg: 'gray.100',
              }}
              onClick={() => {
                setImage(img)
              }}
            >
              <Image src={`${baseUrl}${img.id}`} h={32} alignSelf="center" />

              <Flex flexDir="column" ml={4}>
                <Text>{img.originalFilename}</Text>

                <Text>
                  {img.fileSizeBytes
                    ? `${getMbFromBytes(img.fileSizeBytes)} MB`
                    : ''}
                </Text>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </>
  )
}
export default AddOrEditImage
