import { Button, useDisclosure } from '@chakra-ui/react'
import { ReactElement } from 'react'
import Modal from '../ui/Modal'
import CreateConstructionGlossaryItem from './CreateConstructionGlossaryItem'
const AddConstructionGlossaryItemButton: React.FC<{
  mt?: number
  w?: number
  children: ReactElement
}> = ({ mt, children, w }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} mt={mt} variant="primary" w={w || 32}>
        {children}
      </Button>

      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <CreateConstructionGlossaryItem onCloseModal={onClose} />
      </Modal>
    </>
  )
}
export default AddConstructionGlossaryItemButton
