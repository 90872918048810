import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@chakra-ui/react'

import log from '../../common/log'
import { addArticle } from '../../apiClients/articlesApiClient'
import ListArticles from '../../components/articles/ListArticles'

const ManageArticles = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const addNewArticleHandler = () => {
    addArticle({})
      .then(res => {
        log.info('Successfully created new article', res)
        navigate(String(res.id))
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to create new article')
      })
  }

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('articles.manageArticles')}</title>
      </Helmet>
      <Box pos="absolute" top={44} right={6}>
        <Button
          data-cy="add-articles-link"
          variant="primary"
          onClick={addNewArticleHandler}
        >
          {t('articles.writeArticle')}
        </Button>
      </Box>
      <ListArticles />
    </Box>
  )
}

export default ManageArticles
