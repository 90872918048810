import { Box, ResponsiveValue, useToast } from '@chakra-ui/react'
import { AttachmentSummary, Note, UpdateNoteRequest } from '@contracts/misc'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'

import { Attachment, ContextArea } from '@contracts/support'
import {
  addAttachment,
  useAttachmentsByAreaQuery,
} from '../../apiClients/attachmentsApiClient'
import { useAllNotes as useAllProjectNotes } from '../../apiClients/projectsApiClient/notes'
import { useAllNotes as useAllPropertyNotes } from '../../apiClients/propertiesApiClient/notes'
import { useArea } from '../../common/area-context'
import log from '../../common/log'
import FeedbackPopover from '../feedback/FeedbackPopover'
import { useProjectInfo } from '../../apiClients/projectsApiClient/projects'
import console from '../../common/log'

import FileManager from '../ui/FileManager'
import {
  copyExistingAttachmentsToNoteHandler,
  latestNotesMaxAgeInMonths,
  useNoteParts,
} from './notesHelper'

export const MAX_LENGTH_NOTE_TITLE = 255

export interface AreaData {
  area: ContextArea
  areaId?: string
  parentArea?: ContextArea
  parentAreaId?: string
  grandParentArea?: ContextArea
  grandParentAreaId?: string
}

const AddOrEditNoteFiles: React.FC<{
  note: Note
  propertyId?: string
  projectId?: string
  onCloseModal: (avoidAlertDialog?: boolean) => void
  onNoteHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  px?: ResponsiveValue<string | number>
}> = ({
  note,
  propertyId,
  projectId,
  // onCloseModal
  onNoteHasUnsavedChanges,
  px,
}) => {
  const noteArea = useArea()

  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }
  // console.log('note: ', note)
  // console.log('area: ', noteArea.area)
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()

  const toast = useToast()

  const { entityId, mutate, updateNote } = useNoteParts({
    propertyId,
    projectId,
  })

  const { mutate: mutateAttachments } = useAttachmentsByAreaQuery([
    {
      area: propertyId ? 'PropertyNote' : 'ProjectNote',
      areaId: note.id,
    },
  ])

  const { mutate: mutateAllPropertyNotes } = useAllPropertyNotes(
    latestNotesMaxAgeInMonths,
  )
  const { mutate: mutateAllProjectNotes } = useAllProjectNotes(
    latestNotesMaxAgeInMonths,
  )

  // const { data: projectNote } = useNotes(
  //   noteArea.area === 'project' ? note.id : undefined,
  // )

  const { data: project } = useProjectInfo(projectId)

  // const [attachmentsJson, setAttachmentsJson] = useState<AttachmentSummary[]>(
  //   note?.attachmentsJson ?? [],
  // )
  // const [attachmentIdsToDelete, setAttachmentIdsToDelete] = useState<string[]>(
  //   [],
  // )
  // const [attachmentsToChangeName, setAttachmentsToChangeName] = useState<
  //   { attachmentId: string; newName: string; mimeType: string }[]
  // >([])
  // const [newFiles, setNewFiles] = useState<File[]>([])

  const updateNoteHandler = (requestData: UpdateNoteRequest) => {
    updateNote(note.id, requestData)
      .then(async () => {
        applicationInsights.trackEvent({
          name:
            noteArea.area === 'property'
              ? 'Property Note updated'
              : 'Project Note updated',
        })
        onNoteHasUnsavedChanges(false)
        mutate ? await mutate() : ''
        toast({
          title: t('notes.updateSuccessMessage', { name: note.name }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        await mutateAttachments()
        await mutateAllPropertyNotes()
        await mutateAllProjectNotes()

        // await mutateContextNotes()
      })
      .catch(err => {
        log.error('Failed to update note', err)
        toast({
          title: t('notes.updateErrorMessage', { name: note.name }),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .finally(() => {
        // onCloseModal(true)
        // setSubmitting(false)
      })
  }

  const addAttachmentsToNoteHandler = (
    attachmentsToAdd: Attachment[] | AttachmentSummary[],
  ) => {
    const requestData: UpdateNoteRequest = {
      name: note.name,

      noteTimestamp: note.noteTimestamp,
      starred: note.starred,
      entityId,
      status: note.status,
      noteTypeIds: note.noteTypeIds,

      attachmentsJson: [...(note.attachmentsJson ?? []), ...attachmentsToAdd],
      description: note.description,
    }
    updateNoteHandler(requestData)
    // void mutateNote()
  }

  const deleteAttachmentsFromNoteHandler = (
    attachmentIdsToDelete: string[],
  ) => {
    // Remove attachment
    // await deleteAttachment(attachmentIdToDelete)
    // console.log(`Attachment '${attachmentIdToDelete}' was deleted`)

    console.log('delete from note attachment: ', attachmentIdsToDelete)

    const updatedAttachmentsJson = note.attachmentsJson
      ? note.attachmentsJson.filter(a => {
          // console.log(`${a.id} ==? xxx`)
          if (attachmentIdsToDelete.includes(a.id)) {
            return false
          }

          return true
        })
      : []

    console.log('attachments after delete: ', updatedAttachmentsJson)

    // Remove attachment from attachmentsJson
    const requestData: UpdateNoteRequest = {
      name: note.name,
      noteTimestamp: note.noteTimestamp,
      starred: note.starred,
      entityId,
      status: note.status,
      noteTypeIds: note.noteTypeIds,
      attachmentsJson: updatedAttachmentsJson,

      description: note.description,
      // return Promise.resolve()
    }
    void updateNoteHandler(requestData)
    console.log(`Note '${entityId}' was updated after attachment deleted`)

    // Mutate note
    // void mutateNote()
  }

  const uploadFilesHandler = async (newFilesToAdd: File[]): Promise<void> => {
    console.log('uploadFilesHandler **********')
    const persistedAttachments = await Promise.all(
      newFilesToAdd.map(async file => {
        const formData = new FormData()
        formData.set('image', file)

        if (noteArea.area === 'property') {
          formData.set('area', 'PropertyNote')
          formData.set('parentArea', 'Property')
          if (propertyId) {
            formData.set('parentAreaId', propertyId)
          }
        } else {
          formData.set('area', 'ProjectNote')
          if (projectId) {
            formData.set('parentArea', 'Project')
            formData.set('parentAreaId', projectId)
            formData.set('grandParentArea', 'Property')
            if (project && project.propertyId) {
              formData.set('grandParentAreaId', project.propertyId)
            }
          }
        }
        if (note?.id) {
          formData.set('areaId', note.id)
        }

        const newAttachment = await addAttachment(formData, true)

        return newAttachment
      }),
    )
    // await addExistingAttachmentsToNoteHandler(persistedAttachments, false)
    // await addAttachmentsToNoteHandler(persistedAttachments)
    void addAttachmentsToNoteHandler(persistedAttachments)
  }

  const mutateNote = async () => {
    console.log("let's mutate note")
    console.log('before: ', note.attachmentsJson)
    // mutate ? void mutate() : ''
    mutate ? await mutate() : ''
    // toast({
    //   title: t('notes.updateSuccessMessage', { name: note.name }),
    //   status: 'success',
    //   duration: 9000,
    //   isClosable: true,
    //   position: 'top',
    // })
    await mutateAllPropertyNotes()
    await mutateAllProjectNotes()
    void mutateAllPropertyNotes()
    void mutateAllProjectNotes()

    console.log('after mutate - note.attachmentsJson: ', note.attachmentsJson)
  }

  return (
    <>
      <Box
        h={16}
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          Redigera bifogade filer
        </Box>

        <FeedbackPopover context="notes" />
      </Box>

      {/* <form onSubmit={handleSubmit} id="add-or-edit-note-form"> */}
      <FileManager
        variant="menu"
        buttonW="100%"
        my={4}
        attachmentsJson={note.attachmentsJson ?? []}
        onUploadFiles={uploadFilesHandler}
        onAddExistingAttachments={async (
          attachmentsToAdd: Attachment[] | AttachmentSummary[],
        ) => {
          const copiedAttachments = await copyExistingAttachmentsToNoteHandler(
            attachmentsToAdd,
            note.id,
            noteArea.area === 'property' ? 'PropertyNote' : 'ProjectNote',
            propertyId,
            projectId,
            project,
          )

          void addAttachmentsToNoteHandler(copiedAttachments)
        }}
        onDeleteAttachments={deleteAttachmentsFromNoteHandler}
        px={px}
        mutateParent={mutateNote}
      />
    </>
  )
}
export default AddOrEditNoteFiles
