import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react'
import Heading from '../ui/Heading'

export const CardBuildingPermit: React.FC<{ px?: number | string }> = ({
  px,
}) => {
  return (
    <Box textStyle="infoTextLight" px={px}>
      <Heading textStyle="h6" mb={2}>
        Du behöver oftast bygglov om du vill bygga en ny byggnad, bygga till
        eller göra vissa andra ändringar. Du kan även behöva bygglov för att
        sätta upp ett plank eller en mur, eller anordna ett upplag på din tomt.
        Ibland måste du göra en anmälan även om du inte behöver bygglov.
      </Heading>
      <Text>
        <br />
        Det är kommunen som beslutar i bygglovsfrågor och kan besluta om minskad
        eller utökad bygglovsplikt. Det framgår i så fall av detaljplanen eller
        områdesbestämmelserna för det aktuella området. Minskad bygglovsplikt
        innebär att det inte behövs bygglov för åtgärder som kräver det i
        vanliga fall. Utökad bygglovsplikt innebär att det behövs bygglov för
        åtgärder som inte kräver det i vanliga fall. För att få reda på vad som
        gäller för just din fastighet behöver du kontrollera gällande detaljplan
        och kontakta byggnadsnämnden i din kommun. De flesta kommuner har bra
        information på sina hemsidor om vad som gäller i din kommun.
      </Text>

      <Accordion maxW="container.xl" mt={6} defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">
                  Bra att veta när du söker bygglov
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              <Box pl={6}>
                <ul>
                  <li>
                    Kommunen avgör med stöd av plan- och bygglagstiftningen om
                    det du ska göra kräver bygglov eller anmälan. Kontakta
                    därför alltid kommunen innan du gör något som kan kräva
                    bygglov eller anmälan.
                  </li>
                  <li>
                    Byggnadsnämnden har en serviceskyldighet, vilket innebär att
                    de till exempel ger dig upplysningar om vad som kräver
                    bygglov eller anmälan och hur du gör en ansökan.
                  </li>
                  <li>
                    Du som söker bygglov är skyldig att skicka de handlingar
                    byggnadsnämnden behöver för att kunna bedöma din ansökan.
                    Det är kommunen som avgör vilka ansökningshandlingar du
                    behöver komma in med för att de ska kunna genomföra
                    prövningen.
                  </li>
                  <li>
                    Även om bygglovet har blivit beviljat får du inte påbörja
                    byggarbetet förrän du har fått ett startbesked från
                    byggnadsnämnden, annars kan du bli tvungen att betala en
                    byggsanktionsavgift.
                  </li>
                  <li>
                    Det är du som är byggherre som ska se till att varje
                    byggåtgärd genomförs enligt kraven i plan- och bygglagen och
                    de andra föreskrifter eller beslut som gäller för åtgärden.
                  </li>
                  <li>
                    Det är till länsstyrelsen du kan överklaga om
                    byggnadsnämnden avslår din ansökan eller beslutar att inte
                    ge dig startbesked. Länsstyrelsen prövar självständigt ditt
                    överklagande. Information om hur du överklagar kommer finnas
                    i beslutet från byggnadsnämnden.
                  </li>
                </ul>
                <br />
              </Box>
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">
                  Generellt kräver följande åtgärder bygglov
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Heading textStyle="h8" color="logo_brown">
              Nybyggnad & Tillbyggnad
            </Heading>
            <Text>
              Att bygga en ny byggnad eller göra en tillbyggnad kräver oftast
              bygglov. En tillbyggnad är när du ökar en byggnads volym åt något
              håll, antingen uppåt, nedåt eller åt sidan. Även byggnader utan
              väggar, till exempel större skärmtak eller carportar, kräver
              bygglov. Även vissa saker som du kanske inte tänker på som
              byggnader räknas som byggnader. Exempel på det är husbåtar,
              husvagnar och serveringsvagnar som står uppställda på samma plats
              under lång tid. Att flytta en byggnad till en annan plats räknas
              som rivning på den ursprungliga platsen och nybyggnad på den nya
              platsen. Det kräver därför både rivningslov och bygglov.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Yttre eller inre ändringar av en byggnad
            </Heading>

            <Text>
              Om byggnaden ligger i ett område med detaljplan behöver du bygglov
              för att ändra utseende på byggnaden genom att
              <Box pl={6}>
                <ul>
                  <li>
                    byta kulör på byggnaden, till exempel från gul till röd färg
                  </li>
                  <li>byta fasadbeklädnad, till exempel från puts till trä</li>
                  <li>
                    byta material på taket, till exempel från tegel till plåt
                  </li>
                </ul>
              </Box>
              <br />
              Du behöver inte bygglov för att ändra rumsindelningen eller göra
              någon annan inre ändring, till exempel lägga nytt golv eller ta
              bort undertak. Du måste dock lämna in en anmälan till
              byggnadsnämnden om du vill göra stora förändringar av byggnadens
              planlösning. Detsamma gäller om den åtgärd du vill utföra innebär
              att byggnadens bärande delar berörs eller om du planerar att till
              exempel göra stora förändringar av vatten- och avloppsledningar.
              <br />
              <br />
              Alla ändringar ska göras varsamt, så att byggnaden behåller sin
              karaktär. Du ska också ta hänsyn till byggnadens tekniska,
              historiska, kulturhistoriska, miljömässiga och konstnärliga
              värden. Det gäller både inre och yttre ändringar, och det gäller
              oavsett om det krävs bygglov eller inte. Kravet på varsamhet är
              inte ett förbud mot att ändra, men alla ändringar du gör ska utgå
              från byggnadens förutsättningar. Som byggherre ansvarar du för
              detta. Byggnader som är särskilt värdefulla från historisk,
              kulturhistorisk, miljömässig eller konstnärlig synpunkt, eller som
              ingår i ett område som är särskilt värdefullt har ett utökat skydd
              och får inte förvanskas.
              <br />
              <br />
            </Text>

            <Heading textStyle="h8" color="logo_brown">
              Ny användning av en byggnad
            </Heading>

            <Text>
              Om du ska ändra en byggnad så att det blir en ytterligare bostad
              eller lokal för handel, hantverk eller industri behöver du
              bygglov. Du behöver också bygglov om du ska använda en byggnad för
              ett väsentligt annat ändamål än det som den senast har använts
              för. Det kan till exempel vara om du ändrar en bostad till ett
              kontor eller ett garage till en butik. Det gäller även om du inte
              kommer att ändra något i själva byggnaden, och även om du bara
              använder en del av byggnaden på ett nytt sätt.
              <br />
              <br />
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">
                  Åtgärder som inte kräver bygglov
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Det finns vissa undantag från kravet på bygglov om du har ett en-
              eller ett tvåbostadshus. Du kan få bygga uteplatser, skärmtak,
              friggebodar, tillbyggnader, uthus, murar och plank utan bygglov.
              Du kan även få göra fasadändringar och bygga två takkupor utan
              lov. Du behöver inte bygglov men måste göra en anmälan till
              byggnadsnämnden och invänta startbesked om du ska bygga ett så
              kallat attefallshus på högst 30 m2, en tillbyggnad på högst 15 m2
              eller inreda en ytterligare bostad i ett enbostadshus.
              <br />
              <br />
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Giltighetstid & Avgifter</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              När du har fått bygglov måste du börja bygga inom 2 år och ha
              byggt färdigt inom 5 år. Byggnadsnämnden får ta ut en avgift för
              bygglov och anmälan. Vänd dig till byggnadsnämnden i din kommun om
              du vill veta hur hög avgiften är.
              <br />
              <br />
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">
                  Exempel på handlingar som du oftast behöver skicka in för att
                  få bygglov för nybyggnation av ett enbostadshus
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Omfattning och krav kan variera mellan olika kommuner och
              omfattning på åtgärderna som bygglovsansökan avser. Så kontrollera
              vad som gäller i din kommun.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Ansökan
            </Heading>
            <Text>
              Antingen görs ansökan via kommunens e-tjänst eller via en vanlig
              ansökningsblankett. Skickar du att ansöka på vanlig
              ansökningsblankett behöver du oftast skickar en omgång av alla
              ritningar i A3-format till kommunen.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Nybyggnadskarta
            </Heading>
            <Text>
              Normal nybyggnadskarta ska visa ett kartutdrag över aktuellt tomt
              med huset inritat, i skala 1:400. Du ritar sedan själv in huset
              och måttsätter det. Rita även in minst tre vinkelräta mått från
              husets hörn till tomtgräns, samt ange husets höjd i plushöjd för
              den färdiga golvnivån. Eventuella murar och plank ska också ritas
              in.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Planritningar
            </Heading>
            <Text>
              En planritning är en horisontell genomskärning av huset.
              Ritningarna ska redovisa alla våningsplan i skala 1:100 och vilka
              funktioner de olika rummen har, till exempel kök, sovrum, badrum
              osv. Planritningen över entréplanet ska vara möblerad och
              tillgänglig för personer med nedsatt rörelseförmåga. Ritningen ska
              vara måttsatt så att byggnadens sammanlagda area går att räkna ut.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Fasadritningar
            </Heading>
            <Text>
              Fasadritningar i skala 1:100 ska visa husets fasader från alla
              väderstreck. Ritningarna ska innehålla nuvarande och nya
              marknivåer från tomtgräns till tomtgräns, takvinkel (i grader från
              horisontalplanet), materialval och kulörer (NCS-kod).
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Sektionsritningar
            </Heading>
            <Text>
              En sektionsritning i skala 1:100 ska redovisa en vertikal
              genomskärning av huset. Ritningen ska innehålla rumshöjd, färdiga
              golvnivåer, takvinkel och marknivåer vid entréer.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Kontrollansvarig
            </Heading>
            <Text>
              Den kontrollansvarige (KA) ska vara med och se till att
              lagstiftningen följs i ditt byggprojekt, genom att bland annat
              hjälpa dig att ta fram ett förslag till kontrollplan och se till
              att kontrollplanen följs. En KA ska vara certifierad och ha en
              självständig ställning, vilket innebär att denne inte får vara
              släkt med eller arbeta på samma företag som den som bygger. Du kan
              hitta certifierade KA hos certifieringsorganen, Rise och Kiwa.
              Tänk på att du måste kontakta KA före du anmäler denne i ditt
              ärende.
              <br />
              <br />
            </Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Tekniskt samråd & Startbesked</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Efter beviljat bygglov ska byggnadsverkets tekniska egenskaper
              redovisas innan bygget får påbörjas. Det sker genom att tekniska
              egenskaper för byggnadsverket redovisas och diskuteras vid ett
              tekniskt samråd. Det är också ett krav för att du ska erhålla
              startbesked och få påbörja det faktiska bygget. Nedan listas
              exempel på handlingar som ofta begärs in från kommunen. Men även
              här förekommer variationer, så kontrollera vad som står i ditt
              bygglovsbesked.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Förslag till kontrollplan
            </Heading>
            <Text>
              Kontrollplanen är ett dokument som beskriver vilka kontroller som
              ska göras inför och under byggnationen. I kontrollplanen ska det
              bland annat stå vad som ska kontrolleras, på vilket sätt
              kontrollerna görs och vad kontrollresultatet ska jämföras mot. Det
              ska också framgå vem som ska utföra kontrollerna. Den som själv
              bygger eller beställer byggnadsarbete (så kallad byggherre) ska
              med stöd av den kontrollansvarige, ta fram ett förslag till
              kontrollplan.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Konstruktionsdokumentation
            </Heading>
            <Text>
              Konstruktionsdokumentationen är ett dokument som beskriver husets
              bärande konstruktion. Den ska innehålla förutsättningarna för
              dimensioneringen och utförandet. Förutsättningarna för
              takstolarnas dimensionering och utförande varierar till exempel
              beroende på hur många våningar huset ska ha eller om huset ska
              byggas i ett snörikt område.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Konstruktionsritningar
            </Heading>
            <Text>
              Konstruktionsritningarna ska visa husets uppbyggnad. Ritningarna
              ska innehålla tekniska delar och viktiga byggnadsdetaljer.
              Fullständiga konstruktionsritningar omfattar grund-, bjälklags-
              och takplan, samt sektion genom grund, vägg, bjälklag och tak.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              VA-anslutning
            </Heading>
            <Text>
              Om din fastighet inte är ansluten till kommunens vatten- &
              avloppssytem, behöver du beställa VA-anslutning. Avser kommunalt
              dricksvatten, spillvatten och eventuellt dagvatten.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Redovisning av dagvattenhantering
            </Heading>
            <Text>
              Redovisning av dagvattenhantering är en beskrivning av hur du
              tänker ta hand om ditt dagvatten, det vill säga regn- och
              smältvatten som inte kan tränga ner i marken.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              VVS-ritningar
            </Heading>
            <Text>
              VVS-ritningar ska visa installationer för vatten, avlopp, värme
              och ventilation.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Energiberäkning{' '}
            </Heading>
            <Text>
              Energiberäkningen ska beskriva husets energianvändning, exklusive
              hushållsel. Med husets energianvändning menas byggnadens
              uppvärmning, tappvarmvatten och andra installationer såsom fläktar
              och motorer.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Färdigställandeskydd
            </Heading>
            <Text>
              Ett färdigställandeskydd kan vara en försäkring eller bankgaranti.
              Den ska skydda dig som beställer byggnadsarbete (byggherre) om
              byggentreprenören skulle gå i konkurs och inte kan slutföra
              bygget. Ett färdigställandeskydd behövs inte om du bygger åt dig
              själv, om du är en näringsidkare som bygger och säljer huset när
              det är klart eller om byggnadsnämnden bedömer att det inte finns
              behov av ett färdigställandeskydd. Oftast begär kommunen en kopia
              på bevis att färdigställandeskydd är tecknat.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Geotekniskt PM
            </Heading>
            <Text>
              Kortfattad beskrivning av de geotekniska förhållandena med ett
              grundläggningsförslag för aktuell byggnad. Geotekniskt PM tas fram
              av en sakkunnig.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Fuktsäkerhetsbeskrivning
            </Heading>
            <Text>
              I en fuktsäkerhetsbeskrivning redovisas vilka åtgärder som görs
              för att hantera fuktproblematiken från byggskedet fram till färdig
              byggnad.
              <br />
              <br />
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
