import { Divider, Flex, Text } from '@chakra-ui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  ContextArea,
  ConversationArea,
  Message,
  Message as MessageType,
} from '@contracts/support'
import { useTranslation } from 'react-i18next'
import { KeyedMutator } from 'swr'
import HtmlPane from '../richTextEditor/HtmlPane'
import AccountAvatar from '../accounts/AccountAvatar'
import {
  getFriendlyShortDateStrFromNumber,
  getSmartDateAndTimeString,
  getSmartDateAndTimeStringIfChanged,
  getTimeStrFromNumber,
  isCurrentYear,
  isToday,
} from '../../common/util'
import { Logo } from '../ui/Logo'
import { Icon } from '../ui/Icon'
import AddOrEditMessage from '../messages/AddOrEditMessage'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import { deleteMessage } from '../../apiClients/messagesApiClient'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'
import {
  connectEmbeddedImagesNotConnected,
  removeConnectedImagesNotEmbedded,
} from '../attachments/attachmentHelper'
import { deleteAttachmentsByArea } from '../../apiClients/attachmentsApiClient'

type Props = {
  area: ContextArea
  areaId?: string
  parentArea?: ConversationArea
  parentAreaId?: string
  grandParentArea?: ConversationArea
  grandParentAreaId?: string
  conversationArea?: ConversationArea
  areaFriendlyName: string
  areaFriendlyParentName: string
  messageGroup: MessageType[]
  idOfFirstUnreadMessage?: string
  w?: string | number
  mr?: string | number
  bg?: string
  mutateConversation?: KeyedMutator<Message[] | undefined>
  onMarkMessagesAsRead?: () => Promise<void>
  // onMarkMessagesAsRead?: () => void
}
const MessageGroup: React.FC<Props> = ({
  area,
  areaId,
  parentArea,
  parentAreaId,
  grandParentArea,
  grandParentAreaId,
  conversationArea,
  areaFriendlyName,
  areaFriendlyParentName,
  messageGroup,
  idOfFirstUnreadMessage,
  w,
  bg,
  mr,
  mutateConversation,
  onMarkMessagesAsRead,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const [activeMessage, setActiveMessage] = useState<string | undefined>(
    undefined,
  )
  const [isEditing, setIsEditing] = useState(false)
  const [editingMessageId, setEditingMessageId] = useState('')

  const message = messageGroup[0]

  const onDoneEditingHandler = () => {
    setIsEditing(false)
  }

  // eslint-disable-next-line no-console
  // console.log('idOfFirstUnreadMessage: ', idOfFirstUnreadMessage)

  useEffect(() => {
    if (messageGroup.length) {
      messageGroup.map(message => {
        // TODO - are these needed???

        // eslint-disable-next-line no-console
        // console.log('connectEmbeddedImagesNotConnect(message)')
        void connectEmbeddedImagesNotConnected(message.text, {
          area: 'Message',
          areaId: message.id,
          parentArea: area,
          parentAreaId: areaId,
          grandParentArea: parentArea,
          grandParentAreaId: parentAreaId,
        })
        // eslint-disable-next-line no-console
        // console.log('removeConnectedImagesNotEmbedded(message)')
        void removeConnectedImagesNotEmbedded(message.text, {
          area: 'Message',
          areaId: message.id,
          parentArea: area,
          parentAreaId: areaId,
          grandParentArea: parentArea,
          grandParentAreaId: parentAreaId,
        })
      })
    }
  }, [])

  if (!messageGroup.length) {
    return null
  }

  return (
    <Flex flexDir="row" w={w} mr={mr} pos="relative">
      {!message.isSystemMessage && (
        <AccountAvatar
          accountId={message.accountId}
          variant="avatar"
          mx={2}
          my={1}
        />
      )}
      {message.isSystemMessage && <Logo name="Berikar" h="46px" ml={0.5} />}
      <Flex flexDir="column" textStyle="textSmall" w="100%">
        <Flex justifyContent="space-between" alignItems="baseline">
          <Flex flexDir="row" textStyle="textSmall" alignItems="center">
            {!message.isSystemMessage && (
              <AccountAvatar
                accountId={message.accountId}
                variant="name"
                mx={2}
                my={1}
              />
            )}
            {message.isSystemMessage && (
              <Text ml={1}>{t('messages.systemMessage')}</Text>
            )}
            {!isToday(message.createdAt) && (
              <Text ml={2} color="gray.500" textStyle="textSmall">
                {getFriendlyShortDateStrFromNumber(message.createdAt, 'short')}
              </Text>
            )}

            <Text ml={2} color="gray.500" textStyle="textSmall">
              {isCurrentYear(message.createdAt) &&
                getTimeStrFromNumber(message.createdAt)}
            </Text>
          </Flex>
          {/* Has first message in group been changed */}
          <Text fontSize="2xs" color="gray.600" fontWeight="light">
            {getSmartDateAndTimeStringIfChanged(
              message.createdAt,
              message.updatedAt,
              t('utils.updated'),
            )}
          </Text>
        </Flex>
        <Flex flexDir="column" gap={4}>
          {messageGroup.map((message, i) => {
            let placeNewMessagesDivider = false
            if (
              idOfFirstUnreadMessage &&
              message.id === idOfFirstUnreadMessage
            ) {
              placeNewMessagesDivider = true
            }

            return (
              <Fragment key={message.id}>
                {placeNewMessagesDivider && (
                  // <Flex alignItems="flex-start" gap={2}>
                  //   <Divider borderColor="red" mt={2} />
                  //   <Text
                  //     color="red"
                  //     textStyle="textSmall"
                  //     fontWeight="semibold"
                  //   >
                  //     New
                  //   </Text>
                  // </Flex>
                  <Flex
                    flexDir="column"
                    textAlign="right"
                    textStyle="textSmall"
                  >
                    <Flex alignItems="flex-start" gap={2} color="logo_purple">
                      <Divider borderColor="logo_purple" mt={2} mb={0} />

                      <Text fontWeight="semibold">Nytt</Text>
                    </Flex>
                    <Flex
                      color="primary"
                      flexDir="column"
                      _hover={{
                        color: 'secondary',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        onMarkMessagesAsRead ? void onMarkMessagesAsRead() : ''
                      }}
                    >
                      <Text>Markera som läst</Text>
                    </Flex>
                  </Flex>
                )}
                <Flex
                  w="100%"
                  pos="relative"
                  onMouseOut={() => {
                    !message.isSystemMessage ? setActiveMessage(undefined) : ''
                  }}
                  onMouseOver={() => {
                    !message.isSystemMessage ? setActiveMessage(message.id) : ''
                  }}
                  _hover={message.isSystemMessage ? {} : { bg: 'gray.100' }}
                  justifyContent="space-between"
                  border={message.isSystemMessage ? '' : '1px solid'}
                  borderColor="gray.400"
                  borderRadius="lg"
                  bg={message.isSystemMessage ? 'transparent' : bg}
                >
                  {i > 0 && (
                    <Flex
                      pos="absolute"
                      right={1}
                      top={-4}
                      fontSize="2xs"
                      color="gray.600"
                      fontWeight="light"
                    >
                      {getSmartDateAndTimeString(
                        message.createdAt,
                        message.updatedAt,
                        t('utils.updated'),
                      )}
                    </Flex>
                  )}
                  {(!isEditing || editingMessageId !== message.id) && (
                    <Flex w="100%">
                      <HtmlPane
                        content={message.text}
                        key={message.id}
                        bg="transparent"
                        textStyle={
                          message.isSystemMessage
                            ? 'textMediumLight'
                            : 'textMedium'
                        }
                        py={2}
                        w="100%"
                      />
                    </Flex>
                  )}

                  {isEditing && editingMessageId === message.id && (
                    <AddOrEditMessage
                      message={message}
                      onDoneEditing={onDoneEditingHandler}
                      conversationArea={conversationArea}
                      areaFriendlyName={areaFriendlyName}
                      areaFriendlyParentName={areaFriendlyParentName}
                      area={area}
                      areaId={areaId}
                      parentArea={parentArea}
                      parentAreaId={parentAreaId}
                      grandParentArea={grandParentArea}
                      grandParentAreaId={grandParentAreaId}
                    />
                  )}
                  {activeMessage === message.id &&
                    currentUser?.id === message.accountId &&
                    !isEditing && (
                      // Render menu (edit or delete) if current user has created message
                      <Flex
                        flexDir="row"
                        pos="absolute"
                        top={-3}
                        right={2}
                        border="1px solid"
                        borderColor="gray.300"
                        borderRadius="lg"
                        px={3}
                        py={1}
                        bg="white"
                        alignItems="center"
                        gap={3}
                      >
                        <Icon
                          name="AiOutlineEdit"
                          color="secondary"
                          h={23}
                          _hover={{ color: 'gray' }}
                          onClick={() => {
                            setIsEditing(true)
                            setEditingMessageId(message.id)
                          }}
                        />
                        <AlertDialogAndButton
                          title={t('messages.deleteMessage')}
                          message={t('messages.deleteMessageQuestion')}
                          buttonTitle={t('ui.button.delete')}
                          buttonW="100%"
                          onDestructiveAction={() => {
                            deleteMessage(message.id)
                              .then(() => {
                                if (area && areaId) {
                                  // eslint-disable-next-line no-console
                                  console.log('Deleteing attachments by area')
                                  void deleteAttachmentsByArea(
                                    'Message',
                                    message.id,
                                  )
                                }
                                log.debug('Message successfully deleted')

                                mutateConversation
                                  ? void mutateConversation()
                                  : ''
                              })
                              .catch(e => {
                                log.error(
                                  { err: e },
                                  'Failed to delete message',
                                )
                              })
                          }}
                          variant="children"
                        >
                          <Icon
                            name="DeleteIcon"
                            color="secondary"
                            h={4}
                            _hover={{ color: 'gray' }}
                          />
                        </AlertDialogAndButton>
                      </Flex>
                    )}
                </Flex>
              </Fragment>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MessageGroup
