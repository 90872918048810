import { Box, Text } from '@chakra-ui/react'
import { t } from 'i18next'

const FileHeader: React.FC<{
  area: 'property' | 'project' | 'propertyNote' | 'projectNote'
}> = ({ area }) => {
  return (
    <Box
      px={2}
      py={0.5}
      my={1}
      borderRadius={4}
      overflow="clip"
      wordBreak="keep-all"
      flexWrap="nowrap"
      maxH={6}
      // pl={8}
    >
      <Text fontSize="md" color="primary_grey04">
        <>{t(`attachments.area.${area}Pluralis`)}</>
      </Text>
    </Box>
  )
}
export default FileHeader
