import { Flex, Image, Show, Text } from '@chakra-ui/react'
import { Organization } from '@contracts/misc'
import { useNavigate } from 'react-router-dom'
import {
  useOrganizationProfile,
  useServiceTypes,
} from '../../apiClients/organizationsApiClient'
import HtmlPane from '../richTextEditor/HtmlPane'
import Divider from '../ui/Divider'
import Skeleton from '../ui/Skeleton'
import ServiceTypeBadge from './ServiceTypeBadge'
import OrganizationAvailableWhereCard from './OrganizationAvailableWhereCard'
import OrganizationAccountsCard from './OrganizationAccountsCard'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ProviderProfilePublicCard: React.FC<{
  org: Organization
  mt?: number | string
  mr?: number | string
  mb?: number | string
  path?: string
  isPreview?: boolean
}> = ({ org, mr, mt, mb, path, isPreview = false }) => {
  const navigate = useNavigate()

  const { data: orgProfile } = useOrganizationProfile(org.id)

  const { data: allServiceTypes } = useServiceTypes()

  let logoImgUrl = ''
  if (orgProfile?.profileAttachmentId) {
    logoImgUrl = `${baseUrl}${orgProfile.profileAttachmentId}`
  }

  if (org) {
    return (
      <Flex
        flexDir={{ xxs: 'column', xl: 'row' }}
        mt={mt}
        mr={mr}
        bg="white"
        mb={mb}
        borderRadius="lg"
        boxShadow={isPreview ? '' : 'lg'}
        maxW="container.xl"
        onClick={() => {
          if (path) {
            navigate(`${path}/${org.id}/`)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        }}
        _hover={
          isPreview
            ? {}
            : {
                boxShadow: 'dark-lg',
                transform: 'translate(-1px,-1px)',
                cursor: 'pointer',
              }
        }
        border={isPreview ? '1px solid' : ''}
        borderColor={isPreview ? 'gray.300' : ''}
      >
        <Flex
          flexDir="row"
          alignItems="start"
          px={{ xxs: 2, tablet: 4 }}
          py={4}
          flexGrow={1}
          gap={1}
        >
          <Image
            src={logoImgUrl}
            boxSize={{ xxs: 20, tablet: 24, laptop: 32 }}
            borderRadius="lg"
            objectFit="cover"
            border="1px solid"
            borderColor="gray.200"
            px={{ xxs: 0, tablet: 1 }}
            py={1}
          />

          <Flex flexDir="column" ml={{ xxs: 1, tablet: 4 }} w="100%">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              gap={1}
            >
              <Text
                textStyle="h6"
                wordBreak="break-word"
                overflowWrap="break-word"
              >
                {org.name}
              </Text>
              <OrganizationAvailableWhereCard
                org={org}
                gap={{ xxs: 1, tablet: 2 }}
              />
            </Flex>

            <HtmlPane
              variant="PLAIN_TEXT_OUTPUT"
              fontSize={{ xxs: '10pt', tablet: '12pt', laptop: '14pt' }}
              fontWeight="light"
              fontStyle="italic"
              content={orgProfile?.profileText}
              noScroll
              maxH={{ xxs: '80px', tablet: '90px' }}
            />

            <Flex flexDir="row" wrap="wrap" gap={2} mt={2}>
              {allServiceTypes &&
                orgProfile?.serviceTypeIds?.map(serviceTypeId => {
                  return (
                    <ServiceTypeBadge
                      key={serviceTypeId}
                      serviceTypeId={serviceTypeId}
                      fontSize={{ xxs: 10, tablet: 11, laptop: 12 }}
                    />
                  )
                })}
            </Flex>
          </Flex>
        </Flex>
        <Show above="desktop">
          <Flex>
            <Divider orientation="vertical" />
          </Flex>
        </Show>

        <Flex flexDir="column" px={0} minW={72} w={{ xxs: '100%', xl: 72 }}>
          <Show above="xl">
            <OrganizationAccountsCard org={org} />
          </Show>
          <Show breakpoint="(max-width: 1279px)">
            <OrganizationAccountsCard org={org} variant="card" />
          </Show>
        </Flex>
      </Flex>
    )
  } else {
    return <Skeleton />
  }
}
export default ProviderProfilePublicCard
