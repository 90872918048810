import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'
import ListAttachments from '../../components/attachments/ListAttachments'

const ManageAttachments = () => {
  const { t } = useTranslation()

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('manageAttachments')}</title>
      </Helmet>
      {/* <Box pos="absolute" top={12} right={20} zIndex="hide">
        <AddAttachmentButton w={44}>
          {t('attachments.addAttachment')}
        </AddAttachmentButton>
      </Box> */}
      <ListAttachments mx={{ xxs: 0, tablet: '2%' }} />
    </Box>
  )
}
export default ManageAttachments
