import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Text,
  Flex,
} from '@chakra-ui/react'
import { ArticleInfo } from '@contracts/support'
import _ from 'lodash'
import { Helmet } from 'react-helmet-async'
import TagBadge from '../tags/TagBadge'
import SortableHeading from '../ui/SortableHeading'
import { Icon } from '../ui/Icon'
import { useArticlesInfoAllStates } from '../../apiClients/articlesApiClient'
import { Heading } from '../../ui'
import { calculateSortedItems } from '../articles/articleListUtils'

import { ArticleSortingOption } from '../../common/enums'
import { getDateStrFromDate } from './../../common/util'
import ArticleCard from './ArticleCard'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ListArticles: React.FC = () => {
  const { data: allArticles } = useArticlesInfoAllStates()
  const { t } = useTranslation()
  const [sortType, setSortType] = useState(
    ArticleSortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()
  const [accordionsToShow, setAccordionsToShow] = useState<number[]>([])

  const toggleAccordion = (id: number) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const getStatusStr = (article: ArticleInfo) => {
    if (article.status === 'Done') {
      if (article.publishedAt) {
        return `${t('articles.statusValues.DonePublished')} (${
          getDateStrFromDate(new Date(article.publishedAt)) ?? ''
        })`
      }
      return t('articles.statusValues.DoneNotPublished')
    }
    return t(`articles.statusValues.${article.status}`)
  }
  const sortedArticles = calculateSortedItems(sortType, allArticles, searchStr)

  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('articles.manageArticles')}</title>
      </Helmet>
      <Heading textStyle="h3">{t('articles.manageArticles')}</Heading>

      <Flex flexDir="row" alignItems="center" flexWrap="wrap" mt={6} mb={6}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<FaSearch color="gray.200" />}
          />
          <Input
            type="text"
            placeholder={t('articles.searchArticles')}
            width="30%"
            borderColor="gray.400"
            height={10}
            onChange={e => setSearchStr(e.target.value)}
          />
          <Text ml={4} textStyle="textMedium">
            {t('articles.searchInfo', {
              total: allArticles?.length ?? 0,
              filtered: sortedArticles.length ?? 0,
            })}
          </Text>
        </InputGroup>
      </Flex>

      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <TableCaption>{t('articles.listingBerikarArticles')}</TableCaption>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(ArticleSortingOption.ID_ASC)}
                onDescSort={() => setSortType(ArticleSortingOption.ID_DESC)}
              >
                {t('articles.id')}
              </SortableHeading>
            </Th>
            <Th width={40}>
              <SortableHeading
                onAscSort={() => setSortType(ArticleSortingOption.HEADING_ASC)}
                onDescSort={() =>
                  setSortType(ArticleSortingOption.HEADING_DESC)
                }
              >
                {t('articles.heading')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(ArticleSortingOption.HEADING_ASC)}
                onDescSort={() =>
                  setSortType(ArticleSortingOption.HEADING_DESC)
                }
              >
                {t('articles.status')}
              </SortableHeading>
            </Th>

            <Th>
              <SortableHeading>{t('articles.tags')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading>{t('articles.image')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(ArticleSortingOption.CREATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(ArticleSortingOption.CREATED_DATE_DESC)
                }
              >
                {t('articles.created')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(ArticleSortingOption.UPDATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(ArticleSortingOption.UPDATED_DATE_DESC)
                }
              >
                {t('articles.updated')}
              </SortableHeading>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedArticles && sortedArticles.length > 0 ? (
            sortedArticles.map((articleInfo, i) => {
              return (
                <Fragment key={articleInfo.id}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                    onClick={() => {
                      toggleAccordion(articleInfo.id)
                    }}
                  >
                    <Td
                      color="primary_orange02"
                      _hover={{ color: 'primary_orange01' }}
                    >
                      {!accordionsToShow.includes(articleInfo.id) && (
                        <Icon
                          name={'AiOutlinePlusCircle'}
                          h={24}
                          onClick={() => {
                            toggleAccordion(articleInfo.id)
                          }}
                        />
                      )}
                      {accordionsToShow.includes(articleInfo.id) && (
                        <Icon
                          name={'AiOutlineMinusCircle'}
                          h={24}
                          onClick={() => {
                            toggleAccordion(articleInfo.id)
                          }}
                        />
                      )}
                    </Td>
                    <Td>{articleInfo.id}</Td>
                    <Td>{articleInfo.heading}</Td>
                    <Td>{getStatusStr(articleInfo)}</Td>
                    <Td>
                      {articleInfo.tags &&
                        articleInfo.tags.map(tag => {
                          return <TagBadge key={tag.id} tag={tag} />
                        })}
                    </Td>
                    <Td>
                      {articleInfo.attachmentIdSmall && (
                        <Image
                          src={`${baseUrl}${articleInfo.attachmentIdSmall}`}
                          h={16}
                          alignSelf="center"
                        />
                      )}
                    </Td>
                    <Td>
                      {new Date(articleInfo.createdAt).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      {new Date(articleInfo.updatedAt).toLocaleString('sv-SE')}
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(articleInfo.id) && (
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={8}>
                        <ArticleCard articleId={articleInfo.id.toString()} />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={8}>{t('articles.noArticlesFound')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListArticles
