import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAttachments } from '../../apiClients/attachmentsApiClient'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ImagesCard: React.FC<{
  images: { id: string | undefined; name: string }[]
  label?: string
  w?: number
  ml?: number
}> = ({ images, label, w, ml }) => {
  const { t } = useTranslation()
  const { data: allArticleImgs } = useAttachments(['Article'])

  const getNameFromImgId = (imgId: string) => {
    const img = allArticleImgs?.find(img => {
      return img.id === imgId
    })
    return img?.originalFilename
  }

  return (
    <Flex flexDir="column" w={w} ml={ml}>
      <Text py={4}>{label}</Text>

      {images.map((img, index) => {
        return (
          <Flex
            key={img.id || index}
            flexDir="row"
            alignItems="center"
            mb={2}
            px={2}
          >
            {img.id && (
              <Image src={`${baseUrl}${img.id}`} h={16} alignSelf="center" />
            )}
            {!img.id && (
              <Box
                w={16}
                h={16}
                textAlign="center"
                alignItems="center"
                border="1px"
                borderColor="gray.400"
                color="gray.400"
              >
                {t('articles.noImg')}
              </Box>
            )}
            <Flex flexDir="column">
              <Text fontSize="small" ml={2}>
                {img.name}
              </Text>
              {allArticleImgs && (
                <Text fontSize="small" ml={2}>
                  {img.id && getNameFromImgId(img.id)}
                </Text>
              )}
              <Text fontSize="small" ml={2}>
                {img.id}
              </Text>
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
export default ImagesCard
