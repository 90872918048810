import { Box, Flex, Image, Text } from '@chakra-ui/react'
import climateImpactOverview from './../../../img/pages/compareClimateImpact/livscykel_s.png'

const ConstructionLifeCycleSection = () => {
  return (
    <Flex flexDir="row" w="100%" bg="white" justifyContent="left" wrap="wrap">
      <Flex flexDir="column" py={10} pl={[0, 2, 2, 10, 10, 10, 20, 20]}>
        <Text textStyle="h4" mb={2}>
          En byggnads livscykel
        </Text>
        <Box maxW="lg">
          <Text>
            Att designa och uppföra byggnader med låg klimatpåverkan är en
            viktig del för att bidra till att vi skapar ett mer hållbart
            samhälle. Klimatpåverkan från en byggnad under dess livscykel
            domineras vanligtvis av påverkan från byggskedet och energiåtgången
            under användningsskedet. Tack vare utvecklingen med allt fler
            energisnåla hus, samt att den energi som används består av en ökande
            andel förnybar energi, ser vi att påverkan från byggskedet bidrar
            till en allt större del av de totala utsläppen.
            <br />
            För att en byggnads totala klimatpåverkan ska kunna analyseras,
            används en metod som kallas för livscykelanalys (LCA). Då analyseras
            hela förloppet från att naturresurser utvinns för ingående produkter
            tills att byggnaden inte längre används och behöver tas hand om.
            <br />
            <br />
            En byggnads livscykel delas in i tre skeden
            <br />
            A - Byggskede <br />B - Användningsskede <br /> C - Slutskede
            {/* </ul> */}
            <br />
            <br />
            Dessa kan sedan delas in i informationsmoduler som beskriver
            processerna under respektive skede. I figuren till höger redovisas
            dessa moduler för byggskedet, där A1-A3 är produktskedet och A4-A5
            är byggproduktionsskedet.
          </Text>
        </Box>
      </Flex>
      <Box maxW="container.sm" ml={[0, 0, 1, 10, 20]} py={10}>
        <Image src={climateImpactOverview} alignSelf="center" />
      </Box>
    </Flex>
  )
}
export default ConstructionLifeCycleSection
