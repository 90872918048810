import {
  Box,
  Flex,
  Image,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

const PromoSmall: React.FC<{
  heading?: string
  text: string
  mb?: number
  mr?: number | string
  mt?: number
  variant?: 'Row' | 'Column'
  w?: number | string
  children: ReactNode
  image: string
  link?: string
  tooltip?: string
}> = ({
  heading,
  text,
  mb,
  mr = 10,
  mt,
  variant = 'Row',
  w = '2xs',
  image,
  link,
  tooltip,
}) => {
  const navigate = useNavigate()
  const [isLaptopOrLarger] = useMediaQuery('(min-width: 481px)')

  if (variant === 'Row' && isLaptopOrLarger) {
    return (
      <Tooltip label={tooltip} hasArrow={true} placement="top">
        <Flex
          flexDir="row"
          mb={mb}
          mt={mt}
          onClick={() => {
            if (link) {
              navigate(link)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          }}
          _hover={{ cursor: 'pointer' }}
          maxW="550px"
        >
          <Box mr={4}>
            <Image
              src={image}
              objectFit="cover"
              h={32}
              w={{ xxs: '100%', tablet: w }}
            />
          </Box>

          <Flex flexDir="column" justifyContent="center" maxW="280px" ml={6}>
            <Text fontWeight="light">{heading}</Text>
            <Text fontWeight="bold" fontSize="20px" wordBreak="break-word">
              {text}
            </Text>
          </Flex>
        </Flex>
      </Tooltip>
    )
  }
  return (
    // variant === 'Column'
    <Flex
      flexDir="column"
      mb={mb}
      mr={{ tablet: mr }}
      mt={mt}
      maxH={96}
      onClick={() => {
        if (link) {
          navigate(link)
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      }}
      _hover={{ cursor: 'pointer' }}
      w={{ mobile: '100%', tablet: w }}
    >
      <Box>
        <Image
          src={image}
          objectFit="cover"
          h={32}
          w={{ mobile: '100%', tablet: w }}
        />
      </Box>

      <Flex flexDir="column" justifyContent="center">
        <Box overflowWrap="anywhere">
          <Text fontWeight="light" w={w}>
            {heading}
          </Text>
          <Text textStyle="h5" w={{ mobile: '100%', tablet: w }}>
            {text}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
export default PromoSmall
