import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'

import {
  Collaborator,
  CreateCollaboratorRequest,
  UpdateCollaboratorRequest,
} from '@contracts/misc'
import { useContext } from 'react'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROJECTS_SERVICE_URL

export const useCollaborators = (
  projectId: string | undefined,
): SWRResponse<Collaborator[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<Collaborator[] | undefined>(
    isAdmin
      ? projectId
        ? `${baseUrl}/admin/projects/${projectId}/collaborators`
        : null
      : projectId
      ? `${baseUrl}/projects/${projectId}/collaborators`
      : null,
  )
}

export const useCollaboratorsByAccountId = (
  accountId: string,
): SWRResponse<Collaborator[] | undefined> => {
  return useSWR<Collaborator[] | undefined>(
    `${baseUrl}/projects/collaborators?id=${accountId}`,
  )
}

export const addCollaborator = async (
  projectId: string,
  newCollaborator: CreateCollaboratorRequest,
) => {
  return axios.post(
    `${baseUrl}/projects/${projectId}/collaborators`,
    newCollaborator,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateCollaborator = async (
  projectId: string,
  request: UpdateCollaboratorRequest,
) => {
  return axios.patch(
    `${baseUrl}/projects/${projectId}/collaborators`,
    request,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const deleteCollaborator = async ({
  entityId,
  accountId,
}: Collaborator) => {
  return axios.delete(
    `${baseUrl}/projects/${entityId}/collaborators?accountId=${accountId}`,
    { headers: { 'Content-Type': 'application/json' } },
  )
}
