import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import { useCollaborators } from '../../apiClients/projectsApiClient'
import { useProjectPlace } from '../../apiClients/projectsApiClient/projectPlaces'
import AccountAvatar from '../accounts/AccountAvatar'
import Divider from '../ui/Divider'
import Heading from '../ui/Heading'

const ProjectPlaceCollaboratorsOverviewCard: React.FC<{
  projectPlaceId: string
  mr?: number
  mt?: number
  ml?: ResponsiveValue<number | string>
  headerBg?: string
}> = ({ projectPlaceId, mr, mt, ml }) => {
  const { data: projectPlace } = useProjectPlace(projectPlaceId)
  const { data: allCollabs } = useCollaborators(projectPlace?.projectId)
  const { data: allOrgAccounts } = useOrganizationAccounts(
    projectPlace?.organizationId,
  )

  // Find intesect bts allCollabs and allOrgAccounts
  const orgCollabs = allCollabs?.filter(collab => {
    return allOrgAccounts?.find(orgAccount => {
      return orgAccount.accountId === collab.accountId
    })
  })

  return (
    <Flex
      flexDir="column"
      mr={mr}
      mt={mt}
      pb={4}
      gap={4}
      ml={ml}
      maxW={{ xxs: '360px', desktop: '300px' }}
      px={{ xxs: 1, tablet: 4 }}
      py={4}
    >
      <Flex alignItems="left" flexDir="column">
        <Heading textStyle="h8" isBold>
          {`Involverade medarbetare (${orgCollabs?.length ?? 0})`}
        </Heading>
        <Divider mt={2} />
      </Flex>

      <Flex
        flexDir="row"
        gap={4}
        maxW="lg"
        wrap="wrap"
        pl={{ xxs: 2, tablet: 0 }}
      >
        {orgCollabs &&
          orgCollabs.length > 0 &&
          orgCollabs.map(collaborator => {
            return (
              <AccountAvatar
                key={collaborator.accountId}
                w={60}
                accountId={collaborator.accountId}
                variant="avatarAndName"
              />
            )
          })}
      </Flex>
    </Flex>
  )
}
export default ProjectPlaceCollaboratorsOverviewCard
