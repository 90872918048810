import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { CreateHelpRequest, HelpRequestTemplateResponse } from '@contracts/misc'

import { CloseIcon } from '@chakra-ui/icons'
import { OrganizationCollaborator } from '@contracts/accounts'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import { validateEmail } from '../../common/auth-utils'
import FeedbackPopover from '../feedback/FeedbackPopover'
import Tiptap from '../richTextEditor/Tiptap'

import {
  addHelpRequest,
  useHelpRequests,
  useHelpRequestsTemplates,
} from '../../apiClients/projectsApiClient/helpRequests'
import log from '../../common/log'
import ContactsCard from '../contacts/ContactsCard'
import OrganizationAvatar from '../accounts/OrganizationAvatar'
import { updateContextAreaId } from '../../apiClients/attachmentsApiClient'
import { useAccountInvites } from '../../apiClients/accountsApiClient'

export const MAX_LENGTH_NOTE_TITLE = 255
interface AddHelpRequestValues {
  name: string
  description: string
  attachments: File[]
  helpTimestamp: string | number | null | undefined
  email: string
  emailList: string
}
interface Errors {
  name?: string
  description?: string
  helpTimestamp?: number
  email?: string
  emailList?: string
}

const AddHelpRequest: React.FC<{
  helpRequestTemplate: HelpRequestTemplateResponse
  onCloseModal: (avoidAlertDialog?: boolean) => void
  onParentHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  defaultOrganization?: OrganizationCollaborator
}> = ({
  helpRequestTemplate,
  onCloseModal,
  onParentHasUnsavedChanges,
  defaultOrganization,
}) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [emailList, setEmailList] = useState<
    { email: string; isOrgContact: boolean }[]
  >([])
  const [message, setMessage] = useState('<p></p>')
  const [globalError, setGlobalError] = useState(true)
  const [checkedContactsList, setCheckedContactsList] = useState<
    OrganizationCollaborator[]
  >([])
  const [includeDefaultOrg, setIncludeDefaultOrg] = useState(true)

  const { mutate: mutateHelpRequestTemplates } = useHelpRequestsTemplates(
    helpRequestTemplate.projectId,
  )
  const { mutate: mutateHelpRequestsForCurrentTemplate } = useHelpRequests([
    helpRequestTemplate.id,
  ])

  const { mutate: mutateInvites } = useAccountInvites(
    'HelpRequestTemplate',
    helpRequestTemplate.id,
  )

  const [addedImgIds, setAddedImgIds] = useState<string[] | undefined>()
  const imgIds: string[] = []

  if (!helpRequestTemplate) {
    return null
  }

  const initialValues: AddHelpRequestValues = {
    name: helpRequestTemplate?.name ?? '',
    description: helpRequestTemplate?.description ?? '',
    helpTimestamp: helpRequestTemplate?.helpTimestamp,
    attachments: [],
    email,
    emailList: '',
  }

  const validate = () => {
    const errors: Errors = {}

    const tmpRes = validateEmail(email)

    if (!tmpRes.isValid && email.length) {
      errors.email = tmpRes.errorMsg
    }

    if (
      !emailList.length &&
      checkedContactsList.length === 0 &&
      !includeDefaultOrg
    ) {
      errors.emailList = 'Lägg till minst en e-postadress'
      setGlobalError(true)
    }

    return errors
  }

  const imgAddedHandler = (imgId: string) => {
    imgIds.push(imgId)
    setAddedImgIds(imgIds)
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values: formikValues,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const tmpOrgIdList = [
        ...checkedContactsList.map(contact => {
          return contact.organizationId ?? ''
        }),
      ]
      if (defaultOrganization && includeDefaultOrg) {
        tmpOrgIdList.push(defaultOrganization.id)
      }

      const requestData: CreateHelpRequest = {
        name: helpRequestTemplate.name,
        helpRequestTemplateId: helpRequestTemplate.id,
        emailList: emailList.map(e => {
          return e.email
        }),
        organizationIdList: tmpOrgIdList,
        inviteMessage: message,
      }

      addHelpRequest(requestData)
        .then(({ data: resHelpRequests }) => {
          log.info('Successfully created new help-request')
          applicationInsights.trackEvent({
            name: 'Help-request added',
          })
          if (
            resHelpRequests &&
            resHelpRequests.length &&
            addedImgIds?.length
          ) {
            addedImgIds.map(async imgId => {
              await updateContextAreaId(imgId, resHelpRequests[0].id)
            })
          }

          toast({
            title: t('helpRequests.createSuccessMessage', {
              name: helpRequestTemplate.name,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
          void mutateHelpRequestsForCurrentTemplate()
          void mutateHelpRequestTemplates()
          void mutateInvites()
        })
        .catch(err => {
          log.error('Failed to create new help request', err)
          toast({
            title: t('helpRequests.createErrorMessage', {
              name: helpRequestTemplate.name,
            }),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          onCloseModal(true)
          setSubmitting(false)
        })
    },
  })

  const onChangeDescriptionHandler = (text: string) => {
    setMessage(text)
    onParentHasUnsavedChanges(true)
  }

  const onAddEmailHandler = (email: string, isOrgContact: boolean) => {
    setEmailList([...emailList, { email, isOrgContact }])
  }
  const onRemoveEmailHandler = (email: string) => {
    setEmailList(
      emailList.filter(listEmail => {
        return listEmail.email !== email
      }),
    )
  }

  const checkedContactHandler = (orgCollabToAdd: OrganizationCollaborator) => {
    if (orgCollabToAdd.organizationId) {
      // Connected organization so add to/remove from org id list
      if (checkedContactsList.includes(orgCollabToAdd)) {
        setCheckedContactsList(
          checkedContactsList.filter(collab => {
            return collab.id !== orgCollabToAdd.id
          }),
        )
      } else {
        // If list doesn't include attachmentID, add it
        setCheckedContactsList([...checkedContactsList, orgCollabToAdd])
        setGlobalError(false)
      }
    } else {
      // No connected organization (only email) so add to/remove from email list
      if (
        emailList.find(c => {
          return c.email === orgCollabToAdd.email
        })
      ) {
        onRemoveEmailHandler(orgCollabToAdd.email ?? '')
      } else {
        onAddEmailHandler(orgCollabToAdd.email ?? '', true)
        setGlobalError(false)
      }
    }
  }

  return (
    <>
      <Box
        h={16}
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5" mr={10}>
          {t('helpRequests.sendHelpRequest')}
        </Box>

        <FeedbackPopover context="helpRequestTemplate" />
      </Box>

      <form onSubmit={handleSubmit} id="add-or-edit-note-form">
        <Flex flexDir="column" my={4} px={{ xxs: 2, tablet: 2 }} gap={6}>
          <Flex flexDir="column">
            {/* Mottagare */}
            <Text flexWrap="wrap" mb={1}>
              Mottagare
            </Text>
            {defaultOrganization && (
              <Flex
                flexDir="row"
                alignItems="center"
                key={defaultOrganization.id}
                my={3}
              >
                <Checkbox
                  mr={3}
                  defaultChecked={true}
                  onChange={() => {
                    setIncludeDefaultOrg(!includeDefaultOrg)
                  }}
                  color="gray.300"
                  border="2px solid"
                  borderRadius="sm"
                />
                <OrganizationAvatar
                  organizationId={defaultOrganization.id}
                  variant="avatarAndNameOneRow"
                />
              </Flex>
            )}
            <Flex
              flexDir="column"
              bg="lightBackground"
              p={2}
              borderRadius="lg"
              textStyle="infoTextLight"
              gap={1}
              px={{ xxs: 2, tablet: 4 }}
            >
              <ContactsCard
                isCheckable
                checkedContactsList={checkedContactsList}
                onCheckedContact={checkedContactHandler}
              />
              <Text flexWrap="wrap" mt={3}>
                {t('helpRequests.helpRequestForm.addRecipient')}
              </Text>

              <Flex
                alignItems={{ tablet: 'end' }}
                gap={2}
                mb={2}
                flexDir={{ xxs: 'column', tablet: 'row' }}
              >
                <Input
                  title={t('accounts.accountForm.email')}
                  name="email"
                  type="email"
                  value={formikValues.email}
                  isMandatory={true}
                  isError={!!errors.email}
                  errorText={touched.email && errors.email ? errors.email : ''}
                  onBlur={handleBlur}
                  position="single"
                  bg="white"
                  w={{ xxs: '100%', tablet: '350px' }}
                  onChange={e => {
                    setEmail(e.target.value)
                    handleChange(e)
                  }}
                  titleMinW={14}
                />

                <Button
                  variant="secondary"
                  size="sm"
                  mb={1}
                  maxW="100%"
                  onClick={() => {
                    if (!errors.email && email.length) {
                      onAddEmailHandler(email, false)
                      setEmail('')
                      formikValues.email = ''
                      touched.email = false

                      setGlobalError(false)
                    }
                  }}
                >
                  Lägg till
                </Button>
              </Flex>
              {/* Recipient list */}
              <Flex flexDir="column" mb={4} gap={1}>
                <Flex gap={3} alignItems="center">
                  {globalError && (
                    <Text color="red" textStyle="infoText">
                      {errors.emailList}
                    </Text>
                  )}
                </Flex>
                {emailList.map(c => {
                  if (!c.isOrgContact) {
                    return (
                      <Flex gap={3} alignItems="center" key={c.email}>
                        <Text>{c.email}</Text>
                        <CloseIcon
                          _hover={{ color: 'gray.400', cursor: 'pointer' }}
                          boxSize={2.5}
                          onClick={() => {
                            onRemoveEmailHandler(c.email)
                          }}
                        />
                      </Flex>
                    )
                  }
                })}
              </Flex>
            </Flex>
          </Flex>

          <Box w="100%" maxW="container.lg">
            <Text my={1}>
              {t('helpRequests.helpRequestForm.personalMessage')}
            </Text>
            <Tiptap
              border="1px solid"
              borderColor="gray.400"
              content={message}
              w="100%"
              maxH="sm"
              h={44}
              toolbarVariant="none"
              withTypographyExtension={true}
              withLinkExtension={true}
              onChange={onChangeDescriptionHandler}
              editorLabel={t('notes.addOrUpdateForm.description')}
              area="HelpRequestTemplate.description"
              parentArea="HelpRequestTemplate"
              parentAreaId={helpRequestTemplate.id}
              grandParentArea="Project"
              grandParentAreaId={helpRequestTemplate.projectId}
              container={'private'}
              onImgAdded={imgAddedHandler}
            />
          </Box>

          <HStack
            spacing={2}
            mb={{ xxs: 32, laptop: 0 }}
            justifyContent="right"
          >
            <Button
              my={2}
              w={{ xxs: '50%', tablet: 32 }}
              onClick={() => {
                onCloseModal()
              }}
            >
              {t('general.cancel')}
            </Button>

            <Button
              type="submit"
              my={2}
              w={{ xxs: '50%', tablet: 32 }}
              variant={
                includeDefaultOrg || !globalError ? 'primary' : 'disabled'
              }
              disabled={isSubmitting}
              id="save-helpRequestTemplate"
            >
              {t('ui.button.send')}
            </Button>
          </HStack>
        </Flex>

        {isSubmitting && <Spinner />}
      </form>
    </>
  )
}
export default AddHelpRequest
