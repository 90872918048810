import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react'
import Heading from '../ui/Heading'

export const PhaseCardIdeaAndPlanning = () => {
  return (
    <Box textStyle="infoTextLight">
      <Heading textStyle="h3Small" color="logo_purple" mb={2}>
        Idè & Planering
      </Heading>
      <Text>
        Det är kanske inte alltid helt uppenbart, men att idéer eller behov
        uppstår beror ofta på att det sker en förändring av något slag. För att
        den framtida lösningen ska bli så bra som möjligt är det viktigt att
        dessa förändringar och önskemål identifieras och analyseras.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Målsättning med projektet
      </Heading>
      <Text>
        Denna fas startar med att formulera vissa generella målsättningar med
        projektet för att vara säker på att alla involverade ser samma behov och
        mål med vad som ska åstadkommas. Dessa generella målsättningar blir
        också avgörande för att de personer som ska genomföra projektet förstår
        varför projektet genomförs och vad som är viktigt.
        <br />
        <br />
        Målsättningen med att genomföra ett projekt kan skilja sig åt. I vissa
        fall, handlar det om att utöka byggnadsytan för att få plats med den
        växande familj. I andra fall kan det handla om estetiska och
        arkitektoniska målsättningar, såsom att byggnaden ska ha en viss stil
        eller passa in i en viss miljö. Målsättningarna är ofta unika, så att
        alla i projektet förstår och har en samsyn kring dessa är därför
        avgörande för hur framgångsrikt målet nås. Hoppa därför inte över detta
        viktiga steg.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Funktioner & Behov
      </Heading>
      <Text>
        När målsättningen med projektet är formulerad är det dags att gå vidare
        till de behov och funktioner som ni önskar uppnå. Det kommer sedan vara
        ett bra underlag för arkitekten, inredaren eller annan person ni ska
        anlita för att börja skissa på olika lösningar. Exempel på sådana behov
        och funktioner kan vara
      </Text>
      <br />
      <Box pl={6}>
        <ul>
          <li>
            Sammansättning av rum - Hur ser sammansättningen av rum ut, finns
            det behov av rum med specifik funktion, storlek på rummen osv
          </li>
          <li>
            Kvantitativ information kring behov - Hur många ska bo i huset, hur
            många enskilda sovrum ska det finnas, antal gemensamma utrymmen osv
          </li>
          <li>
            Funktionskrav - Hur ska olika rum vara placerade till varandra (en
            suite, intilliggande barnrum, inbyggt garage etc), vilka
            funktionskrav ställs på installationer, rörlighet i byggnaden osv
          </li>
          <li>
            Ekonomi - Finns det några ekonomiska ramar som behöver tas i
            beaktning när möjliga lösningar arbetas fram
          </li>
        </ul>
      </Box>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Skisser
      </Heading>
      <Text>
        Med detta på plats kan en arkitekt börja ta fram olika skissförslag för
        möjliga lösningar. En skiss är en förenkling av hur den slutgiltiga
        utformningen kommer vara, men fungerar bra för att få en uppfattning om
        vad som ska göras och hur saker kommer se ut. Genom skisser går det att
        utvärdera ett flertal olika alternativ utan att ta fram fullständiga
        ritningar. På det sättet sparas både värdefull tid och pengar.
        Skissarbetet är en iterativ process som pågår tills byggherren har valt
        ett alternativ att gå vidare med, som det sedan tas fram fullständiga
        handlingar för under projekteringsskedet.
      </Text>
    </Box>
  )
}

export const PhaseCardDesign = () => {
  return (
    <Box textStyle="infoTextLight">
      <Heading textStyle="h3Small" color="primary" mb={2}>
        Projektering
      </Heading>
      <Text>
        Med utgångspunkt i det arbete som gjorts under idé och planeringsfasen,
        är syftet med projekteringen om att ta fram detaljerade ritningar och
        beskrivningar för projektet. Underlaget ger byggherren och de som ska
        utföra arbetet en gemensam bild av slutresultatet. Handlingarna behövs
        också för att kunna ansöka om tillstånd och lov hos kommunen och ibland
        andra myndigheter.
        <br />
        <br />
        Under projekteringen specificeras detaljerna i projektet och beslut
        fattas, både gällande specifika moment, men även kring projektet i
        helhet. Initialt i projekteringen handlar det exempelvis om byggnadens
        utformning, storlek och tekniska lösningar, för att i senare skeden
        handla allt mer om detaljer kring materialval, detaljlösningar,
        inredning och ytskikt.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Påverkansmöjlighet & Risk
      </Heading>
      <Text>
        Det är under projekteringen som du som byggherre fortfarande har
        möjlighet att påverka utformningen. Men ju längre projekteringen
        fortskrider, desto svårare och framförallt dyrare blir det att komma med
        stora förändringar. Det är också nu som eventuella risker i projektet
        ska identifieras och analysera. På så sätt kan dessa risker elimineras,
        eller om så inte är möjligt, lämpliga kontroller införas under
        byggtiden. Det omfattar miljörisker, kvalitetsrisker, riskkonstruktioner
        (läckage, mikrobiologisk tillväxt etc) och händelser som kan påverka
        kostnad eller tidsåtgång. Under projekteringen beaktas även risker kring
        säkerhet och hälsa för de som uppför byggnaden samt de som ska använda
        byggnaden när den är klar.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Bygglov
      </Heading>
      <Text>
        När utformningen av byggnaden är klar och byggherren är nöjd med
        lösningen, tas bygglovshandlingar fram. Bygglovshandlingarna visar var
        byggnaden är placerad, utformning av samtliga våningsplan, fasader och
        sektioner i huset. Dessa utgör underlag för bygglovsansökan som skickas
        in till kommunens byggnadsnämnd. Läs mer vad som gäller under Bygglov.
        <br />
        <br />I bygglovet prövas byggnadens lämplighet och tillgänglighet.
        Prövningen är dock inte heltäckande då vissa detaljer inte framgår av
        bygglovshandlingarna (tröskelhöjder mm). Dessa detaljer kommer då att
        bedömas tillsammans med byggnadens tekniska egenskaper i det tekniska
        samråd som krävs för att erhålla startbesked.
      </Text>
      <br />

      <br />
      <Heading textStyle="h8" color="logo_brown">
        Tekniskt samråd & Startbesked
      </Heading>
      <Text>
        Efter beviljat bygglov ska byggnadsverkets tekniska egenskaper redovisas
        innan bygget får påbörjas. Det innebär att projektering för dessa delar
        behöver göras. Fokus för projektörer (arkitekt, konstruktör, vvs,
        energi, geoteknik osv) är att studera alla detaljer, koordinera tekniska
        lösningar samt göra slutliga ritningar och beskrivningar av det arbete
        som ska utföras. Handlingarna som tas fram i detta skede är det som
        sedan kommer utgöra bygghandlingarna för projektet.
        <br />
        <br />
        Vilka handlingar och beskrivningar som begärs in av kommunen för att få
        startbesked varierar mellan kommuner och beroende på omfattningen av
        åtgärden, så kontrollera vad som gäller i ditt fall. Läs mer under
        Bygglov
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Viktigt att tänka på
      </Heading>
      <Text>
        Projekteringsprocessen är en viktig del för att säkerställa att du når
        de målsättningar du har med projektet och att slutreslutatet och
        kvalitén blir som du tänkt och betalt för. Tyvärr är det ganska vanligt
        att ritningar och bygghandlingar är ofullständiga, vilket leder till att
        det blir problem på arbetsplatsen. Ibland beror detta på att besparingar
        under projekteringen och att de som har tagit fram handlingar inte fått
        möjligheten att utreda alla förutsättningar och därför överlåter
        detaljerna kring utförandet till byggarbetsplatsen. Detta fenomen, som
        brukar kallas för LPP (Löses På Plats), medför ofta en högre
        totalkostnad då det innebär störningar på byggarbetsplatsen som tar tid
        i anspråk. Det medför dessutom att ett juridiskt ansvar för slutkvalitén
        hamnar på de som utför arbetet. Därför bör projekteringen alltid vara
        väl genomarbetad.
      </Text>
    </Box>
  )
}
export const PhaseCardProduction = () => {
  return (
    <Box textStyle="infoTextLight">
      <Heading textStyle="h3Small" color="logo_orange" mb={2}>
        Genomförande
      </Heading>
      <Text>
        <br />
        Nu är det dags att ta nästa stora kliv mot din husdröm och börja se det
        ta fysisk form. Här finns det lite olika vägar att gå. Antingen vill du
        göra så mycket som möjligt själv eller så vill du handla upp tjänster
        som hjälper dig med vissa delar eller alltihop.
      </Text>

      <Accordion maxW="container.xl" mt={6} defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Upphandling av entreprenör</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Ofta underskattas tyvärr behovet av att skriva tydliga avtal. Att
              muntliga överenskommelser, tillsammans med offerter och
              lagstiftning utgör överenskommelsen är inte ovanligt. Men tyvärr
              visar det sig ofta att konsumenten och entreprenören har olika
              uppfattning om vad som sagts och bestämts. Därför är det viktigt
              att säkerställa att upphandlingar och ingångna avtal görs på rätt
              sätt för att skydda alla parter från framtida tvist. Därigenom
              ökar även sannolikheten för att du som kund blir nöjd med
              resultatet och att det slutförs i tid.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              ABS 18
            </Heading>
            <Text>
              För dig som är privatperson och beställer en småhusentreprenad,
              alltså en nybyggnation av en- eller tvåfamiljshus fritidshus,
              finns det särskilda bestämmelser i konsumenttjänstlagen (KtjL).
              Men för att ha en tydlig överenskommelse krävs det också ett
              entreprenadkontrakt. Som grund till entreprenadkontrakt finns det
              ett standardavtal, ABS18 (Allmänna bestämmelser för
              småhusentreprenad), som Konsumentverket, Villaägarnas riksförbund
              och Byggföretagen gemensamt har tagit fram.
              <br />
              <br />
              ABS18 gäller tillsammans med KtjL. Avtalet reglerar vissa delar
              mer specifikt och förtydligar vad som gäller för en
              småhusentreprenad. Exempel på det är; entreprenörens
              undersökningsplikt, avhjälpande av entreprenören, entreprenörens
              möjlighet att erbjuda prisavdrag, tidsförlängning, besiktning,
              skyldighet för parterna att hålla varandra underrättade under
              entreprenadens gång, tilläggsarbeten och försäkringsskydd. Avtalen
              måste så klart projektanpassas för att det ska bli ett tydligt
              avtal för alla parter.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Hantverksformuläret
            </Heading>
            <Text>
              För mindre entreprenader som reparations-, renoverings- eller
              ombyggnadsarbeten kan istället hantverksformuläret användas.
              Hantverksformuläret är framtaget av samma parter som tagit fram
              ABS18 och är är en avtalsmall med tillhörande allmänna
              bestämmelser. Det gäller som ett komplement till KtjL och är en
              bra utgångspunkt i avtal för mindre entreprenader.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Att tänka på för att undvika otydligheter i upphandlingen
            </Heading>
            <Box pl={6}>
              <ul>
                <li>
                  Är det lämpligt att använda Allmänna bestämmelser för
                  småhusentreprenad (ABS) eller Hantverksformuläret i ditt
                  projekt? Se användningsområden ovan.
                </li>
                <li>
                  Komplettera avtalet med en tydlig beskrivning av projektet.
                  Vad ska byggas, vad ingår, några särskilda material som ska
                  användas osv.
                </li>
                <li>
                  Det sker nästa alltid förändringar i projekt, så kallade
                  ÄTA-arbeten (ändrings-, tilläggs- och avgående arbeten). Då är
                  det viktigt att det är tydligt bestämt hur rutinen ser ut. Hur
                  ska arbetet beställas och hur ska eventuella förändringar av
                  kostnader och tidsåtgång godkännas?
                </li>
                <li>
                  Vilka besiktningar ska göras under byggtiden? En del
                  besiktningar behöver göras fortlöpande innan olika material
                  eller konstruktionsdelar byggs in och blir dolda. Anlita en
                  expert om du känner dig osäker.
                </li>
                <li>
                  Vad händer vid en försening? Nivån på eventuellt vite är inte
                  reglerat i ABS, men något du som konsument bör göra ett
                  tillägg kring. Det är även viktigt att avtala start- och
                  sluttid så att det går att planera för exempelvis en
                  inflyttning, men även för att veta när eventuellt vite ska
                  utgå.
                </li>
                <li>
                  Kontrollera så att entreprenören du avser att anlita har rätt
                  behörigheter och eventuella certifieringar innan ni skriver
                  avtal.
                </li>
                <li>
                  Var tydliga med betalningsvillkoren. Har projektet ett fast
                  pris eller är det löpande räkning? En förutbestämd
                  betalningsplan är att föredra. Dock ska den vara
                  prestationsbunden, där fakturering inte får ske innan ett
                  visst moment är utfört. Tänk även på att du som konsument har
                  rätt att hålla inne 10% av det avtalade priset till dessa att
                  entreprenaden har godkänts vid slutbesiktning.
                </li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Gör det själv</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Om du vill utföra vissa arbeten själv är det viktigt att vara
              medveten om att vissa arbeten kräver formell behörighet för att få
              utföra. Här följer exempel på några
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              El
            </Heading>
            <Text>
              Arbete med elinstallation är reglerat genom lagstiftning
              (Elsäkerhetslagen) och de flesta elinstallationsarbeten ska
              utföras av ett registrerat elinstallationsföretag. En del enklare
              arbeten kan du få utföra själv, men bara om du vet hur du ska
              göra. Kom ihåg att olagligt elinstallationsarbete är straffbart.
              Ett misstag kan få ödesdigra konsekvenser.
              <br />
              <br />
              Med elinstallationsarbete menas, enligt elsäkerhetslagen, arbete
              som omfattar:
            </Text>
            <Box pl={6}>
              <ul>
                <li>att utföra, ändra eller reparera en elanläggning.</li>
                <li>
                  att fast ansluta en elektrisk produkt till en elanläggning.
                </li>
                <li>
                  att koppla loss en elektrisk produkt från en elanläggning som
                  produkten är fast ansluten till.
                </li>
              </ul>
            </Box>
            <Text>
              <br />
              Detta får du göra själv
              <br />
              <Box pl={6}>
                <ul>
                  <li>
                    Byta trasig proppsäkring och återställa utlöst
                    automatsäkring
                  </li>
                  <li>Byta glödlampor</li>
                  <li>Byta lamphållare</li>
                  <li>Montera skarvsladdar</li>
                  <li>Montera sladdströmbrytare och stickproppar</li>
                  <li>
                    Reparera och byta ut delar i trasiga skarvsladdar och
                    apparatsladdar
                  </li>

                  <li>
                    Byta befintlig strömbrytare för högst 16 A som är placerad i
                    en egen kapsling eller dosa
                  </li>

                  <li>
                    Byta befintligt vägguttag för högst 16 A som är placerat i
                    en egen kapsling eller dosa.
                  </li>
                </ul>
              </Box>
              <br />
              För att få utföra dessa arbeten måste du ha kunskap om hur du gör.
              Arbetet ska även kunna kontrolleras på ett korrekt sätt. Är du
              minsta osäker, överlåt arbetet till ett elinstallationsföretag.
              <br />
              <br />
              Exempel på arbeten som endast får utföras av ett
              elinstallationsföretag eller en auktoriserad elinstallatör:
              <Box pl={6}>
                <ul>
                  <li>Förändringar i fasta installationer</li>
                  <li>Byte av ojordat vägguttag mot jordat</li>
                  <li>Installation av 230 volt golvvärme och värmekabel</li>
                  <li>Förläggning av kabel i mark.</li>
                </ul>
              </Box>
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              VVS
            </Heading>
            <Text>
              Det finns ingen lag som hindrar dig att utföra VVS-installationer
              på egen hand, du får till exempel byta wc-stol, handfat och dusch,
              men däremot får du inte ändra på system eller dra om avlopp och
              rör. Vill du exempelvis flytta på din golvbrunn eller toalettstol
              så måste du anlita en VVS-installatör.
              <br />
              <br />
              Även om det är många arbeten som du får göra själv, så kräver det
              branschkunskap för att kunna utföras på ett korrekt sätt. Har du
              som privatperson utfört en installation själv, exempelvis
              installerat en blandare i köket och det uppstår en vattenskada pga
              felaktig inkoppling kommer försäkringsbolaget sannolikt inte
              ersätta skadan. Därför är det alltid bäst att använda sig av en
              certifierad hantverkare om du är minsta osäker.
              <br />
              <br />
            </Text>
            <Heading textStyle="h8" color="logo_brown">
              Våtrum
            </Heading>
            <Text>
              För att bygga ett godkänt våtrum finns det en hel del att tänka
              på. GVKs branschregler Säkra Våtrum ger hantverkare och beställare
              praktiska instruktioner för hur våtrum ska utföras på rätt sätt.
              GVK har även stöd för att jämföra utfört arbete mot branschregler
              för att se om utfört arbete uppnår kraven.
              <br />
              <br />
              Aktuella regelverk och guider återfinns på hos Svensk
              Våtrumskontroll.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Slutbesiktning</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Det sista som som görs på projektet är en slutbesiktning. Då
              undersöker en certifierad och opartisk besiktningsman om arbetet
              har utförts på ett korrekt sätt och att du som konsument har fått
              det som står i kontraktet.
              <br />
              <br />
              På slutbesiktningen deltar du, din besiktningsman, den eller de
              som har byggt huset och din kontrollansvarig. Besiktningen sker i
              huset och börjar med att ni går igenom samtliga rum på alla
              våningsplan om det finns mer än ett. Även eventuell vind och
              inspektionsutrymmen undersöks. Därefter sker en utvändig
              besiktningar av samtliga ytor, fasader, yttertak, dörrar och
              fönster. När allting är kontrollerat görs det en genomgång av de
              fel som besiktningsmannen har hittat och om det saknas några intyg
              eller liknande. Slutligen gör besiktningsmannen en
              helhetsbedömning av byggnaden och om den är godkänd eller inte
              <br />
              <br />
              Besiktningsmannen skriver sedan ett besiktningsutlåtande och
              skickar det normalt inom ett par dagar till parterna. Här anges
              alla fel som noterats vid slutbesiktning och vilket belopp du som
              beställare har rätt att hålla inne med tills felen är avhjälpta.
              Detta belopp ska du betala först när din entreprenör har åtgärdat
              felen som antecknats i protokollet. De fel som upptäcks vid
              slutbesiktning ska åtgärdas inom två månader.
              <br />
              <br />
              När huset blir godkänt i slutbesiktningen lämnas ansvaret för
              huset över till dig som beställare och garantitiden börjar löpa.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading textStyle="h6">Slutsamråd</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Slutsamråd med kommunen måste genomföras för byggåtgärder som har
              omfattats av tekniskt samråd. Innan samrådet har genomförts och
              ett slutbesked är utfärdat får byggnaden inte tas i bruk.
              Slutsamrådet genomförs normalt på byggarbetsplatsen. Till
              slutsamrådet kallas byggherren, kontrollansvarig och övriga som
              byggnadsnämnden anser ska vara med.
              <br />
              <br />
              Slutsamrådet är en form av revision där följande behandlas
              <br />
              <Box pl={6}>
                <ul>
                  <li>
                    Hur kontrollplanen har uppfyllts, hur andra villkor och
                    kompletterande villkor i startbeskedet har följts
                  </li>
                  <li>
                    Om avvikelser har gjorts från de krav som gäller för
                    åtgärderna, till exempel bygglovet eller tekniska
                    föreskrifter och egenskapskrav
                  </li>
                  <li>
                    Den kontrollansvariges och byggnadsnämndens dokumentation
                    över besök på byggarbetsplatsen
                  </li>
                  <li>Den kontrollansvariges utlåtande</li>
                  <li>Annan dokumentation över arbetets utförande</li>
                  <li>Behov av andra åtgärder</li>
                  <li>Förutsättningarna för ett slutbesked</li>
                </ul>
              </Box>
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
export const PhaseCardGuarantee = () => {
  return (
    <Box textStyle="infoTextLight">
      <Heading textStyle="h3Small" color="logo_purple" mb={2}>
        Garanti
      </Heading>
      <Text>
        Enligt Allmänna bestämmelser småhusentreprenader har du som konsument
        två års garanti på det arbete som en entreprenör har utfört.
        Garantitiden börjar löpa vid godkänd slutbesiktning eller vid
        färdigställande (om slutbesiktning ej genomförs). Det innebär att du som
        konsument under denna tid har rätt att reklamera fel som inträffat efter
        att arbetet färdigställts. Efter att garantitiden löper ut inträder en
        ansvarstid som gäller 10 år efter godkänd slutbesiktning.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Garantitid
      </Heading>
      <Text>
        Innan din tvååriga garantitid löper ut bör du göra ytterligare en
        besiktning, tvåårsbesiktningen. Då är det fortfarande entreprenörens
        ansvar att rätta till eventuella fel som har uppstått under dessa två
        år. Därefter flyttas bevisbördan och du som konsument blir ansvarig för
        att bevisa att entreprenören kan hållas ansvarig för ett fel eller skada
        i huset. Vilket kan bli både kostsamt och energikrävande.
        <br />
        <br />
        Vid tvåårsbesiktningen kontrollerar besiktningsmannen vad som har
        inträffat sedan godkänd slutbesiktning och om det uppstått några nya
        fel. Det finns en antal normala fel som brukar uppstå under denna
        perioden. Exempel på sådana är; sprickor i väggar eller takvinklar, golv
        som har krympt, fönster/dörrar som blivit tröga att öppna, droppande
        kranar. Detta är fel som du som boende inte har orsakat. Onormalt
        slitage och fel som uppkommit på grund av vanskötsel omfattas däremot
        inte av reklamationsrätten och kommer inte heller att noteras i
        besiktningsprotokollet. Du som boende är skyldig att sköta och
        underhålla ditt hus enligt de skötselinstruktioner som finns.
        <br />
        <br />
        Efter avslutad tvåårsbesiktning skickar besiktningsmannen sitt utlåtande
        och det är då entreprenörens skyldighet att åtgärda noterade fel.
      </Text>
      <br />
      <Heading textStyle="h8" color="logo_brown">
        Ansvarstid
      </Heading>
      <Text>
        Efter garantitiden på två år har löpt ut, inträder istället en
        ansvarstid som gäller upp till 10 år efter godkänd slutbesiktning. Under
        denna period kan konsumenten ställa entreprenören till svars för
        upptäckta fel. Dock är det under denna period du som konsument som är
        ansvarig för att bevisa att entreprenören har gjort fel för att få genom
        dina krav. Dessutom måste felet bero på ansvarslöshet från
        entreprenörens sida.
        <br />
        <br />
        Efter 10 år är entreprenören helt friskriven från ansvar.
      </Text>
    </Box>
  )
}
