import { Checkbox, Circle, Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import { EventNotification } from '@contracts/support'

import { useNavigate } from 'react-router-dom'

import { CheckCircleIcon } from '@chakra-ui/icons'
import log from '../../common/log'
import {
  PATH_MY_PROJECTS,
  PATH_MY_PROPERTIES,
  PATH_PROJECT_NOTE,
  PATH_PROPERTY_NOTE,
} from '../../common/nav'
import { getDateStrFromNumber } from '../../common/util'
import {
  hasReadEventNotifications,
  useEventNotifications,
} from '../../apiClients/eventsApiClient'
import AccountAvatar from '../accounts/AccountAvatar'
import { Icon } from '../ui/Icon'

const EventNotificationCard: React.FC<{
  eventNotifications: EventNotification[]
  bg?: string
  px?: ResponsiveValue<number | string>
  showDate?: boolean
  onCloseMenu?: () => void
}> = ({ eventNotifications, bg, px, showDate = false, onCloseMenu }) => {
  const navigate = useNavigate()

  const { mutate } = useEventNotifications()

  const notificationArea =
    eventNotifications[0].event.area === 'Message'
      ? eventNotifications[0].event.parentArea
      : eventNotifications[0].event.area

  const getAreaPath = () => {
    let contextAreaId = ''
    if (
      eventNotifications[0].event.type !== 'Message' &&
      eventNotifications[0].event.type !== 'Mention'
    ) {
      if (!eventNotifications[0].event.areaId) {
        log.error('Event lacks area id, hence no link can be made')
        return ''
      }
      contextAreaId = eventNotifications[0].event.areaId
    } else {
      if (!eventNotifications[0].event.parentAreaId) {
        log.error('Event lacks area id, hence no link can be made')
        return ''
      }
      contextAreaId = eventNotifications[0].event.parentAreaId
    }

    switch (notificationArea) {
      case 'Property':
        // return `${PATH_MY_PROPERTIES}/${eventNotifications[0].event.parentAreaId}`
        return `${PATH_MY_PROPERTIES}/${contextAreaId}`

      case 'PropertyNote':
        return `${PATH_PROPERTY_NOTE}/${contextAreaId}`
      // return `${PATH_PROPERTY_NOTE}/${eventNotifications[0].event.parentAreaId}`

      case 'Project':
        // return `${PATH_MY_PROJECTS}/${eventNotifications[0].event.parentAreaId}`
        return `${PATH_MY_PROJECTS}/${contextAreaId}`

      case 'ProjectNote':
        return `${PATH_PROJECT_NOTE}/${contextAreaId}`
      // return `${PATH_PROJECT_NOTE}/${eventNotifications[0].event.parentAreaId}`

      // TODO: implement these
      // case 'HelpRequest':
      // case 'HelpRequestTemplate':
      // case 'ProjectPlace':
    }
    return ''
  }

  const setHasReadEventNotificationsHandler = async () => {
    const ids = eventNotifications.map(event => {
      return event.id
    })
    await hasReadEventNotifications(ids).then(() => {
      void mutate()
    })
  }

  const getMessage = () => {
    let propertyName: RegExpMatchArray | null
    let projectName: RegExpMatchArray | null
    let propertyNoteName: RegExpMatchArray | null
    let projectNoteName: RegExpMatchArray | null

    if (
      eventNotifications[0].event.type !== 'Message' &&
      eventNotifications[0].event.type !== 'Mention'
    ) {
      return eventNotifications[0].event.message
    }

    // One mention event
    if (
      eventNotifications[0].event.type === 'Mention' &&
      eventNotifications.length === 1
    ) {
      eventNotifications[0].event.message =
        eventNotifications[0].event.message.replace(
          'omnämnt',
          '<font color="#B86890"><b>omnämnt</b></font>',
        )
    }

    if (!eventNotifications[0].event.parentAreaId) {
      log.error('Event lacks area id, hence no message can be made')
      return ''
    }

    switch (notificationArea) {
      case 'Property':
        // eslint-disable-next-line no-console
        // console.log('event: ', eventNotifications)

        if (eventNotifications.length === 1) {
          return eventNotifications[0].event.message
        }
        propertyName = eventNotifications[0].event.message.match(
          /<propertyName>(.*?)<\/propertyName>/g,
        )

        if (eventNotifications[0].event.type === 'Mention') {
          // return `Nya kommentarer i projektinlägget <b>${projectNoteName}</b> för <b>${projectName}</b>`
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `Nya <font color="#B86890"><b>omnämnande</b></font> av dig på hemväggen för <b>${propertyName}</b>`
        }

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `Nya meddelande på hemväggen för <b>${propertyName}</b>`

      case 'PropertyNote':
        if (eventNotifications.length === 1) {
          return eventNotifications[0].event.message
        }

        // Multiple event notifications for current context
        // eslint-disable-next-line no-case-declarations
        propertyName = eventNotifications[0].event.message.match(
          /<propertyName>(.*?)<\/propertyName>/g,
        )
        // eslint-disable-next-line no-case-declarations
        propertyNoteName = eventNotifications[0].event.message.match(
          /<propertyNoteName>(.*?)<\/propertyNoteName>/g,
        )

        if (eventNotifications[0].event.type === 'Mention') {
          // return `Nya kommentarer i projektinlägget <b>${projectNoteName}</b> för <b>${projectName}</b>`
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `Nya <font color="#B86890"><b>omnämnande</b></font> av dig på  för <b>${propertyName}</b>`
        }

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `Nya kommentarer i anteckningen <b>${propertyNoteName}</b> för <b>${propertyName}</b>`

      case 'Project':
        if (eventNotifications.length === 1) {
          return eventNotifications[0].event.message
        }
        projectName = eventNotifications[0].event.message.match(
          /<projectName>(.*?)<\/projectName>/g,
        )
        if (eventNotifications[0].event.type === 'Mention') {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `Nya <font color="#B86890"><b>omnämnande</b></font> av dig på projektväggen för <b>${projectName}</b>`
        }
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `Nya meddelande på projektväggen för <b>${projectName}</b>`

      case 'ProjectNote':
        // eslint-disable-next-line no-console
        // console.log('message: ', eventNotifications[0].event.message)

        if (eventNotifications.length === 1) {
          return eventNotifications[0].event.message
        }

        // Multiple event notifications for current context
        // eslint-disable-next-line no-case-declarations
        projectNoteName = eventNotifications[0].event.message.match(
          /<projectNoteName>(.*?)<\/projectNoteName>/g,
        )
        // eslint-disable-next-line no-case-declarations
        projectName = eventNotifications[0].event.message.match(
          /<projectName>(.*?)<\/projectName>/g,
        )
        if (eventNotifications[0].event.type === 'Mention') {
          // return `Nya kommentarer i projektinlägget <b>${projectNoteName}</b> för <b>${projectName}</b>`
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `Nya <font color="#B86890"><b>omnämnande</b></font> av dig i projektinlägget <b>${projectNoteName}</b> för <b>${projectName}</b>`
        }
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `Nya kommentarer i projektinlägget <b>${projectNoteName}</b> för <b>${projectName}</b>`

      // TODO: implement these
      // case 'HelpRequest':
      // case 'HelpRequestTemplate':
      // case 'ProjectPlace':
    }
    return ''
  }

  return (
    <Flex
      px={px}
      // py={3}
      bg={bg}
      w="100%"
      // gap={3}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        gap={4}
        alignItems="center"
        py={3}
        px={2}
        w="100%"
        onClick={() => {
          navigate(getAreaPath())
          onCloseMenu ? onCloseMenu() : ''
        }}
      >
        {eventNotifications.length === 1 &&
          eventNotifications[0].event.type === 'Message' &&
          eventNotifications[0].event.createdBy && (
            <AccountAvatar
              accountId={eventNotifications[0].event.createdBy}
              variant={'avatar'}
              avatarSize="md"
            />
          )}
        {eventNotifications.length === 1 &&
          eventNotifications[0].event.type === 'Mention' &&
          eventNotifications[0].event.createdBy && (
            <Flex flexDir="column" h={12}>
              <AccountAvatar
                accountId={eventNotifications[0].event.createdBy}
                variant={'avatar'}
                avatarSize="md"
              />
              <Icon name={'mention'} transform="translate(17px,-20px)" h={6} />
            </Flex>
          )}
        {eventNotifications.length === 1 &&
          eventNotifications[0].event.type === 'System' && (
            <Flex minW={12} minH={12}>
              <Icon name={'systemMessage'} />
            </Flex>
          )}

        {eventNotifications.length > 1 && (
          <Circle
            border="2px solid"
            borderColor="gray.400"
            color="primary"
            bg="cyan.50"
            fontWeight="semibold"
            size={12}
          >
            {eventNotifications.length}
          </Circle>
        )}
        <Flex flexDir="column">
          {showDate && (
            <Text textStyle="xxsLight" pr={6} color="gray.600">
              {getDateStrFromNumber(eventNotifications[0].event.createdAt)}
            </Text>
          )}
          <Text
            textStyle="textSmallLight"
            bg="transparent"
            w="100%"
            maxW="container.lg"
            zIndex="docked"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: getMessage(),
              }}
            />
          </Text>
          {/* <HtmlPane
            content={getMessage()}
            py={0}
            px={0}
            textStyle="textSmallLight"
            bg="transparent"
          /> */}
        </Flex>
      </Flex>
      <Flex justifyContent="right">
        {eventNotifications[0].status === 'New' && (
          <Checkbox
            mx={2}
            size="lg"
            borderColor="gray.400"
            onChange={() => {
              void setHasReadEventNotificationsHandler()
            }}
            _hover={{ borderColor: 'primary' }}
          ></Checkbox>
        )}
        {eventNotifications[0].status === 'Read' && (
          <CheckCircleIcon boxSize={6} color="logo_green01" mx={1.5} />
        )}
      </Flex>
    </Flex>
  )
}
export default EventNotificationCard
