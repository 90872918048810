import {
  Divider,
  Flex,
  HStack,
  Text,
  Image,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { PropertyResponse } from '@contracts/misc'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import {
  PATH_CONSTRUCTION_PROCESS,
  PATH_DETAILPLAN_AND_BUILDINGPERMIT,
  PATH_MY_PRIVATE_PAGES,
} from '../../../common/nav'
import { useProjectsByProperty } from '../../../apiClients/projectsApiClient'
import AddProjectButton from '../../ui/AddProjectButton'
import PromoCardSimple from '../../ui/PromoCardSimple'
import promoWhatMayIDo from '../../../img/properties/promoWhatMayIDo.svg'
import promoBuildingProcess from '../../../img/properties/promoBuildingProcess.svg'
import ProjectCard from '../../projects/ProjectFrontCard'
import { Icon } from '../../ui/Icon'
import noProjectsPromo from '../../../img/projects/noProjectsPromo.png'
import { AreaContext } from '../../../common/area-context'

const MyProjectsSection: React.FC<{
  property: PropertyResponse
  mx?: number | string
  mb?: number | string
  pt?: number | string
}> = ({ property, mx, mb, pt }) => {
  const { t } = useTranslation()
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const { data: projectsByProperty } = useProjectsByProperty(property.id)
  const [viewOption, setViewOption] = useState<'compact' | 'micro'>(
    isTabletOrSmaller ? 'micro' : 'compact',
  )

  const currentUserCanEdit = true

  // const { currentUserCanEdit } = useCollaboratorParts({
  //   propertyId: property.id,
  // })
  // const

  const { pathname } = useLocation()
  let baseUrl = ''
  if (pathname.startsWith(PATH_MY_PRIVATE_PAGES)) {
    baseUrl = PATH_MY_PRIVATE_PAGES
  } else {
    baseUrl = ''
  }

  const spacingY = 6

  useEffect(() => {
    if (isTabletOrSmaller) {
      setViewOption('micro')
    } else {
      setViewOption('compact')
    }
  }, [isTabletOrSmaller])

  return (
    <>
      <Flex flexDir="column" w="100%" pt={pt} mx={mx} mt={8}>
        <Flex
          flexDir="row"
          justifyContent="space-between"
          fontSize={24}
          color="primary_grey04"
        >
          <HStack>
            <Tooltip
              hasArrow
              isDisabled={currentUserCanEdit}
              label={t('properties.propertyForm.canNotEditProperty')}
            >
              <Flex flexDir="row" gap={2} alignItems="center">
                <Text textStyle="textRegular">{t('general.myProjects')}</Text>
                {!currentUserCanEdit && (
                  <Icon name="WarningTriangle" h={20} color="orange.500" />
                )}
              </Flex>
            </Tooltip>
            <AddProjectButton
              disabled={!currentUserCanEdit}
              properties={[property]}
              variant={'ICON'}
            />
          </HStack>
        </Flex>
        <Divider borderColor="black" mt={2} />
      </Flex>
      <AreaContext.Provider value={{ area: 'project' }}>
        <Flex flexDir="column" mx={mx} mb={mb}>
          <Flex
            pos="relative"
            flexDir="row"
            justifyContent="left"
            gap={{ xxs: 4, laptop: 8 }}
            mt={8}
            wrap="wrap"
          >
            {projectsByProperty &&
              projectsByProperty.map(project => (
                <ProjectCard
                  projectId={project.id}
                  key={project.id}
                  variant={viewOption}
                />
              ))}

            {!projectsByProperty?.length && (
              <PromoCardSimple
                bg="transparent"
                variant={isTabletOrSmaller ? 'wide' : 'full'}
                noBorder
                mb={4}
              >
                <Image src={noProjectsPromo} />
              </PromoCardSimple>
            )}
          </Flex>
          <Flex flexDir="row" mt={6} gap={{ xxs: 4, laptop: 8 }}>
            <PromoCardSimple
              h="160px"
              w="240px"
              mb={spacingY}
              link={`${baseUrl}${PATH_DETAILPLAN_AND_BUILDINGPERMIT}`}
              variant={viewOption}
            >
              <Image src={promoWhatMayIDo} />
            </PromoCardSimple>
            <PromoCardSimple
              h="160px"
              w="240px"
              mb={spacingY}
              link={`${baseUrl}${PATH_CONSTRUCTION_PROCESS}`}
              variant={viewOption}
            >
              <Image src={promoBuildingProcess} />
            </PromoCardSimple>
          </Flex>
        </Flex>
      </AreaContext.Provider>
    </>
  )
}
export default MyProjectsSection
