import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  ResponsiveValue,
  Show,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import React from 'react'
import SortableHeading from '../../components/ui/SortableHeading'

import { calculateSortedItems } from '../../components/properties/helpRequestsListUtils'
import ProjectPlaceAdminSummaryCard from '../../components/projects/ProjectPlaceAdminSummaryCard'
import { AreaContext } from '../../common/area-context'
import {
  HelpRequestSortingOption,
  ProjectPlaceSortingOption,
} from '../../common/enums'

import { PATH_ADMIN_PAGES } from '../../common/nav'
import ProjectPlaceSummaryCard from '../../components/projects/ProjectPlaceSummaryCard'
import { useAllHelpRequestsAdmin } from '../../apiClients/projectsApiClient/helpRequests'

import ListHelpRequestRow from './ListHelpRequestRow'

const ListHelpRequests: React.FC<{
  searchHelpRequestId?: string | undefined
  px?: ResponsiveValue<string | number>
  mt?: string | number
  // variant?: 'admin-by-organization' | 'provider' | 'admin-all'
  // organizationId?: string
}> = ({
  searchHelpRequestId,
  px,
  mt,
  // variant = 'provider',
  // organizationId,
}) => {
  const { t } = useTranslation()

  const [sortType, setSortType] = useState(
    ProjectPlaceSortingOption.CREATED_DATE_DESC,
  )
  const [searchStr, setSearchStr] = useState<string | undefined>()

  // const { data: adminProjectPlaces } = useAllProjectPlacesAdmin()
  // const { data: orgProjectPlaces } = useProjectPlaces(organizationId)
  const { data: allHelpRequests } = useAllHelpRequestsAdmin()
  // eslint-disable-next-line no-console
  console.log('allHelpRequests: ', allHelpRequests)
  // const allProjectPlaces =
  //   variant === 'admin-all' ? adminProjectPlaces : orgProjectPlaces
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }
  const { pathname } = useLocation()
  const isAdminPages = pathname.startsWith(PATH_ADMIN_PAGES) ? true : false

  // const sortedProjectPlaces = calculateSortedItems(
  //   sortType,
  //   allProjectPlaces,
  //   searchStr,
  // )
  const sortedHelpRequests = calculateSortedItems(
    sortType,
    allHelpRequests,
    searchStr,
  )

  return (
    <Box pb={40} px={px} mt={mt}>
      <>
        <Helmet>
          <title>{t('projectPlaces.registeredProjectPlaces')}</title>
        </Helmet>

        <Flex flexDir="row" alignItems="center" flexWrap="wrap" mt={6} mb={6}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" />}
            />
            <Input
              type="text"
              placeholder={t('accounts.searchHomes')}
              maxW="md"
              borderColor="gray.400"
              height={10}
              onChange={e => setSearchStr(e.target.value)}
            />
          </InputGroup>
          <Text ml={4} textStyle="textMedium">
            {t('projectPlaces.searchInfo', {
              total: allHelpRequests?.length ?? 0,
              filtered: sortedHelpRequests.length ?? 0,
            })}
          </Text>
        </Flex>

        <Table
          variant="unstyled"
          colorScheme="gray"
          data-cy="accounts-list"
          maxW="400px"
        >
          <Show above="tablet">
            <Thead>
              <Tr>
                <Th w={10}></Th>

                <Th minW={48}>
                  <SortableHeading
                    variant="center"
                    onAscSort={() =>
                      setSortType(ProjectPlaceSortingOption.NAME_ASC)
                    }
                    onDescSort={() =>
                      setSortType(ProjectPlaceSortingOption.NAME_DESC)
                    }
                  >
                    {t('projectPlaces.name')}
                  </SortableHeading>
                </Th>
                <Th minW={28}>
                  <SortableHeading
                    variant="center"
                    // onAscSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.CONSUMER_STATUS_ASC)
                    // }
                    // onDescSort={() =>
                    //   setSortType(
                    //     ProjectPlaceSortingOption.CONSUMER_STATUS_DESC,
                    //   )
                    // }
                  >
                    Status kons.
                  </SortableHeading>
                </Th>
                <Th w={28}>
                  <SortableHeading
                    variant="center"
                    // onAscSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.PROVIDER_STATUS_ASC)
                    // }
                    // onDescSort={() =>
                    //   setSortType(
                    //     ProjectPlaceSortingOption.PROVIDER_STATUS_DESC,
                    //   )
                    // }
                  >
                    Status lev.
                  </SortableHeading>
                </Th>
                <Th>
                  <SortableHeading
                    w={24}
                    variant="center"
                    onAscSort={() =>
                      setSortType(HelpRequestSortingOption.CREATED_AT_ASC)
                    }
                    onDescSort={() =>
                      setSortType(HelpRequestSortingOption.CREATED_AT_DESC)
                    }
                  >
                    Skapad
                  </SortableHeading>
                </Th>

                {/* <Show above="laptop">
                  <Th w={36}>
                    <SortableHeading
                      variant="center"
                      onAscSort={() =>
                        setSortType(ProjectPlaceSortingOption.BEGIN_AT_ASC)
                      }
                      onDescSort={() =>
                        setSortType(ProjectPlaceSortingOption.BEGIN_AT_ASC)
                      }
                    >
                      {t('projectPlaces.beginAt')}
                    </SortableHeading>
                  </Th>
                </Show> */}
                {/* <Show above="laptop"> */}
                <Th w={36}>
                  <SortableHeading
                    variant="center"
                    // onAscSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.END_AT_ASC)
                    // }
                    // onDescSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.END_AT_ASC)
                    // }
                  >
                    Skapad av
                  </SortableHeading>
                </Th>
                <Th w={36}>
                  <SortableHeading
                    variant="center"
                    // onAscSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.END_AT_ASC)
                    // }
                    // onDescSort={() =>
                    //   setSortType(ProjectPlaceSortingOption.END_AT_ASC)
                    // }
                  >
                    Skickad till
                  </SortableHeading>
                </Th>
                {/* </Show> */}
              </Tr>
            </Thead>
          </Show>
          <Tbody>
            {sortedHelpRequests && sortedHelpRequests.length ? (
              sortedHelpRequests.map((helpRequest, i) => {
                let bg: string
                if (helpRequest.id === searchHelpRequestId) {
                  bg = 'red.100'
                } else {
                  bg = i % 2 !== 0 ? 'white' : 'primary_grey01'
                }
                // const addressStr = `${projectPlace.street}, ${projectPlace.city}`
                // // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                // const projectPlaceLink = `${PATH_PROJECT_PLACE}/${projectPlace.id}`

                return (
                  <Fragment key={helpRequest.id}>
                    <Show above="tablet">
                      {/* <ListProjectPlacesRow
                        projectPlace={projectPlace}
                        bg={bg}
                        accordionsToShow={accordionsToShow}
                        toggleAccordion={toggleAccordion}
                      /> */}
                      <ListHelpRequestRow
                        bg={bg}
                        accordionsToShow={[]}
                        helpRequest={helpRequest}
                        toggleAccordion={toggleAccordion}
                      />
                    </Show>
                    <Show below="tablet">
                      {/* <ListProj
                      ectPlacesRowFlex
                        projectPlace={projectPlace}
                        bg={bg}
                        accordionsToShow={accordionsToShow}
                        toggleAccordion={toggleAccordion}
                      /> */}
                      <ListHelpRequestRow
                        bg={bg}
                        accordionsToShow={[]}
                        helpRequest={helpRequest}
                        toggleAccordion={toggleAccordion}
                      />
                    </Show>
                    {accordionsToShow.includes(helpRequest.id) && (
                      <Tr bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}>
                        <AreaContext.Provider value={{ area: 'project' }}>
                          <Td colSpan={9}>
                            {isAdminPages && (
                              <ProjectPlaceAdminSummaryCard
                                projectPlaceId={helpRequest.id}
                                headerBg={
                                  i % 2 !== 0 ? 'white' : 'primary_grey01'
                                }
                              />
                            )}
                            {!isAdminPages && (
                              <ProjectPlaceSummaryCard
                                projectPlaceId={helpRequest.id}
                                headerBg={
                                  i % 2 !== 0 ? 'white' : 'primary_grey01'
                                }
                              />
                            )}
                          </Td>
                        </AreaContext.Provider>
                      </Tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>{t('projectPlaces.noProjectPlacesFound')}</Td>
              </Tr>
            )}
          </Tbody>
          {/* <Tbody>
            {sortedProjectPlaces && sortedProjectPlaces.length ? (
              sortedProjectPlaces.map((projectPlace, i) => {
                let bg: string
                if (projectPlace.id === searchProjectPlaceId) {
                  bg = 'red.100'
                } else {
                  bg = i % 2 !== 0 ? 'white' : 'primary_grey01'
                }
                // const addressStr = `${projectPlace.street}, ${projectPlace.city}`
                // // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                // const projectPlaceLink = `${PATH_PROJECT_PLACE}/${projectPlace.id}`

                return (
                  <Fragment key={projectPlace.id}>
                    <Show above="tablet">
                      <ListProjectPlacesRow
                        projectPlace={projectPlace}
                        bg={bg}
                        accordionsToShow={accordionsToShow}
                        toggleAccordion={toggleAccordion}
                      />
                    </Show>
                    <Show below="tablet">
                      <ListProjectPlacesRowFlex
                        projectPlace={projectPlace}
                        bg={bg}
                        accordionsToShow={accordionsToShow}
                        toggleAccordion={toggleAccordion}
                      />
                    </Show>
                    {accordionsToShow.includes(projectPlace.id) && (
                      <Tr bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}>
                        <AreaContext.Provider value={{ area: 'project' }}>
                          <Td colSpan={9}>
                            {isAdminPages && (
                              <ProjectPlaceAdminSummaryCard
                                projectPlaceId={projectPlace.id}
                                headerBg={
                                  i % 2 !== 0 ? 'white' : 'primary_grey01'
                                }
                              />
                            )}
                            {!isAdminPages && (
                              <ProjectPlaceSummaryCard
                                projectPlaceId={projectPlace.id}
                                headerBg={
                                  i % 2 !== 0 ? 'white' : 'primary_grey01'
                                }
                              />
                            )}
                          </Td>
                        </AreaContext.Provider>
                      </Tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <Tr>
                <Td colSpan={8}>{t('projectPlaces.noProjectPlacesFound')}</Td>
              </Tr>
            )}
          </Tbody> */}
        </Table>
      </>
    </Box>
  )
}
export default ListHelpRequests
