import React, { useContext, useRef } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js'
import { useTranslation } from 'react-i18next'
import { AccountData } from '@contracts/misc'
import AuthContext from './auth-context'

type ResultBox<T> = { v: T }
function useMemoOnce<T>(fn: () => T): T {
  const ref = useRef<ResultBox<T>>()
  if (!ref.current) {
    ref.current = { v: fn() }
  }
  return ref.current.v
}

const ApplicationInsightsProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const { currentUser } = useContext(AuthContext)
  const { t } = useTranslation()
  const userRef = useRef<AccountData | null>(currentUser)

  const reactPlugin = useMemoOnce(() => {
    const reactPluginExtension = new ReactPlugin()
    const appInsights = new ApplicationInsights({
      config: {
        // Always track Application Insights when the user is logged in. If not logged in, then the cookie consent will decide.
        // disableTelemetry: !userRef.current && isCookieDisabled,
        enableAutoRouteTracking: true,
        disableFetchTracking: true,
        extensions: [reactPluginExtension],
        // First part of the connection string contains the instrumentation key
        instrumentationKey: window.APPLICATIONINSIGHTS_CONNECTION_STRING.split(
          ';',
        )[0].split('InstrumentationKey=')[1],
      },
    })

    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer(telemetryItem => {
      if (!telemetryItem.data) {
        telemetryItem.data = {}
      }
      if (userRef.current) {
        appInsights.setAuthenticatedUserContext(userRef.current.id)
        telemetryItem.data.userId = userRef.current.id
        telemetryItem.data.userEmail = userRef.current.email
      }
    })

    return reactPluginExtension
  })

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <div>{t('general.topErrorBoundaryMessage')}</div>}
        appInsights={reactPlugin}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  )
}

export default ApplicationInsightsProvider
