import {
  ArrowBackIcon,
  DeleteIcon,
  EditIcon,
  QuestionIcon,
  ViewIcon,
  ViewOffIcon,
  DownloadIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  IconButton as IconButtonChakra,
  ResponsiveValue,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { FaHammer } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { ImArrowLeft2 } from 'react-icons/im'

const IconButton: React.FC<{
  buttonType:
    | 'DELETE'
    | 'EDIT'
    | 'PREVIEW'
    | 'PREVIEW_OFF'
    | 'BACK'
    | 'DOWNLOAD'
    | 'BACK_LARGE'
    | 'EDIT_LARGE'
    | 'PROJECT-PLACE'
  onClick?: React.MouseEventHandler
  m?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  size?: string
  tooltipLabel?: string
  label?: string
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: ResponsiveValue<number | string>
  'data-cy'?: string
}> = ({
  buttonType,
  onClick,
  m,
  mt,
  mr,
  mb,
  ml,
  size,
  tooltipLabel,
  label,
  top,
  bottom,
  left,
  right,
  'data-cy': dataCy,
}) => {
  switch (buttonType) {
    case 'DELETE':
      return (
        <Tooltip label={tooltipLabel}>
          <IconButtonChakra
            variant="outline"
            colorScheme="gray"
            aria-label="Delete"
            size={size ?? 'md'}
            icon={<DeleteIcon />}
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          />
        </Tooltip>
      )
    case 'EDIT':
      return (
        <Tooltip label={tooltipLabel}>
          <IconButtonChakra
            variant="outline"
            colorScheme="gray"
            aria-label="Edit"
            size={size ?? 'md'}
            icon={<EditIcon />}
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          />
        </Tooltip>
      )
    case 'PREVIEW':
      return (
        <Tooltip label={tooltipLabel}>
          <IconButtonChakra
            variant="outline"
            colorScheme="gray"
            aria-label="Preview"
            size={size ?? 'md'}
            icon={<ViewIcon />}
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          />
        </Tooltip>
      )
    case 'PREVIEW_OFF':
      return (
        <Tooltip label={tooltipLabel}>
          <IconButtonChakra
            variant="outline"
            colorScheme="gray"
            aria-label="Turn off preview"
            size={size ?? 'md'}
            icon={<ViewOffIcon />}
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          />
        </Tooltip>
      )
    case 'DOWNLOAD':
      return (
        <Tooltip label={tooltipLabel}>
          <IconButtonChakra
            variant="outline"
            colorScheme="gray"
            aria-label="Download file"
            size={size ?? 'md'}
            icon={<DownloadIcon />}
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          />
        </Tooltip>
      )
    case 'BACK':
      return (
        <Tooltip label={tooltipLabel}>
          <Button
            variant="outline"
            colorScheme="gray"
            aria-label="Turn off preview"
            size={size ?? 'md'}
            color="gray.600"
            onClick={onClick}
            type="button"
            m={m}
            mt={mt}
            mr={mr}
            mb={mb}
            ml={ml}
            data-cy={dataCy}
          >
            <ArrowBackIcon />
            &nbsp;
            {label}
          </Button>
        </Tooltip>
      )
    case 'BACK_LARGE':
      return (
        <Tooltip label={tooltipLabel}>
          <Box
            bg="gray.300"
            opacity={0.6}
            pos={top || bottom || left || right ? 'absolute' : 'relative'}
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            p={2}
            borderRadius="md"
            _hover={{ color: 'gray.500' }}
            onClick={onClick}
          >
            <ImArrowLeft2 size={24} />
          </Box>
        </Tooltip>
      )
    case 'EDIT_LARGE':
      return (
        <Tooltip label={tooltipLabel}>
          <Box
            bg="gray.300"
            opacity={0.6}
            pos={top || bottom || left || right ? 'absolute' : 'relative'}
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            p={2}
            borderRadius="md"
            _hover={{ color: 'gray.500' }}
            onClick={onClick}
          >
            <FiEdit size={24} />
          </Box>
        </Tooltip>
      )
    case 'PROJECT-PLACE':
      return (
        <Tooltip label={tooltipLabel}>
          <Box
            bg="gray.300"
            opacity={0.6}
            pos={top || bottom || left || right ? 'absolute' : 'relative'}
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            p={2}
            borderRadius="md"
            _hover={{ color: 'gray.500' }}
            onClick={onClick}
          >
            <FaHammer size={24} />
          </Box>
        </Tooltip>
      )
    default:
      return <QuestionIcon />
  }
}

export default IconButton
