import { CheckCircleIcon } from '@chakra-ui/icons'
import { Circle, Flex } from '@chakra-ui/react'
import { ProjectResponse } from '@contracts/projects'
import { useContext } from 'react'
import { useConversationsByArea } from '../../apiClients/messagesApiClient'
import { useNotes } from '../../apiClients/projectsApiClient/notes'
import AuthContext from '../../common/auth-context'
import ProjectAvatar from './ProjectAvatar'

const ProjectUpdatesHeader: React.FC<{ project: ProjectResponse }> = ({
  project,
}) => {
  const { currentUser } = useContext(AuthContext)

  const { data: projectNotes } = useNotes(project.id)

  let allProjectNoteIds: string[] = []
  if (projectNotes) {
    allProjectNoteIds = projectNotes.map(note => {
      return note.id
    })
  }
  // Changes since last login
  const newNotes = projectNotes?.filter(note => {
    return note.createdAt > (currentUser?.lastLoginAt ?? 0)
  })

  const { data: projectMessages } = useConversationsByArea(
    'Project',
    [project.id],
    true,
  )
  const { data: noteMessages } = useConversationsByArea(
    'ProjectNote',
    allProjectNoteIds,
    true,
  )

  const updatedProjectMessages = projectMessages?.filter(message => {
    return message.updatedAt > (currentUser?.lastLoginAt ?? 0)
  })
  const updatedNoteMessages = noteMessages?.filter(message => {
    return message.updatedAt > (currentUser?.lastLoginAt ?? 0)
  })

  let newOrUpdatedMessagesCount = 0
  if (updatedProjectMessages || updatedNoteMessages) {
    newOrUpdatedMessagesCount =
      (updatedProjectMessages ? updatedProjectMessages.length : 0) +
      (updatedNoteMessages ? updatedNoteMessages.length : 0)
  }

  const updatedNotes = projectNotes?.filter(note => {
    return (
      note.updatedAt > (currentUser?.lastLoginAt ?? 0) &&
      note.createdAt < (currentUser?.lastLoginAt ?? 0)
    )
  })

  return (
    <Flex
      flexDir="row"
      alignItems="center"
      columnGap={2}
      rowGap={2}
      wrap="wrap"
      pr={4}
      w={{ xxs: '93%', tablet: '100%' }}
      justifyContent="flex-start"
    >
      <ProjectAvatar projectId={project.id} variant="avatarAndName" w={64} />
      {newNotes?.length === 0 &&
        updatedNotes?.length === 0 &&
        newOrUpdatedMessagesCount === 0 && (
          // <Text>Grattis du är i fas &nbsp;🎉</Text>
          <CheckCircleIcon color="green" fontSize="xl" />
        )}
      {(newNotes?.length !== 0 ||
        updatedNotes?.length !== 0 ||
        newOrUpdatedMessagesCount !== 0) && (
        // <Text>Grattis du är i fas &nbsp;🎉</Text>
        <Circle bg="logo_purple" size={5} />
        // <CheckCircleIcon color="logo_purple" fontSize="xl" />
      )}

      {/* {(newNotes?.length ||
        updatedNotes?.length ||
        newOrUpdatedMessagesCount !== 0) && (
        <Flex
          flexDir="row"
          alignItems="center"
          columnGap={{ xxs: 2, tablet: 10, laptop: 20 }}
          wrap="wrap"
          w="60%"
          textAlign="left"
        >
          <Text textStyle="textMediumLight">
            {newNotes?.length === 0 ? t('general.none') : newNotes?.length}
            {newNotes?.length === 1 ? ` nytt ` : ` nya `}
            projektinlägg
          </Text>

          <Text textStyle="textMediumLight">
            {updatedNotes?.length === 0
              ? t('general.none')
              : updatedNotes?.length}
            {updatedNotes?.length === 1 ? ` uppdaterat ` : ` uppdaterade `}
            projektinlägg
          </Text>

          <Text textStyle="textMediumLight">
            {newOrUpdatedMessagesCount === 0
              ? t('general.none')
              : newOrUpdatedMessagesCount}
            {newOrUpdatedMessagesCount === 1 ? ` nytt ` : ` nya `}
            meddelanden
          </Text>
        </Flex>
      )} */}
    </Flex>
  )
}
export default ProjectUpdatesHeader
