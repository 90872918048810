import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image as ChakraImage,
  useMediaQuery,
} from '@chakra-ui/react'
import { PropertyResponse } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import { useContext } from 'react'
import {
  updateProperty,
  useProperty,
} from '../../../apiClients/propertiesApiClient'

import fallBackBackgroundImg from '../../../img/properties/propertyProfile3_s.png'
import fallBackProfileImg from '../../../img/properties/fallbackProperty.png'
import ImgPickerButton from '../../ui/ImgPickerButton'
import log from '../../../common/log'
import {
  PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX,
  PROPERTY_PROFILE_IMAGE_PREFIX,
} from '../../../pages/constants'
import {
  deleteAttachment,
  useAttachments,
} from '../../../apiClients/attachmentsApiClient'
import { useImageUrl } from '../../attachments/attachmentHelper'
import { getHomePath, getPropertiesPath } from '../../../common/nav'
import PropertyNameTag from '../../properties/PropertyNameTag'
import AuthContext from '../../../common/auth-context'
import IconButton from '../../ui/IconButton'
import MembersGroup from './MembersGroup'

const baseUrlPublic = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`
const PropertyHeaderSection: React.FC<{
  property: PropertyResponse | undefined
  isInEditMode?: boolean
}> = ({ property, isInEditMode = false }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { isProvider } = useContext(AuthContext)
  // Media query for screens smaller than laptop
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const [isLarger] = useMediaQuery('(max-width: 1000px)')
  const navigate = useNavigate()
  const profileImg = useImageUrl(property?.imageAttachmentId)

  const oldImgId = property?.imageAttachmentId ?? ''

  const { data: backgroundImg } = useAttachments(
    ['Property'],
    PROPERTY_PROFILE_BACKGROUND_IMAGE_PREFIX,
  )

  if (!property) {
    return <Box width="100%">{t('properties.noPropertyFound')}</Box>
  }
  const { mutate } = useProperty(property.id)

  const updatePropertyImgHandler = (
    inputProp: string | undefined,
    inputValue: string,
  ) => {
    updateProperty(property.id, {
      id: property.id,
      name: property.name,
      imageAttachmentId: inputValue,
    })
      .then(() => {
        // Delete blob only if private
        if (profileImg?.includes('/private/') && property?.imageAttachmentId) {
          log.debug('Deleting old property image: ', oldImgId)
          void deleteAttachment(oldImgId, true)
        }
        property.imageAttachmentId = inputValue
        void mutate()
      })
      .catch(e => {
        log.error({ err: e }, 'Failed to update Property img for property')
      })
  }

  if (isTabletOrSmaller) {
    return (
      <>
        {/* Header background image */}
        <ChakraImage
          h={32}
          w="100vw"
          fit="cover"
          src={profileImg ? profileImg : fallBackProfileImg}
          backgroundPosition="center"
        />
        {!isProvider && !profileImg && (
          <Box pos="absolute" top={20} right={20}>
            <ImgPickerButton
              pos="absolute"
              imgChangeHandler={updatePropertyImgHandler}
              iconColor="gray.500"
              variant={'GHOST-ICON'}
              ContextArea="Property"
              attachmentPrefix={PROPERTY_PROFILE_IMAGE_PREFIX}
            />
          </Box>
        )}
        {/* Edit profile button */}
        {!isProvider && !isInEditMode && (
          <Box
            bg="gray.300"
            opacity={0.6}
            pos="absolute"
            top={20}
            right={4}
            p={2}
            borderRadius="md"
            _hover={{ color: 'gray.500' }}
            onClick={() => {
              navigate(`edit`)
            }}
          >
            <FiEdit size={24} id="link-to-edit-property" />
          </Box>
        )}
        {!isProvider && isInEditMode && (
          <>
            <Box pos="absolute" top={20} right={20}>
              <ImgPickerButton
                imgChangeHandler={updatePropertyImgHandler}
                iconColor="gray.500"
                variant={'GHOST-ICON'}
                ContextArea="Property"
                attachmentPrefix={PROPERTY_PROFILE_IMAGE_PREFIX}
              />
            </Box>
            <IconButton
              buttonType={'BACK_LARGE'}
              top={20}
              right={4}
              onClick={() => {
                navigate(`${getPropertiesPath(pathname)}/${property.id}`)
              }}
            />
          </>
        )}

        <Breadcrumb pt={2} pl="5%" textStyle="breadCrumbs">
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() => {
                navigate(getHomePath(pathname))
              }}
            >
              Hem
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="#" isCurrentPage>
              {property.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PropertyNameTag property={property} mt={2} ml="5%" />
      </>
    )
  } else {
    // Screens larger than tablet
    return (
      <Box pos="relative">
        <Flex top={0} left={0} bottom={0}>
          {/* Background image */}
          <ChakraImage
            h={{ xxs: '140px', laptop: '180px' }}
            w="100vw"
            fit="cover"
            src={
              backgroundImg && backgroundImg.length > 0
                ? `${baseUrlPublic}${backgroundImg[0].id}`
                : fallBackBackgroundImg
            }
            backgroundPosition="center"
          />
          {/* Profile image */}
          <ChakraImage
            pos="absolute"
            src={profileImg ? profileImg : fallBackProfileImg}
            fit="cover"
            backgroundPosition="center"
            boxSize={{ xxs: 24, tablet: 32, laptop: 40 }}
            borderRadius="full"
            border="5px solid"
            borderColor="primary_grey02"
            mt={{ xxs: 28, tablet: 24, laptop: 32 }}
            ml={{ xxs: 2, tablet: 4, laptop: 12 }}
            zIndex="base"
          />
        </Flex>

        {!isProvider && !isInEditMode && (
          <Box
            bg="gray.300"
            opacity={0.6}
            pos="absolute"
            top={32}
            right={4}
            p={2}
            borderRadius="md"
            _hover={{ color: 'gray.500' }}
            onClick={() => {
              navigate(`edit`)
            }}
          >
            <FiEdit size={24} id="link-to-edit-property" />
          </Box>
        )}

        {!isProvider && (isInEditMode || !profileImg) && (
          <Box pos="absolute" top={{ tablet: '240px' }} left="175px">
            <ImgPickerButton
              imgChangeHandler={updatePropertyImgHandler}
              iconColor="gray.500"
              variant={'ICON'}
              ContextArea="Property"
              attachmentPrefix={PROPERTY_PROFILE_IMAGE_PREFIX}
            />
          </Box>
        )}
        {!isProvider && isInEditMode && (
          <IconButton
            buttonType={'BACK_LARGE'}
            top={32}
            right={4}
            onClick={() => {
              navigate(`${getPropertiesPath(pathname)}/${property.id}`)
            }}
          />
        )}

        <PropertyNameTag property={property} ml={56} mt={1} mb={4} />
        {!isLarger && (
          <MembersGroup
            position="absolute"
            top={180}
            right={2}
            propertyId={property.id}
            variant="avatars-compact"
            pt={0}
            max={5}
          />
        )}
      </Box>
    )
  }
}
export default PropertyHeaderSection
