import { Flex, Image } from '@chakra-ui/react'
import { useAttachments } from '../../apiClients/attachmentsApiClient'
import AddImageButton from './AddImageButton'
import Input from './Input'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const ImgPickerAdvanced: React.FC<{
  earlierValue?: string | null
  updateHandler: (inputProp: string, inputValue: string) => void
  inputCallbackName: string
  inputTitle?: string
  mt?: number
  mr?: number
  mb?: number
  ml?: number
}> = ({
  earlierValue,
  updateHandler,
  inputCallbackName,
  inputTitle = 'Input',
  mt,
  mr,
  mb,
  ml,
}) => {
  const { data: allArticleImgs } = useAttachments(['Article'])

  const imgName = allArticleImgs?.find(img => {
    return img.id === earlierValue
  })?.originalFilename

  return (
    <Flex
      flexDir="row"
      flexWrap="wrap"
      alignItems="center"
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
    >
      <Input
        title={inputTitle}
        name="heading"
        defaultValue={`${earlierValue ?? ''} ${imgName ?? ''}`}
        type="text"
        isMandatory={false}
        position="single"
        background="white"
        readOnly={true}
        w={96}
        h={20}
      />
      <AddImageButton
        variant={'ICON'}
        iconName={earlierValue ? 'AiOutlineEdit' : undefined}
        transform="translateX(-50px)"
        imageId={earlierValue ?? undefined}
        saveImgHandler={updateHandler}
        inputCallbackName={inputCallbackName}
      />
      {earlierValue && (
        <Image src={`${baseUrl}${earlierValue}`} h={20} alignSelf="center" />
      )}
    </Flex>
  )
}
export default ImgPickerAdvanced
