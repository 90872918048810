import { Button, Tooltip, useDisclosure } from '@chakra-ui/react'
import { ReactElement, useContext } from 'react'
import { useOrganizationAccounts } from '../../apiClients/organizationsApiClient'
import AuthContext from '../../common/auth-context'
import EmailVerificationRequiredCard from '../collaborators/EmailVerificationRequiredCard'
import AddOrEditOrgAccount from '../organizations/AddOrEditOrgAccount'
import { Icon } from './Icon'
import Modal from './Modal'

const AddOrEditOrgAccountButton: React.FC<{
  orgId: string
  orgAccountId?: string
  mt?: number
  w?: number
  accountEmail?: string
  children?: ReactElement
  variant: 'BUTTON' | 'ICON'
  isDisabled?: boolean
  tooltip?: string
}> = ({
  orgId,
  orgAccountId,
  mt,
  children,
  w,
  variant,
  isDisabled,
  tooltip,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { currentUser } = useContext(AuthContext)

  const { data: orgAccounts } = useOrganizationAccounts(orgId)
  const currentOrgAccount = orgAccounts?.find(orgAccount => {
    return orgAccount.accountId === currentUser?.id
  })

  const allowedToEdit =
    currentUser?.status === 'Verified' &&
    currentOrgAccount?.accountRelation === 'Owner'

  if (variant === 'BUTTON') {
    return (
      <>
        <Tooltip label={tooltip}>
          <Button
            onClick={onOpen}
            mt={mt}
            variant="secondary"
            w={w || 32}
            disabled={isDisabled}
          >
            {children}
          </Button>
        </Tooltip>

        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          {allowedToEdit ? (
            <AddOrEditOrgAccount orgId={orgId} onCloseModal={onClose} />
          ) : (
            <EmailVerificationRequiredCard
              area="Organization"
              onCloseModal={onClose}
              emailToVerify={currentUser?.email}
            />
          )}
        </Modal>
      </>
    )
  }

  if (variant === 'ICON') {
    return (
      <>
        {!orgAccountId && (
          // Add new collaborator
          <Icon
            name="IoAddCircleOutline"
            _hover={{
              color: 'gray',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            color={isDisabled ? 'gray.300' : 'primary_orange02'}
            onClick={isDisabled ? undefined : onOpen}
            isDisabled={isDisabled}
            tooltip={tooltip}
          />
        )}
        {orgAccountId && (
          // Edit existing collaborator
          <Icon
            name="AiOutlineEdit"
            _hover={{
              color: 'gray',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            color={isDisabled ? 'gray.300' : 'primary_orange02'}
            onClick={isDisabled ? undefined : onOpen}
            isDisabled={isDisabled}
            tooltip={tooltip}
          />
        )}
        <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <>
            {allowedToEdit && (
              <AddOrEditOrgAccount
                orgId={orgId}
                orgAccountId={orgAccountId}
                onCloseModal={onClose}
                isEdit={true}
              />
            )}
            {!allowedToEdit && (
              <EmailVerificationRequiredCard
                area="Organization"
                onCloseModal={onClose}
                emailToVerify={currentUser?.email}
              />
            )}
          </>
        </Modal>
      </>
    )
  }
  return null
}
export default AddOrEditOrgAccountButton
