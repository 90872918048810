import { Avatar, Flex, ResponsiveValue, Text } from '@chakra-ui/react'
import {
  useOrganization,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'
import { useImageUrl } from '../attachments/attachmentHelper'
import AccountProfileCardModal from './AccountProfileCardModal'

const OrganizationAvatar: React.FC<{
  organizationId: string | undefined
  email?: string
  variant: 'avatar' | 'name' | 'avatarAndName' | 'full' | 'avatarAndNameOneRow'

  mr?: number | string
  mt?: number | string
  mb?: number | string
  mx?: ResponsiveValue<number | string>
  my?: number | string
  w?: ResponsiveValue<number | string>
  minW?: number | string
  h?: number | string
  py?: number
  color?: string
  lightFont?: boolean
  textStyle?: string
  columnGap?: number
  border?: string
}> = ({
  organizationId,
  variant,
  mr,
  mt,
  mb,
  mx,
  my,
  w,
  minW,
  h = 8,
  color,
  py,
  lightFont,
  textStyle,
  columnGap = 2,
  border,
  email,
}) => {
  const { data: org } = useOrganization(organizationId)
  const { data: orgProfile } = useOrganizationProfile(organizationId)

  const imgUrl = useImageUrl(orgProfile?.profileAttachmentId)

  switch (variant) {
    case 'avatarAndName':
      if (organizationId) {
        return (
          <AccountProfileCardModal
            organizationId={organizationId}
            variant="organizationAccount"
          >
            <Flex
              flexDir="row"
              justifyContent="left"
              mr={mr}
              mt={mt}
              mb={mb}
              mx={mx}
              my={my}
              color={color}
              w={w}
              minW={minW}
              textStyle={textStyle}
              border={border}
            >
              <Avatar name={org?.name} src={imgUrl} size="sm"></Avatar>
              <Flex
                flexDir="column"
                textStyle={lightFont ? 'regularLight' : ''}
              >
                <Text ml={1.5} pt={1} fontStyle={!org ? 'italic' : ''}>
                  {org?.name}
                </Text>
              </Flex>
            </Flex>
          </AccountProfileCardModal>
        )
      } else {
        return (
          <Flex
            flexDir="row"
            justifyContent="left"
            mr={mr}
            mt={mt}
            mb={mb}
            mx={mx}
            my={my}
            color={color}
            w={w}
            minW={minW}
            textStyle={textStyle}
            border={border}
          >
            <Avatar name={org?.name} src={imgUrl} size="sm"></Avatar>
            <Flex flexDir="column" textStyle={lightFont ? 'regularLight' : ''}>
              <Text ml={1.5} pt={1} fontStyle={!org ? 'italic' : ''}>
                {/* <Text ml={1.5} pt={1}> */}
                {email}
              </Text>
            </Flex>
          </Flex>
        )
      }
    case 'avatar':
      return (
        <AccountProfileCardModal
          organizationId={organizationId}
          variant="organizationAccount"
        >
          <Avatar
            name={org?.name}
            src={imgUrl}
            size="sm"
            mr={mr}
            mx={mx}
            my={my}
            py={py}
          />
        </AccountProfileCardModal>
      )
    case 'avatarAndNameOneRow':
      return (
        <Flex
          columnGap={columnGap}
          wordBreak="break-word"
          textStyle={textStyle}
          w={w}
          h={h}
          overflow="clip"
          border={border}
          alignItems="center"
          mb={mb}
          minW={minW}
        >
          <Avatar
            name={org?.name}
            src={imgUrl}
            size="sm"
            mr={mr}
            mx={mx}
            my={my}
          />
          <Text lineHeight={1}>{org?.name}</Text>
        </Flex>
      )
    case 'full':
      return (
        <Flex
          wrap="nowrap"
          columnGap={4}
          color={color}
          w={w}
          border={border}
          minW={minW}
        >
          <Avatar
            name={org?.name}
            src={imgUrl}
            size="lg"
            mr={mr}
            mx={mx}
          ></Avatar>

          <Flex flexDir="column" wrap="wrap" textStyle={textStyle}>
            <Text fontSize="18px" fontWeight="normal" my={my} h="22px">
              {org?.name}
            </Text>
            <Text fontWeight="light" wordBreak="break-word">
              {org?.email}
            </Text>
            <Text fontWeight="light">{org?.phoneNumber ?? ''}</Text>
          </Flex>
        </Flex>
      )

    case 'name':
      return (
        <Text textStyle={textStyle} border={border} minW={minW}>
          {org?.name}
        </Text>
      )
  }
}
export default OrganizationAvatar
