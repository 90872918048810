import { t } from 'i18next'

interface ValidationResponse {
  isValid: boolean
  errorMsg?: string
}

export const validateEmail = (email: string): ValidationResponse => {
  // Hard email verification - Turned off
  // if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {

  // Soft email verification used - Not used
  // if (/^[\w-.]+@([\w-]+)/g.test(email)) {

  // Super super soft email verification used
  if (/.+@.+/g.test(email)) {
    return { isValid: true }
  }
  return {
    isValid: false,
    errorMsg: t('auth.errorMessages.invalidEmail'),
  }
}

export const validateString = (
  str: string,
  strName: string,
  minLength: number,
  maxLength?: number,
  isMandatory = false,
): ValidationResponse => {
  strName = strName.toLowerCase()

  if (!str) {
    if (isMandatory) {
      return {
        isValid: false,
        errorMsg: t('auth.errorMessages.stringMissing', { strName }),
      }
    } else {
      return { isValid: true }
    }
  }

  if (str.length < minLength) {
    return {
      isValid: false,
      errorMsg: t('auth.errorMessages.stringTooShort', { strName, minLength }),
    }
  }
  if (maxLength && str.length > maxLength) {
    return {
      isValid: false,
      errorMsg: t('auth.errorMessages.stringTooLong', { strName, maxLength }),
    }
  }

  return { isValid: true }
}

export const validatePassword = (pwd: string): ValidationResponse => {
  if (!pwd) {
    return { isValid: false, errorMsg: t('auth.errorMessages.passwordMissing') }
  } else if (pwd.length < 5) {
    return {
      isValid: false,
      errorMsg: t('auth.errorMessages.passwordTooShort'),
    }
  }
  return { isValid: true }
}
export const validatePasswordMatch = (
  pwd1: string,
  pwd2: string,
): ValidationResponse => {
  if (pwd1 !== pwd2) {
    return {
      isValid: false,
      errorMsg: t('auth.errorMessages.passwordNotMatching'),
    }
  }
  return { isValid: true }
}
