import { useContext } from 'react'
import { KeyedMutator } from 'swr'
import {
  AccountRelation,
  Collaborator,
  CreateCollaboratorRequest,
  UpdateCollaboratorRequest,
} from '@contracts/misc'

import { useArea } from '../../common/area-context'
import AuthContext from '../../common/auth-context'
import {
  useCollaborators as usePropertyCollaborators,
  addCollaborator as addPropertyCollaborator,
  updateCollaborator as updatePropertyCollaborator,
  deleteCollaborator as deletePropertyCollaborator,
} from '../../apiClients/propertiesApiClient'
import {
  useCollaborators as useProjectCollaborators,
  addCollaborator as addProjectCollaborator,
  updateCollaborator as updateProjectCollaborator,
  deleteCollaborator as deleteProjectCollaborator,
} from '../../apiClients/projectsApiClient'
import log from '../../common/log'

const isCollaboratorRelationOwnerOrEditor = (relation?: AccountRelation) => {
  return ['Owner', 'Editor'].includes(relation ?? '')
}

interface CollaboratorParts {
  entityId: string
  addCollaborator: (
    entityId: string,
    newCollaborator: CreateCollaboratorRequest,
  ) => Promise<void>
  updateCollaborator: (
    entityId: string,
    collaboratorRequest: UpdateCollaboratorRequest,
    newAccountEmail: string,
  ) => Promise<void>
  deleteCollaborator: (collaborator: Collaborator) => Promise<void>
  collaborators: Collaborator[] | undefined
  currentUserCollaboratorRelation?: AccountRelation
  currentUserCanEdit: boolean
  mutate: KeyedMutator<Collaborator[] | undefined>
}

/**
 * Handle differences when handling collaborators for a 'property' or a 'project'.
 */
export const useCollaboratorParts = ({
  propertyId,
  projectId,
}: {
  propertyId?: string
  projectId?: string
}): CollaboratorParts => {
  const { area } = useArea()
  const { currentUser } = useContext(AuthContext)

  if ((area === 'property' || !area) && propertyId) {
    const { data, mutate } = usePropertyCollaborators(propertyId)
    const currentCollaborator = data?.find(c => {
      return c.account?.id === currentUser?.id
    })
    return {
      entityId: propertyId,
      collaborators: data,
      currentUserCollaboratorRelation: currentCollaborator?.accountRelation,
      currentUserCanEdit: isCollaboratorRelationOwnerOrEditor(
        currentCollaborator?.accountRelation,
      ),
      mutate,
      addCollaborator: async (
        entityId: string,
        newCollaborator: CreateCollaboratorRequest,
      ) =>
        addPropertyCollaborator(entityId, newCollaborator).then(() => {
          void mutate()
        }),
      updateCollaborator: async (
        entityId: string,
        collaboratorRequest: UpdateCollaboratorRequest,
      ) =>
        updatePropertyCollaborator(entityId, collaboratorRequest).then(() => {
          void mutate()
        }),
      deleteCollaborator: async (collaborator: Collaborator) =>
        deletePropertyCollaborator(collaborator).then(() => {
          void mutate()
        }),
    }
  } else if ((area === 'project' || !area) && projectId) {
    const { data, mutate } = useProjectCollaborators(projectId)

    const currentCollaborator = data?.find(c => {
      return c.accountId === currentUser?.id
    })
    return {
      entityId: projectId,
      collaborators: data,
      currentUserCollaboratorRelation: currentCollaborator?.accountRelation,
      currentUserCanEdit: isCollaboratorRelationOwnerOrEditor(
        currentCollaborator?.accountRelation,
      ),
      mutate,
      addCollaborator: async (
        entityId: string,
        newCollaborator: CreateCollaboratorRequest,
      ) =>
        addProjectCollaborator(entityId, newCollaborator).then(() => {
          void mutate()
        }),
      updateCollaborator: async (
        entityId: string,
        collaboratorRequest: UpdateCollaboratorRequest,
      ) =>
        updateProjectCollaborator(entityId, collaboratorRequest).then(() => {
          void mutate()
        }),
      deleteCollaborator: async (collaborator: Collaborator) =>
        deleteProjectCollaborator(collaborator).then(() => {
          void mutate()
        }),
    }
  } else if (area === 'projectPlace') {
    // } else if (area === 'projectPlace' && projectId) {
    log.debug('Area is projectPlace')
  } else {
    // Area is not Property nor Project
    log.debug("Area is not 'property' nor 'project'")
    log.debug(`Area is: *${area}*`)
    // return undefined
  }

  throw new Error(
    'To get correct collaborator parts the component must be in an AreaContext.',
  )
}
