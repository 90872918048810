import { Box, Button } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { validateString } from '../../common/auth-utils'
import log from '../../common/log'

import Input from '../ui/Input'
import {
  addGlossaryItem,
  useConstructionGlossary,
} from '../../apiClients/constructionGlossaryApiClient'

const MAX_LENGTH_INPUT = 255
interface AddGlossaryItemValues {
  name: string
  description: string
}
interface Errors {
  name?: string
  description?: string
}

const CreateConstructionGlossaryItem: React.FC<{
  onCloseModal: () => void
}> = ({ onCloseModal }) => {
  const { t } = useTranslation()
  const { mutate } = useConstructionGlossary()

  const initialValues = {
    name: '',
    description: '',
  }
  const validate = (values: AddGlossaryItemValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.name,
      t('constructionGlossary.name'),
      2,
      MAX_LENGTH_INPUT,
    )
    if (!tmpRes.isValid) {
      errors.name = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.description,
      t('constructionGlossary.description'),
      2,
      MAX_LENGTH_INPUT,
    )
    if (!tmpRes.isValid) {
      errors.description = tmpRes.errorMsg
    }
    return errors
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      addGlossaryItem(values.name, values.description)
        .then(() => {
          log.info('Successfully created new glossary item')
        })
        .catch(e => {
          log.error(
            { err: e },
            'Failed to create new construction glossary item',
          )
        })
        .finally(() => {
          onCloseModal()
          void mutate()
          setSubmitting(false)
        })
    },
  })

  return (
    <form data-cy="add-construction-glossary-item" onSubmit={handleSubmit}>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('constructionGlossary.addGlossaryItem')}</Box>
      </Box>

      <Box px={4} mt={6}>
        <Input
          title={t('constructionGlossary.name')}
          name="name"
          type="text"
          isMandatory={true}
          isError={!!errors.name}
          errorText={touched.name && errors.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          position="top"
        />
        <Input
          title={t('constructionGlossary.description')}
          name="description"
          type="text"
          isMandatory={true}
          isError={!!errors.description}
          errorText={
            touched.description && errors.description ? errors.description : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          position="bottom"
        />

        <Button
          type="submit"
          mt={6}
          mb={4}
          variant="primary"
          w="100%"
          disabled={isLoading}
        >
          {t('input.submitForm')}
        </Button>
      </Box>
    </form>
  )
}
export default CreateConstructionGlossaryItem
