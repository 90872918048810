import { Flex, Text, ResponsiveValue, Box } from '@chakra-ui/react'
import { startsWith } from 'lodash'
import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'

const ContentPageHeader: React.FC<{
  label: string | ReactNode
  ingress?: string
  mb?: ResponsiveValue<number | string>
  mt?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
  pt?: ResponsiveValue<number | string>
  pb?: ResponsiveValue<number | string>
  h?: ResponsiveValue<string | number>
  backgroundImgSrc?: string
  bgSize?: 'contain' | 'cover' | 'auto'
  bg?: string
  textStyleIngress?: string
  isEmbedded?: boolean
  headingColor?: string
  textColor?: string
  border?: string
  textStyle?: ResponsiveValue<string>
  children?: ReactNode
  variant?: 'normal' | 'ghost'
}> = ({
  label,
  ingress,
  mb,
  mt,
  px = '5%',
  h,
  pt,
  pb,
  backgroundImgSrc,
  bgSize = 'auto',

  bg,
  textStyleIngress = 'textThin',
  isEmbedded = false,
  headingColor = 'black',
  textColor = 'black',
  border,
  textStyle = 'h3',
  children,
  variant = 'normal',
}) => {
  const location = useLocation()
  const isMyPages = startsWith(location.pathname, PATH_MY_PRIVATE_PAGES)

  return (
    <Flex
      pos="relative"
      flexDir="column"
      width="100%"
      border={border}
      bgGradient={
        isMyPages && variant === 'normal'
          ? 'linear(to-b, primary_green02, primary_green01)'
          : ''
      }
      zIndex="base"
      mb={mb}
      mt={mt}
      px={px}
      mx={0}
      pt={pt}
      pb={pb}
      bg={bg}
      bgImg={backgroundImgSrc}
      bgPos="center"
      bgSize={bgSize}
      bgRepeat="no-repeat"
      h={h}
    >
      <Flex flexDir="column" mt={{ xxs: isEmbedded ? 0 : 10, laptop: 0 }}>
        <Box
          textStyle={textStyle}
          mt={{ xxs: 4, tablet: 0, laptop: 8 }}
          mb={2}
          zIndex="docked"
          color={headingColor}
        >
          {label}
        </Box>
        {ingress ? (
          <Text
            textStyle={textStyleIngress}
            pb={6}
            w="100%"
            maxW="container.lg"
            zIndex="docked"
            color={textColor}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: ingress,
              }}
            />
          </Text>
        ) : null}
        {children}
      </Flex>
    </Flex>
  )
}
export default ContentPageHeader
