import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { RefObject } from 'react'
import { useTranslation } from 'react-i18next'

const AlertDialogUnsavedChanges: React.FC<{
  isOpen: boolean
  leastDestructiveRef: RefObject<HTMLButtonElement>
  onClose: () => void
  onConfirmClose: (avoidAlertDialog: boolean) => void
}> = ({ isOpen, onClose, leastDestructiveRef, onConfirmClose }) => {
  const { t } = useTranslation()
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mt={44}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('general.unsavedChanges')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('general.cancelWithoutSavingQuestion')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={leastDestructiveRef} onClick={onClose}>
              {t('ui.button.back')}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onConfirmClose(true)
                onClose()
              }}
              ml={3}
            >
              {t('general.cancelWithoutSaving')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
export default AlertDialogUnsavedChanges
