import { DeleteIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import FileIcon from './FileIcon'

const EditFileCardCompact: React.FC<{
  file: File
  minW?: number | string
  maxW?: number | string
  onDeleteFiles: (filenames: string[]) => void
}> = ({ file, minW, maxW, onDeleteFiles }) => {
  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      minW={minW}
      maxW={maxW}
      _hover={{ color: 'gray.500' }}
      w="100%"
      // border="1px solid red"
    >
      <>
        <Flex flexDir="row" alignItems="center" gap={2}>
          <FileIcon file={file} />
          <Text textStyle="cardText">{file.name}</Text>
        </Flex>
        <DeleteIcon
          boxSize={4}
          color="gray.500"
          _hover={{ color: 'gray.400' }}
          onClick={() => {
            onDeleteFiles([file.name])
          }}
        />
      </>
    </Flex>
  )
}
export default EditFileCardCompact
