import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerWriteArticle.png'
import Link from '../../components/ui/Link'

const ProviderWriteArticlePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Flex flexDir="column" width="100%" bg="primary_grey01">
        <Helmet>
          <title>{t('portal.sidebar.provider.writeArticle')}</title>
        </Helmet>
        <ContentPageHeader
          label={`${t('portal.sidebar.provider.writeArticle').toUpperCase()}`}
          h={32}
          pt={4}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          bgSize="contain"
          backgroundImgSrc={headerBackground}
        />
      </Flex>

      <Flex
        flexDir="column"
        backgroundColor="white"
        py={6}
        mx={{ xxs: '5%', tablet: '15%' }}
        bg="primaryBackground"
      >
        <Text textStyle="textLarge" my={4}>
          Delad kunskap är dubbel kunskap!
        </Text>

        <Text textStyle="infoTextLight" my={4}>
          Delad kunskap är dubbel kunskap! Ett effektivt sätt att dela med sig
          av kunskap är att skriva en artikel. Det finns många bostadsägare som
          hade velat ta del av just din kunskap. Genom att skriva en artikel om
          ett aktuellt ämne eller vanlig frågeställning inom ditt expertområde
          kan du inspirera och hjälpa andra samtidigt som du bygger ditt eget
          varumärke och ökar din exponering till fler uppdrag. När du publicerar
          artiklar på Berikar exponeras du som författar för mängder av
          bostadsägare samtidigt som du bidrar till ökad kunskapsdelning!
        </Text>
        <Flex alignItems="center" gap={1}>
          <Text textStyle="infoTextLight" my={4}>
            Vill du skriva en artikel, hör av dig till oss på{' '}
          </Text>

          <Text textStyle="">
            <Link to="mailto:hej@berikar.se" isExternal color="primary">
              hej@berikar.se
            </Link>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
export default ProviderWriteArticlePage
