import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ProjectResponse } from '@contracts/projects'

import EditProject from '../../properties/EditProject'
import Divider from '../../ui/Divider'
import Heading from '../../ui/Heading'

const EditProjectSection: React.FC<{
  project: ProjectResponse
  px?: ResponsiveValue<number | string>
  mt?: number | string
}> = ({ project, px, mt }) => {
  const { t } = useTranslation()

  return (
    <Flex flexDir="column" maxW="container.xl" pt={12}>
      <Flex
        flexDir="column"
        wrap="wrap"
        px={px}
        mt={mt}
        borderRadius="lg"
        // border="1px solid red"
      >
        <Heading textStyle="h8" my={2}>
          {t('projects.projectInfo')}
        </Heading>
        <Divider my={0} mx={0} h={0} orientation="horizontal" />
        <EditProject project={project} />
      </Flex>
    </Flex>
  )
}
export default EditProjectSection
