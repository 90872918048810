import { Button, Flex, ResponsiveValue, Text, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { UpdateAccountOrganizationRequest } from '@contracts/accounts'
import { useState } from 'react'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import {
  updateOrganizationAccount,
  useOrganizationAccount,
} from '../../apiClients/organizationsApiClient'
import log from '../../common/log'
import Tiptap from '../richTextEditor/Tiptap'

interface FormValues {
  title: string
  profileText: string
}

const AddOrEditOrgAccountForm: React.FC<{
  orgId: string
  accountId: string
  px?: ResponsiveValue<number | string>
  mt?: number | string
  maxW?: number | string
}> = ({ orgId, accountId, px, mt, maxW }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const applicationInsights = useAppInsightsContext()

  const { data: accountOrg } = useOrganizationAccount(accountId)

  const [profileText, setProfileText] = useState(accountOrg?.profileText)

  const initialValues: FormValues = {
    title: accountOrg?.title ?? '',
    profileText: accountOrg?.profileText ?? '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    // values: formikValues,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    // validate,
    onSubmit: (values, { setSubmitting }) => {
      if (accountOrg) {
        const updateRequest: UpdateAccountOrganizationRequest = {
          profileText,
          title: values.title,
          accountRelation: accountOrg?.accountRelation || 'Viewer',
        }

        updateOrganizationAccount(orgId, accountId, updateRequest)
          .then(() => {
            applicationInsights.trackEvent(
              {
                name: 'Updated account organization',
              },
              updateRequest,
            )

            toast({
              // title: t('collaborators.updateSuccessMessage', {
              title: 'Företagsprofilen har uppdaterats',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            // void mutateOrgAccounts()
            // onCloseModal()
          })
          .catch(e => {
            log.error({ err: e }, 'Failed to update collaborator')
            toast({
              // title: t('collaborators.updateFailureMessage', {
              //   name: orgAccountId,
              // }),
              title: 'Företagsprofilen kunde inte uppdateras',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top',
            })
            // void mutateOrgAccounts()
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    },
  })

  if (accountOrg) {
    return (
      <form onSubmit={handleSubmit} data-cy="add-edit-collaborator">
        <Flex flexDir="column" px={px} mt={mt} maxW={maxW}>
          <Text textStyle="textLargeRegular" maxW={maxW}>
            {t('providers.profile.title')}
          </Text>
          <Input
            title={t('accounts.providers.profile.title')}
            name="title"
            type="text"
            isMandatory={false}
            isError={!!errors.title}
            errorText={touched.title && errors.title ? errors.title : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="plain"
            defaultValue={accountOrg?.title}
            mb={4}
          />
          <Flex flexDir="column" w="100%" mr={6}>
            <Text my={1}>{t('accounts.providers.profile.profileText')}</Text>
            <Tiptap
              border="1px solid"
              borderColor="gray.400"
              content={accountOrg?.profileText}
              w="100%"
              maxH="sm"
              h={40}
              toolbarVariant="none"
              withTypographyExtension={true}
              withLinkExtension={true}
              onChange={setProfileText}
              editorLabel={t('notes.addOrUpdateForm.description')}
              area="Account"
              areaId={accountId}
              parentArea={'Organization'}
              parentAreaId={orgId}
              container="private"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onImgAdded={() => {}}
            />
          </Flex>

          <Button
            type="submit"
            margin="1rem 0"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
          >
            {t('ui.button.update')}
          </Button>
        </Flex>

        {isLoading && <Spinner />}
      </form>
    )
  }
  return null
}
export default AddOrEditOrgAccountForm
