import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
// import { Heading, HorizontalSliderButton } from '../../ui'
import AuthContext from '../../common/auth-context'
import {
  PATH_MY_PROVIDER_PAGES,
  menuItemListNewCompany,
  getNavLinksProviderPortal,
} from '../../common/nav'
import { IconName } from '../../common/icon-utils'

import { Icon } from '../../components/ui/Icon'
import { Logo } from '../../components/ui/Logo'
import { SIDE_BAR_WIDTH_EXPANDED } from '../constants'
import { useProjects } from '../../apiClients/projectsApiClient'
import AddProjectPlace from '../../components/projects/AddProjectPlace'
import Modal from '../../components/ui/Modal'
import { Heading } from '../../ui'
import SidebarAreaHeader from './SidebarAreaHeader'
import SidebarItem from './SidebarItem'

const SidebarProviderPortal: React.FC<{
  // isExpanded: boolean
  // onToggle: () => void
}> = () =>
  // {
  // isExpanded, onToggle
  //  }
  {
    const { t } = useTranslation()
    const marginLeftHeading = 4
    const navigate = useNavigate()

    const { currentOrganization } = useContext(AuthContext)

    // const toggleExpandHandler = () => {
    //   onToggle()
    // }
    const navLinks = getNavLinksProviderPortal(
      currentOrganization?.id != undefined,
    )

    const { data: projects } = useProjects()

    const {
      isOpen: isOpenAddProjectModal,
      onOpen: onOpenAddProjectModal,
      onClose: onCloseAddProjectModal,
    } = useDisclosure()

    return (
      <>
        <Flex
          pos="fixed"
          padding="2.5rem 0rem"
          flexDir="column"
          // w={isExpanded ? SIDE_BAR_WIDTH_EXPANDED : SIDE_BAR_WIDTH_COLLAPSED}
          w={SIDE_BAR_WIDTH_EXPANDED}
          h="100vh"
          boxShadow="xl"
          bg="darkBackground"
          onScroll={() => {
            window.moveTo(50, 300)
          }}
          overflow="scroll"
        >
          {/* {isExpanded && ( */}
          <>
            <Logo
              name="BerikarProvider"
              mb={4}
              h={14}
              onClick={() => {
                navigate(PATH_MY_PROVIDER_PAGES)
              }}
              ml={6}
            />
            <Heading px={4} mb={6} textStyle="textRegular">
              {currentOrganization?.name}
            </Heading>
          </>
          {/* )} */}
          {/* {!isExpanded && ( */}
          {/* <Logo
            name="Berikar"
            alignSelf="center"
            mb={18}
            h={8}
            onClick={() => {
              navigate(PATH_MY_PROVIDER_PAGES)
            }}
          /> */}
          {/* )} */}

          <SidebarAreaHeader
            mt={4}
            mr={6}
            mb={2}
            ml={marginLeftHeading}
            // isMinimized={!isExpanded}
            toolTipText={t('portal.sidebar.addNewProject')}
            onClick={onOpenAddProjectModal}
          >
            {/* {isExpanded && <Text>{t('general.myChosenProjects')}</Text>} */}
            <Text>{t('general.myActiveProjects')}</Text>
          </SidebarAreaHeader>
          {/* {!currentOrganization && isExpanded && ( */}
          {!currentOrganization && (
            <Text textStyle="textSmall" color="red.500" px={8}>
              Aktivera företag för att få tillgång till projekt
            </Text>
          )}
          {projects &&
            projects.map(p => {
              return (
                <SidebarItem
                  key={p.id}
                  variant="project"
                  pl={2}
                  py={1}
                  pr={1}
                  // isMinimized={!isExpanded}
                  toLink={`${PATH_MY_PROVIDER_PAGES}projekt/${p.id}`}
                  isDisabled={currentOrganization ? false : true}
                >
                  {p.name}
                </SidebarItem>
              )
            })}
          {!projects?.length && (
            <Box pl={10} textStyle="inputPlaceholder">
              {/* {isExpanded && ( */}
              <Text>{t('portal.sidebar.provider.noProjectsFound')}</Text>
              {/* )} */}
            </Box>
          )}
          {/* Menu section */}
          <SidebarAreaHeader
            mt={4}
            mr={6}
            mb={2}
            ml={marginLeftHeading}
            noAddIcon={true}
            // isMinimized={!isExpanded}
          >
            {/* {isExpanded && <Text>{t('portal.sidebar.menu.menu')}</Text>} */}
            <Text>{t('portal.sidebar.menu.menu')}</Text>
          </SidebarAreaHeader>
          {navLinks.map((item, i) => {
            return (
              <SidebarItem
                key={`menu${i}`}
                variant="menu"
                icon={<Icon name={item.icon as IconName} />}
                pl={2}
                py={1}
                pr={1}
                // isMinimized={!isExpanded}
                toLink={item.to}
                isDisabled={item.isDisabled}
              >
                {t(item.value)}
              </SidebarItem>
            )
          })}
          <Box height="2%"></Box>
          {!currentOrganization && (
            <SidebarItem
              variant="menu"
              icon={<Icon name={menuItemListNewCompany.icon as IconName} />}
              pl={2}
              py={1}
              pr={1}
              // isMinimized={!isExpanded}
              toLink={menuItemListNewCompany.to}
              isDisabled={menuItemListNewCompany.isDisabled}
            >
              {t(menuItemListNewCompany.value)}
            </SidebarItem>
          )}
          <Box height={4}></Box>
          {/* <HorizontalSliderButton
            onToggle={toggleExpandHandler}
            isExpanded={isExpanded}
          /> */}
        </Flex>
        <Modal
          isOpen={isOpenAddProjectModal}
          onOpen={onOpenAddProjectModal}
          onClose={onCloseAddProjectModal}
          size="xl"
        >
          <AddProjectPlace
            // properties={undefined}
            onCloseModal={onCloseAddProjectModal}
          />
        </Modal>
      </>
    )
  }

export default SidebarProviderPortal
