import { Flex, MenuItem, ResponsiveValue, Text } from '@chakra-ui/react'
import { EventNotification } from '@contracts/support'
import {
  hasReadEventNotifications,
  useEventNotifications,
} from '../../apiClients/eventsApiClient'
import EventNotificationCard from './EventNotificationCard'

const EventNotifications: React.FC<{
  activeTab?: number
  bg?: string
  px?: ResponsiveValue<number | string>
  onCloseMenu?: () => void
}> = ({ activeTab = 0, bg, px = 1, onCloseMenu }) => {
  const { data: eventNotifications, mutate } = useEventNotifications()

  const unreadNotifications = eventNotifications?.filter(event => {
    return event.status === 'New'
  })

  const setHasReadEventNotificationsHandler = async () => {
    if (unreadNotifications?.length) {
      const ids = unreadNotifications.map(event => {
        return event.id
      })
      // await hasReadEventNotifications(ids).then(() => {
      //   void mutate()
      // })
      await hasReadEventNotifications(ids)
      void mutate()
    }
  }

  const groupBy = (
    notifications: EventNotification[],
  ): EventNotification[][] => {
    const result: EventNotification[][] = []
    notifications.forEach(event => {
      if (event.event.type !== 'Mention' && event.event.type !== 'Message') {
        // Only group if 'Mention' or 'Message'
        result.push([event])
        return
      }
      const tmp = notifications.filter(notification => {
        return (
          notification.event.type === event.event.type &&
          notification.status === event.status &&
          notification.event.parentArea === event.event.parentArea &&
          notification.event.parentAreaId === event.event.parentAreaId
        )
      })
      // Remove notifications aleady included in groups above
      const tmp2 = notifications.filter(notification => {
        if (notification.event.type !== event.event.type) {
          return true
        }
        return (
          notification.status === event.status &&
          notification.event.parentAreaId !== event.event.parentAreaId
        )
      })
      notifications = tmp2

      if (tmp.length) {
        result.push(tmp)
      }
    })
    return result
  }

  const groupedUnreadNotifications = groupBy(unreadNotifications ?? [])
  const groupedLatestEvents = groupBy(eventNotifications ?? [])

  return (
    <Flex
      bg={bg}
      w="100%"
      flexDir="column"
      maxH="70vh"
      overflow="scroll"
      px={px}
    >
      {groupedUnreadNotifications.length > 1 && (
        <Flex
          color="primary"
          flexDir="column"
          textAlign="right"
          textStyle="textSmall"
          pr={2}
          py={2}
          _hover={{
            color: 'secondary',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          onClick={() => {
            void setHasReadEventNotificationsHandler()
          }}
        >
          <Text>Markera alla som lästa</Text>
        </Flex>
      )}
      {groupedUnreadNotifications.length < 2 && <Flex h={2}></Flex>}
      {activeTab === 0 &&
        groupedUnreadNotifications &&
        groupedUnreadNotifications.length > 0 &&
        groupedUnreadNotifications.map(eventNotifications => {
          return (
            <MenuItem key={eventNotifications[0].id} p={0}>
              <EventNotificationCard
                key={eventNotifications[0].id}
                eventNotifications={eventNotifications}
                px={2}
                onCloseMenu={onCloseMenu}
              />
            </MenuItem>
          )
        })}
      {activeTab === 0 &&
        (!groupedUnreadNotifications ||
          (!groupedUnreadNotifications.length && (
            <Text
              textAlign="center"
              fontStyle="italic"
              textStyle="textMediumLight"
              py={4}
            >
              Inga olästa händelser
            </Text>
          )))}
      {activeTab === 1 &&
        groupedLatestEvents &&
        groupedLatestEvents.length > 0 &&
        groupedLatestEvents.map(eventNotifications => {
          return (
            <MenuItem key={eventNotifications[0].id}>
              <EventNotificationCard
                eventNotifications={eventNotifications}
                px={0}
                showDate={true}
                onCloseMenu={onCloseMenu}
              />
            </MenuItem>
          )
        })}
      {activeTab === 1 &&
        (!groupedLatestEvents ||
          (!groupedLatestEvents.length && (
            <Text
              textAlign="center"
              fontStyle="italic"
              textStyle="textMediumLight"
              py={4}
            >
              Inga händelser hittades
            </Text>
          )))}
    </Flex>
  )
}
export default EventNotifications
