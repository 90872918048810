import {
  EventNotification,
  UpdateEventNotificationRequest,
} from '@contracts/support'
import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'
require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL as string

export const useEventNotifications = (): SWRResponse<
  EventNotification[] | undefined
> => {
  const { data, error, isValidating, mutate } = useSWR<
    EventNotification[] | undefined
    // >(`${baseUrl}/event-notifications`)
  >(`${baseUrl}/event-notifications`, { refreshInterval: 60000 })
  return { data, error, isValidating, mutate }
}

export const updateEventNotification = async (
  eventNotificationId: string,
  requestData: UpdateEventNotificationRequest,
): Promise<UpdateEventNotificationRequest> => {
  return axios
    .patch<EventNotification, AxiosResponse<UpdateEventNotificationRequest>>(
      `${baseUrl}/event-notifications/${eventNotificationId}`,
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for updating event notification',
        status,
        data,
      )
      return data
    })
}

export const hasReadEventNotifications = async (
  eventNotificationIds: string[],
): Promise<UpdateEventNotificationRequest> => {
  return axios
    .patch<EventNotification, AxiosResponse<UpdateEventNotificationRequest>>(
      `${baseUrl}/event-notifications/setHasRead?ids=${JSON.stringify(
        eventNotificationIds,
      )}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        // params: {
        //   // id: eventNotificationIds,
        //   // ids: ['1', '2', '4'],
        //   ids: eventNotificationIds,
        // },
        // paramsSerializer: {
        //   indexes: null,
        // },
      },
    )
    .then(({ status, data }) => {
      log.info(
        'Response from server for updating event notification',
        status,
        data,
      )
      return data
    })
}
