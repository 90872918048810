import { Flex } from '@chakra-ui/react'
import { HelpRequestTemplateResponse } from '@contracts/misc'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationCollaborator } from '@contracts/accounts'
import { useCollaborators } from '../../apiClients/projectsApiClient'
import AuthContext from '../../common/auth-context'
import HelpRequestTemplateCard from '../sections/portal/HelpRequestTemplateCard'
import ExpandableSectionCard from '../ui/ExpandableSectionCard'
import TextCard from '../ui/TextCard'
import AddHelpRequestButton from './AddHelpRequestButton'
import HelpRequestRecipiantsCard from './HelpRequestRecipiantsCard'
import HelpRequestTemplateRowCard from './HelpRequestTemplateRowCard'

const ExpandableHelpRequestTemplateCard: React.FC<{
  helpReqTemplate: HelpRequestTemplateResponse
  defaultOrganization?: OrganizationCollaborator
}> = ({ helpReqTemplate, defaultOrganization }) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  const { data: projectCollabs } = useCollaborators(helpReqTemplate.projectId)
  const isReadOnly =
    projectCollabs?.find(collab => {
      return collab.accountId === currentUser?.id
    })?.accountRelation === 'Viewer'

  return (
    <ExpandableSectionCard
      key={helpReqTemplate.id}
      variant="arrow-left"
      headerChildren={
        <HelpRequestTemplateRowCard helpReqTemplate={helpReqTemplate} />
      }
      w="100%"
    >
      <Flex
        flexDir="column"
        gap={4}
        mb={4}
        textStyle="infoText"
        key={helpReqTemplate.id}
      >
        <Flex flexDir="column" px={2}>
          <TextCard heading={t('helpRequests.template.hasBeenSentTo')} mt={4}>
            <Flex>
              <HelpRequestRecipiantsCard
                helpRequestTemplateId={helpReqTemplate.id}
              />
            </Flex>
          </TextCard>
        </Flex>

        <HelpRequestTemplateCard helpRequestTemplateId={helpReqTemplate.id} />

        <Flex
          flexDir="column"
          gap={2}
          alignItems="end"
          textAlign={{ xxs: 'center', tablet: 'right' }}
        >
          <AddHelpRequestButton
            helpReqTemplate={helpReqTemplate}
            variant="button"
            size={{ xxs: 'sm', laptop: 'sm' }}
            disabled={isReadOnly ? true : false}
            tooltip={
              isReadOnly
                ? t('helpRequests.template.notAllowedToEditTooltip')
                : ''
            }
            defaultOrganization={defaultOrganization}
          >
            {t('helpRequests.sendRequest')}
          </AddHelpRequestButton>
        </Flex>
      </Flex>
    </ExpandableSectionCard>
  )
}
export default ExpandableHelpRequestTemplateCard
