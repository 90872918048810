import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../../components/ui/Icon'

const SidebarItem: React.FC<{
  children: ReactNode
  variant: 'property' | 'project' | 'menu'
  icon?: ReactElement
  onClick?: () => void
  py?: number
  pl?: number
  pr?: number
  mt?: number
  isMinimized?: boolean
  toLink: string
  onClose?: () => void
  isDisabled?: boolean
  hideLogo?: boolean
  isLightFont?: boolean
}> = ({
  children,
  variant,
  icon,
  onClick,
  isMinimized,
  toLink,
  py,
  pl = 0,
  pr,
  mt,
  onClose,
  isDisabled = false,
  hideLogo = false,
  isLightFont = false,
}) => {
  const location = useLocation()
  const isActive = toLink === location.pathname

  const content = (
    <Flex
      flexDir="row"
      _hover={{
        background: 'primary_orange01',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      onClick={() => {
        if (!isDisabled) {
          if (onClick) {
            onClick()
          }
          if (onClose) {
            onClose()
          }
        }
      }}
      pr={pr}
      mt={mt}
    >
      <Box
        w=".6rem"
        background={isActive ? 'sidebarItemMarker' : ''}
        borderRightRadius=".4rem"
      ></Box>

      {hideLogo && <Box w={6} />}
      {!hideLogo && (
        <Box py={py} pl={pl} fontSize={24}>
          {variant === 'property' && <Icon name="myProperty" />}
          {variant === 'project' && <Icon name="myProject" />}
          {variant === 'menu' && icon}
        </Box>
      )}

      <Text
        ml={hideLogo ? pl : 2}
        textStyle="textRegular"
        fontWeight={isLightFont ? 'light' : ''}
        color={isDisabled ? 'gray.500' : 'black'}
        alignSelf="center"
      >
        {!isMinimized && children}
      </Text>
    </Flex>
  )

  if (isDisabled) {
    return content
  }

  return <Link to={toLink}>{content}</Link>
}
export default SidebarItem
