import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../../common/auth-context'
import WelcomeNewProviderSection from '../../components/sections/portal/WelcomeNewProviderSection'
import { Heading } from '../../ui'
import { PATH_MY_PROVIDER_PAGES, PATH_PROVIDER_PROFILE } from '../../common/nav'
import { AreaContext } from '../../common/area-context'
import LatestNotesSection from '../../components/properties/LatestNotesSection'

import AddOrEditProviderBaseData from '../../components/sections/portal/AddOrEditOrganizationBaseData'
import Alert from '../../components/ui/Alert'
import { useNote as usePropertyNotesById } from '../../apiClients/propertiesApiClient/notes'
import { useNote as useProjectNotesById } from '../../apiClients/projectsApiClient/notes'
import NoteCardMedium from '../../components/notes/NoteCardMedium'
import Modal from '../../components/ui/Modal'

import MyAccountBasedProjectsSection from '../../components/sections/portal/MyAccountBasedProjectsSection'
import ProjectUpdatesSummarySection from '../../components/projects/ProjectUpdatesSummarySection'
import ProviderFeatureSection from '../../components/sections/ProviderFeatureSection'
import Divider from '../../components/ui/Divider'

const HomeProviderPortal: React.FC<{ targetArea?: 'property' | 'project' }> = ({
  targetArea,
}) => {
  const { currentUser, currentOrganization } = useContext(AuthContext)
  const { id: noteId } = useParams()
  const { t } = useTranslation()

  const { data: propertyNote } = usePropertyNotesById(
    targetArea === 'property' ? noteId : undefined,
  )

  const { data: projectNote } = useProjectNotesById(
    targetArea === 'project' ? noteId : undefined,
  )

  const px = '5%'

  const { isOpen: isOpenNoteCardMedium, onClose: onCloseNoteCardMedium } =
    useDisclosure({ defaultIsOpen: true })

  if (!currentOrganization) {
    return (
      <>
        {noteId && targetArea && !projectNote && !propertyNote && (
          <Alert
            variant="Warning"
            title={t('notes.invalidLinkTitle')}
            message={
              targetArea === 'property'
                ? t('notes.invalidPropertyNoteLink')
                : t('notes.invalidProjectNoteLink')
            }
            forwardUri={PATH_MY_PROVIDER_PAGES}
            borderRadius="lg"
            ml={{ xxs: '5%', tablet: '5%' }}
            mr={{ xxs: '5%', tablet: '5%' }}
            mt={20}
            mb={0}
            py={0}
          />
        )}
        <Flex
          flexDir="column"
          bg="white"
          px={6}
          py={4}
          mx="5%"
          mt={20}
          maxW="container.lg"
          borderRadius="lg"
          boxShadow="lg"
        >
          <>
            <Heading>
              {t('providers.profile.newProviderGreeting', {
                name: currentUser?.firstName,
              })}
            </Heading>

            <Text mt={6}>
              {t('content.provider.newProviderWithoutOrgWelcomeText')}
            </Text>
          </>
        </Flex>
        <AddOrEditProviderBaseData px="5%" />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Inloggad startsida</title>
      </Helmet>
      <WelcomeNewProviderSection
        // name={currentOrganization?.name || ''}
        name={currentUser?.firstName || ''}
        mt={16}
      />
      <Text px={px} textStyle="textLarge" fontWeight="light" mt={2}>
        {t('content.portalHome.greetingsPhrase')}
      </Text>

      {currentOrganization.status === 'New' && (
        <Link to={PATH_PROVIDER_PROFILE}>
          <Flex
            flexDir="column"
            bg="white"
            px={6}
            py={4}
            mx="5%"
            mt={20}
            maxW="container.lg"
            borderRadius="lg"
            boxShadow="lg"
          >
            <>
              <Heading>
                {t('providers.profile.newOrganizationGreeting', {
                  // name: currentOrganization?.name,
                  name: currentUser?.firstName,
                })}
              </Heading>

              <Flex flexDir="column" maxW="container.lg">
                <Text textStyle="regularLight" mt={6}>
                  {t('providers.profile.newProviderDescription')}
                </Text>
                <Flex
                  flexDir="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Text textStyle="regularLight" mt={6}>
                    {t('providers.profile.fillInProfilePromoDecscription')}
                  </Text>
                  <Button mt={4} minW={48} mr={10} variant="secondary">
                    {t('providers.profile.goToCompanyProfilePageButton')}
                  </Button>
                </Flex>
              </Flex>
            </>
          </Flex>
        </Link>
      )}

      <ProjectUpdatesSummarySection
        px={{ xxs: 2, tablet: '2%', desktop: '3%', laptop: '5%' }}
        mt={8}
      />

      <AreaContext.Provider value={{ area: 'project' }}>
        {/* <Heading
          textStyle="h6Fixed"
          color="logo_green01"
          isBold
          px={{ xxs: '3%', tablet: '5%' }}
          mb={2}
          mt={6}
        >
          {t('general.latestProjectNotes')}
        </Heading> */}

        <Flex
          flexDir="column"
          px={{ xxs: '3%', tablet: '5%' }}
          mb={2}
          mt={6}
          // border="1px solid red"
        >
          <Heading textStyle="h8">{t('general.latestProjectNotes')}</Heading>
          <Divider my={2} />
        </Flex>

        <LatestNotesSection px={{ xxs: '3%', tablet: px }} />

        {/* Present uri-linked project, if any */}
        {projectNote && targetArea === 'project' && (
          <Modal
            isOpen={isOpenNoteCardMedium}
            onClose={onCloseNoteCardMedium}
            size="lg"
          >
            <NoteCardMedium
              note={projectNote}
              projectId={projectNote.entityId}
              onClose={onCloseNoteCardMedium}
            />
          </Modal>
        )}

        <MyAccountBasedProjectsSection px={{ xxs: '3%', tablet: '5%' }} />
      </AreaContext.Provider>

      <ProviderFeatureSection bg="primary_green" px={px} />
    </>
  )
}
export default HomeProviderPortal
