import {
  Box,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import _ from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaRegFile,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileWord,
  FaRegMinusSquare,
  FaRegPlusSquare,
} from 'react-icons/fa'
import { useAccountAttachments } from '../../apiClients/attachmentsApiClient'
import { AttachmentSortingOption } from '../../common/enums'
import { getMbFromBytes } from '../../common/util'
import SortableHeading from '../ui/SortableHeading'
import TextCard from '../ui/TextCard'
import AttachmentCard from './AttachmentCard'
import { getSumOfFiles } from './attachmentHelper'
import { calculateSortedItems } from './attachmentListUtils'
import ManageAttachmentPanel from './ManageAttachmentPanel'

const AttachmentsTreeViewCard: React.FC<{
  account: AccountData
  mt: string | number
}> = ({ account, mt }) => {
  const { t } = useTranslation()
  const { data: allAttachments, mutate } = useAccountAttachments(account.id)

  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const sumOfFilesSizes = allAttachments ? getSumOfFiles(allAttachments) : 0

  const [sortType, setSortType] = useState(
    AttachmentSortingOption.CREATED_DATE_DESC,
  )

  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const sortedAttachments = calculateSortedItems(sortType, allAttachments)

  const getFileTypeLogo = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return (
        <Box fontSize={24} pl={4} color="green.400">
          <FaRegFileImage />
        </Box>
      )
    }
    if (mimeType.includes('pdf')) {
      return (
        <Box fontSize={24} pl={4} color="red.600">
          <FaRegFilePdf />
        </Box>
      )
    }
    if (mimeType.includes('sheet' || 'xls')) {
      return (
        <Box fontSize={24} pl={4} color="green.600">
          <FaRegFileExcel />
        </Box>
      )
    }
    if (mimeType.includes('word')) {
      return (
        <Box fontSize={24} pl={4} color="blue.700">
          <FaRegFileWord />
        </Box>
      )
    }
    if (mimeType.includes('powerpoint')) {
      return (
        <Box fontSize={24} pl={4} color="red.700">
          <FaRegFilePowerpoint />
        </Box>
      )
    }

    return (
      <Box fontSize={24} pl={4} color="gray.600">
        <FaRegFile />
      </Box>
    )
  }

  return (
    <>
      <TextCard heading="Filer" mt={mt}>
        <Text>
          {t('attachments.filesInAccount', {
            count: allAttachments?.length,
            size: sumOfFilesSizes,
          })}
        </Text>
      </TextCard>
      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <TableCaption>
          {t('attachments.listingBerikarAttachments')}
        </TableCaption>
        <Thead key="thead">
          <Tr key="theadTr">
            <Th></Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(AttachmentSortingOption.AREA_ASC)}
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.AREA_DESC)
                }
              >
                {t('attachments.areaName')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AttachmentSortingOption.MIME_TYPE_ASC)
                }
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.MIME_TYPE_DESC)
                }
              >
                {t('attachments.fileType')}
              </SortableHeading>
            </Th>

            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AttachmentSortingOption.FILE_NAME_ASC)
                }
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.FILE_NAME_DESC)
                }
              >
                {t('attachments.fileName')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AttachmentSortingOption.FILE_SIZE_ASC)
                }
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.FILE_SIZE_DESC)
                }
              >
                {t('attachments.fileSize')}
              </SortableHeading>
            </Th>

            <Th>
              <SortableHeading>{t('articles.id')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading>{t('accounts.account')}</SortableHeading>
            </Th>

            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AttachmentSortingOption.CREATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.CREATED_DATE_DESC)
                }
              >
                {t('articles.created')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() =>
                  setSortType(AttachmentSortingOption.UPDATED_DATE_ASC)
                }
                onDescSort={() =>
                  setSortType(AttachmentSortingOption.UPDATED_DATE_DESC)
                }
              >
                {t('articles.updated')}
              </SortableHeading>
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {sortedAttachments && sortedAttachments.length > 0 ? (
            sortedAttachments.map((attachment, i) => {
              return (
                <Fragment key={attachment.id}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                  >
                    <Td
                      color="primary_orange02"
                      _hover={{ color: 'primary_orange01' }}
                    >
                      {!accordionsToShow.includes(attachment.id) && (
                        <FaRegPlusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(attachment.id)
                          }}
                        />
                      )}
                      {accordionsToShow.includes(attachment.id) && (
                        <FaRegMinusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(attachment.id)
                          }}
                        />
                      )}
                    </Td>
                    <Td>{attachment.area}</Td>
                    <Td>
                      <Tooltip
                        label={attachment.mimeType}
                        placement="top-start"
                      >
                        {getFileTypeLogo(attachment.mimeType)}
                      </Tooltip>
                    </Td>
                    <Td>
                      <Tooltip
                        label={t('general.clickToEdit')}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                        placement="top-start"
                      >
                        <Box w={60}>{attachment.originalFilename}</Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      {attachment?.fileSizeBytes
                        ? `${getMbFromBytes(attachment.fileSizeBytes)} MB`
                        : ''}{' '}
                    </Td>
                    <Td>
                      <Tooltip
                        label={attachment.id}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                      >
                        {_.truncate(attachment.id, { length: 15 })}
                      </Tooltip>
                    </Td>
                    <Td>
                      <Tooltip
                        label={attachment.id}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                      >
                        {_.truncate(attachment.accountId, { length: 15 })}
                      </Tooltip>
                    </Td>

                    <Td>
                      {new Date(attachment.createdAt).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      {new Date(attachment.updatedAt).toLocaleString('sv-SE')}
                    </Td>
                    <Td>
                      <ManageAttachmentPanel
                        attachment={attachment}
                        onMutate={() => {
                          void mutate
                        }}
                      />
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(attachment.id) && (
                    <Tr
                      key={`${attachment.id}-2`}
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={10}>
                        <AttachmentCard attachment={attachment} />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr key="none">
              <Td colSpan={9} textAlign="center">
                {t('articles.noArticlesFound')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  )
}
export default AttachmentsTreeViewCard
