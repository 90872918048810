import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { CreateNoteRequest, Note, NoteType } from '@contracts/misc'
import { useContext } from 'react'
import log from '../../common/log'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROPERTIES_SERVICE_URL

export const useNoteTypes = (): SWRResponse<NoteType[] | undefined> => {
  return useSWRImmutable<NoteType[] | undefined>(`${baseUrl}/note-types/`, {
    revalidateOnFocus: false,
  })
}

export const useNotes = (
  propertyId: string | undefined,
  noteTypeIds?: string[],
): SWRResponse<Note[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)

  let queryStr = ''
  let noteTypeIdsStr = ''
  if (!propertyId) {
    queryStr = ''
  } else {
    if (noteTypeIds?.length) {
      noteTypeIdsStr = encodeURIComponent(JSON.stringify(noteTypeIds))

      if (isAdmin) {
        queryStr = `${baseUrl}/admin/properties/${propertyId}/notes?noteTypeIds=${noteTypeIdsStr}`
      } else {
        queryStr = `${baseUrl}/properties/${propertyId}/notes?noteTypeIds=${noteTypeIdsStr}`
      }
    } else {
      if (isAdmin) {
        queryStr = `${baseUrl}/admin/properties/${propertyId}/notes`
      } else {
        queryStr = `${baseUrl}/properties/${propertyId}/notes`
      }
    }
  }
  return useSWR<Note[] | undefined>(propertyId ? queryStr : null)
}

export const useNote = (
  noteId: string | undefined,
): SWRResponse<Note | undefined> => {
  return useSWR<Note | undefined>(
    noteId ? `${baseUrl}/properties/notes/${noteId}` : null,
    {
      revalidateOnFocus: false,
    },
  )
}

export const useAllNotes = (
  maxAgeMonths: number,
): SWRResponse<Note[] | undefined> => {
  return useSWR<Note[] | undefined>(
    `${baseUrl}/properties/notes?maxAgeMonths=${maxAgeMonths}`,
  )
}

export const addNote = async (
  propertyId: string,
  requestData: CreateNoteRequest,
) => {
  return axios.post(
    `${baseUrl}/properties/${propertyId}/notes`,
    {
      ...requestData,
      noteTimestamp: requestData.noteTimestamp ?? Date.now(),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateNote = async (
  noteId: string,
  requestData: CreateNoteRequest,
) => {
  return axios
    .put(`${baseUrl}/properties/notes/${noteId}`, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated property note', status, data)
      return data
    })
}

export const deleteNote = async (noteId: string): Promise<string> => {
  return axios
    .delete(`${baseUrl}/properties/notes/${noteId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => 'Successfully deleted property note')
}
