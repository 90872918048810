import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'

import ContentPageHeader from '../../components/content/ContentPageHeader'
import headerBackground from '../../img/pages/provider/headerBookings.png'
import FeedbackPopover from '../../components/feedback/FeedbackPopover'

const ProviderBookingsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Flex flexDir="column" width="100%" bg="primary_grey01">
        <Helmet>
          <title>{t('portal.sidebar.provider.bookings')}</title>
        </Helmet>
        <ContentPageHeader
          label={`${t('portal.sidebar.provider.bookings').toUpperCase()}`}
          h={32}
          pt={4}
          px={{ xxs: '5%', tablet: '16%' }}
          textStyle={{ xxs: 'h1', tablet: 'h2' }}
          bgSize="contain"
          backgroundImgSrc={headerBackground}
        />
      </Flex>

      <Flex
        flexDir="column"
        backgroundColor="white"
        py={6}
        mx={{ xxs: '5%', tablet: '15%' }}
        bg="primaryBackground"
        maxW="container.md"
      >
        <Text textStyle="textLarge" my={4} color="logo_pink">
          Detta kommer snart - hur snart? Du avgör genom att tycka till längst
          ner på sidan!
        </Text>

        <Text textStyle="textLarge" my={4}>
          Vill du vara flexibel i dina arbetstider och själv styra när du är
          tillgänglig?
        </Text>

        <Text textStyle="infoTextLight" my={4}>
          Här kommer vi lansera en bokningstjänst där du själv bestämmer när du
          är tillgänglig, antingen för videomöten eller fysiska möten. Du styr
          vilka tider du är bokningsbar, vilken typ av uppdrag du åtar dig och
          hur du vill genomföra möten och rådgivningar.
        </Text>
        <Text textStyle="infoTextLight" my={4}>
          {t('content.provider.engageInCreatingTogether')}
        </Text>
        <FeedbackPopover context={'provider.bookings'} />
      </Flex>
    </>
  )
}
export default ProviderBookingsPage
