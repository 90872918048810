import { Flex, ResponsiveValue, Text, useDisclosure } from '@chakra-ui/react'
import { Attachment, ContextArea } from '@contracts/support'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { useAttachmentsByAreaQuery } from '../../apiClients/attachmentsApiClient'
import {
  useNotes as useProjectNotes,
  useNoteTypes as useProjectNoteTypes,
} from '../../apiClients/projectsApiClient/notes'
import {
  useNotes as usePropertyNotes,
  useNoteTypes as usePropertyNoteTypes,
} from '../../apiClients/propertiesApiClient/notes'

import log from '../../common/log'
import {
  PATH_MY_PRIVATE_PROPERTIES,
  PATH_MY_PROJECTS,
  PATH_NOTE_QUERY,
} from '../../common/nav'
import AttachmentsCardCompact from '../attachments/AttachmentsCardCompact'
import Heading from '../ui/Heading'
import Modal from '../ui/Modal'
import { groupBy } from '../attachments/attachmentHelper'

// import Skeleton from '../ui/Skeleton'

const DocumentsWidget: React.FC<{
  projectId?: string
  propertyId?: string
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  border?: string
  variant?:
    | 'important-documents-project'
    | 'important-documents-property'
    | 'receipts-property'
  minW?: ResponsiveValue<number | string>
  maxW?: ResponsiveValue<number | string>
  minH?: ResponsiveValue<number | string>
  maxH?: ResponsiveValue<number | string>
  onOpenNoteCard?: () => void
}> = ({
  projectId,
  propertyId,
  px = 2,
  py = 2,
  border,
  variant = 'important-documents',
  minW,
  maxW,
  minH,
  maxH = '210px',
  onOpenNoteCard,
}) => {
  let widgetTitle = ''
  let widgetEmptyText = ''
  let noteTypeNames: string[]
  const { t } = useTranslation()
  const {
    isOpen: isInfoModalOpen,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()

  //
  // New part
  //
  let areaQueries: {
    area?: ContextArea
    parentArea?: ContextArea
    grandParentArea?: ContextArea
    areaId?: string
    parentAreaId?: string | undefined
    grandParentAreaId?: string | undefined
  }[] = []

  if (variant === 'important-documents-project') {
    // Get all attachments from notes with the current parent
    areaQueries = [
      { parentArea: 'Project', parentAreaId: projectId },
      // {
      //   grandParentArea: 'Project',
      //   grandParentAreaId: projectId,
      // },
    ]
  } else if (variant === 'important-documents-property') {
    // Get all attachments from notes with the current parent
    areaQueries = [{ parentArea: 'Property', parentAreaId: propertyId }]
  }

  const { data: attachments } = useAttachmentsByAreaQuery(areaQueries)
  //

  // Plan
  // DONE - 1) Get all attachments of propertyNotes or projectNotes of current property or project
  // DONE - 2) Get id's of all notes with correct note type use filter or getNotesByProjectId / getNotesByPropertyId
  // 3) Filter list of attachments with note ids

  if (variant === 'important-documents-project') {
    widgetTitle = t('widgets.documentsWidget.project.title')
    noteTypeNames = ['Gällande handlingar']
    widgetEmptyText = t('widgets.documentsWidget.project.noDocumentsInfoText')
  }
  if (variant === 'important-documents-property') {
    widgetTitle = t('widgets.documentsWidget.property.title')
    noteTypeNames = ['Detaljplan & Bygglov', 'Ritningar']
    widgetEmptyText = t('widgets.documentsWidget.property.noDocumentsInfoText')
  }
  if (variant === 'receipts-property') {
    widgetTitle = 'Kvitton & Inköp'
    noteTypeNames = ['Kvitton & Inköp']
  }

  // Project
  const { data: allProjectNoteTypes } = useProjectNoteTypes()
  const projectNoteTypes = allProjectNoteTypes?.filter(nt => {
    return noteTypeNames.includes(nt.name)
  })
  const projectNoteTypeIds = projectNoteTypes?.map(nt => {
    return nt.id
  })

  const { data: projectNotes } = useProjectNotes(projectId, projectNoteTypeIds)

  // Property
  const { data: allPropertyNoteTypes } = usePropertyNoteTypes()
  const propertyNoteTypes = allPropertyNoteTypes?.filter(nt => {
    return noteTypeNames.includes(nt.name)
  })
  const propertyNoteTypeIds = propertyNoteTypes?.map(nt => {
    return nt.id
  })
  const { data: propertyNotes } = usePropertyNotes(
    propertyId,
    propertyNoteTypeIds,
  )

  let files: Attachment[] | undefined

  if (projectId) {
    const relevantProjectNoteIds = projectNotes?.map(note => {
      return note.id
    })

    files = attachments?.filter(attachment => {
      return (
        !attachment.mimeType.includes('image') &&
        attachment.area === 'ProjectNote' &&
        attachment.areaId &&
        relevantProjectNoteIds?.includes(attachment.areaId)
      )
    })
  } else if (propertyId) {
    const relevantPropertyNoteIds = propertyNotes?.map(note => {
      return note.id
    })

    files = attachments?.filter(attachment => {
      return (
        !attachment.mimeType.includes('image') &&
        attachment.area === 'PropertyNote' &&
        attachment.areaId &&
        relevantPropertyNoteIds?.includes(attachment.areaId)
      )
    })
  } else {
    log.error('Must provide either project id or property id')
    return null
  }

  const { files: groupedFiles } = groupBy(files ?? [])

  return (
    <>
      <Flex
        flexDir="column"
        gap={1}
        py={py}
        px={px}
        justifyContent="left"
        alignItems="flex-start"
        border={border}
        bg="white"
        // bg="red"
        borderRadius="lg"
        boxShadow="lg"
        w="100%"
        minW={minW}
        maxW={maxW}
        maxH={maxH}
        minH={minH}
        overflow="scroll"
      >
        <Flex
          gap={2}
          alignItems="center"
          onClick={() => {
            onOpenInfoModal()
          }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {widgetTitle}
          </Heading>
          <InfoOutlineIcon
            fontSize={14}
            color="logo_orange"
            _hover={{ cursor: 'pointer', color: 'orange' }}
          />
        </Flex>
        {groupedFiles && groupedFiles.length > 0 && (
          <Flex
            flexDir="column"
            w="100%"
            // maxH={maxH}
            overflow="scroll"
          >
            {groupedFiles &&
              groupedFiles.length > 0 &&
              groupedFiles.map(files => {
                return (
                  <AttachmentsCardCompact
                    key={files[files.length - 1].id}
                    attachmentId={files[0].id}
                    variant="list-item"
                    link={
                      propertyId
                        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          `${PATH_MY_PRIVATE_PROPERTIES}/${propertyId}${PATH_NOTE_QUERY}${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            files[0].areaId
                          }`
                        : projectId
                        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          `${PATH_MY_PROJECTS}/${projectId}${PATH_NOTE_QUERY}${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            files[0].areaId
                          }`
                        : ''
                    }
                    onOpenNoteCard={onOpenNoteCard}
                  />
                )
              })}
          </Flex>
        )}
        {!files ||
          (!files.length && (
            <Text textStyle="infoTextLight" fontStyle="italic">
              {widgetEmptyText}
            </Text>
          ))}
      </Flex>
      <Modal isOpen={isInfoModalOpen} onClose={onCloseInfoModal} fixedSize>
        <Flex
          flexDir="column"
          my={{ xxs: 4, tablet: 6 }}
          gap={2}
          px={{ xxs: 2, tablet: 0 }}
        >
          <Heading textStyle="h9" isBold color="logo_green01">
            {widgetTitle}
          </Heading>

          <Text textStyle="infoTextLight" fontStyle="italic">
            {widgetEmptyText}
          </Text>
        </Flex>
      </Modal>
    </>
  )
}
export default DocumentsWidget
