/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { BubbleMenu } from '@tiptap/react'
import { Editor } from '@tiptap/core'
import { Box, Button, HStack, Input, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { deleteAttachment } from '../../apiClients/attachmentsApiClient'
import log from '../../common/log'

const ImageBubbleMenu: React.FC<{ editor: Editor }> = ({ editor }) => {
  const [width, setWidth] = useState('0')
  // const [link, setLink] = useState<string | undefined>(undefined)
  const { t } = useTranslation()
  return (
    <>
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{ duration: 100 }}
          shouldShow={({ editor }) => {
            // only show the bubble menu for images
            return editor.isActive('media')
          }}
          className="bubble-menu"
        >
          <Box
            bg="white"
            p={3}
            border="1px"
            borderRadius="lg"
            borderColor="gray.400"
          >
            <Text fontWeight="bold">{t('richtext.imageSettings')}</Text>
            <Text pb={2}>{t('richtext.imageSettingsDescr')}</Text>
            <HStack spacing={2}>
              <Button
                h={6}
                border="1px"
                borderColor="gray.300"
                onClick={() => {
                  editor.chain().focus().setImage({ width: '200px' }).run()
                }}
                title="small"
              >
                {t('richtext.small')}
              </Button>
              <Button
                h={6}
                border="1px"
                borderColor="gray.300"
                onClick={() => {
                  editor.chain().focus().setImage({ width: '400px' }).run()
                }}
                title="medium"
              >
                {t('richtext.medium')}
              </Button>
              <Button
                h={6}
                border="1px"
                borderColor="gray.300"
                onClick={() => {
                  editor.chain().focus().setImage({ width: '600px' }).run()
                }}
                title="large"
              >
                {t('richtext.large')}
              </Button>
              <Button
                h={6}
                border="1px"
                borderColor="gray.300"
                onClick={() => {
                  editor.chain().focus().setImage({ width: '800px' }).run()
                }}
                title="large"
              >
                {t('richtext.extralarge')}
              </Button>
              <Input
                h={8}
                placeholder={t('richtext.widthPlaceholder')}
                onChange={event => setWidth(event.target.value)}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    editor
                      .chain()
                      .focus()
                      .setImage({ width: `${width}px` })
                      .run()
                  }
                }}
              />
            </HStack>
            <HStack spacing={2} py={2}>
              <Button
                h={6}
                border="1px"
                borderColor="gray.300"
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .setImage({ float: 'left', centered: 'false' })
                    .run()
                }}
                title="left"
              >
                {t('richtext.left')}
              </Button>

              <Button
                h={6}
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .setImage({ float: 'null', centered: 'true' })
                    .run()
                }}
                title="centered"
              >
                centered
              </Button>

              <Button
                h={6}
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .setImage({ float: 'right', centered: 'false' })
                    .run()
                }}
                title="right"
              >
                {t('richtext.right')}
              </Button>

              <Button
                h={6}
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .setImage({ float: 'none', centered: 'false' })
                    .run()
                }}
                title="none"
              >
                {t('richtext.none')}
              </Button>
            </HStack>
            {/* <VStack> */}
            {/* <Input
                h={8}
                placeholder={t('richtext.changeImgPlaceholder')}
                onChange={event =>
                  editor
                    .chain()
                    .focus()
                    .setImage({ src: event.target.value })
                    .run()
                }
              /> */}
            {/* <Input
                h={8}
                placeholder={t('richtext.enterLinkPlaceholder')}
                onChange={event => setLink(event.target.value)}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    editor
                      .chain()
                      .focus()
                      .setImage({ href: link === '' ? undefined : link })
                      .run()
                  }
                }}
              /> */}
            <Button
              w="100%"
              h={6}
              onClick={() => {
                const imageToDelete = editor.getAttributes('media')
                const strArray = imageToDelete.src.split('/')
                const isPrivate = strArray[strArray.length - 2] === 'private'
                const imgId = strArray[strArray.length - 1]

                deleteAttachment(imgId, isPrivate).catch(e => {
                  log.error(
                    { err: e },
                    'Failed to remove attachment from db and blob storage',
                  )
                })

                editor.chain().focus().deleteSelection().run()
              }}
              title="delete"
            >
              {t('richtext.delete')}
            </Button>
          </Box>
        </BubbleMenu>
      )}
    </>
  )
}
export default ImageBubbleMenu
