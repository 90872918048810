import { Tag } from '@contracts/support'
import { filter, orderBy } from 'lodash'
import { TagSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: TagSortingOptionType,
  tags?: Tag[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption
  const filteredTags = searchStr
    ? filter(tags, ({ name, code }) => {
        const searchStrLowercase = searchStr.toLocaleLowerCase()
        return (
          name?.toLocaleLowerCase().includes(searchStrLowercase) ||
          code?.toLocaleLowerCase().includes(searchStrLowercase)
        )
      })
    : tags
  return orderBy(filteredTags, sortingField, sortOrder)
}
