import { CSSProperties } from 'react'
import {
  FcRuler,
  FcComments,
  FcIdea,
  FcGlobe,
  FcRatings,
  FcFeedback,
  FcBusinesswoman,
  FcReading,
  FcAddImage,
  FcHome,
  FcDocument,
  FcImageFile,
  FcVideoFile,
} from 'react-icons/fc'
import { RiFilter2Fill } from 'react-icons/ri'
import { FaHammer } from 'react-icons/fa'
import { GrExpand } from 'react-icons/gr'

import {
  MdSort,
  MdOutlineKeyboardBackspace,
  MdOutlineEdit,
} from 'react-icons/md'
import { GoSearch } from 'react-icons/go'
import { Box, Flex, Image, keyframes } from '@chakra-ui/react'
import { BsQuestion } from 'react-icons/bs'
import { ImArrowLeft } from 'react-icons/im'
import { FiCamera } from 'react-icons/fi'
import { RiImageAddLine } from 'react-icons/ri'
import {
  AiOutlineEdit,
  AiFillStar,
  AiOutlineStar,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineClose,
  AiOutlineWarning,
} from 'react-icons/ai'
import {
  IoAddCircleOutline,
  IoSendSharp,
  IoShareOutline,
} from 'react-icons/io5'

import { BsList, BsGrid } from 'react-icons/bs'
import { CgMenuGridO } from 'react-icons/cg'

import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons'

import myProperty from '../img/icons/myPropertyIcon.svg'
import question from '../img/icons/question.svg'
import myProject from '../img/icons/myProjectIcon.svg'
import findExpert from '../img/icons/findExpertIcon.svg'
import climateSmartConstruction from '../img/icons/climateSmartConstructionIcon.svg'
import compareClimateImpact from '../img/icons/compareClimateImpactIcon.svg'
import guidance from '../img/icons/guidanceIcon.svg'
import inspiration from '../img/icons/inspirationIcon.svg'
import constructionGlossaryIcon from '../img/icons/constructionGlossaryIcon.svg'
import myContacts from '../img/icons/myContactsIcon.svg'
import myFiles from '../img/icons/myFilesIcon.svg'
import help from '../img/icons/helpIcon.svg'
import attachmentWithBackground from '../img/icons/attachmentWithBackground.svg'
import settings from '../img/icons/settingsIcon.svg'
import networking from '../img/icons/networkingIcon.svg'
import project from '../img/icons/projectOverviewIcon.svg'
import calendar from '../img/icons/calendar.svg'
import clock from '../img/icons/clock.svg'
import building from '../img/icons/building.svg'
import yellowStar from '../img/icons/yellowStarIcon.svg'
import computerScreen from '../img/icons/computerScreenIcon.svg'
import WavingHand from '../img/icons/wavingHandIcon.svg'
import UnderConstruction from '../img/icons/underConstruction.png'
import addImgIcon_colored from '../img/icons/addImgIcon_colored.svg'
import berikarLovesHomes from '../img/logos/berikarLovesHomesLogo.svg'
import berikarLogo from '../img/logos/berikarLogo.svg'
import book from '../img/icons/book.svg'
import videoMeeting from '../img/icons/videoMeeting.svg'
import camera from '../img/icons/camera.svg'
import email from '../img/icons/emailIcon.svg'
import activeProject from '../img/icons/activeProject.svg'
import insta from '../img/icons/insta.png'
import file from '../img/icons/file.svg'
import files from '../img/icons/files.svg'
import carouselInsta from '../img/icons/carousel.svg'
import videoInsta from '../img/icons/video.svg'
import mention from '../img/icons/icon_mention.svg'
import systemMessage from '../img/icons/icon_system_message.svg'
import fileIconPropertyNote from '../img/icons/fileIconPropertyNote.svg'
import fileIconProjectNote from '../img/icons/fileIconProjectNote.svg'

interface GetIconFromNameProps {
  iconName: IconName
  h?: number
  _hover?: CSSProperties
  color?: string
  onClick?: () => void
  isDisabled?: boolean
  onPulseAnimate?: boolean
}
export const getIconFromName = ({
  iconName,
  h,
  _hover,
  color,
  onClick,
  isDisabled,
  onPulseAnimate,
}: GetIconFromNameProps) => {
  let cursor = ''
  if (onClick) {
    cursor = 'pointer'
  }
  if (isDisabled) {
    cursor = 'not-allowed'
  }
  const growAndShrink = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }`

  const growAndShrinkAnimation = `${growAndShrink} infinite 1s`

  return (
    <Box
      _hover={_hover}
      fontSize={h}
      color={color}
      cursor={cursor}
      onClick={e => {
        if (!isDisabled && onClick) {
          e.stopPropagation()
          onClick()
        }
      }}
      fontWeight="bold"
      animation={onPulseAnimate ? growAndShrinkAnimation : ''}
      alignItems="center"
      alignContent="center"
      alignSelf="center"
    >
      {getIcon(iconName, h)}
    </Box>
  )
}

const getIcon = (name: string, h?: number) => {
  switch (name) {
    case 'myProperty':
      return <Image src={myProperty} boxSize={h} />
    case 'myProject':
      return <Image src={myProject} boxSize={h} />
    case 'findExpert':
      return <Image src={findExpert} boxSize={h} />
    case 'climateSmartConstruction':
      return <Image src={climateSmartConstruction} boxSize={h} />
    case 'compareClimateImpact':
      return <Image src={compareClimateImpact} boxSize={h} />
    case 'guidance':
      return <Image src={guidance} boxSize={h} />
    case 'inspiration':
      return <Image src={inspiration} boxSize={h} />
    case 'constructionGlossary':
      return <Image src={constructionGlossaryIcon} boxSize={h} />
    case 'myContacts':
      return <Image src={myContacts} boxSize={h} />
    case 'myFiles':
      return <Image src={myFiles} boxSize={h} />
    case 'help':
      return <Image src={help} boxSize={h} />
    case 'settings':
      return <Image src={settings} boxSize={h} />
    case 'activeProject':
      return <Image src={activeProject} boxSize={h} />
    case 'wavingHand':
      return <Image src={WavingHand} boxSize={h} />
    case 'carouselInsta':
      return <Image src={carouselInsta} boxSize={h} />
    case 'videoInsta':
      return <Image src={videoInsta} boxSize={h} />
    case 'underConstruction':
      return <Image src={UnderConstruction} boxSize={h} />
    case 'addImgIcon_colored':
      return <Image src={addImgIcon_colored} boxSize={h} />
    case 'attachmentWithBackground':
      return <Image src={attachmentWithBackground} boxSize={h} />
    case 'FcRuler':
      return <FcRuler />
    case 'FcComments':
      return <FcComments />
    case 'FcIdea':
      return <FcIdea />

    case 'FcGlobe':
      return <FcGlobe />
    case 'FcRatings':
      return <FcRatings />
    case 'FcFeedback':
      return <FcFeedback />
    case 'FcBusinesswoman':
      return <FcBusinesswoman />
    case 'FcReading':
      return <FcReading />
    case 'AiOutlineEdit':
      return <AiOutlineEdit fontSize={h} />
    case 'IoAddCircleOutline':
      return <IoAddCircleOutline />
    case 'AiOutlinePlusCircle':
      return <AiOutlinePlusCircle size={h} />
    case 'AiOutlineMinusCircle':
      return <AiOutlineMinusCircle size={h} />
    case 'AiFillStar':
      return <AiFillStar />
    case 'AiOutlineStar':
      return <AiOutlineStar />
    case 'GoSearch':
      return <GoSearch />
    case 'FcHome':
      return <FcHome />
    case 'RiFilter2Fill':
      return <RiFilter2Fill />
    case 'MdSort':
      return <MdSort />
    case 'MdOutlineKeyboardBackspace':
      return <MdOutlineKeyboardBackspace />
    case 'ImArrowLeft':
      return <ImArrowLeft />
    case 'FiCamera':
      return <FiCamera />
    case 'AiOutlineClose':
      return <AiOutlineClose />
    case 'DeleteIcon':
      return <DeleteIcon boxSize={h} />
    case 'FcAddImage':
      return <FcAddImage />
    case 'MdOutlineEdit':
      return <MdOutlineEdit />
    case 'BsList':
      return <BsList />
    case 'IoShareOutline':
      return <IoShareOutline />
    case 'BsGrid':
      return <BsGrid />
    case 'MinitureGrid':
      return <CgMenuGridO />
    case 'GrExpand':
      return <GrExpand />
    case 'RiImageAddLine':
      return <RiImageAddLine />
    case 'FcDocument':
      return <FcDocument />
    case 'FcImageFile':
      return <FcImageFile />
    case 'WarningTriangle':
      return <AiOutlineWarning />
    case 'FcVideoFile':
      return <FcVideoFile />
    case 'IoSendSharp':
      return <IoSendSharp />
    case 'hammer':
      return <FaHammer size={20} color="grey" />
    case 'networking':
      return <Image src={networking} boxSize={h} />
    case 'computerScreen':
      return <Image src={computerScreen} boxSize={h} />
    case 'project':
      return <Image src={project} boxSize={h} />
    case 'berikarLogo':
      return <Image src={berikarLogo} boxSize={h} />
    case 'berikarLovesHomes':
      return <Image src={berikarLovesHomes} boxSize={h} />
    case 'yellowStar':
      return <Image src={yellowStar} boxSize={h} />
    case 'question':
      return <Image src={question} boxSize={h} />
    case 'calendar':
      return <Image src={calendar} boxSize={h} />
    case 'insta':
      return <Image src={insta} boxSize={h} />
    case 'clock':
      return <Image src={clock} boxSize={h} />
    case 'book':
      return <Image src={book} boxSize={h} />
    case 'mention':
      return <Image src={mention} boxSize={h} />
    case 'systemMessage':
      return <Image src={systemMessage} boxSize={h} />
    case 'camera':
      return <Image src={camera} boxSize={h} />
    case 'email':
      return <Image src={email} boxSize={h} />
    case 'file':
      return <Image src={file} boxSize={h} />
    case 'files':
      return <Image src={files} boxSize={h} />
    case 'FileIconPropertyNote':
      return <Image src={fileIconPropertyNote} boxSize={h} />
    case 'FileIconProjectNote':
      return <Image src={fileIconProjectNote} boxSize={h} />
    case 'building':
      return <Image src={building} boxSize={h} />
    case 'videoMeeting':
      return <Image src={videoMeeting} boxSize={h} />
    case 'sorter':
      return (
        <Flex flexDir="column">
          <ChevronUpIcon fontSize={16} />
          <ChevronDownIcon fontSize={16} />
        </Flex>
      )

    default:
      return <BsQuestion />
  }
}

export type IconName =
  | 'FcHome'
  | 'FcRuler'
  | 'FcComments'
  | 'FcIdea'
  | 'FcGlobe'
  | 'FcRatings'
  | 'FcFeedback'
  | 'FcBusinesswoman'
  | 'FcReading'
  | 'myProperty'
  | 'myProject'
  | 'settings'
  | 'help'
  | 'myFiles'
  | 'myContacts'
  | 'inspiration'
  | 'guidance'
  | 'compareClimateImpact'
  | 'climateSmartConstruction'
  | 'findExpert'
  | 'constructionGlossaryIcon'
  | 'wavingHand'
  | 'underConstruction'
  | 'AiOutlineEdit'
  | 'IoAddCircleOutline'
  | 'GoSearch'
  | 'RiFilter2Fill'
  | 'MdSort'
  | 'AiFillStar'
  | 'AiOutlineStar'
  | 'AiOutlinePlusCircle'
  | 'AiOutlineMinusCircle'
  | 'MdOutlineKeyboardBackspace'
  | 'ImArrowLeft'
  | 'FiCamera'
  | 'AiOutlineClose'
  | 'DeleteIcon'
  | 'FcAddImage'
  | 'MdOutlineEdit'
  | 'addImgIcon_colored'
  | 'BsList'
  | 'BsGrid'
  | 'GrExpand'
  | 'RiImageAddLine'
  | 'FcDocument'
  | 'FcImageFile'
  | 'FcVideoFile'
  | 'attachmentWithBackground'
  | 'networking'
  | 'computerScreen'
  | 'project'
  | 'berikarLogo'
  | 'berikarLovesHomes'
  | 'yellowStar'
  | 'question'
  | 'calendar'
  | 'clock'
  | 'book'
  | 'building'
  | 'sorter'
  | 'MinitureGrid'
  | 'WarningTriangle'
  | 'videoMeeting'
  | 'camera'
  | 'email'
  | 'IoSendSharp'
  | 'IoShareOutline'
  | 'FileIconPropertyNote'
  | 'FileIconProjectNote'
  | 'insta'
  | 'activeProject'
  | 'systemMessage'
  | 'mention'
  | 'carouselInsta'
  | 'videoInsta'
  | 'file'
  | 'files'
