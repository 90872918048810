import { Attachment } from '@contracts/support'
import { filter, orderBy } from 'lodash'
import { AttachmentSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: AttachmentSortingOptionType,
  attachments?: Attachment[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption
  const filteredAttachments = searchStr
    ? filter(attachments, ({ area, originalFilename }) => {
        const searchStrLowercase = searchStr.toLocaleLowerCase()
        return (
          area.toLocaleLowerCase().includes(searchStrLowercase) ||
          originalFilename.toLocaleLowerCase().includes(searchStrLowercase)
        )
      })
    : attachments
  return orderBy(filteredAttachments, sortingField, sortOrder)
}
