import React, { ReactNode } from 'react'
import { ResponsiveValue, Text } from '@chakra-ui/react'

const Heading: React.FC<{
  children: ReactNode
  textStyle?: string | ResponsiveValue<string>
  color?: string
  px?: ResponsiveValue<number | string>
  py?: ResponsiveValue<number | string>
  pr?: ResponsiveValue<number | string>
  mt?: number | string
  mr?: number | string
  mb?: number | string
  ml?: ResponsiveValue<number | string>
  pl?: ResponsiveValue<number | string>
  mx?: ResponsiveValue<number | string>
  my?: number | string
  w?: ResponsiveValue<number | string>
  h?: ResponsiveValue<number | string>
  lineHeight?: ResponsiveValue<number | string>
  border?: string
  isBold?: boolean
  overflow?: string
}> = ({
  children,
  color,
  px,
  py,
  pr,
  mt,
  mr,
  mb,
  mx,
  my,
  ml,
  pl,
  textStyle = 'h5',
  w,
  h,
  lineHeight,
  border,
  isBold,
  overflow,
}) => {
  return (
    <Text
      textStyle={textStyle}
      textColor={color}
      px={px}
      py={py}
      mt={mt}
      mr={mr}
      mb={mb}
      mx={mx}
      my={my}
      ml={ml}
      pl={pl}
      w={w}
      h={h}
      border={border}
      pr={pr}
      fontWeight={isBold ? 'bold' : ''}
      overflow={overflow}
      lineHeight={lineHeight}
    >
      {children}
    </Text>
  )
}

export default Heading
