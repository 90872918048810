import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import ArticleTemplate1 from '../../components/articleTemplates/ArticleTemplate1'
import ContentPageHeader from '../../components/content/ContentPageHeader'
import ArticlesSection from '../../components/sections/articles/ArticlesSection'
import Divider from '../../components/ui/Divider'
import { useArticle } from '../../apiClients/articlesApiClient'
import { ingressClimateSmartConstructionPage } from '../../apiClients/contentApiClient'
import {
  PATH_CLIMATE_IMPACT_OF_BUILDING_PARTS,
  PATH_COMPARE_CLIMATE_IMPACT,
  PATH_MY_PRIVATE_PAGES,
  PATH_MY_PAGES_CLIMATE_SMART_CONSTRUCTION,
  PATH_PUBLIC_CLIMATE_SMART_CONSTRUCTION,
} from '../../common/nav'

import PromoSmall from '../../components/articleTemplates/PromoSmall'
import { TAG_CLIMATE_SMART_CONSTRUCTION } from './../constants'
import climateImpactPromo from './../../img/pages/climateSmartConstruction/climateImpactPromo_s.svg'
import compareClimateImpactPromo from './../../img/pages/climateSmartConstruction/compareClimateImpactPromo_s.svg'

const ArticleWithId = () => {
  const navigate = useNavigate()
  const { id: articleId } = useParams()
  const { data: article } = useArticle(articleId)
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)

  return (
    <>
      <Helmet>
        <title>{article?.heading}</title>
      </Helmet>
      <ArticleTemplate1
        articleId={articleId}
        minW="100px"
        maxW="container.xl"
        onClose={() =>
          navigate(
            isWithinMyPages
              ? `/${PATH_MY_PAGES_CLIMATE_SMART_CONSTRUCTION}`
              : `/${PATH_PUBLIC_CLIMATE_SMART_CONSTRUCTION}`,
          )
        }
      />
      <Divider />
    </>
  )
}

const ClimateSmartConstructionPage = () => {
  const { pathname } = useLocation()
  const isWithinMyPages = pathname.startsWith(PATH_MY_PRIVATE_PAGES)
  const { t } = useTranslation()

  // eslint-disable-next-line no-console
  console.log(' ', pathname)
  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              <Helmet>
                <title>
                  {t('portal.sidebar.menu.climateSmartConstruction')}
                </title>
              </Helmet>
              <ContentPageHeader
                label={t(
                  'portal.sidebar.menu.climateSmartConstruction',
                ).toUpperCase()}
                ingress={ingressClimateSmartConstructionPage}
                mb={isWithinMyPages ? 6 : 0}
                isEmbedded={isWithinMyPages ? false : true}
              />
            </>
          }
        />
        <Route path="/:id/" element={<ArticleWithId />} />
      </Routes>

      <ArticlesSection
        pathPortal={PATH_MY_PAGES_CLIMATE_SMART_CONSTRUCTION}
        pathPublic={PATH_PUBLIC_CLIMATE_SMART_CONSTRUCTION}
        pageTag={TAG_CLIMATE_SMART_CONSTRUCTION}
        mb={64}
        mx="5%"
      >
        <PromoSmall
          variant="Row"
          heading={t('climate.illustration')}
          text={t('climate.climateImpactPromoText')}
          children={undefined}
          image={climateImpactPromo}
          link={PATH_CLIMATE_IMPACT_OF_BUILDING_PARTS}
        />
        <Divider />

        <PromoSmall
          variant="Row"
          heading={t('climate.comparer')}
          text={t('climate.compareClimateImpactPromoText')}
          children={undefined}
          image={compareClimateImpactPromo}
          link={PATH_COMPARE_CLIMATE_IMPACT}
        />
        <Divider />
      </ArticlesSection>
    </>
  )
}
export default ClimateSmartConstructionPage
