import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
const PromoCardSimple: React.FC<{
  children: ReactElement
  bgImageUrl?: string
  color?: string
  bg?: string
  mr?: number | ResponsiveValue<number>
  mb?: number
  mt?: number
  ml?: number
  isExample?: boolean
  px?: number
  py?: number
  mx?: number | string
  my?: number | string
  w?: string | number
  h?: string | number
  border?: string
  link?: string
  maxW?: string | number
  variant?: 'full' | 'wide' | 'compact' | 'micro'
  noBorder?: boolean
}> = ({
  children,
  color = 'black',
  bg = 'white',
  mr,
  mb,
  mt,
  ml,
  px,
  py,
  mx,
  my,
  w,
  h,
  border,
  link,
  maxW,
  variant = 'micro',
  noBorder = false,
}) => {
  const navigate = useNavigate()
  if (variant === 'compact') {
    h = 40
    w = 60
  } else if (variant === 'micro') {
    h = '160px'
    w = '160px'
  } else if (variant === 'full') {
    w = 'md'
  } else {
    h = 40
    w = 80
  }
  return (
    <Flex
      flexDir="column"
      position="relative"
      boxShadow={noBorder ? '' : 'xl'}
      borderRadius="lg"
      bg={bg}
      color={color}
      overflow="clip"
      justifyContent="center"
      py={py}
      px={px}
      my={my}
      mx={mx}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      w={w}
      h={h}
      maxW={maxW}
      border={border}
      onClick={() => {
        navigate(link ?? '')
      }}
      _hover={{ cursor: 'pointer' }}
    >
      {children}
    </Flex>
  )
}
export default PromoCardSimple
