import {
  Attachment,
  ContextArea,
  CopyAttachmentRequest,
  CreateAttachmentRequest,
  CreateAttachmentResponse,
} from '@contracts/support'
import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import log from '../common/log'

require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL as string

export const useAttachments = (
  areas: ContextArea[],
  prefix?: string,
): SWRResponse<Attachment[] | undefined> => {
  const areaQuery = encodeURI(JSON.stringify(areas))
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
    // >(`${baseUrl}/attachments?area=${area}${prefix ? `&prefix=${prefix}` : ''}`)
  >(
    `${baseUrl}/attachments?areas=${areaQuery}${
      prefix ? `&prefix=${prefix}` : ''
    }`,
  )
  return { data, error, isValidating, mutate }
}
export const useAttachmentsByAreaQuery = (
  areaQueries: {
    area?: ContextArea
    parentArea?: ContextArea
    grandParentArea?: ContextArea
    areaId?: string
    parentAreaId?: string
    grandParentAreaId?: string
  }[],
): SWRResponse<Attachment[] | undefined> => {
  const queriesStr = encodeURI(JSON.stringify(areaQueries))
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/attachments/areas?queries=${queriesStr}`)

  return { data, error, isValidating, mutate }
}

export const getAttachmentsByAreaQuery = async (
  areaQueries: {
    area?: ContextArea
    parentArea?: ContextArea
    grandParentArea?: ContextArea
    areaId?: string
    parentAreaId?: string
    grandParentAreaId?: string
  }[],
): Promise<Attachment[] | undefined> => {
  const queriesStr = encodeURI(JSON.stringify(areaQueries))

  return axios
    .get<Attachment[]>(`${baseUrl}/attachments/areas?queries=${queriesStr}`)
    .then(({ data }) => {
      return data
    })
}

//   const { data, error, isValidating, mutate } = useSWR<
//     Attachment[] | undefined
// >

//   (`${baseUrl}/attachments/areas?queries=${queriesStr}`)

//   return { data, error, isValidating, mutate }
// }

// SWRResponse<Attachment[] | undefined> => {
//   const queriesStr = encodeURI(JSON.stringify(areaQueries))
//   const { data, error, isValidating, mutate } = useSWR<
//     Attachment[] | undefined
//   >(`${baseUrl}/attachments/areas?queries=${queriesStr}`)

//   return { data, error, isValidating, mutate }
// }

export const useAttachment = (
  attachmentId: string | undefined,
): SWRResponse<Attachment | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<Attachment | undefined>(
    attachmentId ? `${baseUrl}/attachments/${attachmentId}` : null,
  )
  return { data, error, isValidating, mutate }
}

export const useMultipleAttachments = (
  attachmentIds: string[] | undefined,
): SWRResponse<Attachment[] | undefined> => {
  const queryString = encodeURIComponent(JSON.stringify(attachmentIds)) ?? ''
  // const { data, error, isValidating, mutate } =
  return useSWR<Attachment[] | undefined>(
    attachmentIds?.length
      ? `${baseUrl}/attachments/attachmentIds?attachmentIds=${queryString}`
      : null,
  )
  // return { data, error, isValidating, mutate }
}

export const useAccountAttachments = (
  accountId: string,
): SWRResponse<Attachment[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/admin/attachments/acccounts/${accountId}`)
  return { data, error, isValidating, mutate }
}

export const useAllAttachments = (): SWRResponse<Attachment[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<
    Attachment[] | undefined
  >(`${baseUrl}/attachments`)
  return { data, error, isValidating, mutate }
}

export async function addAttachment(
  requestData: FormData,
  isPrivate?: boolean,
): Promise<Attachment> {
  const uri = isPrivate
    ? `${baseUrl}/attachments?private=true`
    : `${baseUrl}/attachments`

  return axios
    .post<CreateAttachmentRequest, AxiosResponse<CreateAttachmentResponse>>(
      uri,
      requestData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error storing attachment')
      throw error
    })
}

export async function copyAttachment(
  copyRequests: CopyAttachmentRequest,
  isPrivate?: boolean,
): Promise<Attachment[]> {
  const uri = isPrivate
    ? `${baseUrl}/attachments/copy?private=true`
    : `${baseUrl}/attachments/copy`

  return axios
    .post<CopyAttachmentRequest, AxiosResponse<CreateAttachmentResponse[]>>(
      uri,
      copyRequests,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      // eslint-disable-next-line no-console
      console.log('status: ', status)
      // eslint-disable-next-line no-console
      console.log('data: ', data)
      // eslint-disable-next-line no-console
      console.log('data.length: ', data.length)
      log.info('Response from server for copy attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error copying attachment')
      throw error
    })
}

export const updateFilename = async (
  id: string,
  newFilename: string,
): Promise<Attachment> => {
  return axios
    .put(
      `${baseUrl}/attachments/${id}`,
      {
        originalFilename: newFilename,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating attachment')
      throw error
    })
}

export const updateMultipleFilenames = async (
  ids: string[],
  newFilename: string,
): Promise<Attachment> => {
  return axios
    .put(
      `${baseUrl}/attachments/fileName?ids=${JSON.stringify(ids)}`,
      {
        originalFilename: newFilename,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating attachment')
      throw error
    })
}

export const updateContextAreaId = async (
  id: string,
  newAreaId: string,
): Promise<Attachment> => {
  return axios
    .put(
      `${baseUrl}/attachments/${id}`,
      {
        areaId: newAreaId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating attachment')
      throw error
    })
}

export const updateContextAreaData = async (
  id: string,
  areaData: {
    area: ContextArea
    areaId?: string
    parentArea?: ContextArea
    parentAreaId?: string
    grandParentArea?: ContextArea
    grandParentAreaId?: string
  },
): Promise<Attachment> => {
  return axios
    .put(
      `${baseUrl}/attachments/${id}`,
      {
        area: areaData.area,
        areaId: areaData.areaId,
        parentArea: areaData.parentArea,
        parentAreaId: areaData.parentAreaId,
        grandParentArea: areaData.grandParentArea,
        grandParentAreaId: areaData.grandParentAreaId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated attachment', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating attachment')
      throw error
    })
}

export const deleteAttachmentsByArea = async (
  area: ContextArea,
  areaId: string,
) => {
  return axios
    .delete(`${baseUrl}/attachments/area/?area=${area}&areaId=${areaId}`)
    .then(({ status, data }) => {
      log.info('Successfully deleted attachments for area', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error deleting attachments for area', area)
      return error.message
    })
}

export const deleteAttachmentsByAreaQueries = async (
  areaQueries: {
    area?: ContextArea
    parentArea?: ContextArea
    grandParentArea?: ContextArea
    areaId?: string
    parentAreaId?: string
    grandParentAreaId?: string
  }[],
) => {
  const queriesStr = encodeURI(JSON.stringify(areaQueries))
  return axios
    .delete(`${baseUrl}/attachments/areas?queries=${queriesStr}`)
    .then(({ data }) => {
      log.info(
        'Successfully deleted attachments for area quereies',
        areaQueries,
      )
      return data
    })
    .catch(error => {
      log.error(
        { err: error },
        'Error deleting attachments for area queries',
        queriesStr,
      )
      return error.message
    })
}

export const deleteAttachment = async (id: string, isPrivate?: boolean) => {
  const uri = isPrivate
    ? `${baseUrl}/attachments/${id}?private=true`
    : `${baseUrl}/attachments/${id}`

  log.debug('Deleting attachment: ', uri)
  await axios.delete(uri).catch((error: Error) => {
    log.error({ err: error }, 'Error deleting attachment')
    return error.message
  })
}
