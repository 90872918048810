import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { FaSearch, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Flex,
  Editable,
  EditablePreview,
  EditableInput,
  EditableTextarea,
  Text,
} from '@chakra-ui/react'

import { ConstructionGlossary } from '@contracts/support'
import SortableHeading from '../ui/SortableHeading'
import { Heading, IconButton } from '../../ui'
import { TagSortingOption } from '../../common/enums'

import {
  deleteGlossaryItem,
  updateGlossaryItem,
  useConstructionGlossary,
} from '../../apiClients/constructionGlossaryApiClient'
import { calculateSortedItems } from './constructionGlossaryListUtils'

const MAX_LENGTH_STRING = 255

const ListConstructionGlossary: React.FC = () => {
  const { t } = useTranslation()

  const [sortType, setSortType] = useState(TagSortingOption.CREATED_DATE_DESC)
  const [searchStr, setSearchStr] = useState<string | undefined>()

  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])

  const { data: fullGlossary, mutate } = useConstructionGlossary()
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }

  const deleteGlossaryItemHandler = async (id: string) => {
    await deleteGlossaryItem(id)
    void mutate()
  }

  const submitHandler = async (
    glossaryItem: ConstructionGlossary,
    newName: string | undefined,
    newDescription: string | undefined,
  ) => {
    if (
      (newName && newName !== glossaryItem.name) ||
      (newDescription && newDescription !== glossaryItem.description)
    ) {
      await updateGlossaryItem(glossaryItem.id, newName, newDescription)
      void mutate()
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > MAX_LENGTH_STRING) {
      e.currentTarget.value = _.truncate(e.currentTarget.value, {
        length: MAX_LENGTH_STRING,
        omission: '',
      })
    }
  }

  const getEditableName = (
    glossaryItem: ConstructionGlossary,
    name: string | undefined,
  ) => {
    return (
      <Box>
        <Editable
          defaultValue={name}
          onSubmit={newValue => {
            void submitHandler(glossaryItem, newValue, '')
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} />
          <EditableInput onChange={changeHandler} />
        </Editable>
      </Box>
    )
  }
  const getEditableDescription = (
    glossaryItem: ConstructionGlossary,
    description: string | undefined,
  ) => {
    return (
      <Box>
        <Editable
          defaultValue={description}
          onSubmit={newValue => {
            void submitHandler(glossaryItem, '', newValue)
          }}
        >
          <EditablePreview _hover={{ cursor: 'pointer' }} />
          <EditableTextarea h={52} />
        </Editable>
      </Box>
    )
  }

  const sortedGlossary = calculateSortedItems(
    sortType,
    fullGlossary,
    searchStr,
  ) as ConstructionGlossary[]

  return (
    <Box pb={40} mt={12}>
      <Heading textStyle="h3">
        {t('constructionGlossary.manageGlossary')}
      </Heading>

      <Flex
        flexDir="row"
        mt={6}
        mb={12}
        alignItems="center"
        flexWrap="wrap"
        w="100%"
      >
        <Flex flexDir="row" maxW="container.sm" alignItems="center">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<FaSearch color="gray.200" />}
            />
            <Input
              type="text"
              placeholder={t('constructionGlossary.search')}
              width="30%"
              borderColor="gray.400"
              height={10}
              onChange={e => setSearchStr(e.target.value)}
              w="100%"
            />
          </InputGroup>
          <Text ml={4} w={'sm'} textStyle="h7">
            {t('constructionGlossary.searchInfo', {
              total: fullGlossary?.length ?? 0,
              filtered: sortedGlossary.length ?? 0,
            })}
          </Text>
        </Flex>
      </Flex>

      <Table variant="unstyled" colorScheme="gray" data-cy="accounts-list">
        <TableCaption>
          {t('constructionGlossary.listingBerikarGlossary')}
        </TableCaption>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.NAME_ASC)}
                onDescSort={() => setSortType(TagSortingOption.NAME_DESC)}
              >
                {t('constructionGlossary.name')}
              </SortableHeading>
            </Th>
            <Th>
              <Heading textStyle="h6" w="sm">
                {t('constructionGlossary.description')}
              </Heading>
            </Th>

            <Th>
              <SortableHeading>{t('articles.id')}</SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.UPDATED_DATE_ASC)}
                onDescSort={() =>
                  setSortType(TagSortingOption.UPDATED_DATE_DESC)
                }
              >
                {t('constructionGlossary.updatedAt')}
              </SortableHeading>
            </Th>
            <Th>
              <SortableHeading
                onAscSort={() => setSortType(TagSortingOption.CREATED_DATE_ASC)}
                onDescSort={() =>
                  setSortType(TagSortingOption.CREATED_DATE_DESC)
                }
              >
                {t('constructionGlossary.createdAt')}
              </SortableHeading>
            </Th>

            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {sortedGlossary && sortedGlossary.length > 0 ? (
            sortedGlossary.map((glossary, i) => {
              return (
                <Fragment key={glossary.id}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                  >
                    <Td
                      color="primary_orange02"
                      _hover={{ color: 'primary_orange01' }}
                    >
                      {!accordionsToShow.includes(glossary.id) && (
                        <FaRegPlusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(glossary.id)
                          }}
                        />
                      )}
                      {accordionsToShow.includes(glossary.id) && (
                        <FaRegMinusSquare
                          fontSize={20}
                          fontWeight="thin"
                          onClick={() => {
                            toggleAccordion(glossary.id)
                          }}
                        />
                      )}
                    </Td>

                    <Td>
                      <Tooltip
                        label={t('general.clickToEdit')}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                        placement="top-start"
                      >
                        <Box w={60}>
                          {getEditableName(glossary, glossary.name)}
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      <Tooltip
                        label={t('general.clickToEdit')}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                        placement="top-start"
                      >
                        <Box w="sm">
                          {getEditableDescription(
                            glossary,
                            glossary.description,
                          )}
                        </Box>
                      </Tooltip>
                    </Td>
                    <Td>
                      <Tooltip
                        label={glossary.id}
                        hasArrow
                        arrowSize={15}
                        closeDelay={500}
                      >
                        {_.truncate(glossary.id, { length: 15 })}
                      </Tooltip>
                    </Td>
                    <Td>
                      {new Date(glossary.updatedAt ?? 0).toLocaleString(
                        'sv-SE',
                      )}
                    </Td>
                    <Td>
                      {new Date(glossary.createdAt ?? 0).toLocaleString(
                        'sv-SE',
                      )}
                    </Td>
                    <Td>
                      <IconButton
                        data-cy="deleteButton"
                        buttonType="DELETE"
                        m={1}
                        onClick={() => {
                          if (
                            window.confirm(
                              t(
                                'constructionGlossary.deleteGlossaryItemQuestion',
                                {
                                  glossaryItemId: glossary.id,
                                  glossaryItemName: glossary.name,
                                },
                              ),
                            )
                          ) {
                            void deleteGlossaryItemHandler(glossary.id)
                          }
                        }}
                        tooltipLabel={t('constructionGlossary.deleteTooltip')}
                      />
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(glossary.id) && (
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={7}></Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr key="none">
              <Td colSpan={9} textAlign="center">
                {t('articles.noArticlesFound')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
export default ListConstructionGlossary
