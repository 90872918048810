import axios, { AxiosResponse } from 'axios'
import useSWR, { SWRResponse } from 'swr'
import {
  Article,
  ArticleInfo,
  CreateArticleRequestData,
  CreateArticleResponse,
  FetchAllArticlesResponse,
  FetchSingleArticlesResponse,
  Tag,
  UpdateArticleRequestData,
  UpdateArticleStatusRequestData,
} from '@contracts/support'
import log from '../common/log'
require('./apiClientsCommon')

const baseUrl = window.SUPPORT_SERVICE_URL

const articlesFetcher = async (
  urlKey: string,
): Promise<ArticleInfo[] | undefined> => {
  return axios
    .get<FetchAllArticlesResponse>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data
    })
}

const tagsFetcher = async (urlKey: string): Promise<Tag[] | undefined> => {
  return axios.get<Tag[]>(`${baseUrl}${urlKey}`).then(({ data }) => {
    return data
  })
}

export const useTags = (): SWRResponse<Tag[] | undefined> => {
  const { data, error, isValidating, mutate } = useSWR<Tag[] | undefined>(
    '/tags',
    tagsFetcher,
    { revalidateOnFocus: false },
  )
  return { data, error, isValidating, mutate }
}

export const usePublishedArticlesInfo = (requestParams?: {
  includeTags?: string[]
  excludeTags?: string[]
}): SWRResponse<ArticleInfo[] | undefined> => {
  const includeTagsStr: string =
    requestParams?.includeTags
      ?.map(tagCode => {
        return tagCode
      })
      ?.join(',') ?? ''
  const excludeTaggsStr =
    requestParams?.excludeTags
      ?.map(tagCode => {
        return tagCode
      })
      .join(',') ?? ''

  const queryString = `?includeTags=${includeTagsStr}&excludeTags=${excludeTaggsStr}`

  const { data, error, isValidating, mutate } = useSWR<
    ArticleInfo[] | undefined
  >(
    `/articles${queryString}`,

    articlesFetcher,
    {
      revalidateOnFocus: false,
    },
  )
  return { data, error, isValidating, mutate }
}

export const useArticlesInfoAllStates = (requestParams?: {
  includeTags?: string[]
  excludeTags?: string[]
}): SWRResponse<ArticleInfo[] | undefined> => {
  const includeTaggsStr: string =
    requestParams?.includeTags
      ?.map(tagCode => {
        return tagCode
      })
      ?.join(',') ?? ''
  const excludeTaggsStr =
    requestParams?.excludeTags
      ?.map(tagCode => {
        return tagCode
      })
      .join(',') ?? ''

  const queryString = `?includeTags=${includeTaggsStr}&excludeTags=${excludeTaggsStr}`

  const { data, error, isValidating, mutate } = useSWR<
    ArticleInfo[] | undefined
  >(
    `/admin/articles/${queryString}`,

    articlesFetcher,
    {
      revalidateOnFocus: false,
    },
  )
  return { data, error, isValidating, mutate }
}

const articleWithIdFetcher = async (
  urlKey: string,
): Promise<Article | undefined> => {
  return axios
    .get<FetchSingleArticlesResponse>(`${baseUrl}${urlKey}`)
    .then(({ data }) => {
      return data
    })
}

export const useArticle = (
  id?: string,
  allStates = false,
): SWRResponse<Article | undefined> => {
  let pathAndQueryString = ''
  if (allStates !== false) {
    pathAndQueryString = `/admin/articles/${id ?? ''}`
  } else {
    pathAndQueryString = `/articles/${id ?? ''}`
  }
  const { data, error, isValidating, mutate } = useSWR<Article | undefined>(
    id && `${pathAndQueryString}`,
    articleWithIdFetcher,
    { revalidateOnFocus: false },
  )
  return { data, error, isValidating, mutate }
}

export async function addArticle(
  requestData: CreateArticleRequestData,
): Promise<Article> {
  return axios
    .post<CreateArticleRequestData, AxiosResponse<CreateArticleResponse>>(
      `${baseUrl}/articles/`,
      requestData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new article', status, data)
      return data.data.attributes
    })
    .catch(error => {
      log.error({ err: error }, 'Error creating article')
      throw error
    })
}

export const updateArticleByValue = async (
  id: number,
  requestData: UpdateArticleRequestData,
) => {
  return axios
    .patch(`${baseUrl}/articles/${id}`, requestData, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(({ status, data }) => {
      log.info('Successfully updated article', status, data)
      return data.data.attributes
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating article')
      throw error
    })
}

export const updateArticleStatus = async (
  id: number,
  requestData: UpdateArticleStatusRequestData,
) => {
  return axios
    .put(`${baseUrl}/articles/${id}/status`, requestData, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(() => log.info('Successfully updated article status'))
    .catch(error => {
      log.error({ err: error }, 'Error updating article status')
      throw error
    })
}

export const deleteArticle = async (id: number) =>
  await axios.delete(`${baseUrl}/articles/${id}`).catch((error: Error) => {
    log.error({ err: error }, 'Error deleting article')
    return error.message
  })

export async function addTag(name: string, code: string): Promise<Tag> {
  return axios
    .post<Tag, AxiosResponse<Tag>>(
      `${baseUrl}/tags/`,
      {
        name,
        code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Response from server for new tag', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error creating tag')
      throw error
    })
}

export const updateTag = async (
  id: string,
  name: string | undefined,
  code: string | undefined,
) => {
  return axios
    .put(
      `${baseUrl}/tags/${id}`,
      {
        ...(name && { name }),
        ...(code && { code }),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then(({ status, data }) => {
      log.info('Successfully updated tag', status, data)
      return data
    })
    .catch(error => {
      log.error({ err: error }, 'Error updating tag')
      throw error
    })
}

export const deleteTag = async (id: string) => {
  await axios
    .delete(`${baseUrl}/tags/${id}`)
    .then(() => {
      log.info('Successfully deleted tag with id ', id)
    })
    .catch((error: Error) => {
      log.error({ err: error }, 'Error deleting tag')
      return error.message
    })
}
