import { Box, Flex, Image, ResponsiveValue, Text } from '@chakra-ui/react'
import {
  useOrganization,
  useOrganizationProfile,
} from '../../apiClients/organizationsApiClient'

const baseUrl = `${window.AZURE_BLOB_STORAGE_BASE_URL}/public/`

const OrganizationLogoCard: React.FC<{
  organizationId: string
  mt?: ResponsiveValue<number | string>
  px?: ResponsiveValue<number | string>
  w?: ResponsiveValue<number | string>
  boxSize?: ResponsiveValue<number | string>
  variant?: 'full' | 'logo-only'
}> = ({
  organizationId,
  mt,
  px,
  w,
  variant = 'full',
  boxSize = { xxs: 20, laptop: 24 },
}) => {
  const { data: organization } = useOrganization(organizationId)
  const { data: organizationProfile } = useOrganizationProfile(organizationId)

  return (
    <Flex
      mt={mt}
      px={px}
      bg="primary_green01"
      justifyContent="space-around"
      w={w}
    >
      <Flex flexDir="column" gap={2} alignItems="center">
        {organizationProfile?.profileAttachmentId && (
          <Image
            fit="cover"
            src={`${baseUrl}${organizationProfile.profileAttachmentId}`}
            backgroundPosition="center"
            boxSize={boxSize}
            borderRadius="lg"
            border="1px solid"
            borderColor="primary_grey02"
            zIndex="base"
            boxShadow="lg"
          />
        )}
        {!organizationProfile?.profileAttachmentId && (
          <Box
            backgroundPosition="center"
            boxSize={boxSize}
            borderRadius="lg"
            border="1px solid"
            borderColor="primary_grey02"
            zIndex="base"
            boxShadow="lg"
          />
        )}
        {variant === 'full' && (
          <Flex flexDir="column">
            <Text textStyle="textRegular" lineHeight={4}>
              {organization?.name}
            </Text>

            <Text textStyle="smLight">{organization?.address?.city}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
export default OrganizationLogoCard
