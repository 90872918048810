import { Flex, Select, Text, useDisclosure } from '@chakra-ui/react'
import { HelpRequestTemplateResponse } from '@contracts/misc'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useOrganizationCollaborator } from '../../apiClients/organizationsCollaboratorApiClient'
import { useProjectsByProperty } from '../../apiClients/projectsApiClient'
import { useProperties } from '../../apiClients/propertiesApiClient'
import ProjectClientsCard from '../projects/ProjectClientsCard'
import MyHelpRequestSection from '../sections/portal/MyHelpRequestSection'
import AddProjectButton from '../ui/AddProjectButton'
import AlertDialogUnsavedChanges from '../ui/AlertDialogUnsavedChanges'

import Modal from '../ui/Modal'
import Divider from '../ui/Divider'
import Heading from '../ui/Heading'
import AuthContext from '../../common/auth-context'
import AddHelpRequest from '../helpRequests/AddHelpRequest'

import AddOrEditHelpRequestTemplateForm from '../helpRequests/AddOrEditHelpRequestTemplateForm'

const ContactProviderSection: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { isConsumer } = useContext(AuthContext)
  const [propertyId, setPropertyId] = useState<string | undefined>(undefined)
  const [projectId, setProjectId] = useState<string | undefined>(undefined)
  const [newHelpRequestTemplate, setNewHelpRequestTemplate] = useState<
    HelpRequestTemplateResponse | undefined
  >(undefined)
  const { data: organization } = useOrganizationCollaborator(organizationId)
  const { data: properties } = useProperties()
  const { data: projects, mutate: mutateProjects } =
    useProjectsByProperty(propertyId)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const closeHandler = (avoidAlertDialog?: boolean) => {
    if (hasUnsavedChanges && !avoidAlertDialog) {
      onOpenAlertDialog()
    } else {
      onClose()
    }
  }

  return (
    <>
      <Flex flexDir="column" gap={4} my={4} textStyle="infoTextLight" px={2}>
        <Text>
          Vad kul att du vill komma i kontakt med oss! För att vi ska kunna
          hjälpa dig på bästa sätt fyll i formuläret nedan och beskriv, så
          utförligt du kan, vad du behöver hjälp med.
        </Text>
        <Text>Vi återkommer med ett svar på din förfrågan inom kort!</Text>
      </Flex>

      <>
        <Heading color="logo_green01" textStyle="h6" isBold ml={2} mt={8}>
          Skicka ny förfrågan
        </Heading>
        <Divider />
        <Flex flexDir="column" pb={8} gap={1}>
          <Text>Hem som förfrågan avser</Text>
          <Select
            placeholder="Välj hem"
            maxW="md"
            onChange={e => {
              setPropertyId(e.currentTarget.value)
              setProjectId(undefined)
            }}
            bg="white"
          >
            {properties &&
              properties.length > 0 &&
              properties.map(property => {
                return (
                  <option value={property.id} key={property.id}>
                    {property.name}
                  </option>
                )
              })}
          </Select>
          {propertyId && (
            <>
              <Text mt={4}>Vilket projekt gäller det?</Text>
              <Select
                placeholder={projects ? 'Välj projekt' : 'Välj först hem'}
                maxW="md"
                onChange={e => {
                  setProjectId(e.currentTarget.value)
                }}
                bg="white"
              >
                {projects &&
                  projects.length > 0 &&
                  projects.map(project => {
                    return (
                      <option value={project.id} key={project.id}>
                        {project.name}
                      </option>
                    )
                  })}
              </Select>
              {!projectId && properties && (
                <AddProjectButton
                  properties={properties}
                  variant={'BUTTON'}
                  w={72}
                  mt={4}
                  noForwardRedirect
                  mutateProp={mutateProjects}
                >
                  <Text>Jag behöver skapa ett nytt projekt</Text>
                </AddProjectButton>
              )}
            </>
          )}

          {projectId && (
            <>
              <Flex>
                <Text mt={4}>Beställare i projektet</Text>
                <Text mt={4} textStyle="infoTextLight">
                  &nbsp;(mottagare av offert)
                </Text>
              </Flex>
              <ProjectClientsCard projectId={projectId} mt={2} />
            </>
          )}
        </Flex>

        {projectId && (
          <AddOrEditHelpRequestTemplateForm
            helpRequestTemplate={newHelpRequestTemplate}
            projectId={projectId}
            isEmbedded
            onSaveWithoutSendHelpReqTemplate={newHelpRequestTemplate => {
              setNewHelpRequestTemplate(newHelpRequestTemplate)
            }}
            onSaveAndSendHelpReqTemplate={newHelpRequestTemplate => {
              setNewHelpRequestTemplate(newHelpRequestTemplate)
              onOpen()
            }}
          />
        )}
        <Divider />
      </>
      {isConsumer && (
        <MyHelpRequestSection organizationId={organizationId} mt={8} />
      )}

      {newHelpRequestTemplate && (
        <Modal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={closeHandler}
          size="3xl"
        >
          <>
            <AlertDialogUnsavedChanges
              isOpen={isOpenAlertDialog}
              onClose={onCloseAlertDialog}
              leastDestructiveRef={cancelRef}
              onConfirmClose={onClose}
            />

            <AddHelpRequest
              helpRequestTemplate={newHelpRequestTemplate}
              onCloseModal={closeHandler}
              onParentHasUnsavedChanges={setHasUnsavedChanges}
              defaultOrganization={organization}
            />
          </>
        </Modal>
      )}
    </>
  )
}
export default ContactProviderSection
