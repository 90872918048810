import { Flex, Text } from '@chakra-ui/react'
import { PropertyResponse } from '@contracts/misc'
import { usePropertyTypes } from '../../apiClients/propertiesApiClient'

const PropertyNameTag: React.FC<{
  property: PropertyResponse
  mt?: string | number
  mr?: string | number
  mb?: string | number
  ml?: string | number
}> = ({ property, mt, mr, mb, ml }) => {
  const { data: allPropertyTypes } = usePropertyTypes()

  return (
    <Flex flexDir="column" mt={mt} mr={mr} mb={mb} ml={ml} zIndex="base">
      <Text textStyle="h2">{property.name}</Text>
      <Flex
        flexDir="row"
        wrap="wrap"
        textStyle="xlLight"
        alignItems="baseline"
        flexWrap="wrap"
        pb={1}
        borderBottomRadius="lg"
      >
        <Text>
          {
            allPropertyTypes?.find(pt => {
              return pt.id === property.propertyTypeId
            })?.name
          }
          &nbsp;-&nbsp;
          {property.address?.street}, &nbsp;
          {property.address?.city}
        </Text>
      </Flex>
    </Flex>
  )
}
export default PropertyNameTag
