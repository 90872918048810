import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useProjects } from '../../apiClients/projectsApiClient'
import AuthContext from '../../common/auth-context'
import { IconName } from '../../common/icon-utils'
import {
  menuItemListNewCompany,
  getNavLinksProviderPortal,
  PATH_MY_PROVIDER_PAGES,
} from '../../common/nav'

import { Icon } from '../../components/ui/Icon'
import { Logo } from '../../components/ui/Logo'
import { Heading } from '../../ui'

import SidebarAreaHeader from './SidebarAreaHeader'
import SidebarItem from './SidebarItem'

const MenuDrawerProvider: React.FC<{
  isOpenMenuDrawer: boolean
  onOpenMenuDrawer: () => void
  onCloseMenuDrawer: () => void
}> = ({ isOpenMenuDrawer, onCloseMenuDrawer }) => {
  const isExpanded = true
  const navigate = useNavigate()

  const marginLeftHeading = 4
  const paddingLeftItem = 1
  const { t } = useTranslation()
  const { currentOrganization } = useContext(AuthContext)
  const navLinks = getNavLinksProviderPortal(
    currentOrganization?.id != undefined,
  )

  const { data: projects } = useProjects()

  return (
    <Drawer
      placement="left"
      onClose={onCloseMenuDrawer}
      isOpen={isOpenMenuDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody m={0} p={0}>
          <DrawerCloseButton zIndex="overlay" />

          <Flex
            pos="fixed"
            pt={6}
            flexDir="column"
            w="100%"
            h="100vh"
            boxShadow="xl"
            bg="darkBackground"
            overflow="scroll"
            pb={32}
          >
            {isExpanded && (
              <>
                <Logo
                  name="BerikarProvider"
                  mb={4}
                  h={14}
                  onClick={() => {
                    navigate(PATH_MY_PROVIDER_PAGES)
                    onCloseMenuDrawer()
                  }}
                  ml={6}
                />
                <Heading px={4} mb={6} textStyle="textRegular">
                  {currentOrganization?.name}
                </Heading>
              </>
            )}

            <SidebarAreaHeader
              mt={4}
              mr={6}
              mb={2}
              ml={marginLeftHeading}
              isMinimized={!isExpanded}
              toolTipText={t('general.comingSoon')}
            >
              {isExpanded && <Text>{t('general.myActiveProjects')}</Text>}
            </SidebarAreaHeader>
            {!currentOrganization && (
              <Text textStyle="textSmall" color="red.500" px={8}>
                Aktivera företag för att få tillgång till projekt
              </Text>
            )}
            {projects &&
              projects.map(p => {
                return (
                  <SidebarItem
                    key={p.id}
                    variant="project"
                    pl={2}
                    py={1}
                    pr={1}
                    isMinimized={!isExpanded}
                    toLink={`${PATH_MY_PROVIDER_PAGES}projekt/${p.id}`}
                    isDisabled={currentOrganization ? false : true}
                    onClick={onCloseMenuDrawer}
                  >
                    {p.name}
                  </SidebarItem>
                )
              })}
            {!projects?.length && (
              <Box pl={10} textStyle="inputPlaceholder">
                {isExpanded && (
                  <Text>{t('portal.sidebar.provider.noProjectsFound')}</Text>
                )}
              </Box>
            )}

            {/* Menu section */}
            <SidebarAreaHeader
              mt={4}
              mr={6}
              mb={2}
              ml={marginLeftHeading}
              noAddIcon={true}
              isMinimized={!isExpanded}
            >
              {isExpanded && <Text>{t('portal.sidebar.menu.menu')}</Text>}
            </SidebarAreaHeader>
            {navLinks.map(item => {
              return (
                <SidebarItem
                  key={item.value}
                  variant="menu"
                  icon={<Icon name={item.icon as IconName} />}
                  pl={paddingLeftItem}
                  py={1}
                  pr={1}
                  isMinimized={!isExpanded}
                  toLink={item.to}
                  isDisabled={item.isDisabled}
                  onClose={onCloseMenuDrawer}
                >
                  {t(item.value)}
                </SidebarItem>
              )
            })}
            <Box height="2%"></Box>
            {!currentOrganization && (
              <SidebarItem
                variant="menu"
                icon={<Icon name={menuItemListNewCompany.icon as IconName} />}
                pl={paddingLeftItem}
                py={1}
                pr={1}
                isMinimized={!isExpanded}
                toLink={menuItemListNewCompany.to}
                isDisabled={menuItemListNewCompany.isDisabled}
                onClose={onCloseMenuDrawer}
              >
                {t(menuItemListNewCompany.value)}
              </SidebarItem>
            )}

            <Box height="20%"></Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
export default MenuDrawerProvider
