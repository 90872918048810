import { Box, Button, Text } from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import React, { Dispatch, SetStateAction, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { CreateAccountRequestData } from '@contracts/accounts'
import { AccountTypeName } from '@contracts/misc'

import {
  validateEmail,
  validateString,
  validatePassword,
  validatePasswordMatch,
} from '../../common/auth-utils'
import Input from '../ui/Input'
import { addAccount } from '../../apiClients/accountsApiClient'
import Spinner from '../Spinner'
import log from '../../common/log'
import Link from '../ui/Link'
import { PATH_PUBLIC_WEB } from '../../common/nav'

interface MyFormValues {
  firstName: string
  lastName: string
  email: string
  pwd: string
  pwd2: string
}
interface Errors {
  firstName?: string
  lastName?: string
  email?: string
  pwd?: string
  pwd2?: string
}

const RegisterAccountForm: React.FC<{
  m?: number
  accountType: AccountTypeName
  accountCreated: boolean
  setAccountCreated: (accountCreated: boolean) => void
  // defaultEmail?: string
  subscription?: string
  currentUserEmail: string
  setCurrentUserEmail: Dispatch<SetStateAction<string>>
}> = ({
  accountType,
  setAccountCreated,
  accountCreated,
  subscription,
  currentUserEmail,
  setCurrentUserEmail,
}) => {
  const applicationInsights = useAppInsightsContext()
  const { t } = useTranslation()

  const [emailTaken, setEmailTaken] = useState(false)

  const initialValues: MyFormValues = {
    firstName: '',
    lastName: '',
    email: currentUserEmail,
    pwd: '',
    pwd2: '',
  }

  const validate = (values: MyFormValues) => {
    const errors: Errors = {}
    let tmpRes = validateString(
      values.firstName,
      t('accounts.accountForm.firstName'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.firstName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.lastName,
      t('accounts.accountForm.lastName'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.lastName = tmpRes.errorMsg
    }
    tmpRes = validateEmail(values.email)
    if (!tmpRes.isValid) {
      errors.email = tmpRes.errorMsg
    }
    tmpRes = validatePassword(values.pwd)
    if (!tmpRes.isValid) {
      errors.pwd = tmpRes.errorMsg
    }
    tmpRes = validatePasswordMatch(values.pwd, values.pwd2)
    if (!tmpRes.isValid) {
      errors.pwd2 = tmpRes.errorMsg
    }
    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const createAccountRequest: CreateAccountRequestData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.pwd,
        accountType,
      }

      addAccount(createAccountRequest)
        .then(data => {
          if (
            typeof data === 'object' &&
            'title' in data &&
            data.title === 'Email already taken'
          ) {
            setEmailTaken(true)
            return
          }
          log.info('Successfully registered an account')

          setEmailTaken(false)
          setAccountCreated(true)
          applicationInsights.trackEvent(
            {
              name:
                accountType === 'Consumer'
                  ? 'New registered consumer'
                  : 'New registered provider',
            },
            { email: values.email },
          )
          applicationInsights.trackEvent(
            { name: 'Subscription chosen' },
            { subcription: subscription },
          )
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to register account')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="add-account">
      {!accountCreated && (
        <Box display="flex" flexDirection="column">
          <Input
            title={t('accounts.accountForm.firstName')}
            name="firstName"
            type="text"
            isMandatory={true}
            isError={!!errors.firstName}
            errorText={
              touched.firstName && errors.firstName ? errors.firstName : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="top"
          />
          <Input
            title={t('accounts.accountForm.lastName')}
            name="lastName"
            type="text"
            isMandatory={true}
            isError={!!errors.lastName}
            errorText={
              touched.lastName && errors.lastName ? errors.lastName : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            position="bottom"
          />
          <Input
            margin="2rem 0 0 0"
            title={t('accounts.accountForm.email')}
            name="email"
            autoComplete="username"
            isMandatory={true}
            type="email"
            defaultValue={currentUserEmail}
            isError={!!errors.email}
            errorText={touched.email && errors.email ? errors.email : ''}
            onChange={e => {
              setCurrentUserEmail(e.currentTarget.value)
              handleChange(e)
            }}
            onBlur={handleBlur}
            position="top"
          />
          {emailTaken && (
            <Box textStyle="errorSmall" m=".5rem 1rem 0 1rem">
              {t('auth.errorMessages.emailAlreadyTaken')}
            </Box>
          )}
          <Input
            title={t('auth.password')}
            name="pwd"
            autoComplete="new-password"
            isMandatory={true}
            type="password"
            isError={!!errors.pwd}
            errorText={touched.pwd && errors.pwd ? errors.pwd : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="middle"
          />
          <Input
            title={t('auth.passwordRepeat')}
            name="pwd2"
            autoComplete="new-password"
            isMandatory={true}
            type="password"
            isError={!!errors.pwd2}
            errorText={touched.pwd2 && errors.pwd2 ? errors.pwd2 : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            position="bottom"
          />
          <Button
            type="submit"
            variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
            disabled={isLoading}
            mt={8}
          >
            {t('auth.createAccount')}
          </Button>

          <Text my={2} fontSize="14px">
            <Trans i18nKey="auth.acceptTerms">
              <Link
                to={`${PATH_PUBLIC_WEB}/anvandarvillkor`}
                color="primary"
                isExternal
              >
                terms
              </Link>
              <Link
                to={`${PATH_PUBLIC_WEB}/integritetspolicy`}
                color="primary"
                isExternal
              >
                privacy policy
              </Link>
            </Trans>
          </Text>
        </Box>
      )}

      {accountCreated && accountType === 'Consumer' && (
        <Text>{t('auth.privateAccountCreatedPleaseLogin')}</Text>
      )}
      {accountCreated && accountType === 'Provider' && (
        <Text>{t('auth.providerAccountCreatedPleaseLogin')}</Text>
      )}

      {isLoading && <Spinner />}
    </form>
  )
}
export default RegisterAccountForm
