import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ListProjectPlaces from './ListProjectPlaces'

const ManageProjectPlaces = () => {
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const projectPlaceId = search.get('projekt')
  return (
    <Box pb={40}>
      <Helmet>
        <title>{t('admin.manageProjectPlaces')}</title>
      </Helmet>

      <ListProjectPlaces
        searchProjectPlaceId={projectPlaceId ?? undefined}
        variant="admin-all"
      />
    </Box>
  )
}
export default ManageProjectPlaces
