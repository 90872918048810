import {
  Badge,
  Center,
  HStack,
  Show,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Flex,
} from '@chakra-ui/react'
import _ from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Note } from '@contracts/misc'
import { BsEye } from 'react-icons/bs'

import { NoteSortingOption, TagSortingOption } from '../../common/enums'
import log from '../../common/log'
import {
  getDateStrFromNumber,
  getLatestDateStrFromNumber,
} from '../../common/util'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'

import AlertDialog from '../ui/AlertDialogAndButton'
import { Icon } from '../ui/Icon'

import SortableHeading from '../ui/SortableHeading'
import StarIcon from '../ui/StarIcon'

import NoteContentCard from './NoteCardContent'
import { useNoteParts } from './notesHelper'

import { calculateSortedItemsWrapper } from './notesListUtils'
import NoteCardMediumButton from './NoteCardMediumButton'

const NotesListView: React.FC<{
  propertyId?: string
  projectId?: string
  filteredNotes?: Note[]
  mx?: string | number
}> = ({ propertyId, projectId, filteredNotes, mx }) => {
  const {
    notes: notesFromDb,
    noteTypes,
    mutate,
    deleteNote,
    toggleStar,
  } = useNoteParts({ propertyId, projectId })
  let allNotes: Note[] | undefined = undefined
  allNotes = filteredNotes ?? notesFromDb
  const { currentUserCanEdit } = useCollaboratorParts({
    propertyId: propertyId,
    projectId: projectId,
  })

  const { t } = useTranslation()
  const [sortType, setSortType] = useState(TagSortingOption.CREATED_DATE_DESC)
  const [accordionsToShow, setAccordionsToShow] = useState<string[]>([])
  const toggleAccordion = (id: string) => {
    setAccordionsToShow(_.xor(accordionsToShow, [id]))
  }
  const toast = useToast()

  const onDeleteHandler = (note: Note) => {
    if (!note) {
      return
    }
    deleteNote(note)
      .then(() => {
        log.debug('Successfully deleted note')
        mutate ? void mutate() : ''
        toast({
          title: t('notes.noteDeleteSuccessMessage', { name: note.name }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(err => {
        log.error({ err: err }, `Error deleting note ${note.id}`)
      })
  }

  const toggleStarHandlerLocal = async (note: Note) => {
    await toggleStar(note).then(() => {
      mutate ? void mutate() : ''
    })
  }

  const sortedNotes = calculateSortedItemsWrapper(
    sortType,
    allNotes,
    '',
  ) as Note[]

  return (
    <Flex pb={10} mt={4} mx={mx} px={0} justifyContent="space-around">
      <Table
        variant="unstyled"
        colorScheme="gray"
        data-cy="accounts-list"
        // w={{ xxs: '100%', laptop: '85vw', desktop: '86vw' }}
      >
        <Thead>
          <Tr>
            <Show above="laptop">
              <Th></Th>
            </Show>
            <Show above="laptop">
              <Th></Th>
            </Show>
            <Show above="tablet">
              <Th mx={0} px={0}>
                <SortableHeading
                  onAscSort={() => setSortType(NoteSortingOption.TIMESTAMP_ASC)}
                  onDescSort={() =>
                    setSortType(NoteSortingOption.TIMESTAMP_DESC)
                  }
                >
                  {t('notes.timestamp')}
                </SortableHeading>
              </Th>
              <Th>
                <SortableHeading
                  textAlign="left"
                  w={16}
                  onAscSort={() => setSortType(NoteSortingOption.NAME_ASC)}
                  onDescSort={() => setSortType(NoteSortingOption.NAME_DESC)}
                >
                  {t('general.name')}
                </SortableHeading>
              </Th>
              <Th textAlign="center">
                <Text fontStyle="h8">{t('notes.category')}</Text>
              </Th>
            </Show>
            <Show above="desktop">
              <Th>
                <SortableHeading
                  onAscSort={() =>
                    setSortType(NoteSortingOption.UPDATED_DATE_ASC)
                  }
                  onDescSort={() =>
                    setSortType(NoteSortingOption.UPDATED_DATE_DESC)
                  }
                >
                  {t('constructionGlossary.updatedAt')}
                </SortableHeading>
              </Th>
            </Show>

            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {sortedNotes && sortedNotes.length > 0 ? (
            sortedNotes.map((note, i) => {
              return (
                <Fragment key={note.id}>
                  <Tr
                    bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                    data-cy="accountTag"
                    h={4}
                  >
                    <Show above="laptop">
                      <Td
                        color="primary_orange02"
                        _hover={{ color: 'primary_orange01' }}
                        mx={0}
                        px={0}
                        w={{ laptop: 12 }}
                      >
                        {!accordionsToShow.includes(note.id) && (
                          <Icon
                            name={'AiOutlinePlusCircle'}
                            onClick={() => {
                              toggleAccordion(note.id)
                            }}
                          />
                        )}
                        {accordionsToShow.includes(note.id) && (
                          <Icon
                            name={'AiOutlineMinusCircle'}
                            onClick={() => {
                              toggleAccordion(note.id)
                            }}
                          />
                        )}
                      </Td>
                    </Show>
                    <Show above="laptop">
                      <Td maxW={10} w={{ laptop: 12 }}>
                        {currentUserCanEdit ? (
                          <StarIcon
                            h={24}
                            isStarred={note.starred}
                            onClick={() => {
                              void toggleStarHandlerLocal(note)
                            }}
                          />
                        ) : null}
                      </Td>
                    </Show>
                    <Td px={1} maxW={24} w={24} wordBreak="break-word">
                      <Center>
                        <Text textStyle="textMedium" maxW={24}>
                          {note.noteTimestamp
                            ? getDateStrFromNumber(note?.noteTimestamp)
                            : ''}
                        </Text>
                      </Center>
                    </Td>
                    <Td
                      wordBreak="break-word"
                      px={{ xxs: 2, tablet: 4, laptop: 4 }}
                    >
                      <Text
                        textStyle={{
                          xxs: 'textLargeRegular',
                          laptop: 'textMedium',
                        }}
                      >
                        {note.name}
                      </Text>
                    </Td>
                    <Td
                      textAlign="center"
                      px={{ xxs: 1, tablet: 2, laptop: 4 }}
                      maxW={{ xxs: 24, tablet: 32 }}
                      overflow="clip"
                    >
                      {note.noteTypeIds?.map(noteTypeId => {
                        return (
                          <Badge
                            key={noteTypeId}
                            py={0.1}
                            mt={1}
                            mx={0}
                            borderWidth={3}
                            borderColor="gray.300"
                            borderRadius="md"
                            fontWeight="light"
                            h={6}
                          >
                            {
                              noteTypes?.find(nt => {
                                return nt.id === noteTypeId
                              })?.name
                            }
                          </Badge>
                        )
                      })}
                    </Td>
                    <Show above="desktop">
                      <Td px={1} maxW={24} w={24} wordBreak="break-word">
                        <Center>
                          <Text textStyle="textMedium" maxW={24}>
                            {note.createdAt
                              ? getLatestDateStrFromNumber(
                                  note?.createdAt,
                                  note?.updatedAt,
                                )
                              : ''}
                          </Text>
                        </Center>
                      </Td>
                    </Show>
                    <Td justifyContent="space-around" px={0} w={{ tablet: 12 }}>
                      <Center>
                        <HStack
                          spacing={{ xxs: 0, laptop: 2 }}
                          mx={{ desktop: 2 }}
                        >
                          <NoteCardMediumButton
                            note={note}
                            propertyId={propertyId}
                            projectId={projectId}
                          >
                            <Center
                              borderRadius="lg"
                              borderColor="gray.300"
                              p={3}
                              _hover={{
                                background: 'gray.100',
                                opacity: '0.6',
                              }}
                            >
                              <BsEye />
                            </Center>
                          </NoteCardMediumButton>

                          <Show above="desktop">
                            {currentUserCanEdit ? (
                              <AlertDialog
                                title={t('notes.deleteNote')}
                                message={t('notes.deleteNoteQuestion', {
                                  name: note.name,
                                })}
                                buttonTitle={t('ui.button.delete')}
                                buttonW="100%"
                                onDestructiveAction={() =>
                                  onDeleteHandler(note)
                                }
                                variant="deleteIconButton"
                              />
                            ) : null}
                          </Show>
                        </HStack>
                      </Center>
                    </Td>
                  </Tr>
                  {accordionsToShow.includes(note.id) && (
                    <Tr
                      bg={i % 2 !== 0 ? 'white' : 'primary_grey01'}
                      borderTopWidth={1}
                    >
                      <Td colSpan={7}>
                        <NoteContentCard
                          note={note}
                          propertyId={propertyId}
                          projectId={projectId}
                        />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              )
            })
          ) : (
            <Tr key="none">
              <Td colSpan={9} textAlign="center">
                {propertyId
                  ? t('notes.noPropertyNotesFound')
                  : t('notes.noProjectNotesFound')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Flex>
  )
}

export default NotesListView
