import React, { ReactNode } from 'react'
import { Text } from '@chakra-ui/react'

const PromoUnderText: React.FC<{
  children: ReactNode
  textStyle?: string
  color?: string
  px?: number
  py?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  size?: 1 | 2 | 3 | 4 | 5 | 6
}> = ({ children, color, px, py, mt = 2, mr, mb, ml, textStyle }) => {
  return (
    <Text
      fontWeight="light"
      color={color}
      px={px}
      py={py}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      textStyle={textStyle}
    >
      {children}
    </Text>
  )
}

export default PromoUnderText
