import { useTranslation } from 'react-i18next'

export const getDateStrFromDate = (date?: Date | null) => {
  const usedDate = date ? new Date(date) : new Date()
  return usedDate.toLocaleDateString('sv-SE')
}

export const getDateStrFromNumber = (date?: number | null) => {
  const usedDate = date ? new Date(date) : new Date()
  return usedDate.toLocaleDateString('sv-SE')
}

export const getLatestDateStrFromNumber = (
  dateA?: number | null,
  dateB?: number | null,
) => {
  let usedDate: Date
  if (dateA && (!dateB || minutesDiff(dateA, dateB) > 0)) {
    usedDate = dateA ? new Date(dateA) : new Date()
  } else {
    usedDate = dateB ? new Date(dateB) : new Date()
  }
  return usedDate.toLocaleDateString('sv-SE')
}

export const getDateStrFromNumberIfExist = (date?: number | null) => {
  if (date) {
    return new Date(date).toLocaleDateString('sv-SE')
  }
  return undefined
}

export const getFriendlyShortDateStrFromNumber = (
  date?: number | null,
  variant: 'long' | 'short' = 'short',
) => {
  const usedDate = date ? new Date(date) : new Date()
  const { t } = useTranslation()

  let month: string
  if (variant === 'short') {
    month = t(
      `utils.months.${usedDate.toLocaleDateString('sv-SE', {
        month: 'short',
      })}Short`,
    )
  } else {
    month = t(
      `utils.months.${usedDate.toLocaleDateString('sv-SE', {
        month: 'long',
      })}Long`,
    )
  }
  const year =
    new Date().getFullYear() === usedDate.getFullYear()
      ? ''
      : usedDate.getFullYear()
  return `${new Date(usedDate).getDate()} ${month} ${year}`
}

export const getUpdatedDateString = (
  created: number,
  updated: number,
): string | undefined => {
  const hasBeenUpdated = Math.abs(
    new Date(updated).getMinutes() - new Date(created).getMinutes(),
  )
    ? true
    : false
  if (!hasBeenUpdated) {
    return undefined
  }
  return getFriendlyShortDateStrFromNumber(updated)
}

export const hasBeenUpdated = (created: number, updated: number): boolean => {
  if (
    Math.abs(new Date(updated).getMinutes() - new Date(created).getMinutes())
  ) {
    return true
  }
  return false
  //   ? return true
  //   : false
  // if (!hasBeenUpdated) {
  //   return undefined
  // }
  // return getFriendlyShortDateStrFromNumber(updated)
}

export const getUserFriendlyDateStrFromNumber = (date: number): string => {
  if (isToday(date)) {
    return `Idag ${getTimeStrFromNumber(date)}`
  }
  return getDateStrFromNumber(date)
}

export const getSmartDateAndTimeString = (
  created: number,
  updated: number,
  changeStr: string,
): string => {
  if (hasBeenUpdated(created, updated)) {
    return `${changeStr} 
    ${isToday(updated) ? '' : getFriendlyShortDateStrFromNumber(updated)} ${
      isCurrentYear(updated) ? '' : new Date(updated).getFullYear()
    } ${getTimeStrFromNumber(updated)}`
  }
  return getTimeStrFromNumber(created)
}

export const getSmartDateAndTimeStringIfChanged = (
  created: number,
  updated: number,
  changeStr: string,
): string => {
  if (hasBeenUpdated(created, updated)) {
    return `${changeStr} 
    ${isToday(updated) ? '' : getFriendlyShortDateStrFromNumber(updated)} ${
      isCurrentYear(updated) ? getTimeStrFromNumber(updated) : ''
    }`
  }
  return ''
}

export const getTimeStrFromNumber = (date?: number | null) => {
  const usedDate = date ? new Date(date) : new Date()
  return usedDate.toLocaleTimeString('sv-SE', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const getLastLogin = (date?: number | null) => {
  const usedDate = date ? new Date(date) : new Date()

  if (isToday(date ?? usedDate.getTime())) {
    return usedDate.toLocaleTimeString('sv-SE', {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return `${usedDate.toLocaleDateString('sv-SE')} ${usedDate.toLocaleTimeString(
    'sv-SE',
    {
      hour: '2-digit',
      minute: '2-digit',
    },
  )}`
}

export const minutesDiff = (dateA: number, dateB: number) => {
  return (new Date(dateA).getTime() - new Date(dateB).getTime()) / (1000 * 60)
}

export const getMbFromBytes = (bytes: number, decimals = 3) => {
  return (bytes / (1024 * 1024)).toFixed(decimals)
}

export const isToday = (date: number): boolean => {
  if (new Date(date).toDateString() === new Date(Date.now()).toDateString()) {
    return true
  }
  return false
}

export const isCurrentYear = (date: number): boolean => {
  if (new Date(date).getFullYear() === new Date(Date.now()).getFullYear()) {
    return true
  }
  return false
}

export const isOutOfDateOrInvalid = (date: number | undefined): boolean => {
  if (!date) {
    return true
  }
  return new Date(date).getTime() > Date.now() ? false : true
}

export const isOutOfDate = (date: number): boolean => {
  return new Date(date).getTime() > Date.now() ? false : true
}
