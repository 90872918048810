import {
  Button,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'

import { PropertyResponse } from '@contracts/misc'
import { UpdatePropertyRequest } from '@contracts/properties'
import log from '../../common/log'
import { PATH_MY_PRIVATE_PAGES } from '../../common/nav'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import Spinner from '../Spinner'
import {
  updateProperty,
  useProperties,
  useProperty,
  usePropertyTypes,
  deleteProperty,
} from '../../apiClients/propertiesApiClient/properties'
import { useCollaboratorParts } from '../collaborators/collaboratorHelper'

interface MyFormValues {
  propertyName: string
  street: string
  zipCode: string
  city: string
  propertyTypeId: string
}

interface Errors {
  propertyName?: string
  street?: string
  zipCode?: string
  city?: string
  propertyTypeId?: string
}

const EditProperty: React.FC<{
  property: PropertyResponse
}> = ({ property }) => {
  const { mutate: mutateAllProperties } = useProperties()
  const { t } = useTranslation()
  const { data: allPropertyTypes } = usePropertyTypes()
  const { mutate: mutateThisProperty } = useProperty(property.id)
  const navigate = useNavigate()
  const toast = useToast()
  const { currentUserCollaboratorRelation, currentUserCanEdit } =
    useCollaboratorParts({
      propertyId: property.id,
    })

  const initialValues: MyFormValues = {
    propertyName: property.name,
    street: property.address?.street ?? '',
    zipCode: property.address?.zipCode ?? '',
    city: property.address?.city ?? '',
    propertyTypeId: property.propertyTypeId,
  }

  const validate = (values: MyFormValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.propertyName,
      t('properties.propertyForm.name'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.propertyName = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.street,
      t('properties.propertyForm.street'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.street = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.zipCode,
      t('properties.propertyForm.zipCode'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.zipCode = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.city,
      t('properties.propertyForm.city'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.city = tmpRes.errorMsg
    }
    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const requestData: UpdatePropertyRequest = {
        id: property.id,
        name: values.propertyName,
        propertyTypeId: values.propertyTypeId,
        address: {
          street: values.street,
          zipCode: values.zipCode,
          city: values.city,
        },
      }

      updateProperty(property.id, requestData)
        .then(() => {
          log.info('Successfully updated property')
          void mutateAllProperties()
          void mutateThisProperty()
          toast({
            title: t('properties.updateSuccessMessage', {
              name: values.propertyName,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(e => {
          log.error('Failed to update property', e)
          toast({
            title: t('properties.updateErrorMessage', {
              name: values.propertyName,
            }),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const onDeleteProperty = () => {
    deleteProperty(property.id)
      .then(() => {
        void mutateAllProperties()
        toast({
          title: t('projects.deleteSuccessMessage', {
            name: property.name,
          }),
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
        navigate(PATH_MY_PRIVATE_PAGES)
      })
      .catch((err: AxiosError<{ error?: string }>) => {
        log.error('Failed to delete project', err)
        toast({
          title: t(
            `properties.${
              err.response?.data.error === 'PROJECTS_EXIST_ON_PROPERTY'
                ? 'deleteFailureMessageProjectsOnProperty'
                : err.response?.data.error === 'NOTES_EXIST_ON_PROPERTY'
                ? 'deleteFailureMessageNotesOnProperty'
                : 'deleteFailureMessage'
            }`,
            {
              name: property.name,
            },
          ),
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  return (
    <form onSubmit={handleSubmit} id="edit-property-form">
      {!currentUserCanEdit ? (
        <Text mt="1rem" fontStyle="infoText">
          {t('properties.propertyForm.canNotEditProperty')}
        </Text>
      ) : null}
      <Flex
        display="flex"
        flexDirection="column"
        my={6}
        maxW="md"
        mb={{ xxs: 32, tablet: 0 }}
      >
        <Input
          title={t('properties.propertyForm.name')}
          name="propertyName"
          type="text"
          isMandatory={true}
          isError={!!errors.propertyName}
          errorText={
            touched.propertyName && errors.propertyName
              ? errors.propertyName
              : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          tooltip={t('properties.propertyForm.nameTooltip')}
          variant="plain"
          defaultValue={property.name}
          mb={4}
          disabled={isLoading || !currentUserCanEdit}
        />
        <Input
          title={t('properties.propertyForm.street')}
          name="street"
          type="text"
          isMandatory={true}
          isError={!!errors.street}
          errorText={touched.street && errors.street ? errors.street : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={property?.address?.street}
          mb={4}
          disabled={isLoading || !currentUserCanEdit}
        />
        <Input
          title={t('properties.propertyForm.zipCode')}
          name="zipCode"
          isMandatory={true}
          type="text"
          isError={!!errors.zipCode}
          errorText={touched.zipCode && errors.zipCode ? errors.zipCode : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={property?.address?.zipCode}
          mb={4}
          disabled={isLoading || !currentUserCanEdit}
        />
        <Input
          title={t('properties.propertyForm.city')}
          name="city"
          isMandatory={true}
          type="text"
          isError={!!errors.city}
          errorText={touched.city && errors.city ? errors.city : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={property?.address?.city}
          mb={1}
          disabled={isLoading || !currentUserCanEdit}
        />
        <Text ml="1rem" textStyle="infoSmall">
          *{t('input.mandatoryFields')}
        </Text>

        {allPropertyTypes ? (
          <RadioGroup defaultValue={property.propertyTypeId}>
            <Text mt="1rem" fontStyle="infoText">
              {t('properties.propertyForm.propertyType')}
            </Text>
            <Flex flexDir="row" wrap="wrap" textStyle="infoTextLight">
              {allPropertyTypes?.map(p => {
                return (
                  <Radio
                    key={p.id}
                    colorScheme="blue"
                    borderColor="gray.400"
                    name="propertyTypeId"
                    value={p.id}
                    w={44}
                    paddingY={2}
                    paddingX={4}
                    onChange={handleChange}
                  >
                    {p.name}
                  </Radio>
                )
              })}
            </Flex>
          </RadioGroup>
        ) : (
          <Spinner />
        )}

        <HStack>
          <Tooltip
            isDisabled={currentUserCanEdit}
            label={t('properties.propertyForm.canNotEditProperty')}
          >
            <Button
              type="submit"
              my={4}
              width="80%"
              variant={
                Object.keys(errors).length !== 0 ? 'disabled' : 'primary'
              }
              disabled={isLoading || !currentUserCanEdit}
            >
              {t('auth.update')}
            </Button>
          </Tooltip>

          {currentUserCollaboratorRelation === 'Owner' ? (
            <AlertDialogAndButton
              title={t('properties.deleteProperty')}
              message={t('properties.deletePropertyQuestion', {
                name: property.name,
              })}
              buttonTitle={t('ui.button.delete')}
              buttonW="100"
              variant="deleteIconButton"
              onDestructiveAction={onDeleteProperty}
            />
          ) : null}
        </HStack>
      </Flex>

      {isLoading && <Spinner />}
    </form>
  )
}
export default EditProperty
