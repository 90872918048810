import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { AccountData } from '@contracts/misc'
import { useOrganizationByAccount } from '../../apiClients/organizationsApiClient'
import { PATH_ADMIN_ORGANIZATION_LIST } from '../../common/nav'
import { Link } from '../../ui'

const ProviderOverviewCardAdmin: React.FC<{ providerAccount: AccountData }> = ({
  providerAccount,
}) => {
  const { data: organization } = useOrganizationByAccount(providerAccount.id)

  return (
    <Flex flexDir="row">
      <Text mr={4}>Bolagsnamn:</Text>
      {organization && (
        <Link
          to={`${PATH_ADMIN_ORGANIZATION_LIST}/?foretag=${organization.id}`}
        >
          {organization?.name}
          <ExternalLinkIcon ml={2} />
        </Link>
      )}
      {!organization && <Text>No organisation found</Text>}
    </Flex>
  )
}
export default ProviderOverviewCardAdmin
