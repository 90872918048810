import { Flex, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import Sorter from './Sorter'

const SortableHeading: React.FC<{
  onAscSort?: () => void
  onDescSort?: () => void
  children: ReactElement | string
  textAlign?: string
  w?: string | number
  variant?: 'space-betwwen' | 'center'
  px?: string | number
}> = ({
  onAscSort,
  onDescSort,
  children,
  textAlign = 'left',
  w,
  px = 0,
  variant = 'space-betwwen',
}) => {
  return (
    <Flex
      // border="1px solid red"
      flexDir="row"
      alignItems="center"
      textAlign={textAlign === 'center' ? 'center' : 'left'}
      w={w}
      px={px}
      gap={3}
    >
      <Text textStyle="h9" w={variant === 'space-betwwen' ? '100%' : ''}>
        {children}
      </Text>

      <Sorter onAscSort={onAscSort} onDescSort={onDescSort} />
    </Flex>
  )
}
export default SortableHeading
