import { Avatar, AvatarBadge, Flex, Text } from '@chakra-ui/react'
import { useAccount } from '../../apiClients/accountsApiClient'
import { useOrganizationByAccount } from '../../apiClients/organizationsApiClient'
import { useImageUrl } from '../attachments/attachmentHelper'
import AccountProfileCardModal from './AccountProfileCardModal'

const AccountAvatar: React.FC<{
  accountId: string
  variant:
    | 'avatar'
    | 'name'
    | 'avatarAndName'
    | 'full'
    | 'avatarAndNameOneRow'
    | 'contacts'
  mr?: number | string
  mt?: number | string
  mb?: number | string
  mx?: number | string
  my?: number | string
  w?: number | string
  py?: number
  color?: string
  lightFont?: boolean
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg'
}> = ({
  accountId,
  variant,
  mr,
  mt,
  mb,
  mx,
  my,
  w,
  color,
  py,
  lightFont,
  avatarSize,
}) => {
  const { data: account } = useAccount(accountId)
  const imgUrl = useImageUrl(account?.profileAttachmentId, undefined)
  const name = account?.firstName
    ? `${account?.firstName} ${account?.lastName}`
    : account?.email
  const badgeSize = '.9rem'

  const isProvider = account?.accountTypes?.includes('Provider') ?? false
  const { data: organization } = useOrganizationByAccount(
    isProvider ? account?.id : undefined,
  )

  switch (variant) {
    case 'avatarAndName':
      return (
        <AccountProfileCardModal
          accountId={accountId}
          organizationId={organization?.id}
          // variant={isProvider ? 'organizationAccount' : 'userAccount'}
          variant="userAccount"
        >
          <Flex
            flexDir="row"
            justifyContent="left"
            mr={mr}
            mt={mt}
            mb={mb}
            mx={mx}
            my={my}
            color={color}
            w={w}
          >
            <Avatar name={name} src={imgUrl} size={avatarSize}>
              {isProvider && (
                <AvatarBadge
                  borderColor="papayawhip"
                  bg="primary"
                  boxSize={badgeSize}
                />
              )}
            </Avatar>
            <Flex flexDir="column" textStyle={lightFont ? 'regularLight' : ''}>
              <Text ml={1.5} pt={1} fontStyle={!account ? 'italic' : ''}>
                {name}
              </Text>
              <Text textStyle="xsLight" ml={1.5}>
                {isProvider && organization?.name}
              </Text>
            </Flex>
          </Flex>
        </AccountProfileCardModal>
      )
    case 'avatar':
      return (
        <AccountProfileCardModal
          accountId={account?.id}
          organizationId={organization?.id}
          // variant={isProvider ? 'organizationAccount' : 'userAccount'}
          variant="userAccount"
        >
          <Avatar
            name={name}
            src={imgUrl}
            // size="sm"
            mr={mr}
            mx={mx}
            my={my}
            py={py}
            size={avatarSize ?? 'sm'}
          >
            {isProvider && (
              <AvatarBadge
                borderColor="papayawhip"
                bg="primary"
                boxSize={badgeSize}
              />
            )}
          </Avatar>
        </AccountProfileCardModal>
      )
    case 'avatarAndNameOneRow':
      return (
        <Flex columnGap={4}>
          <Avatar
            name={name}
            src={imgUrl}
            // size="xs"
            mr={mr}
            mx={mx}
            my={my}
            size={avatarSize ?? 'xs'}
          >
            {isProvider && (
              <AvatarBadge borderColor="papayawhip" bg="primary" boxSize={2} />
            )}
          </Avatar>
          <Text>{name}</Text>
        </Flex>
      )
    case 'full':
      return (
        <Flex wrap="nowrap" columnGap={4} color={color} w={w}>
          <Avatar
            name={name}
            src={imgUrl}
            // size="lg"
            mr={mr}
            mx={mx}
            size={avatarSize ?? 'lg'}
          />

          <Flex flexDir="column" wrap="wrap">
            {/* <Text fontSize="18px" fontWeight="normal" my={my} h="22px"> */}
            {/* <Text my={my} h="22px"> */}
            <Text my={my}>{name}</Text>
            <Text fontWeight="light" wordBreak="break-word">
              {account?.email}
            </Text>
            <Text fontWeight="light">{account?.phoneNumber ?? ''}</Text>
          </Flex>
        </Flex>
      )
    case 'contacts':
      return (
        <Flex flexDir="column" wrap="wrap">
          <Text my={my} textStyle="infoTextLight">
            {name}
          </Text>
          <Text fontWeight="light">{account?.phoneNumber ?? ''}</Text>
          <Text fontWeight="light" wordBreak="break-word">
            {account?.email}
          </Text>
        </Flex>
      )

    case 'name':
      return <Text>{name}</Text>
  }
}
export default AccountAvatar
