import { Flex, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountRelation } from '@contracts/misc'
import { InviteResponse } from '@contracts/accounts'
import { KeyedMutator } from 'swr'
import AuthContext from '../../common/auth-context'
import { Icon } from '../ui/Icon'
;('../../apiClients/accountsApiClient')
import {
  deleteInvite,
  useAccountInvites,
} from '../../apiClients/accountsApiClient'
import log from '../../common/log'

const InviteCard: React.FC<{
  invite: InviteResponse
  area: 'Property' | 'Project' | 'Organization'
  areaId: string
  propertyId?: string
  projectId?: string
  orgId?: string
  currentUserAccessRights: AccountRelation
  // isOnlyAdmin: boolean
  mutate?: KeyedMutator<InviteResponse[] | undefined>
}> = ({
  invite,
  area,
  areaId,
  // propertyId,
  // projectId,
  // orgId,
  currentUserAccessRights,
  // isOnlyAdmin,
  mutate,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useContext(AuthContext)
  // const area: 'Property' | 'Project' = propertyId ? 'Property' : 'Project'
  // const areaId = propertyId ? propertyId : projectId
  const { mutate: mutateLocal } = useAccountInvites(area, areaId)

  // let isAllowedToDelete = false
  const isAllowedToDelete = currentUserAccessRights === 'Owner' ? true : false
  let tooltipText = ''
  const vSpacing = 4

  if (
    currentUserAccessRights === 'Owner' &&
    currentUser?.status === 'Verified'
  ) {
    // if (!isOnlyAdmin) {
    //   isAllowedToDelete = true
    // } else {
    //   tooltipText = t('accounts.notAllowedToDeleteOnlyAdminTooltip')
    // }
  } else {
    if (
      currentUserAccessRights !== 'Owner' ||
      currentUser?.status !== 'Verified'
    ) {
      tooltipText = t('accounts.notAllowedToEditTooltip')
    }
  }
  return (
    <Flex
      flexDir="row"
      borderRadius={8}
      flexWrap="wrap"
      justifyContent="space-around"
      alignItems="center"
    >
      <Text mb={vSpacing}>{t('properties.collaboratorNotRegistered')}</Text>

      <Flex flexDir="row" ml={{ xxs: 4, laptop: 20 }}>
        <Icon
          name="DeleteIcon"
          color="secondary"
          h={5}
          ml={3}
          mt={0.5}
          _hover={{ color: 'gray' }}
          onClick={() => {
            deleteInvite(invite.id)
              .then(() => {
                void mutateLocal()
                mutate ? void mutate() : ''
              })
              .catch(e => {
                log.error({ err: e }, 'Failed to delete invite')
              })
          }}
          isDisabled={!isAllowedToDelete}
          tooltip={isAllowedToDelete ? '' : tooltipText}
        />
      </Flex>
    </Flex>
  )
}
export default InviteCard
