import { Badge, ResponsiveValue } from '@chakra-ui/react'
import { ServiceType } from '@contracts/accounts'
import { useTranslation } from 'react-i18next'
import { useServiceTypes } from '../../apiClients/organizationsApiClient'

const ServiceTypeBadge: React.FC<{
  serviceTypeProp?: ServiceType
  serviceTypeId?: string
  w?: ResponsiveValue<string | number>
  fontSize?: ResponsiveValue<number>
}> = ({ w, serviceTypeProp, serviceTypeId, fontSize }) => {
  const { t } = useTranslation()
  const { data: allServiceTypes } = useServiceTypes()
  let serviceType: ServiceType | undefined
  if (serviceTypeProp) {
    serviceType = serviceTypeProp
  } else {
    serviceType = allServiceTypes?.find(st => {
      return st.id === serviceTypeId
    })
  }

  let title = ''
  let colorScheme
  let bg
  let color

  if (!serviceType) {
    title = t(`projectPlaces.phase.${serviceType ?? 'noData'}`)
  } else {
    title = serviceType.name
    switch (serviceType.consultant) {
      case false:
        // color = 'logo_petroleum01'
        // bg = '#CAEBEA'
        // color = 'primary_orange03'
        // bg = '#E9C799'
        color = 'white'
        bg = 'primary_orange01'

        break
      case true:
        color = 'white'
        bg = 'logo_green02'
        // color = 'logo_green01'
        // bg = 'primary_green01'
        break
      default:
        // colorScheme = 'red'
        bg = 'logo_purple'
        color = 'white'
    }
  }
  return (
    <Badge
      w={w}
      colorScheme={colorScheme}
      bg={bg}
      color={color}
      borderRadius="full"
      px={2}
      fontSize={fontSize}
    >
      {title}
    </Badge>
  )
}
export default ServiceTypeBadge
