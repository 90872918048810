import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useProjectsByPropertyAdmin } from '../../apiClients/projectsApiClient/projects'
import { PATH_ADMIN_PROJECTS } from '../../common/nav'
import TextCard from '../ui/TextCard'
import ProjectAvatar from './ProjectAvatar'

const ProjectsByPropertyCard: React.FC<{
  propertyName: string
  propertyId: string
  accountId?: string
  mr?: number
  mt?: number
  headerBg?: string
  w?: number | string
}> = ({ propertyName, propertyId, mr, mt, headerBg, w, accountId }) => {
  const { t } = useTranslation()
  const { data: projects } = useProjectsByPropertyAdmin(propertyId)

  return (
    <TextCard
      heading={`${t('admin.projectInHome')} "${propertyName}" (${
        projects?.length ?? ''
      })`}
      mr={mr}
      mt={mt}
      variant="border"
      headerBg={headerBg}
      maxW={w}
      px={6}
      py={2}
    >
      <Flex flexDir="row" wrap="wrap" gap={2} alignItems="flex-start" mt={4}>
        {projects &&
          projects.length > 0 &&
          projects.map(project => {
            return (
              <ProjectAvatar
                projectId={project.id}
                accountId={accountId}
                variant="avatarAndName"
                link={`${PATH_ADMIN_PROJECTS}/?projekt=${project.id}`}
                w="md"
              />
            )
          })}
        {projects && projects.length === 0 && (
          <Text>{t('admin.noProjects')}</Text>
        )}
      </Flex>
    </TextCard>
  )
}
export default ProjectsByPropertyCard
