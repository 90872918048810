import { VStack, Text } from '@chakra-ui/react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
const Sorter: React.FC<{
  onAscSort?: () => void
  onDescSort?: () => void
}> = ({ onAscSort, onDescSort }) => {
  return (
    <VStack p={0} m={0} color="gray.500" spacing={0}>
      {onDescSort && (
        <Text
          _hover={{
            fontWeight: '900',
            color: 'black',
            cursor: 'pointer',
          }}
          onClick={onDescSort}
        >
          <FiChevronUp fontSize={16} />
        </Text>
      )}
      {onAscSort && (
        <Text
          _hover={{
            fontWeight: '900',
            color: 'black',
            cursor: 'pointer',
          }}
          onClick={onAscSort}
        >
          <FiChevronDown fontSize={16} />
        </Text>
      )}
    </VStack>
  )
}
export default Sorter
