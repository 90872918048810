import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAttachment } from '../../apiClients/attachmentsApiClient'
import AlertDialogAndButton from '../ui/AlertDialogAndButton'
import FileIcon from './FileIcon'

const EditAttachmentCardCompact: React.FC<{
  mimeType: string

  attachmentId?: string
  minW?: number | string
  maxW?: number | string

  onChangeAttachmentName: (
    attachmentId: string,
    newFileName: string,
    mimeType: string,
  ) => Promise<void>
  onDeleteAttachments: (attachmentIds: string[]) => void
  changeNameIsDisabled?: boolean
}> = ({
  mimeType,

  attachmentId,

  minW,
  maxW,

  onChangeAttachmentName,
  onDeleteAttachments,
  changeNameIsDisabled = false,
}) => {
  const { data: attachment } = attachmentId
    ? useAttachment(attachmentId)
    : { data: undefined }

  const [fileName, setFileName] = useState(attachment?.originalFilename)

  useEffect(() => {
    setFileName(attachment?.originalFilename)
  }, [attachment])

  const fileNameChangedHandler = (newFileName: string) => {
    if (attachmentId) {
      if (newFileName !== attachment?.originalFilename) {
        void onChangeAttachmentName(attachmentId, newFileName, mimeType)
      }
    }
  }

  if (!attachment) {
    return null
  }

  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      minW={minW}
      maxW={maxW}
      _hover={{ color: 'gray.500' }}
      w="100%"
      // border="1px solid red"
    >
      <>
        <Flex flexDir="row" alignItems="center" gap={2}>
          <FileIcon attachment={attachment} />
          {changeNameIsDisabled && <Text textStyle="cardText">{fileName}</Text>}
          {!changeNameIsDisabled && (
            <Editable
              textStyle="cardText"
              ml={2}
              value={fileName}
              submitOnBlur={true}
              onSubmit={fileNameChangedHandler}
              // h={7}
              borderRadius="lg"
              border="1px solid"
              borderColor="gray.100"
              overflow="clip"
              wordBreak="break-all"
              pr={2}
              pl={2}
              w={{ tablet: '355px' }}
            >
              <EditablePreview
                _hover={{ cursor: 'pointer' }}
                overflow="clip"
                w={{ tablet: '335px' }}
              />
              <EditableInput
                onChange={e => {
                  setFileName(e.currentTarget.value)
                }}
                border="1px solid"
                borderColor="gray.200"
                bg="gray.100"
                w="100%"
                minW={{ mobile: 64, tablet: 'sm' }}
              />
            </Editable>
          )}
        </Flex>
        <AlertDialogAndButton
          title="Radera bifogad fil"
          message="Är du säker på att du vill radera bifogad fil?"
          onDestructiveAction={() => {
            onDeleteAttachments([attachment.id])
          }}
          buttonTitle={''}
          variant="deleteIcon"
        />
        {/* <DeleteIcon
          boxSize={4}
          color="gray.500"
          _hover={{ color: 'gray.400' }}
          onClick={() => {
            onDeleteAttachments([attachment.id])
          }}
        /> */}
      </>
    </Flex>
  )
}
export default EditAttachmentCardCompact
