import { Flex, Image, ResponsiveValue, useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import PromoCardSimple from '../../ui/PromoCardSimple'
import ProjectFrontCard from '../../projects/ProjectFrontCard'

import noProjectsPromo from '../../../img/projects/noProjectsPromo.png'
import { useProjects } from '../../../apiClients/projectsApiClient/projects'
import { Heading } from '../../../ui'
import Divider from '../../ui/Divider'
import AddProjectButton from '../../ui/AddProjectButton'

const MyAccountBasedProjectsSection: React.FC<{
  px?: ResponsiveValue<number | string>
  mb?: number | string
  pt?: number | string
  variant?: 'classic-heading' | 'modern-heading'
}> = ({ px, mb, pt, variant = 'classic-heading' }) => {
  const { t } = useTranslation()
  const [isTabletOrSmaller] = useMediaQuery('(max-width: 768px)')
  const { data: projectsForAccount } = useProjects()
  const [viewOption, setViewOption] = useState<'compact' | 'micro' | 'full'>(
    isTabletOrSmaller ? 'micro' : 'full',
  )

  useEffect(() => {
    if (isTabletOrSmaller) {
      // setViewOption('micro')
      setViewOption('full')
    } else {
      setViewOption('full')
    }
  }, [isTabletOrSmaller])

  return (
    <>
      <Flex flexDir="column" w="100%" pt={pt} px={px} mt={8}>
        {variant === 'classic-heading' && (
          <>
            <Flex alignItems="center" mt={4} gap={4}>
              <Heading textStyle="h8">{t('general.myActiveProjects')}</Heading>
              <AddProjectButton properties={[]} variant={'ICON'} />
            </Flex>
            <Divider mt={1} mb={2} />
          </>
        )}
        {variant === 'modern-heading' && (
          <Heading textStyle="h6Fixed" color="logo_green01" isBold>
            {t('general.myActiveProjects')}
          </Heading>
        )}
      </Flex>
      <Flex flexDir="column" mx={px} mb={mb}>
        <Flex
          pos="relative"
          flexDir="row"
          justifyContent="left"
          gap={{ xxs: 4, laptop: 8 }}
          mt={2}
          wrap="wrap"
        >
          {projectsForAccount &&
            projectsForAccount.map((project, i) => {
              const bg = i % 2 !== 0 ? 'primary_grey04' : 'primary_grey02'
              const color = i % 2 !== 0 ? 'white' : 'black'

              return (
                <ProjectFrontCard
                  projectId={project.id}
                  key={project.id}
                  variant={viewOption}
                  bg={bg}
                  color={color}
                />
              )
            })}

          {!projectsForAccount?.length && (
            <PromoCardSimple
              bg="transparent"
              variant={isTabletOrSmaller ? 'wide' : 'full'}
              noBorder
              mb={4}
            >
              <Image src={noProjectsPromo} />
            </PromoCardSimple>
          )}
        </Flex>
      </Flex>
    </>
  )
}
export default MyAccountBasedProjectsSection
