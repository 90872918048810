import { filter, orderBy } from 'lodash'

import { ProjectPlaceResponse } from '@contracts/projects'
import { ProjectPlaceSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: ProjectPlaceSortingOptionType,
  projectPlaces?: ProjectPlaceResponse[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption

  const filteredProjectPlaces = searchStr
    ? filter(projectPlaces, ({ name, street, city }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return (
          name.toLowerCase().includes(searchStrLowercase) ||
          street.toLowerCase().includes(searchStrLowercase) ||
          city.toLowerCase().includes(searchStrLowercase)
        )
      })
    : projectPlaces

  return orderBy(filteredProjectPlaces, sortingField, sortOrder)
}
