import { Box, Button, useDisclosure } from '@chakra-ui/react'
import { ContextArea } from '@contracts/support'
import { ReactElement, useEffect } from 'react'
import { useFilePicker } from 'use-file-picker'
import { IconName } from '../../common/icon-utils'
import {
  IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
  IMG_PICKER_MAX_FILESIZE,
  // IMG_PICKER_MAX_NO_FILES,
  // IMG_PICKER_MIN_NO_FILES,
} from '../../pages/constants'
import ImgPicker from '../attachments/ImgPicker'
import { Icon } from './Icon'
import Modal from './Modal'

const ImgPickerButton: React.FC<{
  variant: 'ICON' | 'BUTTON' | 'GHOST-ICON'
  mt?: number
  mb?: number
  ml?: number
  w?: number | string
  iconName?: IconName
  iconColor?: string
  children?: ReactElement | string
  h?: number
  label?: string
  transform?: string
  imageId?: string
  imgChangeHandler: (inputProp: string, inputValue: string) => void
  inputCallbackName?: string
  // ContextArea: 'Article' | 'Account' | 'Property' | 'Project' | 'Note'
  ContextArea: ContextArea
  attachmentPrefix?: string
  pickerVariant?: 'LIGHT' | 'MEDIUM' | 'FULL' | 'NO-DIALOG'
  pos?: string
}> = ({
  mt,
  mb,
  ml,
  children,
  w,
  h,
  iconName = 'FiCamera',
  iconColor = 'primary_orange02',
  label,
  variant,
  imgChangeHandler,
  inputCallbackName,
  ContextArea,
  attachmentPrefix,
  pickerVariant = 'MEDIUM',
  transform,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openFileSelector, { plainFiles }] = useFilePicker({
    multiple: true,
    readAs: 'DataURL',
    accept: IMG_PICKER_ALLOWED_IMG_FILE_TYPES,
    // limitFilesConfig: {
    //   min: IMG_PICKER_MIN_NO_FILES,
    //   max: IMG_PICKER_MAX_NO_FILES,
    // },
    maxFileSize: IMG_PICKER_MAX_FILESIZE,
  })

  useEffect(() => {
    if (plainFiles.length > 0) {
      updateImgHandler()
    }
  }, [plainFiles])

  const updateImgHandler = () => {
    const formData = new FormData()
    formData.set('image', plainFiles[0])
    formData.set('area', 'Property')
  }

  const onClickHandler = () => {
    if (pickerVariant === 'NO-DIALOG') {
      openFileSelector()
    } else {
      onOpen()
    }
  }

  const getUIComponent = () => {
    if (variant === 'ICON' || variant === 'GHOST-ICON') {
      return (
        <Box
          bg={variant === 'ICON' ? 'white' : 'whiteAlpha.500'}
          w={10}
          h={10}
          border="1px"
          borderRadius="full"
          borderColor="gray.500"
          p="7px"
          // zIndex="tooltip"
          transform={transform}
        >
          <Icon
            name={iconName}
            _hover={{
              color: variant === 'ICON' ? 'primary_orange02' : 'gray.500',
            }}
            color={variant === 'ICON' ? iconColor : 'gray.800'}
            onClick={onClickHandler}
            ml={ml}
            h={24}
          />
        </Box>
      )
    }
    return (
      <Button
        onClick={onClickHandler}
        mt={mt}
        mb={mb}
        variant="primary"
        w={w || 32}
        h={h}
        transform={transform}
      >
        {children}
        {label}
      </Button>
    )
  }
  return (
    <>
      {getUIComponent()}
      <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} size="lg">
        <ImgPicker
          onCloseModal={onClose}
          imgChangeHandler={imgChangeHandler}
          inputCallbackName={inputCallbackName}
          ContextArea={ContextArea}
          attachmentPrefix={attachmentPrefix}
          variant={pickerVariant}
        />
      </Modal>
    </>
  )
}
export default ImgPickerButton
