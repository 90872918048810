import { Box, Button } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { validateString } from '../../common/auth-utils'
import log from '../../common/log'
import { addTag, useTags } from '../../apiClients/articlesApiClient'

import Input from '../ui/Input'

const MAX_LENGTH_INPUT = 20
interface AddTagValues {
  name: string
  code: string
}
interface Errors {
  name?: string
  code?: string
}

const CreateTag: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}) => {
  const { t } = useTranslation()
  const { mutate } = useTags()

  const initialValues = {
    name: '',
    code: '',
  }
  const validate = (values: AddTagValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.name,
      t('tags.name'),
      2,
      MAX_LENGTH_INPUT,
      true,
    )
    if (!tmpRes.isValid) {
      errors.name = tmpRes.errorMsg
    }
    tmpRes = validateString(values.code, t('tags.code'), 2, MAX_LENGTH_INPUT)
    if (!tmpRes.isValid) {
      errors.code = tmpRes.errorMsg
    }
    return errors
  }
  const { handleSubmit, handleChange, handleBlur, touched, errors } = useFormik(
    {
      initialValues: initialValues,
      validate,
      onSubmit: (values, { setSubmitting }) => {
        addTag(values.name, values.code)
          .then(() => {
            log.info('Successfully created new tag')
          })
          .catch(e => {
            log.error({ err: e }, 'Failed to create new note')
          })
          .finally(() => {
            onCloseModal()
            void mutate()
            setSubmitting(false)
          })
      },
    },
  )

  return (
    <form data-cy="add-tag" onSubmit={handleSubmit}>
      <Box
        h="4rem"
        display="flex"
        borderBottom="1px solid"
        borderColor="inputBorder"
        justifyContent="center"
        alignItems="center"
      >
        <Box textStyle="h5">{t('tags.addTag')}</Box>
      </Box>

      <Box px={4} mt={6}>
        <Input
          title={t('tags.name')}
          name="name"
          type="text"
          isMandatory={true}
          isError={!!errors.name}
          errorText={touched.name && errors.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          position="top"
        />
        <Input
          title={t('tags.code')}
          name="code"
          type="text"
          isMandatory={true}
          isError={!!errors.code}
          errorText={touched.code && errors.code ? errors.code : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          position="bottom"
        />

        <Button type="submit" mt={6} mb={4} variant="primary" w="100%">
          {t('input.submitForm')}
        </Button>
      </Box>
    </form>
  )
}
export default CreateTag
