import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import Divider from './Divider'
import Heading from './Heading'

const ProfileSectionCard: React.FC<{
  title: string
  children: ReactElement
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  w?: number | string
  maxW?: number | string
  isFilledIn?: boolean
  hideFilledInIcon?: boolean
}> = ({
  title,
  children,
  mt,
  mr,
  mb,
  ml,
  maxW = 'container.lg',
  w,
  isFilledIn = false,
  hideFilledInIcon = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setIsExpanded(!isFilledIn)
  }, [isFilledIn])

  const toggleAccordian = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Flex
      flexDir="column"
      borderRadius="lg"
      bg="white"
      boxShadow="lg"
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      w={w}
      maxW={maxW}
    >
      <Box mx={{ xxs: 2, tablet: 6 }}>
        <Flex
          flexDir="row"
          mt={isExpanded ? 2 : 0}
          py={isExpanded ? 0 : 2}
          justifyContent="space-between"
          alignItems="center"
          _hover={{ cursor: 'pointer' }}
        >
          <Flex
            flexDir="row"
            alignItems="center"
            onClick={toggleAccordian}
            w="100%"
          >
            <Heading mr={2} textStyle="h6Regular">
              {title}
            </Heading>
            {isExpanded ? (
              <ChevronUpIcon boxSize={6} />
            ) : (
              <ChevronDownIcon boxSize={6} />
            )}
          </Flex>

          {isFilledIn && !hideFilledInIcon && (
            <CheckCircleIcon color="green.500" fontSize="xl" />
          )}
          {!isFilledIn && !hideFilledInIcon && (
            <CheckCircleIcon color="gray.200" fontSize="xl" />
          )}
        </Flex>

        {isExpanded && (
          <>
            <Divider my={2} />
            {children}
          </>
        )}
      </Box>
    </Flex>
  )
}
export default ProfileSectionCard
