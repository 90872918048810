import { Flex, useDisclosure } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { Note } from '@contracts/misc'
import { Icon } from '../ui/Icon'

import AlertDialogUnsavedChanges from '../ui/AlertDialogUnsavedChanges'
import log from '../../common/log'
import Modal from '../ui/Modal'
import { useArea } from '../../common/area-context'
import NoteCardContent from './NoteCardContent'

const NoteCardContentButton: React.FC<{
  note: Note
  propertyId: string
  projectId: string
}> = ({ note, propertyId, projectId }) => {
  const noteArea = useArea()
  if (noteArea.area === 'property' && !propertyId) {
    log.error('Error - no property provided')
    return null
  } else if (noteArea.area === 'project' && !projectId) {
    log.error('Error - no project provided')
    return null
  }

  const [noteHasUnsavedChanges, setNoteHasUnsavedChanges] = useState(false)
  const {
    isOpen: isOpenAlertDialog,
    onOpen: onOpenAlertDialog,
    onClose: onCloseAlertDialog,
  } = useDisclosure()
  const {
    isOpen: isOpenNoteCardContent,
    onOpen: onOpenNoteCardContent,
    onClose: onCloseNoteCardContent,
  } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const closeEditNoteHandler = (avoidAlertDialog?: boolean) => {
    if (noteHasUnsavedChanges && !avoidAlertDialog) {
      onOpenAlertDialog()
    } else {
      onCloseNoteCardContent()
    }
  }
  return (
    <>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Icon
          name="GrExpand"
          h={16}
          mr={2}
          _hover={{
            color: 'gray.300',
            transform: 'scale(1.2)',
            cursor: 'pointer',
          }}
          onClick={onOpenNoteCardContent}
        />
      </Flex>

      <AlertDialogUnsavedChanges
        isOpen={isOpenAlertDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlertDialog}
        onConfirmClose={closeEditNoteHandler}
      />

      <Modal
        isOpen={isOpenNoteCardContent}
        onOpen={onOpenNoteCardContent}
        onClose={closeEditNoteHandler}
        size="5xl"
        pt={10}
      >
        <NoteCardContent
          note={note}
          propertyId={propertyId}
          projectId={projectId}
          showHtml={false}
          onNoteHasUnsavedChanges={setNoteHasUnsavedChanges}
        />
      </Modal>
    </>
  )
}
export default NoteCardContentButton
