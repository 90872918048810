import { Badge, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const ProviderBadge: React.FC<{
  size?: 'sm' | 'md' | 'lg'
  transform?: string
}> = ({ size = 'md', transform }) => {
  const { t } = useTranslation()

  const getW = () => {
    switch (size) {
      case 'sm':
        return 12
      case 'md':
        return 20
      case 'lg':
        return 24
    }
  }
  const getText = () => {
    switch (size) {
      case 'sm':
        return (
          <Text textStyle="textXSmall" color="white">
            {t('general.company')}
          </Text>
        )
      case 'md':
        return (
          <Text textStyle="textRegular" color="white" w={12}>
            {t('general.company')}
          </Text>
        )
      case 'lg':
        return (
          <Text textStyle="textLarge" color="white" w={12}>
            {t('general.company')}
          </Text>
        )
    }
  }
  return (
    <Badge
      bg="primary"
      textAlign="center"
      borderRadius={2}
      w={getW()}
      transform={transform}
    >
      {getText()}
    </Badge>
  )
}
export default ProviderBadge
