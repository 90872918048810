import axios from 'axios'
import useSWR, { SWRResponse } from 'swr'
import {
  Collaborator,
  CreateCollaboratorRequest,
  UpdateCollaboratorRequest,
} from '@contracts/misc'
import { useContext } from 'react'
import AuthContext from '../../common/auth-context'

const baseUrl = window.PROPERTIES_SERVICE_URL

export const useCollaborators = (
  propertyId: string | undefined,
): SWRResponse<Collaborator[] | undefined> => {
  const { isAdmin } = useContext(AuthContext)
  return useSWR<Collaborator[] | undefined>(
    isAdmin
      ? propertyId
        ? `${baseUrl}/admin/properties/${propertyId}/collaborators`
        : null
      : propertyId
      ? `${baseUrl}/properties/${propertyId}/collaborators`
      : null,
    {
      revalidateOnFocus: false,
    },
  )
}

export const useCollaboratorsByAccountId = (
  accountId: string,
): SWRResponse<Collaborator[] | undefined> => {
  return useSWR<Collaborator[] | undefined>(
    `${baseUrl}/properties/collaborators?id=${accountId}`,
  )
}

export const addCollaborator = async (
  propertyId: string,
  newCollaborator: CreateCollaboratorRequest,
) => {
  return axios.post(
    `${baseUrl}/properties/${propertyId}/collaborators`,
    newCollaborator,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const updateCollaborator = async (
  propertyId: string,
  request: UpdateCollaboratorRequest,
) => {
  return axios.patch(
    `${baseUrl}/properties/${propertyId}/collaborators`,
    request,
    { headers: { 'Content-Type': 'application/json' } },
  )
}

export const deleteCollaborator = async ({
  entityId,
  accountId,
}: Collaborator) => {
  return axios.delete(
    `${baseUrl}/properties/${entityId}/collaborators?accountId=${accountId}`,
    { headers: { 'Content-Type': 'application/json' } },
  )
}
