import { Show, Td, Tr } from '@chakra-ui/react'

import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
// import { useNavigate } from 'react-router-dom'
import { HelpRequestResponse } from '@contracts/misc'
// import { PATH_PROJECT_PLACE } from '../../common/nav'
import { getDateStrFromNumberIfExist } from '../../common/util'
// import ProjectPlaceBadge from '../../components/projects/ProjectPlaceBadge'
import StringShortener from '../../components/ui/StringShortener'
import { useAccount } from '../../apiClients/accountsApiClient'
import { useOrganization } from '../../apiClients/organizationsApiClient'

const ListHelpRequestRow: React.FC<{
  bg: string
  accordionsToShow: string[]
  helpRequest: HelpRequestResponse
  toggleAccordion: (id: string) => void
}> = ({ helpRequest, bg, accordionsToShow, toggleAccordion }) => {
  // const navigate = useNavigate()
  // const addressStr = `${helpRequest.street}, ${helpRequest.city}`
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  // const projectPlaceLink = `${PATH_PROJECT_PLACE}/${helpRequest.id}`
  const { data: createdByUser } = useAccount(helpRequest.createdBy)
  const { data: org } = useOrganization(helpRequest.organizationId)
  return (
    <Tr bg={bg} data-cy="projectPlaces">
      <Td color="primary_orange02" _hover={{ color: 'primary_orange01' }}>
        {!accordionsToShow.includes(helpRequest.id) && (
          <AiOutlinePlusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(helpRequest.id)
            }}
          />
        )}
        {accordionsToShow.includes(helpRequest.id) && (
          <AiOutlineMinusCircle
            fontSize={24}
            onClick={() => {
              toggleAccordion(helpRequest.id)
            }}
          />
        )}
      </Td>

      <Td
        // onClick={() => {
        //   navigate(projectPlaceLink)
        // }}
        _hover={{ cursor: 'pointer' }}
      >
        <StringShortener title={helpRequest.name} maxLength={15} />
      </Td>
      <Show above="laptop">
        <Td
          // onClick={() => {
          //   navigate(projectPlaceLink)
          // }}
          _hover={{ cursor: 'pointer' }}
        >
          {helpRequest.consumerStatus}
          {/* <StringShortener title={addressStr} maxLength={25} /> */}
        </Td>
      </Show>
      <Show above="laptop">
        <Td
          // onClick={() => {
          //   navigate(projectPlaceLink)
          // }}
          _hover={{ cursor: 'pointer' }}
        >
          {helpRequest.providerStatus}

          {/* <ProjectPlaceBadge
          variant="status"
          status={helpRequest.consumerStatus}
        /> */}
        </Td>
      </Show>
      <Td
        // onClick={() => {
        //   navigate(projectPlaceLink)
        // }}
        _hover={{ cursor: 'pointer' }}
      >
        {getDateStrFromNumberIfExist(helpRequest.createdAt)}
        {/* <ProjectPlaceBadge variant="phase" phase={helpRequest.phase} /> */}
      </Td>
      <Show above="laptop">
        <Td
          w={18}
          // onClick={() => {
          //   navigate(projectPlaceLink)
          // }}
          _hover={{ cursor: 'pointer' }}
        >
          {/* hej-{helpRequest.consumerStatus} */}

          {`${createdByUser?.firstName ?? ''} ${createdByUser?.lastName ?? ''}`}
          {/* {getDateStrFromNumberIfExist(helpRequest.beginAt)} */}
        </Td>
      </Show>
      <Show above="laptop">
        <Td
          // onClick={() => {
          //   navigate(projectPlaceLink)
          // }}
          _hover={{ cursor: 'pointer' }}
        >
          {org?.name}
          {/* {getDateStrFromNumberIfExist(helpRequest.endAt)} */}
        </Td>
      </Show>
    </Tr>
  )
}
export default ListHelpRequestRow
