import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useToast,
  Image,
  Textarea,
} from '@chakra-ui/react'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

interface FeedbackFormProps {
  context: string
  w?: number
}

interface FeedbackEvent {
  context: string
  comment: string
  whatCouldImprove: string
  grade?: number
  gradeStr?: string
}

const FeedbackPopover: React.FC<FeedbackFormProps> = ({ context, w = 12 }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const applicationInsights = useAppInsightsContext()
  const [grade, setGrade] = useState<number>()
  const [comment, setComment] = useState<string>('')
  const [whatCouldImprove, setWhatCouldImprove] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialFocusRef = React.useRef(null)

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur={false}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Image
          w={w}
          style={{ cursor: 'pointer' }}
          src="/icons/Feedback_icon.svg"
        />
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">
          {t(`feedback.${context}.header`)}
        </PopoverHeader>

        <PopoverBody>
          <form
            onSubmit={ev => {
              ev.preventDefault()
              if (!(grade || comment || whatCouldImprove)) {
                toast({
                  title: t('feedback.nothingFilledIn'),
                  status: 'info',
                  duration: 5000,
                  isClosable: true,
                  position: 'bottom',
                })
                return
              }
              let feedbackEvent: FeedbackEvent = {
                context,
                comment,
                whatCouldImprove,
              }
              if (grade) {
                feedbackEvent = {
                  ...feedbackEvent,
                  grade,
                  gradeStr: String(grade),
                }
              }
              applicationInsights.trackEvent(
                { name: 'Feedback' },
                feedbackEvent,
              )
              toast({
                title: t('feedback.thankYou'),
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'bottom',
              })
              // Clean up and close
              setGrade(undefined)
              setComment('')
              setWhatCouldImprove('')
              onClose()
            }}
          >
            {t(`feedback.${context}.body`)}

            <ButtonGroup>
              <Button
                variant={grade === 1 ? 'solid' : 'outline'}
                onClick={() => setGrade(1)}
              >
                😢
              </Button>
              <Button
                variant={grade === 2 ? 'solid' : 'outline'}
                onClick={() => setGrade(2)}
              >
                🙁
              </Button>
              <Button
                variant={grade === 3 ? 'solid' : 'outline'}
                onClick={() => setGrade(3)}
              >
                😃
              </Button>
              <Button
                variant={grade === 4 ? 'solid' : 'outline'}
                onClick={() => setGrade(4)}
              >
                🤩
              </Button>
            </ButtonGroup>

            <label htmlFor="comment">{t('feedback.comment')}</label>
            <Textarea
              name="comment"
              ref={initialFocusRef}
              value={comment}
              onChange={ev => setComment(ev.target.value)}
            />

            <label htmlFor="whatCouldImprove">
              {t('feedback.whatCouldImprove')}
            </label>
            <Textarea
              name="whatCouldImprove"
              value={whatCouldImprove}
              onChange={ev => setWhatCouldImprove(ev.target.value)}
            />

            <Button type="submit" my={4} variant="primary">
              {t('feedback.send')}
            </Button>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default FeedbackPopover
