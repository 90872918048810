import { Button, Flex, Text, useToast } from '@chakra-ui/react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { ChangePasswordRequestData } from '@contracts/accounts'
import { validateString } from '../../common/auth-utils'
import Input from '../ui/Input'
import Spinner from '../Spinner'
import AuthContext from '../../common/auth-context'
import { updatePassword } from '../../apiClients/accountsApiClient'
import log from '../../common/log'

interface MyFormValues {
  currentPassword: string
  password: string
  confirmPassword: string
}

interface Errors {
  currentPassword?: string
  password?: string
  confirmPassword?: string
}

const EditPassword = () => {
  const { currentUser } = useContext(AuthContext)
  const { t } = useTranslation()
  const toast = useToast()

  if (!currentUser) {
    return null
  }

  const initialValues: MyFormValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  }

  const validate = (values: MyFormValues) => {
    const errors: Errors = {}

    let tmpRes = validateString(
      values.currentPassword,
      t('accounts.accountForm.currentPassword'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.currentPassword = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.password,
      t('accounts.accountForm.newPassword'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.password = tmpRes.errorMsg
    }
    tmpRes = validateString(
      values.confirmPassword,
      t('accounts.accountForm.confirmNewPassword'),
      2,
      30,
      true,
    )
    if (!tmpRes.isValid) {
      errors.confirmPassword = tmpRes.errorMsg
    }
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t('accounts.accountForm.passwordMissmatch')
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting: isLoading,
  } = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const requestData: ChangePasswordRequestData = {
        currentPassword: values.currentPassword,
        password: values.password,
        confirmPassword: values.confirmPassword,
      }

      updatePassword(currentUser.id, requestData)
        .then(() => {
          log.debug('Successfully updated password')
          toast({
            title: t('accounts.accountForm.passwordUpdatedSuccessMessage'),
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .catch(e => {
          log.error({ err: e }, 'Failed to update password')
          toast({
            title: t('accounts.accountForm.passwordUpdatedFailMessage'),
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
          })
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  return (
    <form onSubmit={handleSubmit} data-cy="edit-password">
      <Flex display="flex" flexDirection="column" maxW="md">
        <Input
          title={t('accounts.accountForm.currentPassword')}
          name="currentPassword"
          autoComplete="current-password"
          type="password"
          isMandatory={true}
          isError={!!errors.currentPassword}
          errorText={
            touched.currentPassword && errors.currentPassword
              ? errors.currentPassword
              : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={initialValues.currentPassword}
          mb={4}
        />
        <Input
          title={t('accounts.accountForm.newPassword')}
          name="password"
          type="password"
          autoComplete="new-password"
          isMandatory={true}
          isError={!!errors.password}
          errorText={touched.password && errors.password ? errors.password : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={initialValues.password}
          mb={4}
        />
        <Input
          title={t('accounts.accountForm.confirmNewPassword')}
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          isMandatory={true}
          isError={!!errors.confirmPassword}
          errorText={
            touched.confirmPassword && errors.confirmPassword
              ? errors.confirmPassword
              : ''
          }
          onChange={handleChange}
          onBlur={handleBlur}
          variant="plain"
          defaultValue={initialValues.confirmPassword}
        />
        <Text ml={4} textStyle="infoSmall">
          *{t('input.mandatoryFields')}
        </Text>
        <Button
          type="submit"
          my={4}
          variant={Object.keys(errors).length !== 0 ? 'disabled' : 'primary'}
          disabled={isLoading}
        >
          {t('accounts.editPassword')}
        </Button>
      </Flex>
      {isLoading && <Spinner />}
    </form>
  )
}
export default EditPassword
