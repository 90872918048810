import { Badge, CloseButton, Flex } from '@chakra-ui/react'
import { Tag } from '@contracts/support'

const TagBadge: React.FC<{
  tag: Tag
  isEditable?: boolean
  mb?: number
  onRemoveTag?: (id: string) => void
  color?: string
}> = ({ tag, isEditable = false, mb, onRemoveTag, color = 'white' }) => {
  const removeTagHandler = (id: string) => {
    if (isEditable && onRemoveTag) {
      onRemoveTag(id)
    }
  }
  return (
    <Badge
      borderRadius="lg"
      bg="secondary"
      py={0.5}
      pl={isEditable ? 4 : 2}
      pr={isEditable ? 1 : 2}
      mr={4}
      border="1px"
      borderColor="gray.400"
      mb={mb}
      color={color}
    >
      <Flex flexDir="row" alignItems="center">
        {tag.name}
        {isEditable && (
          <CloseButton size="sm" onClick={() => removeTagHandler(tag.id)} />
        )}
      </Flex>
    </Badge>
  )
}

export default TagBadge
