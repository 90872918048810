import { SearchIcon } from '@chakra-ui/icons'
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import { ConstructionGlossary } from '@contracts/support'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { fetchConstructionGlossary } from '../../../apiClients/constructionGlossaryApiClient'
import log from '../../../common/log'
import Heading from '../../ui/Heading'
import SearchLetter from '../../ui/SearchLetter'

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Å',
  'Ä',
  'Ö',
]

const ConstructionGlossarySection: React.FC<{ mx: number | string }> = ({
  mx,
}) => {
  const { t } = useTranslation()
  const [activeLetter, setActiveLetter] = useState('A')
  const [glossaries, setGlossaries] = useState<ConstructionGlossary[]>([])

  useEffect(() => {
    searchLetterClickHander(activeLetter)
  }, [])

  const searchLetterClickHander = (letter: string) => {
    setActiveLetter(letter)
    fetchConstructionGlossary({ letter: letter })
      .then(({ data }) => {
        setGlossaries(data)
      })
      .catch(error => {
        log.error({ err: error }, 'Failed to fetch construction glossary.')
      })
  }

  const searchChangedHandler = (searchStr: string) => {
    if (searchStr !== '') {
      setActiveLetter('')
      fetchConstructionGlossary({ search: searchStr })
        .then(({ data }) => {
          setGlossaries(data)
        })
        .catch(error => {
          log.error({ err: error }, 'Failed to fetch construction glossary.')
        })
    } else {
      searchLetterClickHander('A')
    }
  }

  return (
    <>
      <Flex
        flexDir="column"
        textAlign="center"
        maxW="container.xl"
        justifyContent="center"
        px={mx}
      >
        <Heading mt={12} mb={2} textStyle="h4">
          {t('constructionGlossary.search')}
        </Heading>
        <Text>{t('constructionGlossary.descriptionText')}</Text>

        <InputGroup mt={8} maxW="md" alignSelf="center" boxShadow="lg">
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.400" />}
          />
          <Input
            placeholder={t('constructionGlossary.searchInputPlaceholder')}
            bg="white"
            onChange={e => {
              searchChangedHandler(e.target.value)
            }}
          />
        </InputGroup>

        <Flex
          flexDir="row"
          justifyContent={{ xxs: 'left', xxl: 'space-between' }}
          px={mx}
          mt={8}
          mb={6}
          flexWrap="wrap"
        >
          {alphabet.map(letter => {
            return (
              <SearchLetter
                key={letter}
                letter={letter}
                onClick={searchLetterClickHander}
                isActive={letter === activeLetter}
              />
            )
          })}
        </Flex>
      </Flex>
      <Flex
        flexDir="row"
        w="100%"
        wrap="wrap"
        justifyContent="space-around"
        mb={36}
      >
        {glossaries.length > 0 &&
          glossaries.map(glossary => {
            return (
              <Flex
                key={glossary.id}
                flexDir="column"
                bg="white"
                borderRadius="lg"
                textAlign="left"
                mx={4}
                my={4}
                px={4}
                pt={6}
                pb={4}
                boxShadow="lg"
                w="sm"
              >
                <Heading mb={4}>{glossary.name}</Heading>
                <Text fontWeight="light">{glossary.description}</Text>
              </Flex>
            )
          })}
      </Flex>
      {/* </Flex> */}
    </>
  )
}
export default ConstructionGlossarySection
