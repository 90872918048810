import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/react'
import { ProjectResponse } from '@contracts/projects'
import { PropertyResponse } from '@contracts/misc'

import AddOrEditCollaboratorButton from '../ui/AddOrEditCollaboratorButton'
import ListCollaborators from './ListCollaborators'
import { useCollaboratorParts } from './collaboratorHelper'
import ListInvites from './ListInvites'

const CollaboratorsPage: React.FC<{
  property?: PropertyResponse
  project?: ProjectResponse
  mt?: number | string
  pb?: number | string
}> = ({ property, project, mt, pb = 64 }) => {
  const { t } = useTranslation()

  const { currentUserCollaboratorRelation } = useCollaboratorParts({
    propertyId: property?.id,
    projectId: project?.id,
  })
  const allowedToEdit = currentUserCollaboratorRelation === 'Owner'

  return (
    <>
      <Box mt={mt} w={48}>
        <AddOrEditCollaboratorButton
          property={property}
          project={project}
          variant={'BUTTON'}
          w={40}
          ml={{ xxs: 2, tablet: 0 }}
          isDisabled={!allowedToEdit}
          tooltip={
            !allowedToEdit
              ? t('accounts.notAllowedToEditIfNotOwnerTooltip')
              : ''
          }
        >
          <Text>{t('properties.addCollaborator')}</Text>
        </AddOrEditCollaboratorButton>
      </Box>

      {property || project ? (
        <>
          <ListCollaborators property={property} project={project} mt={10} />
          <ListInvites property={property} project={project} pb={pb} mt={10} />
        </>
      ) : null}
    </>
  )
}
export default CollaboratorsPage
