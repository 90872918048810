import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FileError } from 'use-file-picker'
import {
  IMG_PICKER_MAX_FILESIZE,
  IMG_PICKER_MAX_NO_FILES,
  IMG_PICKER_MIN_FILESIZE,
  IMG_PICKER_MIN_NO_FILES,
} from '../../pages/constants'

const FilePickerErrorAlert: React.FC<{ errors: FileError[] }> = ({
  errors,
}) => {
  const { t } = useTranslation()
  const getErrorsIfAny = () => {
    if (errors.length) {
      return (
        <Box pl={2} pt={2} color="red">
          {errors[0].fileSizeTooSmall &&
            t('richtext.fileUploadErrors.fileTooSmall', {
              minSize: IMG_PICKER_MIN_FILESIZE,
            })}
          {errors[0].fileSizeToolarge &&
            t('richtext.fileUploadErrors.fileTooLarge', {
              maxSize: IMG_PICKER_MAX_FILESIZE,
            })}
          {errors[0].readerError && t('richtext.fileUploadErrors.readError')}
          {errors[0].maxLimitExceeded &&
            t('richtext.fileUploadErrors.maxLimitExceeded', {
              maxNo: IMG_PICKER_MAX_NO_FILES,
            })}
          {errors[0].minLimitNotReached &&
            t('richtext.fileUploadErrors.minLimitNotReached', {
              minNo: IMG_PICKER_MIN_NO_FILES,
            })}
        </Box>
      )
    }
    return
  }
  if (errors.length) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text pb={2}>{getErrorsIfAny()}</Text>
      </Alert>
    )
  }
  return null
}
export default FilePickerErrorAlert
