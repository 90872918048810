import { Center } from '@chakra-ui/react'
import FeedbackPopover from './FeedbackPopover'
import './GlobalFeedback.scss'

const GlobalFeedback = () => {
  return (
    <div className="GlobalFeedback">
      <Center h="60px">
        <FeedbackPopover context="general" />
      </Center>
    </div>
  )
}

export default GlobalFeedback
