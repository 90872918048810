import { Flex, Text } from '@chakra-ui/react'
import { Address } from '@contracts/misc'
import { useTranslation } from 'react-i18next'
import TextCard from '../ui/TextCard'
const AddressCard: React.FC<{
  address?: Address | undefined
  street?: string
  zipCode?: string
  city?: string
  mr?: number | string
  mt?: number | string
  mb?: number | string
  borderVariant?: 'plain' | 'border'
  variant?: '3-rows' | '2-rows' | '1-row'
  headerBg?: string
  noHeader?: boolean
  color?: string
}> = ({
  address,
  mr,
  mt,
  mb,
  street,
  zipCode,
  city,
  borderVariant = 'plain',
  variant = '3-rows',
  headerBg,
  noHeader = false,
  color,
}) => {
  const { t } = useTranslation()

  const getAddrStr = () => {
    if (address) {
      return `${address.street}, ${address.zipCode}, ${address.city}`
    }
    return `${street ?? ''}, ${zipCode ?? ''}, ${city ?? ''}`
  }
  const getZipAndCityStr = () => {
    if (address) {
      return `${address.zipCode}, ${address.city}`
    }
    return `${zipCode ?? ''}, ${city ?? ''}`
  }
  const getContent = () => {
    if (!address && !street && !zipCode && !city) {
      return <Text>{t('properties.noAddress')}</Text>
    }
    switch (variant) {
      case '1-row':
        return getAddrStr()

      case '2-rows':
        return (
          <>
            <Text h={5}>{address ? address.street : street}</Text>
            <Text>{getZipAndCityStr()}</Text>
          </>
        )
      case '3-rows':
        return (
          <>
            <Text h={5}>{address ? address.street : street}</Text>
            <Text>{address ? address.zipCode : zipCode}</Text>
            <Text>{address ? address.city : city}</Text>
          </>
        )
    }
  }
  return (
    <TextCard
      variant={borderVariant}
      heading={noHeader ? '' : t('properties.address')}
      mr={mr}
      mt={mt}
      headerBg={headerBg}
      mb={mb}
      color={color}
    >
      <Flex flexDir="column">{getContent()}</Flex>
    </TextCard>
  )
}
export default AddressCard
