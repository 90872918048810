import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Show } from '@chakra-ui/react'
import AuthContext from '../common/auth-context'
import HeaderNavbarPortal from './portal/HeaderNavbarPortal'

const LayoutAdmin = () => {
  const authCtx = useContext(AuthContext)

  if (!authCtx.isLoggedIn) {
    return null
  }
  return (
    <Box display="flex" flexDir="row">
      <Box display="flex" flexDir="column">
        <Show above="laptop">
          <HeaderNavbarPortal isAdminPortal={true} showHamburger={false} />
          <Box ml={4}>
            <Outlet />
          </Box>
        </Show>
        <Show below="laptop">
          <HeaderNavbarPortal isAdminPortal={true} showHamburger={true} />
          <Box ml={{ tablet: 4 }}>
            <Outlet />
          </Box>
        </Show>
      </Box>
    </Box>
  )
}
export default LayoutAdmin
