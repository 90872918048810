import { filter, orderBy } from 'lodash'
import { ProjectResponse } from '@contracts/projects'
import { ProjectSortingOptionType } from '../../common/enums'

export const calculateSortedItems = (
  sortOption: ProjectSortingOptionType,
  projects?: ProjectResponse[],
  searchStr?: string,
) => {
  const [, sortingField, sortOrder] = sortOption

  const filteredProjects = searchStr
    ? filter(projects, ({ name }) => {
        const searchStrLowercase = searchStr.toLowerCase()
        return name.toLowerCase().includes(searchStrLowercase)
      })
    : projects

  return orderBy(filteredProjects, sortingField, sortOrder)
}
