import { Box, Image, Text } from '@chakra-ui/react'
import Heading from '../ui/Heading'
import detailPlanExplanation from '../../img/content/detailPlanExplanation.svg'

export const CardDetailPlan: React.FC<{ px?: number | string }> = ({ px }) => {
  return (
    <Box textStyle="infoTextLight" px={px}>
      <Heading textStyle="h6" mb={2}>
        Detaljplanen reglerar hur och var det får bebyggas, hur vägar och parker
        ska anläggas och så vidare. Den gällande detaljplanen styr om bygglov
        beviljas eller inte.
      </Heading>
      <Text>
        <br />
        En detaljplan består av en planbeskrivning och en plankarta.
        <br />
        <br />
        <b>Planbeskrivningen</b> är vägledande. Den beskriver planens bakgrund,
        förutsättningar och genomförande.
        <br />
        <br />
        <b>Plankartan</b> med bestämmelser är det juridiskt bindande dokument
        som en kommun tar fram för att reglera användningen av mark och
        vattenområden. Plankartan består av två huvudsakliga lager, grundkarta
        och själva planen med dess nya användning. På plankartan finns
        bestämmelser som visar bland annat vilken användning byggnader ska ha,
        hur höga de kan bli eller var det inte får byggas alls. Bestämmelserna
        finns under eller bredvid kartan. Plankartans områden kan vara allmän
        platsmark som är offentlig, kvartersmark som är privat eller
        vattenområden. De bestämmelser som finns i en gällande detaljplan ska
        sedan följas när bygglov prövas. Det gäller både när det ska byggas nytt
        och när bebyggelse ska förändras eller bevaras.
        <br />
        <br />
        Under arbetet med detaljplanen tar kommunen ställning till allmänna
        intressen enligt andra kapitlet plan- och bygglagen samt till
        hushållningsbestämmelserna i tredje och fjärde kapitlen miljöbalken.
        Observera att de krav som inte har prövats i detaljplanen och som gäller
        allmänna intressen tas upp i bygglovsprövningen.
        <br />
        <br />
        Detaljplanen medför i princip en rätt att bygga i enlighet med planen
        under en särskild angiven genomförandetid, som är minst 5 och högst 15
        år. Efter att genomförandetiden gått ut fortsätter planen att gälla på
        samma sätt som innan med den skillnaden att planen då kan ersättas,
        ändras eller upphävas utan att berörda kan ställa ersättningsanspråk för
        förlorade rättigheter.
      </Text>
      <br />
      <Heading textStyle="h8">Att tolka en plankarta</Heading>

      <Image src={detailPlanExplanation} mt={10} mb={4} />

      <Heading textStyle="h8" color="logo_brown">
        Boverket rekommenderar följande förkortningar och färgmarkeringar att
        använda i plankartan
      </Heading>
      <Box pl={6}>
        <ul>
          <li>B för bostäder (gul)</li>
          <li>C för centrum (brun)</li>
          <li>D för vård (röd)</li>
          <li>H för handel (brun)</li>
          <li>J för industri, tillverkning, materialhantering (blågrå)</li>
          <li>K för kontor och konferenslokal (brun)</li>
          <li>M för minneslund, begravningsplats, krematorium (lila)</li>
          <li>N för friluftsliv och camping (mörkgrön)</li>
          <li>P för parkering, garage (grå)</li>
          <li>R för kultur och fritid (rosa)</li>
          <li>S för skola, högre utbildning (röd)</li>
          <li>T för järnväg, tunnelbana och liknande (grå)</li>
          <li>
            u för att marken skall vara tillgänglig för allmänna underjordiska
            ledningar
          </li>
          <li>V för hamn, utfyllnader, kanaler (grå)</li>
          <li>W för vattenområden (ljusblå)</li>
          <li>
            z för att marken ska vara tillgänglig för allmän fordonstrafik
          </li>
          <li>0.0 (inom romb) för totalhöjd i meter</li>
          <li>+0.0 (inom romb) för totalhöjd i meter över nollplanet</li>
          <li>Korsmark anger att enbart uthus och garage får byggas</li>
          <li>Prickmark anger att inga byggnader får uppföras</li>
          <li>
            Ringmark anger att marken får byggas under (över) med körbart
            bjälklag
          </li>
          <li>
            Q-märkning innebär skydd för kulturhistoriskt värdefull bebyggelse
            inom planens område
          </li>
        </ul>
      </Box>
    </Box>
  )
}
