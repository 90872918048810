import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useLocation } from 'react-router-dom'
import {
  getLink,
  // PATH_ADMIN_PAGES,
  // PATH_ADMIN_PROJECTS,
  // PATH_ADMIN_PROPERTIES,
  // PATH_MY_PROJECTS,
  // PATH_MY_PROPERTIES,
  // PATH_PROJECT_NOTE,
  // PATH_PROPERTY_NOTE,
} from '../../common/nav'
import { Link } from '../../ui'
// import { Icon } from './Icon'

const FileLink: React.FC<{
  area: 'property' | 'project' | 'propertyNote' | 'projectNote'
  areaId: string
  // variant?: 'iconAndName' | 'icon'
  // color?: string
}> = ({
  area,
  areaId,
  // variant = 'iconAndName', color = 'black'
}) => {
  const { pathname } = useLocation()
  // let isAdminPages = false
  // if (pathname.includes(PATH_ADMIN_PAGES)) {
  //   isAdminPages = true
  // }

  const linkPath = getLink(pathname, area, areaId)
  // switch (area) {
  //   case 'property':
  //     linkPath = isAdminPages
  //       ? `${PATH_ADMIN_PROPERTIES}?fastighet=${areaId}`
  //       : `${PATH_MY_PROPERTIES}/${areaId}`
  //     break
  //   case 'propertyNote':
  //     linkPath = `${PATH_PROPERTY_NOTE}/${areaId}`
  //     break
  //   case 'project':
  //     linkPath = isAdminPages
  //       ? `${PATH_ADMIN_PROJECTS}?projekt=${areaId}`
  //       : `${PATH_MY_PROJECTS}/${areaId}`
  //     break
  //   case 'projectNote':
  //     linkPath = `${PATH_PROJECT_NOTE}/${areaId}`

  //     break
  //   default:
  //     linkPath = ''
  // }
  return (
    <Box
      px={2}
      py={0.5}
      borderRadius={4}
      // onClick={() => {

      // }}
      _hover={{ bg: 'primary', color: 'white', cursor: 'pointer' }}
      overflow="clip"
      wordBreak="keep-all"
      flexWrap="nowrap"
      maxH={6}
      // color={color}
    >
      <Link to={linkPath}>
        <Flex
          overflow="clip"
          wordBreak="keep-all"
          columnGap={2}
          alignItems="center"
        >
          <ExternalLinkIcon />
          {/* {variant === 'iconAndName' && ( */}
          <Text fontSize="sm">
            <>Gå till {t(`attachments.area.${area}`)}</>
          </Text>
          {/* )} */}
        </Flex>
      </Link>
    </Box>
  )
}
export default FileLink
