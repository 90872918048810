import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProjectResponse } from '@contracts/projects'
import { Fragment } from 'react'
import { IconName } from '../../common/icon-utils'
import {
  navLinksConsumerPortal,
  PATH_MY_PRIVATE_PAGES,
  // PATH_PROPERTY,
} from '../../common/nav'

import { Icon } from '../../components/ui/Icon'
import { Logo } from '../../components/ui/Logo'

import { useProjects } from '../../apiClients/projectsApiClient'
import { useProperties } from '../../apiClients/propertiesApiClient'

import SidebarAreaHeader from './SidebarAreaHeader'
import SidebarItem from './SidebarItem'

const MenuDrawerConsumer: React.FC<{
  isOpenMenuDrawer: boolean
  onOpenMenuDrawer: () => void
  onCloseMenuDrawer: () => void
  onAddProperty: () => void
  onOpenAddProjectModal: () => void
}> = ({
  isOpenMenuDrawer,
  onCloseMenuDrawer,
  onAddProperty,
  onOpenAddProjectModal,
}) => {
  const { data: properties } = useProperties()
  const { data: projects } = useProjects()

  const orphanProjects: ProjectResponse[] | undefined = projects?.filter(
    project => {
      return !properties?.find(property => {
        return project.propertyId === property.id
      })
    },
  )
  const isExpanded = true
  const navigate = useNavigate()

  const marginLeftHeading = 4
  const paddingLeftItem = 1
  const { t } = useTranslation()

  return (
    <Drawer
      placement="left"
      onClose={onCloseMenuDrawer}
      isOpen={isOpenMenuDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody m={0} p={0}>
          <DrawerCloseButton zIndex="overlay" />

          <Flex
            pos="fixed"
            pt={6}
            flexDir="column"
            w="100%"
            h="100vh"
            boxShadow="xl"
            background="lightBackground"
            overflow="scroll"
            pb={32}
          >
            {isExpanded && (
              <Logo
                name="BerikarWithName"
                mb={4}
                h={8}
                onClick={() => {
                  navigate(PATH_MY_PRIVATE_PAGES)
                  onCloseMenuDrawer()
                }}
                ml={6}
              />
            )}

            {/* My Properties section */}
            {/* <SidebarAreaHeader
              mt={4}
              mr={6}
              mb={2}
              ml={marginLeftHeading}
              onClick={() => {
                onAddProperty()
                onCloseMenuDrawer()
              }}
              isMinimized={!isExpanded}
              toolTipText={t('portal.sidebar.addNewProperty')}
            >
              {isExpanded && <Text>{t('general.myProperties')}</Text>}
            </SidebarAreaHeader>
            {!properties && (
              <Box
                as="p"
                padding=".1rem .2rem .4rem 4.1rem"
                textStyle="inputPlaceholder"
              >
                {t('portal.loading...')}
              </Box>
            )}
            {properties && properties.length === 0 && (
              <Box
                padding=".1rem .2rem .4rem 4.1rem"
                textStyle="inputPlaceholder"
              >
                {isExpanded && <Text>{t('portal.sidebar.noHomesFound')}</Text>}
              </Box>
            )}
            {properties &&
              properties.length > 0 &&
              properties.map(p => {
                return (
                  <SidebarItem
                    key={p.id}
                    variant="property"
                    pl={paddingLeftItem}
                    py={1}
                    pr={1}
                    isMinimized={!isExpanded}
                    toLink={`${PATH_PROPERTY}/${p.id}`}
                    onClose={onCloseMenuDrawer}
                  >
                    {p.name}
                  </SidebarItem>
                )
              })} */}
            <SidebarAreaHeader
              mt={4}
              mr={6}
              ml={marginLeftHeading}
              // onClick={onOpenAddPropertyModal}
              onClick={() => {
                onAddProperty()
                onCloseMenuDrawer()
              }}
              // isMinimized={!isExpanded}
              toolTipText={t('portal.sidebar.addNewProperty')}
              dataCy="addNewProperty"
            >
              {/* {isExpanded && <Text>{t('general.myProperties')}</Text>} */}
              <Text>{t('general.myProperties')}</Text>
            </SidebarAreaHeader>
            {!properties && (
              <Box
                as="p"
                padding=".1rem .2rem .4rem 4.1rem"
                textStyle="inputPlaceholder"
              >
                {t('portal.loading...')}
              </Box>
            )}
            {properties && properties.length === 0 && (
              <Box
                padding=".1rem .2rem .4rem 4.1rem"
                textStyle="inputPlaceholder"
              >
                {/* {isExpanded && <Text>{t('portal.sidebar.noHomesFound')}</Text>} */}
                <Text>{t('portal.sidebar.noHomesFound')}</Text>
              </Box>
            )}
            {properties &&
              properties.length > 0 &&
              properties.map(property => {
                return (
                  <Fragment key={property.id}>
                    <SidebarItem
                      variant="property"
                      pl={2}
                      py={1}
                      mt={3}
                      pr={1}
                      // isMinimized={!isExpanded}
                      toLink={`${PATH_MY_PRIVATE_PAGES}fastighet/${property.id}`}
                      onClick={onCloseMenuDrawer}
                    >
                      {property.name}
                    </SidebarItem>
                    {projects &&
                      projects.map(project => {
                        if (project.propertyId === property.id) {
                          return (
                            <SidebarItem
                              key={project.id}
                              variant="project"
                              pl={4}
                              py={1}
                              pr={1}
                              // isMinimized={!isExpanded}
                              toLink={`${PATH_MY_PRIVATE_PAGES}projekt/${project.id}`}
                              onClick={onCloseMenuDrawer}
                              hideLogo
                              isLightFont
                            >
                              {/* <Text fontWeight="light">{project.name}</Text> */}
                              {project.name}
                            </SidebarItem>
                          )
                        }
                      })}
                  </Fragment>
                )
              })}

            {/* My Projects section */}
            <SidebarAreaHeader
              mt={4}
              mr={6}
              mb={2}
              ml={marginLeftHeading}
              onClick={() => {
                onOpenAddProjectModal()
                onCloseMenuDrawer()
              }}
              isMinimized={!isExpanded}
              toolTipText={t('portal.sidebar.addNewProject')}
            >
              {isExpanded && <Text>{t('general.otherProjects')}</Text>}
            </SidebarAreaHeader>
            {orphanProjects &&
              orphanProjects.map(p => {
                return (
                  <SidebarItem
                    key={p.id}
                    variant="project"
                    pl={paddingLeftItem}
                    py={1}
                    pr={1}
                    isMinimized={!isExpanded}
                    toLink={`${PATH_MY_PRIVATE_PAGES}projekt/${p.id}`}
                    onClose={onCloseMenuDrawer}
                  >
                    {p.name}
                  </SidebarItem>
                )
              })}

            {/* Menu section */}
            <SidebarAreaHeader
              mt={4}
              mr={6}
              mb={2}
              ml={marginLeftHeading}
              noAddIcon={true}
              isMinimized={!isExpanded}
            >
              {isExpanded && <Text>{t('portal.sidebar.menu.menu')}</Text>}
            </SidebarAreaHeader>
            {navLinksConsumerPortal &&
              navLinksConsumerPortal.map(item => {
                return (
                  <SidebarItem
                    key={item.value}
                    variant="menu"
                    icon={<Icon name={item.icon as IconName} />}
                    pl={paddingLeftItem}
                    py={1}
                    pr={1}
                    isMinimized={!isExpanded}
                    toLink={item.to}
                    isDisabled={item.isDisabled}
                    onClose={onCloseMenuDrawer}
                  >
                    {t(item.value)}
                  </SidebarItem>
                )
              })}
            <Box height="20%"></Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
export default MenuDrawerConsumer
