import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react'

const AlertChakra: React.FC<{
  status: 'success' | 'error' | 'info' | 'warning' | 'loading'
  title?: string
  description: string
  mb?: number
}> = ({ status, title, description, mb }) => {
  return (
    <ChakraAlert status={status} borderRadius={8} mb={mb}>
      <AlertIcon />
      <Box>
        <AlertTitle mb={1}>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </Box>
    </ChakraAlert>
  )
}
export default AlertChakra
