import { Flex, ResponsiveValue } from '@chakra-ui/react'
import { useProperties } from '../../apiClients/propertiesApiClient'

import PropertyCard from './PropertyCard'

const ListPropertiesSection: React.FC<{
  px?: ResponsiveValue<number | string>
}> = ({ px }) => {
  const { data: properties } = useProperties()

  return (
    <Flex flexDir="row" px={px} gap={{ xxs: 4, laptop: 8 }} wrap="wrap">
      {properties &&
        properties.length > 0 &&
        properties.map(p => {
          return <PropertyCard key={p.id} property={p} w="160px" h="160px" />
        })}
    </Flex>
  )
}
export default ListPropertiesSection
